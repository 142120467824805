import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { actionCreators as doctorActionCreators } from 'state/doctors/actions.js';
import Header from 'components/Header.jsx';
import HealthSystemActions from 'components/HealthSystemActions.jsx';
import Dropdown from 'components/Dropdown.jsx';
import {
	PresenceStatusType,
	UserRoles,
	ConversationType,
	WorkFlow,
	NotificationTypes,
	AppointmentStatus,
	AlertTypes,
	FeedColors,
	HealthcareErrorCode,
	UserTypes,
	DeviceListLevel,
} from 'constants/enums.js';
import { buildProfilePicUrl } from 'infrastructure/helpers/thumbnailHelper.js';
import {
	checkIPPermission,
	clearStorage,
	getCompanyId,
	getUserId,
	getUserInfo,
	getUserRole,
	setUserRole,
} from 'infrastructure/auth.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getNotifications, updateUnreadNotifications } from 'api/patientNotifications.js';
import NotificationItem from 'components/NotificationItem.jsx';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { getLastActivityTime } from 'infrastructure/helpers/dateHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import {
	decodeHtml,
	getNursePoolingStatus,
	isMobileOrTabletDevice,
	buildTree,
	getConfigurationMenu,
	getConfigurationValue,
	getHealthSystemDevices,
	getStorage,
	getUserRoleId,
	getIsOldUserExperience,
} from 'infrastructure/helpers/commonHelpers.js';
import { updateReadStatus, getRecent, setAllReadConversation } from 'api/messenger.js';
import Alert from 'components/Alert.jsx';
import Loader from 'components/Loader.jsx';
import CallPatientModal from 'views/Doctors/Partials/CallPatientModal.jsx';
import { getWorkflow, setWorkflow, getAssociatedNurses } from 'api/workflow.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { getUserHealthSystemPreferences, getUserPreferences, updateSession } from 'api/users.js';
import { getHealthSystems } from 'api/healthSystems.js';
import UserRolesList from 'components/UserRolesList.jsx';
import { updateUserPresence } from 'api/userPresence.js';
import EditPoolingModal from 'components/EditPoolingModal.jsx';
import ConfigurableMenu from 'components/ConfigurableMenu.jsx';
import {
	buildConfigSettings,
	buildHealthSystemMenuConfigs,
	buildRoleSettings,
	configurableRoundingMenu,
	GeneralSettings,
	SettingsCategory,
	UserSettings,
	UserSettingTypes,
} from 'constants/configurationEnums.js';
import { getRegionSubTree } from 'api/tree.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { getHealthSystemRoleConfigs, getTeamSettings } from 'api/adminConfigurations.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import {
	buildCallSettings,
	buildHealthSystemConfigurations,
	buildVisualsSettings,
	getConfiguredRoleDisplayName,
} from 'infrastructure/helpers/configurationsHelpers.js';
import MissedCallsNotifications from 'components/Notifications/MissedCallsNotifications.jsx';
import LightTheme from 'calls/styles/LightTheme.js';
import Messages from 'icons/Header/Messages.jsx';
import Notifications from 'icons/Header/Notifications.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import Button from 'components/Button.jsx';
import AiAlertNotifications from 'components/AiAlertNotifications.jsx';

const MainHeader = props => {
	let appointmentReminderTimeOut;
	const companyId = getCompanyId();
	const userInfo = getUserInfo();
	const { current: userId } = useRef(getUserId());
	const socket = useContext(SocketContext);
	const user = useSelector(state => state.user);
	const patient = useSelector(state => state.myPatients);
	const doctor = useSelector(state => state.myDoctors);
	const configurations = useSelector(state => state.configurations);
	const healthSystems = useSelector(state => state.healthSystems);
	const unreadNotificationsCounter = useSelector(state => state.user.unreadNotificationsCounter);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const companySettings = useSelector(state => state.company.companySettings);

	const dispatch = useDispatch();
	const toggleFullscreenAction = () => dispatch(healthSystemsActionCreators.toggleFullscreen());
	const setConversationNotifications = notifications => dispatch(userActionCreators.setConversationNotifications(notifications));
	const toggleLeftNavigation = () => dispatch(healthSystemsActionCreators.toggleLeftNavigation());
	const setSelectedDoctor = doc => dispatch(doctorActionCreators.setSelectedDoctor(doc));
	const setDoctorsAction = docs => dispatch(doctorActionCreators.setDoctors(docs));
	const setSelectedPatient = item => dispatch(patientActionCreators.setSelectedPatient(item));
	const setPatients = items => dispatch(patientActionCreators.setPatients(items));
	const setUserSession = session => dispatch(userActionCreators.setUserSession(session));
	const intl = useIntl();
	const history = useHistory();

	const isFullScreenSize =
		document.fullscreenElement ||
		document.mozFullScreenElement ||
		document.webkitFullscreenElement ||
		document.msFullscreenElement;

	const [indexPage, setIndexPage] = useState(0);
	const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
	const [notifications, setNotifications] = useState([]);
	const [breadcrumb, setBreadcrumb] = useState([]);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [recentConversationsError, setRecentConversationsError] = useState('');
	const [updateTreeError, setUpdateTreeError] = useState('');
	const [updateNotificationsError, setUpdateNotificationsError] = useState('');
	const [notificationsError, setNotificationsError] = useState('');
	const [allReadError, setAllReadError] = useState('');
	const [isCallPatientModalOpen, setIsCallPatientModalOpen] = useState(false);
	const [patientCallRequests, setPatientCallRequests] = useState([]);
	const [appointmentReminderText, setAppointmentReminderText] = useState('');
	const [error, setError] = useState(null);
	const [isMenuSettingsOpen, setIsMenuSettingsOpen] = useState(false);
	const [hasNewConversations, setHasNewConversations] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isEditPoolingModalOpen, setIsEditPoolingModalOpen] = useState(false);
	const [workflowError, setWorkflowError] = useState('');
	const [notificationsLoading, setNotificationsLoading] = useState(false);
	const [newNotificationsIds, setNewNotificationsIds] = useState([]);
	const [unreadNotificationsIds, setUnreadNotificationsIds] = useState([]);
	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
	const [isMissedCallsOpen, setIsMissedCallsOpen] = useState(false);
	const [showDropdownOnTouch, setShowDropdownOnTouch] = useState('');
	const [newAlertsNotificationsCount, setNewAlertsNotificationsCount] = useState(0);
	const playingAlertRef = useRef(null);
	const displayMessageSeconds = 5;
	const notificationsPageSize = 10;
	const wrapperRef = useRef(null);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);

	const precautions = [
		{
			title: intl.formatMessage({ id: 'suspiciousBehavior' }),
			color: FeedColors.purple,
			id: 0,
		},
		{
			title: intl.formatMessage({ id: 'suicideWatch' }),
			color: FeedColors.cyan,
			id: 1,
		},
		{
			title: intl.formatMessage({ id: 'falls' }),
			color: FeedColors.yellow,
			id: 2,
		},
		{
			title: intl.formatMessage({ id: 'isolation' }),
			color: FeedColors.red,
			id: 3,
		},
		{
			title: intl.formatMessage({ id: 'seizure' }),
			color: FeedColors.pink,
			id: 4,
		},
		{
			title: intl.formatMessage({ id: 'hospice' }),
			color: FeedColors.black,
			id: 5,
		},
		{
			title: intl.formatMessage({ id: 'detox' }),
			color: FeedColors.orange,
			id: 6,
		},
		{
			title: intl.formatMessage({ id: 'pullingO2' }),
			color: FeedColors.lightBlue,
			id: 7,
		},
		{
			title: intl.formatMessage({ id: 'impulsiveConfused' }),
			color: FeedColors.gray,
			id: 8,
		},
		{
			title: intl.formatMessage({ id: 'nonRedirectable' }),
			color: FeedColors.blue,
			id: 9,
		},
	];

	const getRecentConversations = async () => {
		let conversations = [];
		const response = await getRecent(userId);
		if (response.error) {
			setRecentConversationsError(`${intl.formatMessage({ id: 'errorOccurred' })} ${response.error.message}`);
		} else {
			conversations = response.conversations;
			const individualConversations = conversations.filter(conversation => conversation.typeId === ConversationType.INDIVIDUAL);
			setConversationNotifications(individualConversations);
		}
	};

	const newMessageHandler = ({ message }) => {
		if (
			parseInt(message.sender.objectId, 10) === userInfo.userId ||
			(history.location.pathname.includes(`recent/${message.sender.objectId}`) && getUserRole() === UserRoles.PATIENT) ||
			(history.location.pathname.includes(`patients/${message.sender.objectId}`) && getUserRole() === UserRoles.DOCTOR) ||
			(history.location.pathname.includes(`nurses/${message.sender.objectId}`) &&
				[UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole()))
		) {
			return;
		}
		getRecentConversations();
	};

	const toggleIsFullScreen = () => {
		setIsFullscreen(prevState => !prevState);
	};

	const onNotificationUpdated = eventNotification => {
		const appointmentNotification = [
			NotificationTypes.APPOINTMENT_CREATED,
			NotificationTypes.APPOINTMENT_REMINDER,
			NotificationTypes.APPOINTMENT_UPDATED,
			NotificationTypes.APPOINTMENT_STATUS_CHANGED,
			NotificationTypes.APPOINTMENT_RESCHEDULED,
		].includes(eventNotification.notificationType);

		if (!appointmentNotification) {
			notifyDoctor(eventNotification);
		}
		if (NotificationTypes.APPOINTMENT_REMINDER === eventNotification.notificationType) {
			getAppointmentReminder(eventNotification);
		}

		const newNotification = { ...eventNotification, payload: JSON.parse(eventNotification.payload), isNew: true };

		setNewNotificationsIds(prevState => [...prevState, eventNotification.notificationId]);
		setTotalNotificationsCount(prevState => prevState + 1);
		setUnreadNotificationsIds(prevState => [eventNotification.notificationId, ...prevState]);
		setNotifications(prevState => [newNotification, ...prevState]);
		dispatch(userActionCreators.setUnreadNotificationsCounterIncrement);
	};

	const getAppointmentReminder = data => {
		const payload = JSON.parse(data.payload);
		setAppointmentReminderText(`${translate('youHaveAppointmentWith')} ${payload.CreatedBy.FirstName} ${
			payload.CreatedBy.LastName
		} ${translate('inMinutesOn', { value: 10 })} &quot;
	${payload.Place}&quot;`);
		appointmentReminderTimeOut = setTimeout(() => {
			setAppointmentReminderText('');
		}, displayMessageSeconds * 1000);
	};

	const updateWorkFlow = async id => {
		if (user.doctorData.workflowTypeId === id) {
			return;
		}
		let authorizedNurses = [];
		const params = {
			doctorId: userId,
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
		};
		const res = await getAssociatedNurses(params);
		if (res.error) {
			setWorkflowError(res.error.message);
		} else {
			authorizedNurses = res.nurses.filter(nurse => nurse.isAuthorized);
			setPagination({ pageSize: pagination.pageSize, pageIndex: pagination.pageIndex, totalCount: res.totalCount || 0 });
		}

		const canChangeWorkFlow =
			(authorizedNurses.length > 0 && WorkFlow.THROUGH_NURSES === user.doctorData.workflowTypeId) ||
			WorkFlow.DIRECTLY === user.doctorData.workflowTypeId;
		if (!canChangeWorkFlow) {
			setWorkflowError(intl.formatMessage({ id: 'cannotChangeWorkflow' }));
			return;
		}

		const response = await setWorkflow(userId, id);
		if (response.error) {
			setWorkflowError(response.error.message);
		} else {
			dispatch(userActionCreators.setDoctorData({ ...user.doctorData, workflowTypeId: id }));
		}
	};

	const notifyDoctor = request => {
		const requestPayload = JSON.parse(request.payload);
		if (userId !== request.userGuidId || getUserRole() !== UserRoles.DOCTOR || !requestPayload.IsLiveCall) {
			return;
		}
		const result = !requestPayload.RequestStatusId
			? [...patientCallRequests, request]
			: patientCallRequests
					.filter(item => JSON.parse(item.payload).Request.Patient.UserId !== requestPayload.Request.Patient.UserId)
					.sort((a, b) => +new Date(a.creationDate) - +new Date(b.creationDate));

		setPatientCallRequests(result);
		setIsCallPatientModalOpen(result.length !== 0);
		if (result) {
			playVoiceOver();
		} else {
			stopVoiceOver();
		}
	};

	const fetchNotifications = async pageIndex => {
		setNotificationsLoading(true);
		const response = await getNotifications(pageIndex, notificationsPageSize + newNotificationsIds.length);

		if (response.error) {
			setNotificationsError(`${intl.formatMessage({ id: 'errorOccurred' })} ${response.error.message}`);
			setNotificationsLoading(false);
		} else {
			setNotificationsLoading(false);
			if (unreadNotificationsCounter !== null) {
				setIndexPage(prevState => prevState + 1);
				setNotifications(prevState => [...prevState, ...getParsedNotifications(response.notifications)]);
				setUnreadNotificationsIds(getUnReadNotifications(response.notifications));
			}
			setTotalNotificationsCount(response.totalCount);
			dispatch(userActionCreators.setUnreadNotificationsCounter(response.totalUnread));
		}
	};

	const getParsedNotifications = array =>
		array.reduce((result, notification) => {
			const item = _.cloneDeep(notification);
			if (!newNotificationsIds.includes(notification.id)) {
				item.payload = JSON.parse(notification.payload);
				item.isNew = !notification.isRead;
				result.push(item);
			}
			return result;
		}, []);

	const getUnReadNotifications = array =>
		array.reduce((result, notification) => {
			const item = _.cloneDeep(notification);
			if (!notification.isRead) {
				result.push(item.id);
			}
			return result;
		}, []);

	const onBreadcrumbClick = (options, breadcrumbIndex) => {
		const result = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				result.push(option);
			}
		});
		setBreadcrumb(result);
	};
	const onUpdateUserPresence = async presenceStatusTypeId => {
		const response = await updateUserPresence(userId, presenceStatusTypeId, '');
		if (response.error) {
			setError(response.error.message);
		} else {
			dispatch(userActionCreators.setUserPresenceStatus(presenceStatusTypeId));
		}
	};

	const signOut = () => {
		if (user.userSession.checkedInChannelId) {
			checkOut(null);
		}
		clearStorage();
		history.push('/logout');
	};

	const checkOut = async checkedInChannelId => {
		const response = await updateSession({
			healthSystemId: user.userSession.healthSystem.id,
			checkedInChannelId,
			floorId: null,
			departmentId: null,
			companyId,
		});
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		getStorage().removeItem('helloDeviceId');
		setUserSession({ ...user.userSession, checkedInChannelId: null });
	};

	const handleUpdateUnreadNotifications = async () => {
		if (unreadNotificationsIds.length > 0) {
			const response = await updateUnreadNotifications({
				notificationsIds: unreadNotificationsIds,
			});
			if (response.error) {
				setUpdateNotificationsError(`${intl.formatMessage({ id: 'errorOccurred' })} ${response.error.message}`);
			} else {
				dispatch(userActionCreators.setUnreadNotificationsCounter(unreadNotificationsCounter - unreadNotificationsIds.length));
				setUnreadNotificationsIds([]);
			}
		}
	};

	const handleNotificationsClick = () => {
		setIsNotificationsOpen(prevState => !prevState);
		setNotificationsError('');
		if (notifications.length === newNotificationsIds.length) {
			fetchNotifications(0);
		}
	};

	const handleConversationsClick = async () => {
		if (hasNewConversations) {
			setHasNewConversations(false);
			const response = await updateUnreadNotifications();
			if (response.error) {
				setUpdateNotificationsError(`${intl.formatMessage({ id: 'errorOccurred' })} ${response.error.message}`);
			}
		}
	};

	const redirectToAppointment = (notification, appointmentNotification) => {
		const itemIndex = notifications.findIndex(current => current.id === notification.id);
		notifications[itemIndex] = { ...notifications[itemIndex], isNew: false };
		setUnreadNotificationsIds(prevState => prevState.filter(id => id !== notification.id));

		if (getUserRole() === UserRoles.NURSE) {
			history.push('/waiting-room');
		} else {
			if (!appointmentNotification) {
				return;
			}
			if (notification.payload.StatusId !== AppointmentStatus.CANCELED || getUserRole() === UserRoles.DOCTOR) {
				history.push({ pathname: `/appointments`, state: { openAppointmentDetails: true, notification } });
			}
		}
	};

	const rescheduleOrChangeDoctor = (notification, action) => {
		history.push({
			pathname: '/select-doctor',
			state: { recreateAppointment: true, notification, action },
		});
	};

	const redirectToConversation = async conversation => {
		const id = conversation.contact.objectId;
		if (conversation.unreadMessagesCount > 0) {
			await updateReadStatus(conversation.id);
		}
		const selectedPatient = patient.patients.find(item => item.userId === +id);
		if (selectedPatient) {
			setSelectedPatient(selectedPatient);
		}
		const selectedDoctor = doctor.doctors.find(doc => doc.userId === +id);
		if (selectedDoctor) {
			setSelectedDoctor(selectedDoctor);
		}
		const conversationNotifications = [...user.conversationNotifications];
		const selectedNotification = conversationNotifications.find(item => item.contact.objectId === id);
		selectedNotification.unreadMessagesCount = 0;
		setConversationNotifications(conversationNotifications);
		if ([UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(getUserRole())) {
			const url = conversation.contact.healthCareUserRoles.includes(UserTypes.NURSE)
				? `/nurses/${conversation.contact.objectId}`
				: `/nurse-patients/${conversation.contact.objectId}`;
			history.push({
				pathname: url,
			});
		} else {
			history.push({
				pathname: getUserRole() === UserRoles.PATIENT ? `/recent/${id}/conversation` : `/patients/${id}/conversation`,
			});
		}
	};

	const getNotificationSender = (notification, appointmentNotification) => {
		let firstName = '';
		let lastName = '';
		let profilePicture = '';

		if (!appointmentNotification) {
			const doctorOrMedic = notification.payload.Medic || notification.payload.Doctor;
			firstName = getUserRole() === UserRoles.PATIENT ? doctorOrMedic.FirstName : notification.payload.Request.Patient.FirstName;
			lastName = getUserRole() === UserRoles.PATIENT ? doctorOrMedic.LastName : notification.payload.Request.Patient.LastName;
			profilePicture =
				getUserRole() === UserRoles.PATIENT ? doctorOrMedic.ProfilePicture : notification.payload.Request.Patient.ProfilePicture;
		} else {
			firstName =
				userId === notification.payload.CreatedBy.Id
					? notification.payload.AppointmentInvitation.InvitedUser.FirstName
					: notification.payload.CreatedBy.FirstName;

			lastName =
				userId === notification.payload.CreatedBy.Id
					? notification.payload.AppointmentInvitation.InvitedUser.LastName
					: notification.payload.CreatedBy.LastName;

			profilePicture =
				userId === notification.payload.CreatedBy.Id
					? notification.payload.AppointmentInvitation.InvitedUser.ProfilePicture
					: notification.payload.CreatedBy.ProfilePicture;
		}
		return { firstName, lastName, profilePicture };
	};

	const fetchNotificationsItem = notification => {
		const appointmentNotification = [
			NotificationTypes.APPOINTMENT_CREATED,
			NotificationTypes.APPOINTMENT_REMINDER,
			NotificationTypes.APPOINTMENT_UPDATED,
			NotificationTypes.APPOINTMENT_STATUS_CHANGED,
			NotificationTypes.APPOINTMENT_RESCHEDULED,
		].includes(notification.notificationType);

		if (!notification.sender) {
			notification.sender = getNotificationSender(notification, appointmentNotification);
		}

		return (
			<NotificationItem
				onClick={() => redirectToAppointment(notification, appointmentNotification)}
				type={notification.notificationType}
				userFirstName={notification.sender.firstName}
				userLastName={notification.sender.lastName}
				profilePicture={notification.sender.profilePicture}
				time={notification.creationDate}
				place={notification.payload.Place}
				appointmentStartTime={appointmentNotification && notification.payload.AppointmentSlot.StartDateTime}
				appointmentStatusId={appointmentNotification && notification.payload.StatusId}
				isAppointmentNotification={appointmentNotification}
				rescheduleAppointment={rescheduleOrChangeDoctor}
				notification={notification}
			/>
		);
	};

	useEffect(() => {
		if (!unreadNotificationsCounter) {
			fetchNotifications(0);
		}
	}, []);

	useEffect(() => {
		getRecentConversations();
	}, []);

	useEffect(() => {
		const getMyWorkFlow = async () => {
			if (getUserRole() !== UserRoles.DOCTOR || user.doctorData.workflowTypeId) {
				return;
			}
			const response = await getWorkflow(userId);
			if (response.error) {
				setAllReadError(response.error.message);
			} else {
				dispatch(userActionCreators.setDoctorData({ ...user.doctorData, workflowTypeId: response.workflow.workflowTypeId }));
			}
		};
		getMyWorkFlow();
	}, [userId, getUserRole(), user.doctorData.workflowTypeId]);

	useEffect(() => {
		socket.on(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, onNotificationUpdated);
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, newMessageHandler);
		document.addEventListener('fullscreenchange', toggleIsFullScreen, false);
		return () => {
			socket.off(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, onNotificationUpdated);
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, newMessageHandler);
			document.removeEventListener('fullscreenchange', toggleIsFullScreen, false);
			clearTimeout(appointmentReminderTimeOut);
		};
	}, [appointmentReminderTimeOut, socket, getUserRole()]);

	useEffect(() => {
		if (healthSystems.isFullscreen !== isFullscreen) {
			toggleFullscreenAction();
		}

		if (isFullscreen && !healthSystems.isLeftNavigationExpanded) {
			toggleLeftNavigation();
		}
	}, [isFullscreen, healthSystems.isFullscreen]);

	const toggleFullScreen = () => {
		if (isFullscreen) {
			closeFullscreen();
		} else {
			openFullscreen();
		}
	};

	const openFullscreen = () => {
		const elem = document.documentElement;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */

			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */

			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE/Edge */

			elem.msRequestFullscreen();
		}
	};

	const closeFullscreen = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
	};

	const getLastActivityContent = content => {
		const isMe = content.split(':')[0]?.toLocaleLowerCase() === 'you';
		if (isMe) {
			return intl.formatMessage({ id: 'you' }) + ': ' + content.split(':')[1];
		}
		return content;
	};

	const mapConversationNotifications = isRead => {
		const filteredNotifications = user.conversationNotifications.filter(notification =>
			isRead ? notification.unreadMessagesCount === 0 : notification.unreadMessagesCount > 0
		);
		return (
			<>
				{filteredNotifications.length > 0 && (
					<>
						<p className='notifications-title'> {isRead ? translate('earlier') : translate('unread')} </p>
						{filteredNotifications.map(conversation => (
							<li key={conversation.id} className={classNames(user.darkMode ? 'dark-mode' : '')}>
								<div className='notification-item' onClick={() => redirectToConversation(conversation)}>
									<div className='flex conversation-item-flex'>
										<ProfilePicture
											className='doctor-request-img small-profile-pic available-doctor-request-img'
											firstName={conversation.name.split(' ')[0]}
											lastName={conversation.name.split(' ')[conversation.name.split(' ').length - 1]}
											profilePicture={conversation.pictureThumbnail}
										/>
										<div>
											<h4>{conversation.name}</h4>
											<p>{getLastActivityContent(conversation.lastActivity?.content)}</p>
										</div>
										<div>
											<span>{getLastActivityTime(conversation.lastActivity.time)}</span>
											{conversation.unreadMessagesCount > 0 && (
												<div className='conversation-label-blue'>{conversation.unreadMessagesCount}</div>
											)}
										</div>
									</div>
								</div>
							</li>
						))}
					</>
				)}
			</>
		);
	};

	const playVoiceOver = async () => {
		if (!playingAlertRef.current) {
			playingAlertRef.current = new Audio(`${healthCareCdnUrl}sound/patient-requested-live-call.mp3`);
		}
		try {
			playingAlertRef.current.loop = true;
			await playingAlertRef.current.play();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log('Autoplay failed:', err);
		}
	};

	const stopVoiceOver = () => {
		if (playingAlertRef.current) {
			playingAlertRef.current.currentTime = 0;
			playingAlertRef.current.pause();
			playingAlertRef.current = null;
		}
	};

	const setAllConvosToRead = async () => {
		const response = await setAllReadConversation();
		if (response.error) {
			setAllReadError(response.error.message);
		} else {
			getRecentConversations();
			const doctors = [...doctor.doctors];
			const patients = [...patient.patients];
			const updatedDoctors = doctors.map(doc => ({
				...doc,
				lastActivity: { ...doctor.lastActivity, unReadMessageCount: 0 },
			}));
			const updatedPatients = patients.map(item => ({
				...item,
				lastActivity: { ...patient.lastActivity, unReadMessageCount: 0 },
			}));

			setDoctorsAction(updatedDoctors);
			setPatients(updatedPatients);
		}
	};

	const toggleCheckInOut = async () => {
		if (user.userSession.checkedInChannelId) {
			await checkOut(null);
		}
		history.push('/check-in-dc');
	};

	const unReadConversationNotificationsCount = user.conversationNotifications.filter(item => item.unreadMessagesCount > 0).length;

	const isMonitoringPage = ['/monitoring', '/care-notifications'].includes(history.location.pathname) && isNewExperience;

	useEffect(() => {
		if (isFullScreenSize) {
			if (!isMonitoringPage) {
				closeFullscreen();
			} else {
				setIsFullscreen(true);
			}
		}
	}, [isMonitoringPage, isFullScreenSize]);

	const switchRole = async role => {
		props.setIsLoading(true);
		if (getUserRole() === UserRoles.NURSE) {
			socket.emitWithAck(SocketEvents.Client.ON_UNSUBSCRIBE_FROM_HS_EVENTS, {
				healthSystemId: user.userSession.healthSystem.id,
			});
		}
		const response = await updateSession({
			healthSystemId: user.userSession.healthSystem.id,
			checkedInChannelId: user.userSession.checkedInChannelId || null,
			floorId: user.userSession.floorId || null,
			departmentId: user.userSession.departmentId || null,
			companyId: getCompanyId(),
			roleId: role.id,
		});
		setUserRole(role.name);
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			}
			if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		const healthSystemsRes = await getHealthSystems();
		let selectedHs = user.userSession.healthSystem;
		let regionId = user.userSession.healthSystem.regionId || user.userSession.healthSystem.regions[0].id;
		if (!healthSystemsRes.error) {
			const foundHs = healthSystemsRes.find(item => item.id === user.userSession.healthSystem.id);
			if (!foundHs && healthSystemsRes.length > 0) {
				[selectedHs] = healthSystemsRes;
				regionId = selectedHs.regions[0].id;
			}

			const configurableMenu = getConfigurationMenu(configurations.configurableMenu, role.name);
			dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(false));
			const [configs, navigationResponse, userPreferenceResponse] = await Promise.all([
				await getHealthSystemRoleConfigs(selectedHs.id),
				await getUserPreferences(UserSettingTypes.Navigation),
				await getUserHealthSystemPreferences(selectedHs.id),
			]);

			if (role.name !== UserRoles.ADMIN) {
				await checkIPPermission();
			}
			await updateTree(selectedHs.id, regionId);
			if (!navigationResponse.error) {
				const navMenu = {
					...configurations.configurableMenu,
					[role.name]: buildConfigSettings(navigationResponse, _.cloneDeep(configurableMenu)),
				};
				dispatch(configurationActionCreators.setConfigurableMenu(navMenu));
			}
			if (!configs.error) {
				const hsMenu = await buildHealthSystemMenuConfigs(configs.settings);
				dispatch(configurationActionCreators.setAdminConfigurableMenu(hsMenu));
				history.push('/');
				const roundingConfigs = await buildRoleSettings(
					configs.settings,
					configurableRoundingMenu(),
					getUserRoleId(getUserRole())
				);
				dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
				dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(true));
			}
			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
			await fetchHealthSystemConfigurations(selectedHs.id);

			fetchUserHsPreferences(userPreferenceResponse);
			setUserSession({
				...user.userSession,
				healthSystem: selectedHs,
				regionId,
			});
			if (getUserRole() === UserRoles.NURSE) {
				socket.emitWithAck(SocketEvents.Client.ON_SUBSCRIBE_FROM_HS_EVENTS, { healthSystemId: selectedHs.id });
			}
			props.setIsLoading(false);
		}
	};

	const fetchHealthSystemConfigurations = async healthSystemId => {
		const fetchUnboundHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});
			if (!response.error) {
				const isNewExperience = !getIsOldUserExperience(companyConfigurations, response.settings);
				const healthSystemSettings = {
					callSettings: buildCallSettings(response.settings),
					visualsSettings: buildVisualsSettings(response.settings),
				};
				dispatch(configurationActionCreators.setIsNewExperience(isNewExperience));
				dispatch(configurationActionCreators.setHealthSystemUnboundSettings(healthSystemSettings));
			}
		};

		const fetchBoundedHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.MONITORING, SettingsCategory.PATIENTS],
			});
			if (!response.error) {
				const hsConfigs = buildHealthSystemConfigurations(response.settings);
				dispatch(configurationActionCreators.setHealthSystemConfigs(hsConfigs));
			}
		};

		await fetchUnboundHealthSystemConfigs();
		await fetchBoundedHealthSystemConfigs();
	};

	const fetchUserHsPreferences = async userPreferences => {
		if (getUserRole() === UserRoles.GUEST) {
			return;
		}
		if (userPreferences.error) {
			return;
		}
		let obj = {};
		userPreferences.teamMemberSettings.forEach(item => {
			if (Object.values(UserSettings).includes(item.settingTypeId)) {
				obj[item.settingTypeId] = item.value;
			}
		});
		dispatch(configurationActionCreators.setUserSettings(obj));
	};

	const updateTree = async (healthSystemId = user.userSession.healthSystem.id, regionId = user.userSession.regionId) => {
		setUpdateTreeError('');
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);

		if (subTreeResponse.error) {
			setUpdateTreeError(`${intl.formatMessage({ id: 'errorOccurred' })} ${subTreeResponse.error.message}`);
			dispatch(devicesActionCreators.setBulkDevicesBusy([]));
			dispatch(devicesActionCreators.setBulkDevicesOnline([]));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy([]));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice([]));
			dispatch(healthSystemsActionCreators.setHealthSystem(null));
			dispatch(healthSystemsActionCreators.setTreeData([]));
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const getNotificationItems = status =>
		notifications &&
		notifications.map(
			notification =>
				notification.isNew === status &&
				![
					NotificationTypes.PATIENT_PRESCRIPTION_CREATED,
					NotificationTypes.PATIENT_PRESCRIPTION_UPDATED,
					NotificationTypes.PATIENT_PRESCRIPTION_DELETED,
					NotificationTypes.PATIENT_PRESCRIPTION_DISABLED,
					NotificationTypes.PATIENT_PRESCRIPTION_MISSED,
				].includes(notification.notificationType) && (
					<li key={notification.id} className={`${notification.isNew ? 'notification-active' : ''}`}>
						{fetchNotificationsItem(notification)}
					</li>
				)
		);

	const hasNewNotification = notifications.some(not => not.isNew);
	const UnreadNotifications = () => (
		<>
			{hasNewNotification && <p className='notifications-title'>{translate('new')}</p>}
			{getNotificationItems(true)}
		</>
	);

	const ReadNotifications = () => (
		<>
			{notifications.length - unreadNotificationsIds.length > 0 && <p className='notifications-title'>{translate('earlier')}</p>}
			{getNotificationItems(false)}
		</>
	);

	const onScroll = event => {
		const isBottom = Math.abs(event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight)) <= 5;
		if (notifications.length < totalNotificationsCount && isBottom && !notificationsLoading) {
			fetchNotifications(indexPage);
			handleUpdateUnreadNotifications();
		}
	};

	useEffect(() => {
		if (isNotificationsOpen) {
			handleUpdateUnreadNotifications();
		}
	}, [isNotificationsOpen, unreadNotificationsIds]);

	const hasAdminConfigs = () => Object.values(configurations.adminConfigurableMenu[getUserRole()]).some(item => item);

	const showConfigItem = key => {
		const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, getUserRole());
		const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());

		const nonConfigurableMenus = [UserRoles.ADMIN, UserRoles.SUPER_ADMIN, UserRoles.PATIENT].includes(getUserRole());
		if (nonConfigurableMenus && getConfigurationValue(configurableMenu[key])) {
			return true;
		}
		if (
			!nonConfigurableMenus &&
			getConfigurationValue(adminConfigurableMenu[key]) &&
			getConfigurationValue(configurableMenu[key])
		) {
			return true;
		}
		return false;
	};

	const showMenuSettings = () => {
		if ([UserRoles.VISITOR, UserRoles.SUPER_ADMIN].includes(getUserRole())) {
			return false;
		}
		return ![UserRoles.NURSE, UserRoles.VIRTUAL_SITTER, UserRoles.DOCTOR].includes(getUserRole()) || hasAdminConfigs();
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleTouchOutsideItem);
		return () => {
			document.removeEventListener('mousedown', handleTouchOutsideItem);
		};
	}, []);

	const openDropdownModal = className => {
		if (isMobileOrTabletDevice()) {
			setShowDropdownOnTouch(className);
		}
	};

	const handleTouchOutsideItem = event => {
		if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
			return;
		}
		setShowDropdownOnTouch('');
	};

	return (
		<section>
			<div
				className={
					props.isMonitoring && showConfigItem(GeneralSettings.MonitoringPrecautionsLegend) && !isNewExperience
						? 'monitoring-header-wrapper'
						: ''
				}>
				{!healthSystems.isFullscreen && !isMonitoringPage && (
					<Header name={props.hasName ? patient.fullname : ''} links={breadcrumb} onBreadcrumbClick={onBreadcrumbClick}>
						<div className={`health-system-actions${getUserRole() === UserRoles.ADMIN ? ' admin-hs-actions' : ''}`}>
							<HealthSystemActions />
						</div>
						<div className='flex'>
							{isMonitoringPage && <div className='flex' />}
							{!isMonitoringPage && (
								<div className='flex-1 flex'>
									{props.isMonitoring && showConfigItem(GeneralSettings.MonitoringPrecautionsLegend) && (
										<div className='monitoring-precautions'>
											{precautions.map(item => (
												<div key={item.id}>
													<i className='material-icons' style={{ color: item.color }}>
														fiber_manual_record
													</i>
													<span>{item.title}</span>
												</div>
											))}
										</div>
									)}
								</div>
							)}

							<nav className='navigation'>
								{getUserRole() === UserRoles.DIGITAL_CLINICIAN && (
									<>
										{user.userSession.checkedInChannelId && (
											<>
												<div className='check-in-badge red' onClick={toggleCheckInOut}>
													<img src={`${healthCareCdnUrl}header/red-check-in.svg`} alt='check in badge icon' />
													<p>{translate('checkOut')}</p>
												</div>
												{!props.isPrimaryCareType && (
													<div className='check-in-badge blue' onClick={() => props.openCheckInNewPatient(null)}>
														<img src={`${healthCareCdnUrl}header/header-new-request.svg`} alt='icon' />
														<p> {translate('checkInPatient')}</p>
													</div>
												)}
											</>
										)}
									</>
								)}
								{getUserRole() === UserRoles.PATIENT && getUserInfo().patientExtendedProfile?.healthSystemId && (
									<div className='header-request-btn' data-cy='requestDoctorHeaderBtn'>
										<Link to='/select-doctor'>
											<Button
												size='small'
												variant='white'
												imgIcon={`${healthCareCdnUrl}header/header-new-request.svg`}
												text={translate('requestDoctor')}
											/>
										</Link>
									</div>
								)}
								{getUserRole() === UserRoles.NURSE && showConfigItem(GeneralSettings.PoolingFlow) && getNursePoolingStatus() && (
									<div
										className='role-wrapper'
										data-cy='assignPoolRole'
										onClick={() => setIsEditPoolingModalOpen(prevState => !prevState)}>
										<span
											style={{ color: getNursePoolingStatus() === '1' ? LightTheme.colors.blueOne : '' }}
											className='material-icons'>
											assignment_ind
										</span>
									</div>
								)}
								{[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE].includes(getUserRole()) && (
									<>
										{showConfigItem(GeneralSettings.ConversationHistory) && (
											<div className='position-relative' onClick={handleConversationsClick} data-cy='messageIcon'>
												<Dropdown
													isPortal={false}
													position='bottom'
													className='notifications-icon-header'
													icon={<Messages color={user.darkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive} />}
													unreadNotificationCount={unReadConversationNotificationsCount}>
													<div className={classNames(user.darkMode ? 'dark-mode' : '', 'dropdown__items')}>
														<ul
															className={`list-group messages-group-notifications list-group-notifications${
																unReadConversationNotificationsCount ? ' list-group-unread' : ''
															}`}>
															<h3>{translate('conversationHistory')}</h3>
															{user.conversationNotifications.length > 0 && (
																<div
																	className='flex mark-all-as-read'
																	onClick={setAllConvosToRead}
																	data-cy='markAllConvosReadBtn'>
																	<img src={`${healthCareCdnUrl}mark-as-read.svg`} alt='icon' />
																	<span>{translate('markAllAsRead')}</span>
																</div>
															)}
															{user.conversationNotifications.length === 0 && (
																<li>
																	<span className='notification-item'>{translate('noConversations')}</span>
																</li>
															)}
															{!recentConversationsError && (
																<>
																	<div>{mapConversationNotifications(false)}</div>
																	<div>{mapConversationNotifications(true)}</div>
																</>
															)}
														</ul>
													</div>
												</Dropdown>
											</div>
										)}
										{showConfigItem(GeneralSettings.Notifications) && (
											<div className='position-relative' onClick={handleNotificationsClick} data-cy='notifications'>
												<Dropdown
													isPortal={true}
													setIsPortalOpen={val => setIsNotificationsOpen(val)}
													position='bottom'
													className='notifications-icon-header'
													icon={<Notifications color={user.darkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive} />}
													unreadNotificationCount={unreadNotificationsCounter}>
													<div className='dropdown__items'>
														<ul
															className={classNames(
																'list-group list-group-notifications overflow-hidden',
																unreadNotificationsCounter?.length > 0 ? ' list-group-unread' : '',
																user.darkMode ? 'dark-list-notifications' : ''
															)}>
															<div className='notifications-list-title'>
																<h3>{translate('notifications')}</h3>
																{notifications.length >= 0 && notificationsError.length === 0 && (
																	<span>
																		{notifications.length}/{totalNotificationsCount}
																	</span>
																)}
															</div>
															{updateNotificationsError && <h3>{updateNotificationsError}</h3>}
															{notificationsError && <h3>{notificationsError}</h3>}
															<div onScroll={onScroll} className='notifications-list'>
																<UnreadNotifications />
																<ReadNotifications />
																{notificationsLoading && (
																	<div className='loader'>
																		<Loader />
																	</div>
																)}
															</div>
															{notifications.length === 0 && !notificationsLoading && notificationsError.length === 0 && (
																<li>
																	<span className='notification-item'>{translate('noNotifications')}</span>
																</li>
															)}
														</ul>
													</div>
												</Dropdown>
											</div>
										)}
										{showConfigItem(GeneralSettings.AiNotifications) && (
											<div className='position-relative' onClick={handleNotificationsClick} data-cy='notifications'>
												<Dropdown
													isPortal={false}
													position='bottom'
													className='notifications-icon-header alerts-notifications'
													icon={<span className='material-icons'>notification_important</span>}
													unreadNotificationCount={newAlertsNotificationsCount}
													toolTip={intl.formatMessage({ id: 'aiNotifications' })}
													toolTipPosition='bottom'>
													<div className='dropdown__items'>
														<AiAlertNotifications
															setNewAlertsNotificationsCount={setNewAlertsNotificationsCount}
															newAlertsNotificationsCount={newAlertsNotificationsCount}
														/>
													</div>
												</Dropdown>
											</div>
										)}
										{showConfigItem(GeneralSettings.PoolingFlow) && (
											<MissedCallsNotifications
												setIsMissedCallsOpen={setIsMissedCallsOpen}
												isMissedCallsOpen={isMissedCallsOpen}
											/>
										)}
									</>
								)}
								<Dropdown
									className='user header-user-dropdown'
									position='bottom'
									stayOpenOnClick={isMobileOrTabletDevice()}
									hasImageWrapper={true}
									title={
										<div>
											<p>{decodeHtml(`${userInfo.firstName} ${userInfo.lastName}`)}</p>
											{getUserRole() && (
												<p className='user-role'>{getConfiguredRoleDisplayName(getUserRole(), companySettings)}</p>
											)}
											{healthSystems && (
												<p>
													<span>
														<i
															className={`material-icons ${
																user.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? '--green-color' : '--red-color'
															}
													`}>
															fiber_manual_record
														</i>
													</span>
												</p>
											)}
										</div>
									}
									imageUrl={buildProfilePicUrl(userInfo.profilePicture, 50)}>
									<div className='dropdown__items'>
										<ul className='list-group' data-cy='listOfItems'>
											<UserRolesList
												ref={wrapperRef}
												switchRole={switchRole}
												showDropdownOnTouch={showDropdownOnTouch}
												openDropdownModal={openDropdownModal}
											/>
											<li ref={wrapperRef} className={showDropdownOnTouch === 'presence' ? showDropdownOnTouch : ''}>
												<span onClick={() => openDropdownModal('presence')}>
													<img
														src={`${healthCareCdnUrl}header/${
															user.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? 'online' : 'offline'
														}-presence.svg`}
														alt='icon'
													/>
													<div className='flex'>
														{translate('changePresence')}
														<span>
															{translate(
																user.presenceStatusTypeId === PresenceStatusType.AVAILABLE
																	? 'statusCurrentlyOnline'
																	: 'statusCurrentlyOffline'
															)}
														</span>
													</div>
													<div className='list-group-wrapper'>
														<ul className='list-group' data-cy='userPresenceOptions'>
															<li className='switch-presence-li'>
																<span onClick={() => onUpdateUserPresence(PresenceStatusType.AVAILABLE)}>
																	<i className='material-icons --green-color'>fiber_manual_record</i>
																	{translate('available')}
																</span>
															</li>
															<li className='switch-presence-li'>
																<span onClick={() => onUpdateUserPresence(PresenceStatusType.UNAVAILABLE)}>
																	<i className='material-icons --red-color'>fiber_manual_record</i>
																	{translate('unavailable')}
																</span>
															</li>
														</ul>
													</div>
												</span>
											</li>
											{showMenuSettings() && (
												<li>
													<span onClick={() => setIsMenuSettingsOpen(true)}>
														<img src={`${healthCareCdnUrl}header/account-settings-new.svg`} alt='icon' />
														<div className='flex'>
															{translate('generalAndMenuSettings')}
															<span> {translate('editMenuSettings')}</span>
														</div>
													</span>
												</li>
											)}
											<li>
												<span onClick={() => props.openCheckInNewPatient('/account-settings')}>
													<img src={`${healthCareCdnUrl}header/account-settings-new.svg`} alt='icon' />
													<div className='flex'>
														{translate('accountSettings')}
														<span> {translate('editChangeAccountInfo')}</span>
													</div>
												</span>
											</li>
											{getUserRole() === UserRoles.DOCTOR && (
												<li ref={wrapperRef} className={showDropdownOnTouch === 'work-flow' ? showDropdownOnTouch : ''}>
													<span onClick={() => openDropdownModal('work-flow')}>
														<img src={`${healthCareCdnUrl}header/workflow.svg`} alt='icon' />
														<div className='flex'>
															{translate('selectMyWorkflow')}
															<span>
																{translate(
																	user.doctorData.workflowTypeId === WorkFlow.DIRECTLY ? 'directWorkflow' : 'throughNurses'
																)}
															</span>
														</div>
														<div className='list-group-wrapper'>
															<ul className='list-group workflow-list-group' data-cy='userPresenceOptions'>
																<li
																	className={classNames(
																		'switch-presence-li',
																		user.doctorData.workflowTypeId === WorkFlow.DIRECTLY ? 'active' : ''
																	)}>
																	<span onClick={() => updateWorkFlow(WorkFlow.DIRECTLY)}>
																		<img
																			src={`${healthCareCdnUrl}header/direct-workflow-${
																				user.doctorData.workflowTypeId === WorkFlow.DIRECTLY ? 'active' : 'normal'
																			}.svg`}
																			alt='icon'
																		/>
																		{translate('directWorkflow')}
																		<div>
																			<i className='material-icons'>done</i>
																		</div>
																	</span>
																</li>
																<li
																	className={classNames(
																		'switch-presence-li',
																		user.doctorData.workflowTypeId === WorkFlow.THROUGH_NURSES ? 'active' : ''
																	)}>
																	<span onClick={() => updateWorkFlow(WorkFlow.THROUGH_NURSES)}>
																		<img
																			src={`${healthCareCdnUrl}header/through-nurses-${
																				user.doctorData.workflowTypeId === WorkFlow.THROUGH_NURSES ? 'active' : 'normal'
																			}.svg`}
																			alt='icon'
																		/>
																		{translate('throughNurses')}
																		<div>
																			<i className='material-icons'>done</i>
																		</div>
																	</span>
																</li>
															</ul>
														</div>
													</span>
												</li>
											)}
											{isMonitoringPage && (
												<li>
													<span onClick={toggleFullScreen}>
														{user.darkMode && (
															<img
																src={
																	isFullscreen
																		? `${healthCareCdnUrl}dark-mode/ExitFullScreen.svg?v2`
																		: `${healthCareCdnUrl}dark-mode/FullScreen.svg?v2`
																}
																alt='icon'
															/>
														)}
														{!user.darkMode && (
															<img
																src={
																	isFullscreen
																		? `${healthCareCdnUrl}footer-icons/ExitFullScreen.svg?v2`
																		: `${healthCareCdnUrl}footer-icons/FullScreen.svg?v2`
																}
																alt='icon'
															/>
														)}
														<div>{translate(isFullscreen ? 'exitFullScreen' : 'fullScreen')}</div>
													</span>
												</li>
											)}
											<li>
												<span onClick={signOut}>
													<img src={`${healthCareCdnUrl}header/sign-out-new.svg`} alt='icon' />
													{translate('logOut')}
												</span>
											</li>
										</ul>
									</div>
								</Dropdown>
							</nav>
						</div>
					</Header>
				)}
				{isEditPoolingModalOpen && (
					<EditPoolingModal toggleEditPoolingModal={() => setIsEditPoolingModalOpen(prevState => !prevState)} isEdit={true} />
				)}
				{patientCallRequests.length > 0 && (
					<CallPatientModal
						toggleCallPatientModal={setIsCallPatientModalOpen(prevState => !prevState)}
						isCallPatientModalOpen={isCallPatientModalOpen}
						patientCallRequests={patientCallRequests}
						stopVoiceOver={stopVoiceOver}
					/>
				)}
				<Alert display={allReadError} fixed={true} onClose={() => setAllReadError('')} message={allReadError} variant='dark' />
				<Alert
					display={appointmentReminderText}
					fixed={true}
					message={appointmentReminderText}
					hideCloseButton={true}
					variant='blue'
				/>
				<Alert
					display={recentConversationsError}
					fixed={true}
					hideCloseButton={true}
					message={recentConversationsError}
					variant='dark'
				/>
				<Alert display={updateTreeError} fixed={true} hideCloseButton={true} message={updateTreeError} variant='dark' />
				<PopUpAlert
					alertType={AlertTypes.WARNING}
					display={error}
					onAlertClose={() => setError('')}
					contentText={error}
					isSilent={true}
					center={true}
				/>
				<PopUpAlert
					alertType={AlertTypes.WARNING}
					display={getUserRole() === UserRoles.DOCTOR && workflowError}
					onAlertClose={() => setWorkflowError('')}
					contentText={translate('youNeedAuthorizedNurses')}
					isSilent={true}
					center={true}
				/>
			</div>
			{isMenuSettingsOpen && <ConfigurableMenu setIsMenuSettingsOpen={() => setIsMenuSettingsOpen(prevState => !prevState)} />}
		</section>
	);
};

export default MainHeader;
