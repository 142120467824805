import { getRoomMembersAndInvitees } from 'api/organization.js';
import { getRegionSubTree } from 'api/tree.js';
import { createInvite, deleteMemberV1M2, updateInviteStatus } from 'api/users.js';
import { enableVisits, getVisitingHours } from 'api/visitingHours.js';
import ConferenceModal from 'calls/views/ConferenceModal.jsx';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import { outGoingCallSound } from 'components/CallSounds.jsx';
import CustomTable from 'components/CustomTable.jsx';
import DisableVisitingHoursModal from 'components/DisableVisitingHoursModal.jsx';
import DisabledCountDown from 'components/DisabledCountDown.jsx';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import Modal from 'components/Modal.jsx';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import {
	CallTypes,
	DeviceListLevel,
	DeviceStatus,
	InviteStatuses,
	InviteTypes,
	ObjectType,
	VisitorType,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { RoomTypes } from 'constants/visitEnums.js';
import AssignFamilyMemberOrVisitor from 'containers/AssignFamilyMemberOrVisitor.jsx';
import translate from 'i18n-translations/translate.jsx';
import { buildTree, findSectorById, getInviteStatus } from 'infrastructure/helpers/commonHelpers.js';
import { convertUTCDateToLocalDateOrTime, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from 'state/healthSystems/actions.js';

const VisitorRoom = props => {
	const [visitorType, setVisitorType] = useState(null);
	const [isRoomLoaded, setIsRoomLoaded] = useState(false);
	const [devices, setDevices] = useState([]);
	const [error, setError] = useState(null);
	const [currentRoom, setCurrentRoom] = useState({
		sectorName: '',
		roomName: '',
		hospitalId: '',
		departmentId: '',
		floorId: '',
		roomId: '',
		sectorType: '',
		type: null,
		solHelloDeviceId: null,
	});
	const [currentTab, setCurrentTab] = useState(0);
	const [roomFamilyMembers, setRoomFamilyMembers] = useState([]);
	const [visitors, setVisitors] = useState([]);
	const intl = useIntl();
	const dispatch = useDispatch();
	const setHealthSystem = hs => dispatch(actionCreators.setHealthSystem(hs));
	const setTreeData = tree => dispatch(actionCreators.setTreeData(tree));
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const [visitingHours, setVisitingHours] = useState([]);
	const [isObserverConferenceModalOpen, setIsObserverConferenceModalOpen] = useState(false);
	const [incomingConferenceInfo, setIncomingConferenceInfo] = useState(null);
	const [isDisableVisitsOpen, setIsDisableVisitsOpen] = useState(false);
	const [observerConferenceData, setObserverConferenceData] = useState({
		participants: [],
		conferenceName: '',
		callType: null,
		roomId: null,
	});
	const pairedRemoteDevices = useSelector(state => state.devices.pairedRemoteDevices);
	const [disabledVisitTime, setDisabledVisitTime] = useState({
		createdAt: new Date(),
		disableTime: 0,
		isDisabled: false,
	});
	const [selectedModal, setSelectedModal] = useState({ type: null, inviteId: null, email: null, memberId: null });
	const [currentMemberId, setCurrentMemberId] = useState(null);

	const visitorHeaders = [
		{ title: translate('name'), id: 'member' },
		{ title: translate('email'), id: 'email' },
		{ title: translate('invitedBy'), id: 'invitedBy' },
		{ title: translate('dateSent'), id: 'invitedDate' },
		{ title: translate('memberStatus'), id: 'memberStatus' },
		{ title: translate('disableAccess'), id: 'disableAccess' },
	];
	const modalTypes = { RESEND: 1, CANCEL: 2 };
	const socket = useContext(SocketContext);

	useEffect(() => {
		fetchTreeData();
	}, [props.match.params.roomId]);

	const fetchVisitingHours = async () => {
		const response = await getVisitingHours(DeviceListLevel.ROOM, props.match.params.roomId);
		if (response.error) {
			setError(response.error.message);
		} else {
			const createdAt = new Date(response.createdAt);
			const localTime = convertUTCDateToLocalDateOrTime(createdAt);
			setDisabledVisitTime({
				createdAt: localTime,
				disableTime: response.disableTime,
				isDisabled: response.isVisitDisabled,
			});
			const result = getInitialVisitingHoursMaped(response.visitingHours);
			const visitingHoursRearragned = reArrangeVisitingHours(result, intl);
			setVisitingHours(visitingHoursRearragned);
		}
	};

	useEffect(() => {
		const fetchRoomMembers = async () => {
			const response = await getRoomMembersAndInvitees(userSession.healthSystem.id, props.match.params.roomId);
			if (response.error) {
				setRoomFamilyMembers([]);
				setVisitors([]);
				setError(response.error.message);
				return;
			}

			setRoomFamilyMembers(response?.roomMembers.filter(item => item.visitorTypeId === VisitorType.FAMILY_MEMBER));
			setVisitors(response?.roomMembers.filter(item => item.visitorTypeId === VisitorType.VISITOR));
		};
		if (userSession.healthSystem.id) {
			fetchRoomMembers();
			fetchVisitingHours();
		}
	}, [userSession.healthSystem.id, props.match.params.hospitalId, props.match.params.roomId]);

	useEffect(() => {
		setCurrentRoom(getCurrentRoom(healthSystems.treeData.tree, props.match.params.roomId));
	}, [healthSystems.treeData.tree, props.match.params.roomId]);

	const hasPairedRemote = helloId => {
		if (!helloId) {
			return false;
		}
		return pairedRemoteDevices.some(id => id === +helloId);
	};

	const getCurrentRoom = (tree, roomId) => {
		let selectedRoom;
		if (tree.length > 0) {
			selectedRoom = findSectorById(tree, roomId);
		}
		return {
			sectorName: selectedRoom ? selectedRoom.name : '',
			roomName: selectedRoom ? selectedRoom.name : '',
			hospitalId: selectedRoom ? selectedRoom.hospitalId : '',
			departmentId: selectedRoom ? selectedRoom.departmentId : '',
			floorId: selectedRoom ? selectedRoom.floorId : '',
			roomId: selectedRoom ? selectedRoom.roomId : '',
			sectorType: selectedRoom ? selectedRoom.type : '',
			hasPairedRemote: hasPairedRemote(selectedRoom?.helloDeviceId),
			isOnline: selectedRoom?.status === DeviceStatus.ONLINE,
			solHelloDeviceId: selectedRoom?.helloDeviceId,
			type: selectedRoom?.roomType,
		};
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			setHealthSystem(healthSystem);
			setTreeData(tree);
		}
		setIsRoomLoaded(true);
	};

	const displayFamilyMembersOrVisitors = list => {
		if (!list || !list.length) {
			return [];
		}
		return list.map(member => ({
			member: member.name || 'N/A',
			email: member.email,
			invitedBy: member.invitedBy,
			invitedDate: monthDayYear(member.inviteDate),
			memberStatus: getInviteStatus(member.memberStatus),
			disableAccess: getEditButtons(member),
		}));
	};

	const onSubmitVisitingHoursSucceed = () => {
		fetchTreeData();
		fetchVisitingHours();
	};

	const onObserverConferenceEnded = () => {
		setIsObserverConferenceModalOpen(false);
		setObserverConferenceData({
			participants: [],
			conferenceName: '',
			callType: null,
			roomId: null,
		});
		setIncomingConferenceInfo(null);
	};

	const callHelloPatient = async () => {
		if (isObserverConferenceModalOpen) {
			return;
		}
		await outGoingCallSound();
		setObserverConferenceData({
			participants: [{ objectId: currentRoom.solHelloDeviceId, objectType: ObjectType.HELLO_DEVICE }],
			conferenceName: currentRoom.roomName,
			callType: CallTypes.VIDEO,
			roomId: currentRoom.roomId,
		});
		setIsObserverConferenceModalOpen(true);
	};

	const handleEnableVisits = async () => {
		if (!disabledVisitTime.isDisabled) {
			return;
		}
		const response = await enableVisits(DeviceListLevel.ROOM, currentRoom.roomId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setDisabledVisitTime(prevState => ({ ...prevState, isDisabled: false }));
		}
	};

	const getEditButtons = ({ inviteId, memberStatus, email, memberId }) => {
		if (memberStatus === InviteStatuses.SENT.type) {
			return (
				<div className='wrapped justify-content-start'>
					<div data-tooltip={intl.formatMessage({ id: 'resendInvite' })} data-position='bottom'>
						<i
							className='material-icons-outlined boxed-icon resend-icon'
							data-cy='resendInviteBtn'
							onClick={() => setSelectedModal({ type: modalTypes.RESEND, inviteId, email, memberId })}>
							email
						</i>
					</div>
					{memberStatus !== InviteStatuses.CANCELLED.type && (
						<div data-tooltip={intl.formatMessage({ id: 'cancelInvite' })} data-position='bottom'>
							<i
								className='material-icons-outlined boxed-icon'
								data-cy='cancelInviteBtn'
								onClick={() => setSelectedModal({ type: modalTypes.CANCEL, inviteId, email, memberId })}>
								remove
							</i>
						</div>
					)}
				</div>
			);
		}
		return (
			<div className='wrapped justify-content-start'>
				&nbsp;
				<i
					className='material-icons-outlined boxed-icon'
					onClick={() => setCurrentMemberId(memberId)}
					data-position='top'
					data-tooltip={intl.formatMessage({ id: 'delete' })}>
					delete
				</i>
			</div>
		);
	};

	const handleDeleteMember = async () => {
		const response = await deleteMemberV1M2(currentMemberId, props.match.params.roomId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setRoomFamilyMembers(prevState => prevState.filter(member => member.memberId !== currentMemberId));
		setVisitors(prevState => prevState.filter(member => member.memberId !== currentMemberId));
		setCurrentMemberId(null);
	};

	const handleResendInvite = async () => {
		const inviteTypeId = currentTab === 0 ? InviteTypes.FAMILY_MEMBER.type : InviteTypes.VISITOR.type;
		const params = {
			users: [{ email: selectedModal.email, inviteTypeId }],
			inviteInfo: {
				channels: [
					{
						id: currentRoom.roomId,
						name: currentRoom.roomName,
						visitorTypeId: currentTab === 0 ? VisitorType.FAMILY_MEMBER : VisitorType.VISITOR,
					},
				],
				teamId: userSession.healthSystem.id,
				teamName: userSession.healthSystem.name,
			},
		};

		const response = await createInvite(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSelectedModal({ type: null, inviteId: null, email: null, memberId: null });
	};

	const handleCancelInvite = async () => {
		const response = await updateInviteStatus(selectedModal.inviteId, InviteStatuses.CANCELLED.type);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (response.hasSucceeded) {
			currentTab === 0
				? setRoomFamilyMembers(prevState => prevState.filter(item => item.inviteId !== selectedModal.inviteId))
				: setVisitors(prevState => prevState.filter(item => item.inviteId !== selectedModal.inviteId));
		}
		setSelectedModal({ type: null, inviteId: null, email: null, memberId: null });
	};

	useEffect(() => {
		const handleVisitingHoursUpdate = ({ createdAt, disableTime, isDisabled, deviceId }) => {
			if (currentRoom && +currentRoom?.solHelloDeviceId === deviceId) {
				setDisabledVisitTime({
					createdAt: new Date(createdAt),
					disableTime,
					isDisabled,
				});
			}
		};
		socket.on(SocketEvents.HealthCare.VISITS_TOGGLE_UPDATED, handleVisitingHoursUpdate);
		return () => {
			socket.off(SocketEvents.HealthCare.VISITS_TOGGLE_UPDATED, handleVisitingHoursUpdate);
		};
	}, [socket, currentRoom]);

	return (
		<div className='users room-wrapper visitors-room-wrapper'>
			{!!isRoomLoaded && (
				<>
					<h3>
						{RoomTypes.BABY_ROOM.type !== currentRoom.type && (
							<Button
								marginRight='var(--spacing-s)'
								text={translate('assignVisitor')}
								variant='white'
								onClick={() => {
									setVisitorType(InviteTypes.VISITOR.type);
								}}
								imgIcon={`${healthCareCdnUrl}admin/assign-visitors.svg`}
							/>
						)}
						<Button
							text={translate('assignFamilyMember')}
							variant='white'
							onClick={() => {
								setVisitorType(InviteTypes.FAMILY_MEMBER.type);
							}}
							imgIcon={`${healthCareCdnUrl}admin/assign-family-members.svg`}
						/>
					</h3>
					{disabledVisitTime.isDisabled && (
						<div className='hello-feature-grid'>
							<p>
								{translate('disabledFor')}:{' '}
								<DisabledCountDown
									disabledTime={disabledVisitTime}
									setDisabledTime={() => setDisabledVisitTime(prevState => ({ ...prevState, isDisabled: false }))}
								/>
							</p>
						</div>
					)}
					<div className='hello-feature-grid top-30 bottom-30'>
						<HelloFeatureBlock
							onClick={() => {
								if (disabledVisitTime.isDisabled) {
									handleEnableVisits();
								} else {
									setIsDisableVisitsOpen(true);
								}
							}}
							title={translate(disabledVisitTime.isDisabled ? 'enableVisits' : 'disableVisits')}
							tooltip='TBD'
							className={!disabledVisitTime.isDisabled ? 'hello-feature-disabled' : 'hello-feature-enabled'}
						/>
						{currentRoom.type !== RoomTypes.BABY_ROOM.type && (
							<HelloFeatureBlock
								onClick={() => callHelloPatient()}
								title={translate('talkToPatient')}
								className='hello-feature-audio'
							/>
						)}
					</div>
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
						<TabList>
							<Tab>{translate('familyMembers')}</Tab>
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && <Tab>{translate('visitors')}</Tab>}
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && <Tab>{translate('visitingHours')}</Tab>}
						</TabList>
						<TabPanels>
							<TabPanel>
								<CustomTable
									headers={visitorHeaders}
									rows={displayFamilyMembersOrVisitors(roomFamilyMembers)}
									className='admin-table'
									isEditable={false}
								/>
							</TabPanel>
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && (
								<TabPanel>
									<CustomTable
										headers={visitorHeaders}
										rows={displayFamilyMembersOrVisitors(visitors)}
										className='admin-table'
										isEditable={false}
									/>
								</TabPanel>
							)}
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && (
								<TabPanel>
									<CustomTable
										headers={[
											{ title: translate('monday'), id: 0 },
											{ title: translate('tuesday'), id: 1 },
											{ title: translate('wednesday'), id: 2 },
											{ title: translate('thursday'), id: 3 },
											{ title: translate('friday'), id: 4 },
											{ title: translate('saturday'), id: 5 },
											{ title: translate('sunday'), id: 6 },
										]}
										rows={visitingHours}
										className='admin-table'
										isEditable={false}
									/>
								</TabPanel>
							)}
						</TabPanels>
					</Tabs>
				</>
			)}
			{!isRoomLoaded && <SkeletonLoader rows={10} padding='20px 0' />}

			{visitorType && (
				<AssignFamilyMemberOrVisitor
					devices={devices}
					setDevices={setDevices}
					toggleAddFamilyMemberModal={() => setVisitorType(null)}
					sectorData={currentRoom}
					setError={setError}
					onSubmitVisitingHoursSucceed={onSubmitVisitingHoursSucceed}
					visitorType={visitorType}
				/>
			)}

			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration
					setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen}
					sector={currentRoom}
					onSubmit={fetchVisitingHours}
				/>
			)}
			{isObserverConferenceModalOpen && (
				<ConferenceModal
					startConferenceData={observerConferenceData}
					incomingConferenceInfo={incomingConferenceInfo}
					onCallStarted={() => null}
					onCallEnded={onObserverConferenceEnded}
					isEmergencyCallOpen={false}
				/>
			)}
			{isDisableVisitsOpen && (
				<DisableVisitingHoursModal
					setIsDisableVisitsOpen={setIsDisableVisitsOpen}
					roomId={currentRoom.roomId}
					setDisabledVisitTime={obj => setDisabledVisitTime(obj)}
				/>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<Modal
				display={currentMemberId != null}
				position='center'
				onModalSubmit={handleDeleteMember}
				onModalClose={() => setCurrentMemberId(null)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureToRemoveMember')}</p>
				</form>
			</Modal>
			<Modal
				modalSelector='cancelInviteModal'
				display={selectedModal.type === modalTypes.CANCEL}
				position='center'
				onModalSubmit={handleCancelInvite}
				onModalClose={() => setSelectedModal({ type: null, inviteId: null, email: null, memberId: null })}>
				<form>
					<h3>{translate('confirmation')}</h3>
					<p>{translate('areYouSureCancelInvitation')}</p>
				</form>
			</Modal>
			<Modal
				modalSelector='resendInviteModal'
				display={selectedModal.type === modalTypes.RESEND}
				position='center'
				onModalSubmit={handleResendInvite}
				onModalClose={() => setSelectedModal({ type: null, inviteId: null, email: null, memberId: null })}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureResendInvitation')}</p>
				</form>
			</Modal>
		</div>
	);
};

export default VisitorRoom;
