import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CareEventTypes } from 'constants/enums.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { WindowSize, StartQueryStringKeys } from 'calls/enums/index.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import { useConference, useConferenceConfigurations } from 'calls/hooks/index.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import translate from 'i18n-translations/translate.jsx';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean }, never>}
 */
const StyledCallEventInformation = styled.div`
	display: flex;
	align-items: center;
	border-left: 1px solid ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayThree)};
	padding-left: var(--spacing-m);
	height: 75%;
	p {
		color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
		font-size: 14px;
		line-height: normal;
		margin: 0;
		padding: 0;
		&:last-of-type {
			margin-left: 3px;
		}
	}
	@media (max-width: ${WindowSize.TABLET}px) {
		display: none;
	}
`;

const CallEventInformation = () => {
	const conference = useConference();
	const conferenceConfigurations = useConferenceConfigurations();
	const userSession = useSelector(state => state.user.userSession);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const [selectedCallEvent, setSelectedCallEvent] = useState(null);

	useEffect(() => {
		const fetchCareEvents = async () => {
			if (!roleRoundingConfigurations[RoundingSettings.RoundingCareEvents]) {
				return;
			}
			const response = await getCareEvents({
				pageIndex: 0,
				pageSize: 20,
				teamCareEventType: CareEventTypes.Rounding,
				healthSystemId: userSession.healthSystem.id,
			});
			let teamCareEvents = [];
			if (!response.error) {
				teamCareEvents = response?.teamCareEvents.map(item => ({ name: item.name, id: item.id })) ?? [];
			}
			const roundingCallType = conference?.additionalData?.find(item => item.key === StartQueryStringKeys.ROUNDING_CALL_TYPE);
			const foundItem = teamCareEvents.find(item => +item.id === +roundingCallType?.value);
			if (foundItem) {
				setSelectedCallEvent(foundItem);
			}
		};
		fetchCareEvents();
	}, []);

	return (
		selectedCallEvent?.id && (
			<StyledCallEventInformation $isDarkMode={conferenceConfigurations.isDarkMode}>
				<p>{translate('careEvent')}: </p>
				<p>{selectedCallEvent.name}</p>
			</StyledCallEventInformation>
		)
	);
};

export default CallEventInformation;
