import { createInvite, getHealthSystemInvitedUsers, getInvitedMembers, updateInviteStatus } from 'api/users.js';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import { Alert, Badge, Button, Input, Modal, MultiSelectCheckboxes } from 'components/index.js';
import { InviteStatuses, InviteTypes, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const InvitedUsersList = props => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const maxSelectItems = 5;

	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [totalCount, setTotalCount] = useState(0);
	const [invitedMembers, setInvitedMembers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [isResendModalOpen, setIsResendModalOpen] = useState(false);
	const [currentInviteId, setCurrentInviteId] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isInviteUsersDropDown, setIsInviteUsersDropDown] = useState(false);
	const [filterValues, setFilterValues] = useState({
		searchValue: '',
		inviteTypes: [],
		healthSystems: [],
	});

	const userRole = getUserRole();

	const inviteTypeOptions = [
		{ value: InviteTypes.ADMIN.type, label: intl.formatMessage({ id: InviteTypes.ADMIN.message }) },
		{
			value: InviteTypes.VIRTUAL_CARE_PROVIDER.type,
			label: intl.formatMessage({ id: InviteTypes.VIRTUAL_CARE_PROVIDER.message }),
		},
		{ value: InviteTypes.VIRTUAL_SITTER.type, label: intl.formatMessage({ id: InviteTypes.VIRTUAL_SITTER.message }) },
		{ value: InviteTypes.SUPER_USER.type, label: intl.formatMessage({ id: InviteTypes.SUPER_USER.message }) },
		{
			value: InviteTypes.ACTIVE_DIRECTORY_INVITE.type,
			label: intl.formatMessage({ id: InviteTypes.ACTIVE_DIRECTORY_INVITE.message }),
		},
		{ value: InviteTypes.DIGITAL_CLINICIAN.type, label: intl.formatMessage({ id: InviteTypes.DIGITAL_CLINICIAN.message }) },
		{ value: InviteTypes.DOCTOR.type, label: intl.formatMessage({ id: InviteTypes.DOCTOR.message }) },
		{ value: InviteTypes.FAMILY_MEMBER.type, label: intl.formatMessage({ id: InviteTypes.FAMILY_MEMBER.message }) },
		{ value: InviteTypes.VISITOR.type, label: intl.formatMessage({ id: InviteTypes.VISITOR.message }) },
	];

	useEffect(() => {
		const fetchData = async () => {
			let response = {};

			const params = {
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				...filterValues,
			};
			if (userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.ADMIN) {
				if (userRole === UserRoles.SUPER_ADMIN) {
					params.inviteTypes.push(InviteTypes.SUPER_ADMIN.type);
				}

				response = await getInvitedMembers(params);
			} else {
				response = await getHealthSystemInvitedUsers({
					healthSystemId: userSession.healthSystem.id,
					...params,
				});
			}

			if (response.error) {
				setErrorMessage(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
			} else {
				setTotalCount(response.totalCount);
				setInvitedMembers(response.companyInvites);
			}
			setIsLoading(false);
		};

		fetchData();
	}, [pagination, userRole, userSession.healthSystem.id, filterValues, intl]);

	const getLocation = invitationInfo => {
		const { channels, teamName } = invitationInfo;
		if (channels && channels.length) {
			return channels.map(location => location.name).join(', ');
		}
		return teamName;
	};

	const displayInvitedUsers = () => {
		if (!invitedMembers.length) return [];

		return invitedMembers.map(user => ({
			id: user.id,
			emailBox: user.email,
			date: formattedDate(user.dateCreated),
			invitedBy: getProfileBox(
				decodeHtml(`${user.sender.companyUser.firstName} ${user.sender.companyUser.lastName}`),
				getUserInfo().userId === user.sender.companyUser.userId
			),
			...(userRole !== UserRoles.SUPER_ADMIN && {
				location: user.invitationInfo ? getLocation(user.invitationInfo) : 'N/A',
			}),
			status: getStatus(user.inviteStatusId),
			...(userRole !== UserRoles.SUPER_ADMIN && { role: getInviteType(user.inviteTypeId) }),
			edit:
				user.inviteStatusId !== InviteStatuses.ACCEPTED.type && getInviteButtons(user.id, user.inviteStatusId, user.inviteTypeId),
		}));
	};

	const getProfileBox = (name, current) => (
		<div className='div-container'>
			<span className={current ? 'bold' : ''}>
				{name} {current && `(${intl.formatMessage({ id: 'you' })})`}
			</span>
		</div>
	);

	const isInviteBtnShown = (inviteStatusId, inviteTypeId) =>
		inviteStatusId !== InviteStatuses.ACCEPTED.type &&
		(userRole === UserRoles.ADMIN || (userRole === UserRoles.SUPER_USER && getInviteType(inviteTypeId) === UserRoles.NURSE));

	const getInviteButtons = (inviteId, inviteStatusId, inviteTypeId) =>
		isInviteBtnShown(inviteStatusId, inviteTypeId) && (
			<div className='wrapped'>
				<div data-tooltip={intl.formatMessage({ id: 'resendInvite' })} data-position='bottom'>
					<i
						className='material-icons-outlined boxed-icon resend-icon'
						data-cy='resendInviteBtn'
						onClick={() => {
							setIsResendModalOpen(true);
							setCurrentInviteId(inviteId);
						}}>
						email
					</i>
				</div>
				{inviteStatusId !== InviteStatuses.CANCELLED.type && (
					<>
						&nbsp;
						<div data-tooltip={intl.formatMessage({ id: 'cancelInvite' })} data-position='bottom'>
							<i
								className='material-icons-outlined boxed-icon'
								data-cy='cancelInviteBtn'
								onClick={() => {
									setIsCancelModalOpen(true);
									setCurrentInviteId(inviteId);
								}}>
								remove
							</i>
						</div>
					</>
				)}
			</div>
		);

	const getInviteType = inviteId => {
		const inviteType = Object.values(InviteTypes).find(obj => obj.type === inviteId);
		return inviteType ? intl.formatMessage({ id: inviteType.message }) : 'N/A';
	};

	const getStatus = inviteStatusId => {
		switch (inviteStatusId) {
			case InviteStatuses.SENT.type:
				return <Badge text={translate('sentOnList')} variant='gray' />;
			case InviteStatuses.ACCEPTED.type:
				return <Badge text={translate('registeredOnList')} variant='green' />;
			case InviteStatuses.PENDING.type:
				return <Badge text={translate('pendingOnList')} variant='gray' />;
			case InviteStatuses.CANCELLED.type:
				return <Badge text={translate('cancelledOnList')} variant='red' />;
			case InviteStatuses.FAILED.type:
				return <Badge text={translate('failedOnList')} variant='red' />;
			default:
				return '';
		}
	};

	const handleCancelInvite = async () => {
		const inviteIndex = invitedMembers.findIndex(invite => invite.id === currentInviteId);

		const response = await updateInviteStatus(currentInviteId, InviteStatuses.CANCELLED.type);
		if (response.error) {
			setErrorMessage(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
		} else {
			setInvitedMembers(prevState => {
				const items = [...prevState];
				const item = {
					...items[inviteIndex],
					inviteStatusId: InviteStatuses.CANCELLED.type,
				};
				items[inviteIndex] = item;
				return items;
			});
			setCurrentInviteId(null);
			setIsCancelModalOpen(false);
		}
	};

	const handleResendInvite = async () => {
		const inviteIndex = invitedMembers.findIndex(invite => invite.id === currentInviteId);
		const invite = invitedMembers.find(i => i.id === currentInviteId);
		const emails = [invite.email];
		const { inviteTypeId } = invite;
		let response;

		if (invite.inviteTypeId === InviteTypes.ADMIN.type) {
			const params = {
				users: emails.map(email => ({ email, inviteTypeId })),
			};
			response = await createInvite(params);
		}

		if (invite.inviteTypeId !== InviteTypes.ADMIN.type) {
			const healthSystems = invite?.invitationInfo?.channels
				? invite.invitationInfo.channels.map(hs => ({ value: hs.id, label: hs.name }))
				: [invite.invitationInfo.teamId];

			const params = {
				users: emails.map(email => ({ email, inviteTypeId })),
				inviteInfo: {
					channels: healthSystems.map(healthSystem => ({ id: healthSystem.value, name: healthSystem.label })),
				},
			};

			response = await createInvite(params);
		}

		if (response.error) {
			setErrorMessage(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
		} else {
			setInvitedMembers(prevState => {
				const items = [...prevState];
				const item = {
					...items[inviteIndex],
					inviteStatusId: InviteStatuses.SENT.type,
				};

				items[inviteIndex] = item;
				return items;
			});

			setCurrentInviteId(null);
			setIsResendModalOpen(false);
		}
	};

	const selectInviteType = id => {
		props.toggleSendInvitationsModal(id);
		setIsInviteUsersDropDown(false);
	};

	const invitedUsersTableHeaders = [
		{ title: translate('email'), id: 'emailBox' },
		{ title: translate('dateSent'), id: 'date' },
		{ title: translate('invitedBy'), id: 'invitedBy' },
		userRole !== UserRoles.SUPER_ADMIN && { title: translate('healthSystem'), id: 'location' },
		{ title: translate('status'), id: 'status' },
		userRole !== UserRoles.SUPER_ADMIN && { title: translate('role'), id: 'role' },
		{ title: '', id: 'edit' },
	].filter(Boolean);

	const getFiltersInitialValues = () => ({
		searchValue: '',
		inviteTypes: [],
		healthSystems: [],
	});

	const handleSearchChanges = values => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setFilterValues({
			searchValue: values.searchValue,
			inviteTypes: values.inviteTypes.map(item => item.value),
			healthSystems: values.healthSystems.map(item => item.value),
		});
	};

	return (
		<div>
			<CustomTable isLoading={isLoading} headers={invitedUsersTableHeaders} rows={isLoading ? [] : displayInvitedUsers()}>
				<div className='flex'>
					<Formik initialValues={getFiltersInitialValues()} onSubmit={handleSearchChanges}>
						{formikProps => {
							const { values, handleSubmit, handleBlur, setFieldValue } = formikProps;
							const handleChange = (name, value) => {
								setFieldValue(name, value);
								handleSubmit();
							};

							return (
								<div className='members-filters flex-1'>
									<Input
										validationOptions={{}}
										type='text'
										placeholder={intl.formatMessage({ id: 'nameOrEmail' })}
										bottomSpace='0'
										variant='filled'
										name='searchValue'
										onChange={event => handleChange('searchValue', event.target.value)}
										maxLength={100}
										value={values.searchValue}
									/>
									{getUserRole() !== UserRoles.SUPER_ADMIN && (
										<>
											{getUserRole() !== UserRoles.SUPER_USER && (
												<MultiSelectCheckboxes
													name='healthSystems'
													selectedOptions={values.healthSystems}
													searchable
													reorderSelected
													options={allHealthSystems.map(item => ({ value: item.id, label: item.name }))}
													placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
													onChange={value => handleChange('healthSystems', value)}
													selectedItemsPlaceholder={intl.formatMessage({ id: 'healthSystems' })}
													onBlur={handleBlur}
													maxSelect={maxSelectItems}
													errorMessage={intl.formatMessage(
														{ id: 'cannotSelectNrMoreThanValue' },
														{ value: maxSelectItems, item: intl.formatMessage({ id: 'healthSystems' }) }
													)}
												/>
											)}
											<MultiSelectCheckboxes
												name='inviteTypes'
												selectedOptions={values.inviteTypes}
												searchable
												reorderSelected
												options={inviteTypeOptions}
												onChange={value => handleChange('inviteTypes', value)}
												placeholder={intl.formatMessage({ id: 'selectRole' })}
												selectedItemsPlaceholder={intl.formatMessage({ id: 'roles' })}
												onBlur={handleBlur}
												maxSelect={maxSelectItems}
												errorMessage={intl.formatMessage(
													{ id: 'cannotSelectNrMoreThanValue' },
													{ value: maxSelectItems, item: intl.formatMessage({ id: 'roles' }) }
												)}
											/>
										</>
									)}
								</div>
							);
						}}
					</Formik>
					{UserRoles.ADMIN === userRole && (
						<div className='position-relative invite-users-wrapper'>
							<Button
								onClick={() => setIsInviteUsersDropDown(prevState => !prevState)}
								text={translate('inviteUsers')}
								imgIcon={`${healthCareCdnUrl}appointments/create-appointment.svg`}
							/>
							{isInviteUsersDropDown && (
								<div className='full-page-input-results' data-cy='symptomResults'>
									{props.inviteType.map(type => (
										<div key={type.inviteTypeId} className='cursor-pointer' onClick={() => selectInviteType(type.inviteTypeId)}>
											<span>{type.name}</span>
										</div>
									))}
								</div>
							)}
						</div>
					)}
					{userRole === UserRoles.SUPER_ADMIN && (
						<Button
							text={translate('addSuperAdmin')}
							horizAlign='end'
							onClick={() => selectInviteType(InviteTypes.SUPER_ADMIN.type)}
						/>
					)}
				</div>
			</CustomTable>
			{!isLoading && (
				<Pagination
					totalCount={totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => {
						setIsLoading(true);
						setPagination({ pageSize, pageIndex });
					}}
				/>
			)}
			<Modal
				modalSelector='cancelInviteModal'
				display={isCancelModalOpen}
				position='center'
				onModalSubmit={() => handleCancelInvite()}
				onModalClose={() => setIsCancelModalOpen(false)}>
				<form>
					<h3>{translate('confirmation')}</h3>
					<p>{translate('areYouSureCancelInvitation')}</p>
				</form>
			</Modal>
			<Modal
				modalSelector='resendInviteModal'
				display={isResendModalOpen}
				position='center'
				onModalSubmit={handleResendInvite}
				onModalClose={() => setIsResendModalOpen(false)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureResendInvitation')}</p>
				</form>
			</Modal>
			<Alert display={errorMessage} fixed hideCloseButton message={errorMessage} variant='dark' />
		</div>
	);
};

export default InvitedUsersList;
