import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getCompanyId, checkIPPermission, setUserRole, getUserRole, isAuthenticated } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import { Alert, Button } from 'components/index.js';
import { DeviceListLevel, HealthcareErrorCode, UserRoles, UserTypes } from 'constants/enums.js';
import {
	buildTree,
	getConfigurationMenu,
	getHealthSystemDevices,
	getIsOldUserExperience,
	getStorage,
	getUserRoleId,
} from 'infrastructure/helpers/commonHelpers.js';
import { getUserHealthSystemPreferences, getUserPreferences, updateSession } from 'api/users.js';
import { useIntl } from 'react-intl';
import { getHealthSystems } from 'api/healthSystems.js';
import { useDispatch } from 'react-redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { getHealthSystemRoleConfigs, getTeamSettings } from 'api/adminConfigurations.js';
import {
	SettingsCategory,
	UserSettingTypes,
	UserSettings,
	buildConfigSettings,
	buildHealthSystemMenuConfigs,
	buildRoleSettings,
	configurableRoundingMenu,
} from 'constants/configurationEnums.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import {
	buildCallSettings,
	buildHealthSystemConfigurations,
	buildVisualsSettings,
} from 'infrastructure/helpers/configurationsHelpers.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import SocketEvents from 'constants/socket-events.js';
import _ from 'lodash';
import { getRegionSubTree } from 'api/tree.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import classNames from 'classnames';

const ContinueAs = () => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const configurations = useSelector(state => state.configurations);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [selectedRole, setSelectedRole] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const setUserSession = session => dispatch(userActionCreators.setUserSession(session));
	const session = useSelector(state => state.user.userSession);

	const switchRole = async () => {
		setIsLoading(true);
		dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(false));
		if (selectedRole?.id !== UserTypes.ADMIN) {
			await checkIPPermission();
		}
		const response = await updateSession({
			healthSystemId: session.healthSystem.id,
			checkedInChannelId: session.checkedInChannelId || null,
			floorId: session.floorId || null,
			departmentId: session.departmentId || null,
			companyId: getCompanyId(),
			roleId: selectedRole?.id,
		});
		setUserRole(selectedRole.name);
		getStorage().setItem('userRole', selectedRole?.name);
		getStorage().setItem('isContinueAs', 'true');
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
		}
		const healthSystemsRes = await getHealthSystems();
		if (healthSystemsRes.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
		} else {
			const selectedHs = healthSystemsRes.find(item => item.id === session.healthSystem.id) || healthSystemsRes[0];
			const configurableMenu = getConfigurationMenu(configurations.configurableMenu, selectedRole.name);

			if (selectedHs) {
				const regionId = selectedHs?.regions[0].id;

				const [configs, navigationResponse] = await Promise.all([
					await getHealthSystemRoleConfigs(selectedHs?.id),
					await getUserPreferences(UserSettingTypes.Navigation),
				]);
				if (navigationResponse.error || configs.error) {
					setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					return;
				}
				await updateTree(selectedHs?.id, regionId);
				if (!navigationResponse.error) {
					const navMenu = {
						...configurations.configurableMenu,
						[selectedRole.name]: buildConfigSettings(navigationResponse, _.cloneDeep(configurableMenu)),
					};
					dispatch(configurationActionCreators.setConfigurableMenu(navMenu));
				}
				if (!configs.error) {
					const hsMenu = await buildHealthSystemMenuConfigs(configs.settings);
					history.push('/');
					dispatch(configurationActionCreators.setAdminConfigurableMenu(hsMenu));
					history.replace({
						...history.location,
						state: { isSwitchRole: true },
					});
					const roundingConfigs = await buildRoleSettings(
						configs.settings,
						configurableRoundingMenu(),
						getUserRoleId(getUserRole())
					);
					dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
				}

				dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
				await fetchHealthSystemConfigurations(selectedHs?.id);

				setUserSession({
					...session,
					healthSystem: selectedHs,
					regionId,
					roleId: selectedRole?.id,
				});
				await fetchUserHsPreferences(selectedHs?.id);

				if (getUserRole() === UserRoles.NURSE) {
					socket.emitWithAck(SocketEvents.Client.ON_SUBSCRIBE_FROM_HS_EVENTS, { healthSystemId: selectedHs?.id });
				}
			}
			dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(true));
		}
		setIsLoading(false);
	};

	const fetchUserHsPreferences = async healthSystemId => {
		if ([UserRoles.GUEST, UserRoles.ADMIN].includes(getUserRole()) || !isAuthenticated()) {
			return;
		}
		const response = await getUserHealthSystemPreferences(healthSystemId);
		if (response.error) {
			return;
		}
		let obj = {};
		response.teamMemberSettings.forEach(item => {
			if (Object.values(UserSettings).includes(item.settingTypeId)) {
				obj[item.settingTypeId] = item.value;
			}
		});
		dispatch(configurationActionCreators.setUserSettings(obj));
	};

	const fetchHealthSystemConfigurations = async healthSystemId => {
		const fetchUnboundHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});
			if (!response.error) {
				const isNewExperience = !getIsOldUserExperience(companyConfigurations, response.settings);
				const healthSystemSettings = {
					callSettings: buildCallSettings(response.settings),
					visualsSettings: buildVisualsSettings(response.settings),
				};
				dispatch(configurationActionCreators.setIsNewExperience(isNewExperience));
				dispatch(configurationActionCreators.setHealthSystemUnboundSettings(healthSystemSettings));
			}
		};

		const fetchBoundedHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.MONITORING, SettingsCategory.PATIENTS],
			});
			if (!response.error) {
				const hsConfigs = buildHealthSystemConfigurations(response.settings);
				dispatch(configurationActionCreators.setHealthSystemConfigs(hsConfigs));
			}
		};

		await fetchUnboundHealthSystemConfigs();
		await fetchBoundedHealthSystemConfigs();
	};

	const updateTree = async (healthSystemId, regionId) => {
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);

		if (subTreeResponse.error) {
			setError(`${intl.formatMessage({ id: 'errorOccurred' })} ${subTreeResponse.error.message}`);
			dispatch(devicesActionCreators.setBulkDevicesBusy([]));
			dispatch(devicesActionCreators.setBulkDevicesOnline([]));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy([]));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice([]));
			dispatch(healthSystemsActionCreators.setHealthSystem(null));
			dispatch(healthSystemsActionCreators.setTreeData([]));
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	return (
		<div className='flex continue-as-wrapper'>
			<img src={`${healthCareCdnUrl}hellocare-logo.svg`} className='form-part-logo-lw' alt='hellocare' />
			<h1>{translate('continueAs')}</h1>
			<div className='flex'>
				{user.userRoles.map(role => (
					<div
						key={role.id}
						className={classNames('item', { active: selectedRole?.id === role.id })}
						onClick={() => setSelectedRole(role)}>
						<img
							src={`${healthCareCdnUrl}user-types/${role.name.toLowerCase()}${selectedRole?.id === role.id ? '-active' : ''}.svg`}
							alt='icon'
						/>
						<h1>{translate(role.name.toLowerCase())}</h1>
						<input
							type='radio'
							name='type-of-account'
							value='patient'
							required
							checked={selectedRole?.id === role.id}
							onClick={() => null}
						/>
					</div>
				))}
			</div>
			{selectedRole?.id && (
				<Button
					isLoading={isLoading}
					onClick={switchRole}
					className={selectedRole?.id ? '' : 'disabled-link'}
					text={translate('select')}
				/>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default ContinueAs;
