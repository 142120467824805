import { companyLevelAdData, getActiveDirectories, getCompanyLevelExists } from 'api/activeDirectory.js';
import { deleteUserLogins } from 'api/adminConfigurations.js';
import { addMemberRole, deleteMemberRole } from 'api/roles.js';
import { deleteMember, getHealthSystemMembers, getMembers } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import Badge from 'components/Badge.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import PinGenerate from 'components/Users/PinGenerate.jsx';
import { WhiteboardSettings } from 'constants/configurationEnums.js';
import { CompanyRoles, MembersType, PresenceStatusType, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AddMemberRole from 'containers/UserManagement/Lists/AddMemberRole.jsx';
import ManageHealthSystems from 'containers/UserManagement/Lists/EditUser/ManageHealthSystems.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import {
	getConfigurationValue,
	getPreferredLanguageLocale,
	getRoleValueById,
	getUserTypeBasedOnCompanyRole,
} from 'infrastructure/helpers/commonHelpers.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Button from 'components/Button.jsx';

const AdMembers = props => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const companySettings = useSelector(state => state.company.companySettings);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [members, setMembers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [tabConfigsLoading, setTabConfigsLoading] = useState(true);
	const [currentMemberObj, setCurrentMemberObj] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
	const [roleToDelete, setRoleToDelete] = useState(null);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [companyLevelExists, setCompanyLevelExists] = useState(false);
	const [selectedAdConfig, setSelectedAdConfig] = useState(null);
	const [hideDeleteBtn, setHideDeleteBtn] = useState(false);
	const [hideUserActions, setHideUserActions] = useState(false);
	const [isInviteUsersDropDown, setIsInviteUsersDropDown] = useState(false);
	const [latestSearch, setLatestSearch] = useState('');
	const [pinGenerateMember, setPinGenerateMember] = useState(null);

	const usersTableHeaders = [
		{
			title: intl.formatMessage({ id: 'userName' }),
			id: 'profileBox',
			sticky: true,
		},
		{
			title: intl.formatMessage({ id: 'email' }),
			id: 'email',
		},
		{
			title: intl.formatMessage({ id: 'dateAdded' }),
			id: 'dateAdded',
		},
		getUserRole() !== UserRoles.SUPER_ADMIN && {
			title: intl.formatMessage({ id: 'healthSystems' }),
			id: 'healthSystem',
			columnClass: 'normal-wrap',
		},
		{
			title: intl.formatMessage({ id: 'status' }),
			id: 'status',
		},
		getUserRole() !== UserRoles.SUPER_ADMIN && {
			title: intl.formatMessage({ id: 'role' }),
			id: 'role',
			columnClass: 'normal-wrap',
		},
		{ title: '', id: 'edit' },
	].filter(Boolean);

	useEffect(() => {
		const fetchActiveDirectories = async () => {
			const formData = {
				pageSize: 10,
				pageIndex: 0,
				healthSystemId: userSession.healthSystem.id,
				hospitalId: null,
			};
			const response = await getActiveDirectories(formData);
			if (!response.error) {
				const selectedAdConfig =
					response.activeDirectories.length > 0
						? response.activeDirectories[0]
						: { adConfigurationTypeId: 1, autoSyncOnLogin: false };
				setSelectedAdConfig(selectedAdConfig);
				setHideUserActions(selectedAdConfig.autoSyncOnLogin);
			} else {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			}
		};

		const fetchCompanyLevelExist = async () => {
			const params = { usersHealthSystemId: userSession.healthSystem.id };
			const response = await getCompanyLevelExists(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setCompanyLevelExists(response.exists);
				if (response.exists) {
					const companyLevelAdDataRes = await companyLevelAdData(params);
					if (companyLevelAdDataRes) {
						setHideDeleteBtn(companyLevelAdDataRes.autoDelete);
					}
				}
			}
		};

		fetchActiveDirectories();
		fetchCompanyLevelExist();
		setTabConfigsLoading(false);
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		const fetchMembers = async () => {
			const params = {
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				searchValue: latestSearch,
				filterType: MembersType.AD_MEMBERS,
			};

			const response =
				UserRoles.SUPER_USER !== getUserRole()
					? await getMembers(params)
					: await getHealthSystemMembers({
							healthSystemId: userSession.healthSystem.id,
							...params,
						});
			if (response.error) {
				setError(response.error.message);
			} else {
				setMembers(response.members);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			}
			setIsLoading(false);
		};

		fetchMembers();
	}, [pagination.pageIndex, latestSearch, userSession.healthSystem.id, pagination.pageSize]);

	useEffect(() => {
		setIsLoading(true);
		const delayDebounceFn = setTimeout(() => {
			setLatestSearch(searchValue);
			setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [searchValue]);

	const handleDeleteMember = async () => {
		setIsLoading(true);
		setIsBtnLoading(true);
		const [deleteUserResponse, deleteMemberResponse] = await Promise.all([
			deleteUserLogins(currentMemberObj.userInfo?.id, selectedAdConfig.id),
			deleteMember(currentMemberObj.memberId),
		]);
		const responseError = deleteUserResponse.error || deleteMemberResponse.error;
		if (responseError || !deleteUserResponse.HasSucceed) {
			setError(responseError?.message || translate('somethingWentWrong'));
		}
		if (deleteMemberResponse.error) {
			setError(deleteMemberResponse.error.message);
		} else {
			setMembers(prevSate => prevSate.filter(member => member.memberId !== currentMemberObj.memberId));
			setIsDeleteModalOpen(false);
		}
		setIsBtnLoading(false);
		setIsLoading(false);
		setCurrentMemberObj(null);
	};

	const getRoles = user => {
		if (!user.roles) {
			return 'N/A';
		}
		const result = user.roles.map(role => (
			<div key={role.id} className='badge blue'>
				{getRoleValueById(getUserTypeBasedOnCompanyRole(role.id))}
				{!(getUserRole() === UserRoles.SUPER_USER && user.healthSystems.length > 1) && user.roles.length > 1 && (
					<i className='material-icons remove-role-btn' onClick={() => openDeleteRoleModal(role, user)}>
						close
					</i>
				)}
			</div>
		));
		return result.length > 0 ? result : 'N/A';
	};

	const showEditButton = (roles = []) => {
		return roles.some(role =>
			[
				CompanyRoles.DOCTOR,
				CompanyRoles.VIRTUAL_CARE_PROVIDER,
				CompanyRoles.DIGITAL_CLINICIAN,
				CompanyRoles.VIRTUAL_SITTER,
			].includes(role.id)
		);
	};

	const showDeleteButton = (roles = []) => {
		const restrictedRoles =
			{
				[UserRoles.ADMIN]: [CompanyRoles.OWNER],
				[UserRoles.SUPER_USER]: [CompanyRoles.OWNER, CompanyRoles.ADMIN],
			}[getUserRole()] || [];

		return !roles.some(role => restrictedRoles.includes(role.id)) && !hideDeleteBtn && !hideUserActions;
	};

	const shouldShowAddRoleButton = roles =>
		!roles || roles.length >= 5
			? false
			: roles.some(role =>
					[
						CompanyRoles.DOCTOR,
						CompanyRoles.VIRTUAL_CARE_PROVIDER,
						CompanyRoles.DIGITAL_CLINICIAN,
						CompanyRoles.ADMIN,
						CompanyRoles.SUPER_USER,
					].includes(role.id)
				) && !hideUserActions;

	const displayUsers = () => {
		if (members.length === 0) return [];
		return members.map(user => {
			const currentUser = getUserInfo().userId === user.userInfo.userId;
			const isUserAvailable = user.userInfo?.presenceStatusTypeId === PresenceStatusType.AVAILABLE;
			return {
				id: user.userInfo.id,
				profileBox: (
					<div className='div-container'>
						<span className={currentUser ? 'bold' : ''}>
							{`${user.userInfo.firstName} ${user.userInfo.lastName}`} {currentUser && `(${intl.formatMessage({ id: 'you' })})`}
						</span>
					</div>
				),
				email: user.userInfo.email,
				dateAdded: user.dateAdded
					? moment.utc(user.dateAdded).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
					: 'N/A',
				...(getUserRole() !== UserRoles.SUPER_ADMIN && {
					healthSystem:
						user.healthSystems.length > 0
							? user.healthSystems.map((hs, index) => (
									<span>
										{' '}
										{hs.name?.trimEnd()}
										{user.healthSystems.length !== index + 1 && ','}
									</span>
								))
							: 'N/A',
				}),
				status: (
					<Badge
						text={isUserAvailable ? translate('available') : translate('unavailable')}
						variant={isUserAvailable ? 'green' : 'red'}
					/>
				),
				...(getUserRole() !== UserRoles.SUPER_ADMIN && {
					role: getRoles(user),
				}),
				edit: !currentUser && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && getActionButtons(user),
			};
		});
	};

	const getActionButtons = member => {
		return (
			<div className='wrapped'>
				{shouldShowAddRoleButton(member.roles) && (
					<i
						className='material-icons-outlined boxed-icon add-icon'
						data-tooltip={intl.formatMessage({ id: 'addARole' })}
						data-position='top'
						onClick={() => openAddRoleModal(member)}>
						add
					</i>
				)}
				{showEditButton(member.roles) && !hideUserActions && (
					<i
						className='material-icons-outlined boxed-icon edit-icon'
						onClick={() => openEditMemberModal(member)}
						data-position='top'
						data-tooltip={intl.formatMessage({ id: 'edit' })}>
						edit
					</i>
				)}
				{getConfigurationValue(companySettings?.companyConfigurations[WhiteboardSettings.PIN]) &&
					showEditButton(member.roles) && (
						<i
							className='material-symbols-outlined boxed-icon --orange-background'
							onClick={() => setPinGenerateMember(member.userInfo?.id)}
							data-position='top'
							data-tooltip='PIN'>
							passkey
						</i>
					)}
				{showDeleteButton(member.roles) && (
					<i
						className='material-icons-outlined boxed-icon'
						onClick={() => openDeleteMemberModal(member)}
						data-position='top'
						data-tooltip={intl.formatMessage({ id: 'delete' })}>
						delete
					</i>
				)}
			</div>
		);
	};

	const openEditMemberModal = member => {
		setCurrentMemberObj(member);
		setIsEditMemberModalOpen(true);
	};

	const openDeleteMemberModal = member => {
		setCurrentMemberObj(member);
		setIsDeleteModalOpen(true);
	};

	const updateMemberHealthSystems = healthSystems =>
		setMembers(currentMembers => {
			const memberIndex = currentMembers.findIndex(member => member.memberId === currentMemberObj.memberId);
			if (memberIndex === -1) return currentMembers;
			const updatedMembers = [...currentMembers];
			updatedMembers[memberIndex] = {
				...updatedMembers[memberIndex],
				healthSystems,
			};
			return updatedMembers;
		});

	const openAddRoleModal = member => {
		setCurrentMemberObj(member);
		setIsAddRoleModalOpen(true);
	};

	const handleAddMemberRole = async (data, { resetForm }) => {
		setIsBtnLoading(true);
		setIsLoading(true);
		const params = {
			companyId: getCompanyId(),
			memberId: currentMemberObj.memberId,
			role: data.selectedRole?.value,
			teamId: data.healthSystem ? data.healthSystem.value : null,
		};
		const addRoleResponse = await addMemberRole(params);
		if (addRoleResponse.error) {
			setError(addRoleResponse.error.message);
		} else {
			setMembers(currentMembers => {
				const memberIndex = currentMembers.findIndex(member => member.memberId === currentMemberObj.memberId);
				if (memberIndex === -1) return currentMembers;
				const updatedMembers = [...currentMembers];
				updatedMembers[memberIndex] = {
					...updatedMembers[memberIndex],
					roles: [...updatedMembers[memberIndex].roles, { id: data.selectedRole.value, name: data.selectedRole.label.trim('') }],
					...(data.healthSystem && { healthSystems: data.healthSystem }),
				};

				return updatedMembers;
			});
			if (data.healthSystem) {
				updateMemberHealthSystems([{ id: data.healthSystem.value, name: data.healthSystem.label }]);
			}
			resetForm();
		}
		setIsAddRoleModalOpen(false);
		setIsLoading(false);
		setIsBtnLoading(false);
		setCurrentMemberObj(null);
	};

	const openDeleteRoleModal = (role, member) => {
		setCurrentMemberObj(member);
		setRoleToDelete(role);
	};

	const handleDeleteMemberRole = async () => {
		setIsBtnLoading(true);
		setIsLoading(true);
		const params = {
			companyId: getCompanyId(),
			memberId: currentMemberObj.memberId,
			roleId: roleToDelete.id,
		};
		const deleteRoleResponse = await deleteMemberRole(params);
		if (deleteRoleResponse.error) {
			setError(deleteRoleResponse.error.message);
		} else {
			setMembers(currentMembers => {
				const memberIndex = currentMembers.findIndex(member => member.memberId === currentMemberObj.memberId);
				if (memberIndex === -1) return currentMembers;
				const updatedMembers = [...currentMembers];
				const updatedRoles = updatedMembers[memberIndex].roles.filter(role => role.id !== roleToDelete.id);
				updatedMembers[memberIndex] = {
					...updatedMembers[memberIndex],
					roles: updatedRoles,
					healthSystems:
						updatedRoles.length === 1 && updatedRoles[0].name === UserRoles.ADMIN
							? []
							: updatedMembers[memberIndex].healthSystems,
				};

				return updatedMembers;
			});
		}
		setIsBtnLoading(false);
		setIsLoading(false);
		setRoleToDelete(null);
		setCurrentMemberObj(null);
	};

	const selectInviteType = id => {
		props.toggleSendInvitationsModal(id, selectedAdConfig.adConfigurationTypeId);
		setIsInviteUsersDropDown(false);
	};

	const isUsersLoading = () => isLoading && tabConfigsLoading;

	return (
		<div>
			<CustomTable isLoading={isUsersLoading()} headers={usersTableHeaders} rows={isUsersLoading() ? [] : displayUsers()}>
				<div className='flex flex-align-center'>
					<Input
						validationOptions={{}}
						type='text'
						placeholder={intl.formatMessage({ id: 'search' })}
						bottomSpace='0'
						variant='filled flex-1'
						name='searchUsers'
						onChange={event => setSearchValue(event.target.value)}
						maxLength={100}
						inputWidth='300px'
					/>
					{UserRoles.ADMIN === getUserRole() && companyLevelExists && (
						<div className='position-relative invite-users-wrapper'>
							<Button
								onClick={() => setIsInviteUsersDropDown(prevState => !prevState)}
								text={translate('inviteUsers')}
								imgIcon={`${healthCareCdnUrl}appointments/create-appointment.svg`}
							/>
							{isInviteUsersDropDown && (
								<div className='full-page-input-results' data-cy='symptomResults'>
									{props.inviteType.map(type => (
										<div key={type.inviteTypeId} className='cursor-pointer' onClick={() => selectInviteType(type.inviteTypeId)}>
											<span>{type.name}</span>
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			</CustomTable>
			{!isLoading && (
				<Pagination
					totalCount={pagination.totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => setPagination(prevState => ({ ...prevState, pageIndex, pageSize }))}
				/>
			)}
			{getConfigurationValue(companySettings?.companyConfigurations[WhiteboardSettings.PIN]) && (
				<PinGenerate memberId={pinGenerateMember} onModalClose={() => setPinGenerateMember(null)} />
			)}
			<Modal
				display={isDeleteModalOpen}
				position='center'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={() => handleDeleteMember()}
				onModalClose={() => {
					setCurrentMemberObj(null);
					setIsDeleteModalOpen(false);
				}}
				primaryButtonLoading={isBtnLoading}>
				<form>
					<h3>{translate('deleteMember')}</h3>
					<p>{translate('areYouSureDeleteMember')}</p>
				</form>
			</Modal>
			{currentMemberObj !== null && isEditMemberModalOpen && (
				<ManageHealthSystems
					member={currentMemberObj}
					updateMemberHealthSystems={updateMemberHealthSystems}
					healthSystems={props.healthSystems}
					modalSelector='editMemberModal'
					display={isEditMemberModalOpen}
					className='admin-page-modal'
					position='right'
					onModalClose={() => {
						setCurrentMemberObj(null);
						setIsEditMemberModalOpen(false);
					}}
				/>
			)}
			<AddMemberRole
				isAddRoleModalOpen={isAddRoleModalOpen}
				onAddMemberRole={handleAddMemberRole}
				onModalClose={() => {
					setCurrentMemberObj(null);
					setIsAddRoleModalOpen(false);
				}}
				healthSystems={props.healthSystems}
				isBtnLoading={isBtnLoading}
				currentMemberObj={currentMemberObj}
			/>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				display={roleToDelete}
				position='center'
				onModalSubmit={handleDeleteMemberRole}
				onModalClose={() => {
					setCurrentMemberObj(null);
					setRoleToDelete(null);
				}}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={intl.formatMessage({ id: 'yes' })}
				primaryButtonLoading={isBtnLoading}>
				<Form height={200} className='modal-form-wrapper'>
					<h3>{translate('deleteRole')}</h3>
					<p>{translate('areYouSureDeleteRole')}</p>
				</Form>
			</Modal>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</div>
	);
};

export default AdMembers;
