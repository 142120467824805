import { deleteTranslationProvider, getOrganizationTranslationProviders } from 'api/translationServices.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Grid from 'components/Grid.jsx';
import Modal from 'components/Modal.jsx';
import { UserRoles } from 'constants/enums.js';
import TranslationServicesForm from 'containers/Configurations/TranslationServicesForm.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const TranslationServices = props => {
	const intl = useIntl();
	const role = getUserRole();
	const [translationProviders, setTranslationProviders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editConfig, setEditConfig] = useState(null);
	const [currentTranslation, setCurrentTranslation] = useState(null);

	const [total, setTotal] = useState(0);
	const [hasDeleteError, setDeleteError] = useState(false);

	const getHeaders = () => [
		{ id: 'healthSystem', title: translate('healthSystem') },
		{ id: 'providerName', title: translate('providerName') },
		{ id: 'baseURL', title: translate('baseURL') },
		{ id: 'userCreated', title: translate('createdBy') },
		{ id: 'dateCreated', title: translate('dateCreated') },
		{ id: 'delete', title: '' },
	];

	const onPaginationChange = (pageSize, pageIndex) => {
		setIsLoading(true);
		props.onPaginationChange(pageSize, pageIndex);
	};

	const transformRows = () => {
		if (translationProviders.length === 0) {
			return [];
		}
		return translationProviders.map(item => ({
			healthSystem: item.team.name,
			providerName: item.translationProvider.providerName,
			baseURL: item.translationProvider.baseUrl,
			userCreated: item.userCreated.email,
			dateCreated: formattedDate(item.dateCreated),
			delete: getEditButtons(item),
		}));
	};

	const getEditButtons = translationProvider => (
		<div className='wrapped'>
			&nbsp;
			<i
				className='material-icons-outlined boxed-icon'
				onClick={() => setCurrentTranslation(translationProvider)}
				data-position='top'
				data-tooltip={intl.formatMessage({ id: 'delete' })}>
				delete
			</i>
		</div>
	);

	const transformArray = array => {
		const newArray = array.map(item => ({ value: item.id, label: item.name }));
		if (role === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const onHealthSystemSelect = healthSystem => {
		props.setSelectedHealthSystem(healthSystem);
	};

	const fetchTranslationServices = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pageSize,
			pageIndex: props.pageIndex,
			healthSystemId: +props.selectedHealthSystem.value === 0 ? null : props.selectedHealthSystem.value,
		};
		const response = await getOrganizationTranslationProviders(formData);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setTranslationProviders(response.teamTranslationProviders);
		setTotal(response.total);
		setIsLoading(false);
	}, [props.pageIndex, props.pageSize, props.selectedHealthSystem.value]);

	const handleDelete = async () => {
		setIsLoading(true);
		const response = await deleteTranslationProvider(currentTranslation.id, currentTranslation.team.id);
		if (response.error) {
			setIsLoading(false);
			setDeleteError(response.error.message);
			return;
		}
		setTranslationProviders(prevState => prevState.filter(item => item.id !== currentTranslation.id));
		setCurrentTranslation(null);
		setIsLoading(false);
	};

	const toggleModal = () => {
		setIsModalOpen(prevState => !prevState);
		setEditConfig(null);
	};

	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;

	useEffect(() => {
		fetchTranslationServices();
	}, [fetchTranslationServices]);

	return (
		<div>
			<CustomTable isLoading={isLoading} headers={getHeaders()} rows={isLoading ? [] : transformRows()}>
				<Grid columns='1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={translate('all')}
						classNamePrefix='custom-select'
						options={transformArray(healthSystems)}
						components={{ DropdownIndicator }}
						onChange={onHealthSystemSelect}
						isDisabled={role === UserRoles.SUPER_USER}
					/>
					{translationProviders.length === 0 && (
						<Button
							onClick={() => setIsModalOpen(prevState => !prevState)}
							horizAlign='end'
							text={translate('addConfiguration')}
							className='text-transform-none'
						/>
					)}
				</Grid>
			</CustomTable>
			<Pagination
				totalCount={total}
				pageSize={props.pageSize}
				pageIndex={props.pageIndex}
				onChange={(pageSize, pageIndex) => onPaginationChange(pageSize, pageIndex)}
			/>
			{isModalOpen && (
				<TranslationServicesForm
					activeTab={props.activeSubTab}
					isModalOpen={isModalOpen}
					isSuperUser={role === UserRoles.SUPER_USER}
					healthSystems={healthSystems.map(item => ({ id: item.id, value: item.name }))}
					toggleModal={toggleModal}
					initialValues={editConfig}
					onSucceeded={fetchTranslationServices}
				/>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<Modal
				display={currentTranslation != null}
				position='center'
				onModalSubmit={handleDelete}
				isLoading={isLoading}
				onModalClose={() => setCurrentTranslation(null)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureRemoveTranslation')}</p>
					{hasDeleteError && <p className='error'>{translate('somethingWentWrong')}</p>}
				</form>
			</Modal>
		</div>
	);
};

export default TranslationServices;
