import React, { Fragment, useContext, useEffect, useState } from 'react';
import { enums } from '@solaborate/calls';
import Telemetry from 'calls/icons/Telemetry.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import { HealthDataSetupTypes, MeasureDeviceType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import PulseEcg from 'icons/Monitoring/PulseEcg.jsx';
import Watch from 'icons/Monitoring/Watch.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { actionCreators as healthDataActionCreators } from 'state/healthData/actions.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button.jsx';

const TelemetryItems = props => {
	const socketFunctions = useContext(SocketFunctionsContext);
	const [isWatchEnabled, setIsWatchEnabled] = useState(false);
	const [expandedBox, setExpandedBox] = useState(true);
	const [setupItemsToShow, setSetupItemsToShow] = useState([
		{
			id: 0,
			label: 'Biobeat',
			img: <PulseEcg color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.graySix} />,
			checked: props.isBioBeatActive,
			type: HealthDataSetupTypes.BIO_BEAT,
			show: props.biobeatWearable && props.healthDataConfigurableMonitoring[MonitoringSettings.BiobeatVitalSigns],
		},
		{
			id: 1,
			label: 'Watch',
			img: <Watch color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.graySix} />,
			checked: isWatchEnabled,
			type: HealthDataSetupTypes.WATCH_MEASUREMENTS,
			show: props.healthDataConfigurableMonitoring[MonitoringSettings.SmartWatchVitalSigns],
		},
		{
			id: 2,
			label: 'LifeSignals',
			img: <PulseEcg color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.graySix} />,
			checked: props.isLifeSignalsActive,
			type: HealthDataSetupTypes.LIFE_SIGNALS,
			show: props.lifeSignalsWearable && props.healthDataConfigurableMonitoring[MonitoringSettings.LifeSignals],
		},
	]);

	const dispatch = useDispatch();
	const toggleWatchMeasurements = watchMeasurements => dispatch(healthDataActionCreators.setWatchMeasurements(watchMeasurements));
	const watchMeasurements = useSelector(state => state.watchMeasurementsList.watchMeasurementsVisible);

	useEffect(() => {
		const selectedValue = watchMeasurements.find(item => item.deviceId === props.deviceId)?.value;
		setIsWatchEnabled(selectedValue);
	}, [watchMeasurements, props.deviceId]);

	useEffect(() => {
		setSetupItemsToShow([
			{
				id: 0,
				label: 'Biobeat',
				img: <PulseEcg color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.graySix} />,
				checked: props.isBioBeatActive,
				type: HealthDataSetupTypes.BIO_BEAT,
				show: props.biobeatWearable && props.healthDataConfigurableMonitoring[MonitoringSettings.BiobeatVitalSigns],
			},
			{
				id: 1,
				label: 'Watch',
				img: <Watch color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.graySix} />,
				checked: isWatchEnabled,
				type: HealthDataSetupTypes.WATCH_MEASUREMENTS,
				show: props.healthDataConfigurableMonitoring[MonitoringSettings.SmartWatchVitalSigns],
			},
			{
				id: 2,
				label: 'LifeSignals',
				img: <PulseEcg color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.graySix} />,
				checked: props.isLifeSignalsActive,
				type: HealthDataSetupTypes.LIFE_SIGNALS,
				show: props.lifeSignalsWearable && props.healthDataConfigurableMonitoring[MonitoringSettings.LifeSignals],
			},
		]);
	}, [
		isWatchEnabled,
		props.biobeatWearable,
		props.healthDataConfigurableMonitoring,
		props.isBioBeatActive,
		props.isDarkMode,
		props.isLifeSignalsActive,
		props.lifeSignalsWearable,
	]);

	const handleOnClick = type => {
		switch (type) {
			case HealthDataSetupTypes.BIO_BEAT:
				props.toggleBioBeat(props.deviceId, !props.isBioBeatActive, props.biobeatWearable?.iotDeviceId);
				break;
			case HealthDataSetupTypes.WATCH_MEASUREMENTS:
				toggleWatch(!isWatchEnabled);
				break;
			case HealthDataSetupTypes.LIFE_SIGNALS:
				props.toggleLifeSignals(props.deviceId, !props.isLifeSignalsActive);
				break;
			default:
				break;
		}
	};

	const toggleWatch = startMeasure => {
		socketFunctions.startMeasuringIoTDevice({
			iotDevice: MeasureDeviceType.WATCH,
			helloDeviceId: props.patientUserId,
			conferenceId: props.conferenceId,
			participantId: props.participantId,
			iotDeviceType: '',
			objectType: enums.ObjectTypes.USER,
			measureDeviceType: MeasureDeviceType.WATCH,
			startMeasure,
			doctorId: getUserInfo().userId,
			deviceId: props.deviceId,
		});
		toggleWatchMeasurements({ value: startMeasure, deviceId: props.deviceId });
		setIsWatchEnabled(prevState => !prevState);
	};

	return (
		<div className='monitoring-timeline-box patient-out-of-room telemetry-box'>
			<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
				<p className='timeline-box-title flex-1 telemetry-box-title'>
					<Telemetry color='white' />
					{translate('telemetry')}
				</p>

				<div className='timeline-box-actions'>
					<Button
						border='none'
						style={{ top: 0 }}
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>
			{expandedBox && (
				<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
					{setupItemsToShow.map(
						item =>
							item.show && (
								<Fragment key={item.id}>
									<div className='flex flex-align-center'>
										{item.img}
										<p className='timeline-box-title flex-1 left-s'>{item.label}</p>
										<div className='toggle-switch' onClick={() => handleOnClick(item.type)}>
											<input type='checkbox' checked={item.checked} onChange={() => null} />
											<span className='toggle-body'>
												<span className='on-text'>{translate('on')}</span>
												<span className='off-text'>{translate('off')}</span>
											</span>
										</div>
									</div>
									<br />
								</Fragment>
							)
					)}
				</div>
			)}
		</div>
	);
};

export default TelemetryItems;
