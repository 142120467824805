import Emitter from 'owt/p2p/Emitter.js';
import SignalingChannel from 'owt/p2p/sc.websocket.js';
import { P2PClient, P2PClientConfiguration } from 'owt/p2p/p2pclient.js';
import { Offer } from 'owt/base/conference.js';
import { LocalStream, StreamSourceInfo } from 'owt/base/stream.js';
import {
	AudioTrackConstraints,
	MediaStreamFactory,
	StreamConstraints,
	VideoTrackConstraints,
} from 'owt/base/mediastream-factory.js';
import { AudioSourceInfo, VideoSourceInfo } from 'owt/base/mediaformat.js';
import SocketEvents from 'constants/socket-events.js';
import {
	CallTypes,
	ParticipantState,
	StreamError,
	ConferenceEndReason,
	SocketState,
	RTCPeerConnectionEnum,
	DeviceCommands,
	JoinConferenceFailureReasonEnum,
	StreamTypes,
	ObjectType,
	AudioOutputDevice,
	ParticipantRemoveReason,
	TerminateRequestFailureReasonEnum,
	MeasureDeviceType,
	CallStatsLogType,
} from 'constants/enums.js';
import { getIceServers } from 'api/iceServers.js';
import { busySound, dropSound, outGoingCallSound, stopOutgoingCallSound } from 'components/CallSounds.jsx';
import CallStats from 'infrastructure/callStats/callStats.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { enums } from '@solaborate/calls';

class CallManager extends Emitter {
	constructor(socket, options) {
		super();

		this.participants = {};
		this.socket = socket;
		this.p2p = null;
		this.outGoingCallTimer = {};
		this._callStats = new CallStats();
		this._useCallStats = options.useCallStats;
		this._sendCallStatsInterval = options.sendCallStatsInterval;
		this._doCloseSocket = options.doCloseSocket ?? true;
		this.reconnectTimer = null;
		this.cameraEventInProgress = false;
		this.cameraEventTimeout = null;
		this.endingCall = false;
		this.callStartedOrJoined = false;
		this.callerParticipantId = null;
		this.socketListeners = {};

		this.reAddToMonitoringIntervals = new Map();
		this.feedRetryCount = new Map();
	}

	bindOn = (event, cb) => {
		const checkConferenceCallback = data => {
			if (data?.conferenceId && this.conferenceInfo?.conferenceId && data.conferenceId !== this.conferenceInfo.conferenceId) {
				return;
			}

			cb(data);
		};
		this.socketListeners[event] = checkConferenceCallback;
		this.socket.on(event, checkConferenceCallback);
		return this;
	};

	unbindTimeouts = () => {
		if (this.cameraEventTimeout) {
			clearTimeout(this.cameraEventTimeout);
		}
	};

	/**
	 * Method to send payload to signaling to invite new device
	 * @typedef FailedInvitation
	 * @property {string} id
	 * @property {number} objectId
	 * @property {number} objectType
	 * @property {string} reason
	 * @typedef {{ objectId: number, objectType: number }} InviteParticipantData
	 * @param {Object} data
	 * @param {Object} data.participant
	 * @param {number} data.participant.objectId
	 * @param {number} data.participant.objectType
	 * @param {boolean} data.participant.isAmbient
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @returns {Promise<{failedInvitationToParticipants: FailedInvitation[]}>}
	 *
	 */
	addDeviceToMonitoring = async data => {
		const inviteParticipantPayload = {
			participants: [data.participant],
			conferenceId: data.conferenceId,
			participantId: data.participantId,
		};
		this.startOutGoingCallTimerForParticipant({
			objectId: data.participant.objectId,
		});

		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.INVITE_PARTICIPANTS, inviteParticipantPayload);
	};

	/**
	 * Stop peer connection by actionee participant ID
	 * @param {string} actioneeParticipantId
	 * @param {number} objectId
	 */
	closePeerConnection = (actioneeParticipantId, objectId) => {
		this.clearMonitoringReAddInterval(objectId);

		const hasPeerConnectionChannelForRemoteId = this.p2p.hasPeerConnectionChannelForRemoteId(actioneeParticipantId);
		if (hasPeerConnectionChannelForRemoteId) {
			this.p2p.stop(actioneeParticipantId);
		}
	};

	removeDeviceFromMonitoring = async ({ conferenceId, participantId, actioneeParticipantId }) => {
		const actioneeParticipant = this.getParticipantByParticipantId(actioneeParticipantId);
		if (actioneeParticipant) {
			this.clearMonitoringReAddInterval(actioneeParticipant.objectId);
			delete this.participants[actioneeParticipant.objectId];
			this.p2p.sendMessageToSignaling(SocketEvents.Conference.REMOVE_PARTICIPANT, {
				conferenceId,
				participantId,
				actioneeParticipantId,
			});

			const hasPeerConnectionChannelForRemoteId = this.p2p.hasPeerConnectionChannelForRemoteId(actioneeParticipantId);
			if (hasPeerConnectionChannelForRemoteId) {
				this.p2p.stop(actioneeParticipantId);
			}
		}
	};

	clearMonitoringReAddInterval = objectId => {
		const reAddInterval = this.reAddToMonitoringIntervals.get(objectId);
		if (reAddInterval) {
			clearInterval(reAddInterval);
			this.feedRetryCount.set(objectId, 0);
			this.reAddToMonitoringIntervals.delete(objectId);
		}
	};

	startMonitoring = async startConferenceInfo => {
		const iceServers = await this.setP2pClient();
		this.conferenceInfo = startConferenceInfo;

		const data = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.START, startConferenceInfo);
		if (data.hasActiveConference) {
			this.emit('end-call', { hasActiveConference: true });
			this.clearManager();
		}
		if (data.failureReason === enums.StartConferenceFailureReasons.GET_INITIATOR_INFO_FAILED) {
			this.clearManager();
			return { conferenceStarted: false, response: data };
		}

		this.callStartedOrJoined = true;
		this.endingCall = false;
		return { conferenceStarted: true, iceServers, conferenceInfo: startConferenceInfo };
	};

	setP2pClient = async () => {
		if (this.p2p) {
			return null;
		}
		const iceServers = await getIceServers();
		const p2pClientConfiguration = Object.assign(new P2PClientConfiguration(), {
			// videoEncoding: [
			//     new VideoEncodingParameters(new VideoCodecParameters(VideoCodec.H264)),
			//     new VideoEncodingParameters(new VideoCodecParameters(VideoCodec.VP8)),
			// ],
			rtcConfiguration: { iceServers },
		});

		this.p2p = new P2PClient({
			configuration: p2pClientConfiguration,
			signalingChannel: new SignalingChannel(this.socket),
			onPeerConnectionCreated: this.onPeerConnectionCreated,
		});

		this.bindP2pEvents();

		return iceServers;
	};

	startConference = async startConferenceInfo => {
		await this.setP2pClient();
		this.conferenceInfo = { ...startConferenceInfo };

		let { isAudio, isVideo, isScreen, callType } = startConferenceInfo;
		// manually changed isAudio and isVideo to false
		// because the conference in patient view(securitycam) starts with isAudio and isVideo -> true
		if (callType === CallTypes.SECURITY_CAM) {
			isAudio = false;
			isVideo = false;
		}
		this.conferenceInfo.hasAudio = true;
		this.conferenceInfo.hasVideo = isVideo;
		await this.initMediaStream(isAudio, isVideo, isScreen);
		this.setTrackIdsInConferenceInfo({
			isAudio,
			isVideo,
			isScreen,
		});
		if (startConferenceInfo.isMeetingRoom) {
			const audioOrVideo = startConferenceInfo.isVideo ? 'videoStream' : 'audioStream';
			this.emit(startConferenceInfo.isVideo ? 'video-call' : 'audio-call', { [audioOrVideo]: this.localSrc });
		} else {
			startConferenceInfo.participants.forEach(participant => {
				this.startOutGoingCallTimerForParticipant({
					objectId: participant.objectId,
				});
			});
		}
		const data = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.START, startConferenceInfo);
		if (data.hasActiveConference) {
			this.emit('end-call', { hasActiveConference: true });
			this.clearManager();
			this.closeSocket();
			return;
		}

		this.callStartedOrJoined = true;
		this.callerParticipantId = this.conferenceInfo.isMeetingRoom ? data.participantId : data.participants[0].id;
		this.p2p.allowedRemoteIds = this.conferenceInfo.isMeetingRoom ? [data.participantId] : [data.participants[0].id];
		this.endingCall = false;
		this.emit('call-started', {
			conferenceId: startConferenceInfo.conferenceId,
			participantId: startConferenceInfo.participantId,
			helloDevice: data.participants.find(participant => participant.objectType === ObjectType.HELLO_DEVICE),
		});
	};

	setTrackIdsInConferenceInfo = ({ isAudio, isVideo, isScreen }) => {
		if (isAudio && this.localSrc) {
			this.conferenceInfo.audioTrackId = this.localSrc.mediaStream.getAudioTracks()[0].id;
		}
		if (isVideo && this.localSrc) {
			this.conferenceInfo.videoTrackId = this.localSrc.mediaStream.getVideoTracks()[0].id;
		}
		if (isScreen && this.localScreenSrc) {
			this.conferenceInfo.screenTrackId = this.localScreenSrc.mediaStream.getVideoTracks()[0].id;
		}
	};

	joinConference = async joinConferenceInfo => {
		await this.setP2pClient();
		this.conferenceInfo = joinConferenceInfo;
		this.conferenceInfo.isVideo = joinConferenceInfo.callType === CallTypes.VIDEO;
		this.conferenceInfo.isAudio = true;
		// change should be here if use case changes
		let hasAudio = false;
		let hasVideo = false;
		let hasScreenShare = false;
		if ([CallTypes.AUDIO, CallTypes.FIRST_RESPONDER].includes(joinConferenceInfo.callType)) {
			hasAudio = true;
		} else if (joinConferenceInfo.callType === CallTypes.VIDEO) {
			hasAudio = true;
			hasVideo = true;
		} else if (joinConferenceInfo.callType === CallTypes.SCREENSHARE) {
			hasScreenShare = true;
		}

		await this.initMediaStream(hasAudio, hasVideo, hasScreenShare);
		this.localSrc.mediaStream
			.getTracks()
			.forEach(track => this.bindOnTrackEndedEventListener(track, this.conferenceInfo.from.id));

		this.conferenceInfo.hasAudio = hasAudio;
		this.conferenceInfo.hasVideo = hasVideo;
		this.setTrackIdsInConferenceInfo({
			isAudio: hasAudio,
			isVideo: hasVideo,
			isScreen: hasScreenShare,
		});

		if (hasAudio && !hasVideo) {
			this.emit('audio-call', { audioStream: this.localSrc });
		} else if (hasAudio && hasVideo) {
			this.emit('video-call', { videoStream: this.localSrc });
		}
		this.participants[this.conferenceInfo.from.objectId] = this.conferenceInfo.from;
		this.p2p.allowedRemoteIds.push(this.conferenceInfo.from.id);
		const p2pChannel = this.p2p.getChannel(this.conferenceInfo.from.id);
		p2pChannel.addStreamToPendingStreams(this.localSrc);
		p2pChannel.setMyStreamInfo(this.localSrc);
		p2pChannel.actioneeParticipantId = this.conferenceInfo.from.id;
		p2pChannel.participantId = this.conferenceInfo.participantId;
		p2pChannel.conferenceId = this.conferenceInfo.conferenceId;
		this.callerParticipantId = this.conferenceInfo.from.id;
		const data = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.JOIN, {
			conferenceId: this.conferenceInfo.conferenceId,
			callType: this.conferenceInfo.callType,
			participantId: this.conferenceInfo.participantId,
			inputDevices: this.conferenceInfo.inputDevices,
		});

		// check if false because it can be undefined
		if (data.ok === false) {
			switch (data.failureReason) {
				case JoinConferenceFailureReasonEnum.WRONG_PARTICIPANT_STATE:
					this.emit('end-call', { anotherNursePickedUp: true });
					break;
				case JoinConferenceFailureReasonEnum.UNHANDLED_EXCEPTION:
				case JoinConferenceFailureReasonEnum.NULL_CONFERENCE:
					this.emit('end-call', { endReason: ConferenceEndReason.ABORTED });
					break;
				default:
					break;
			}
			this.clearManager();
			this.closeSocket();
			return;
		}
		this.callStartedOrJoined = true;
		Object.assign(this.conferenceInfo, data);
		this.emit('call-started', {
			conferenceId: data.conferenceId,
			participantId: data.participantId,
			helloDevice: data.participants.find(participant => participant.objectType === ObjectType.HELLO_DEVICE),
		});
	};

	newOffer = (isAudio, isVideo, isScreen, actioneeParticipantId) => {
		const { callerParticipantId, conferenceInfo } = this;

		return new Offer(
			actioneeParticipantId || callerParticipantId,
			conferenceInfo.callType,
			conferenceInfo.conferenceId,
			conferenceInfo.forScreenShare,
			isAudio,
			isVideo,
			isScreen,
			conferenceInfo.toScreenSharingSocket,
			conferenceInfo.participantId
		);
	};

	initMediaStream = async (isAudio, isVideo, isScreen) => {
		if (isAudio || isVideo) {
			// check for permissions to show the 'allow permission dialog' if needed
			const status = await this.getInputStreamsStatus();
			const streamPermission = this.validateStreamStatus(isAudio, isVideo, status);
			if (streamPermission) {
				this.emit('stream-permission', streamPermission);
			}
		}

		try {
			this.localSrc = await this.getStream(isAudio, isVideo, isScreen);
			this.emit('stream-permission', null);
		} catch (err) {
			const errorType = this.handelMediaStreamError(err);
			this.emit('stream-permission', errorType);
			if (errorType.type !== StreamError.PERMISSION_DISMISSED.type && errorType.type !== StreamError.STREAM_NOT_ALLOWED.type) {
				throw err;
			}
			// start a lisener to check if the user gives stream permission (mic & cam)
			await this.checkForStreamPermissions({ isAudio, isVideo });
			// we call initMediaStream because we need to set the stream to the this.localSrc after the user granted the permissions
			await this.initMediaStream(isAudio, isVideo, isScreen);
		}
	};

	validateStreamStatus = (isAudio, isVideo, status) => {
		let streamPermission;
		if (isAudio && !isVideo) {
			// audio call
			if (!status.mic.available) {
				streamPermission = StreamError.DEVICE_NOT_FOUND;
			}
			if (!status.mic.permission) {
				streamPermission = StreamError.PERMISSION_DISMISSED;
			}
		} else if (isAudio && isVideo) {
			// video call
			if (!status.mic.available || !status.cam.available) {
				streamPermission = StreamError.DEVICE_NOT_FOUND;
			}
			if (!status.mic.permission || !status.cam.permission) {
				streamPermission = StreamError.PERMISSION_DISMISSED;
			}
		}
		return streamPermission;
	};

	audioCall = async actioneeParticipant => {
		const offer = this.newOffer(true, false, false, actioneeParticipant?.id);
		const { actioneeParticipantId } = offer;
		await this.p2p.publish(actioneeParticipantId, this.localSrc, offer);
		this.emit('audio-call', { audioStream: this.localSrc, actioneeParticipantId });
	};

	videoCall = async () => {
		const offer = this.newOffer(true, true, false);
		await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer);
		this.emit('video-call', { videoStream: this.localSrc, actioneeParticipantId: offer.actioneeParticipantId });
	};

	cameraFeedCall = async () => {
		const offer = this.newOffer(true, true, false);
		this.emit('feed-call', { localStream: this.localSrc, actioneeParticipantId: offer.actioneeParticipantId });
		await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer);
	};

	monitoringSendOffer = async participantId => {
		if (!this.p2p.allowedRemoteIds.includes(participantId)) {
			this.p2p.allowedRemoteIds.push(participantId);
		}
		const offer = this.newOffer(true, true, false);
		offer.actioneeParticipantId = participantId;
		// We need to add a dummy track to fix mic issue after reconnect if the track is already added
		const streamSourceInfo = new StreamSourceInfo('mic', undefined);
		let ctx = new AudioContext(),
			oscillator = ctx.createOscillator();
		let dst = oscillator.connect(ctx.createMediaStreamDestination());
		oscillator.start();
		let track = Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });

		const mediaStream = new MediaStream([track]);
		const localStream = new LocalStream(mediaStream, streamSourceInfo);
		this.localSrc = localStream;
		try {
			await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer, false, 'aiDetections', true);
		} catch (error) {
			this.emit('p2p-publish-failed', error);
		}
	};

	screenShare = async () => {
		if (this.togglingScreenShare) {
			return;
		}
		this.togglingScreenShare = true;
		const offer = this.newOffer(false, false, true);
		if (!this.localScreenSrc) {
			try {
				const screenStream = await this.getStream(false, false, true);
				this.localScreenSrc = screenStream;
				const screenTrack = screenStream.mediaStream.getVideoTracks()[0];
				this.conferenceInfo.screenTrackId = screenTrack.id;
				const toggleInfo = this.toggleEvent(StreamTypes.SCREEN_SHARE, false, false, true);

				const eventResponse = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TOGGLE_STREAMS, toggleInfo);
				if (!eventResponse.ok) {
					this.conferenceInfo.screenTrackId = null;
					this.conferenceInfo.isScreen = false;
					screenTrack.stop();
					return this.handleEventFailureResponse(eventResponse);
				}

				this.bindOnTrackEndedEventListener(screenTrack);
				this.localSrc.mediaStream.addTrack(screenTrack);
				this.localSrc.source.video = 'screen-cast';

				await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer);
				this.emit('screensharing', {
					localSrc: this.localSrc,
					activeSrc: this.localScreenSrc,
					localScreenSrc: this.localScreenSrc,
				});
			} catch (err) {
				this.handelMediaStreamError(err);
			} finally {
				this.togglingScreenShare = false;
			}
		} else {
			const videoTracks = this.localSrc.mediaStream.getVideoTracks();
			const screenTrack = this.localScreenSrc.mediaStream.getVideoTracks()[0];
			const toggleInfo = this.toggleEvent(StreamTypes.SCREEN_SHARE, false, false, true);
			const eventResponse = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TOGGLE_STREAMS, toggleInfo);
			if (!eventResponse.ok) {
				this.conferenceInfo.screenTrackId = screenTrack.id;
				this.conferenceInfo.isScreen = true;
				return this.handleEventFailureResponse(eventResponse);
			}

			for (let i = 0; i < videoTracks.length; i += 1) {
				if (videoTracks[i].id === screenTrack.id) {
					this.localScreenSrc.mediaStream.getVideoTracks()[0].stop();
					this.localSrc.mediaStream.getVideoTracks()[i].stop();
					this.localScreenSrc = null;
					this.localSrc.mediaStream.removeTrack(this.localSrc.mediaStream.getVideoTracks()[i]);
					break;
				}
			}
			await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer);
			this.emit('screensharing', { localSrc: this.localSrc, activeSrc: this.localSrc, localScreenSrc: this.localScreenSrc });
			this.togglingScreenShare = false;
		}
	};

	toggleAudio = async actioneeParticipantId => {
		if (this.localSrc.mediaStream.getAudioTracks().length && this.conferenceInfo.callType !== CallTypes.MONITORING) {
			const audioTrack = this.localSrc.mediaStream.getAudioTracks()[0];
			if (!audioTrack.enabled) {
				// check if user changed permissions for mic during the call
				const status = await this.getInputStreamsStatus();
				if (!status.mic.available || !status.mic.permission) {
					return {
						error: 'No mic or permission available',
					};
				}
			}
			if (!this.conferenceInfo.isAudio) {
				this.conferenceInfo.audioTrackId = audioTrack.id;
			}
			const toggleInfo = this.toggleEvent(StreamTypes.AUDIO, true, false, false);
			const eventResponse = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TOGGLE_STREAMS, toggleInfo);
			if (!eventResponse.ok) {
				this.conferenceInfo.isAudio = !this.conferenceInfo.isAudio;
				this.conferenceInfo.audioTrackId = this.conferenceInfo.isAudio ? audioTrack.id : null;
				return this.handleEventFailureResponse(eventResponse.failureReason);
			}
			audioTrack.enabled = !audioTrack.enabled;
			this.emit('toggle-audio');
		} else {
			// case for patient view because the call starts with no tracks so we need to add the audio track
			try {
				const audioStream = await this.getStream(true, false, false);
				const audioTrack = audioStream.mediaStream.getAudioTracks()[0];
				let stream = audioStream;

				const offer = this.newOffer(true, false, false, actioneeParticipantId);
				// created manually the toggle info object because with each feed we need the toggle info like this
				const toggleInfo = {
					streamType: StreamTypes.AUDIO,
					isAudio: true,
					isVideo: false,
					isScreen: false,
					conferenceId: this.conferenceInfo.conferenceId,
					participantId: this.conferenceInfo.participantId,
					actioneeParticipantId: offer.actioneeParticipantId,
					audioTrackId: audioTrack.id,
					videoTrackId: null,
					screenTrackId: null,
				};
				const eventResponse = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TOGGLE_STREAMS, toggleInfo);
				if (!eventResponse.ok) {
					audioTrack.stop();
					return this.handleEventFailureResponse(eventResponse);
				}

				if (this.conferenceInfo.callType !== CallTypes.MONITORING) {
					this.localSrc.mediaStream.addTrack(audioStream.mediaStream.getAudioTracks()[0]);
					this.localSrc.source.audio = 'mic';
					stream = this.localSrc;
					this.emit('toggle-audio');
				}

				this.bindOnTrackEndedEventListener(audioTrack, offer.actioneeParticipantId);

				await this.p2p.publish(offer.actioneeParticipantId, stream, offer);
				return audioStream;
			} catch (err) {
				return {
					error: this.handelMediaStreamError(err),
				};
			}
		}
	};

	toggleVideo = async () => {
		const offer = this.newOffer(false, true, false);
		if (!this.hasCameraTrack()) {
			try {
				const videoStream = await this.getStream(false, true, false);
				const videoTrack = videoStream.mediaStream.getVideoTracks()[0];
				this.conferenceInfo.videoTrackId = videoTrack.id;
				const toggleInfo = this.toggleEvent(StreamTypes.VIDEO, false, true);
				const eventResponse = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TOGGLE_STREAMS, toggleInfo);
				if (!eventResponse.ok) {
					this.conferenceInfo.videoTrackId = null;
					this.conferenceInfo.isVideo = false;
					videoTrack.stop();
					this.handleEventFailureResponse(eventResponse);
					return {
						error: 'camera blocked',
					};
				}

				this.localSrc.mediaStream.addTrack(videoStream.mediaStream.getVideoTracks()[0]);
				this.localSrc.source.video = 'camera';

				// if there are 2 tracks, perform this to change positions of the track
				if (this.localSrc.mediaStream.getVideoTracks().length === 2) {
					const screensharingTrack = this.localSrc.mediaStream.getVideoTracks()[0];
					this.localSrc.mediaStream.removeTrack(screensharingTrack);
					this.localSrc.mediaStream.addTrack(screensharingTrack);
				}

				this.bindOnTrackEndedEventListener(videoTrack, offer.actioneeParticipantId);

				await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer);
				this.emit('toggle-video', { hasVideoTrack: true });
			} catch (err) {
				return {
					error: this.handelMediaStreamError(err),
				};
			} finally {
				this.togglingVideo = false;
			}
		} else {
			const videoTrack = this.localSrc.mediaStream.getVideoTracks()[0];
			const toggleInfo = this.toggleEvent(StreamTypes.VIDEO, false, true);
			const eventResponse = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TOGGLE_STREAMS, toggleInfo);
			if (!eventResponse.ok) {
				this.conferenceInfo.videoTrackId = videoTrack.id;
				this.conferenceInfo.isVideo = true;
				return {
					error: 'camera blocked',
				};
			}
			this.localSrc.mediaStream.getVideoTracks()[0].stop();
			this.localSrc.mediaStream.removeTrack(this.localSrc.mediaStream.getVideoTracks()[0]);
			await this.p2p.publish(offer.actioneeParticipantId, this.localSrc, offer);
			this.emit('toggle-video', { hasVideoTrack: false });
		}
	};

	/**
	 * Method checks if there is a camera track
	 * @returns {boolean}
	 * */
	hasCameraTrack() {
		const { localSrc, localScreenSrc } = this;

		// if no screensharing, video track should be in localSrc
		if (!localScreenSrc) return !!localSrc.mediaStream.getVideoTracks().length;

		// if yes screensharing, check the tracks in localSrc if there exists one with different track id
		if (localSrc) {
			const screenTrack = localScreenSrc.mediaStream.getVideoTracks()[0];
			const videoTracks = localSrc.mediaStream.getVideoTracks();
			for (let i = 0; i < videoTracks.length; i++) {
				if (screenTrack.id !== videoTracks[i].id) {
					return true;
				}
			}
			return false;
		}
	}

	handleEventFailureResponse = eventResponse => {
		this.emit('event-failure', eventResponse);
		return null;
	};

	toggleEvent = (_streamType, _isAudio, _isVideo, _isScreen, _actioneeParticipantId) => {
		if (_isAudio) {
			this.conferenceInfo.isAudio = !this.conferenceInfo.isAudio;
			this.conferenceInfo.hasAudio = !this.conferenceInfo.hasAudio;
		}
		if (_isVideo) {
			this.conferenceInfo.isVideo = !this.conferenceInfo.isVideo;
			this.conferenceInfo.hasVideo = !this.conferenceInfo.hasVideo;
		}
		if (_isScreen) {
			this.conferenceInfo.isScreen = !this.conferenceInfo.isScreen;
		}

		if (!this.conferenceInfo.isAudio) {
			this.conferenceInfo.audioTrackId = null;
		}
		if (!this.conferenceInfo.isVideo) {
			this.conferenceInfo.videoTrackId = null;
		}
		if (!this.conferenceInfo.isScreen) {
			this.conferenceInfo.screenTrackId = null;
		}

		const toggleInfo = {
			streamType: _streamType,
			hasAudio: this.conferenceInfo.hasAudio,
			hasVideo: this.conferenceInfo.hasVideo,
			isAudio: this.conferenceInfo.isAudio,
			isVideo: this.conferenceInfo.isVideo,
			isScreen: this.conferenceInfo.isScreen,
			conferenceId: this.conferenceInfo.conferenceId,
			participantId: this.conferenceInfo.participantId,
			actioneeParticipantId: _actioneeParticipantId,
			audioTrackId: this.conferenceInfo.audioTrackId,
			videoTrackId: this.conferenceInfo.videoTrackId,
			screenTrackId: this.conferenceInfo.screenTrackId,
		};

		return toggleInfo;
	};

	requestToChangeBitrate = data => {
		this.p2p.sendMessageToSignaling(SocketEvents.Conference.PARTICIAPNT_CHANGE_BITRATE, data);
	};

	bindP2pEvents = () => {
		const { p2p } = this;
		p2p.addEventListener('streamadded', e => {
			if (e.stream.source.audio || e.stream.source.video) {
				const hasAudioTrack = e.stream.mediaStream.getAudioTracks().length > 0;
				const hasVideoTrack = e.stream.mediaStream.getVideoTracks().length > 0;
				const participant = this.getParticipantByParticipantId(e.stream.origin);
				Object.assign(this.participants[participant.objectId], {
					hasAudioTrack,
					hasVideoTrack,
				});
				this.emit('peer-stream', { peerSrc: e.stream, activeSrc: e.stream, participantId: e.origin, trackTypes: e.trackTypes });
			}
		});

		p2p.addEventListener('remoteDescriptionSet', ({ origin }) => {
			this.emit('remoteStreamChanged', origin);
		});

		p2p.addEventListener('messagereceived', event => {
			const { origin, message } = event;

			if (!this.conferenceInfo) {
				console.warn('Conference is not active!');
				return;
			}

			if (!Object.values(this.participants).some(participant => origin === participant.id)) {
				console.warn('Participant is not in conference!');
				return;
			}

			if (!message) {
				console.warn(`Empty message received for channel!`);
				return;
			}

			const messageData = JSON.parse(message);
			if (!messageData.data) {
				return;
			}

			const MessageDetectionTypes = {
				SKELETON: 'skeletons',
				OBJECT_DETECTIONS: 'detections',
			};

			const EmitType = {
				[MessageDetectionTypes.SKELETON]: 'draw-skeleton',
				[MessageDetectionTypes.OBJECT_DETECTIONS]: 'object-detection',
			};

			this.emit(EmitType[messageData.type], {
				participantId: origin,
				conferenceId: this.conferenceInfo.conferenceId,
				[messageData.type]: messageData.data,
			});
		});
	};

	ping = () => {
		return new Promise((resolve, reject) => {
			if (!this.isIoConnected()) {
				setTimeout(() => {
					reject();
				}, 500);
			} else {
				this.socket.emit(SocketEvents.Client.PING, {}, ({ pong }) => {
					resolve(pong);
				});
			}
		});
	};

	promiseTimeout = (ms, promise) => {
		// Create a promise that rejects in <ms> milliseconds
		const timeout = new Promise((resolve, reject) => {
			const id = setTimeout(() => {
				clearTimeout(id);
				// eslint-disable-next-line no-console
				console.error(`Timed out in ${ms} ms.`);
				reject();
			}, ms);
		});

		// Returns a race between our timeout and the passed in promise
		return Promise.race([promise, timeout]);
	};

	checkIfConnectedToSignaling = () => {
		return new Promise((resolve, reject) => {
			const checkIfConnected = async () => {
				try {
					const pong = await this.promiseTimeout(1000, this.ping());
					if (pong) {
						resolve();
					} else {
						reject();
					}
				} catch (error) {
					checkIfConnected();
				}
			};
			checkIfConnected();
		});
	};

	handleReconnectingWithParticipant = async (participantId, stream = this.localSrc) => {
		// check for network and signaling
		// if connection state failed because of network then execute reconnect when the network is up
		await this.checkIfConnectedToSignaling();
		if (this.conferenceInfo) {
			const response = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.PARTICIPANT_RECONNECT, {
				conferenceId: this.conferenceInfo.conferenceId,
				participantId: this.conferenceInfo.participantId,
				isAudio: this.conferenceInfo.isAudio,
				isVideo: this.conferenceInfo.isVideo,
				isScreen: this.conferenceInfo.isScreen,
			});

			if (response.isActive) {
				const participant = response.participants.find(p => p.id === participantId);
				if (participant.state === ParticipantState.CONNECTING.type || participant.state === ParticipantState.CONNECTED.type) {
					const p2pChannel = this.p2p.getChannel(participant.id);
					const rtcPeerConnection = p2pChannel.getRTCPeerConnection();
					const { connectionState, iceConnectionState } = rtcPeerConnection;
					if (
						connectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED ||
						connectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CLOSED
					) {
						// close this peer connection and make a new one after with the same participant
						if (connectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CLOSED) {
							this.p2p.stop(participant.id);
							this.p2p.allowedRemoteIds.push(participant.id);
						}
						this.callerParticipantId = participant.id;
						const offer = this.newOffer(this.conferenceInfo.isAudio, this.conferenceInfo.isVideo, this.conferenceInfo.isScreen);
						if (iceConnectionState === RTCPeerConnectionEnum.ICE_CONNECTION_STATE.DISCONNECTED) {
							Object.assign(offer, { iceRestart: true });
						}
						if (stream) {
							this.p2p.publish(participant.id, stream, offer, true);
						}
					}
				} else {
					this.emit('participant-status', {
						status: participant.state,
						objectId: participant.objectId,
					});
				}
			} else {
				this.endCall({ endReason: ConferenceEndReason.DROPPED });
			}
		}
	};

	/**
	 * Method used to bind MediaStreamTrack.ended event to local track
	 * @function onTrackEndedEventListener
	 * @param {MediaStreamTrack} track
	 * @param {string} [actioneeParticipantId] Remote id
	 */
	bindOnTrackEndedEventListener = (track, actioneeParticipantId) => {
		track.removeEventListener('ended', () => this.onTrackEndedEventListener(track, actioneeParticipantId));
		track.addEventListener('ended', () => this.onTrackEndedEventListener(track, actioneeParticipantId));
	};

	onTrackEndedEventListener = async (track, actioneeParticipantId) => {
		if (this.conferenceInfo.isScreen && !!this.localScreenSrc) {
			this.screenShare();
			return;
		}

		const inputDevices = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === `${track.kind}input`);
		const trackDeviceNotFound = !inputDevices.some(device => device.groupId === track.getSettings().groupId);

		const trackType = track.kind === 'audio' ? 'Audio' : 'Video.jsx';
		const localTrack = this.localSrc.mediaStream[`get${trackType}Tracks`]().find(t => t.id === track.id);
		if (localTrack) {
			localTrack.stop();
			this.localSrc.mediaStream.removeTrack(localTrack);
		}
		this.removeTrackById(actioneeParticipantId, track.id);
		this.emit(`local-${track.kind}-error`, { participantId: actioneeParticipantId, inputDevices, trackDeviceNotFound });

		let message = `${trackType} track has ended.`;
		if (trackDeviceNotFound) {
			message += ` ${trackType} input device that was being used is not found!`;
		} else if (!trackDeviceNotFound && inputDevices.length) {
			message += ` ${trackType} input devices disabled!`;
		} else if (!inputDevices.length) {
			message += ` No ${trackType} input device found!`;
		}

		this.publishConferenceLog(message, { actioneeParticipantId, inputDevices });
	};

	/**
	 * Send leave conference event
	 * @param {object} leaveData
	 * @param {string} leaveData.participantId
	 * @param {string} leaveData.conferenceId
	 * @param {number} [leaveData.leaveReason]
	 * @returns {Promise} Ack promise
	 */
	sendLeaveEvent = leaveData => {
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.LEAVE, leaveData);
	};

	stopTracks = stream => {
		if (stream && stream.mediaStream) {
			const tracks = stream.mediaStream.getTracks();
			for (const track of tracks) {
				track.stop();
			}
		}
	};

	endCall = ({ endReason }) => {
		if (!this.endingCall && this.conferenceInfo) {
			this.endingCall = true;
			const shouldCloseSocket =
				this.conferenceInfo.callType !== CallTypes.MONITORING || endReason === ConferenceEndReason.DROPPED;
			const leaveData = {
				conferenceId: this.conferenceInfo.conferenceId,
				participantId: this.conferenceInfo.participantId,
			};

			if (endReason === ConferenceEndReason.PARTICIPANT_PASSWORD_CHANGED) {
				leaveData.leaveReason = ParticipantRemoveReason.PASSWORD_CHANGED;
			} else if (endReason === ConferenceEndReason.PARTICIPANT_IDLE) {
				leaveData.leaveReason = ParticipantRemoveReason.IDLE;
			} else if (endReason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
				leaveData.leaveReason = ParticipantRemoveReason.CONFERENCE_TERMINATED_BY_ADMINISTRATOR;
			}

			this.clearManager();
			this.sendLeaveEvent(leaveData);
			if (shouldCloseSocket) {
				this.closeSocket();
			}
			this.emit('end-call', { endReason });
		}
	};

	closeSocket = () => {
		if (!this._doCloseSocket || this.conferenceInfo?.callType === CallTypes.MONITORING) {
			return;
		}
		// Disabled this tab from receiving any more calls by closing it's socket
		console.info('Disconnecting socket...');
		this.socket.removeAllListeners();
		this.socket.close();
	};

	clearManager = () => {
		this.conferenceInfo = null;
		this.callStartedOrJoined = false;
		this.stopTracks(this.localSrc);
		this.stopTracks(this.localScreenSrc);
		this.clearAndDeleteTimers({ timers: this.outGoingCallTimer });
		this.reAddToMonitoringIntervals.clear();
		this.feedRetryCount.clear();
		if (this._useCallStats) {
			this._callStats.stop();
		}
		Object.values(this.participants).forEach(participant => {
			this.p2p.stop(participant.id);
		});
		this.participants = {};
	};

	/**
	 * Method used to clear and delete timers
	 * @function clearAndDeleteTimers
	 * @param {Object} data
	 * @param {Object} data.timers
	 */
	clearAndDeleteTimers = data => {
		const { timers } = data;

		if (timers) {
			console.info('There are no timers to clear and delete!');
			return;
		}

		for (const key in timers) {
			clearTimeout(timers[key]);
			delete timers[key];
		}
	};

	toggleParticipantTrack = async (trackType, isTrackMuted, actioneeParticipantId = this.callerParticipantId) => {
		// only updating hasAudioTrack and hasVideoTrack for participant when isTrackMuted is true because streamAdded won't be called after the participant removes a track
		// if isTrackMuted is false than streamAdded will be called and hasAudioTrack and hasVideoTrack will be updated
		if (isTrackMuted) {
			const participant = this.getParticipantByParticipantId(actioneeParticipantId);
			if (trackType === CallTypes.AUDIO) {
				this.participants[participant.objectId].hasAudioTrack = false;
			} else {
				this.participants[participant.objectId].hasVideoTrack = false;
			}
		}

		const data = {
			participantId: this.conferenceInfo.participantId,
			conferenceId: this.conferenceInfo.conferenceId,
			actioneeParticipantId,
			type: trackType,
			muted: isTrackMuted,
		};

		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.TRACK_TOGGLE, data);
	};

	/**
	 * Method used to send move camera event
	 * @function panTiltCamera
	 * @param {object} data
	 * @param {string} data.direction
	 * @param {string} data.action
	 * @param {string} data.participantId
	 * @param {string} data.conferenceId
	 * @param {number} data.helloDeviceId
	 * @param {number} [data.level]
	 */
	panTiltCamera = async data => {
		try {
			const response = await this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.MOVE_CAMERA, data);
			return response;
		} catch (error) {
			console.log('Camera tilt event send failed!', error);
			return { error };
		}
	};

	/**
	 * Method used to send zoom or switch camera event
	 * @function sendCameraEvent
	 * @param {string} eventType
	 * @param {object} data
	 * @param {string} data.participantId
	 * @param {string} data.conferenceId
	 * @param {number} data.helloDeviceId
	 * @param {number} [data.level]
	 */
	sendCameraEvent = async (eventType, data) => {
		if (this.cameraEventInProgress) {
			return { cameraEventInProgress: true };
		}

		this.cameraEventInProgress = true;

		// If no ack is received from camera enable the controls after 3 seconds
		if (this.cameraEventTimeout) {
			clearTimeout(this.cameraEventTimeout);
		}
		this.cameraEventTimeout = setTimeout(() => {
			if (this.cameraEventInProgress) {
				this.cameraEventInProgress = false;
			}
		}, 3000);

		try {
			const response = await this.p2p.sendMessageToSignaling(eventType, data);

			console.log(`'${eventType}'`, 'event sent.');
			return response;
		} catch (error) {
			console.log(`'${eventType}'`, 'event send failed!', error);
			return { error };
		}
	};

	serialCommandsTv = (serialCommand, isVolume, deviceId, conferenceId, participantId) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.ON_TOGGLE_TV, {
			tvStatus: serialCommand,
			isVolume: isVolume,
			helloDeviceId: deviceId,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	toggleNightVision = (nightVisionMode, deviceId, conferenceId, participantId) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.TOGGLE_NIGHTVISION, {
			helloDeviceId: deviceId,
			toggleNightVision: nightVisionMode,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	/**
	 * This method is used to get the stream for these cases:
	 * F,F,F -> monitoring or patient view
	 * F,F,T -> only screenshare
	 * F,T,F -> only video
	 * F,T,T -> we don't support this case (video && screenshare)
	 * T,F,F -> only audio
	 * T,F,T -> we don't support this case (audio && screenshare)
	 * T,T,F -> audio and video
	 * T,T,T -> we don't support this case (audio && video && screenshare)
	 * @param {boolean} isAudio
	 * @param {boolean} isVideo
	 * @param {boolean} isScreenshare
	 * @returns {LocalStream}
	 */
	getStream = (isAudio, isVideo, isScreenshare) => {
		return new Promise(async (resolve, reject) => {
			if ((isAudio && isScreenshare) || (isVideo && isScreenshare) || (isAudio && isVideo && isScreenshare)) {
				reject(`Can't get this stream!`);
				return;
			}

			let streamSourceInfo;
			let streamConstraints;

			if (isAudio && !isVideo) {
				// only audio
				const audioConstraintsForMic = new AudioTrackConstraints(AudioSourceInfo.MIC);
				streamSourceInfo = new StreamSourceInfo('mic', undefined);
				streamConstraints = new StreamConstraints(audioConstraintsForMic);
			} else if (isAudio && isVideo) {
				// audio and video
				const audioConstraintsForMic = new AudioTrackConstraints(AudioSourceInfo.MIC);
				const videoConstraintsForCamera = new VideoTrackConstraints(VideoSourceInfo.CAMERA);
				videoConstraintsForCamera.resolution = { width: 1280, height: 720 };
				videoConstraintsForCamera.frameRate = 30;
				streamSourceInfo = new StreamSourceInfo('mic', 'camera');
				streamConstraints = new StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera);
			} else if (isVideo) {
				// only video
				const videoConstraintsForCamera = new VideoTrackConstraints(VideoSourceInfo.CAMERA);
				videoConstraintsForCamera.resolution = { width: 1280, height: 720 };
				videoConstraintsForCamera.frameRate = 30;
				streamSourceInfo = new StreamSourceInfo(undefined, 'camera');
				streamConstraints = new StreamConstraints(undefined, videoConstraintsForCamera);
			} else if (isScreenshare) {
				// only screenshare
				const videoConstraintsForCamera = new VideoTrackConstraints(VideoSourceInfo.SCREENCAST);
				streamSourceInfo = new StreamSourceInfo(undefined, 'screen-cast');
				streamConstraints = new StreamConstraints(false, videoConstraintsForCamera);
			} else {
				// monitoring or patient view
				streamSourceInfo = new StreamSourceInfo(undefined, undefined);
				const mediaStream = new MediaStream();
				const stream = new LocalStream(mediaStream, streamSourceInfo);
				resolve(stream);
				return;
			}

			try {
				const mediaStream = await MediaStreamFactory.createMediaStream(streamConstraints);
				const stream = new LocalStream(mediaStream, streamSourceInfo);
				resolve(stream);
				return;
			} catch (error) {
				reject(error);
			}
		});
	};

	handelMediaStreamError = error => {
		console.log(`GET USER MEDIA ERROR:${error}`);
		let errorType = StreamError.CANT_ACCESS_MEDIA_STREAM;

		if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
			errorType = StreamError.DEVICE_NOT_FOUND;
		} else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
			errorType = StreamError.NOT_READABLE_ERROR;
		} else if (error.name === 'NotAllowedError') {
			errorType = StreamError.STREAM_NOT_ALLOWED;
		} else if (error.name === 'PermissionDismissedError') {
			errorType = StreamError.PERMISSION_DISMISSED;
		}

		return errorType;
	};

	startReAddFeedInterval = ({ id, objectId, removeReason, isAmbient, state = null }, byPassStateCheck = false) => {
		const retryParticipantStates = [ParticipantState.LEFT_CALL.type, ParticipantState.DISCONNECTED.type];
		if (!byPassStateCheck && !retryParticipantStates.includes(state)) {
			return;
		}

		if (removeReason && removeReason === ParticipantRemoveReason.CONFERENCE_TERMINATED_BY_ADMINISTRATOR) {
			return;
		}

		this.p2p.allowedRemoteIds.push(id);

		this.emit('re-add-feed', { objectId });

		this.addDeviceToMonitoring({
			conferenceId: this.conferenceInfo.conferenceId,
			participantId: this.conferenceInfo.participantId,
			participant: {
				objectType: ObjectType.HELLO_DEVICE,
				objectId,
				...(isAmbient ? { isAmbient: true } : null),
			},
		});

		if (!this.reAddToMonitoringIntervals.get(objectId)) {
			this.feedRetryCount.set(objectId, 0);
			this.reAddToMonitoringIntervals.set(
				objectId,
				setInterval(() => {
					if (
						!this.conferenceInfo ||
						(!byPassStateCheck && !retryParticipantStates.includes(state)) ||
						this.feedRetryCount.get(objectId) >= 10
					) {
						return;
					}

					this.feedRetryCount.set(objectId, this.feedRetryCount.get(objectId) + 1);
					if (this.feedRetryCount.get(objectId) === 10) {
						this.updateParticipantStatus(ParticipantState.NOT_ANSWERING.type, { objectId });
						this.clearMonitoringReAddInterval(objectId);
						return;
					}

					console.warn('Retry', objectId, this.feedRetryCount.get(objectId));
					this.clearOutGoingCallTimerForParticipant({ objectId });
					this.addDeviceToMonitoring({
						conferenceId: this.conferenceInfo.conferenceId,
						participantId: this.conferenceInfo.participantId,
						participant: {
							objectType: ObjectType.HELLO_DEVICE,
							objectId,
							...(isAmbient ? { isAmbient: true } : null),
						},
					});
				}, 3000)
			);
		}
	};

	/**
	 * This function binds socket listeners needed in a call
	 */
	bindSocketEventListeners = (playSound = true, shouldCloseSocket = true) => {
		this.bindOn(SocketEvents.Conference.ON_RINGING, async () => {
			if (playSound) {
				await outGoingCallSound();
			}
		})
			.bindOn(SocketEvents.Conference.ON_TRANSFERRED_TO_ANOTHER_CLIENT, data => {
				if (this.conferenceInfo && this.conferenceInfo.conferenceId === data.conferenceId) {
					this.emit('end-call', { endReason: ConferenceEndReason.ABORTED });
					this.clearManager();
					if (shouldCloseSocket) {
						this.closeSocket();
					}
				}
			})
			.bindOn(SocketEvents.Conference.ON_PARTICIPANT_DECLINED, data => {
				this.stopTracks(this.localSrc);
				stopOutgoingCallSound();
				this.updateParticipantStatus(ParticipantState.DECLINED.type, data);
			})
			.bindOn(SocketEvents.HelloDevice.ON_OFFLINE, this.updateParticipantStatus.bind(this, ParticipantState.OFFLINE.type))
			.bindOn(
				SocketEvents.Conference.ON_PARTICIPANT_OFFLINE,
				this.updateParticipantStatus.bind(this, ParticipantState.OFFLINE.type)
			)
			.bindOn(SocketEvents.Conference.ON_PARTICIPANT_BUSY, data => {
				this.emit('participant-busy', data);
				if (data.objectId) {
					this.clearOutGoingCallTimerForParticipant({ objectId: data.objectId });
				}
			})
			.bindOn(SocketEvents.Conference.ON_PARTICIPANT_LEFT, data => {
				const { conferenceId, participantId, reason } = data;

				const participant = this.getParticipantByParticipantId(participantId);
				if (!this.conferenceInfo || this.conferenceInfo.conferenceId !== conferenceId || !participant) {
					return;
				}

				participant.removeReason = data.reason;
				participant.state = ParticipantState.LEFT_CALL.type;
				this.p2p.stop(participant.id);

				if (
					this.conferenceInfo.callType === CallTypes.MONITORING &&
					[ParticipantRemoveReason.DISCONNECTED, ParticipantRemoveReason.DISCONNECTED_PARTICIPANT_CLEANUP].includes(reason) &&
					participant.isOnline
				) {
					this.startReAddFeedInterval(participant);
					return;
				}
				if (reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL) {
					this.emit('on-disconnected-by-call', data);
				}
				this.updateParticipantStatus(ParticipantState.LEFT_CALL.type, { objectId: participant.objectId, reason });
			})
			.bindOn(SocketEvents.Conference.ON_TOGGLE_TRACK, data => {
				this.emit('toggle-track', data);
			})
			.bindOn(SocketEvents.HelloDevice.ON_TV_RESPONSE, data => {
				this.emit('tv-commands', data);
			})
			.bindOn(SocketEvents.Conference.ON_PARTICIPANT_STREAMS_TOGGLED, data => {
				this.emit('participant-toggled-streams', data);
			})
			.bindOn(SocketEvents.HelloDevice.ON_CAMERA_RESPONSE, data => {
				this.cameraEventInProgress = false;
				this.emit('camera-response', data);
			})
			.bindOn(SocketEvents.HelloDevice.ON_INITIAL_STATE, data => {
				this.emit('initial-state', data);
			})
			.bindOn(SocketEvents.HelloDevice.ON_AUDIO_OUTPUT_DEVICE, data => {
				// just for loging the error
				if (!data.isSwitchSuccessful) {
					// eslint-disable-next-line no-console
					console.error(
						`Switch was not successful! Current audio device is ${
							AudioOutputDevice.HELLO === data.audioOutputDevice ? 'Banyan Bridge' : 'Pillow Speaker'
						}`,
						data.message
					);
				}
				this.emit('audio-output-changed', data);
			})
			.bindOn(SocketEvents.Conference.ON_ENDED, async data => {
				// if conferenceInfo exists on conference ended event it means that the remote peer ended the call
				// so we need only to clean up and we don't need to send a leave event
				if (this.conferenceInfo && this.conferenceInfo.conferenceId === data.conferenceId) {
					this.emit('end-call', { endReason: data.reason });
					this.clearManager();
					if (shouldCloseSocket) {
						this.closeSocket();
					}

					if (playSound) {
						stopOutgoingCallSound();
						if (
							[
								ConferenceEndReason.PARTICIPANT_OFFLINE,
								ConferenceEndReason.PARTICIPANT_BUSY,
								ConferenceEndReason.PARTICIPANT_NOT_ANSWERING,
								ConferenceEndReason.PARTICIPANT_DECLINED,
							].indexOf(data.reason) !== -1
						) {
							await busySound();
						} else {
							await dropSound();
						}
					}
				}
			})
			.bindOn(SocketEvents.Conference.ON_PARTICIPANT_REMOVED, data => {
				const { actioneeParticipantId } = data;
				const participant = this.getParticipantByParticipantId(actioneeParticipantId);
				if (participant) {
					this.updateParticipantStatus(ParticipantState.REMOVED.type, { objectId: participant.objectId });
				}

				const hasPeerConnectionChannelForRemoteId = this.p2p.hasPeerConnectionChannelForRemoteId(actioneeParticipantId);
				if (hasPeerConnectionChannelForRemoteId) {
					this.p2p.stop(actioneeParticipantId);
				}
			})
			.bindOn(SocketEvents.Conference.ON_NEW_PARTICIPANT, async data => {
				if (!this.conferenceInfo) {
					return;
				}
				const { conferenceInfo } = this;
				const { participant } = data;
				if (this.conferenceInfo && !this.conferenceInfo.isMeetingRoom && !this.outGoingCallTimer[participant.objectId]) {
					console.info('Out going call timer has been executed!');
					return;
				}

				this.clearOutGoingCallTimerForParticipant({ objectId: participant.objectId });
				if (conferenceInfo.callType === CallTypes.SECURITY_CAM) {
					this.cameraFeedCall();
				} else if (conferenceInfo.callType === CallTypes.MONITORING) {
					this.clearMonitoringReAddInterval(participant.objectId);
					if (!participant?.isAmbient) {
						await this.monitoringSendOffer(participant.id);
					} else {
						this.emit('participant-status', {
							status: ParticipantState.CONNECTED.type,
							objectId: participant.objectId,
						});
					}
				} else if (
					conferenceInfo.callType === CallTypes.AUDIO ||
					(conferenceInfo.callType === CallTypes.FIRST_RESPONDER && !conferenceInfo.isVideo)
				) {
					this.audioCall(conferenceInfo.callType === CallTypes.FIRST_RESPONDER ? data.participant : null);
				} else if (
					conferenceInfo.callType === CallTypes.VIDEO ||
					(conferenceInfo.callType === CallTypes.FIRST_RESPONDER && conferenceInfo.isVideo)
				) {
					const { allowedRemoteIds } = this.p2p;
					if (!allowedRemoteIds.includes(participant.id)) {
						this.p2p.allowedRemoteIds.push(participant.id);
					}
					// add new participant or overwrite participant with the updates values
					this.participants[participant.objectId] = participant;
					this.callerParticipantId = this.conferenceInfo.isMeetingRoom ? participant.id : this.callerParticipantId;
					this.videoCall();
				}

				if (this.localSrc) {
					const { mediaStream } = this.localSrc;
					if (mediaStream) {
						mediaStream.getTracks().forEach(track => this.bindOnTrackEndedEventListener(track, participant.id));
					}
				}
			})
			.bindOn(SocketEvents.Conference.ON_UPDATE_PARTICIPANTS, data => {
				const { conferenceInfo } = this;
				const { allowedRemoteIds } = this.p2p;
				const { conferenceId, participants } = data;
				if (conferenceInfo && conferenceId !== conferenceInfo.conferenceId) {
					return;
				}
				if (participants.length === 0) {
					console.error('No participant to update');
					return;
				}

				// Clear re-add interval in-case participant is active and trying to reconnect
				participants.forEach(updatedParticipant => {
					if (
						[ParticipantState.CONNECTED.type, ParticipantState.CONNECTING.type, ParticipantState.RECONNECTING.type].includes(
							updatedParticipant.state
						)
					) {
						this.clearMonitoringReAddInterval(updatedParticipant.objectId);
					}
				});

				// we choose updateParticipants event to insert the new participants because those are all invited participants
				// even if they don't join we still get the information about the participant being invited for a call
				// we will use participant data to send leave or remove event later during the call
				const participant = participants[0];
				// don't update the state for participant that left the call
				if (
					this.participants[participant.objectId] &&
					(this.participants[participant.objectId].state === ParticipantState.LEFT_CALL.type ||
						this.participants[participant.objectId].state === ParticipantState.DISCONNECTED.type) &&
					participant.state !== ParticipantState.CONNECTING.type
				) {
					return;
				}

				if (!allowedRemoteIds.includes(participant.id)) {
					this.p2p.allowedRemoteIds.push(participant.id);
				}
				// add new participant or overwrite participant with the updates values
				this.participants[participant.objectId] = { ...this.participants[participant.objectId], ...participant };

				if (participant.isAmbient && participant.state !== ParticipantState.CONNECTING.type) {
					this.emit('participant-status', {
						status: participant.state,
						objectId: participant.objectId,
					});
				}

				this.emit('update-participant', participants);
			})
			.bindOn(SocketEvents.Client.ON_CONNECT, async () => {
				console.log('Socket connected \n');
				this.emit('socket-state', { socketState: SocketState.CONNECTED });
				if (this.reconnectTimer !== null) {
					clearTimeout(this.reconnectTimer);
					this.reconnectTimer = null;
				}
			})
			.bindOn(SocketEvents.Client.ON_AUTHENTICATED, async () => {
				if (this.conferenceInfo && this.callStartedOrJoined) {
					const data = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.PARTICIPANT_RECONNECT, {
						conferenceId: this.conferenceInfo.conferenceId,
						participantId: this.conferenceInfo.participantId,
						isAudio: this.conferenceInfo.isAudio,
						isVideo: this.conferenceInfo.isVideo,
						isScreen: this.conferenceInfo.isScreen,
					});
					if (!data.isActive) {
						this.endCall({ endReason: ConferenceEndReason.DROPPED });
						return;
					}

					const remoteParticipants = Object.values(this.participants);
					if (this.conferenceInfo.callType === CallTypes.MONITORING && remoteParticipants.length > 0) {
						remoteParticipants.forEach(participant => {
							this.requestHelloDeviceState(participant.id);
						});
					}
				}
			})
			// .off(SocketEvents.Client.ON_DISCONNECT, this.socketListeners[SocketEvents.Client.ON_DISCONNECT])
			.bindOn(SocketEvents.Client.ON_DISCONNECT, reason => {
				this.emit('socket-state', { socketState: SocketState.DISCONNECTED });
				console.warn('Disconnected from websocket server.', reason, new Date().getTime());
				this.socketDisconnectReason = reason;
				if (reason === 'io client disconnect') {
					return;
				}

				if (reason === 'io server disconnect') {
					this.socket.connect();
				}

				// if after 60 seconds we still don't have a connection than show view connection lost
				this.reconnectTimer = setTimeout(() => {
					clearTimeout(this.reconnectTimer);
					this.reconnectTimer = null;
					if (this.conferenceInfo) {
						this.endCall({ endReason: ConferenceEndReason.DROPPED });
					}
				}, 60000);
			})
			// .off(SocketEvents.Client.ON_RECONNECT, this.socketListeners[SocketEvents.Client.ON_RECONNECT])
			// .off(SocketEvents.Client.ON_RECONNECTING, this.socketListeners[SocketEvents.Client.ON_RECONNECTING])
			// .off(SocketEvents.Client.ON_CONNECT_ERROR, this.socketListeners[SocketEvents.Client.ON_CONNECT_ERROR])
			.bindOn(SocketEvents.Client.ON_CONNECT_ERROR, () => {
				console.error('Server not responding!');
			})
			.off(SocketEvents.User.ON_PASSWORD_CHANGED)
			.bindOn(SocketEvents.User.ON_PASSWORD_CHANGED, () => {
				if (!this.conferenceInfo) {
					window.location.href = '/logout';
					return;
				}

				const { callType } = this.conferenceInfo;
				this.endCall({ endReason: ConferenceEndReason.PARTICIPANT_PASSWORD_CHANGED });
				if (callType === CallTypes.MONITORING) {
					window.location.href = '/logout';
				}
			})
			.bindOn(SocketEvents.Conference.ON_TERMINATE_REQUEST, async data => {
				// eslint-disable-next-line no-console
				if (data.conferenceId === this.conferenceInfo?.conferenceId) {
					const response = await this.p2p.sendMessageToSignaling(SocketEvents.Conference.TERMINATE_REQUEST_ACCEPT, {
						conferenceId: data.conferenceId,
					});
					if (!response.ok) {
						if (response.failureReason === TerminateRequestFailureReasonEnum.NULL_TERMINATE_REQUEST) {
							// Terminate request was cancled
							// eslint-disable-next-line no-console
							console.log('Terminate request was cancled');
						}
						return;
					}
					this.endCall({ endReason: ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR });
				}
			})
			// .bindOn(SocketEvents.HelloDevice.ON_SKELETON_DETECTIONS, data => {
			// 	this.emit('draw-skeleton', data);
			// })
			// .bindOn(SocketEvents.HelloDevice.PATIENT_OBJECT_DETECTIONS, data => {
			// 	this.emit('object-detection', data);
			// })
			.bindOn(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, data => {
				this.emit('health-measurements', data);
			})
			.bindOn(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, data => {
				this.emit('stethoscope-recording', data);
			})
			.bindOn(SocketEvents.HelloDevice.PATIENT_INFO, data => {
				this.emit('patient-overview', data);
			})
			.bindOn(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, data => {
				this.emit('iot-toast-messages', data);
			})
			.bindOn(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS, data => {
				this.emit('camera-measurements', data);
			})
			.bindOn(SocketEvents.HelloDevice.TOGGLE_AI_TOOLS, data => {
				this.emit('toggle-ai-tools', data);
			})
			.bindOn(SocketEvents.Conference.WALKIE_TALKIE_VOICE_ACTIVITY, data => {
				this.emit('walkie-talkie-activity', data);
			})
			.bindOn(SocketEvents.HelloDevice.ON_CALL_STATE_CHANGED, data => {
				this.emit('call-state-changed', data);
			})
			.bindOn(SocketEvents.Client.ON_DEVICE_ONLINE, ({ helloDeviceId: objectId }) => {
				const participant = this.participants[objectId];

				if (!participant) {
					this.emit('feed-is-online', { objectId });
					return;
				}

				this.emit('feed-reassign', { objectId });

				this.participants[objectId] = { ...participant, isOnline: true };

				if (this.conferenceInfo.callType === CallTypes.MONITORING) {
					this.startReAddFeedInterval(participant);
				}
			})
			.bindOn(SocketEvents.Client.ON_DEVICE_OFFLINE, ({ helloDeviceId: objectId }) => {
				const participant = this.participants[objectId];

				if (!participant) {
					return;
				}

				this.participants[objectId] = { ...participant, isOnline: false };
			})
			.bindOn(SocketEvents.Conference.REQUEST_DEVICE_STATE_RESPONSE, data => {
				if (!data) {
					console.warn(`Data not provided at ${SocketEvents.Conference.REQUEST_DEVICE_STATE_RESPONSE}`);
					return;
				}
				const { conferenceId, participantId, actioneeParticipantId, state } = data;
				if (conferenceId !== this.conferenceInfo?.conferenceId) {
					console.warn(`Conference ID not matching at ${SocketEvents.Conference.REQUEST_DEVICE_STATE_RESPONSE}`);
					return;
				}

				if (actioneeParticipantId !== this.conferenceInfo.participantId) {
					console.warn(
						`Participant (${actioneeParticipantId}) ID not matching ${SocketEvents.Conference.REQUEST_DEVICE_STATE_RESPONSE}`
					);
					return;
				}

				const helloParticipant = Object.values(this.participants).find(participant => participant.id === participantId);
				if (!helloParticipant) {
					console.warn(
						`Participant (${participantId}) not part of conference at ${SocketEvents.Conference.REQUEST_DEVICE_STATE_RESPONSE}`
					);
					return;
				}

				this.emit('device-state', { helloDeviceId: helloParticipant.objectId, ...state });
			})
			.bindOn(SocketEvents.Conference.ON_PARTICIPANT_NOT_ANSWERING, data => {
				const { conferenceEnded, conferenceId, participantId, reason } = data;

				const participant = this.getParticipantByParticipantId(participantId);

				if (
					this.conferenceInfo?.callType !== CallTypes.MONITORING ||
					conferenceEnded ||
					conferenceId !== this.conferenceInfo.conferenceId ||
					!participant ||
					reason !== ParticipantRemoveReason.DISCONNECTED_PARTICIPANT_CLEANUP
				) {
					return;
				}

				this.startReAddFeedInterval({ ...participant, removeReason: reason }, true);
			});

		return this;
	};

	unbindSocketEventListeners = () => {
		Object.entries(this.socketListeners).forEach(([event, cb]) => this.socket.off(event, cb));

		return this;
	};

	updateParticipantStatus = (status, data) => {
		const objectId = data.objectId || data.helloDeviceId;
		this.emit('participant-status', { status, objectId, reason: data?.reason });
		if (objectId) {
			const participant = this.participants[objectId];
			if (participant && status === ParticipantState.OFFLINE.type) {
				participant.isOnline = false;
			}
			this.clearOutGoingCallTimerForParticipant({ objectId });
		}
	};

	getInputStreamsStatus = async () => {
		if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
			console.warn('enumerateDevices() not supported.');
			return;
		}

		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const status = {
				mic: {
					available: false,
					permission: false,
				},
				cam: {
					available: false,
					permission: false,
				},
			};

			devices.forEach(device => {
				if (device.kind === 'audioinput') {
					status.mic.available = true;
					if (device.label) {
						status.mic.permission = true;
					}
				} else if (device.kind === 'videoinput') {
					status.cam.available = true;
					if (device.label) {
						status.cam.permission = true;
					}
				}
			});

			return status;
		} catch (err) {
			console.error(err);
			console.warn('cann`t enumerate devices');
		}
	};

	checkForStreamPermissions = options => {
		return new Promise(resolve => {
			const { isAudio, isVideo } = options;
			const streamInterval = setInterval(async () => {
				const status = await this.getInputStreamsStatus();
				const streamPermission = this.validateStreamStatus(isAudio, isVideo, status);
				if (!streamPermission) {
					this.emit('stream-permission', null);
					clearInterval(streamInterval);
					resolve();
				}
			}, 2000);
		});
	};

	/**
	 * Method used to start outgoing call timer for a participant
	 * @function startOutGoingCallTimerForParticipant
	 * @param {Object}  data
	 * @param {Number}  data.objectId
	 */
	startOutGoingCallTimerForParticipant = data => {
		const { objectId } = data;

		if (!objectId) {
			console.warn('Missing participant object id.');
			return;
		}

		if (this.outGoingCallTimer[objectId]) {
			this.clearOutGoingCallTimerForParticipant({ objectId });
		}

		this.outGoingCallTimer[objectId] = setTimeout(() => {
			stopOutgoingCallSound();
			// clearOutGoingCallTimerForParticipant will be cleared in updateParticipantStatus function

			if (!this.reAddToMonitoringIntervals.get(objectId)) {
				this.updateParticipantStatus(ParticipantState.NOT_ANSWERING.type, { objectId });
			}

			const participant = this.participants[objectId];
			if (!participant) {
				console.error('Participant was not found in participants.');
				return;
			}
			if (this.conferenceInfo.callType !== CallTypes.MONITORING) {
				this.endCall({ endReason: ConferenceEndReason.PARTICIPANT_NOT_ANSWERING });
			} else if (!this.reAddToMonitoringIntervals.get(objectId)) {
				this.removeDeviceFromMonitoring({
					conferenceId: this.conferenceInfo.conferenceId,
					participantId: this.conferenceInfo.participantId,
					actioneeParticipantId: participant.id,
				});
			}
		}, 60000);
	};

	/**
	 * Method used to clear the timer for outgoing call timer for a participant
	 * @function clearOutGoingCallTimerForParticipant
	 * @param {Object} data
	 * @param {Number}    data.objectId
	 */
	clearOutGoingCallTimerForParticipant = data => {
		const { objectId } = data;

		if (this.outGoingCallTimer && this.outGoingCallTimer[objectId]) {
			clearTimeout(this.outGoingCallTimer[objectId]);
			delete this.outGoingCallTimer[objectId];
		}
	};

	toggleAiTools = (isEnabled, aiTool, helloDeviceId, conferenceId, participantId) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.TOGGLE_AI_TOOLS, {
			isEnabled: isEnabled,
			aiOption: aiTool,
			helloDeviceId: helloDeviceId,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	startMeasuringIoTDevice = ({
		iotDevice,
		helloDeviceId,
		conferenceId,
		participantId,
		iotDeviceType = '',
		objectType,
		measureDeviceType = MeasureDeviceType.VITAL_KIT,
		startMeasure = true,
		doctorId = null,
		deviceId = null,
	}) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.START_MEASURING_IOT_DEVICES, {
			iotDevice,
			helloDeviceId,
			conferenceId,
			participantId,
			iotDeviceType,
			objectType,
			measureDeviceType,
			startMeasure,
			doctorId,
			deviceId,
		});
	};

	sendFallDetected = data => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.SEND_FALL_DETECTED, data);
	};

	cancelPersonUpAlert = (helloDeviceId, conferenceId, participantId, type) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.CANCEL_AI_ALERT, {
			helloDeviceId: helloDeviceId,
			conferenceId: conferenceId,
			participantId: participantId,
			type: type,
		});
	};

	toggleHealthData = ({
		isEnabled,
		helloDeviceId,
		conferenceId,
		participantId,
		toolType = SocketEvents.HelloDevice.TOOLTYPE_PATIENTOVERVIEW,
	}) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.TOGGLE_HEALTH_DATA, {
			isEnabled: isEnabled,
			toolType,
			helloDeviceId: helloDeviceId,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	toggleCameraMeasurements = ({ isEnabled, conferenceId, participantId }) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS_TOGGLE, {
			isEnabled: isEnabled,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	toggleStethoscope = ({ isEnabled, helloDeviceId, conferenceId, participantId }) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.TOGGLE_STETHOSCOPE, {
			isEnabled: isEnabled,
			toolType: SocketEvents.HelloDevice.TOOLTYPE_STETHOSCOPE,
			helloDeviceId: helloDeviceId,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	/**
	 * Remove peer connection channel track by id
	 * @function removeTrackById
	 * @param {string} participantId
	 * @param {string}    trackId
	 */
	removeTrackById = (participantId, trackId) => {
		const offer = this.newOffer(this.conferenceInfo.isAudio, this.conferenceInfo.isVideo, this.conferenceInfo.isScreen);
		if (this.conferenceInfo.callType === CallTypes.MONITORING) {
			offer.isAudio = true;
			offer.actioneeParticipantId = participantId;
		}

		this.p2p.removeTrack(participantId, trackId, offer);
	};

	getStats = () => {
		return this.p2p.getStats(this.callerParticipantId);
	};

	isIoConnected = () => {
		return this.socket !== null && this.socket.connected;
	};

	onPeerConnectionCreated = (pccObj, remoteParticipantId) => {
		const exposePeerconnections =
			process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
				? process.env.REACT_APP_EXPOSE_PEERCONNECTIONS
				: window.__env__.REACT_APP_EXPOSE_PEERCONNECTIONS;
		if (exposePeerconnections === 'true') {
			if (!window.peerConnections) {
				window.peerConnections = [];
			}
			window.peerConnections.push(pccObj);
		}

		if (this._useCallStats) {
			this._callStats.addPeerConnection({
				pcObject: pccObj,
				pushInterval: this._sendCallStatsInterval,
				conferenceId: this.conferenceInfo.conferenceId,
				participantId: this.conferenceInfo.participantId,
				remoteParticipantId: remoteParticipantId,
				userId: getStorage().getItem('userId'),
			});
		}

		pccObj.onconnectionstatechange = event => this.onConnectionStateChange(event, remoteParticipantId);
	};

	onConnectionStateChange = (event, remoteParticipantId, stream) => {
		if (!this.conferenceInfo) {
			return;
		}
		this.p2p.sendMessageToSignaling(SocketEvents.Conference.PEER_CONNECTION_STATE_CHANGED, {
			conferenceId: this.conferenceInfo.conferenceId,
			participantId: this.conferenceInfo.participantId,
			actioneeParticipantId: remoteParticipantId,
			state: event.currentTarget.connectionState,
			peerConnection: event.currentTarget,
		});

		const { connectionState } = event.currentTarget;
		console.log(`Connection state changed "${connectionState}" \nParticipantId ${remoteParticipantId}`);
		const { objectId } = this.getParticipantByParticipantId(remoteParticipantId);

		this.participants[objectId].peerConnectionState = connectionState;
		if (connectionState === RTCPeerConnectionEnum.CONNECTION_STATE.FAILED && !this.participants[objectId].reconnecting) {
			this.participants[objectId].reconnecting = true;
			// Comment reason: Hello participant is impolite and will trigger renegotiation
			// we will only send participant reconnect event after socket authorization
			// this.handleReconnectingWithParticipant(remoteParticipantId, stream);
		} else if (connectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED && this.participants[objectId].reconnecting) {
			this.participants[objectId].reconnecting = false;
		}
		this.emit('peer-connection-state', {
			participantObjectId: objectId,
			peerConnectionState: connectionState,
		});
	};

	/**
	 * Get participant from participants by participant id
	 * @function getParticipantById
	 * @param {string} participantId
	 * @returns {Object} participant
	 */
	getParticipantByParticipantId = participantId => {
		const participant = Object.values(this.participants).find(p => p.id.toString() === participantId);
		return participant;
	};

	sendMediaControlsEvent = ({ conferenceId, participantId, actioneeParticipantId, command, data, type }) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			actioneeParticipantId,
			conferenceId,
			participantId,
			command,
			data,
			type,
		});
	};

	rebootHuddleCam = ({ conferenceId, participantId, helloDeviceId }) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.HelloDevice.COMMAND, {
			conferenceId,
			participantId,
			helloDeviceId,
			command: DeviceCommands.REBOOT_HUDDLE_CAM,
		});
	};

	/**
	 * @param {string} message Message to log
	 * @param {object} [rest] Metadata
	 * @returns {Promise}
	 */
	publishConferenceLog = (message, rest = {}) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.LOG, {
			message: message,
			participantId: this.conferenceInfo.participantId,
			conferenceId: this.conferenceInfo.conferenceId,
			...rest,
		});
	};

	/**
	 * Use conference logger to publish events to call stats
	 * @typedef {object} LogData
	 * @property {string[]} [actioneeParticipantIds]
	 * @property {number} [errorType]
	 * @property {boolean} [isAmbient]
	 * @param {object} data
	 * @param {string} data.key
	 * @param {string} data.message
	 * @param {string} [data.conferenceId]
	 * @param {string} [data.participantId]
	 * @param {typeof CallStatsLogType[keyof typeof CallStatsLogType]} [data.logType]
	 * @param {LogData} [data.data={}]
	 */
	publishConferenceLogger = ({
		key,
		message,
		conferenceId,
		participantId,
		logType = CallStatsLogType.INFORMATION,
		data = {},
	}) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.LOGGER, {
			conferenceId: conferenceId || this.conferenceInfo.conferenceId,
			participantId: participantId || this.conferenceInfo.participantId,
			key: {
				value: key,
			},
			message,
			logType,
			data,
		});
	};

	monitoringTrackToggled = async (isEnabled, actioneeParticipantId) => {
		const toggleInfo = {
			conferenceId: this.conferenceInfo.conferenceId,
			participantId: this.conferenceInfo.participantId,
			actioneeParticipantId: actioneeParticipantId,
			hasAudio: isEnabled,
		};
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.AUDIO_TRACK_TOGGLED, toggleInfo);
	};

	unbindHealthMeasurementEventListeners = () => {
		this.socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS);
	};

	walkieTalkieVoiceActicity = ({ id, name, pic, objectIds, isTalking, conversationId }) => {
		this.p2p.sendMessageToSignaling(SocketEvents.Conference.WALKIE_TALKIE_VOICE_ACTIVITY, {
			id,
			name,
			pic,
			objectIds,
			isTalking,
			conversationId,
		});
	};

	requestHelloDeviceState = actioneeParticipantId => {
		this.p2p.sendMessageToSignaling(SocketEvents.Conference.REQUEST_DEVICE_STATE, {
			conferenceId: this.conferenceInfo.conferenceId,
			participantId: this.conferenceInfo.participantId,
			actioneeParticipantId,
		});
	};

	/**
	 * Update participant isAmbient property and send conference.ambientMonitoringToggle log for stats
	 * @param {string[]} actioneeParticipantId
	 * @param {boolean} isAmbient
	 */
	monitoringFeedAmbientToggle = (actioneeParticipantId, isAmbient) => {
		return this.p2p.sendMessageToSignaling(SocketEvents.Conference.AMBIENT_MONITORING_TOGGLE, {
			conferenceId: this.conferenceInfo.conferenceId,
			participantId: this.conferenceInfo.participantId,
			actioneeParticipantId,
			isAmbient,
		});
	};
}

export default CallManager;
