import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getLatestManualAlerts, saveManualAlert } from 'api/alerts.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import ReactSelect from 'react-select';
import { CareEventTypes, OutOfRoomEnums } from 'constants/enums.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { PatientOutOfRoomIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { getDeviceOwnerPatient } from 'api/patients.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';
import { outOfTheRoomOptions } from 'constants/monitoring.js';

const PatientOutOfRoom = ({ patientId, feed, patientOutOfRoomCareEvent, patientReturnedCareEvent, conferenceId }) => {
	const intl = useIntl();
	const otherOption = 40;
	const socket = useContext(SocketContext);

	const user = useSelector(state => state.user);
	const [expandedBox, setExpandedBox] = useState(true);
	const [selectedReason, setSelectedReason] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setIsLoading] = useState(false);

	const [noteValue, setNoteValue] = useState('');
	const userSession = useSelector(state => state.user.userSession);
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const dispatch = useDispatch();

	useEffect(() => {
		const getEvents = async () => {
			const response = await getCareEvents({
				pageSize: 10,
				pageIndex: 0,
				healthSystemId: userSession.healthSystem.id,
				teamCareEventType: CareEventTypes.SafetyCompanions,
			});
			if (response.error) {
				setIsLoading(false);
				return;
			}

			setIsLoading(false);
		};
		getEvents();
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		const getPatientOutOfRoomStatus = async () => {
			const names = [OutOfRoomEnums.PATIENT_OUT_OF_ROOM, OutOfRoomEnums.PATIENT_RETURNED_TO_ROOM];
			let existingPatientId = patientId;
			if (!patientId) {
				const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
				if (deviceOwnerResponse.error) {
					setError(deviceOwnerResponse.error.message);
					return;
				}
				existingPatientId = deviceOwnerResponse?.healthcareUserId;
			}
			const response = await getLatestManualAlerts(existingPatientId, feed.deviceId, names);
			if (response.error) {
				setError(this.props.intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const outOfRoom = response.devicePatientLatestManualAlerts.find(
				item => item.typeName === OutOfRoomEnums.PATIENT_OUT_OF_ROOM
			);
			const backToRoom = response.devicePatientLatestManualAlerts.find(
				item => item.typeName === OutOfRoomEnums.PATIENT_RETURNED_TO_ROOM
			);
			if (
				(outOfRoom && !backToRoom) ||
				(outOfRoom && backToRoom && new Date(outOfRoom.dateCreated) > new Date(backToRoom.dateCreated))
			) {
				const comment = outOfRoom.properties && isJSON(outOfRoom.properties) ? JSON.parse(outOfRoom.properties).Comment : '';

				if (outOfTheRoomOptions(intl).find(item => item.label === comment)) {
					setSelectedReason({
						label: comment,
						value: outOfTheRoomOptions(intl).find(item => item.label === comment)?.id,
					});
					return;
				}
				setSelectedReason({
					label: intl.formatMessage({ id: 'other' }),
					value: otherOption,
				});
				setNoteValue(comment);
			}
		};

		getPatientOutOfRoomStatus();
	}, [feed.deviceId, intl, patientId]);

	useEffect(() => {
		const handleAlertsResponse = async data => {
			if (feed.deviceId !== data.deviceId) {
				return;
			}
			const { isDraft, comment } = data;
			const isPatientOutOfRoom = data.alertTypeName?.toLowerCase() === OutOfRoomEnums.PATIENT_OUT_OF_ROOM.toLowerCase();
			if (isDraft && isPatientOutOfRoom) {
				if (comment && outOfTheRoomOptions(intl).find(item => item.label === comment)) {
					setSelectedReason({
						label: comment,
						value: outOfTheRoomOptions(intl).find(item => item.label === comment)?.id,
					});
					return;
				}
				setSelectedReason({
					label: intl.formatMessage({ id: 'other' }),
					value: otherOption,
				});
				setNoteValue(comment);
			}
		};

		socket.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleAlertsResponse);
		return () => {
			socket.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleAlertsResponse);
		};
	}, [feed.deviceId, intl, patientId, socket]);

	useEffect(() => {
		const foundItem = outOfTheRoomOptions(intl).find(
			item => item.label.toLowerCase() === patientNotes[feed.deviceId]?.outOfRoomReason?.toLowerCase()
		);
		setSelectedReason(
			foundItem || {
				label: outOfTheRoomOptions(intl)[0].label,
				value: outOfTheRoomOptions(intl)[0].id,
			}
		);
	}, [feed.deviceId]);

	const togglePatientOutofRoom = async () => {
		if (!selectedReason) {
			setError('Select a reason please!');
			return;
		}
		let existingPatientId = patientId;
		if (!patientId) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(feed.deviceId);
			if (deviceOwnerResponse.error) {
				setError(deviceOwnerResponse.error.message);
				return;
			}
			existingPatientId = deviceOwnerResponse?.healthcareUserId;
		}
		const dataToSend = {
			patientId: existingPatientId,
			deviceId: feed.deviceId,
			manualAlertTypeId: patientNotes[feed.deviceId].isPatientOutOfRoom
				? patientReturnedCareEvent?.id
				: patientOutOfRoomCareEvent?.id,
			comment: selectedReason.value === otherOption ? noteValue : selectedReason.label,
			isDraft: !patientNotes[feed.deviceId].isPatientOutOfRoom,
			conferenceId,
		};
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientNotes[feed.deviceId],
					isPatientOutOfRoom: !patientNotes[feed.deviceId].isPatientOutOfRoom,
					outOfRoomReason: selectedReason.label,
					draftsCount: patientNotes[feed.deviceId].isPatientOutOfRoom
						? patientNotes[feed.deviceId].draftsCount
						: patientNotes[feed.deviceId].draftsCount + 1,
				},
				feed.deviceId
			)
		);
		setError(null);
	};

	useEffect(() => {
		setError(null);
	}, [selectedReason]);

	return (
		<>
			<div className='monitoring-timeline-box patient-out-of-room'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title flex-1'>
						<PatientOutOfRoomIcon />
						{translate('patientOutOfRoom')}
					</p>
					<div className='timeline-box-actions'>
						<Button
							border='none'
							style={{ top: 0 }}
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='flex flex-align-center'>
							<ReactSelect
								options={outOfTheRoomOptions(intl).map(item => ({
									label: item.label,
									value: item.id,
								}))}
								value={selectedReason}
								onChange={item => setSelectedReason(item)}
								id='select'
								className='select-voice-announcements'
								classNamePrefix='react-select'
								styles={generateCustomStyles({ darkMode: user.darkMode })}
								isDisabled={patientNotes[feed.deviceId]?.isPatientOutOfRoom}
							/>
							<div className='toggle-switch' onClick={togglePatientOutofRoom}>
								<input type='checkbox' checked={patientNotes[feed.deviceId]?.isPatientOutOfRoom} onChange={() => null} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
						{error && <p className='error'>{error}</p>}
						{selectedReason?.value === otherOption && (
							<textarea
								className='text-input'
								onChange={event => setNoteValue(event.target.value)}
								name='note'
								rows={3}
								value={noteValue}
								placeholder={intl.formatMessage({ id: 'addAdditionalNotes' })}
								style={{ fontSize: '14px' }}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default PatientOutOfRoom;
