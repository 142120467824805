import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import AiDrawingIcon from 'components/AiDrawingIcon.jsx';
import {
	AiConfigField,
	AiNotificationTypes,
	FallDetectionThresholdValues,
	GeneralAiSettings,
	GettingOutOfBedThresholdValues,
	ImbalanceTypes,
	PatientAiSettingEditType,
	RailsThresholdValues,
	StatNotificationTypes,
	propertiesToValidate,
} from 'constants/ai.js';
import { AiSetting } from 'constants/configurationEnums.js';
import { AiAnalyticsTypeId, BodyPositionType, PatientAiSetting, RailType, SensitivityType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import HandWashing from 'icons/Monitoring/HandWashing.jsx';
import HeartRate from 'icons/Monitoring/HeartRate.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import PatientMobility from 'icons/Monitoring/PatientMobility.jsx';
import PatientPrivacy from 'icons/Monitoring/PatientPrivacy.jsx';
import PressureInjury from 'icons/Monitoring/PressureInjury.jsx';
import Rails from 'icons/Monitoring/Rails.jsx';
import SoundOnAiAlertIcon from 'icons/Monitoring/SoundOnAiAlertIcon.jsx';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

const getTooltipDescription = id => {
	switch (id) {
		case SensitivityType.HIGH:
			return 'highSensitivity';
		case SensitivityType.MEDIUM:
			return 'mediumSensitivity';
		case SensitivityType.LOW:
			return 'lowSensitivity';
		default:
			return ' ';
	}
};

export const aiFormatOptionLabel = (data, intl) => (
	<div className='flex flex-space-between'>
		<div>{data.label}</div>
		<div data-position='left' data-tooltip={intl.formatMessage({ id: getTooltipDescription(data.value) })}>
			<i className='material-icons-outlined font-13'>info</i>
		</div>
	</div>
);

export const getSelectedAiType = settingTypeId => {
	switch (settingTypeId) {
		case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
			return 'getOutOfBed';
		case PatientAiSetting.RAILS:
			return 'rails';
		case PatientAiSetting.FALL_DETECTED:
			return 'fallDetection';
		case PatientAiSetting.PRESSURE_INJURY:
			return 'pressureInjury';
		case PatientAiSetting.PATIENT_MOBILITY:
			return 'patientMobility';
		default:
			return ' ';
	}
};

export const SnoozeTimers = intl => [
	{ label: intl.formatMessage({ id: 'noSnooze' }), value: 0, isChecked: false, isDefault: false },
	{ label: `30 ${intl.formatMessage({ id: 'seconds' })}`, value: 30, isChecked: false, isDefault: false },
	{ label: `1 ${intl.formatMessage({ id: 'minute' })}`, value: 60, isChecked: false, isDefault: false },
	{ label: `2 ${intl.formatMessage({ id: 'minutes' })}`, value: 120, isChecked: false, isDefault: false },
	{ label: `5 ${intl.formatMessage({ id: 'minutes' })}`, value: 300, isChecked: false, isDefault: false },
	{ label: `10 ${intl.formatMessage({ id: 'minutes' })}`, value: 600, isChecked: false, isDefault: false },
	{ label: `30 ${intl.formatMessage({ id: 'minutes' })}`, value: 1800, isChecked: false, isDefault: false },
	{ label: `1 ${intl.formatMessage({ id: 'hour' })}`, value: 3600, isChecked: false, isDefault: false },
	{ label: `2 ${intl.formatMessage({ id: 'hours' })}`, value: 7200, isChecked: false, isDefault: false },
	{ label: `4 ${intl.formatMessage({ id: 'hours' })}`, value: 14400, isChecked: false, isDefault: false },
];

export const RailOptions = intl => [
	{
		label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'head' })} ${intl.formatMessage({ id: 'rail' })}`,
		value: RailType.TOP_LEFT,
		isChecked: false,
		isDefault: false,
	},
	{
		label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'head' })} ${intl.formatMessage({ id: 'rail' })}`,
		value: RailType.TOP_RIGHT,
		isChecked: false,
		isDefault: false,
	},
	{
		label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'foot' })} ${intl.formatMessage({ id: 'rail' })}`,
		value: RailType.BOTTOM_LEFT,
		isChecked: false,
		isDefault: false,
	},
	{
		label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'foot' })} ${intl.formatMessage({ id: 'rail' })}`,
		value: RailType.BOTTOM_RIGHT,
		isChecked: false,
		isDefault: false,
	},
];

export const BodyPosition = intl => [
	{
		label: intl.formatMessage({ id: 'upInAChair' }),
		value: BodyPositionType.SITTING,
		isChecked: false,
		isDefault: false,
	},
	{
		label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'lateral' })}`,
		value: BodyPositionType.LYING_LEFT_SIDE,
		isChecked: false,
		isDefault: false,
	},
	{
		label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'lateral' })}`,
		value: BodyPositionType.LYING_RIGHT_SIDE,
		isChecked: false,
		isDefault: false,
	},
	{
		label: intl.formatMessage({ id: 'supine' }),
		value: BodyPositionType.LYING_FRONT,
		isChecked: false,
		isDefault: false,
	},
	{
		label: intl.formatMessage({ id: 'prone' }),
		value: BodyPositionType.LYING_DOWN,
		isChecked: false,
		isDefault: false,
	},
];

export const TimerValues = intl => [
	{ label: `10 ${intl.formatMessage({ id: 'seconds' })}`, value: '10', isChecked: false, isDefault: false },
	{ label: `10 ${intl.formatMessage({ id: 'minutes' })}`, value: '600', isChecked: false, isDefault: false },
	{ label: `20 ${intl.formatMessage({ id: 'minutes' })}`, value: '1200', isChecked: false, isDefault: false },
	{ label: `30 ${intl.formatMessage({ id: 'minutes' })}`, value: '1800', isChecked: false, isDefault: false },
	{ label: `1 ${intl.formatMessage({ id: 'hour' })}`, value: '3600', isChecked: false, isDefault: false },
	{ label: `2 ${intl.formatMessage({ id: 'hours' })}`, value: '7200', isChecked: false, isDefault: false },
	{ label: `3 ${intl.formatMessage({ id: 'hours' })}`, value: '10800', isChecked: false, isDefault: false },
	{ label: `4 ${intl.formatMessage({ id: 'hours' })}`, value: '14400', isChecked: false, isDefault: false },
];

export const AiModelCheckTimers = intl => [
	{ label: `2 ${intl.formatMessage({ id: 'seconds' })}`, value: 2 },
	{ label: `5 ${intl.formatMessage({ id: 'seconds' })}`, value: 5 },
	{ label: `10 ${intl.formatMessage({ id: 'seconds' })}`, value: 10 },
	{ label: `20 ${intl.formatMessage({ id: 'seconds' })}`, value: 20 },
	{ label: `30 ${intl.formatMessage({ id: 'seconds' })}`, value: 30 },
	{ label: `1 ${intl.formatMessage({ id: 'minute' })}`, value: 60 },
];

export const YesNo = intl => [
	{ label: intl.formatMessage({ id: 'yes' }), value: true, isChecked: false },
	{ label: intl.formatMessage({ id: 'no' }), value: false, isChecked: false },
];

export const SensitivityTypes = intl => [
	{ label: intl.formatMessage({ id: 'low' }), value: SensitivityType.LOW, isChecked: false, isDefault: false },
	{ label: intl.formatMessage({ id: 'medium' }), value: SensitivityType.MEDIUM, isChecked: false, isDefault: false },
	{ label: intl.formatMessage({ id: 'high' }), value: SensitivityType.HIGH, isChecked: false, isDefault: false },
];

export const initialAiConfigurations = intl => [
	{
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		values: SensitivityTypes(intl),
		thresholdValues: GettingOutOfBedThresholdValues,
		notAloneTriggerAlert: YesNo(intl),
		snoozeValues: SnoozeTimers(intl),
		alerts: AiNotificationTypes(intl),
		statAlerts: StatNotificationTypes(intl),
		coveredRailsTriggerAlert: [],
		aiModelCheckTimer: null,
		bodyPositionValues: [],
		imbalanceAlert: [],
	},
	{
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		values: RailOptions(intl),
		thresholdValues: RailsThresholdValues,
		notAloneTriggerAlert: YesNo(intl),
		snoozeValues: SnoozeTimers(intl),
		alerts: AiNotificationTypes(intl),
		statAlerts: StatNotificationTypes(intl),
		coveredRailsTriggerAlert: YesNo(intl),
		aiModelCheckTimer: null,
		bodyPositionValues: [],
		imbalanceAlert: [],
	},
	{
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		values: [],
		thresholdValues: FallDetectionThresholdValues,
		notAloneTriggerAlert: YesNo(intl),
		snoozeValues: SnoozeTimers(intl),
		alerts: AiNotificationTypes(intl),
		statAlerts: StatNotificationTypes(intl),
		coveredRailsTriggerAlert: [],
		aiModelCheckTimer: null,
		bodyPositionValues: [],
		imbalanceAlert: [],
	},
	{
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		values: TimerValues(intl),
		thresholdValues: [],
		notAloneTriggerAlert: [],
		snoozeValues: SnoozeTimers(intl),
		alerts: AiNotificationTypes(intl),
		statAlerts: StatNotificationTypes(intl),
		coveredRailsTriggerAlert: [],
		aiModelCheckTimer: null,
		bodyPositionValues: BodyPosition(intl),
		imbalanceAlert: [],
	},
	{
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
		values: [],
		thresholdValues: [],
		notAloneTriggerAlert: [],
		snoozeValues: SnoozeTimers(intl),
		alerts: AiNotificationTypes(intl),
		statAlerts: StatNotificationTypes(intl),
		coveredRailsTriggerAlert: [],
		aiModelCheckTimer: null,
		bodyPositionValues: [],
		imbalanceAlert: ImbalanceTypes,
	},
];

const getAiErrorMessage = property => {
	switch (property) {
		case AiConfigField.THRESHOLD_VALUES:
			return 'provideThreshold';
		case AiConfigField.VALUES:
		case AiConfigField.SNOOZE_VALUES:
		case AiConfigField.ALERTS:
		case AiConfigField.BODY_POSITION:
			return 'selectOneCheckbox';
		default:
			return 'pleaseSelectOption';
	}
};

const shouldBeSetAsDefault = prop => [AiConfigField.VALUES, AiConfigField.SNOOZE_VALUES, AiConfigField.ALERTS].includes(prop);

const isNotAloneAlert = (prop, aiConfigurationTypeId) =>
	prop === AiConfigField.NOT_ALONE_TRIGGER_ALERT &&
	![PatientAiSetting.PRESSURE_INJURY, PatientAiSetting.PATIENT_MOBILITY].includes(aiConfigurationTypeId);

const isCoveredRails = (prop, aiConfigurationTypeId) =>
	prop === AiConfigField.COVERED_RAILS_TRIGGER_ALERT && aiConfigurationTypeId === PatientAiSetting.RAILS;

const isBodyPosition = (prop, aiConfigurationTypeId) =>
	prop === AiConfigField.BODY_POSITION && aiConfigurationTypeId === PatientAiSetting.PRESSURE_INJURY;

const isPatientImbalance = (prop, aiConfigurationTypeId) =>
	prop === AiConfigField.IMBALANCE_ALERT && aiConfigurationTypeId === PatientAiSetting.PATIENT_MOBILITY;

const allUnchecked = arr => arr.every(el => !el.isChecked);

export const validateAiConfigurations = (config, intl) => {
	const errors = {};
	const aiConfig = config.aiConfigurations;
	if (!aiConfig) {
		return { errors: { all: 'fillRequiredFieldsAi', aiConfigurationTypeId: config.aiConfigurationTypeId } };
	}

	const validateAiFields = (arr, prop) => {
		if (!arr) {
			return;
		}
		if (
			(shouldBeSetAsDefault(prop) && arr.every(el => !el.isChecked || !el.isDefault)) ||
			(prop === AiConfigField.THRESHOLD_VALUES && arr.some(el => hasFalsyValue(el.value))) ||
			(isNotAloneAlert(prop, config.aiConfigurationTypeId) && allUnchecked(arr)) ||
			(isCoveredRails(prop, config.aiConfigurationTypeId) && allUnchecked(arr)) ||
			(isPatientImbalance(prop, config.aiConfigurationTypeId) && allUnchecked(arr)) ||
			(isBodyPosition(prop, config.aiConfigurationTypeId) && arr.every(el => !el.isChecked || !el.isDefault))
		) {
			errors[prop] = intl.formatMessage({ id: getAiErrorMessage(prop) });
		}
	};

	if (![PatientAiSetting.FALL_DETECTED, PatientAiSetting.PATIENT_MOBILITY].includes(config.aiConfigurationTypeId)) {
		validateAiFields(aiConfig.values, AiConfigField.VALUES);
	}

	if (config.aiConfigurationTypeId === PatientAiSetting.RAILS && !aiConfig.aiModelCheckTimer) {
		errors[AiConfigField.AI_MODE_CHECK_TIMER] = intl.formatMessage({ id: 'pleaseSelectOption' });
	}

	propertiesToValidate.forEach(prop => validateAiFields(aiConfig[prop], prop));

	return { errors, aiConfigurationTypeId: config.aiConfigurationTypeId };
};

export const getCommonRoomAiConfigs = (defaultConfigs, roomConfigs) => {
	if (!roomConfigs) {
		return defaultConfigs;
	}
	return defaultConfigs.filter(item => roomConfigs.some(arr2Item => arr2Item.value === item.value));
};

export const getValue = (obj, propName) => {
	const item = obj[propName] && obj[propName].find(item => item.isChecked);
	return item?.value || false;
};

const fillArray = (types, values) =>
	types.map(type => ({
		...type,
		isChecked: values?.some(val => val.value === type.value),
		isDefault: values?.some(val => val.value === type.value && val.isDefault),
	}));

const filterArray = (types, options, selectedValues) =>
	types.reduce((acc, type) => {
		if (options?.some(val => val.value === type.value)) {
			acc.push({
				...type,
				isChecked: selectedValues?.some(val => val.value === type.value),
				isDefault: selectedValues?.some(val => val.value === type.value && val.isDefault),
			});
		}
		return acc;
	}, []);

const getDefaultThresholdValues = aiConfigurationTypeId => {
	switch (aiConfigurationTypeId) {
		case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
			return GettingOutOfBedThresholdValues;
		case PatientAiSetting.RAILS:
			return RailsThresholdValues;
		case PatientAiSetting.FALL_DETECTED:
			return FallDetectionThresholdValues;
		default:
			return [];
	}
};

export const getImbalanceAlert = (obj, propName) => {
	const item = obj[propName] && obj[propName].find(item => item.isChecked);
	return item?.value || null;
};

const extractCheckedValues = arr =>
	arr.reduce((acc, el) => {
		if (el.isChecked) {
			acc.push({ value: el.value, isDefault: el.isDefault });
		}
		return acc;
	}, []);

export const prepareAiConfigsForSubmit = obj => {
	const alertNotificationOptions = [...obj.alerts, ...obj.statAlerts]
		.filter(el => el.isChecked)
		.map(alert => ({
			value: alert.value,
			isDefault: alert.isDefault,
		}));

	const transformedObj = {
		aiConfigurationTypeId: obj.aiConfigurationTypeId,
		values: extractCheckedValues(obj.values),
		snoozeValues: extractCheckedValues(obj.snoozeValues),
		thresholdValues: obj.thresholdValues,
		alertNotificationOptions,
		aiModelCheckTimer: obj.aiModelCheckTimer,
		coveredRailsTriggerAlert: getValue(obj, 'coveredRailsTriggerAlert'),
		notAloneTriggerAlert: getValue(obj, 'notAloneTriggerAlert'),
		bodyPositionValues: extractCheckedValues(obj.bodyPositionValues),
		imbalanceAlert: getImbalanceAlert(obj, 'imbalanceAlert'),
	};

	return transformedObj;
};

const commonAiConfigs = (aiConfigs, intl) => ({
	aiConfigurationTypeId: aiConfigs.aiConfigurationTypeId,
	thresholdValues:
		aiConfigs.thresholdValues?.length > 0
			? aiConfigs.thresholdValues
			: getDefaultThresholdValues(aiConfigs.aiConfigurationTypeId),
	aiModelCheckTimer: aiConfigs.aiModelCheckTimer,
	notAloneTriggerAlert: YesNo(intl).map(item => ({
		...item,
		isChecked: item.value === aiConfigs.notAloneTriggerAlert,
	})),
	coveredRailsTriggerAlert: YesNo(intl).map(item => ({
		...item,
		isChecked: item.value === aiConfigs.coveredRailsTriggerAlert,
	})),
	imbalanceAlert: ImbalanceTypes.map(item => ({
		...item,
		isChecked: item.value === aiConfigs.imbalanceAlert,
	})),
});

const getValueOptions = (aiConfigurationTypeId, intl) => {
	switch (aiConfigurationTypeId) {
		case PatientAiSetting.PATIENT_GETTING_OUT_OF_BED:
			return SensitivityTypes(intl);
		case PatientAiSetting.RAILS:
			return RailOptions(intl);
		case PatientAiSetting.PRESSURE_INJURY:
			return TimerValues(intl);
		default:
			return [];
	}
};

export const getCompanyAiConfigs = (aiConfigs, intl) => ({
	...commonAiConfigs(aiConfigs, intl),
	values: fillArray(getValueOptions(aiConfigs.aiConfigurationTypeId, intl), aiConfigs.values),
	snoozeValues: fillArray(SnoozeTimers(intl), aiConfigs.snoozeValues),
	alerts: fillArray(AiNotificationTypes(intl), aiConfigs.alertNotificationOptions),
	statAlerts: fillArray(StatNotificationTypes(intl), aiConfigs.alertNotificationOptions),
	bodyPositionValues: fillArray(BodyPosition(intl), aiConfigs.bodyPositionValues),
});

export const getSectorAiConfigs = (aiConfigs, intl) => ({
	...commonAiConfigs(aiConfigs, intl),
	values: filterArray(getValueOptions(aiConfigs.aiConfigurationTypeId, intl), aiConfigs.valuesOptions, aiConfigs.values),
	snoozeValues: filterArray(SnoozeTimers(intl), aiConfigs.snoozeOptions, aiConfigs.snoozeValues),
	alerts: filterArray(AiNotificationTypes(intl), aiConfigs.notificationOptions, aiConfigs.alertNotificationOptions),
	statAlerts: filterArray(StatNotificationTypes(intl), aiConfigs.notificationOptions, aiConfigs.alertNotificationOptions),
	bodyPositionValues: filterArray(BodyPosition(intl), aiConfigs.bodyPositionOptions, aiConfigs.bodyPositionValues),
});

export const aiTypesToShow = ({ adminAiSettingsConfigurations, isDarkMode, intl }) => [
	{
		aiTypeId: PatientAiSetting.FALL_PREVENTION,
		label: intl.formatMessage({ id: 'fallPrevention' }),
		img: '',
		show:
			getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) ||
			getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]) ||
			getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]),
		noBorder: true,
		isTitle: true,
	},
	{
		aiTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		label: intl.formatMessage({ id: 'getOutOfBed' }),
		img: <OutOfBed color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]),
		siblings: [PatientAiSetting.RAILS, PatientAiSetting.FALL_DETECTED],
		noBorder: true,
	},
	{
		aiTypeId: PatientAiSetting.RAILS,
		label: intl.formatMessage({ id: 'rails' }),
		img: <Rails color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]),
		siblings: [PatientAiSetting.PATIENT_GETTING_OUT_OF_BED, PatientAiSetting.FALL_DETECTED],
		noBorder: true,
	},
	{
		aiTypeId: PatientAiSetting.FALL_DETECTED,
		label: intl.formatMessage({ id: 'fallDetection' }),
		img: <FallDetection color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]),
		siblings: [PatientAiSetting.PATIENT_GETTING_OUT_OF_BED, PatientAiSetting.RAILS],
	},
	{
		aiTypeId: PatientAiSetting.PATIENT_MOBILITY,
		label: intl.formatMessage({ id: 'patientMobility' }),
		img: <PatientMobility color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PATIENT_MOBILITY]),
		subTitle: intl.formatMessage({ id: 'patientMobility' }),
	},
	{
		aiTypeId: PatientAiSetting.PRESSURE_INJURY,
		label: intl.formatMessage({ id: 'pressureInjury' }),
		img: <PressureInjury color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.PRESSURE_INJURY]),
		subTitle: intl.formatMessage({ id: 'others' }),
		noBorder: true,
	},
	{
		aiTypeId: PatientAiSetting.SKELETON_PRIVATE_MODE,
		label: intl.formatMessage({ id: 'privacyMode' }),
		img: <PatientPrivacy color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.AI_PRIVACY_MODE]),
		noBorder: true,
	},
	{
		aiTypeId: PatientAiSetting.HAND_WASHING,
		label: intl.formatMessage({ id: 'handWashing' }),
		img: <HandWashing color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.HAND_WASHING]),
		noBorder: true,
	},
	{
		aiTypeId: PatientAiSetting.VITAL_SIGNS_AI,
		label: intl.formatMessage({ id: 'ewsAiVitalSigns' }),
		img: <HeartRate color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.EWS_AI_VITAL_SIGNS]),
	},

	{
		aiTypeId: PatientAiSetting.IV_BAG,
		label: intl.formatMessage({ id: 'ivBagFluidLevel' }),
		img: <IvBag color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.IV_BAG_FLUID_LEVEL]),
		subTitle: 'AI Rounding',
	},
];

export const allPatientAiSettings = ({ adminAiSettingsConfigurations, isDarkMode, intl }) => [
	...aiTypesToShow({ adminAiSettingsConfigurations, isDarkMode, intl }),
	{
		aiTypeId: PatientAiSetting.AI_DRAWING,
		label: intl.formatMessage({ id: 'aIDetectionDrawings' }),
		img: <AiDrawingIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		subTitle: intl.formatMessage({ id: 'general' }),
		noBorder: true,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.AI_DETECTION_DRAWING]),
	},
	{
		aiTypeId: PatientAiSetting.SOUND_ON_AI_ALERT,
		label: intl.formatMessage({ id: 'soundOnAiAlert' }),
		img: <SoundOnAiAlertIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		noBorder: true,
		show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SOUND_ON_AI_ALERT]),
	},
];

export const getAdminAiConfigurationsById = (adminAiConfigurations, settingTypeId, callWorkflowTypeId) =>
	adminAiConfigurations.reduce((acc, curr) => {
		if (curr.aiConfigurations?.aiConfigurationTypeId === settingTypeId && curr.callWorkflowTypeId === callWorkflowTypeId) {
			return curr.aiConfigurations;
		}
		return acc;
	}, null);

export const hasFalsyValue = value => ['false', undefined, null].includes(value);

export const isAnySettingActive = (settings, settingsTypes, currentTypeId, isIncluded = false) =>
	settings
		.filter(el => (isIncluded ? settingsTypes.includes(el.settingTypeId) : !settingsTypes.includes(el.settingTypeId)))
		.some(item => item.isEnabled && item.settingTypeId !== currentTypeId);

export const getConfiguredAiSettings = aiType =>
	[
		PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		PatientAiSetting.RAILS,
		PatientAiSetting.PRESSURE_INJURY,
		PatientAiSetting.PATIENT_MOBILITY,
		PatientAiSetting.FALL_DETECTED,
	].includes(aiType);

export const getAiSettings = (aiSettings = [], deviceId) => {
	const found = aiSettings.find(item => item?.deviceId === deviceId);
	if (!found) {
		return [];
	}
	const result = found.settings?.length > 0 ? found.settings.filter(item => !GeneralAiSettings.includes(item.settingTypeId)) : [];
	return result;
};

export const isSettingEnabled = (settings = [], settingTypeId) =>
	settings.find(item => item?.settingTypeId === settingTypeId)?.isEnabled;

export const mobilityChartDetails = [
	{ id: 1, label: 'lying', color: 'rgba(83, 144, 255, 1)', percentage: 'lyingPercentage', bedAndChair: true },
	{ id: 2, label: 'sitting', color: 'rgba(107, 198, 181, 1)', percentage: 'sittingPercentage', bedAndChair: true },
	{ id: 3, label: 'walking', color: 'rgba(190, 213, 255, 1)', percentage: 'walkingPercentage' },
];

export const pressureInjuryChartDetails = [
	{ id: 1, label: 'leftLateral', color: 'rgba(83, 144, 255, 1)', percentage: 'leftLateralPercentage' },
	{ id: 2, label: 'rightLateral', color: 'rgba(135, 165, 221, 1)', percentage: 'rightLateralPercentage' },
	{ id: 3, label: 'prone', color: 'rgba(107, 198, 181, 1)', percentage: 'pronePercentage' },
	{ id: 4, label: 'supine', color: 'rgba(190, 213, 255, 1)', percentage: 'supinePercentage' },
	{ id: 5, label: 'upInAChair', color: 'rgba(98, 211, 253, 1)', percentage: 'upInAChairPercentage' },
];

export const getAnalyticsTypeById = id => {
	switch (id) {
		case AiAnalyticsTypeId.CHAIR_LYING_FRONT:
		case AiAnalyticsTypeId.BED_LYING_FRONT:
			return 'supine';
		case AiAnalyticsTypeId.CHAIR_LYING_SIDE:
		case AiAnalyticsTypeId.BED_LYING_SIDE:
			return 'lateral';
		case AiAnalyticsTypeId.CHAIR_LYING_DOWN:
		case AiAnalyticsTypeId.BED_LYING_DOWN:
			return 'prone';
		case AiAnalyticsTypeId.CHAIR_SITTING:
		case AiAnalyticsTypeId.BED_SITTING:
			return 'upInAChair';
		case AiAnalyticsTypeId.CHAIR_LYING_LEFT:
		case AiAnalyticsTypeId.BED_LYING_LEFT:
			return 'leftLateral';
		case AiAnalyticsTypeId.CHAIR_LYING_RIGHT:
		case AiAnalyticsTypeId.BED_LYING_RIGHT:
			return 'rightLateral';
		case AiAnalyticsTypeId.WALKING:
			return 'walking';
		default:
			return 'na';
	}
};

export const groupObjectBySimilarPropertyName = obj => {
	const groupedObject = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const matches = key.match(/^([a-zA-Z]+?)(Percentage)?$/);

			if (matches) {
				const baseKey = matches[1];
				const isPercentage = matches[2];

				if (!groupedObject[baseKey]) {
					groupedObject[baseKey] = {};
				}
				if (isPercentage) {
					groupedObject[baseKey].percentage = obj[key];
				} else {
					groupedObject[baseKey].value = obj[key];
				}
			} else {
				groupedObject[key] = obj[key];
			}
		}
	}

	return groupedObject;
};

export const findLabelByValue = (array, value) => {
	const found = array.find(item => item.value?.toString() === value);
	return found?.label ?? null;
};

const parseArrayString = (array, inputStr) => {
	const itemIds = JSON.parse(inputStr);
	if (itemIds.length === 0) {
		return translate('none');
	}
	const mappedItems = itemIds.map(id => {
		const found = array.find(item => item.value === id);
		return found?.label ?? null;
	});
	return mappedItems.join(', ');
};

export const parseInputString = (array, inputStr) => {
	if (inputStr.startsWith('[') && inputStr.endsWith(']')) {
		return parseArrayString(array, inputStr);
	} else if (inputStr.includes('-')) {
		return transformCustomString(inputStr, array);
	}
	return findLabelByValue(array, inputStr);
};

const transformCustomString = (inputStr, array) => {
	const mapping = array.reduce((acc, item) => {
		acc[item.value] = item.label;
		return acc;
	}, {});

	return inputStr
		.split('-')
		.map(item => mapping[item] || item)
		.join(', ');
};

export const createMessage = (key, values) => translate(key, values);

export const formatAiConfigLog = (aiLog, aiType, intl) => {
	const { editorName, editType, editedFrom, editedTo } = aiLog;
	const commonValues = { value1: editorName, value4: <span className='bold font-13'>{aiType}</span> };
	switch (editType) {
		case PatientAiSettingEditType.IS_ENABLED:
			return createMessage('isEnabledMessage', {
				...commonValues,
				value2: intl.formatMessage({ id: JSON.parse(editedTo?.toLowerCase()) ? 'onState' : 'offState' }),
				value3: <span className='bold font-13'>{aiType}</span>,
			});
		case PatientAiSettingEditType.NOTIFICATION_OPTIONS_ALERTS:
			return createMessage('notificationOptionsMessage', {
				...commonValues,
				value2: <span className='bold font-13'>{parseInputString(AiNotificationTypes(intl), editedFrom)}</span>,
				value3: <span className='bold font-13'>{parseInputString(AiNotificationTypes(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.NOTIFICATION_OPTIONS_STAT_ALARM_ACTIVATION:
			return createMessage('statAlarmMessage', {
				...commonValues,
				value2: <span className='bold font-13'>{parseInputString(StatNotificationTypes(intl), editedFrom)}</span>,
				value3: <span className='bold font-13'>{parseInputString(StatNotificationTypes(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.SNOOZE_TIME:
			return createMessage('snoozeTimeMessage', {
				...commonValues,
				value2: <span className='bold font-13'>{parseInputString(SnoozeTimers(intl), editedFrom)}</span>,
				value3: <span className='bold font-13'>{parseInputString(SnoozeTimers(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.RAILS:
			return createMessage('railsOptionsMessage', {
				...commonValues,
				value2: aiType,
				value3: <span className='bold font-13'>{parseInputString(RailOptions(intl), editedFrom)}</span>,
				value4: <span className='bold font-13'>{parseInputString(RailOptions(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.PRESSURE_INJURY_TIMER:
			return createMessage('pressureInjuryTimerMessage', {
				value1: editorName,
				value2: <span className='bold font-13'>{parseInputString(TimerValues(intl), editedFrom)}</span>,
				value3: <span className='bold font-13'>{parseInputString(TimerValues(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.PRESSURE_INJURY_BODY_POSITIONS:
			return createMessage('pressureInjuryPositionsMessage', {
				value1: editorName,
				value2: <span className='bold font-13'>{parseInputString(BodyPosition(intl), editedFrom)}</span>,
				value3: <span className='bold font-13'>{parseInputString(BodyPosition(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.GOB_SENSITIVITY:
			return createMessage('sensitivityOptionsMessage', {
				...commonValues,
				value2: <span className='bold font-13'>{parseInputString(SensitivityTypes(intl), editedFrom)}</span>,
				value3: <span className='bold font-13'>{parseInputString(SensitivityTypes(intl), editedTo)}</span>,
			});
		case PatientAiSettingEditType.IV_BAG_WARNING_VALUE:
			return createMessage('ivBagValueMessage', {
				value1: editorName,
				value2: <span className='bold font-13'>{editedFrom}</span>,
				value3: <span className='bold font-13'>{editedTo}</span>,
			});
		default:
			return '';
	}
};

export const handHygieneDashboardDetails = [
	{ id: 1, label: 'compliant', color: 'rgb(95, 146, 255)' },
	{ id: 2, label: 'nonCompliant', color: 'rgb(121, 198, 182)' },
];

export const handHygieneDashboardIdentifications = [
	{ id: 1, label: 'doctor', color: 'rgb(95, 146, 255)' },
	{ id: 2, label: 'pca', color: 'rgb(121, 198, 182)' },
	{ id: 3, label: 'rn', color: 'rgba(190, 213, 255, 1)' },
	{ id: 4, label: 'other', color: 'rgb(135, 165, 221)' },
];

export const areSettingValuesEqual = (currentSetting, newSetting) => {
	const currentValue = currentSetting?.value ?? currentSetting;
	const newValue = newSetting?.value ?? newSetting;
	return currentValue === newValue;
};
