import React, { useEffect, useRef } from 'react';

const SmallModal = props => {
	const wrapperRef = useRef(null);
	const closeModal = useRef(props.onModalClose);

	useEffect(() => {
		const handleClickOutside = event => {
			if (wrapperRef && !wrapperRef.current.contains(event.target)) {
				closeModal.current();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div ref={wrapperRef} className='small-modal'>
			<section className='modal-main'>{props.children}</section>
		</div>
	);
};

export default SmallModal;
