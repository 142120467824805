import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import {
	getAppointments,
	updateAppointmentStatus,
	getUpcomingAppointments,
	getAppointmentInvitations,
} from 'api/appointments.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import ScheduleAppointment from 'containers/Appointments/ScheduleAppointment.jsx';
import UpcomingAppointments from 'containers/Appointments/UpcomingAppointments.jsx';
import EditScheduleAppointment from 'containers/Appointments/EditScheduleAppointment.jsx';
import AppointmentsHistory from 'containers/Appointments/AppointmentsHistory.jsx';
import AppointmentRequests from 'containers/Appointments/AppointmentRequests.jsx';
import Loader from 'components/Loader.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { UserRoles, AppointmentStatus } from 'constants/enums.js';
import EmptyState from 'components/EmptyState.jsx';
import SetUpAvailability from 'containers/Appointments/SetUpAvailability.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';
import Modal from 'components/Modal.jsx';
import AppointmentDetailsModal from 'components/Appointments/AppointmentDetailsModal.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import AppointmentSlotDetails from 'containers/Appointments/AppointmentSlotDetails.jsx';
import classNames from 'classnames';
import Button from 'components/Button.jsx';
import { LOCALES } from 'i18n-translations/locales.js';

const Appointments = props => {
	const fullCalendarRef = React.createRef();
	const socket = useContext(SocketContext);
	const { current: userId } = useRef(getUserId());
	const { current: userRole } = useRef(getUserRole());
	const today = new Date();
	const [error, setError] = useState(null);
	const locale = useSelector(state => state.language.locale);
	const [isCreateAppointmentOpen, setIsCreateAppointmentOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(today);
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [selectedViewGrid, setSelectedViewGrid] = useState('timeGridDay');
	const [upcomingEventAppointments, setUpcomingEventAppointments] = useState([
		{
			title: '',
			start: '',
			end: '',
			description: '',
		},
	]);
	const [isEventEditModal, setIsEventEditModal] = useState(false);
	const [isEditAppointmentOpen, setIsEditAppointmentOpen] = useState(false);
	const [isCalendarDateDropdownVisible, setIsCalendarDateDropdownVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
	const [isSetUpAvailabilityModalVisible, setIsSetUpAvailabilityModalVisible] = useState(false);
	const [selectedCalendarEventStart, setSelectedCalendarEventStart] = useState('');
	const [selectedCalendarEventEnd, setSelectedCalendarEventEnd] = useState('');
	const [upcomingAppointments, setUpcomingAppointments] = useState([]);
	const [firstVisibleCalendarDate, setFirstVisibleCalendarDate] = useState(null);
	const [calendarActiveColumn, setCalendarActiveColumn] = useState(null);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [appointmentId, setAppointmentId] = useState(null);
	const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
	const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
	const [unreadNotifications, setUnreadNotifications] = useState(0);
	const [currentDate, setCurrentDate] = useState(`${today}`);
	const [appointmentEvent, setAppointmentEvent] = useState(null);
	const [appointmentDetails, setAppointmentDetails] = useState(null);
	const time = `${today.getHours()}:0${today.getMinutes()}:${today.getSeconds()}`;

	const intl = useIntl();

	const calendarViewGrids = [
		{
			id: 'dayGridMonth',
			name: translate('month'),
		},
		{
			id: 'timeGridWeek',
			name: translate('week'),
		},
		{
			id: 'timeGridDay',
			name: translate('day'),
		},
	];
	const wrapperRef = useRef(null);
	const eventWrapperRef = useRef(null);

	const fetchAppointments = async (
		startDate = moment().startOf('month').format('YYYY-MM-DD'),
		endDate = moment().endOf('month').format('YYYY-MM-DD')
	) => {
		const response = await getAppointments(userId, startDate, endDate);
		if (response.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
		}
		const upcomingEventAppointment = response.appointments
			.filter(item => item.statusId !== AppointmentStatus.CANCELED)
			.map(item => {
				const { invitedUser } = item.appointmentInvitation;
				return {
					start: moment.utc(item.appointmentSlot.startDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
					end: moment.utc(item.appointmentSlot.endDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
					description: `${invitedUser.firstName} ${invitedUser.lastName}`,
					title: item.title,
					publicId: item.id,
					date: moment(item.appointmentSlot.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
					className: getStatus(item.statusId),
					...item,
				};
			});

		setUpcomingEventAppointments(upcomingEventAppointment);
		setIsLoading(false);
	};

	useEffect(() => {
		const fetchRequests = async status => {
			setIsLoading(true);
			const params = {
				userId,
				pageIndex: 0,
				pageSize: 10,
				as: UserRoles.DOCTOR === userRole ? 'doctor' : 'patient',
				status,
			};
			const request = await getAppointmentInvitations(params);
			if (request.error) {
				setError(intl.formatMessage({ id: 'failedToLoad' }));
				setIsLoading(false);
			} else {
				setIsLoading(false);
				setUnreadNotifications(request.appointmentInvitations.filter(item => item?.appointmentNotification).length);
				fetchUpcomingAppointments();
				fetchAppointments();
			}
		};
		fetchRequests();
		socket.on(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, fetchRequests);
		return () => {
			socket.off(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, fetchRequests);
		};
	}, [intl, socket, userRole, userId]);

	useEffect(() => {
		const getSelectedGrid = () => {
			switch (selectedViewGrid) {
				case 'dayGridMonth':
					return 'month';
				case 'timeGridWeek':
					return 'week';
				case 'timeGridDay':
					return 'day';
				default:
					return null;
			}
		};
		const fetchData = async (
			startDate = moment().startOf(getSelectedGrid()).format('YYYY-MM-DD'),
			endDate = moment().endOf(getSelectedGrid()).format('YYYY-MM-DD')
		) => {
			setCurrentDate(`${today}`);
			const response = await getAppointments(userId, startDate, endDate);
			if (response.error) {
				setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
			}
			const upcomingEventAppointment = response.appointments
				.filter(item => item.statusId !== AppointmentStatus.CANCELED)
				.map(item => {
					const { invitedUser } = item.appointmentInvitation;
					return {
						start: moment.utc(item.appointmentSlot.startDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
						end: moment.utc(item.appointmentSlot.endDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
						description: `${invitedUser.firstName} ${invitedUser.lastName}`,
						title: item.title,
						publicId: item.id,
						date: moment(item.appointmentSlot.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
						className: getStatus(item.statusId),
						...item,
					};
				});

			setUpcomingEventAppointments(upcomingEventAppointment);
		};
		fetchData();
	}, [intl, selectedViewGrid, userId]);

	useEffect(() => {
		const onBeforeUnload = event => {
			if (props.location?.state?.openAppointmentDetails) {
				props.history.push({ pathname: `/appointments` });
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		};
		if (props.location?.state?.recreateAppointment) {
			if (userRole === UserRoles.PATIENT) {
				props.history.push({
					pathname: '/select-doctor',
					state: { createAppointment: true },
				});
			} else {
				setIsCreateAppointmentOpen(prevState => !prevState);
				setIsEditAppointmentOpen(false);
				setIsEventEditModal(false);
				setSelectedCalendarEventStart('');
				setSelectedCalendarEventEnd('');
			}
		}
		if (props.location?.state?.openAppointmentDetails) {
			setIsDetailsModalOpen(true);
		}
		window.addEventListener('beforeunload', onBeforeUnload);
	}, [props.location, props.history, userRole]);

	useOutsideClick(wrapperRef, () => {
		if (wrapperRef.current && isCalendarDateDropdownVisible) {
			setIsCalendarDateDropdownVisible(false);
		}
	});

	const fetchUpcomingAppointments = async () => {
		const params = {
			userId,
			pageIndex: 0,
			pageSize: 10,
		};
		const appointmentsRes = await getUpcomingAppointments(params);
		if (!appointmentsRes.error) {
			setUpcomingAppointments(appointmentsRes.upcomingAppointments);
		} else {
			setError(appointmentsRes.error.message);
		}
	};

	const openTab = currentTab => {
		setCurrentTabIndex(currentTab);
		if (currentTab === 0) {
			fetchAppointments();
			onSelectedDateChanged(selectedDate);
		}
		if (currentTab === 1) {
			setUnreadNotifications(0);
		}
	};

	const onSelectedDateChanged = date => {
		if (fullCalendarRef.current) {
			const calendarApi = fullCalendarRef.current.getApi();
			calendarApi.gotoDate(date);
			fetchAppointments(moment(date).startOf('month').format('YYYY-MM-DD'), moment(date).endOf('month').format('YYYY-MM-DD'));
		}
		setSelectedDate(date);
	};

	const changeSelectedGrid = selectedViewGridOnClick => {
		if (fullCalendarRef.current) {
			setSelectedViewGrid(selectedViewGridOnClick);
			setIsCalendarDateDropdownVisible(false);
			const calendarApi = fullCalendarRef.current.getApi();
			calendarApi.changeView(selectedViewGridOnClick, selectedDate);
			setIsEventEditModal(false);
		}
	};

	const toggleCreateAppointmentModal = info => {
		if (userRole === UserRoles.PATIENT) {
			props.history.push({
				pathname: '/select-doctor',
				state: { createAppointment: true },
			});
		} else {
			setIsCreateAppointmentOpen(prevState => !prevState);
			setIsEditAppointmentOpen(false);
			setIsEventEditModal(false);
			setSelectedCalendarEventStart(info && info.start ? moment.utc(info.start) : '');
			setSelectedCalendarEventEnd(info && info.end ? moment.utc(info.end) : '');
		}
	};

	const toggleEditAppointmentModal = selectedAppointmentIdOnToggle => {
		setIsEventEditModal(false);
		setIsEditAppointmentOpen(prevState => !prevState);
		setSelectedAppointmentId(selectedAppointmentIdOnToggle);
	};

	const toggleEditEventModal = selectedAppointment => {
		if (isCalendarDateDropdownVisible || isEventEditModal) {
			setIsCalendarDateDropdownVisible(false);
			setIsEventEditModal(false);
		}
		let activeColumn = null;

		const firstCalendarColumn = 1;
		const secondCalendarColumn = 2;

		if (!isEventEditModal) {
			const selectedEventStartDate = moment(selectedAppointment.extendedProps.appointmentSlot.startDateTime).format('L');
			const dayIndex = moment(selectedAppointment.extendedProps.appointmentSlot.startDateTime).day();
			const firstCalendarDate = moment(firstVisibleCalendarDate).format('L');

			const secondCalendarDate = moment(firstVisibleCalendarDate).add(1, 'days').format('L');

			if (selectedEventStartDate === firstCalendarDate) {
				activeColumn = firstCalendarColumn;
			}

			if (selectedEventStartDate === secondCalendarDate) {
				activeColumn = secondCalendarColumn;
			}

			if (calendarViewGrids.find(item => item.id === selectedViewGrid)?.id === 'dayGridMonth') {
				if (dayIndex === 0) {
					activeColumn = firstCalendarColumn;
				}
				if (dayIndex === 1) {
					activeColumn = secondCalendarColumn;
				}
			}
		}

		setIsEventEditModal(prevState => !prevState);
		setSelectedAppointmentId(selectedAppointment.extendedProps ? selectedAppointment.extendedProps.publicId : null);
		setCalendarActiveColumn(activeColumn);
	};

	const toggleSetUpAvailability = () => {
		setIsSetUpAvailabilityModalVisible(prevState => !prevState);
	};

	const getStatus = statusId => {
		switch (statusId) {
			case AppointmentStatus.ACCEPTED:
				return 'accepted';
			case AppointmentStatus.PENDING:
				return 'pending';
			case AppointmentStatus.REJECTED:
				return 'rejected';
			case AppointmentStatus.CANCELED:
				return 'canceled';
			default:
				return '';
		}
	};

	const openCancelAppointmentModal = id => {
		setIsCancelModalOpen(true);
		setAppointmentId(id);
	};

	const cancelAppointment = async () => {
		const response = await updateAppointmentStatus(appointmentId, AppointmentStatus.CANCELED);
		if (response.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${error.response.data.message}`);
		} else {
			fetchAppointments();
			fetchUpcomingAppointments();
		}
		setIsCancelModalOpen(false);
	};

	const updateAppointmentStatusOnDetails = async (selectedAppointmentIdonClick, statusId, acceptRejectBtn) => {
		setIsLoading(true);
		if (acceptRejectBtn && acceptRejectBtn.textContent !== 'close') {
			const response = await updateAppointmentStatus(selectedAppointmentIdonClick, statusId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'anErrorOccurred' }));
				return;
			}
			fetchAppointments();
			fetchUpcomingAppointments();
		}
		setIsDetailsModalOpen(false);
		setIsLoading(false);
		setUnreadNotifications(0);
	};

	const openCompleteAppointmentModal = selectedAppointmentIdOnComplete => {
		setIsCompleteModalOpen(true);
		setAppointmentId(selectedAppointmentIdOnComplete);
	};

	const completeAppointment = async () => {
		const response = await updateAppointmentStatus(appointmentId, AppointmentStatus.COMPLETED);
		if (response.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${error.response.data.message}`);
		} else {
			fetchAppointments();
			fetchUpcomingAppointments();
		}
		setIsCompleteModalOpen(false);
	};

	const updateUnreadNotifications = () => {
		if (unreadNotifications > 0) {
			setUnreadNotifications(prevState => prevState - 1);
		}
	};

	const getNextOrPreviousWeek = isNext => {
		let startDate = moment(currentDate),
			endDate = moment(currentDate);
		startDate = isNext
			? startDate.add(7, 'd').startOf('week').subtract(1, 'd')
			: startDate.subtract(7, 'd').startOf('week').subtract(1, 'd');
		endDate = isNext
			? endDate.add(7, 'd').endOf('week').subtract(1, 'd')
			: endDate.subtract(7, 'd').endOf('week').subtract(1, 'd');
		return { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
	};

	const getNextOrPreviousDay = isNext => {
		let startDate = moment(currentDate),
			endDate = moment(currentDate);
		startDate = isNext ? startDate.add(1, 'days') : startDate.subtract(1, 'days');
		endDate = isNext ? endDate.add(1, 'days') : endDate.subtract(1, 'days');
		return { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
	};

	const getNextOrPreviousMonth = isNext => {
		let startDate = moment(currentDate),
			endDate = moment(currentDate);
		startDate = isNext ? startDate.add(1, 'M').startOf('month') : startDate.subtract(1, 'M').startOf('month');
		endDate = isNext ? endDate.add(1, 'M').endOf('month') : endDate.subtract(1, 'M').endOf('month');
		return { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
	};

	const getStartAndEndDate = isNext => {
		let dates;
		switch (selectedViewGrid) {
			case 'dayGridMonth':
				dates = getNextOrPreviousMonth(isNext);
				setCurrentDate(dates.startDate);
				break;
			case 'timeGridWeek':
				dates = getNextOrPreviousWeek(isNext);
				setCurrentDate(
					isNext
						? moment(currentDate).add(7, 'd').format('YYYY-MM-DD')
						: moment(currentDate).subtract(7, 'd').format('YYYY-MM-DD')
				);
				break;
			case 'timeGridDay':
				dates = getNextOrPreviousDay(isNext);
				setCurrentDate(dates.startDate);
				break;
			default:
				break;
		}
		return dates;
	};

	const fetchUpcomingAppointmentsForCalendar = async isNext => {
		const { startDate, endDate } = getStartAndEndDate(isNext);
		const response = await getAppointments(userId, startDate, endDate);
		if (response.error) {
			setError(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${response.error.message}`);
		}
		const upcomingEventAppointment = response.appointments
			.filter(item => item.statusId !== AppointmentStatus.CANCELED)
			.map(item => {
				const { invitedUser } = item.appointmentInvitation;
				return {
					start: moment.utc(item.appointmentSlot.startDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
					end: moment.utc(item.appointmentSlot.endDateTime).local().format('YYYY-MM-DD HH:mm:ss'),
					description: `${invitedUser.firstName} ${invitedUser.lastName}`,
					title: item.title,
					publicId: item.id,
					date: moment(item.appointmentSlot.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
					className: getStatus(item.statusId),
					...item,
				};
			});
		setUpcomingEventAppointments(upcomingEventAppointment);
	};

	const getMonthInAlbanian = month =>
		['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'][month];

	const handleEventLimitClick = () => {
		if (isEventEditModal) {
			setIsEventEditModal(false);
		}
	};

	const handleEventClick = e => {
		if (userId === e.event.extendedProps.createdBy.id) {
			setAppointmentDetails({
				createdByFN: translate('you'),
				createdByLN: '',
				createdByPic: e.event.extendedProps.createdBy.profilePicture,
				invitedFN: e.event.extendedProps.appointmentInvitation.invitedUser.firstName,
				invitedLN: e.event.extendedProps.appointmentInvitation.invitedUser.lastName,
				invitedPic: e.event.extendedProps.appointmentInvitation.invitedUser.profilePicture,
			});
		}
		if (userId === e.event.extendedProps.appointmentInvitation.invitedUser.id) {
			setAppointmentDetails({
				createdByFN: e.event.extendedProps.createdBy.firstName,
				createdByLN: e.event.extendedProps.createdBy.lastName,
				createdByPic: e.event.extendedProps.createdBy.profilePicture,
				invitedFN: translate('you'),
				invitedLN: '',
				invitedPic: e.event.extendedProps.appointmentInvitation.invitedUser.profilePicture,
			});
		}
		setAppointmentEvent(e.event);
		toggleEditEventModal(e.event);
	};

	return (
		<MainLayout>
			{isLoading && (
				<div className='patient-request-loader'>
					<Loader />
				</div>
			)}
			{!isLoading && (
				<div className='app-wrapper appointment-wrapper'>
					<Grid columns='1fr 3fr' stretch='100%'>
						<div className='appointment-left appointments-side' data-cy='appointmentLeftSide'>
							<div className='sub-page-header new-appointments-header flex'>
								<h2>{translate('appointments')}</h2>

								<Button
									variant='white'
									size='small'
									onClick={() => onSelectedDateChanged(new Date())}
									text={translate('today')}
								/>
							</div>
							<div className='appointments-side-info'>
								<div className='appointments-left-calendar'>
									<Calendar
										className='appointment-calendar'
										onChange={data => {
											onSelectedDateChanged(data);
										}}
										prev2Label={null}
										next2Label={null}
										formatShortWeekday={(loc, value) =>
											locale === LOCALES.ALBANIAN
												? ['D', 'H', 'M', 'Me', 'E', 'P', 'Sh'][value.getDay()]
												: value.toLocaleString('default', { weekday: 'narrow' })
										}
										formatMonthYear={(loc, value) =>
											locale === LOCALES.ALBANIAN
												? getMonthInAlbanian(value.getMonth())
												: value.toLocaleString('default', { month: 'long' })
										}
										calendarType='US'
										value={selectedDate}
									/>
								</div>
								<UpcomingAppointments
									fetchAppointments={fetchAppointments}
									upcomingAppointments={upcomingAppointments}
									modifyTimeForAppointments={values => setUpcomingAppointments(values)}
									getUpcomingAppointments={fetchUpcomingAppointments}
								/>
							</div>
						</div>
						<div className='appointments-tabs'>
							<Tabs activeIndex={currentTabIndex} onChange={index => openTab(index)}>
								<TabList>
									<Tab>{translate('calendarOverview')}</Tab>
									<Tab>
										{unreadNotifications > 0 && <div className='conversation-label-blue'>{unreadNotifications}</div>}
										{translate('appointmentRequests')}
									</Tab>
									<Tab>{translate('appointmentsHistory')}</Tab>
									{currentTabIndex === 0 && (
										<li className='appointment-select position-relative margin-right-m ' ref={wrapperRef}>
											<Button
												border='1px solid var(--gray-3)'
												color='var(--gray-11)'
												variant='white'
												size='small'
												icon='keyboard_arrow_down'
												onClick={() => setIsCalendarDateDropdownVisible(prevState => !prevState)}
												text={calendarViewGrids.find(item => item.id === selectedViewGrid).name}
											/>
											{isCalendarDateDropdownVisible && (
												<div className='appointment-select-dropdown'>
													{calendarViewGrids.map(item => (
														<div onClick={() => changeSelectedGrid(item.id)} key={item.id}>
															{item.name}
														</div>
													))}
												</div>
											)}
										</li>
									)}
									{userRole === UserRoles.DOCTOR && (
										<li className='appointment-select'>
											<Button
												size='small'
												imgIcon={`${healthCareCdnUrl}appointments/schedule-white.svg`}
												className='appointment-header-btns'
												onClick={toggleSetUpAvailability}
												text={translate('setupAvailability')}
											/>
										</li>
									)}
									<li data-cy='createAppointmentBtn appointment-select'>
										<Button
											size='small'
											imgIcon={`${healthCareCdnUrl}appointments/create-appointment.svg`}
											className='appointment-header-btns'
											onClick={toggleCreateAppointmentModal}
											text={translate('createAppointment')}
										/>
									</li>
								</TabList>
								<TabPanels>
									<TabPanel>
										<div className='appointment-billing-wrapper'>
											<div
												className={classNames(
													'appointment-billing appointment-calendar-new',
													calendarActiveColumn ? ` event-is-in-calendar-col-${calendarActiveColumn}` : '',
													selectedViewGrid === 'dayGridMonth' ? 'month-grid' : ''
												)}>
												<FullCalendar
													ref={fullCalendarRef}
													dayHeaderContent={headerData => {
														if (locale === LOCALES.ALBANIAN) {
															const weekday = [' diel', ' hënë', ' martë', ' mërkurë', ' enjte', ' premte', ' shtunë'][
																headerData.date.getDay()
															];
															return selectedViewGrid === 'dayGridMonth' ? weekday : headerData.date.getDate() + weekday;
														}
														return headerData.text;
													}}
													plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
													headerToolbar={{
														right: 'customPrev,customNext',
													}}
													initialView={selectedViewGrid}
													views={{
														dayGridMonth: {
															dayHeaderFormat: { weekday: 'long' },
														},
													}}
													locale={locale}
													datesSet={info => setFirstVisibleCalendarDate(info.startStr)}
													dayHeaderFormat={{ weekday: 'long', day: 'numeric', omitCommas: true }}
													customButtons={{
														customPrev: {
															text: '<',
															click: () => {
																if (fullCalendarRef.current) {
																	const calendarAPI = fullCalendarRef?.current?.getApi();
																	fetchUpcomingAppointmentsForCalendar(false);
																	calendarAPI.prev();
																}
															},
														},
														customNext: {
															text: '>',
															click: () => {
																if (fullCalendarRef.current) {
																	const calendar = fullCalendarRef?.current?.getApi();
																	fetchUpcomingAppointmentsForCalendar(true);
																	calendar.next();
																}
															},
														},
													}}
													scrollTime={time}
													allDaySlot={false}
													events={upcomingEventAppointments}
													dayMaxEventRows={3}
													moreLinkClick={handleEventLimitClick}
													displayEventTime
													progressiveEventRendering
													nowIndicator
													select={info => toggleCreateAppointmentModal(info)}
													slotLabelContent={fazeHours => {
														if (locale === LOCALES.ALBANIAN) {
															const faze = ((fazeHours.date.getHours() + 11) % 12) + 1;
															return fazeHours.date.getHours() > 11 ? `${faze}md` : `${faze}pd`;
														}
														return fazeHours.text;
													}}
													eventClick={handleEventClick}
													eventContent={info => {
														return (
															<div ref={eventWrapperRef}>
																<div className='fc-event-wrapper'>
																	<div className='flex column-direction'>
																		<div>
																			<div className='fc-event-title'>
																				<h3>{info.event.title}</h3>
																			</div>
																			<div className='fc-event-time'>
																				<span>
																					{moment
																						.utc(info.event.start)
																						.local()
																						.locale(getPreferredLanguageLocale())
																						.format('h:mm')}{' '}
																					-{' '}
																					{moment.utc(info.event.end).local().locale(getPreferredLanguageLocale()).format('h:mm')}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														);
													}}
												/>
												{isEventEditModal && (
													<AppointmentSlotDetails
														appointmentEvent={appointmentEvent}
														appointmentDetails={appointmentDetails}
														setIsEventEditModal={setIsEventEditModal}
														toggleEditAppointmentModal={toggleEditAppointmentModal}
														openCancelAppointmentModal={openCancelAppointmentModal}
														openCompleteAppointmentModal={openCompleteAppointmentModal}
													/>
												)}
											</div>
										</div>
									</TabPanel>
									<TabPanel>
										<AppointmentRequests updateUnreadNotifications={updateUnreadNotifications} />
									</TabPanel>
									<TabPanel>
										<AppointmentsHistory />
									</TabPanel>
									<TabPanel />
									<TabPanel />
									<TabPanel />
								</TabPanels>
							</Tabs>
						</div>
					</Grid>

					{userRole === UserRoles.DOCTOR && (
						<div className='full-width flex flex-align-center flex-justify-center appointment-empty-state'>
							<EmptyState
								image='setup-account.svg'
								title={translate('setupCalendarAccount')}
								paragraph={translate('noSetupAccount')}>
								<Link
									to={{
										pathname: '/account-settings',
										state: {
											isDoctorAppointmentsPageLinkVisible: true,
										},
									}}>
									<Button icon='add' text={translate('setupAccount')} />
								</Link>
							</EmptyState>
						</div>
					)}
				</div>
			)}
			{isCreateAppointmentOpen && (
				<ScheduleAppointment
					isCreateAppointmentOpen={isCreateAppointmentOpen}
					toggleCreateAppointmentModal={toggleCreateAppointmentModal}
					selectedCalendarEventStart={selectedCalendarEventStart}
					selectedCalendarEventEnd={selectedCalendarEventEnd}
					onSave={fetchAppointments}
					selectedNotification={
						props.location.state && props.location.state.recreateAppointment && props.location.state.notification
					}
					notificationActionType={props.location.state && props.location.state.recreateAppointment && props.location.state.action}
				/>
			)}
			{isEditAppointmentOpen && (
				<EditScheduleAppointment
					isEditAppointmentOpen={isEditAppointmentOpen}
					selectedAppointmentId={selectedAppointmentId}
					toggleEditAppointmentModal={toggleEditAppointmentModal}
					onSave={fetchAppointments}
				/>
			)}

			{isSetUpAvailabilityModalVisible && (
				<SetUpAvailability
					isSetUpAvailabilityModalVisible={isSetUpAvailabilityModalVisible}
					toggleSetUpAvailability={toggleSetUpAvailability}
				/>
			)}
			<Modal
				display={isCancelModalOpen}
				position='center'
				onModalSubmit={cancelAppointment}
				onModalClose={() => setIsCancelModalOpen(false)}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('cancelAppointmentTitle')}</h3>
					<p>{translate('cancelAppointmentQuestion')}</p>
				</form>
			</Modal>
			{isDetailsModalOpen && (
				<AppointmentDetailsModal
					display={isDetailsModalOpen}
					onModalClose={() => setIsDetailsModalOpen(false)}
					isLoading={isLoading}
					updateAppointmentStatus={updateAppointmentStatusOnDetails}
					appointmentId={props.location.state?.notification?.payload.Id}
					notificationType={props.location.state?.notification.type}
				/>
			)}
			<Modal
				display={isCompleteModalOpen}
				position='center'
				onModalSubmit={completeAppointment}
				onModalClose={() => setIsCompleteModalOpen(false)}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('completeAppointmentTitle')}</h3>
					<p>{translate('completeAppointmentQuestion')}</p>
				</form>
			</Modal>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</MainLayout>
	);
};
export default Appointments;
