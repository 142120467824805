import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import NetworkConfig from 'containers/Configurations/NetworkConfig.jsx';
import DeviceConfig from 'containers/Configurations/DeviceConfig.jsx';
import { DeviceListLevel, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import TranslationServices from 'containers/Configurations/TranslationServices.jsx';
import ManageRpmPrograms from 'containers/Rpm/ManageRpmPrograms.jsx';
import EcareManager from 'containers/Configurations/EcareManager.jsx';
import { IntegrationTypesSettings, hsFeatureFlagsCategories } from 'constants/configurationEnums.js';
import FeatureFlagsCategories from 'components/FeatureFlagsCategories.jsx';
import CareEvents from 'containers/Configurations/CareEvents.jsx';
import CallSettingsAdmin from 'components/CallSettingsAdmin.jsx';
import { getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';

const Configurations = () => {
	const userSession = useSelector(state => state.user.userSession);
	const history = useHistory();
	const location = useLocation();
	const [pagination, setPagination] = useState({ index: 0, size: 10 });
	const [isLoading, setLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState(0);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState({
		value: userSession.healthSystem.id,
		label: userSession.healthSystem.name,
	});
	const [selectedRole, setSelectedRole] = useState({ value: '0', label: 'All' });
	const [error, setError] = useState(null);
	const [selectedHospital, setSelectedHospital] = useState(null);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [currentSubTab, setCurrentSubTab] = useState(0);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [treeHierarchyTypeId, setTreeHierarchyTypeId] = useState(TreeHierarchyType.DEFAULT_TREE);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const shouldShowTranslationServicesTab = () =>
		getUserRole() !== UserRoles.SUPER_USER &&
		getSomeConfigurationsValues(companyConfigurations, [IntegrationTypesSettings.GLOBO, IntegrationTypesSettings.AMN]);

	useEffect(() => {
		const setStateFromQueryString = async queryParams => {
			setLoading(false);
			const { pageIndex, activeTab, healthSystem, pageSize, activeSubTab } = queryParams;
			const hs = healthSystem ? allHealthSystems.find(x => x.id === healthSystem) : null;
			setPagination({
				index: pageIndex ? parseInt(pageIndex, 10) : 0,
				size: pageSize ? parseInt(pageSize, 10) : 10,
			});
			setCurrentTab(activeTab ? parseInt(activeTab, 10) : 0);
			setCurrentSubTab(activeSubTab ? parseInt(activeSubTab, 10) : 0);
			if (healthSystem) {
				setSelectedHealthSystem({ value: hs.id, label: hs.name });
			} else {
				setSelectedHealthSystem({
					value: userSession.healthSystem.id,
					label: userSession.healthSystem.name,
				});
			}
		};
		const currentParams = queryString.parse(location.search);
		setStateFromQueryString(currentParams);
	}, [allHealthSystems, location.search, userSession.healthSystem]);

	const updateQueryParam = (prop, val, shouldDeleteProp = false) => {
		const currentParams = queryString.parse(location.search);
		if (shouldDeleteProp) {
			delete currentParams[prop];
		} else {
			currentParams[prop] = val;
		}
		history.push({ search: queryString.stringify(currentParams) });
	};

	const onHealthSystemChange = param => {
		if (param.value !== '0') {
			setTreeHierarchyTypeId(allHealthSystems.find(item => item.id === param.value)?.treeHierarchyTypeId);
		}
		setSelectedHospital(null);
		setSelectedDepartment(null);
		setSelectedHealthSystem(param);
		setPagination(prev => ({
			...prev,
			index: 0,
		}));
		updateQueryParam('healthSystem', param.value, param.value === '0');
	};

	const onDepartmentChange = department => {
		setSelectedDepartment(department.value);
	};

	const onPaginationChange = (size, index) => {
		setPagination({
			index,
			size,
		});
	};

	const onTabChange = index => {
		setLoading(true);
		setPagination({
			index: 0,
			size: 10,
		});
		setCurrentTab(index);
		setLoading(false);
		const currentParams = queryString.parse(location.search);
		if (index === 0) {
			delete currentParams.activeTab;
		} else {
			currentParams.activeTab = index;
			currentParams.activeSubTab = '0';
		}
		history.push({ search: queryString.stringify(currentParams) });
		setCurrentSubTab(0);
	};

	const onSubTabChange = index => {
		setLoading(true);
		setPagination({
			index: 0,
			size: 10,
		});
		setCurrentSubTab(index);
		setLoading(false);
		updateQueryParam('activeSubTab', index, index === 0);
	};

	const onHospitalChange = hs => {
		setSelectedHospital(hs.value);
		setSelectedDepartment(null);
		setPagination(prev => ({ ...prev, index: 0 }));
	};

	const onRoleChange = param => {
		setSelectedRole(param);
		setPagination(prev => ({
			...prev,
			index: 0,
		}));
		updateQueryParam('role', param.value, param.value === '0');
	};

	return (
		<MainLayout>
			<div className='users-view test'>
				<Grid stretch='100%'>
					<main className='main-view'>
						<section>
							<Grid width='100%' vertAlign='start'>
								<h3>{translate('configurations')}</h3>
								<Tabs activeIndex={currentTab} onChange={onTabChange}>
									<TabList>
										<Tab className='cursor-pointer'>{translate('device')}</Tab>
										<Tab className='cursor-pointer'>{translate('network')}</Tab>
										{getUserRole() !== UserRoles.SUPER_USER && <Tab className='cursor-pointer'>{translate('calls')}</Tab>}
										{shouldShowTranslationServicesTab() && (
											<Tab className='cursor-pointer'>{translate('translationServices')}</Tab>
										)}
										{getUserRole() !== UserRoles.SUPER_USER && <Tab className='cursor-pointer'>{translate('rpmPrograms')}</Tab>}
										{getUserRole() === UserRoles.ADMIN && <Tab className='cursor-pointer'>{translate('integrations')}</Tab>}
										{getUserRole() !== UserRoles.SUPER_USER && <Tab className='cursor-pointer'>{translate('featureFlags')}</Tab>}
										<Tab className='cursor-pointer'>{translate('careEvents')}</Tab>
									</TabList>
									<TabPanels>
										<TabPanel>
											<DeviceConfig
												healthSystems={allHealthSystems}
												selectedHealthSystem={selectedHealthSystem}
												selectedHospitalId={selectedHospital}
												selectedDepartmentId={selectedDepartment}
												setSelectedHealthSystem={onHealthSystemChange}
												setSelectedHospital={onHospitalChange}
												setSelectedDepartment={onDepartmentChange}
												treeHierarchyTypeId={treeHierarchyTypeId}
											/>
										</TabPanel>
										<TabPanel>
											<NetworkConfig
												healthSystems={allHealthSystems}
												selectedHealthSystem={selectedHealthSystem}
												selectedRole={selectedRole}
												selectedHospitalId={selectedHospital}
												pageSize={pagination.size}
												pageIndex={pagination.index}
												activeSubTab={currentSubTab}
												setSelectedHealthSystem={onHealthSystemChange}
												setSelectedHospital={onHospitalChange}
												setSelectedRole={onRoleChange}
												onPaginationChange={onPaginationChange}
												onSubTabChange={onSubTabChange}
												isLoading={isLoading}
											/>
										</TabPanel>
										{getUserRole() !== UserRoles.SUPER_USER && (
											<TabPanel>
												<CallSettingsAdmin
													selectedHealthSystem={selectedHealthSystem}
													setSelectedHealthSystem={onHealthSystemChange}
													healthSystems={allHealthSystems}
												/>
											</TabPanel>
										)}
										{shouldShowTranslationServicesTab() && (
											<TabPanel>
												<TranslationServices
													healthSystems={allHealthSystems}
													selectedHealthSystem={selectedHealthSystem}
													selectedHospitalId={selectedHospital}
													pageSize={pagination.size}
													pageIndex={pagination.index}
													setSelectedHealthSystem={onHealthSystemChange}
													setSelectedHospital={onHospitalChange}
													onPaginationChange={onPaginationChange}
													onSubTabChange={onSubTabChange}
													isLoading={isLoading}
												/>
											</TabPanel>
										)}
										{getUserRole() !== UserRoles.SUPER_USER && (
											<TabPanel>
												<ManageRpmPrograms />
											</TabPanel>
										)}
										{getUserRole() === UserRoles.ADMIN && (
											<TabPanel>
												<EcareManager />
											</TabPanel>
										)}
										{getUserRole() !== UserRoles.SUPER_USER && (
											<TabPanel>
												<FeatureFlagsCategories
													currentSectorType={DeviceListLevel.HEALTH_SYSTEM}
													setError={setError}
													setSelectedHealthSystem={onHealthSystemChange}
													selectedHealthSystem={selectedHealthSystem}
													featureFlagsCategories={hsFeatureFlagsCategories}
													levelId={selectedHealthSystem.value}
												/>
											</TabPanel>
										)}
										<TabPanel>
											<CareEvents
												healthSystems={allHealthSystems}
												selectedHealthSystem={selectedHealthSystem}
												pageSize={pagination.size}
												pageIndex={pagination.index}
												selectedRole={selectedRole}
												setSelectedHealthSystem={onHealthSystemChange}
												onPaginationChange={onPaginationChange}
												activeSubTab={currentSubTab}
												onSubTabChange={onSubTabChange}
												isLoading={isLoading}
												treeHierarchyTypeId={treeHierarchyTypeId}
											/>
										</TabPanel>
									</TabPanels>
								</Tabs>
							</Grid>
						</section>
					</main>
				</Grid>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default Configurations;
