import { LOCALES } from 'i18n-translations/locales.js';

const keys = {
	[LOCALES.ENGLISH]: {
		conversation: 'Conversation',
		files: 'Files',
		health: 'Health',
		visits: 'Visits',
		roundingVisits: 'Visits',
		healthRecords: 'Health Records',
		hello: 'Hello',
		hideProfile: "Hide {value}'s Profile",
		showProfile: "Show {value}'s Profile",
		familyPhysician: 'Family Physician',
		assignedToMe: 'Assigned to me',
		otherRequests: 'Other Requests',
		requestSentFrom: 'Request sent from',
		healthConcernsDescription: 'Health concerns description',
		symptomsSigns: 'Symptoms & Signs',
		viewProfile: 'View Profile',
		viewAlerts: 'View Alerts',
		requestedAppointment: 'Requested Appointment',
		meetingPlace: 'Meeting Place',
		location: 'Location',
		dateOfBirth: 'Date of Birth',
		sex: 'Sex',
		accepted: 'Accepted',
		acceptRequest: 'Accept',
		wrapUp: 'Wrap Up',
		rejected: 'Rejected',
		canceled: 'Canceled',
		completed: 'Completed',
		unassigned: 'Unassigned',
		rejectRequest: 'Reject request',
		male: 'Male',
		female: 'Female',
		nonBinary: 'Non-binary',
		gender: 'Gender',
		helloHealthAppointmentRequest: 'hellocare Appointment Request',
		appointmentConfirmation: 'Appointment Confirmation',
		areYouSureAcceptFollowingAppointment: 'Are you sure you want to accept the following appointment?',
		addToGoogleCalendar: 'Add to Google Calendar',
		addToOutlookCalendar: 'Add to Outlook Calendar',
		clientNotAuthenticated: 'Client not authenticated',
		appointments: 'Appointments',
		calendarOverview: 'Calendar Overview',
		appointmentRequests: 'Appointment Requests',
		appointmentsHistory: 'Appointments History',
		nextAppointmentAt: 'Next appointment at',
		noUpcomingAppointment: 'You have no upcoming appointment',
		yesterday: 'Yesterday',
		today: 'Today',
		lastAmountOfDays: 'Last {value} days',
		lastMonth: 'Last Month',
		lastYear: 'Last Year',
		lastOnline: 'Last Online',
		monthToDate: 'Month to date',
		quarterToDate: 'Quarter to date',
		yearToDate: 'Year to date',
		firstQuarterOf: '1st quarter of {value}',
		secondQuarterOf: '2nd quarter of {value}',
		thirdQuarterOf: '3rd quarter of {value}',
		fourthQuarterOf: '4th quarter of {value}',
		customRange: 'Custom Range',
		selectRange: 'Select Range',
		jul: 'Jul',
		unauthorize: 'Unauthorize',
		authorize: 'Authorize',
		connectGoogleAccount: 'Connect Google Account',
		connectOutlookAccount: 'Connect Outlook Account',
		googleAccount: 'Google Account',
		outlookAccount: 'Outlook Account',
		calendarAccounts: 'Calendar Accounts',
		waitingRoom: 'Waiting Room',
		primaryCare: 'Primary Care',
		healthcareProgressAndDiagnosisChecking: 'Healthcare progress and diagnosis checking',
		yourMobileDevice: 'Your mobile device',
		clinic: 'Clinic',
		days: 'days',
		anErrorOccurred: 'An error occurred.',
		assignCompanionDeviceError: 'Only one device can be selected for each companion mode at a time.',
		recaptchaLoadError: 'Error loading recaptcha script.',
		noAppointmentsToShowClickAuth:
			'No appointments to show. Please click "Authorize" to get the appointments associated with your account.',
		noAppointmentsToShowClickCreateAppointment:
			'You have no appointments. You can click "Create Appointment" to create an appointment.',
		createAppointment: 'Create Appointment',
		inAMoment: 'In a moment',
		personsProfile: "{value}'s Profile",
		topic: 'Topic',
		inviteMember: 'Invite member',
		fullName: 'Full Name',
		facility: 'Facility',
		profession: 'Profession',
		familyDoctor: 'Family Doctor',
		birthday: 'Birthday',
		address: 'Address',
		phone: 'Phone',
		audioCall: 'Audio Call',
		videoCall: 'Video Call',
		cameraFeed: 'Camera Feed',
		writeMessage: 'Write Message',
		rapidCall: 'Rapid Call',
		rapidCallDescription: 'When this is enabled, option to initiate rapid call will be available on Cart side.',
		regularCall: 'Regular Call',
		regularCallDescription: 'When this is enabled, option to initiate regular call will be available on Cart side.',
		admissionCall: 'Admission Call',
		admissionCallDescription: 'When this is enabled, option to initiate admission call will be available on Cart side.',
		submitNewCase: 'Submit New Case',
		submitNewCaseDescription: 'When this is enabled, on display will appear only one option to initiate a session.',
		sharedQueue: 'Shared Queue',
		sharedQueueDescription: ' When this is enabled, initiated calls will be sent to all available doctors within a hospital.',
		sessionAlreadyReceived: 'This session has already been received by another provider.',
		join: 'Join',
		type: 'Type',
		name: 'Name',
		dateTime: 'Date/Time',
		sentBy: 'Sent By',
		noFilesFound: 'You have shared no files with {value}',
		noFilesParagraph: 'Try by sending a picture, a video or a document!',
		deleteMsgTitle: 'Delete message for myself',
		deleteMsgWarning: 'Are you sure you want to delete this message?',
		deleteMediaTitle: 'Delete media for myself',
		deleteMediaWarning: 'Are you sure you want to delete this media?',
		actionUndone: 'This action can’t be undone.',
		summary: 'Summary',
		heart: 'Heart',
		heartRate: 'Heart Rate',
		beatsPerMinute: 'beats per minute',
		mmOfMercury: 'millimeters of mercury',
		percentage: 'percentage',
		bloodPressure: 'Blood Pressure',
		perfusionIndex: 'Perfusion Index',
		activity: 'Activity',
		steps: 'Steps',
		flightsClimbed: 'Flights Climbed',
		walkingRunningDistance: 'Walking + Running Distance',
		bodyMeasurements: 'Body Measurements',
		weight: 'Weight',
		scale: 'Scale',
		pinFeed: 'Pin to main screen',
		unpinFeed: 'Unpin from main screen',
		bodyTemperature: 'Body Temperature',
		leanBodyMass: 'Lean Body Mass',
		bodyFatPercentage: 'Body Fat Percentage',
		bmi: 'Body Mass Index',
		bMI: 'BMI',
		respiratory: 'Respiratory',
		oxygenSaturation: 'Oxygen Saturation',
		respiratoryRate: 'Respiratory Rate',
		sleep: 'Sleep',
		sleepAnalysis: 'Sleep Analysis',
		bloodGlucose: 'Blood Glucose',
		stethoscope: 'Stethoscope',
		noVisits: 'There are no visits for this room.',
		hasVisited: '{value1} has visited {value2}',
		selectVisitsDate: 'Select visits date',
		enterDetailsOfVisitReason: 'Write the reason why the patient is here. ',
		allergy: 'Allergy',
		medication: 'Medication',
		condition: 'Condition',
		observation: 'Observation',
		immunization: 'Immunization',
		procedure: 'Procedure',
		device: 'Device',
		carePlan: 'Care Plan',
		manifestation: 'Manifestation',
		criticality: 'Criticality',
		substance: 'Substance',
		status: 'Status',
		medicationName: 'Medication Name',
		dateWritten: 'Date Written',
		startDate: 'Start Date',
		endDate: 'End Date',
		prescriber: 'Prescriber',
		diagnosis: 'Diagnosis',
		dateRecorded: 'Date Recorded',
		severity: 'Severity',
		category: 'Category',
		value: 'Value',
		report: 'Report',
		performer: 'Performer',
		date: 'Date',
		vaccineType: 'vaccineType',
		procedureName: 'Procedure Name',
		deviceType: 'Device Type',
		deviceModel: 'Device Model',
		deviceUDI: 'Device UDI',
		deviceStatus: 'Device Status',
		expiryDate: 'Expiry Date',
		diseases: 'Diseases',
		categories: 'Categories',
		goals: 'Goals',
		appointmentDate: 'Appointment Date',
		noAppointmentsAvailableSelectDifferentDate: 'There are no available appointments, please select a different date.',
		noAppointmentsAvailableSelectDifferentDoctor: 'There are no available appointments, please select a different doctor.',
		setUpAvailabilityHours: 'Please set up the availability hours before you set an appointment.',
		whereMeetingPlace: 'Where would you like this meeting to take place',
		recurringMeeting: 'Recurring meeting',
		recurrenceEndDate: 'Recurrence End Date',
		description: 'Description',
		unavailable: 'Unavailable',
		selectAnotherColor: 'Select another color',
		interfaceThemeColor: 'Interface Theme Color',
		authNone: 'None',
		authExternalProvider: 'Authenticator',
		authEmail: 'Email',
		invalidCode: 'Invalid code. Please try again!',
		emailChanged: 'Your hellocare email was successfully changed. Please login again using your new email.',
		goToLoginPage: 'Click here to go to login page',
		currentEmail: 'Current Email',
		newEmail: 'New email',
		enableMultipleFeedAudio: 'Enable audio for multiple feeds',
		available: 'Available',
		changeLanguage: 'Change Language',
		english: 'English',
		albanian: 'Albanian',
		editProfile: 'Edit Profile',
		accountSettings: 'Account settings',
		logOut: 'Log Out',
		noNotifications: 'You have no notifications',
		noConversations: 'You have no conversations with Patients',
		conversationWithPatient: 'Conversation with Patient',
		toggledPatientCamera: "You have toggled patient's camera off.",
		turnCameraOn: 'Turn camera on',
		turnCameraOff: 'Turn camera off',
		initiatingCall: 'Initiating call',
		endCall: 'End call',
		turnOnMic: 'Turn on mic',
		turnOffMic: 'Turn off mic',
		hidePTZ: 'Hide PTZ',
		showPTZ: 'Show PTZ',
		showHealth: 'Show Health',
		hideHealth: 'Hide Health',
		takeScreenshot: 'Take a screenshot',
		otoscopeCamera: 'Otoscope',
		selectBackground: 'Select background',
		streamSettings: 'Stream Settings',
		streamSettingsModalTitle: 'Check your audio and video settings',
		usingDifferentMic: 'Using different mic and speaker devices may cause echo.',
		streamSettingsSpeaker: 'Speaker',
		filterNone: 'None',
		filterBlur: 'Blur',
		tvControls: 'TV Controls',
		startScreenSharing: 'Start screensharing',
		stopScreenSharing: 'Stop screensharing',
		presenting: 'Presenting',
		you: 'You',
		patient: 'Patient',
		disableNightvision: 'Disable night vision',
		enableNightvision: 'Enable night vision',
		aiControls: 'AI Controls',
		connect: 'Connect',
		measure: 'Measure',
		connecting: 'Connecting',
		measuring: 'Measuring',
		overview: 'Overview',
		seeMoreData: 'See More Data',
		historicalData: 'Historical Data',
		first: 'first',
		second: 'second',
		third: 'third',
		daily: 'Daily',
		weeklyOn: 'Weekly on',
		monthlyThe: 'Monthly the',
		annuallyOn: 'Annually on',
		everyWorkday: 'Every workday (Monday to Friday)',
		prev: 'Prev',
		next: 'Next',
		switchHealthSystem: 'Switch Health System',
		createNewHealthSystem: 'Create New Health System',
		editHealthSystem: 'Edit Health System',
		createHospital: 'Create a Hospital',
		communicateWithARoom: 'In order to communicate with a room',
		monitorRoom: 'In order to monitor rooms',
		clickAddIconInTree: 'in the tree view, click on add icon next to the room name',
		openHospitalChooseDepartment: 'please open a hospital, choose the department',
		clickFloorSelectRoom: 'click on a floor, and select a room',
		scheduleMeeting: 'Schedule a Meeting',
		makeAppointment: 'Make an appointment',
		switchHSorRegion: 'Switch Health System or Region',
		selectHealthSystem: 'Select Health System',
		selectIntegrationType: 'Select Integration Type',
		selectGroupRole: 'Select Group Role',
		selectHealthSystemApply: 'Select health system to apply active directory',
		selectGroupRoleApply: 'Select Role to apply active directory',
		selectRegion: 'Select Region',
		newDevice: 'New Device',
		enterSerialNumber: 'Enter Serial Number',
		deviceName: 'Device Name',
		deviceMode: 'Device Mode',
		deviceOfflineStatus: 'Some devices may be offline',
		version: 'Version',
		deviceFamilyName: 'Device Family Name',
		deviceLocation: 'Device Location',
		activationCode: 'Activation Code',
		savedSessions: 'Saved Views',
		updateSession: 'Update Session',
		saveSession: 'Save this View',
		currentSession: 'Current View',
		loadingSession: 'Loading Session',
		alreadyMonitoringPatients: "You're already in a call or monitoring patients.",
		tryAgainAfterSessionEnds: 'Please try again after you end that session.',
		disconnectedFromMonitoring: 'You have been disconnected from monitoring',
		youCantSwitchCam: `You can't switch back to {value} because the physical privacy buttons have been enabled. These buttons can be disabled only manually. Please contact your administrator.`,
		monitoringEndSoon: 'Monitoring or nurse views will end upon leaving this page. Are you sure you want to leave?',
		talkToPatients: 'Talk to patients',
		tvChannels: 'TV Channels',
		previousState: 'Previous State',
		microphoneIsOn: 'Microphone is on',
		audioFromMonitoringFeeds: 'Audio from monitoring feeds',
		cameraBlockedTitle: 'Allow hellocare to use your camera',
		microphoneBlockedTitle: 'Allow hellocare to use your microphone',
		cameraAndMicrophoneBlockedTitle: 'Allow hellocare to use your camera and microphone',
		cameraBlockedMessage:
			'hellocare needs access to you camera so patients can see you. Check the top left of the screen and click Allow.',
		microphoneBlockedMessage:
			'hellocare needs access to you microphone so patients can hear you. Check the top left of the screen and click Allow.',
		cameraAndMicrophoneBlockedMessage:
			'hellocare needs access to you camera and microphone so patients can hear you. Check the top left of the screen and click Allow.',
		microphoneBlockedTitleGeneric: 'Microphone is blocked',
		microphoneBlockedMessageGeneric:
			'hellocare requires access to your microphone. Click the camera blocked icon in your browser address bar and then refresh.',
		cameraBlockedTitleGeneric: 'Camera is blocked',
		cameraBlockedMessageGeneric:
			'hellocare requires access to your camera. Click the camera blocked icon in your browser address bar and then refresh.',
		cantFindMicrophoneTitle: 'Can’t find your microphone',
		cantFindMicrophoneMessage:
			'Please check on your system if a mic has been plugged in and you have allowed mic permissions. If not, plug one in or allow permissions and restart your browser. Patients won’t be able to hear you until you do that.',
		cantFindCameraTitle: 'Can’t find your camera',
		cantFindCameraMessage:
			'Please check on your system if a camera has been plugged in and you have allowed camera permissions. If not, plug one in or allow permissions and restart your browser. Patients won’t be able to see you until you do that.',
		popUpCameraBlockedTitle: 'Your Camera is blocked',
		popUpCameraBlockedMessage: 'Click the Camera blocked icon on the top right of your screen',
		popUpMicrophoneBlockedTitle: 'Your Microphone is blocked',
		popUpMicrophoneBlockedMessage: 'Click the Microphone blocked icon on the top right of your screen',
		popUpCameraAndMicrophoneBlockedTitle: 'Your Camera is blocked',
		popUpCameraAndMicrophoneBlockedMessage: 'Click the Camera blocked icon on the top right of your screen',
		deviceUnavailable: 'Device unavailable',
		virtualCaseProviderIsInCall: 'Virtual Session in Progress.',
		browserPeerConnectionsLimitReached:
			'Browser limit of maximum number of connections reached. Please close and re-open all hellocare tabs.',
		sessionName: 'View Name',
		enterSessionName: 'Enter View Name',
		delete: 'Delete',
		deleteSession: 'Delete this View',
		sureDeleteSession: 'Are you sure you want to delete this view?',
		failedToReconnect: 'Failed to reconnect to the network.',
		sessionEndedByAdmin: 'This session was ended by an admin.',
		disconnectedByCall: 'This feed has been disconnected by a call session, will reconnect as soon as the call ends.',
		maximumNumberOfMonitoringSessions: 'Device is being monitored by {value}. Max. number for this room has been reached.',
		beingMonitoredBy: 'This patient is being monitored by',
		pleaseRefreshThePage: 'Please refresh the page.',
		sessionUpdatedSuccessfully: 'Session updated successfully',
		save: 'Save',
		cancel: 'Cancel',
		apply: 'Apply',
		suspiciousBehavior: 'Suspicious Behavior',
		suicideWatch: 'Suicide Watch',
		watch: 'Watch',
		aIWithCamera: 'AI with camera',
		falls: 'Falls',
		behavioral: 'Behavioral',
		isolation: 'Isolation',
		seizure: 'Seizure',
		hospice: 'Hospice',
		detox: 'Detox',
		fallRiskCheckPatient: 'Fall risk. Please check the patient.',
		multiPersonFallDetection: 'Multi-Person Fall detection',
		fallDetection: 'Fall Detection',
		turnOffPrivacyMode: 'Turn off privacy mode',
		turnOnPrivacyMode: 'Turn on privacy mode',
		allowPermission: 'Allow permission',
		muteAudio: 'Mute audio',
		unMuteAudio: 'Unmute audio',
		reinitiateCallPermissions: 'To re-initiate this call, you need to allow sound permissions',
		for: 'for',
		myRequests: 'My Requests',
		newRequest: 'New Request',
		acceptedRequests: 'Accepted Requests',
		pendingRequests: 'Pending Requests',
		rejectedRequests: 'Rejected Requests',
		pending: 'Pending',
		requestSentTo: 'Request sent to',
		viewRequestHistory: 'View Request History',
		healthConcern: 'Health Concern',
		reassignRequest: 'Reassign Request',
		welcomeMessage: 'Welcome message',
		visitCost: 'Visit Cost',
		languagesSpoken: 'Languages Spoken',
		professionalEducation: 'Professional Education',
		yearsOfExperience: 'Years of Experience',
		fellowship: 'Fellowship',
		requestHistory: 'Request History',
		youHaveAcceptedRequest: "You have accepted {value}'s request",
		acceptedYourRequest: 'accepted your request',
		assignedTheRequestTo: 'assigned the request to',
		rejectedYourRequest: 'rejected your request',
		sentRequestToAllDoctors: 'sent this request to all doctors.',
		general: 'General',
		profileInformation: 'Profile Information',
		passwordProtection: 'Password Protection',
		notifications: 'Notifications',
		measurementUnits: 'Measurement Units',
		integratedApps: 'Integrated Apps',
		dependents: 'Dependents',
		insurance: 'Insurance',
		paymentInformation: 'Payment Information',
		activityHistory: 'Activity History',
		noPageFound: 'No Page Found',
		saveChanges: 'Save Changes',
		phoneNumber: 'Phone Number',
		emailAddress: 'Email Address',
		language: 'Language',
		cameraNames: 'Camera Names',
		customRolesDisplayName: 'Custom Roles Display Name',
		enterCustomRoleNameHere: 'Enter custom role name here',
		customRoleNameRequired: 'Custom role name is required',
		informationMessageIsRequired: 'Information message is required',
		informationMessageForMonitoring: 'Information message for monitoring',
		enterAMessageHere: 'Enter a message here',
		displayAs: 'Display as',
		externalCamera: 'External Camera',
		edit: 'Edit',
		timeTrackerValue: '{value} Time Tracker',
		enable: 'Enable',
		disable: 'Disable',
		disabled: 'Disabled',
		recent: 'Recent',
		myDevices: 'My Devices',
		online: 'Online',
		offline: 'Offline',
		send: 'Send',
		healthCategories: 'Health Categories',
		addTab: 'Add a Tab',
		addFavTabQuickerAccess: 'Add your favorite apps or files as tabs for a quicker access',
		searchAppsFiles: 'Search apps or files',
		myApps: 'My Apps',
		otherApps: 'Other Apps',
		addNewHealthSystem: 'Add New Health System',
		resendInvite: 'Resend Invite',
		cancelInvite: 'Cancel Invite',
		addNewField: 'Add New Field',
		viewYourBaby: 'See your baby',
		enablePrivacyButtons: `Privacy buttons are enabled on {value} Device. You can see the baby when the privacy buttons are disabled.`,
		somethingWentWrong: 'Something went wrong! Please try again.',
		nurseAdded: 'Nurse was added successfully to the health system.',
		doctorAdded: 'Doctor was added successfully to the health system.',
		successfullySaved: 'Successfully saved.',
		usersAddedOnHelloHealth: 'The users were successfully invited to hellocare!',
		patientAssignedAsDeviceOwner: 'Patient has been successfully assigned as the device owner',
		manageHealthSystems: 'Manage Health Systems',
		selectHealthSystemNurse: 'Please select the health system you want to assign this nurse to.',
		enterHealthSystem: 'Enter Health System',
		healthSystem: 'Health System',
		assignation: 'Assignation',
		unAssignableLevel: 'Unassignable Level',
		downloadCSV: 'Download CSV',
		watchRecording: 'Watch Recording',
		recordedBy: 'Recorded by',
		assignLevel: 'Assign Level',
		signsSymptoms: 'Signs and Symptoms',
		selectSignsSymptomsAssociatedWithHealthConcerns: 'Please select the signs and symptoms associated with your health concerns.',
		mostRecentMeasurementByDate: 'This view displays the most recent measurement sorted by date and hour.',
		searchForSymptoms: 'Search for Symptoms',
		noSymptomsFound: 'No symptoms found',
		mySymptoms: 'My Symptoms',
		searchDoctors: 'Search Doctors',
		pleaseWriteYourHealthConcerns: 'Please write your health concerns!',
		errorOccurred: 'An error occurred:',
		pleaseSelectTheRequiredFields: 'Please select the required fields!',
		symptomsAssociatedWithGeneralDoctors: 'Your Symptoms are associated with General Doctors Category',
		sendRequest: 'Send Request',
		scheduleAppointment: 'Schedule Appointment',
		writeYourFirstName: 'Please write your first name here...',
		writeYourLastName: 'Please write your last name here...',
		writeYourEmail: 'example@gmail.com',
		confirmEmail: 'Please confirm your e-mail here...',
		writePassword: 'Please write your password here...',
		search: 'Search',
		selectSerialNumber: 'Select Serial Number',
		writeCurrentLocation: 'Please write your current location here...',
		writeHeight: 'Please write your height here...',
		writeHeightUnit: 'Height unit',
		writeWeight: 'Please write your weight here...',
		writeWeightUnit: 'Weight unit',
		healthSystemName: 'Health System Name',
		regionName: 'Region Name',
		confirmPassword: 'Confirm Password',
		firstName: 'First Name',
		lastName: 'Last Name',
		password: 'Password',
		email: 'Email',
		enterYourInfo: 'Your info',
		verifyCellPhone: 'Cell Phone',
		licenseNumber: 'License Number',
		medicalSchool: 'Medical School',
		chooseADate: 'Choose a date',
		companyName: 'Company Name',
		positionTitle: 'Position/Title',
		specify: 'Specify',
		suffix: 'Suffix',
		maidenName: 'Maiden Name',
		npiNumber: 'NPI Number',
		internshipInstitutionName: 'Internship Institution Name',
		internshipSpecialty: 'Internship Specialty',
		residencyInstitutionName: 'Residency Institution Name',
		residencySpecialty: 'Residency Specialty',
		fellowshipInstitutionName: 'Fellowship Institution Name',
		fellowshipSpecialty: 'Fellowship Specialty',
		searchForBoard: 'Type to search for Board here..',
		medicaidProviderNumber: 'Medicaid Provider Number',
		medicareProviderNumber: 'Medicare Provider Number',
		birthDate: 'Birth Date',
		socialSecurityNumber: 'Social Security Number',
		yourAnswer: 'Your answer',
		noResultsFound: 'No results found!',
		moreThanCharacters: 'You need to put at least {value} letters to make a search.',
		patientNotFound: 'Patient you were looking for does not exist!',
		showAllResults: 'Show all results',
		advancedSearch: 'Advanced Search',
		filterType: 'Filter Type',
		all: 'All',
		patients: 'Patients',
		lowercaseToday: 'today',
		lowercaseTomorrow: 'tomorrow',
		myHealthConcerns: 'My Health Concerns',
		describeYourHealthConcerns: 'Describe your health concerns',
		thereAreAppointmentsFor: 'There are {value} available appointments for',
		typeAppointmentTopic: 'Type appointment topic',
		whereWouldYouLikeMeetingTakePlace: 'Where would you like this meeting to take place?',
		firstNameCannotBeEmpty: 'First name cannot be empty',
		parentNameCannotBeEmpty: 'Parent Name cannot be empty',
		idNumberCannotBeEmpty: 'ID Number cannot be empty',
		patientHealthCardNumberCannotBeEmpty: 'Patient Health Card Number cannot be empty',
		lastNameCannotBeEmpty: 'Last name cannot be empty',
		professionCannotBeEmpty: 'Profession cannot be empty',
		emailCannotBeEmpty: 'Email address cannot be empty',
		rpmCannotBeEmpty: 'RPM program cannot be empty',
		enterFirstName: 'Enter First Name',
		enterLastName: 'Enter Last Name',
		virtualNurse: 'Virtual Nurse',
		virtualCaseManager: 'Virtual Case Manager',
		virtualIntensivist: 'Virtual Intensivist',
		virtualPatient: 'Virtual Patient',
		manualPatient: 'Manual Patient',
		ehrPatient: 'EHR Patient',
		memberSuccessfullyModified: 'The member was successfully modified!',
		somethingWentWrongTryAgain: 'Something went wrong! Please try again.',
		modifyVirtualCareProviderFirstName: "Modify the virtual care provider's first name",
		modifyVirtualCareProviderLastName: "Modify the virtual care provider's last name",
		assignRoleVirtualCareProvider: 'Please assign a role for the virtual care provider.',
		errorWhileSavingThePhotos: 'Error while saving the photos.',
		fileReadingWasAborted: 'File reading was aborted',
		fileReadingHasFailed: 'File reading has failed',
		errorDeletingPhoto: 'Error deleting photo.',
		dragAndDropOrSelectPhotos: 'Drag and drop some photos here, or click to select photos',
		dragAndDropOrSelectAttachments: 'Drag and drop some files here, or click to select files',
		photos: 'Photos',
		attachments: 'Attachments',
		fiveAttachmentsMaximumUpload: 'You can upload up to 5 files maximum.',
		fileUploadMaximumSize: 'Maximum file size should be 5MB',
		noPhotosForThisUser: 'There are no photos for this user.',
		uploadPhotos: 'Upload photos',
		dateSent: 'Date Sent',
		invitedBy: 'Invited By',
		role: 'Role',
		addVirtualCareProvider: 'Add Virtual Care Provider',
		addAdministrator: 'Add Administrator',
		addAdminUsers: 'Add Admin Users',
		addSuperUsers: 'Add Super Users',
		addSuperAdminUsers: 'Add Super Admin Users',
		deleteAlertSubscription: 'Delete Alert Subscription',
		areYouSureYouWantToDeleteSubscription: 'Are you sure you want to delete subscription for {value}?',
		setupDeviceAlerts: 'Setup Device Alerts',
		deviceAlerts: 'Device Alerts',
		receiveAlertsFrom: 'Receive Alerts From',
		addAnotherEmailAddress: 'Add another Email Address',
		setupAlertsFor: 'Setup Alerts For',
		recipients: 'Recipients',
		assignationLevel: 'Assignation Level',
		alertType: 'Alert Type',
		invitationDate: 'Invitation Date',
		deviceIsOffline: 'Device is offline',
		anAlertMustBeSelected: 'An alert must be selected',
		couldNotFindThisEmail: 'Could not find this email',
		alreadyAddedThisEmail: 'You already have added this email',
		formShouldContainOneEmail:
			'Form should contain at least one valid email address. If the input field is filled, please press Enter to add email address.',
		confirmation: 'Confirmation',
		areYouSureCancelInvitation:
			'Are you sure you want to cancel this invitation? The link sent to this invited user will be deactivated and cannot be used any longer.',
		overwrite: 'Overwrite',
		warning: 'Warning',
		featureOverwrite: 'Feature overwrite',
		exclude: 'Exclude',
		applyToAll: 'Apply to All',
		endedTime: 'Ended Time',
		startTime: 'Start Time',
		roomsWithDifferentConfigs: 'Rooms that have a different set of feature flags',
		waringOverwriteDescription: `You are trying to make changes to a sector that already has devices with a different set of features than the one you're trying to set.`,
		bySavingThisDescription:
			'If you want to override the feature flags on the rooms above, then please select Apply to All. If you want to exclude those rooms, please select Exclude.',
		areYouSureResendInvitation:
			'Are you sure you want to resend this invitation? The user you are inviting will receive another email asking them to join hellocare.',
		areYouSureTwoFaReset:
			'Are you sure you want to reset Two Factor Authentication? The user will receive an email asking to reset Two Factor Authentication.',
		userName: 'User Name',
		dateInvited: 'Date Invited',
		dateAdded: 'Date Added',
		healthSystems: 'Health Systems',
		areYouSureDeleteMember:
			'Are you sure you want to delete this member? This member will be removed from all health systems and devices associated with them.',
		userInfo: 'User Info',
		userPhotos: 'User Photos',
		enterEmail: 'Enter Email',
		writeEmailOfVcpToInvite: 'Please write the emails of the virtual care providers you wish to invite.',
		writeEmailOfVSToInvite: 'Please write the emails of the virtual sitters you wish to invite.',
		writeEmailOfDCToInvite: 'Please write the emails of the digital clinicians you wish to invite.',
		writeEmailOfDoctorsToInvite: 'Please write the emails of the doctors you wish to invite.',
		writeEmailOfAdminToInvite: 'Please write the emails of the admin users you wish to invite.',
		inviteMax5PeopleAtOnce: 'You can only invite a maximum of 5 people at once!',
		selectAtLeastOneHSToAddRole: 'Please select at least one health system in order to add a {value}',
		writeEmailsYouWishToInvite: 'Please write the emails of the {value} you wish to invite.',
		virtualCareProviders: 'virtual care providers',
		addRole: 'Add {value}',
		userManagement: 'User Management',
		fleetManagement: 'Fleet Management',
		fleetDashboardDescription:
			'When this is enabled, the user will be able to see real-time statuses of devices on the selected health system.',
		fleetDashboard: 'Fleet Management',
		setupAlerts: 'Setup Alerts',
		ofTheRooms: 'of the rooms',
		deviceAssignation: 'Device Assignation',
		connection: 'Connection',
		clearFilter: 'Clear Filter',
		searchByRoomOrSN: 'Search by Room Name or SN',
		ethernet: 'Ethernet',
		wireless: 'Wireless',
		devicesAssigned: 'Devices Assigned',
		members: 'Members',
		invitedMembers: 'Invited Members',
		invitedMember: 'Invited Member',
		active: 'Active',
		notActive: 'Not active',
		patientPosition: 'The patient has been inactive for 10 seconds.',
		railDownMessage: 'Bed Rail is down. Please check the patient.',
		alertCheckThePatient: 'Alert! Check the patient.',
		fallRiskCheckThePatient: 'Fall risk. Please check the patient.',
		pleaseCheckPatient: 'Please check the patient.',
		concerningMeasurements: 'Patient has concerning measurements.',
		alertCheckTheBaby: 'Alert! Check the baby.',
		alertBabyExitingCrib: 'Alert! Baby exiting crib.',
		missingTools: 'Missing tools: {value}',
		surgicalToolsDetection: 'Surgical Tools Detection',
		objectDetection: 'Object Detection',
		babyDetection: 'Baby Detection',
		patientPositionDetection: 'Patient Position Detection',
		hospitalTimeZone: 'Hospital Time Zone',
		localTimeZone: 'Local Time Zone',
		bedRailDetection: 'Bed and Rails Detection',
		actionRecognition: 'Action Recognition',
		skeletonFallDetection: 'Skeleton Fall Detection',
		privacyOnSkeletonDetection: 'Privacy On Skeleton Detection',
		otoscopeConnected: 'Otoscope has been connected',
		switchView: 'Switch view',
		areYouSureRebootHuddleCam: `Are you sure you want to reboot {value} camera? In 30 seconds, the view will return to the {value} perspective`,
		chooseHealthSystemYouWantToManage: 'Choose the health system you want to manage.',
		chooseRegionFromHSManageHospitals: 'Select a region from the health system above to manage the hospitals on it.',
		togglePatientTVPowerOn: "Toggle patient's TV Power on",
		volume: 'Volume',
		switchPatientHDMI: "Switch Patient's HDMI",
		heartRateTooltip:
			'Heart rate is the speed of the heartbeat measured by the number of contractions (beats) of the heart per minute (bpm). The American Heart Association states the normal resting adult human heart rate is 60–100 bpm.',
		bloodPressureTooltip:
			'Blood pressure is the pressure of circulating blood on the walls of blood vessels. Most of this pressure is due to work done by the heart by pumping blood through the circulatory system. Used without further specification, "blood pressure" usually refers to the pressure in large arteries of the systemic circulation.',
		perfusionIndexTooltip:
			'Perfusion Index or PI is the ratio of the pulsatile blood flow to the non-pulsatile static blood flow in a patient’s peripheral tissue such as in a fingertip, toe, or ear lobe. Perfusion index is an indication of the pulse strength at the sensor site.',
		stepsTooltip:
			'Step count is the number of steps you take throughout  the day. Pedometers and digital activity trackers can help you determine your step count. These devices count steps for any activity that involves step-like movement, including walking, running, stair-climbing, cross-country skiing, and even movement as you go about your daily chores.',
		flightsClimbedTooltip:
			'A flight of stairs is counted as approximately 10 feet (3 meters) of elevation gain (approximately 16 steps).',
		leanBodyMassTooltip:
			"Lean body mass is the amount of weight you carry that isn't body fat. It includes muscle but also bones, water, and connective tissue, among other substances.",
		bodyFatPercentageTooltip:
			'Body fat percentage is the weight of fat in your body, compared to your total body weight, which includes muscles, bones, water, and so on.',
		bodyMassIndexTooltip:
			'BMI is a calculation of your size that takes into account your height and weight. It can tell you wether you are underweight, normal, overweight, or obese. It can also gauge your risk for diseases that can occur with more body fat.',
		bodyTemperatureTooltip:
			'Normal body temperature varies by person, age, activity, and time of day. The average normal body temperature is generally accepted as 98.6°F (37°C). A temperature over 100.4°F (38°C) most often means you have a fever caused by an infection or illness. Body temperature normally changes throughout the day.',
		oxygenSaturationTooltip:
			'Oxygen Saturation is a measure of the amount of oxygen in the protein (hemoglobin) in your red blood cells. To function properly, your body needs a certain level of oxygen circulating in the blood. Your red blood cells are loaded (saturated) with oxygen in the lungs and carry it throughout your body.',
		sleepAnalysisTooltip:
			'Sleep Analysis provides insight into your sleep habits. Sleep trackers and monitors can help you determine the amount of time you are in bed and asleep. These devices estimate your time in bed and your time asleep by analyzing changes in physical activity, including movement during the night. You can also keep track of your sleep by entering your own estimation of your bedtime and sleep time manually. \n “In Bed” period reflects the time period you are lying in bed with the intention to sleep. For most people it starts when you turn the lights off and it ends when you get out of bed. The “Asleep” period reflects the period(s) you are asleep.',
		bloodGlucoseTooltip:
			"Blood glucose, also called blood sugar, is the main sugar found in your blood. It comes from the food you eat, and is your body's main source of energy.",
		stethoscopeTooltip:
			'The stethoscope is an acoustic medical device for auscultation, or listening to the internal sounds of an animal or human body. A stethoscope can be used to listen to the sounds made by the heart, lungs or intestines, as well as blood flow in arteries and veins.',
		respiratoryRateTooltip:
			'Respiratory Rate is your rate of breathing (inhalation and exhalation) within a certain time (typically 60 seconds).',
		noDataFound: 'There are no available data for this date-range',
		noDataAllowed: 'No data allowed',
		tryOtherDateRange: 'Try by selecting a different date-range!',
		healthSystemDoesNotHaveCreatedHospitals:
			'This health system does not have any created hospitals. Make sure to create them before assigning nurses!',
		requestAccepted: 'Request Accepted',
		requestRejected: 'Request Rejected',
		clickAllowToMakeCall: 'Click �Allow� to Make a Call',
		allowPermissionToMakeCall: 'To make a call on hellocare, you need to allow permissions for microphone and camera.',
		participantHearAndSeeYou: 'This will let the other participants hear and see you.',
		canTurnOffMicCameraLater: 'You can always turn off your microphone or camera later.',
		turnOnMicCamera: 'Turn on Microphone and Camera',
		deviceNotFound: 'Device not found',
		videoCallTurnOnCamera: 'To make a video call on hellocare, you need to turn on your camera.',
		audioCallTurnOnMicRefreshPage: 'For an audio call, you need to turn on your microphone. Try again by refreshing the page.',
		deviceMayBeInUse: 'Device may be in use',
		camOrMicAnotherApp: 'Camera or microphone may be in use by another application.',
		closeOtherAppRefresh: 'Please close the other application and refresh the page.',
		noData: 'No Data',
		pleaseReachAdmin: 'Please reach out to the administrator to have those configurations available.',
		overviewDescription: 'When this is enabled, overview data for monitoring, rounding, and role-based will display.',
		providerCentricReportDescription: 'When this is enabled, providers data on the number of sessions, patients and session',
		patientCentricReportDescription:
			'When this is enabled, patient data such as number of sessions, interventions, and actions taken will be displayed.',
		plsReachAdministratorToHaveThisFeatureAvailable: 'Please reach out to your administrator to have this feature available',
		selectOneOfConnectedDoctors: 'Select one of the connected doctors!',
		noConnectedDoctor: 'You have no connected doctors!',
		noActionsFound: 'No Actions Found',
		noHelloIsSelected: `You have no connected {value} Device!`,
		tryBySelectingOne: 'Try by selecting one!',
		optionInitiateAudioCall:
			"This option will initiate an audio call to the patient, they will be able to hear you and you will be able to hear them. During the call, you can switch your or patient's camera on, or even share your screen.",
		myPatients: 'My Patients',
		myHellos: `My {value}s`,
		noConnectedPatientsYet: 'You have no connected patients yet!',
		noConnectedDoctorsYet: 'You have no connected doctors yet!',
		editDepartmentDetails: 'Edit Department Details',
		deleteDepartment: 'Delete Department',
		floorsInThisDepartment: 'Floors in this department',
		roomsInThisDepartment: 'Rooms in this department',
		enrolledDevices: 'Enrolled Devices',
		enrolledCompanionDevices: 'Companion Devices',
		devices: 'Devices',
		rooms: 'Rooms',
		floors: 'Floors',
		deviceSerialNumber: 'Device Serial Number',
		tvSerialNumber: 'TV Serial Number',
		deviceEnrolled: 'Device Enrolled',
		deviceEnrollment: 'Device Enrollment',
		enrollment: 'Enrollment',
		companyDeviceEnrollment: 'Company level device enrollment',
		actions: 'Actions',
		roomNames: 'Room Names',
		roomNumber: 'Room Number',
		writeRoomNumber: 'Write Room Number',
		floorNames: 'Floor Names',
		unassign: 'Unassign',
		unassignDevice: 'Unassign Device',
		unassignPatient: 'Unassign Patient',
		sureToUnassignDevice:
			'Are you sure you want to unassign device {value}? Neither you nor patient will not be able to make any calls.',
		sureToUnassignPatient:
			'Are you sure you want to unassign {value} from this device? Neither you nor patient will not be able to make any calls.',
		sureToDeleteOrg:
			'Are you sure you want to delete {value}? Any devices related to it will be deleted and neither Virtual Care Providers nor patients will be able to make any calls.',
		editFloorDetails: 'Edit Floor Details',
		deleteFloor: 'Delete Floor',
		roomsInThisFloor: 'Rooms in this floor',
		signInToYourAccount: 'Sign in to your account',
		editHospitalDetails: 'Edit Hospital Details',
		deleteHospital: 'Delete Hospital',
		departmentsInThisHospital: 'Departments in this hospital',
		floorsInThisHospital: 'Floors in this hospital',
		roomsInThisHospital: 'Rooms in this hospital',
		departmentNames: 'Department Names',
		departments: 'Departments',
		editRoomDetails: 'Edit Room Details',
		familyMember: 'Family Member',
		familyMembers: 'Family Members',
		assignFamilyMember: 'Assign Family Member',
		babyRoom: 'Baby Room',
		roomDetails: 'Room details',
		roomType: 'Room Type',
		deleteRoom: 'Delete Room',
		addDevice: 'Add Device',
		addCompanionDevice: 'Add Companion Device',
		roomDevice: 'Room Device',
		assignDevice: 'Assign Device',
		assignCompanionDevice: 'Assign Companion Device',
		selectSerialNumberAssociateWithRoom: 'Select a serial number to associate with this room.',
		unitPhoneNumberMust10: 'Unit Phone Number must be 10 digits long',
		selectCompanionMode: 'Select companion mode',
		selectCompanionDevice: 'Select companion device',
		theSectorTypeRoomLocatedAt: 'This room is located at {value}.',
		download: 'Download',
		document: 'Document',
		errorWhileFetchingPatients: 'Error while fetching patients',
		selectPatientHelloDevice: `Select one of the Patients/{value} Devices!`,
		noConnectedPatients: 'No connected patients',
		joinHELLOHealth: 'Join hellocare.',
		haveVisitInMinutes: 'Have a visit in minutes.',
		createUserAccount: 'Create User Account',
		createProviderAccount: 'Create Provider Account',
		createHealthSystemProfile: 'Create a health system profile',
		addRegionsForThisHealthSystem: 'Add regions for this health system',
		addNew: 'Add new',
		healthSystemCreatedSuccessfully: 'Your health system profile has been created successfully.',
		accountBeingCreatedMayTakeMinutes: 'Your account is being created. This may take a few minutes!',
		voiceCommandForEmergencyAssistance: 'Emergency Assistance',
		vCEADesc: `When this is enabled, bedside nurses and patients will be able to activate it by saying "Hellocare Code Blue"`,
		voiceCommandForCareEvents: 'Care Events',
		vCCEDesc: `When this is enabled, bedside nurses and patients will be able to activate by saying "Hellocare open Care Events"`,
		voiceCommandForPrivacyEnabling: 'Privacy',
		vCPDesc: `When this is enabled, bedside nurses and patients will be able to activate it by saying "Hellocare Enable privacy"`,
		voiceCommandForCallNurse: 'Call Nurse',
		vCCNDesc: `When this is enabled, patients can call nurses by saying "Hellocare Call Nurse" or "Hellocare Call the Nurse".`,
		voiceCommands: 'Voice Commands',
		invalidInvitation: 'This is an invalid invitation',
		inviteAlreadyAccepted: 'Invite has already been accepted',
		inviteCancelled: 'Invite has been cancelled by the system administrator',
		inviteExpired: 'Invite has expired',
		checkInvitationLinkValidity:
			'Go back to your email and make sure that the link you have clicked is valid. Also, contact your administrator to make sure that you have not already accepted this invitation.',
		anotherNursePickedUpCall: 'Another nurse picked up this call',
		waitAttemptingReconnect: 'Please wait…attempting to reconnect to the network.',
		manageHealthSystem: 'Manage Health System',
		changesWillBeAppliedToThisHS:
			'Any changes you make will be applied to this health system. You can rename or delete this health system.',
		pressEnterToSave: 'Press enter to save',
		regions: 'Regions',
		availableRegionsInThisHS: 'These are the available regions on this health system. Create more, rename, or delete a region.',
		addNewRegion: 'Add new region',
		areYouSureToDeleteRegion: 'Are you sure you want to delete this region?',
		areYouSureToDeleteHS: 'Are you sure you want to delete this Health System?',
		sectorIsLocatedAt: 'This {value2} is located at the {value}',
		boardType: 'Board Type',
		selectBoard: 'Select board',
		rowsPerPage: 'Rows per page',
		minttiStethoscope: 'Mintti Stethoscope',
		littmannStethoscope: 'Littmann Stethoscope',
		manuallyAdded: 'Manually added',
		addData: 'Add Data',
		systolic: 'Systolic',
		diastolic: 'Diastolic',
		selectStartDate: 'Select start date',
		selectEndDate: 'Select end date',
		allRecordedData: 'All Recorded Data',
		deleteAllDataRecords: 'Delete All Data Records',
		add: 'Add',
		addDataManually: 'Add Data Manually',
		dateAndTime: 'Date and Time',
		inBed: 'In Bed',
		inChair: 'In Chair',
		asleep: 'Asleep',
		selectState: 'Select State',
		done: 'Done',
		deleteThisDataRecord: 'Delete this data record',
		dataDetails: 'Data Details',
		dateOfMeasurement: 'Date of Measurement',
		source: 'Source',
		manufacturer: 'Manufacturer',
		model: 'Model',
		areYouSureDeleteThisRecord: 'Are you sure you want to delete this record?',
		areYouSureDeleteAllRecords: 'Are you sure you want to delete all data records?',
		documentName: 'Document Name',
		creationDate: 'Creation Date',
		attachment: 'Attachment',
		reboot: 'Reboot',
		rebootCamera: 'Reboot Camera',
		patientsTV: "Patient's TV",
		togglePatientTVtoHDMI: "Toggle patient's TV to HDMI",
		maxCharacterLengthExceeded: 'Maximum number of characters exceeded',
		firstNameMustStartWithCapitalLetter: 'First Name must start with a capital letter',
		pleaseWriteFirstName: 'Please write your first name',
		lastNameMustStartWithCapitalLetter: 'Last Name must start with a capital letter',
		pleaseWriteLastName: 'Please write your last name',
		emailAlreadyRegistered: 'The e-mail address provided is already registered.',
		newEmailDifferent: 'New email must be different than old email.',
		invalidEmail: 'Provided e-mail is invalid',
		pleaseWriteEmail: 'Please write your e-mail',
		emailsMustMatch: 'E-mails must match',
		passwordsMustMatch: 'Passwords must match',
		pleaseConfirmYourEmail: 'Please confirm your e-mail',
		pleaseWriteDateOfBirth: 'Please write your date of birth',
		passwordCannotBeLessThan8Chart:
			'Your password should be at least 8 characters long and contain at least one uppercase character, one lowercase, one number and a special symbol',
		pleaseWritePassword: 'Please write your password',
		pleaseWriteConfirmPassword: 'Please confirm your password',
		haveToAgreeToTermsAndConditions: 'You have to agree with our Terms and Conditions!',
		haveToAgreePrivacyPolicy: 'You have to agree with our Privacy Policy!',
		locationMustStartWithCapitalLetter: 'Location must start with a capital letter',
		pleaseWriteCurrentLocation: 'Please write your current location',
		pleaseSelectDateOfBirth: 'Please select your date of birth',
		pleaseSelectSex: 'Please select a sex',
		pleaseEnterHeight: 'Please enter your height',
		pleaseEnterWeight: 'Please enter your weight',
		pleaseSelectOne: 'Please select one',
		pleaseSelectHealthInsurancePlan: 'Please select your Health Insurance Plan',
		pleaseWriteSubscriberId: 'Please write your Subscriber ID',
		pleaseWritePrimarySubscriberFirstName: 'Please write your Primary Subscriber First Name',
		pleaseWritePrimarySubscriberLastName: 'Please write your Primary Subscriber Last Name',
		pleaseWritePrimarySubscriberBirthDate: 'Please write your Primary Subscriber Birth Date',
		serviceKeyLearnMore:
			'You may receive a Service Key from your employer, health plan, or health care provider to activate a new service. It is optional and not required.',
		numberTimesFallen: 'Number of Times Fallen',
		lowest: 'Lowest',
		average: 'Average',
		highest: 'Highest',
		changePresence: 'Change presence',
		height: 'Height',
		width: 'Width',
		bodyMassIndex: 'Body Mass Index',
		primaryDiagnosis: 'Primary Diagnosis',
		headInjury: 'Head Injury',
		basicInformation: 'Basic Information',
		fillUpFormOnRightSide: 'Fill up the form on the right side using your real data!',
		currentLocation: 'Current Location',
		back: 'Back',
		continue: 'Continue',
		signUp: 'Sign Up',
		alreadyHaveAnAccount: 'Already have an account',
		clickHereToSignIn: 'Click here to sign in',
		personalInformation: 'Personal Information',
		agreeToThe: 'I agree to the',
		termsAndConditions: 'Terms and Conditions',
		privacyPolicy: 'Privacy Policy',
		healthInsurance: 'Health Insurance',
		doYouHaveHealthInsurance: 'Do you have Health Insurance?',
		yes: 'Yes',
		no: 'No',
		serviceKey: 'Service Key',
		enterReceivedKey: 'If you received a key from your employer or another group, enter it here.',
		learnMore: 'Learn More',
		finish: 'Finish',
		yourAccountHasBeenSuccessfullyCreated: 'Your account has been successfully created!',
		checkEmailToActivateAccount: 'Check your email to activate your account.',
		yourAccountActivated: 'Your account has been activated!',
		continueToSignIn: 'Continue to Sign In',
		accountActivationFailedTryAgain: 'Account activation failed! Please try again',
		nurse: 'Nurse',
		virtualCareProvider: 'Virtual Care Provider',
		outgoing: 'Outgoing',
		incoming: 'Incoming',
		viewAll: 'View All',
		callInitiator: 'Call Initiator',
		room: 'Room',
		dateSlashTime: 'Date/Time',
		callOrigin: 'Call Origin',
		callStatus: 'Call Status',
		callDuration: 'Call Duration',
		successful: 'Successful',
		successfulDescription: 'All participants were able to join.',
		partiallySuccessful: 'Partially Successful',
		partiallySuccessfulDescription: "Some of the participants weren't able to join due to errors.",
		failed: 'Failed',
		failedDescription: 'None of the participants were able to connect due to errors.',
		disrupted: 'Disrupted',
		disruptedDescription:
			"Call was successfully established, but during the call someone's actions causes everyone to lose the ability to hear and see each other.",
		partiallyDisrupted: 'Partially Disrupted',
		partiallyDisruptedDescription:
			"Call was successfully established, but during the call someone's actions causes some of the participants to lose the ability to hear and see each other.",
		missed: 'Missed',
		missedDescription: 'Call was successfully done, but the invited participant missed the call.',
		aborted: 'Aborted',
		abortedDescription: "Call has ended, while others were still connecting to the call or call hasn't been established yet.",
		dropped: 'Dropped',
		droppedDescription:
			'Conference is marked as dropped, if the call is established but the participant disconnects causing the whole conference to end.',
		excludeDurationValidation: 'Duration exclusion should be between 1 and 60 seconds!',
		excludeStatusValidation: 'You cannot exclude all call statuses!',
		excludeDurationInfo: 'When the duration filter is applied, Missed, Aborted and Failed calls are excluded from the report.',
		excludeCalls: 'Exclude calls',
		under: 'under',
		withStatus: 'with status',
		audioUsed: 'Audio Used',
		videoUsed: 'Video Used',
		contentSharing: 'Content Sharing',
		queue: 'Queue',
		export: 'Export',
		region: 'Region',
		hospital: 'Hospital',
		department: 'Department',
		floor: 'Floor',
		queueOfNursesThatWereCalled: 'Queue of nurses that were called',
		generalSettings: 'General Settings',
		closeSession: 'Close Session',
		selectMemberToInvite: 'Select a member to invite',
		selectMeetingPlaceDevice: 'Select a meeting place or device',
		deviceOnCall: 'Device on another call.',
		deviceOnCallWithNurse: 'This device is being monitored by',
		deviceOffline: 'Device offline',
		initiatingMonitoring: 'Initiating Monitoring...',
		notAnswering: 'Could not connect! Please retry again.',
		leftCall: 'Failed to reconnect to the network. Please retry again.',
		disconnected: 'Patient is disconnected from the call.',
		connected: 'Connected',
		reconnecting: 'Please wait…attempting to reconnect to the network.',
		participantRemoved: 'This session was ended by a an admin.',
		addToMonitoringView: 'Add to monitoring view',
		organization: 'Organization',
		callLogs: 'Call Logs',
		collapse: 'Collapse',
		recentLowerCase: 'Recent',
		hospitals: 'Hospitals',
		rounding: 'Rounding',
		patientRequests: 'Patient Requests',
		solutions: 'Solutions',
		providers: 'Providers',
		monitoring: 'Monitoring',
		close: 'Close',
		addHospital: 'Add Hospital',
		create: 'Create',
		createMeeting: 'Create Meeting',
		monday: 'Monday',
		tuesday: 'Tuesday',
		wednesday: 'Wednesday',
		thursday: 'Thursday',
		friday: 'Friday',
		saturday: 'Saturday',
		sunday: 'Sunday',
		bloating: 'Bloating',
		cough: 'Cough',
		diarrhea: 'Diarrhea',
		dizziness: 'Dizziness',
		fatigue: 'Fatigue',
		fever: 'Fever',
		headache: 'Headache',
		muscleCramp: 'Muscle cramp',
		nausea: 'Nausea',
		throatIrritation: 'Throat irritation',
		runnyNose: 'Runny nose',
		throwingUpVomiting: 'Throwing up (vomiting)',
		soreThroat: 'Sore throat',
		headPressure: 'Head pressure',
		lowBodyTemperatureDescription: 'Low body temperature',
		highBodyTemperatureDescription: 'High body temperature',
		backPain: 'Back pain',
		appetiteChanges: 'Appetite changes',
		earache: 'Earache',
		sweating: 'Sweating',
		sneezing: 'Sneezing',
		fainting: 'Fainting',
		skinRash: 'Skin rash',
		insomnia: 'Insomnia',
		itchySkin: 'Itchy skin',
		jointPainAndSwelling: 'Joint pain and swelling',
		kneePain: 'Knee pain',
		legPain: 'Leg pain',
		limbNumbness: 'Limb numbness',
		muscleAchesAndPains: 'Muscle aches and pains',
		nosebleeds: 'Nosebleeds',
		shoulderPain: 'Shoulder pain',
		stomachPain: 'Stomach pain',
		swellingInJoints: 'Swelling (in joints)',
		tummyPain: 'Tummy pain',
		addDepartment: 'Add Department',
		support: 'Support',
		sla: 'SLA',
		na: 'N/A',
		addFloor: 'Add Floor',
		addRoom: 'Add Room',
		goBackToHealthApp: 'Go Back to hellocare App',
		fullScreen: 'Full screen',
		exitFullScreen: 'Exit full screen',
		fitToScreen: 'Fit to screen',
		screenshare: 'Screenshare',
		sound: 'Sound',
		turnPatientTVOff: 'Turn Patient TV Off',
		turnPatientTVOn: 'Turn Patient TV On',
		changeEmail: 'Change Email',
		changePassword: 'Change Password',
		currentPassword: 'Current password',
		enterCurrentPassword: 'Enter current password',
		enterYourCurrentPassword: 'Enter your current password',
		enterNewPassword: 'Enter new password',
		reEnterNewPassword: 'Re-enter new password',
		updatePassword: 'Update Password',
		editChangeAccountInfo: 'Edit or change your account information',
		statusCurrentlyOnline: 'Your status is currently online',
		statusCurrentlyOffline: 'Your status is currently offline',
		passwordChangedSuccessfully: 'Your password has been changed successfully',
		emailChangedSuccessfully: 'Your email has been changed successfully',
		oldPasswordIncorrect: 'Your current password is incorrect',
		errorDuringRequestProcessing: 'An error has occurred while processing your request',
		passwordRequired: 'Password is required',
		atLeastCharacters: 'Password must be at least',
		characters: 'characters',
		notMatchCurrentPassword: 'Your new password must be different from your previous password',
		mustMatchPassword: 'Passwords must match',
		passwordConfirmationRequired: 'Password confirmation is required',
		participantCameraOff: '{value} has toggled camera off.',
		modifyUserInformation: 'Modify User Information',
		zipCode: 'Zip Code',
		country: 'Country',
		city: 'City',
		state: 'State',
		visitCostCurrency: 'Visit Cost Currency',
		dateOfBirthRequired: 'Date of birth is required',
		countryRequired: 'Country is required',
		stateRequired: 'State is required',
		cityRequired: 'City is required',
		zipCodeRequired: 'Zip Code is required',
		addressRequired: 'Address is required',
		enterCity: 'Enter your city',
		enterZipCode: 'Enter your Zip Code',
		enterAddress: 'Enter your address',
		enterVisitCost: 'Enter your visit cost',
		writeWelcomeMessage: 'Write your welcome message',
		profileEditedSuccessfully: 'Your profile has been edited successfully',
		profilePicture: 'Profile Picture',
		updatePicture: 'Update Picture',
		shouldUploadProfilePicture: 'You should upload a profile picture',
		acceptedFilesTypesAre: 'Accepted files types are: {value}',
		select: 'Select',
		visitCostRequired: 'Visit Cost is required',
		visitCostCurrencyRequired: 'Visit Cost currency is required',
		welcomeMessageRequired: 'Welcome message is required',
		from: 'from',
		to: 'to',
		requestDoctor: 'Request a Doctor',
		thankYou: 'Thank you',
		requestSentSuccessfully: 'Your request has been sent successfully',
		goToThe: 'Go to  the',
		toGetStarted: 'to get started',
		requests: 'Requests',
		noConversation: 'You have no conversation with {value}',
		startConvo: 'Start the conversation by writing a message or attaching a file',
		noRequestsFound: 'No requests found!',
		noMatchesFound: 'No matches found',
		noDoctorsBasedOnSymptoms: 'No Doctors are found matching your symptoms and health concerns',
		noHealthDataFound: 'No health data found!',
		noHealthDataCurrently: "Currently you don't have any added health data",
		newConnection: 'New Connection',
		isNowConnection: 'is now a connection.',
		removeConnection: 'Remove Connection',
		callViewMessage: "You can call, message or view {value}'s health data via the Patients Menu",
		goToPatient: 'Go to Patient',
		newConfiguration: 'New Configuration',
		addPrivacyConfiguration: 'Add Privacy Configuration',
		editPrivacyConfiguration: 'Edit Privacy Configuration',
		selectPrivacy: 'Select Privacy',
		createdBy: 'Created By',
		dateCreated: 'Date Created',
		isHealthSystemActive: 'Is HealthSystem Active',
		isPrivateModeOn: 'Is Privacy Mode On',
		healthCareId: 'Healthcare ID',
		privacyModeIsOn: 'Privacy mode is on',
		configurations: 'Configurations',
		turnOffSurgicalTools: 'Turn Off Surgical Mode',
		turnOnSurgicalTools: 'Turn On Surgical Mode',
		turnOffBabyDetection: 'Turn Off Baby Mode',
		turnOnBabyDetection: 'Turn On Baby Mode',
		turnOffPatientPosition: 'Turn Off Patient Position',
		turnOnPatientPosition: 'Turn On Patient Position',
		turnOffBedAndRails: 'Turn Off Bed And Rails',
		turnOffSkeletonAndPrivacy: 'Turn off Skeleton Mode',
		turnOnBedAndRails: 'Turn on bed and rails',
		turnOnSkeletonAndPrivacy: 'Turn on Skeleton Mode',
		turnOnObjectDetection: 'Turn On Object Detection',
		turnOffObjectDetection: 'Turn Off Object Detection',
		surgicalToolsMissing: 'Surgical Tools Missing',
		railDown: 'Rail Down',
		breathsPerMin: 'breaths/min',
		unknownDevice: 'Unknown device',
		activities: 'Activities',
		inputValue: 'Value',
		privateMode: 'Private Mode',
		doctorOnList: 'Doctor',
		superUserRoleValidation: 'Super User role cannot be assigned to multiple Health Systems',
		superUserHealthSystemValidation: 'Super User must be assigned to a Health System',
		superUserOnList: 'Super User',
		superUser: 'Super User',
		superAdminOnList: 'Super Admin',
		ownerOnList: 'Owner',
		nurseOnList: 'Nurse',
		patientOnList: 'Patient',
		adminOnList: 'Admin',
		sentOnList: 'Sent',
		registeredOnList: 'Registered',
		pendingOnList: 'Pending',
		cancelledOnList: 'Cancelled',
		failedOnList: 'Failed',
		virtualSitter: 'Virtual Sitter',
		undefinedOnList: 'Undefined',
		successfulOnList: 'Successful',
		partiallySuccessfulOnList: 'Partially Successful',
		disruptedOnList: 'Disrupted',
		partiallyDisruptedOnList: 'Partially Disrupted',
		missedOnList: 'Missed',
		abortedOnList: 'Aborted',
		droppedOnList: 'Dropped',
		on: 'On',
		off: 'Off',
		fitnessTracker: 'Fitness Tracker',
		bodyWeightScale: 'Body Weight Scale',
		weightScale: 'Weight Scale',
		weightScaleDevice: 'Weight Scale',
		noEvents: 'There are no events.',
		glucometer: 'Glucometer',
		infraredEarThermometer: 'Infrared Ear Thermometer',
		thermometer: 'Thermometer',
		iphone: 'iPhone',
		pulseOximeter: 'Pulse Oximeter',
		hours: 'hours',
		timeOfMeasurement: 'Time of Measurement',
		fallDetected: 'Fall Detected',
		custom: 'Custom',
		auto: 'Auto',
		audioSettings: 'Audio Settings',
		audioTuning: 'Audio Tuning',
		selectValue: 'Select Value',
		selectOneOfTheOptions: 'Select one of the options',
		selectCustomValue: 'Select Custom Value',
		hdmiSyncDelay: 'HDMI SysDelay',
		detected: 'detected',
		hasMeasured: 'has measured',
		callPatient: 'Call Patient',
		callPatientDescription: 'When this is enabled, users will be able to call the patient while monitoring them.',
		patientCommunication: 'Being Monitored Message',
		patientCommunicationDescription:
			'When this is enabled, patients will be able to see a message on the {helloName} display that they are being monitored.',
		monitoringPrecautionsLegend: 'Monitoring Precautions Legend',
		acknowledge: 'Acknowledge',
		hasAcceptedYourRequest: 'has accepted your request for the {value} category',
		hasRejectedYourRequest: 'has rejected your request for the {value} category',
		hasCanceledRequest: 'has canceled request for the {value} category',
		hasSentRequest: 'has sent you a request for the {value} category',
		unread: 'Unread',
		earlier: 'Earlier',
		new: 'New',
		alerts: 'Alerts',
		upload: 'Upload',
		zipCodeDigitsMax: 'Zip Code must be less or equal to 10 digits',
		quickOptions: 'Quick Options',
		addDependents: 'Add Dependents',
		measureYourVitalSigns: 'Measure Vital Signs',
		updateProfile: 'Update Profile',
		upcomingAppointments: 'Upcoming Appointments',
		goToMyAppointments: 'Go to my appointments',
		goToMyRequests: 'View all Requests',
		vitalSigns: 'Vital Signs',
		ewsAiVitalSigns: 'EWS AI Vital Signs',
		inputterFirstName: 'Inputter First Name',
		inputterLastName: 'Inputter Last Name',
		goToMyHealthData: 'Go to my Health Data',
		limitedSelectedSymptoms: "You can't select more than 5 symptoms.",
		comingSoon: 'Coming Soon',
		pleaseTryAgainLater: 'Please try again later',
		currentlyNoHealthDataMainAnalysis:
			"Currently you don't have any added health data from main analysis. Click 'Go to my Health data' to view all the data.",
		currentlyNoVitalSigns:
			"{value} currently does not have any added health data from main analysis. Click 'Go to the Vital Signs' to view all the data.",
		extremelyHighTemperature: 'The patient has an extremely high temperature!',
		highTemperature: 'The patient has a high temperature!',
		lowTemperature: 'The patient has a low temperature!',
		highBloodPressure: 'The patient has high blood pressure!',
		extremelyHighPressure: 'The patient has an extremely high pressure!',
		extremelyLowBloodPressure: 'The patient has an extremely low pressure!',
		lowBloodPressure: 'The patient has low blood pressure!',
		lowHeartRateDescription: 'The patient has a very low heart rate!',
		highHeartRateDescription: 'The patient has a very high heart rate!',
		overweight: 'The patient is overweight!',
		obese: 'The patient is obese!',
		underweight: 'The patient is underweight!',
		sleepDeficient: 'The patient is sleep deficient!',
		privacyMode: 'Privacy Mode',
		microphoneMuted: 'Your mic is muted',
		microphoneOn: 'Your mic is on',
		cameraOn: 'Camera on',
		cameraOff: 'Camera off',
		privacyModeOff: 'Privacy mode turned off',
		privacyModeOn: 'Privacy mode turned on',
		checkDateOfBirth: 'You must be at least 18 years old to sign up',
		greaterThanEighteen: 'You must be at least 18 years old',
		homePage: 'Home',
		newPatientRequests: 'New Patient Requests',
		noNewRequests: 'You have no new Requests',
		about: 'About',
		termsOfUse: 'Terms of Use',
		goTo: 'Go to',
		domain: 'Domain',
		domainRequired: 'Domain is required',
		validGroupName: 'Valid Group Name',
		group: 'Group',
		allCaughtUp: 'You are all caught up',
		noNewAppointmentsOrRequests: 'You have no new upcoming appointments or no new requests!',
		age: 'Age',
		failedToLoad: 'Failed to load',
		hvrIndex: 'HVR Index',
		stressLevel: 'Stress Level',
		mildlyCalm: 'Mildly Calm',
		highlyTense: 'Highly Tense',
		slightlyTense: 'Slightly TENSE',
		quietlyRelaxed: 'Quietly Relaxed',
		deeplyRelaxed: 'Deeply Relaxed',
		vitalsCamera: 'Vitals Camera',
		cameraInUseHeader: 'Camera is already in use - Please close other apps',
		cameraInUseParagraph1: 'To participate the meeting with camera, disable the camera from other application',
		cameraInUseParagraph2: 'Refresh the page to initialize it',
		noAppointmentRequests: 'You have no appointment requests!',
		noAcceptedAppointments: 'You have no accepted requests!',
		scheduleAvailability: 'Schedule Availability',
		schedulingAvailability: 'Scheduling based on your availability',
		addTopic: 'Add Topic',
		setupAccount: 'Setup Account',
		noSetupAccount: 'Looks like you have not setup a calendar account yet, click on the below button to continue',
		setupCalendarAccount: 'Setup your calendar account',
		viewSomeonesProfile: "View {value}'s Profile",
		reSchedule: 'Reschedule',
		chooseAvailableTime: 'Choose your available time for ',
		letAvailbleSchedule: "Let patients know when you're available to accept meetings",
		setup: 'Setup',
		availability: 'availability',
		noUpcomingAppointments: 'You have no upcoming appointments!',
		roomNoDeviceAssigned: 'This room has no device assigned',
		roomsNeedDevice: 'Rooms need to have a device assigned',
		inOrderToCall: 'in order to call',
		createCompanyProfile: 'Create Company Profile',
		companyWebsite: 'Company Website',
		createCompany: 'Create Company',
		owner: 'Owner',
		companyType: 'Company Type',
		website: 'Website',
		companyId: 'Company Id',
		goBack: 'Go Back',
		companySuccessfullyCreated: 'Company Successfully Created',
		meetingTime: 'Meeting time',
		meetingDate: 'Meeting Date',
		noPendingAppointments: 'You have no pending requests!',
		noRejectedAppointments: 'You have no rejected requests!',
		patientEmail: 'Patient Email',
		familyMemberEmail: 'Family Member Email',
		patientFirstName: 'Patient First Name',
		familyMemberFirstName: 'Family Member First Name',
		patientLastName: 'Patient Last Name',
		familyMemberLastName: 'Family Member Last Name',
		assignPatient: 'Assign Patient',
		assignmentMethod: 'Assignment Method',
		modalLogsTitle: 'Device logs',
		modalLogsFailure: 'Failed to get device logs. Device is offline.',
		modalLogsWentWrong: 'Something went wrong, please try again',
		modalLogsSuccess: 'Device logs registered on Solaborate system. You can close this message.',
		modalLogsButton: 'Get Device Logs',
		rebootExportLogsButton: 'Export Reboot Logs',
		rebootExportLogsNoLogsYet: 'This device has no reboot logs yet.',
		exportAsCSV: 'Export as CSV',
		endpointWhitelisting: 'Endpoint Whitelisting',
		csv: 'CSV',
		reportedOn: 'Reported On',
		id: 'ID',
		allowed: 'Allowed',
		denied: 'Denied',
		protocol: 'Protocol',
		response: 'Response',
		searchPatient: 'Search Patient',
		newPatient: 'New Patient',
		existingPatient: 'Existing Patient',
		requiredField: 'Required field',
		selectedPatient: 'Selected Patient',
		nextDays: 'days',
		nextTime: 'In the next',
		nextHours: 'hours',
		nextHour: 'hour',
		nextMinutes: 'minutes',
		availableHours: 'Available Hours',
		availableDays: 'Available Days',
		break: 'Break',
		checkSelectedBreaks: 'Please check selected break times',
		sundays: 'Sundays',
		mondays: 'Mondays',
		tuesdays: 'Tuesdays',
		wednesdays: 'Wednesdays',
		thursdays: 'Thursdays',
		fridays: 'Fridays',
		saturdays: 'Saturdays',
		month: 'Month',
		week: 'Week',
		day: 'Day',
		remote: 'Remote',
		clearData: 'Clear data',
		resetDevice: 'Reset device',
		th: 'th',
		fileUploadError: 'File {value} already exists',
		conversationHistory: 'Conversation History',
		noDevices: 'No Devices',
		oneDevice: '{value} Device',
		moreThanOneDevice: '{value} Devices',
		healthSystemRequired: 'Health system is required',
		hospitalRequired: 'Hospital is required',
		emailRequired: 'Email is required',
		userAlreadyExists: 'This user already exists',
		privacy: 'Privacy',
		enablePrivacyMode: 'Enable Privacy Mode',
		privacyDescription: 'When this is enabled, anyone that calls this device will see a blurred view of the camera.',
		futureConfigurations: 'Future Configurations',
		false: 'False',
		true: 'True',
		forwarded: 'Forwarded',
		acknowledged: 'Acknowledged',
		rotate: 'Rotate',
		filter: 'Filter',
		appointment: 'appointment',
		editMeeting: 'Edit Meeting',
		companyDetails: 'Company Details',
		companySettings: 'Company Settings',
		companyAddress: 'Company Address',
		remotePatientMonitoring: 'Remote Patient Monitoring',
		continuousPatientMonitoring: 'Continuous Patient Monitoring',
		numberOfPatients: 'Number of Patients',
		highRisk: 'High Risk',
		memberStatus: 'Member Status',
		disableAccess: 'Disable Access',
		moderateRisk: 'Moderate Risk',
		searchByNameOrHello: `Search for patients by name or {value}`,
		optional: 'Optional',
		uploadPicture: 'Upload Picture',
		profileImageDescription: `This photo will be used as your profile image and will be viewable to patients on our website and mobile app.
		Image can be .jpg or .png format and a size limit of 2MB.`,
		pleaseUploadPicture: 'Please upload a profile picture',
		maxSizePic: `Picture size can't exceed 600KB.`,
		commandNotSupported: `This command is not supported on this patient's TV.`,
		userIdleConfigurations: 'User Idle Configurations',
		minutes: 'Minutes',
		editedBy: 'Edited by',
		dateEdited: 'Date edited',
		historyFor: 'History for',
		backToConfigs: 'Go back to Configurations',
		setTimeOut: 'Set timeout',
		viewHistory: 'View history',
		minutesGreaterThanZero: 'Minutes must be greater than 0',
		minutesRequired: 'Minutes are required',
		userIdleFormTitle: 'User needs to sign in again after a period of inactivity',
		selectHealthSystemOrAll: 'Select health system or choose All to apply period of inactivity',
		setInActivityPeriod: 'Set period of inactivity',
		userIdleNotifedPeriod: `User will be notified for 30 seconds, if they don't confirm presence they will be auto logged out`,
		configurationSuccessfullyAdded: 'Configuration successfully added',
		imHere: `I'm here`,
		idleMessage: 'You have been idle for {value} minutes.',
		idleMessageDescription: `You will be logged out in case you don't confirm your presence within the next {value} seconds`,
		seeDoctorNow: 'See Doctor Now',
		waitingRoomDoctor: `Your live call with {value1} is expected to start in {value2} or less`,
		doctorAvailableNow: `is available right now`,
		doctorUnAvailableNow: 'is not available for a call now',
		hasPatientsInWaitingList: ` has {value} other patients in waiting list`,
		goToWaitingRoom: `Go to waiting room`,
		pleaseSpecifySymptoms: `Please specify your symptoms to find the most convenient doctor for you.`,
		pleaseSelectAvailableDoctor: `Please select one of the available doctors to proceed with your appointment.`,
		seeTheAvailableDoctors: `See the available doctors`,
		pleaseWaitUntilDoctorCalls: `Please wait until the doctor calls you`,
		liveCallExpectedToStart: 'Your live call with Dr. {value} is expected to start in',
		orLess: 'or less',
		searchForDoctors: 'Search and select a doctor',
		additionalHealthData: 'Additional Health Data',
		additionalNotes: 'Additional Notes',
		clickHere: 'Click here',
		orDragAndDrop: 'or drag and drop to upload a file',
		leaveWaitingRoom: 'Leave Waiting Room ',
		liveCallRequest: 'Live Call Request',
		requestTime: 'Request time',
		notNow: 'Not Now',
		hasRequestedAliveCall: 'has requested for a live call with you',
		haveRequestedAliveCall: 'have requested for a live call with you',
		andNumberOthers: 'and {value} others',
		typeHere: 'Type here',
		notSpecified: 'Not Specified',
		changeDoctor: 'Change Doctor',
		changedYourMind: 'If you have changed your mind about your doctor you can pick another one',
		diagnoses: 'Diagnoses',
		procedures: 'Procedures',
		medicalInfoProcedures: 'Procedures',
		notes: 'Notes',
		medicalInfoNotes: 'Notes',
		notesDescription: 'When this is enabled, users will be able to write notes while monitoring a patient. ',
		showNotesOnExpand: 'Show notes only on expand',
		showNotesUnderTile: 'Show notes under each tile',
		defaultVideoMonitoring: 'Default Video Monitoring',
		defaultAmbientMonitoring: 'Default Ambient Monitoring',
		customize: 'Customize',
		prescriptions: 'Prescriptions',
		medicalInfoPrescriptions: 'Prescriptions',
		configurationExists: 'This configuration already exists!',
		deviceSettings: 'Device Settings',
		languages: 'Languages',
		aiModels: 'AI Models',
		selectMode: 'Select Mode',
		mainDisplay: 'Main Display',
		secondDisplay: 'Second Display',
		secondaryDisplay: 'Secondary Display',
		approval: 'Approval',
		approvalNeeded: 'In order to successfully toggle this feature you need approval from the patient.',
		changeSettingsRequestAccepted: 'Patient {patient} has {acceptedOrRejected} your request',
		updateRequested: 'Update Requested',
		updateNow: 'Update Now',
		softwareUpdate: 'Software Update',
		markAllAsRead: 'Mark all as read',
		selectDoctor: 'Select Doctor',
		tvMode: 'TV Mode',
		gamingMode: 'Gaming Mode',
		cleanMode: 'Clean Mode',
		widgetMode: 'Widget Mode',
		autoAnswer: 'Auto Answer',
		appInstaller: 'App Installer',
		audioLevel: 'Audio Level',
		portraitMode: 'Portrait Mode',
		landscape: 'Landscape',
		portrait: 'Portrait',
		portraitReverse: 'Portrait Reverse',
		deviceOrientation: 'Display Orientation',
		connectPatientsHelloToWifi: `Connect Patient's {value} to Wifi`,
		and: 'and',
		maxSymptomsNo: 'Max number of symptoms is 10',
		haveCompleted: 'have completed',
		haveCanceled: 'have canceled',
		yourRequestWith: 'your request with',
		yourLiveCallWith: 'your live call with',
		doctorRequestResponse: 'Doctor has {value} your request',
		goBackToDoctors: 'You can go back and request a call with an other doctor',
		deleteHealthSystemMember: 'Are you sure you want to delete this health system from this member?',
		dateValidation: 'Please select start date earlier than end date.',
		twentyHrsBetween: 'Please select 23:59 hours between the start date and the end date.',
		notAvailableNow:
			'Unfortunately, the provider is not available at this time. Please, select one of the times of availability below:',
		notAvailableToday: 'Unfortunately, the provider is not available today. Please, select a different date.',
		showForm: 'Show Form',
		priority: 'Priority',
		selectPriority: 'Select priority',
		changesSaved: 'Changes saved.',
		poolRoleRequired: 'Pool role is required.',
		requestSentToAllDoctors: 'Request sent to all doctors',
		firstNameMinLength: 'First name must be at least 2 characters',
		lastNameMinLength: 'Last name must be at least 2 characters',
		pleaseWritePhoneNumber: 'Please write your cell phone number',
		pleaseSelectPrimarySpecialty: 'Please select one primary specialty',
		pleaseWriteLicenseNumber: 'Please write a license number',
		pleaseWriteNPINumber: 'Please write your NPI number',
		npiMaxLength: 'NPI Number must be 10 characters or fewer',
		atLeastOneState: 'At least one state should be selected',
		selectedItems: 'Selected Items',
		valueItemSelected: '{value} selected',
		roles: 'Roles',
		activeDirectoryInvite: 'Active Directory Invite',
		doctor: 'Doctor',
		superadmin: 'Super Admin',
		superuser: 'Super User',
		noAdded: 'You have no added {value}',
		addAnother: 'Add another {value}',
		cancelVisit: 'Cancel visit',
		submitWrapUp: 'Submit wrap-up',
		addNote: 'Add Note',
		addDiagnose: 'Add Diagnose',
		addProcedure: 'Add Procedure',
		addedNotes: 'Added Notes',
		title: 'Title',
		selectPharmacy: 'Select Pharmacy',
		pharmacy: 'Pharmacy',
		noMoreHealthSystems: 'There are no other Health Systems.',
		whereToSendPrescriptions: 'Where would you like your prescriptions sent?',
		findPharmacy: 'Find Pharmacy',
		miles: 'Miles',
		homeDelivery: 'Home Delivery',
		pickUp: 'Pick-Up',
		errorLoading: 'Error Loading',
		searchPharmacies: 'Search Pharmacies',
		startCall: 'Start call',
		changePharmacy: 'Change Pharmacy',
		enterDescription: 'Enter description here',
		enterTopic: 'Enter topic here',
		editAppointment: 'Edit Appointment',
		discardChanges: 'Discard Changes?',
		discard: 'Discard',
		discardDescription: 'By closing the modal you will discard any data that you have entered.',
		dosage: 'Dosage',
		frequency: 'Frequency',
		duration: 'Duration',
		dispense: 'Dispense',
		refills: 'Refills',
		isRequired: 'is required',
		maxLengthIs: 'Max length is',
		medicineSelection: 'Medicine selection',
		dispensedAsWritten: 'Dispensed as written if medically necessary',
		noteToPharmacist: 'Note to pharmacist',
		patientInstructions: 'Patient instructions',
		quantityToDispense: 'Quantity to dispense',
		timesAday: 'times a day',
		capsule: 'capsule',
		addedPrescriptions: 'Added Prescriptions',
		typeOfDosage: 'Type of Dosage',
		orUntilDiretedToStop: '(or) until directed to stop',
		onlyAsNeeded: `Only as needed`,
		default: 'Default',
		numberOfDays: 'Number of days',
		appointmentCreated: 'Appointment Created Successfully',
		appointmentUpdated: 'Appointment Updated Successfully',
		appointmentDetails: 'Your appointment details are:',
		change: 'Change',
		confirm: 'Confirm',
		successfulSignIn: 'Successful Signing In',
		successfulSignInDescription: 'Your {value} has been successfully connected to hellocare.',
		errorSignIn: 'Error Signing In',
		errorSignInDescription: 'Could not connect to your {value}, please try again.',
		invalidFileType: 'Invalid file type.',
		hideForm: 'Hide Form',
		addSuperUser: 'Add Super User',
		myWorkflow: 'My Workflow',
		directWorkflow: 'Direct Workflow',
		throughNurses: 'Through Nurses',
		selectMyWorkflow: 'Select my workflow',
		nurses: 'Nurses',
		clickHereAddNurse: 'Click here to add a new nurse',
		addNurse: 'Add Nurse',
		inviteNurses: 'Invite Nurses',
		sectorIsRequired: 'Sector name cannot be empty',
		timeZoneMustBeSet: 'Time zone must be set',
		sectorNameMinLength: 'Sector name must be at least 2 characters',
		writeSectorName: 'Write sector name',
		writeDeviceSerialNumber: 'Write device serial number',
		writeTvSerialNumber: 'Write TV serial number',
		healthSystemMinLength: 'Health system name must be at least 2 characters',
		nextStep: 'Next Step',
		minLengthIs: 'Min length is',
		areYouSureDeleteItem: 'Are you sure you want to delete this {value}?',
		addPrescription: 'Add Prescription',
		medicalInfo: 'Medical Info',
		addMedicalInfo: 'Add {value}',
		diagnose: 'diagnose',
		note: 'note',
		prescription: 'prescription',
		titleIsRequired: 'Title is required',
		appointmentSlotIsRequired: 'Please select an appointment slot',
		personIsRequired: 'Person is required',
		meetingOptionIsRequired: 'Meeting option is required',
		descriptionIsRequired: 'Description is required',
		descriptionMustHaveLetter: 'Description cannot have only blanks, numbers or symbols. Must contain letters.',
		rebootDevice: 'Reboot Device',
		factoryReset: 'Factory reset',
		rebootDeviceQuestion: 'Why do you want to reboot device',
		resetDeviceQuestion: 'Why do you want to reset device',
		rebootDeviceReason: 'Describe the reboot reason...',
		resetDeviceReason: 'Please describe the reason for initiating the factory reset...',
		resetDeviceUndone: 'This action cannot be undone. Are you sure you want to initiate a factory reset this device with',
		clearDataQuestion: 'Why do you want to clear device data',
		clearDataReason: 'Describe the clear data reason...',
		editDetails: 'Edit Details',
		otherDevices: 'other devices',
		areYouSureDeletePrescription: 'Are you sure you want to delete this prescription?',
		inviteDoctor: 'Invite Doctor',
		inactiveConference: 'Patient has left',
		inactiveConferenceDesc: 'Patient has already left virtual room',
		joinCall: 'Join Call',
		expandLatestHealthData: 'Show Latest Health Data',
		collapseLatestHealthData: 'Hide Latest Health Data',
		minutesInWaitingRoom: 'minutes in the waiting room',
		rejectedRequest: 'rejected your request',
		selectHelloDevice: `Select {value} Device`,
		otherMedicalInfo: 'Other Medical Info',
		mobile: 'Mobile',
		laptop: 'Laptop',
		youHaveLengthPatients: 'You have {length} {patients} in the waiting room',
		bodyFat: 'Body Fat',
		muscleMass: 'Muscle Mass',
		bodyWater: 'Body Water',
		symptoms: 'Symptoms',
		transfer: 'Transfer',
		transferToDoctor: 'Transfer to doctor',
		transferToDoctorWarning:
			'Transfering the call to Dr. {doctor} will result in ending the call with {patient}. Are you sure you want to procced?',
		aiPrivacyMode: 'AI Privacy Mode',
		noMoreThanTwoAI: `You can't add more than two AI Models`,
		soundOn: 'Patient room sound on',
		soundOff: 'Patient room sound off',
		nightVisionOn: 'Night Vision On',
		nightVisionOff: 'Night Vision Off',
		reportAiDetection: 'Report AI Detection',
		detectionReported: 'AI detection reported successfully',
		visitWith: 'Visit with',
		mainDetails: 'Main Details',
		lengthOfVisit: 'Length of visit',
		viewDetails: 'View Details',
		newAppointmentRequest: 'You have a new appointment request with',
		onDate: 'on',
		has: 'has',
		yourAppointmentInvitation: 'your appointment invitation',
		youHaveAppointmentWith: 'You have an appointment with',
		inMinutesOn: 'in {value} minutes on',
		hasAcceptedAppointment: 'has accepted your appointment invitation',
		hasRejectedAppointment: 'has rejected your appointment invitation',
		hasCanceledAppointment: 'has canceled your appointment invitation',
		atDate: 'at',
		youHaveAppointmentRequest: 'You have an appointment request with',
		updated: 'updated',
		rescheduled: 'rescheduled',
		expand: 'Expand',
		reportAsFalse: 'Report as false',
		callEmergency: 'Call Emergency',
		callNurse: 'Call Nurse',
		markFalseAlert: 'Mark as false alert',
		markAsSeen: 'Mark as seen',
		resultsFor: 'Results for {value}',
		patientExists: 'This patient already exists!',
		dataAcquisition: 'Data Acquisition',
		allRequests: 'All Requests',
		opened: 'Opened',
		cancelAppointmentTitle: 'Cancel Appointment?',
		cancelAppointment: 'Cancel Appointment',
		cancelAppointmentQuestion: 'Are you sure you want to cancel this appointment?',
		alertHistory: 'Alert History',
		healthData: 'Health Data',
		roundingHealthData: 'Health Data',
		healthDataAlerts: 'Health Data Alerts',
		artificialIntelligence: 'Artificial Intelligence',
		wasDetected: 'was detected at',
		timeline: 'Timeline',
		youNeedAuthorizedNurses: `You need to authorize at least 1 nurse in order to change workflow to "Through Nurses"`,
		timeSlotExists: 'You already have an appointment on that time slot.',
		hideEmergencyButtons: 'Hide Emergency Buttons',
		showEmergencyButtons: 'Show Emergency Buttons',
		appointmentDetail: 'Appointment Details',
		invitee: 'Invitee',
		selectOneOption: 'Select one of the following options',
		noCanceledAppointments: 'You have no canceled requests!',
		editOrder: 'Edit Order',
		reorderSessions: 'Reorder Sessions',
		rearrangeMonitoringSessions: 'Drag and drop to rearrange monitoring sessions',
		code: 'Code',
		alreadyAddedBreakTime: 'You already added that break time',
		completeAppointmentTitle: 'Complete Appointment?',
		completeAppointmentQuestion: 'Are you sure you want to complete this appointment?',
		markAsCompleted: 'Mark as completed',
		contactNurses: 'Contact Nurses',
		contactNurse: 'Contact Nurses',
		contactNursesDescription:
			'When this is enabled, the user will be able to contact nurses by choosing 3 options: Send a group message, Call a nurse on duty, or contact a specific nurse.',
		falseAlert: 'False Alert',
		forwardToNurses: 'Forward to Bedside Nurses',
		forwardToFrontlineNurses: 'Forward To Frontline Nurses',
		forwardToNursesDescription:
			'Communication workflow for the alerts to be forwarded by Virtual nurse or eSitter to the bedside nurses.',
		sendGroupMessage: 'Send Group Message',
		callNursesOnDuty: 'Call Nurses on Duty',
		specificNurse: 'Specific Nurse',
		stopTalking: 'Click to stop talking',
		checkBreakTime: 'Please add break time inside available hours',
		teams: 'Teams',
		sendMessage: 'Send Message',
		message: 'Message',
		chat: 'Chat',
		sort: 'Sort',
		viewLatestAlerts: 'View the latest alerts',
		sharedAttachments: 'Shared Attachments',
		links: 'Links',
		sharedLinks: 'Shared links',
		images: 'Images',
		sharedImages: 'Shared Images',
		videos: 'Videos',
		sharedVideos: 'Shared Videos',
		noTeamMembers: 'No team members',
		noTeamsYet: 'No teams yet',
		noCheckedInNurse: 'No checked in nurses available!',
		nursesUnavailable: 'No nurses available. Try again later.',
		receivedAlert: 'received an alert',
		sentAlert: 'sent an alert',
		addedComment: 'added a comment',
		markedThe: 'marked the',
		alertAcknowledged: 'alert as acknowledged',
		wasAddedAs: 'was added as an',
		hospitalCareTaker: 'in-hospital caretaker',
		alertDetails: 'Alert Details',
		measured: 'measured',
		forwardedToNurses: 'forwarded the alert to nurses',
		raiseAlert: 'Raise Alert',
		raiseAnAlertManually: 'Raise an alert manually',
		raiseAlertDescription:
			'Through this option, the nurse will be able to raise an alert manually for that patient. This alert will be forwarded to Bedside or Frontline nurses.',
		typeOfAlert: 'Type of Alert',
		isAtRisk: 'is at risk due to',
		bloodType: 'Blood Type',
		latestVitalSigns: 'Latest Vital Signs',
		latestDiagnosis: 'Latest Diagnosis',
		chronicDiseases: 'Chronic Diseases',
		allergies: 'Allergies',
		goToAllScripts: 'Go to Allscripts EHR',
		alertAsFalse: 'alert as false',
		manualAlerts: 'Manual Alerts',
		forwardToMSNurses: 'Forward to MS Nurses',
		raisedAnAlert: 'raised an alert',
		badURL: 'Bad URL. The URL format should be like https://www.google.com or http://www.google.com',
		symbolAndSpacesNotAllowed: 'Symbols and spaces are not allowed',
		symbolsNotAllowed: 'Symbols are not allowed!',
		call911: 'Call 911',
		call911Description: 'When this is enabled, users will be able to call 911 in one click.',
		addComment: 'Add Comment',
		saveComment: 'Save Comment',
		forwardToNoraApp: 'Forward to Nora App',
		mainMedicalFamilyCenter: 'Main Medical Family Center',
		medicalFamilyCenter: 'Medical Family Center',
		digitalClinic: 'Digital Clinic',
		checkIn: 'Check In',
		checkOut: 'Check Out',
		checkInPage: 'Check In',
		noCheckInAllowed: 'Check in is not allowed',
		userIsCheckedIn: 'User with the email: {value} is already registered.',
		hangUp: 'Hang Up',
		calling: 'Calling',
		emergencyCall: 'Emergency Call',
		callEnded: 'Call ended.',
		typeNameForPool: 'Type the name for this pool',
		selectRoles: 'Select Roles',
		selectRoleAppy: 'Select Role to apply active directory',
		checkInPatient: 'Check-in a patient',
		selectPatient: 'Select Patient',
		patientHealthDetails: 'Symptoms and Additional Health Data',
		measureVitalSigns: 'Measure Vital Signs of the patient',
		searchForPatients: 'Search for patients',
		addNewPatient: 'Add new patient',
		addPatient: 'Add patient',
		results: 'Results',
		showConsent: 'Consent from the patient',
		idNumber: 'ID Number',
		selectThePatient: 'Select patient',
		typeSymptoms: 'Type symptoms and signs here',
		selectedSymptoms: 'After you select the symptoms will appear here',
		howLongSymptoms: 'For how long do you have the symptoms?',
		preExistingCondition: 'Do you have any pre-existing conditions? If yes, then please type them below:',
		anyAllergies: 'Do you have any allergies?',
		dragAndDropOrUpload: 'Click here or drag and drop to upload a file',
		measurePatientVitalSigns: `Please measure the patient's Vital Signs`,
		saveAndSelectPatient: 'Save and select patient',
		addManually: 'Add Manually',
		userExists: 'This user already exists!',
		viewDevices: 'View Devices',
		assignDevicesList: 'Assigned Devices List',
		monitoringSettings: 'Monitoring Settings',
		editMonitoringSettings: 'Edit Monitoring Settings',
		nightVision: 'Night Vision',
		nightVisionDescription: 'When this is enabled, users will be able to have vision when the room is dark.',
		EHRDescription: `Access to patient's EHR data.`,
		microsoftTeams: 'Microsoft Teams',
		microsoftTeamsDescription: 'Communication workflow with the hospital Microsoft Team App',
		walkieTalkie: 'Walkie Talkie',
		walkieTalkieDescription: 'Audio broadcast to all the bedside nurses and virtual nurses checked in to the specific floor.',
		addDigitalClinician: 'Add Digital Clinician',
		digitalClinicianOnList: 'Digital Clinician',
		maxSizeExceeded: 'Try uploading a file under 2 MB',
		maxCompanySizeExceeded: 'Try uploading a file under 600 KB',
		appointmentDateRequired: 'Appointment Date is required',
		timeSlotRequired: 'Time slot is required',
		editSafeRanges: 'Edit Safe Ranges',
		thresholdAlertDescription: `Enter minimal and maximal values for each health data based on the patient’s condition. If any of the patient’s
		health data is below or above the selected minimal and maximal values, an alert will be sent.`,
		doctorPooling: 'Doctor Pooling',
		doctorList: 'Doctor List',
		addDoctor: 'Add Doctor',
		searchDoctor: 'Search Doctor',
		selectedDoctors: 'Selected Doctors',
		sureToDeleteDoctor: 'Are you sure you want to delete doctor {value}?',
		deleteDoctor: 'Delete Doctor',
		checkedIn: 'Checked In',
		checkedOut: 'Checked Out',
		placement: 'Placement',
		seeDoctorInDC: 'See doctor in digital clinic',
		patientIsInVisit: 'Patient is already in visit!',
		doctorAvailableForCall: `is available for a call now`,
		healthDataSafeRanges: `Health Data Safe Ranges`,
		medicalReport: 'Medical Report',
		doctorsStampSignature: "Doctor's stamp signature",
		doctorFullName: "Doctor's Full Name",
		printReports: 'Print Reports',
		printReport: 'Print Report',
		capsulesDosage: '{value} capsules',
		oneCapsule: '{value} capsule',
		specialty: 'Specialty',
		refillsLowercase: 'refills',
		cityMustHaveLetter: 'City must contain letters',
		ok: 'OK',
		measuredIn: 'measured in',
		completeRegistration: 'We recommend you to fill in your personal & professional information in the below form.',
		dragAndDropOr: 'Drag and drop or',
		click: 'click here',
		uploadProfilePicture: 'to upload your profile picture',
		addLicense: 'Add License',
		licenseNo: 'License No',
		selectDate: 'Select Date',
		selectCountry: 'Select Country',
		selectSpecialty: 'Select Specialty',
		saveAndContinue: 'Save & Continue',
		position: 'Position',
		addPosition: 'Add Position',
		company: 'Company',
		educationHistory: 'Education History',
		school: 'School',
		degree: 'Degree',
		startYear: 'Start Year',
		endYear: 'End Year',
		employmentHistory: 'Employment History',
		addEducation: 'Add Education',
		licenses: 'Licenses',
		reset: 'Reset',
		companyNameRequired: 'Company name is required',
		doctorsStamp: 'Doctors Stamp',
		doctorsSignature: `Doctor's Signature`,
		companyWebsiteRequired: 'Company website is required',
		companyAddressRequired: 'Company address is required',
		somethingWrongWithConference: 'Something went wrong while creating conference!',
		patientIsOnWaitingRoom: 'Patient is on waiting room now!',
		deviceOfflineNow: 'Device is offline at the moment. Please try again!',
		personalNumber: 'Personal Number',
		pleaseWriteIdNumber: 'Please write your ID number',
		idNumberLength: 'ID number should consist of 10 characters and should not start with zero.',
		patientHealthCardNumberLength:
			'Patient Health Card number should consist of 10 to 20 characters and should not start with zero.',
		diabetes: 'Diabetes',
		oxygen: 'Oxygen',
		distanceWalkingRunning: 'Walking + Running Distance',
		temperature: 'Temperature',
		pi: 'Perfusion Index',
		numberOfTimesFallen: 'Number of Times Fallen',
		pleaseUploadProfilePicture: 'Please upload a profile picture',
		beforeContinue: 'Before you continue',
		currentlyWorkingHere: 'Currently working here',
		currentlyStudyingHere: 'Currently studying here',
		high: 'High',
		low: 'Low',
		criticalHigh: 'Critical High',
		criticalLow: 'Critical Low',
		french: 'French',
		german: 'German',
		dutch: 'Dutch',
		turkish: 'Turkish',
		spanish: 'Spanish',
		englishUnitedStates: 'English (United States)',
		serbian: 'Serbian',
		chinese: 'Chinese',
		russian: 'Russian',
		mandarinChinese: 'Mandarin Chinese',
		graduateSchool: 'Graduate School',
		nursePractitioner: 'Nurse Practitioner',
		emergencyMedicinePhysician: 'Emergency Medicine Physician',
		hospitalist: 'Hospitalist',
		internist: 'Internist',
		pediatrician: 'Pediatrician',
		psychiatrist: 'Psychiatrist',
		childPsychologist: 'Child Psychologist',
		counselor: 'Counselor',
		psychologist: 'Psychologist',
		psychotherapist: 'Psychotherapist',
		other: 'Other',
		newVersionAvailable: 'New version of the app is available',
		checkInOtherPatient:
			'If you continue to check in another patient, then all the current changes that you have made will be lost. Are you sure you want to discard your changes and start over with the check-in process?',
		discardAndContinue: 'Discard changes and continue',
		currentSignature: 'Current Signature',
		cancelCall: 'Cancel Call',
		deviceOfflineTryLater: 'This device is offline. Try again later.',
		deviceOnCallTryLater: 'This device is currently on a call. Try again later.',
		deviceOfflineTryLaterExtended: '{value1} is offline. Try again later.',
		deviceOnCallTryLaterExtended: '{value1} is currently on a call. Try again later.',
		cannotConnect: 'Cannot connect. Please try again.',
		callDeclined: 'Call has been declined',
		callEndedTryAgain: 'Call has ended. Please try again',
		failedReconnect: 'Failed to reconnect to the network. Please try again.',
		idleCallEnded: 'You have been idle for too long. Call is ended.',
		riskOfFall: 'Risk of fall',
		patientNeedsHelp: 'Patient needs help',
		patientRequiresHelp: 'Patient requires help',
		otherActivity: 'Other Activity',
		searchPatientByName: `Please search patient by first name and last name in order to see it's visits' information`,
		switchToHelloSource: 'Click to switch to this source',
		tvActiveOnThiSource: 'Tv is active on this source',
		powerOnTV: 'Power ON TV',
		powerOffTV: 'Power Off TV',
		time: 'Time',
		macAddress: 'Mac Address',
		macUpperAddress: 'MAC Address',
		appVersion: 'App Version',
		androidVersion: 'Android Version',
		osVersion: 'OS Version',
		ipAddress: 'IP Address',
		stethoscopeAudioRecordings: 'Stethoscope audio recordings',
		selectPatientAndContinue: 'Please select one of the patients in order to proceed with further procedures',
		unit: 'Unit',
		pleaseWriteHeight: 'Please write your height',
		pleaseWriteWeight: 'Please write your weight',
		pleaseWriteTotalCholesterol: 'Please write your total cholesterol',
		pleaseWriteHdlCholesterol: 'Please write your HDL cholesterol',
		pleaseWriteWaistCircumference: 'Please write your waist circumference',
		centimeters: 'Centimeters',
		kilograms: 'Kilograms',
		pleaseSelectCountry: 'Please select a country',
		pleaseWriteCity: 'Please write a city',
		pleaseWriteZipCode: 'Please write a zip code',
		pleaseWriteAddress: 'Please write an address',
		pleaseWriteLicense: 'Please write a license',
		pleaseWriteSchool: 'Please enter the school',
		pleaseSelectDegree: 'Please select a degree',
		pleaseWriteStartDate: 'Please write start date',
		pleaseWriteCorrectDate: 'Please enter a correct date',
		startDateCannotBeHigher: "Start date can't be higher than today.",
		startDateCannotBeThatLower: "Start date can't be lower than 120 years from now.",
		endDateCannotBeSameAsStartDate: 'The end date must be at least 1 year higher than the start date.',
		pleaseWriteEndDate: 'Please write end date',
		pleaseWriteCompany: 'Please write company name',
		pleaseWritePosition: 'Please write position',
		clear: 'Clear',
		noDiagnosesFound: 'No diagnoses found',
		noPrescriptionsFound: 'No prescriptions found',
		noNotesFound: 'No notes found',
		noProcedureFound: 'No procedure found',
		setupAvailability: 'Setup Availability',
		noVisitsFound: 'No visits found',
		connectionType: 'Connection Type',
		physicalExercises: 'Physical Exercises',
		physicalTherapy: 'Physical Therapy',
		medicalInfoPhysicalTherapy: 'Physical Therapy',
		physicalTherapist: 'Physical Therapist',
		startingDate: 'Starting Date',
		targetedDays: 'Targeted Days',
		pTPrescriptionReport: 'PT Prescription Report',
		dataEditedSuccessfully: 'Data has been edited successfully',
		exercise: 'Exercise',
		exerciseDate: 'Exercise Date',
		editExercise: 'Edit Exercise',
		noPhysicalExercisesFound: 'No physical therapies found',
		treeView: 'Tree View',
		listView: 'List View',
		currentlyLoggedInAs: 'You are currently logged in as',
		logOutHere: 'Log out here',
		pleaseLogOutFromThisAccount: 'Please sign out from this account and go back to your email to accept this invitation.',
		thisDeviceIsOnACall: 'This device is on a call.',
		onePatient: 'Patient',
		multiplePatients: 'Patients',
		noOptions: 'No Options',
		anotherProcedure: 'procedure',
		addExistingPatient: 'Add existing patient',
		addOrUpdate: 'Add or update your',
		locationAndAddress: 'Location & Address',
		signatureAndStamp: 'Signature & Stamp',
		employment: 'Employment',
		education: 'Education',
		signatureDescription: 'When you save your changes, whatever you write in the canvas will become your signature.',
		stampDescription: 'It is recommended that the stamp be transparent and in the PNG format.',
		visceralFatGrade: 'Visceral Fat Grade',
		daysNo: 'days',
		emailExists: 'Email exists',
		gridView: 'Grid View',
		singleView: 'Single View',
		unknown: 'Unknown',
		measuredAt: 'Measured At',
		patientName: 'Patient Name',
		patientMRN: 'Patient MRN',
		of: 'of',
		firstNameNonNumber: 'First name should contain only characters.',
		lastNameNonNumber: 'Last name should contain only characters.',
		parentNameNonNumber: 'Parent name should contain only characters.',
		cityNameNonNumber: 'The name of the city should contain only characters.',
		allergyNameNonNumber: 'Allergy name should contain only letters',
		dateOfBirthNotValid: 'Date of birth is not valid',
		minHeightIs: 'Min height is',
		maxHeightIs: 'Max height is',
		minWeightIs: 'Min weight is',
		maxWeightIs: 'Max weight is',
		minTotalCholesterolIs: 'Min total cholesterol is',
		maxTotalCholesterolIs: 'Max total cholesterol is',
		minHdlCholesterolIs: 'Min HDL cholesterol is',
		maxHdlCholesterolIs: 'Max HDL cholesterol is',
		minWaistCircumferenceIs: 'Min waist circumference is',
		maxWaistCircumferenceIs: 'Max waist circumference is',
		dateFormat: 'Date Format',
		timeFormat: 'Time Format',
		companyRegion: 'Company Region',
		companyOwner: 'Company Owner',
		invite: 'Invite',
		defaultMeasurementUnits: 'Default Measurement Units',
		userCanChangeMeasurement:
			'The selected units will be default for each user that is registered in your company, but each user will be able to change it to their preference (only for themselves).',
		walkingRunningDistanceRequired: 'Walking + Running Distance is required',
		weightRequired: 'Weight is required',
		bodyTemperatureRequired: 'Temperature is required',
		heightRequired: 'Height is required',
		bloodGlucoseRequired: 'Blood glucose is required',
		dateFormatRequired: 'Date format is required',
		timeFormatRequired: 'Time format is required',
		cameraNameRequired: 'Camera name required',
		enterCameraName: 'Enter camera name here',
		uniqueEmail: 'Emails should be unique',
		companies: 'Companies',
		verificationEmailFollowInstructions: 'You have received an verification email, please follow the instructions.',
		success: 'Success',
		requiredCompanyName: 'Company Name is required',
		requiredWebsiteName: 'Website URL is required',
		milesUnit: 'mi (Mile)',
		kmUnit: 'km (Kilometer)',
		lbsUnit: 'lbs (Pounds)',
		kgUnit: 'kg (Kilogram)',
		fahrenheitUnit: '°F (Fahrenheit)',
		celsiusUnit: '°C (Celsius)',
		ftUnit: 'ft (Feet)',
		cmUnit: 'cm (Centimeters)',
		mgUnit: 'mg/dL (Milligrams per Deciliter)',
		mmolUnit: 'mmol/L (Millimoles per Liter)',
		hourFormat12: '12-hour',
		hourFormat24: '24-hour',
		continueAs: 'Continue as',
		rememberAsDefaultChoice: 'Remember this as my default choice',
		myUserRoles: 'My User Roles',
		switch: 'Switch',
		addPatientRole: 'Add Patient Role',
		addDoctorRole: 'Add Doctor Role',
		primarySpecialty: 'Primary Specialty',
		selectStates: 'Select State(s)',
		statesLicensed: 'States Licensed In:',
		addARole: 'Add a role',
		resetTwoFa: 'Reset two factor authentication',
		selectAuth: 'Select two factor authentication',
		pleaseSelectRole: 'Please select a role you want to add to this member',
		switchRole: 'Switch Role',
		providerOrPatient: 'Switch to a provider or patient account',
		admin: 'Admin',
		selectRole: 'Select a role',
		digitalClinician: 'Digital Clinician',
		areYouSureDeleteRole: 'Are you sure you want to delete this role from this member?',
		deleteRole: 'Delete Role?',
		memberRole: 'role',
		hrs: 'hrs',
		physicalTherapyExercises: 'Physical Therapy Exercises',
		addNewExercise: 'Add a new exercise',
		selectTypeOfExercise: 'Select Type of Exercise',
		exerciseSettings: 'Exercise Settings',
		addExerciseSettings: 'Add Exercise Settings',
		noAddedExercise: ' has no added exercises!',
		selectCategory: 'Select Category',
		regulateExerciseIntensity: 'Regulate the exercises intensity',
		selectDatesForExercise: 'Select dates for exercise',
		containOnlyNumbers: 'It should contain only numbers',
		rangeExceeded: 'Number should be between {value1} and {value2}',
		downloadReport: 'Download Report',
		downloadPatientReport: 'Download Patient Report',
		participantProfile: 'Participant profile',
		bridge: 'Bridge',
		togglePatientsTvOFF: `Is powered OFF`,
		togglePatientsTvON: `Is powered ON`,
		toggleGridView: 'Toggle grid view',
		toggleLayoutView: 'Toggle layout view',
		invitePeople: 'Invite people',
		moreOptions: 'More options',
		phoneNumberExists: 'Phone number already exists.',
		pleaseEnterValidNumber: 'Please enter a valid phone number.',
		directLink: 'Direct link',
		sendLinkToPeople: 'Send this link to the people you want to invite',
		addEmail: 'Add Email',
		inviteViaEmail: 'Invite via Email',
		pleaseEnterToAddEmail: 'Press enter to add email.',
		inviteViaSms: 'Invite via SMS',
		inviteSms: 'Invite via SMS',
		pressEnterToAddPhoneNumber: 'Press enter to add phone number.',
		joinViaPhone: 'Join via Phone',
		accessCode: 'Access code',
		sendInvite: 'Send invite',
		addOneOrMorePplEmailSmsLink: 'Add one or more people via Email, SMS, Link',
		pleaseEnterValidEmail: 'Please enter a valid email.',
		emailAlreadyExists: 'This email already exists.',
		conferenceLoading: 'Conference is loading',
		cameraIsOff: 'Camera is off',
		joinNow: 'Join now',
		youAreLoggedInAs: `You're logged in as`,
		youAreAboutToParticipate: 'You are going to participate in a telemedicine appointment.',
		pleaseIntroduceYourself: 'Please introduce yourself',
		yourName: 'Your name',
		askToJoin: 'Ask to join',
		conferenceNotActive: 'Conference is not active!',
		joiningConference: 'Joining conference',
		firstNameRequired: 'First name is required',
		lastNameRequired: 'Last name is required',
		seeingOrHearingPatient: `You are not {value5} the {value2} because the physical {value3} privacy button has been enabled on {value}. This button can be disabled only manually. Please contact your administrator. {value4}`,
		alsoTvIsMuted: 'Also, the TV is muted or OFF. Please make sure the {value} can hear you.',
		tvIsMuted: 'TV is muted or OFF. Please make sure the {value} can hear you.',
		thePatient: 'patient',
		theBaby: 'baby',
		connectingToHello: `Connecting to {value}`,
		loadingCamera: 'Loading camera',
		cameraAndMicBlocked: 'Your Camera and Microphone are blocked',
		micBlocked: 'Your Microphone is blocked',
		cameraBlocked: 'Your Camera is blocked',
		clickMicOrCamera: `Click the {value} blocked icon on the top right of your screen`,
		camera: 'Camera',
		microphone: 'Microphone',
		maximizeFeed: 'Enter full screen mode',
		minimizeFeed: 'Exit full screen mode',
		allowAccessRefreshPage: 'Allow access and refresh the page',
		youHaveLeftMeeting: 'You have left the meeting',
		meetingEndedFamilyMember: `The session has been ended by the hospital. Privacy buttons are enabled on {value} Device. You can see the baby when privacy buttons are disabled.`,
		babyOutsideVisitingHoursMessage: 'You cannot see your baby outside visiting hours.',
		unSupportedTV: 'Unsupported TV',
		commandIsNotSupportedPatientTV: `This command is not supported on this patient's TV.`,
		areUsureUwantToLeaveSession: `Are you sure you want to leave the session?`,
		copy: `Copy`,
		copied: `Copied`,
		failedToCopyLink: `Failed to copy link!`,
		copyCorrelationId: `Copy correlation id`,
		thereIsActiveConference: 'There is an already active conference',
		callTransferredToAnotherClient: 'Call transferred to another client.',
		callAnsweredElsewhere: 'Call answered on another device.',
		rejoin: 'Rejoin',
		submitPhysicalTherapy: 'Submit physical therapy',
		universalSafetyInterventions: 'Universal Safety Interventions',
		fallRisk: 'Fall Risk',
		dailyWeight: 'Daily Weight',
		fallHarmRisk: 'Fall with Harm Risk',
		silentHeartAttack: 'Silent Heart Attack',
		addSuperAdmin: 'Add Super Admin',
		privacyOn: 'Privacy On',
		privacyOff: 'Privacy Off',
		settings: 'Settings',
		safeRanges: 'Safe Ranges',
		pressureInjury: 'Pressure Injury',
		getOutOfBed: 'Getting Out Of Bed',
		screenMode: 'Screen Mode',
		speakerAudioVolume: 'Speaker Audio Volume',
		displayOrientationExtended: 'Display Orientation Extended',
		roundingDefaultCamera: 'Rounding Default Camera',
		monitoringDefaultCamera: 'Monitoring Default Camera',
		faceDown: 'Face Down',
		rails: 'Rails',
		left: 'Left',
		right: 'Right',
		top: 'Top',
		bottom: 'Bottom',
		skeletonMode: 'Skeleton Mode',
		physicalTherapies: 'Physical Therapies',
		addExercise: 'Add Exercise',
		seconds: 'seconds',
		hour: 'hour',
		noRooms: 'No rooms at the moment.',
		callType: 'Call Type',
		details: 'Details',
		exportCSV: 'Export CSV Logs',
		exportToCSV: 'Export to CSV',
		cannotBeNDaysDifference: 'Date difference cannot be more than {value} days',
		reportSentSuccessfully: 'Report has been sent successfully.',
		toAccessCheckEmail: 'To access and download the report, please continue on your email address.',
		reportIsUnderway: 'Report is underway.',
		reportUnderwayDescription: 'Your report is underway and it may take a while depending on data size.',
		thankYouForPatience: 'Thank you for your patience!',
		listOfRooms: 'List of Rooms',
		exportCSVInBackground:
			'The CSV export will be created in the background. Once finished, it will be sent to {value} in an attachment.',
		talkToPatient: 'Talk to Patient',
		talkToNurse: 'Talk to nurse',
		patientView: 'Patient View',
		ongoing: 'Ongoing',
		past: 'Past',
		screenUsed: 'Screen Used',
		callDetails: 'Call Details',
		endCallForRoom: 'End call for room',
		retry: 'Retry',
		callDurationSoFar: 'Call Duration (so far)',
		noOngoingConferences: 'No ongoing conferences at the moment.',
		refresh: 'Refresh',
		areYouSureToTerminate:
			'Are you sure you want to terminate this session? This will end the call between the doctor and the patient.',
		terminateRequestTimeOut: 'Terminate request timeout',
		retryRequest: 'Do you want to retry? This will end the call between the doctor and the patient.',
		sessionTerminatedSuccessfully: 'Session has been terminated successfully.',
		terminateRoomSession:
			'Are you sure you want to terminate this room session? This will end the call between the doctor and the patient.',
		roomSessionTerminated: 'Room session has been terminated successfully.',
		sessionAlreadyEnded: 'This session has already been ended.',
		terminationInitiated: 'Termination for this session is initiated by another administrator.',
		noPermissionToTerminate: 'You have no permission to terminate this session.',
		assignedDevices: 'Assigned Devices',
		assignedDevice: 'Assigned Device',
		availabilitySetup: 'Availability',
		data: 'Data',
		participants: 'Participants',
		nursesCurrentlyDuty: 'Nurses are currently on duty',
		noCategory: 'No category',
		required: 'Required',
		fillRequiredFields: 'Fill required fields',
		ai: 'AI',
		noMoreThanOneAiType: `AI is already active`,
		maxBirthdayLimit: 'You must be at most 120 years old',
		year: 'Year',
		video: 'Video',
		file: 'File',
		invalidLink: 'Invalid link. Please try again!',
		measureCompleted: 'Measure Completed',
		couldNotGetPatientData: 'Could not get patient data',
		tryAgainWithoutMoving: 'Try again without moving',
		pressurizationSlow: 'Pressurization slow! Please check the cuff!',
		wait5minutes: 'Wait 5 minutes and try again',
		doToRetakeMeasure: 'You can {value} to retake measuring!',
		toRemeasureTemperature: `To retake the measurement, press the thermometer's measure button.`,
		doToReconnect: 'You can {value} to reconnect!',
		measureHasFinished: '{value} has finished measuring',
		deviceIsConnected: '{value} - Device is connected',
		deviceIsConnecting: '{value} - Device is connecting',
		deviceDisconnected: '{value} - Device has been disconnected',
		pleaseWaitConnection: 'Please wait till the connection is established',
		pleaseStandOnScale: 'Please stand on the scale to start measuring.',
		valueIsDisconnected: '{value} is disconnected',
		valueIsConnecting: '{value} is connecting',
		valueIsConnected: '{value} is connected',
		deviceCouldNotConnect: '{value} - Could not connect to the device. Check bluetooth',
		deviceMeasuring: '{value} - Device is measuring',
		deviceConnectFail: '{value} - Device connection fail',
		deviceMeasureStopped: '{value} - Measure stopped',
		deviceMeasureCompleted: '{value} - Measure Completed',
		deviceReconnecting: '{value} - Device is reconnecting',
		problemWithReader: 'Problem with the reader. Take a new test with a new test strip!',
		testStripRemoved: 'The test strip was removed during the measurement.',
		problemStrip:
			'Problem with the test strip (wet strip, removed too quickly, too much blood applied to the strip). Take a new test with a new test strip!',
		lowRoomTemperature: 'The room temperature is too low. Optimal temperature is 10°C - 40°C / 50°F - 104°F',
		highRoomTemperature: 'The room temperature is too high. Optimal temperature is 10°C - 40°C / 50°F - 104°F',
		communicationError: '{value} - Communication error. Press and hold the M (Memory) button for 7 seconds to restore the device',
		newTestNewStrip: 'Take a new test with a new test strip!',
		getBlood: 'Get Blood!',
		measureStarted: 'Measure started',
		stripIn: 'Strip in',
		stripOut: 'Strip out',
		totalCholesterol: 'Total Cholesterol',
		hdlCholesterol: 'HDL Cholesterol',
		waistCircumference: 'Waist Circumference',
		healthInformation: 'Health Information',
		currentEverydaySmoker: 'Current every day smoker',
		currentSomedaySmoker: 'Current some day smoker',
		formerSmoker: 'Former smoker',
		neverSmoker: 'Never smoker',
		sufferFromHypertension: 'Do you suffer from hypertension?',
		medicalStatus: 'Do you have a medication status?',
		areYouSmoker: 'Are you a smoker?',
		haveDiabeticStatus: 'Do you have a diabetic status?',
		patientIsBeingRegistered: 'Patient is being registered',
		patientAlreadyExists: 'Patient already exists',
		pleaseSelectOneOfTheOptionsBelow: 'Please select one of the options below',
		diabetesMellitusI: 'Diabetes Mellitus Type I',
		diabetesMellitusII: 'Diabetes Mellitus Type II',
		submit: 'Submit',
		dateTimeFormats: 'Date Time Formats',
		seeingAndHearing: 'seeing and hearing',
		seeing: 'seeing',
		hearing: 'hearing',
		patientRequestedHelp: 'Patient requested help',
		patientSitting: 'Patient was detected inactive in a chair position.',
		patientStanding: 'Patient was detected inactive in standing position.',
		patientLyingSide: 'Patient was detected inactive in lateral position.',
		patientLyingRightSide: 'Patient was detected inactive in right lateral position.',
		patientLyingLeftSide: 'Patient was detected inactive in left lateral position.',
		patientLyingFront: 'Patient was detected inactive in supine position.',
		patientFaceDown: 'Patient was detected inactive in prone position.',
		patientInactive: 'Patient was detected inactive.',
		closed: 'Closed',
		or: 'or',
		history: 'History',
		availableDoctors: 'Available Doctors',
		alreadySelected: 'Already selected',
		alreadyInPool: 'Already in pool',
		cantAddMoreDoctors: "Can't add more than 5 doctors at a time!",
		cam: 'camera',
		mic: 'mic',
		camAndMic: 'camera and mic',
		hellocareMode: 'Hellocare Mode',
		aiWidgetMode: 'AI Widget Mode',
		telemedicineMode: 'Telemedicine Mode',
		nicuMode: 'NICU Mode',
		virtualExaminationMode: 'Virtual Examination Mode',
		simplifiedMode: 'Simplified Mode',
		eVideonMode: 'eVideon Mode',
		hellocareEngagement: 'Hellocare Engagement',
		patientMonitoring: 'Patient Monitoring',
		hospitalMode: 'Hospital Mode',
		signOut: 'Sign Out',
		primaryHealthcare: 'Primary Healthcare',
		healthSystemType: 'Health System Type',
		selectHealthSystemType: 'Select Health System Type',
		patientGettingOutOfBed: 'Patient is getting out of bed',
		clickToLoginAnotherAcc: 'Click here to login with another account.',
		notAssignedHs: 'You are currently not assigned to any health system',
		digitalClinicMode: 'Digital Clinic Mode',
		impulsiveConfused: 'Impulsive/Confused',
		pullingO2: 'Pulling O2',
		nonRedirectable: 'Non-redirectable',
		precautions: 'Precautions',
		precautionsDescription:
			'When this is enabled, nurses will be able to set precautions such as fall risk for the patient so they can easily visualize, and sort patients based on the precautions list.',
		terminateSession: 'Terminate session?',
		terminateRoomCall: 'Terminate room session?',
		selectRoom: 'Select room',
		selectLocationReassin: 'Select a location from the health system above to manage the hospitals on it.',
		selectLocation: 'Select Location',
		selectHsToManage: 'Choose the health system you want to manage.',
		reAssignDevice: 'Reassign Device',
		regionSaved: 'Region Saved',
		somethingWrong: 'Something went wrong!',
		selectLocationReassign: 'Choose a location from the health system above to manage the hospitals in it.',
		hi: 'Hi',
		selectOptionsBelow: 'Select options below',
		willBeReceivingCalls: 'I will be receiving calls from patients',
		willNotBeReceivingCalls: 'I will not be receiving calls from patients',
		selectHealthSystems: 'Select Health Systems',
		selectReceivingCalls: 'Select whether you will be receiving calls from patients.',
		selectHealthSystemsReceiveCalls: 'Select the health systems you will be receiving calls from.',
		selectLevelReceiveCalls: 'Select the level from which you will be receiving calls.',
		neckExercises: 'Neck Exercises',
		legExercises: 'Leg Exercises',
		shoulderExercises: 'Shoulder Exercises',
		hipExercises: 'Hip Exercises',
		extendedLegExercise: 'Extended Leg Exercise',
		singleLegGluteKicksExercise: 'Single Leg Glute Kicks Exercise',
		standingMarchExercise: 'Standing March Exercise',
		hamstringStretchExercise: 'Hamstring Stretch Exercise',
		standingQuadStretchExercise: 'Standing Quad Stretch Exercise',
		neckExercise: 'Neck Exercise',
		neckStretchExercise: 'Neck Stretch Exercise',
		liftingHeadSidewaysExercise: 'Lifting Head Sideways Exercise',
		shoulderExercise: 'Shoulder Exercise',
		armRaisesExercise: 'Arm Raises Exercise',
		rotatorCuffExercise: 'Rotator Cuff Exercise',
		shoulderExtensionExercise: 'Shoulder Extension Exercise',
		seatedShoulderRaiseExercise: 'Seated Shoulder Raise Exercise',
		hipExercise: 'Hip Exercise',
		hipMarchingExercise: 'Hip Marching Exercise',
		sideLyingLegLiftExercise: 'Side Lying Leg Lift Exercise',
		fireHydrantExercise: 'Fire Hydrant Exercise',
		maximumRangeOfMotion: 'Maximum range of motion',
		leftSideNumberOfRepetition: 'Left side number of repetition',
		rightSideNumberOfRepetition: 'Right side number of repetition',
		numberOfDaysToAchieveTheTarget: 'Number of days to achieve the target',
		degreeIncrementInTargetROM: 'Degree increment in target ROM',
		holdingTimeInSeconds: 'Holding time in seconds',
		numberOfRepetition: 'Number of repetition',
		numberOfRepetitionForRightNeck: 'Right side number of repetition',
		addPhysicalTherapy: 'Add a physical therapy',
		remotePairedWithDevice: `There's a remote paired with this device. Do you want to unpair it?`,
		remoteNotPairedWithDevice: `This device does not have any remotes paired. If you wish to pair a remote, press and hold the OK and Back button until you see a red pulsing light on the remote, and then click Pair on the web.`,
		remotePairedSuccessfully: 'Remote paired successfully.',
		remoteUnpairedSuccessfully: 'Remote unpaired successfully.',
		pairingTimeout: 'Pairing time out. Please try again.',
		pair: 'Pair',
		unpair: 'Unpair',
		dismiss: 'Dismiss',
		tryingToPairRemote: 'Trying to pair the remote...',
		tryingToUnpairRemote: 'Trying to unpair the remote...',
		remoteControl: 'Remote',
		patientStandingWalking: 'Loss of balance detected',
		personIsDetectedWalking: 'Loss of balance detected. Please check the patient!',
		analyticPositionDescription: 'Patient was detected {value} for {value1}',
		lyingFront: 'lying front',
		lyingSide: 'lying side',
		lyingDown: 'lying down',
		lying: 'Lying',
		sitting: 'Sitting',
		walking: 'Walking',
		inviteUsers: 'Invite users',
		cannotEnableDataAcquisition: 'You cannot enable data acquisition since this device currently has privacy turned on.',
		cannotEnablePrivacyMode: 'You cannot enable privacy since this device currently has data acquisition turned on.',
		careEventsForNurses: 'Care Events for Nurses',
		roundingCareEventsForNurses: 'Care Events for Nurses',
		careEventsForNursesDescription:
			'Patient care events such as Admission, Consultation, Treatment, and Discharge performed by VCPs are registered.',
		careEventsForSafetyCompanions: 'Interventions and alerts for Safety Companions and Nurses',
		roundingCareEvents: 'Rounding Care Events',
		roundingCareEventsDesc: 'By enabling this option, you can set the reason of the call before rounding call or during the call',
		careEvents: 'Care Events',
		careEvent: 'Care Event',
		recordings: 'Recordings',
		savedCareEvent: 'Saved Care Event',
		eventDetails: 'Event Details',
		homeMedListCompleted: 'HomeMed List Completed',
		admissionDataCompleted: 'Admission Data Completed',
		safetyCompanions: 'Safety Companions',
		companionModeName: 'Companion Mode',
		frontlineNurses: 'Frontline Nurses',
		ipoCompleted: 'IPOC completed',
		dischargeInstructionsCreated: 'Discharge Instructions Created',
		dischargeTeachingCompleted: 'Discharge Teaching Completed',
		performed2ndRNMedVerification: 'Performed 2nd RNMed Verification',
		notifiedMD: 'Notified MD',
		performedMentorship: 'Performed Mentorship',
		performedSepsisScreenNotification: 'Performed Sepsis Screen Notification',
		completed24HourChartCheck: 'Completed 24 Hour Chart Check',
		performedClinicalRounding: 'Performed Clinical Rounding',
		providedUpdateToFamily: 'Provided Update To Family',
		assistedWithCodeBlue: 'Assisted With Code Blue',
		otherCareEventForNurse: 'Other Care Event For Nurse',
		redirectedPatientToPreventFall: 'Redirected Patient To Prevent Fall',
		notifiedBedsideStaffToAssistPatientToPreventFall: 'Notified Bedside Staff To Assist Patient To PreventFall',
		notifiedBedsideStaffToAssistPatient: 'Notified Bedside Staff To Assist Patient',
		calledRapidResponse: 'Called Rapid Response',
		preventedHarmToStaffMember: 'Prevented Harm To Staff Member',
		typeOfCareEvent: 'Add a Care Event',
		stopRecording: 'Stop Recording',
		record: 'Record',
		startRecording: 'Start Recording',
		videoRecordingSaving: 'Video Feed Recording is saving',
		chooseValidDate: 'End date cannot be in the future',
		patientHistory: 'Patient History',
		additionalHealthDataSymptoms: 'Additional Health Data and Symptoms',
		patientRegistration: 'Patient Registration',
		parentName: 'Parent Name',
		patientHealthCardNumber: 'Patient Health Card Number',
		maritalStatus: 'Marital Status',
		single: 'Single',
		married: 'Married',
		divorced: 'Divorced',
		widowWidower: 'Widow/Widower',
		registerNewPatient: 'Register New Patient',
		getPatientData: 'Get patient data',
		menuSettings: 'Menu Settings',
		editMenuSettings: 'Edit Menu Settings',
		alreadyWaitingForRequest: 'You are already waiting for a request',
		whatReasonOfVisit: 'What is the reason of your visit today?',
		reasonOfVisit: 'Reason of visit',
		cannotMeasureVS:
			'Vital signs can not be measured from web if you are not on the call with the doctor. Go to or download the hellocare app on mobile to measure vital signs.',
		takeBreak: 'Take a break',
		resumeWorking: 'Resume Working',
		symptomsDuration: 'Symptoms Duration',
		frontDeskCheckIn: 'Front Desk Check In',
		startVisit: 'Start Visit',
		minutesWaitingRoom: 'Minutes in the waiting room',
		markVisitAsCompleted: 'Mark visit as completed',
		inPerson: 'In Person',
		virtualCall: 'Virtual Call',
		instructionsForConsultation: 'Instructions for consultation',
		instructionsForDiagnosticResearch: 'Instructions for diagnostic research',
		healthInstitutionWherePatientInstructed: 'Health Institution where the patient is instructed',
		consultant: 'Consultant',
		purposeOfInstruction: 'Purpose of the Instruction',
		medicalHistory: 'Medical History',
		examinations: 'Examinations',
		icd9Code: 'ICD-9 Code',
		currentTreatment: 'Current Treatment',
		codeMedicines: 'Code Medicines',
		workingDiagnosis: 'Working Diagnosis',
		icd10Code: 'ICD-10 Code',
		organizationalUnit: 'Organizational Unit',
		organizationalUnitCode: 'Organizational Unit Code',
		addressOfInstitution: 'Address of Institution',
		consultantDoctor: 'Consultant Doctor',
		procedureCode: 'Procedure Code',
		icpc: 'ICPC',
		addForms: 'Add Forms',
		visitDetails: 'Visit Details',
		forms: 'Forms',
		numberOfVisits: 'Number of visits',
		lastVisit: 'Last visit',
		showVisitHistory: 'Show Visit History',
		remove: 'Remove',
		nextVisit: 'Next Visit',
		waitingList: 'Waiting List',
		nextTest: 'Next Test',
		startTesting: 'Start Testing',
		instructions: 'Instructions',
		test: 'Test',
		sample: 'Sample',
		enterResults: 'Enter Results',
		submitResults: 'Submit Results',
		checkInPatients: 'Check In Patients',
		doYouHaveAllergies: 'Do you have any allergies?',
		waitingResults: 'Waiting Results',
		healthInstitution: 'Health Institution',
		codeHealthInstitution: 'Health Institution Code',
		addedForms: 'Added Forms',
		addAnotherTest: 'Add Another Test',
		doesPatientHaveAllergies: 'Does the patient have any allergies?',
		noResponseFromPatient: 'No response from the patient',
		whatTypeOfAllergies: 'What type of allergies does the patient have?',
		food: 'Food',
		environmental: 'Environmental',
		biological: 'Biological',
		foodAllergies: 'Food Allergies',
		medicationAllergies: 'Medication Allergies',
		addAllergies: 'Add allergies',
		registerPatient: 'Register Patient',
		helloIsDisconnected: `{value} is disconnected!`,
		connectionCannotEstablish: `Connection to the {value} could not be established. Please check the internet connection on the device and try again.`,
		cannotChangeWorkflow: 'Cannot change workflow',
		noCompletedAppointments: 'You have no completed requests!',
		cholesterol: 'Cholesterol',
		totalCholesterolTooltip:
			'Your total blood cholesterol is a measure of LDL cholesterol, HDL cholesterol, and other lipid components. Your doctor will use your total cholesterol number when determining your risk for heart disease and how best to manage it.',
		hdlCholesterolToolip:
			'HDL (high-density lipoprotein), or “good” cholesterol, absorbs cholesterol and carries it back to the liver. The liver then flushes it from the body. High levels of HDL cholesterol can lower your risk for heart disease and stroke.',
		waistCircumferenceTooltip:
			'Another way to estimate your potential disease risk is to measure your waist circumference. Excessive abdominal fat may be serious because it places you at greater risk for developing obesity-related conditions, such as Type 2 Diabetes, high blood pressure, and coronary artery disease. So your waistline may be telling you that you have a higher risk of developing obesity-related conditions.',
		meetingWithDoctor: 'Meeting with Dr.',
		meetingWithPatient: 'Meeting with patient:',
		enterNoteHere: 'Enter note here',
		accountIsDeactivated: 'Your account is deactivated',
		helloUser: `Hello {value}`,
		reachAdministrator: 'Please reach out to your administrator for further information',
		numberOfSamples: 'Number of Samples',
		numberOfTests: 'Number of Tests',
		emergencyCases: 'Emergency Cases',
		startEmergencyCase: 'Start Emergency Case',
		waitingForResults: 'Waiting for Results',
		searchPatientNameOrNumber: 'Search patient name or ID card number',
		addResults: 'Add Results',
		network: 'Network',
		profileName: 'Profile name',
		cidr: 'CIDR',
		wiFiBand: 'WiFi Band',
		minBandwidth: 'Min bandwidth in kbps',
		maxBandwidth: 'Max bandwidth in kbps',
		addConfiguration: 'Add Configuration',
		bandwidth: 'Bandwidth',
		wifiFrequency: 'WiFi Frequency',
		minShouldBeLessThanMax: 'Minimum should be less than maximum.',
		minBandwidthRequired: 'Minimum bandwidth is required.',
		maxBandwidthRequired: 'Maximum bandwidth is required.',
		minBandwidthLessThan: `Minimum bandwidth can't be less than 500.`,
		maxBandwithMoreThan: `Maximum bandwidth can't be more than 5000.`,
		wiFiBandRequired: 'WiFi band is required.',
		chooseUnit: 'Choose unit',
		applyConfig: 'Apply configuration.',
		selectHospital: 'Select Hospital',
		selectHospitalApply: 'Select Hospital to apply active directory',
		selectFloor: 'Select Floor.',
		setMinBandwidth: 'Set minimum bandwidth',
		setMaxBandwidth: 'Set maximum bandwidth',
		chooseWiFiBand: 'Select WiFi Band',
		roleIsRequired: 'Role is required.',
		invalidIpAddress: 'Invalid IP Address or CIDR Range.',
		webSiteAccess: 'Website access',
		setIpAddress: 'Set IP Address',
		maximumIpAddresses: `Can't add more than {value} IP Addresses at once`,
		provideCIDRRange:
			'Provide an address range using CIDR notation (e.g 192.168.99.0/24),or an IP address (e.g. 192.168.99.0). You can also provide list of IP addresses or address ranges using enter.',
		setIpAddressOrRange: 'Set IP Addres or range',
		deleteNetworkConfigConfirmation: 'Are you sure you want to delete this network configuration?',
		deleteAdConfirmation: 'Are you sure you want to delete this active directory?',
		deleteGroupRoleConfirmation: 'Are you sure you want to delete this active directory group role? ',
		representsRangeOfIps: 'Represents range of IPs',
		historyForConfig: 'History for configuration',
		notAuthorizedFromThisIP: 'You are not authorized to access this page from the IP Address: ',
		notAuthorizedToAccessThisPage: 'You are not authorized to access this page.',
		ifYouThinkThereAreMistakes: 'If you think there has been a mistake, please contact your administrator.',
		activeDirectory: 'Active Directory',
		addAd: 'Add AD',
		addGroupRoles: 'Add Group Roles',
		groupRoles: 'Group Roles',
		groupRolesDescription: 'Active directory role group',
		groupRolesRequired: 'Group roles is required',
		azureAdUrl: 'Set Azure AD Url',
		setValidGroupName: 'Set valid group name',
		setValidGroupNameRequired: 'Valid Group name is required',
		setValidGroupNameDescription: 'Active directory valid group name',
		clientId: 'Client Id',
		clientIdRequired: 'Client Id is required',
		clientIdDescription: `Set {value} client id`,
		clientSecret: 'Client Secret',
		clientSecretRequired: 'Client Secret is required',
		clientSecretDescription: 'Set {value} client secret',
		radiology: 'Radiology',
		numberOfRepetitionForLeftNeck: 'Number of repetition for neck',
		inviteToLaboratory: 'Invite to the Laboratory',
		startTests: 'Start Tests',
		patientNoShowUp: "Patient didn't show up",
		markSamplesAsTaken: 'Mark samples as taken',
		markProcessAsCompleted: 'Mark process as completed',
		editPatient: 'Edit Patient',
		searchPatientNameId: 'You can start searching patients via their name or ID number',
		medicalForms: 'Medical Forms',
		formsDisplayedHere: 'The forms that you add will be displayed here.',
		totalNumberOfSessions: 'Total Number of Sessions',
		completedVisits: 'Completed Visits',
		sessionDuration: 'Session Duration',
		completedConferences: 'Completed Conferences',
		notShownUp: 'Not shown up',
		inProgress: 'In Progress',
		ongoingVisits: 'Ongoing Visits',
		registryNumber: 'Registry Number',
		visitStart: 'Start of the visit',
		visitEnd: 'End of the visit',
		inviteToRoom: 'Invite to the Room',
		currentVisit: 'Current Visit',
		consultationReport: 'Consultation Report',
		instructionsForInjection: 'Instructions for giving parenteral therapy (Injection)',
		instructionsForInfusion: 'Instructions for giving parenteral therapy (Infusion)',
		laboratory: 'Laboratory',
		searchToAdd: 'Search to add {value} for {value2}',
		tests: 'tests',
		xRayScans: 'x-ray scans',
		medicationNameHere: 'Medication name here',
		medicationType: 'Medication Type',
		addAnotherMedication: 'Add another medication',
		selectData: 'Select Data',
		addOtherData: 'Add other data',
		returnReportFromLab: 'Return report from the laboratory',
		returnReportFromRadiology: 'Return report from the radiology',
		addOtherNote: 'Add other note',
		addMedicalForms: 'Add Medical Forms',
		searchPatientNameIdVisitNumber: "Search by patient's name, ID or visit's registry number",
		nextDay: 'day',
		frontDesk: 'Front Desk',
		injectionRoom: 'Injection Room',
		infusionRoom: 'Infusion Room',
		capacity: 'Capacity',
		writeCapacity: 'Write Capacity',
		writePriority: 'Write Priority',
		startBreak: 'Start Break',
		endBreak: 'End Break',
		startSchedule: 'Start Schedule',
		endSchedule: 'End Schedule',
		personalInformationTitle: 'Personal Information',
		locationAndAddressTitle: 'Location & Address',
		licensesTitle: 'Licenses',
		signatureAndStampTitle: 'Signature & Stamp',
		employmentTitle: 'Employment',
		educationTitle: 'Education',
		visitHistoryFor: 'Visit History for',
		patientNoVisits: "The patient doesn't have any visits.",
		openCases: 'Open Cases',
		closedCases: 'Closed Cases',
		instruction: 'Instruction',
		therapyDescription: 'Therapy description',
		checkUp: 'Check-up',
		reCheckUp: 'Re Check-up',
		referral: 'Referral',
		referToOneOfTheDoctors: 'Refer the patient to one the doctors',
		patientQueue: 'Patient Queue',
		referToThisRoom: 'Refer patient to this room',
		waitingTime: 'Waiting time',
		referralDetails: 'Referral details',
		queueNumber: 'Queue number',
		patientAddedDoctorWaitingList: 'Patient has been added to the Doctor’s Waiting List',
		itinerary: 'Itinerary',
		additionalNote: 'Additional Note',
		visitIn: 'Visit in',
		doctorCheckUp: 'Doctor Checkup',
		laboratoryTesting: 'Laboratory Testing',
		invited: 'Invited',
		notShowedUp: 'Not showed up',
		cancelled: 'Cancelled',
		frontDeskPatientCheckinPending: 'Front Desk - Patient check in - Pending',
		frontDeskPatientCheckinInvited: 'Front Desk - Patient check in - Invited',
		frontDeskPatientCheckinInprogress: 'Front Desk - Patient check in - In Progress',
		frontDeskPatientNotShowedUp: 'Front Desk - Patient not shown up',
		frontDeskPatientCheckinCompleted: 'Front Desk - Patient check in completed',
		frontDeskPatientCheckinCancelled: 'Front Desk - Patient check in canceled',
		waitingForDoctor: 'Waiting for doctor',
		doctorInvitedPatient: 'Patient invited by Doctor',
		doctorInvitedPatientNotShowedUp: 'Patient invited by Doctor - Not shown up',
		inDoctorVisit: 'In visit with doctor',
		doctorVisitCompleted: 'Doctor visit - Completed',
		doctorVisitCancelled: 'Doctor visit - Cancelled',
		patientWaitingForLaboratoryTest: 'Lab test - Patient Waiting',
		laborantInvitedPatient: 'Patient invited to Lab',
		laborantInvitedPatientNotShowedUp: 'Patient invited to Lab - Not shown up',
		laboratoryTestInProgress: 'Laboratory test - In Progress',
		laboratoryVisitCompleted: 'Laboratory visit - Completed',
		laboratoryVisitCancelled: 'Laboratory visit - Cancelled',
		otherInformation: 'Other Information',
		preExistingDescription: 'Pre-existing conditions',
		typeOfAllergies: 'What type of allergies do you have have?',
		searchOrAddAllergies: 'Search or add new allergies',
		typeAllergyAndPressEnter: 'Type your allergy and press enter',
		pleaseSelectOneOfTheOptionsAbove: 'Please select one of the options above',
		environmentalAllergies: 'Environmental Allergies',
		biologicalAllergies: 'Biological Allergies',
		writePreExistingCondition: 'Write pre-existing conditions',
		pleaseWritePreExistingCondition: 'Please write pre-existing conditions',
		patientToBeTestedFor: 'Patient to be tested for',
		creatinine: 'Creatinine',
		lucose: 'Lucose',
		hemogram: 'Hemogram',
		sedimentation: 'Sedimentation',
		triglycerides: 'Triglycerides',
		urea: 'Urea',
		urine: 'Urine',
		setToPending: 'Set To Pending',
		numberOfAnalysis: 'Number of Analysis',
		glucose: 'Glucose',
		assignNewDoctor: 'Assign new doctor',
		reassignToQueue: 'Reassign to {value} queue',
		pleaseWriteCorrectValues: 'Please write correct values',
		caseId: 'Case ID',
		checkInNumber: 'Check-in number',
		addNecessaryVitalSigns: 'Add any necessary vital signs by clicking "Add other data" button.',
		sediment: 'Sediment',
		erythrocyteSedimentationRate: 'Erythrocyte sedimentation rate',
		erythrocyteCount: 'Erythrocyte count',
		hemoglobin: 'Hemoglobin',
		femtolitre: 'Femtolitre',
		picograms: 'Picograms',
		hematocrit: 'Hematocrit',
		ldlCholesterol: 'LDL Cholesterol',
		vldCholesterol: 'VLD Cholesterol',
		view: 'View',
		color: 'Color',
		reaction: 'Reaction',
		specificWeight: 'Specific weight',
		proteins: 'Proteins',
		acetone: 'Acetone',
		bilirubin: 'Bilirubin',
		urobilinogen: 'Urobilinogen',
		nitrites: 'Nitrites',
		babyCrying: 'Baby crying',
		babyCryingDetected: 'Baby crying detected',
		availableLaboratorians: 'Available laboratorians',
		referToOneOfTheLaboratorians: 'Refer to one of the laboratorians',
		referToThisLaboratorian: 'Refer to this laboratorian',
		leukocyteCount: 'Leukocyte Count',
		patientAddedNurseWaitingList: 'Patient added to waiting list of {value}',
		auditLogs: 'Audit Logs',
		client: 'Client',
		logType: 'Log Type',
		inputter: 'Inputter',
		inputterId: 'Inputter Id',
		inputterType: 'Inputter type',
		subjectType: 'Subject Type',
		subjectName: 'Subject name',
		subjectId: 'Subject Id',
		tenantId: 'Tenant Id',
		objectType: 'Object Type',
		objectId: 'Object Id',
		sourceOfInput: 'Source of Input',
		actioneeName: 'Actionee Name',
		actioneeObjectId: 'Actionee Object Id',
		actioneeObjectType: 'Actionee Object Type',
		noAuditLogs: 'No audit logs',
		filterLogs: 'Filter Logs',
		user: 'User',
		resetFilter: 'Reset Filter',
		waistUnit: 'Waist unit',
		totalCholesterolUnit: 'Total cholesterol unit',
		hdlCholesterolUnit: 'HDL cholesterol unit',
		contactInformation: 'Contact Information',
		updateYour: 'Update your',
		update: 'Update',
		profilePictureTitle: 'Profile Picture',
		addressTitle: 'Address',
		contactInformationTitle: 'Contact Information',
		healthInformationTitle: 'Health Information',
		manageProfileInformation: 'Manage your profile information',
		keepAccountSecure: 'Keep your account secure',
		changePharmacyLocation: `Change your pharmacy's location`,
		manageApps: 'Manage your apps',
		changeAppLanguages: `Change app's language`,
		changeMeasurementUnits: 'Change measurement units',
		helpDescription: 'About, Live Support Chat, Invite People',
		appLanguage: 'App Language',
		help: 'Help',
		uploadNewPicture: 'Upload new picture',
		postalCode: 'Postal Code',
		otherData: 'Other Data',
		selectOption: 'Select Option',
		selectRails: 'Select Rails to send alerts',
		showFullProfile: 'Show full profile',
		fillMandatoryFields: 'Changes can not be saved unless you fill the mandatory fields',
		endDateLaterThanStartDate: 'End date must be later than start date',
		endDateLaterThanToday: 'End date must not be later than today date',
		rtgPulmonary: 'Pulmonary scan',
		rtgSkeleton: 'Skeleton scan',
		rtgChest: 'Chest scan',
		rtgUpperExtremities: 'Upper extremities scan',
		rtgLowerExtremities: 'Lower extremities scan',
		rtgSpine: 'Spine scan',
		ultrasonography: 'Echo - Ultrasonography',
		injection: 'Injection',
		infusion: 'Infusion',
		recording: 'Recording',
		recordTheSession: 'Record The Session',
		recordingDescription: `When this is enabled, users will be able to record the monitoring session which will be saved in the patient's profile.`,
		pleaseChooseAnotherDate: 'Please choose another date.',
		availableNursesIn: 'Available nurses in {value}',
		referToOneOfNurses: 'Refer to one of the nurses',
		infusionTherapy: 'Infusion Therapy',
		injectionTherapy: 'Injection Therapy',
		deleteRegion: 'Delete Region?',
		atLeastOneRegion: 'Health systems need to have at least one region!',
		deleteHS: 'Delete Health System?',
		regionCannotBeEmpty: 'Region cannot be empty',
		regionExists: 'Region with this name already exists',
		healthSystemExists: 'Health system with this name already exists',
		positive: 'Positive',
		negative: 'Negative',
		inTraces: 'In traces',
		clearUrine: 'Clear',
		turbid: 'Turbid',
		reddish: 'Reddish',
		yellow: 'Yellow',
		acidic: 'Acidic',
		noOngoingVisit: "Currently there isn't any ongoing visit.",
		noShowUpVisit: "Currently there isn't any case where patients didn't show up.",
		noCompletedVisits: "Currently there isn't any completed visit.",
		radiologyScans: 'Radiology scans',
		comment: 'Comment',
		enterComment: 'Enter comment',
		radiologyRoom: 'Radiology Room',
		newPassword: 'New password',
		readTermsAndConditions: 'Read our terms and conditions',
		readPrivacyPolicy: 'Read our privacy policy',
		chemicals: 'Chemicals',
		distance: 'Distance',
		weightAndLeanBodyMass: 'Related to Weight and Lean Body Mass',
		relatedBodyTemperature: 'Related to Body Temperature',
		heightAndWaistCircumference: 'Related to Height and Waist Circumference',
		walkingAndRunning: 'Related to Walking + Running Distance',
		bloodGlucoseAndCholesterol: 'Related to Blood Glucose, Total Cholesterol and HDL Cholesterol',
		selectUnit: 'Select unit',
		poolingFlow: 'Pooling Flow',
		editGeneralSettings: 'Edit General Settings',
		durationDays: 'days',
		timesADay: 'times a day',
		parenteralTherapyReport: 'Parenteral therapy report',
		returnReportFromDiagnostics: 'Return report from diagnostics',
		searchToAddDiagnosis: 'Search to add Diagnosis',
		addDiagnoses: 'Add Diagnoses',
		previousDiagnoses: 'Previous Diagnoses',
		presets: 'Presets',
		createPreset: 'Create Preset',
		createNewPreset: 'Create new Preset',
		typePresetDescription: 'Type preset name',
		addNewPreset: 'Add new preset',
		deletePreset: 'Delete this preset',
		areYouSureToDeletePreset: 'Are you sure you want to delete {value} preset?',
		goToPreset: 'Go to this preset',
		noPresets: 'No presets available, try adding one.',
		presetChangeDescription: 'You can change it anytime',
		presetName: 'Preset name',
		activePreset: 'Active preset:',
		noActivePreset: 'No active preset',
		resetCamera: 'Reset camera',
		updateToCurrentPosition: 'Update to current position',
		pleaseWriteValidDate: `Please add more recent experiences. Unfortunately, we currently don't support experiences that have passed 60 years.`,
		returnReportFromParentalTherapy: 'Return report from parental therapy',
		number: 'Number',
		refer: 'Refer',
		sectorExistsMessage: '{value} with this name already exists',
		youAreAlreadyOnCall: 'You are already on a call.',
		deviceNotAvailableForCall: 'Device is not available for call now.',
		couldNotGetDeviceState: 'Could not get device call state.',
		visitingHours: 'Visiting Hours',
		addVisitingHours: 'Add visiting hours',
		selectAll: 'Select all',
		deSelectAll: 'Deselect all',
		conferenceId: 'Conference ID',
		initiator: 'Initiator',
		roomCount: 'Room Count',
		origin: 'Origin',
		usedAudio: 'Used Audio',
		usedVideo: 'Used Video',
		usedScreen: 'Used Screen',
		selectContentToDownload: 'Select the content that you want to download.',
		applyGlobalSettings: 'Apply Global Settings',
		areYouSureConfigurations: 'Are you sure you want to save the new configurations?',
		enforceConfiguration: 'Enforce configuration',
		overrideLocalConfiguration: 'By enabling this option, you override the local configuration set on the hierarchy below.',
		confirmationModal: 'Confirmation Modal',
		activeQueueError: 'You have patients in Your waiting queue. Please complete the queue.',
		chooseRole: 'Choose a role',
		selectRoleOrAll: 'Select a role or choose All to apply period of inactivity',
		addToQueue: 'Add to queue at {value}',
		rejectSession: 'Reject session',
		confirmRejectSession: 'Are you sure you want to reject this session?',
		reject: 'Reject',
		rejectSessionReason: 'Reason for rejection (Optional)',
		patientsInQueue: '{value} patients in queue',
		availableNurses: 'Available nurses',
		virtualSitterOnList: 'Virtual Sitter',
		visitor: 'Visitor',
		addVirtualSitter: 'Add Virtual Sitter',
		virtualsitter: 'Virtual Sitter',
		nextVisitIn: 'Next visit is in {value}',
		pleaseGoToFrontDesk: 'Please visit the front desk to be assigned to a queue',
		hdmiPort: 'HDMI Port',
		defaultSource: 'Default Source',
		ringtoneVolume: 'Ringtone Volume',
		'port/siteId': 'Port / Site ID',
		port: 'Port',
		siteId: 'Site ID',
		apiKey: 'API Key',
		pleaseSelectHealthSystem: 'Please select a health system.',
		cannotSelectNrMoreThanValue: 'Cannot select more than {value} {item}',
		pleaseSelectHospital: 'Please select a hospital.',
		pleaseSelectDepartment: 'Please select a department.',
		pleaseSelectSector: 'Please select a {value}.',
		pleaseSelectHDMIPort: 'Please select a HDMI port.',
		pleaseSelectTV: 'Please select a TV.',
		ringtoneVolumeValidation: 'Ringtone volume must be in between 0 and 100.',
		pleaseSelectEndCallSource: 'Please select a TV End Call Source.',
		pleaseSelectProfileName: 'Please set a profile name.',
		pleaseSelectCompanionDevice: 'Please select a companion device if available',
		pleaseSetURL: 'Please set a URL.',
		pleaseSetSecret: 'Please set a secret.',
		pleaseSetApiKey: 'Please set an API key.',
		pleaseSetSiteId: 'Please set a site ID',
		pleaseSetClientId: 'Please set a client ID',
		pleaseSetUserName: 'Please set a username',
		integrationType: 'Please choose an integration type.',
		portValidation: 'Port must be a number greater than 0',
		addHSConfiguration: 'Add Health System Configuration',
		addTVConfiguration: 'Add TV Configuration',
		selectHSForConfiguration: 'Select Health System so you can choose the hospital to apply the configurations.',
		chooseHospitalForChanges: 'Choose the hospital you want to apply changes to',
		chooseSectorForChanges: 'Choose the {value} you want to apply changes to',
		selectDepartment: 'Select Department',
		chooseTVSetup: 'Choose TV Setup',
		chooseTVSetupType: 'Choose the type of TV setup.',
		selectHelloCareHDMI: 'Select hellocare HDMI port',
		selectHDMIPort: 'Select the HDMI port where hellocare will be plugged into.',
		tvManagement: 'TV Management',
		selectTVOption: 'Select the option where TV will go back after call ends.',
		selectTeleHealthProfile: 'Select Telehealth Profile',
		selectPreConfiguredProfile: 'Select the pre-configured telehealth profile. Leave empty if none.',
		helloCareRingtone: 'hellocare ringtone',
		applyToAllDevices: 'This setting will be applied to all the devices under this hospital.',
		setProfileName: 'Set profile name',
		setURL: 'Set URL',
		setPort: 'Set Port',
		setSiteId: 'Set Site ID',
		setAPIKey: 'Set API Key',
		secret: 'Secret',
		setSecret: 'Set Secret',
		setScope: 'Set Scope',
		pleaseSetScope: 'Please set scope.',
		pleaseSetTenantId: 'Please set Tenant ID.',
		facilityAccessKey: 'Facility Access Key',
		pleaseSetFacilityAccessKey: 'Please set Facility Access Key.',
		setTheme: 'Set Theme',
		pleaseSetTheme: 'Please set a theme.',
		tokenUrl: 'Token URL',
		pleaseSetTokenUrl: 'Please set Token URL.',
		deleteConfiguration: 'Delete Configuration?',
		areYouSureToDeleteConfig: 'Are you sure you want to delete this configuration?',
		liveExaminations: 'Live Examinations',
		selectOneOfExaminations: 'Please select one of the examinations to start measuring',
		ear: 'Ear',
		lungs: 'Lungs',
		throat: 'Throat',
		skin: 'Skin',
		ecg: 'ECG',
		eCG: 'ECG',
		spirometer: 'Spirometer',
		abdomen: 'Abdomen',
		lungsAir: 'Spirometer',
		connectDevice: '{value} {value1}',
		confirmIotProperlyPlaced: 'Please confirm with the patient that the medical device is properly placed.',
		toCheckConnectToDevice: 'To {value} {value2}, the patient needs to connect {value3}.',
		toStartMeasureTemperature: 'To start measuring temperature, the patient should press the measure button on device.',
		check: 'check',
		toMeasure: 'measure',
		otoscope: 'Otoscope',
		dermatoscope: 'Dermatoscope',
		ultrasound: 'Ultrasound',
		deviceIsConnectedStartMeasurement: '{value} is connected, you can start the measurement!',
		startMeasuring: 'Start Measurement',
		previousMeasurements: 'Previous Measurements',
		showMore: 'Show more',
		checkMePro: 'Checkme Pro',
		initiateDevice: 'Initiate {value}',
		assignVisitor: 'Assign Visitor',
		visitors: 'Visitors',
		disableVisits: 'Disable Visits',
		disableVisitsDescription:
			'When this is enabled, users will set the maximum time that the calls to the patient will be disabled for family and friends.',
		enableVisits: 'Enable Visits',
		selectTime: 'Select Time',
		areYouSureDisableVisits:
			'By enabling this option, you set the maximum time that the calls to the patient will be disabled for Family and Friends. After the selected time passed, the calls will be allowed based on the user role and hospital visits schedule.',
		idNumberExists: 'This ID number already exists. Please try again!',
		infectiousDisease: 'Infectious Disease',
		cardiologist: 'Cardiologist',
		pulmonologist: 'Pulmonologist',
		nephrologist: 'Nephrologist',
		callDuringVisitingHours: 'You can call the patient only during the visiting hours.',
		retakeMeasurement: 'Retake measurement',
		deviceIsMeasuring: '{value} is measuring',
		waitForCompletionToEnsure: 'Wait for completion to ensure accurate results!',
		removeDeviceToCompleteMeasure: 'Remove the device from the finger to complete the measurement.',
		completeProfileTerms: 'Check here to indicate that you have read and agree to the ',
		terms: 'terms',
		disabledFor: 'Disabled for',
		familyFriends: 'Family & Friends',
		otherPeople: 'Other people',
		hierarchy: 'Hierarchy',
		roomOnList: 'Room',
		selectHSHierarchy: 'Select Health System Hierarchy',
		applyVisitingHoursAllDays: 'Apply visiting hours to all days of the week',
		addedConfigsRecurring: 'Added configurations are recurring based on a specific day of the week.',
		deleteMember: 'Delete member?',
		stopMeasuring: 'Stop measuring',
		showHistory: 'Show History',
		error: 'Error',
		takingCareOfbaby: 'Taking care of this baby',
		takingCareOfbabyConfirm:
			'By enabling this option, you will be assigned to the chat group with family members of this baby. Confirm?',
		takingCareOfbabyConfirmUnAssigned:
			'By enabling this option, you will be unassigned to the chat group with family members of this baby. Confirm?',
		nicuChat: 'NICU Chat',
		nicuCheckIn: 'NICU Check-in',
		nicuCheckOut: 'NICU Check-out',
		kiosk: 'Kiosk',
		triage: 'Triage',
		includeRoles: 'Include Roles',
		isSingleSignOutEnabled: 'Enable Single Sign Out',
		deviceIsBusy: 'Device is busy',
		ntpConfigurations: 'NTP Configurations',
		ntpUrl: 'NTP Endpoint',
		ntpPort: 'NTP Port',
		setNtpUrl: 'Set NTP Endpoint',
		setNtpPort: 'Set NTP Port',
		setNtpUrlPort: 'Set NTP Endpoint & Port',
		pleaseEnterNtpUrl: 'Please enter NTP Endpoint',
		pleaseEnterNtpPort: 'Please enter NTP Port',
		atLeastOneNtpUrlIsRequired: 'At least 1 NTP Enpoint is required',
		enterValidUrl: 'Enter a valid url',
		portIsRequired: 'Port is required',
		urlPortCannotBeSame: 'Endpoint + port fields should be unique',
		turnOnGettingOutOfBed: 'Turn on getting out of bed',
		turnOffGettingOutOfBed: 'Turn off getting out of bed',
		turnOnInactiveTime: `Turn on patient's inactive time`,
		turnOffInactiveTime: `Turn off patient's inactive time`,
		patientInQueue: 'You have patients in your waiting queue. Please complete the queue!',
		medicalVisitInitiated: 'Already initiated Medical Visit. Please complete the visit!',
		disableAi: 'Disable AI',
		disableAiTemporarily: 'Disable AI temporarily',
		disableAiDescription:
			'When this is enabled, users will be able to disable an AI use case that is turned on for a desired amount of time and after that time is over the AI will continue its activity.',
		enableAi: 'Enable AI',
		sendRailsAlert: 'For which rails to trigger an alert',
		areYouSureDisableAi:
			'By enabling this option, you set the time that the AI alerts will be disabled for you and the patient. After the selected time has passed, the AI function will be turned on and you will receive alerts.',
		defaultCamera: 'Default Camera',
		selectDefaultCamera: 'Select default camera',
		selectedDefaultCameraDescription: 'Selected camera will be shown as default in each type of call.',
		defaultCameraDescription: ` Prior to selecting the {value} Camera as a default camera, please make sure that it is connected to the device.`,
		scheduleDataAcquisition: 'Schedule Data Acquisition',
		scheduleTimeForDataAcquisition: 'Schedule time for data acquisition',
		dataAcquisitionSchedule: 'Data Acquisition Schedule',
		snoozeFalseAlert: 'This alert will be snoozed for 5 minutes.',
		snoozeAcknowledgeAlert: 'This alert will be turned off for 15 minutes while the patient gets help.',
		configureFeatureFlags: 'Configure Feature Flags',
		generalAndMenuSettings: 'General and Menu Settings',
		featureFlags: 'Feature Flags',
		featureFlagsDescription: `Feature Flags provide a centralized control setup for enabling or disabling specific features across an entire company's levels.`,
		selectSector: 'Select {value}',
		sector: 'Sector',
		pleaseSelectFloor: 'Please select a floor.',
		adminPreviousConfigs: 'By selecting this option you override the previous configurations of the sectors below.',
		maxPortNumber: 'Maximum port number is',
		portCannotBeNegative: 'Port cannot be a negative number',
		wearablesDescription: 'When this is enabled, wearables will be available to use during live examination',
		bodyComposition: 'Body Composition',
		snoreDetection: 'Snore Detection',
		caloriesBurned: 'Calories Burned',
		makeSureDeviceIsOn: `Make sure the patient's device is ON.`,
		samsungWatch: 'Samsung Watch',
		vitalKit: 'Vital Kit',
		showPreviousMeasurements: 'Show previous measurements',
		patientHasMeasured: '{value1} has {value2}',
		ecgDoesNotShowSigns: 'This ECG does not show signs of atrial fibrillation',
		cannotCheckForSignsOfHeartAttack:
			'We cannot check for signs of a heart attack. If you believe you are having a medical emergency,',
		callEmergencyServices: 'call emergency services',
		theear: 'Ear',
		thelungs: 'Lungs',
		theheart: 'Heart',
		thethroat: 'Throat',
		theheartRate: 'Heart Rate',
		theoxygenSaturation: 'Oxygen Saturation',
		thetemperature: 'Temperature',
		theskin: 'Skin',
		thebloodPressure: 'Blood Pressure',
		thebloodGlucose: 'Blood Glucose',
		theweight: 'Weight',
		theabdomen: 'Abdomen',
		theotoscope: 'Otoscope',
		thestethoscope: 'Stethoscope',
		thepulseOximeter: 'Pulse Oximeter',
		thethermometer: 'Thermometer',
		thedermatoscope: 'Dermatoscope',
		theglucometer: 'Glucometer',
		thescale: 'Scale',
		thespirometer: 'Spirometer',
		theultrasound: 'Ultrasound',
		selectAvailableDevices: 'Select one of the available devices to start the examination.',
		medicalDevices: 'Medical Devices',
		medicalDevicesDescription: 'When this is enabled, all IoT devices will be available for user to use during live examination ',
		aiUsingCamera: 'AI using Camera',
		guidePatientOtoscope:
			'Guide the patient to insert the Oval Ear Specula into the cylindrical camera on the device and place it properly in the Ear.',
		guidePatientHeartLungs:
			'Guide the patient to place the device properly on the body by using the guided illustration on the screen.',
		guidePatientThroat: 'Guide the patient to insert the tongue depressor on the device and place it properly on the mouth.',
		guidePatientHeartRate: 'Guide the patient to place the right thumb on the down right electrode.',
		guidePatientForMeasurement: 'Guide the patient on how to make the measurement.',
		guidePatientDiabetes: `Make sure the patient's devices are ON and connected via bluetooth. 
		Guide the patient on how to make the measurement.`,
		guidePatientECG:
			'Guide the patient turn the device horizontally on the right. Press the right thumb on the down right electrode. Press the left thumb on the down left electrode. Press the left  index finger on the top left electrode.',
		startDateLaterThanToday: 'Start date must not be later than today date',
		pleaseWriteAllergy: 'Please write at least an allergy and click Enter!',
		calls: 'Calls',
		tv: 'TV',
		web: 'Web',
		callCareTeam: 'Call Care Team',
		examKit: 'All-in-one Exam Kit',
		examKitDescription: 'When this is enabled, All-in-one Exam Kit device will be available to use during live examination',
		aiMeasurements: 'AI Measurements',
		AIMeasurementsDescription:
			'When this is enabled, Vital Signs measurement will be available through AI during Live Examination ',
		biobeatPatch: 'Biobeat Patch',
		biobeatPatchDescription: 'When this is enabled, Biobeat Patch device will be available to use during live examination',
		lifesignalsPatch: 'Lifesignals Patch',
		lifeSignalsPatchDescription: 'When this is enabled, Lifesignal Patch device will be available to use during live examination',
		healthDataDescription: 'When this is enabled, all health data about the patient on the call will be displayed.',
		summaryDescription: 'When this is enabled, summary of the medical info will be displayed in one place',
		visitsDescription: 'When this is enabled, all visit history for the patient on the call will be displayed.',
		alertHistoryDescription:
			'When this is enabled, the option to update and view the history of the risk for vital signs will be displayed.',
		medicalInfoPatientDiagnosis: 'Patient Diagnosis',
		patientDiagnosesDescription:
			'When this is enabled, doctors will have the option to add diagnostic for the patient during the call.',
		proceduresDescription: 'When this is enabled, doctors will have the option to add procedure for the patient during the call.',
		roundingNotesDescription: 'When this is enabled, doctors will have the option to add notes for the patient during the call.',
		prescriptionsDescription:
			'When this is enabled, doctors will have the option to add a prescription for the patient during the call.',
		physicalTherapyDescription:
			'When this is enabled, doctors will have the option to add new exercises for the patient during the call.',
		wrapUpPage: 'Session Wrap-Up',
		sessionWrapUpDescription:
			'When this is enabled, doctors will be presented with a page where they can review and input medical information.',
		previous: 'Previous',
		ehr: 'EHR',
		eHR: 'EHR',
		liveExamination: 'Live Examination',
		prescriptionAlerts: 'Prescription Alerts',
		weather: 'Weather',
		familyCalling: 'Family Calling',
		moods: 'Moods',
		educationalVideos: 'Educational Videos',
		cannotSwitchExaminationTab: 'You cannot switch to another examination while the device is in a connecting mode.',
		viewPatient: 'View Patient',
		callSettings: 'Call Settings',
		roomSignCallSettings: 'Room Sign Call Settings',
		providerAndPatientCallSettings: 'Provider and Patient Call Settings',
		wallpaper: 'Wallpaper & Background',
		audioOnly: 'Start with audio only',
		audioVideo: 'Start with audio & video',
		showMonitoring: 'Show monitoring page',
		hideMonitoring: 'Hide monitoring page',
		allowViewPatient: 'Show "View Patient" option',
		hideViewPatient: 'Hide "View Patient" option',
		talkToPatientRequired: 'You must select 1 talk to patient call type',
		monitoringRequired: 'You must select whether Monitoring should be shown or not',
		viewPatientRequired: 'You must select whether View Patient should be shown or not',
		selectCallOptions: 'Select between given options for every type of call',
		others: 'Others',
		callTypes: 'Call Types',
		callStartAudioOnly: 'Call will start with audio only',
		callStartAudioVideo: 'Call will start with audio & video',
		digitalclinician: 'Digital Clinician',
		theecg: 'ECG',
		thelungsAir: 'FEV1/PEF',
		thebloodPressureMonitor: 'Blood Pressure',
		arabic: 'Arabic',
		highHeartRate: 'High Heart Rate',
		lowHeartRate: 'Low Heart Rate',
		lowBodyTemperature: 'Low Body Temperature',
		highBodyTemperature: 'High Body Temperature',
		highBloodGlucose: 'High Blood Glucose',
		criticalHighBodyTemperature: 'Critical High Body Temperature',
		lowBMIUnderweight: 'Low BMI Underweight',
		highBMI: 'High BMI',
		criticalHighBMI: 'Critical High BMI',
		systolicLowBloodPressure: 'Systolic Low Blood Pressure',
		diastolicLowBloodPressure: 'Diastolic Low Blood Pressure',
		systolicCriticalLowBloodPressure: 'Systolic Critical Low Blood Pressure',
		diastolicCriticalLowBloodPressure: 'Diastolic Critical Low Blood Pressure',
		systolicHighBloodPressure: 'Systolic High Blood Pressure',
		diastolicHighBloodPressure: ' Diastolic High Blood Pressure',
		systolicCriticalHighBloodPressure: 'Systolic Critical High Blood Pressure',
		diastolicCriticalHighBloodPressure: 'Diastolic Critical High Blood Pressure',
		lowOxygenSaturation: 'Low Oxygen Saturation',
		criticalLowOxygenSaturation: 'Critical Low Oxygen Saturation',
		thehighHeartRate: 'High Heart Rate',
		thelowHeartRate: 'Low Heart Rate',
		thelowBodyTemperature: 'Low Body Temperature',
		thehighBodyTemperature: 'High Body Temperature',
		thecriticalHighBodyTemperature: 'Critical High Body Temperature',
		thelowBMIUnderweight: 'Low BMI Underweight',
		thehighBMI: 'High BMI',
		thecriticalHighBMI: 'Critical High BMI',
		thesystolicLowBloodPressure: 'Systolic Low Blood Pressure',
		thediastolicLowBloodPressure: 'Diastolic Low Blood Pressure',
		thesystolicCriticalLowBloodPressure: 'Systolic Critical Low Blood Pressure',
		thediastolicCriticalLowBloodPressure: 'Diastolic Critical Low Blood Pressure',
		thesystolicHighBloodPressure: 'Systolic High Blood Pressure',
		thediastolicHighBloodPressure: ' Diastolic High Blood Pressure',
		thesystolicCriticalHighBloodPressure: 'Systolic Critical High Blood Pressure',
		thediastolicCriticalHighBloodPressure: 'Diastolic Critical High Blood Pressure',
		thelowOxygenSaturation: 'Low Oxygen Saturation',
		thecriticalLowOxygenSaturation: 'Critical Low Oxygen Saturation',
		thehighBloodGlucose: 'High Blood Glucose',
		medicalItemExists: `This {value} already exists!`,
		pleaseSelectValidImageType: 'Please select any of these valid image type: png, jpeg, tiff, gif, bmp',
		bloodPressureMonitor: 'Blood Pressure',
		companyImage: 'Company Image',
		visitingHoursEnd: 'Visiting hours will end in ',
		locked: 'Locked',
		unlocked: 'Unlocked',
		callSettingStatus: '{value} {value2} allowed',
		is: 'is',
		isNot: 'is not',
		audioRoutingOptions: 'Audio Routing Options',
		selectAudioRoutingOptions: 'Select the option you would like to route to for each type of call',
		customConfigurations: 'Custom Configurations',
		basedOnDeviceType: 'Based on device type',
		selectOneOfRoutingOptions: 'Select one of the routing options',
		audioRoutingDeviceType: 'Select specific configurations based on device type',
		notAuthorizedHealthSystem: 'You are not authorized to use this Health System.',
		switchHsVirtualSitter: 'Please switch the Health System to use features of the Virtual Sitter.',
		bedDefinition: 'EHR Bed Definition',
		bed: 'Bed',
		switchHsFeature: 'Please switch the Health System to use this feature.',
		tablet: 'Tablet',
		alwaysFromHello: 'Always from HELLO',
		routesToTvPatientNurse: 'Routes to TV when patient sees the nurse',
		routesToTvSourceAvailable: 'Routes to TV when source/state available',
		alwaysPillowSpeaker: 'Always from pillow speaker',
		alwaysFromPhone: 'Always from Phone',
		alwaysFromTablet: 'Always from Tablet',
		routeExternalDetected: 'Route to external speaker when detected',
		notAuthorizedMonitoring: 'You are not authorized to use Monitoring',
		isCompanyLevelConfiguration: 'Company Level Configuration',
		autoSynchronizeLogin: 'Auto synchronize on log in',
		youAlreadyHaveAddedThisDomain: 'You already have added this Domain',
		theProvidedDomainIsInvalid: 'The provided Domain is invalid (e.g. hellocare.ai)',
		formShouldContainOneDomainEmail: 'At least one Domain is required, please write and press Enter to add a Email Domain.',
		maximumEmailDomainsAllowedAre5: 'Maximum Email Domains allowed are 5',
		accountCreationFailed: 'Account creation failed. If the problem persists, contact our support team for assistance.',
		accountSynchronizationFailed:
			'Account Synchronization failed. If the problem persists, contact our support team for assistance.',
		accountBeingSynchronizedMayTakeMinutes: 'Your account is being Synchronized. This may take a few minutes!',
		autoAdd: 'Auto Add',
		autoUpdate: 'Auto Update',
		autoDelete: 'Auto Delete',
		areYouSureToRemoveMember: 'Are you sure you want to remove this member from the room?',
		returnReport: 'Return Report',
		medicalAssessment: 'Medical Assessment',
		therapy: 'Therapy',
		diagnosticResearch: 'Diagnostic Research',
		menuOptions: 'Menu Options',
		mainConcerns: 'Main Concerns',
		anamnesis: 'Anamnesis',
		clickToSave: 'Click to save',
		addMoreAllergies: 'Add More Allergies',
		patientAcknowledgedAllergies: 'The patient acknowledged having the following allergies',
		doesHaveAllergies: 'Does this patient have Allergies?',
		writeAllergyAndEnter: 'Write allergy and press enter to add',
		noAddedAllergies: 'No added allergies',
		searchForDiagnoses: 'Search for the code or name and press enter to add diagnose',
		addedDiagnoses: 'Added Diagnoses',
		recommendations: 'Recommendations',
		prescribeDrugs: 'Prescribe Drugs',
		useToPrescribe: 'Use to perscribe {value}',
		drugs: 'Drugs',
		prescribeInfusions: 'Prescribe Infusions',
		prescribeInjections: 'Prescribe Injections',
		addedTests: 'Added Tests',
		useToReferPatientTo: 'Use to refer patient to the {value}',
		searchforTheCodeAndEnter: 'Search for the code and press enter to add procedure',
		commentForTechnicians: 'Comments for the technicians',
		visitSummaries: 'Visit Summaries',
		adUserDoesNotExist:
			'This user already exists or User account does not exist in Identity External Providers list of users and cannot access the application. Please check if the account is correct.',
		additionalDataMissing:
			'Additional scale data is not obtainable due to the absence of height, weight, and gender information in the patient profile',
		missingWeight: ' Additional scale data is not attainable due to the absence of weight',
		missingHeight: 'Additional scale data is not attainable due to the absence of height',
		missingGender: 'Additional scale data is not attainable due to the absence of gender',
		unrealisticWeight: `Weight is not corresponding with the patient's height`,
		shoesOn: `The scale can't detect the body impedance. Make sure your bare feet are correctly placed on the 4 electrodes`,
		emailNotValid: 'Email is not valid',
		emailDuplicated: 'Email duplicated',
		taskCompleted: 'Task completed',
		noEmailsExelFile: 'There are no emails in your exel file!',
		errorType: 'Error type',
		babyRoomDeviceBusy: 'You cannot see the baby because {value} is already in call',
		bulkUpload: 'Bulk upload',
		emailList: 'Email list',
		medium: 'Medium',
		sensitivity: 'Sensitivity',
		highSensitivity: 'This mode is designed to trigger an alert at even the slightest movements of a person away from their bed.',
		mediumSensitivity: 'This mode is designed to trigger an alert when a person has moved moderately away from their bed.',
		lowSensitivity: 'This mode is designed to trigger an alert only when a person has completely left their bed.',
		standOnScale: 'Stand on scale',
		deviceDisconnect: 'Disconnected',
		stethoscopeMeasurements: 'Stethoscope {value} Measurements',
		heartStetho: 'Heart',
		lungsStetho: 'Lungs',
		clickAnyPoint: 'Click on any of the recordings at the bottom to start playing the file and view the measurement graph',
		availableRecordings: 'Available Recordings',
		measurement: 'Measurement',
		pause: 'Pause',
		more: 'More',
		switchToFrontBack: 'Switch to {value} view',
		frontView: 'front',
		backView: 'back',
		totalMeasurements: '{value} Measurement/s',
		allRoleCustomizationsDisabled: 'Role Customization feature flags could be disabled for your role.',
		point: 'Point',
		selectOneOfThePoints: 'Select one of the points to start measuring!',
		selectAnotherPoint: 'Select another point',
		front: 'Front',
		bulkView: 'Bulk View',
		defaultView: 'Default View',
		maxEmail: 'Maximum emails you can upload is 100',
		missingPatientInfo: 'Missing patient info',
		conversationFiles: 'Conversation Files',
		conversationPhotos: 'Conversation Photos',
		noPermission: 'You do not have permission!',
		sendRequestFailed: 'Send request failed. Please try again!',
		conferenceFailed: 'Conference failed. Please try again!',
		requestHasBeenSent: 'Request has been sent!',
		manageBedside: 'Manage Bedside',
		serialNumber: 'Serial Number',
		roomSignage: 'Room Signage',
		respiration: 'Respiration',
		facialSkinAge: 'Facial Skin Age',
		stressIndex: 'Stress Index',
		cardiacWorkload: 'Cardiac Workload',
		heartAttackRisk: 'Heart Attack Risk',
		strokeRisk: 'Stroke Risk',
		extremelyLowOxygen: 'The patient has an extremely low oxygen saturation!',
		lowOxygen: 'The patient has a low oxygen saturation!',
		cardiacOutput: 'Cardiac Output',
		cardiacIndex: 'Cardiac Index',
		reloadLatestFeatures: 'Please {value} the page to access the latest features and improvements.',
		appleWatch: 'Apple Watch',
		selectTimer: 'Select Timer',
		snoozeAlert: 'This alert will be turned off for {value} while the patient gets help.',
		translationServices: 'Translation Services',
		minute: 'minute',
		generateQRCode: 'Generate QR Code',
		clickGenerateQRCode: 'Please click Generate QR Code to authorize Vital Kit',
		scanGeneratedQRCode: 'QR code generated. Please ask the bedside nurse to scan the QR code with All-in-one Exam Kit.',
		removedAek: 'All-in-one Exam Kit has been removed from the session.',
		spirometerMeasurements: 'Spirometer Measurements',
		spirometerTooltip: `A spirometer is a diagnostic device that measures the amount of air you're able to breathe in and out. It also tracks the time it takes you to exhale completely after you take a deep breath. A spirometry test requires you to breathe into a tube attached to a machine called a spirometer.`,
		guidePatientSpirometer: 'Guide the patient to place the device properly',
		selectedTranslator: 'Selected Translator',
		areYouSureRemoveTranslation: 'Are you sure you want to remove translation service?',
		providerName: 'Provider Name',
		otoscopeMeasurements: 'Otoscope {value} Measurements',
		dermatoscopeMeasurements: 'Dermatoscope Skin Measurements',
		darkMode: 'Dark Mode',
		handWashing: 'Hand Washing',
		handsDisinfected: 'Hands Disinfected',
		handsDisinfectedSuccessfully: 'Hands Disinfected Successfully!',
		lastUpdate: 'Last update',
		interpreter: 'Interpreter',
		resourceAssigned: 'Resource Assigned',
		skinTemperature: 'Skin Temperature',
		oralTemperature: 'Oral Temperature',
		e2ee: 'E2EE calls',
		dialIn: 'Dial In',
		dialOut: 'Dial Out',
		ptzCam: 'PTZ controls',
		pTZControlsDescription: `When this is enabled, users will be able to control the PTZ of the {helloName} device and {huddleName} USB cam and create bookmarks for specific camera positions.`,
		faceDownDescription: 'When this is enabled, users will be able to be alerted via AI when a patient is turned face down. ',
		aIPrivacyModeDescription:
			'When this is enabled, users will be able to hide the patient and draw a stick figure on top of them to protect their privacy.',
		handWashingDescription: `When this is enabled, hospital administrators will be able to monitor/track healthcare providers via AI if they're being compliant with hospital hygiene.`,
		fallDetectionDescription:
			'When this is enabled, users will be able to be alerted via AI when a patient falls on the ground. ',
		patientMobility: 'Patient Mobility',
		patientWalkingDescription:
			'When this is enabled, users will be able to be alerted via AI when a patient has a risk of falling while they are walking. ',
		railsDescription:
			'When this is enabled, users will be able to be alerted via AI when a patient puts a rail down from their bed. ',
		inactiveTimeDescription:
			'When this is enabled, users will be able to be alerted via AI when a patient has been inactive for a specified period of time. ',
		gettingOutOfBedDescription:
			'When this is enabled, users will be able to be alerted via AI when a patient tries to get out of their bed.',
		aIVitalSignsDescription: `When this is enabled, users will be able to monitor patients' vital signs using Artificial Intelligence.`,
		biobeatVitalSigns: 'Biobeat vital signs',
		biobeatVitalSignsDescription: 'Through this option, users will be able to enable Biobeat real-time vital sign monitoring.',
		smartWatchVitalSigns: 'Smartwatch vital signs',
		smartWatchVitalSignsDescription:
			'Through this option, users will be able to enable smartwatch real-time vital sign monitoring.',
		dialInDescription: 'When this is enabled, the option to invite people to the call via phone number will display.',
		dialOutDescription: 'When this is enabled, the option to call people via phone number will display.',
		inviteViaEmailDescription: 'When this is enabled, the option to invite people to the call via email will display.',
		translationServiceDescription: 'When this is enabled, translation services in different languages will display.',
		inviteViaSmsDescription: 'When this is enabled, the option to invite people to the call via SMS will display.',
		meetingUrl: 'Meeting URL',
		meetingUrlDescription: 'When this is enabled, the option to invite people to the call via the link will display.',
		roundingNightVisionDescription:
			'When this is enabled, the option to see in the night vision mode during the call will appear.',
		gridViewDescription: 'When this is enabled, the option to show all call participants as a grid view will appear.',
		fitToScreenDescription: 'When this is enabled, the option to make full screen or fit to screen during the call will display.',
		screenSharing: 'Screensharing',
		screenSharingDescription: 'When this is enabled, the option to share the screen during the call will display.',
		invitingPeople: 'Inviting People',
		generalFeatures: 'General Features',
		communicatingWithThePatient: 'Communicating with the patient',
		communicatingWithNurses: 'Communicating with nurses',
		automaticAlerts: 'Automatic alerts',
		cannotBeEmpty: 'Cannot be empty',
		viewPatientTooltip:
			"This option will enable you to see and hear the patient; the patient won't be able to see or hear you unless you enable it during the feed.",
		ttpTooltip:
			"This option will initiate an audio call to the patient, they will be able to hear you and you will be able to hear them. During the call, you can switch your or patient's camera on, or even share your screen.",
		userNoAccess: 'You do not have permission to complete this action.',
		reload: 'Reload',
		rpmProgram: 'RPM Program',
		rpmPrograms: 'RPM Programs',
		addRpmProgram: 'Add RPM Program',
		pleaseWriteProgramName: 'Please write program name',
		careTeam: 'Care Team',
		pleaseSelectCareTeam: 'Please select Care Team members',
		primaryCarePhysician: 'Primary Care Physician',
		specialist: 'Specialist',
		pharmacist: 'Pharmacist',
		careGiver: 'Caregiver',
		selectMember: 'Select a member',
		survey: 'Survey',
		viewSurvey: 'View Survey',
		viewCareTeam: 'View Care team',
		score: 'Score',
		heartRateSpo2: 'Heart Rate & SpO2',
		mod: 'Medium',
		searchByName: 'Search for patients by name',
		pleaseSelectRpmProgram: 'Please select an RPM program',
		selectProgram: 'Select a program',
		pleaseSelectMember: 'Please select a {value}',
		pleaseSelectDoctor: 'Please select a doctor',
		program: 'Program',
		createProgram: 'Create Program',
		deleteProgram: 'Delete Program?',
		areYouSureDeleteProgram: 'Are you sure you want to delete this program?',
		heartLungsAuscultation: 'Heart and Lungs Auscultation',
		creatingProgram: 'Creating Program',
		programName: 'Program Name',
		measurementsSelection: 'Measurements Selection',
		pickMeasurements: 'Pick the measurements and devices you want to enable for this program.',
		selectedMeasurements: 'Selected Measurements',
		measurements: 'Measurements',
		pleaseSelectMeasurement: 'Please select at least one measurement.',
		pleaseSelectDevice: 'Please select at least one device per every selected measurement.',
		editingProgram: 'Editing Program',
		editProgram: 'Edit Program',
		programExists: 'This program already exists!',
		maximumDevices: 'Measurement should contain up to two devices!',
		wearables: 'Wearables',
		provider: 'Provider',
		wearableId: 'Wearable ID',
		wearableType: 'Type',
		patchWearableType: 'Patch',
		watchWearableType: 'Watch',
		inactive: 'Inactive',
		monitor: 'Monitor',
		nutritionist: 'Nutritionist',
		providerError: 'Please select a provider.',
		wearableTypeError: "Please select the wearable's type.",
		wearableIdEmpty: "Please enter the wearable's ID.",
		invalidWearableId: 'Please enter a valid ID.',
		patchIdAlreadyExists: 'Patch ID already exists',
		aspDescription:
			'Alexa Smart Properties for healthcare makes it easy and cost effective for hospitals and providers to care for their patients. Alexa Smart Properties simplifies deploying and managing Alexa enabled-devices at scale and allows properties to enable HIPAA-eligible Alexa skills, connecting care providers to patients through voice experiences.',
		alexaSmartProperties: 'Alexa Smart Properties',
		alexaSmartPropertiesActivated: 'Alexa Smart Properties Activated!',
		aspFailed: 'ASP could not be activated, please try again later.',
		baseURL: 'Base Url',
		addBaseUrl: 'Add Base Url',
		addApiKey: 'Add Api Key',
		eCareManager: 'E-Care Manager',
		secretKey: 'Secret Key',
		nodeId: 'Node Id',
		nodeLevel: 'Node Level',
		carelogs: 'Care Logs',
		carelogDetails: 'Care log details',
		visitInfo: 'Visit Info',
		patientInfo: 'Patient Info',
		endVisit: 'End Visit',
		careMemberType: 'Care Member Type',
		liveMeasurements: 'Live Measurements',
		medicalRecordNumber: 'Medical Record Number',
		patientsCareLogs: `{value}'s Care Logs`,
		possessiveSessions: `{value}'s Sessions`,
		at: 'at {value}',
		removePosition: 'Remove Position',
		removeLicense: 'Remove License',
		removeEducation: 'Remove Education',
		ewsScore: 'EWS Score',
		lowRisk: 'Low risk',
		ewsSettings: 'EWS Settings',
		removeOption: 'Remove Option',
		removeQuestion: 'Remove Question',
		display: 'Display',
		theme: 'Theme',
		defaultTheme: 'Default Theme',
		light: 'Light',
		dark: 'Dark',
		question: 'Question',
		option: 'Option',
		addNewSurveyDescription: 'No surveys have been added yet.',
		addNewSurvey: 'Add new survey',
		addNewQuestion: 'Add new question',
		addMoreOptions: 'Add more options',
		atLeastTwoOptions: 'Please select at least two options per question',
		valuesCannotBeEmpty: 'Values cannot be empty',
		options: 'Options',
		patchNotExist: 'This patch does not exist',
		telemetry: 'Telemetry',
		deleteFileForMyself: 'Delete file for myself',
		deleteFileWarning: 'Are you sure you want to delete this file?',
		conferenceEndedParticipantInviteDenied:
			"You don't have sufficient permissions to call this participant. Please contact your administrator.",
		participantInviteDenied: "You don't have sufficient permissions to call {value}. Please contact your administrator.",
		switchingToOtherTab:
			'This monitoring session will end and the windows will close upon leaving this page. Are you sure you want to leave?',
		key: 'Key',
		selectConfiguration: 'Select Configuration',
		selectConfigurationDesc: 'Select Configuration you want to apply',
		nodeName: 'Node Name',
		scope: 'Scope',
		issuer: 'Issuer',
		returnUrl: 'Return URL',
		oauthScopes: 'OAuth Scopes',
		dictate: 'Dictate',
		write: 'Write',
		statAlarm: 'Stat Alarm',
		statAlarmDescription: `When this is enabled, users will see an extra icon within each monitoring feed, and toggling it will play an alarm on the patient's side.`,
		statAlarms: 'Stat Alarms',
		activationTime: 'Activation Time',
		deactivationTime: 'Deactivation Time',
		editRoomNameRounding: 'Edit Room Name',
		editRoomNameMonitoring: 'Edit Room Name',
		editRoomNameRoundingDescription: 'When this is enabled, users will be able to edit room names for Rounding',
		editRoomNameMonitoringDescription: 'When this is enabled, users will be able to edit room names for Monitoring',
		enableStatAlarm: 'Enable Stat Alarm',
		disableStatAlarm: 'Disable Stat Alarm',
		alexaEnabled: 'Alexa',
		enableAlexa: 'Enable Alexa for this company',
		enableAlexaConfiguration: 'By enabling this feature, hospitals will be able to use Alexa on their {helloName} devices',
		pleaseSetPassword: 'Please set a password.',
		errorStatAlarm: 'There was an error toggling stat alarm of {value}. Please try again!',
		shippingAddress: 'Shipping Address',
		requestedBy: 'Requested By',
		shipped: 'Shipped',
		logistics: 'Logistics',
		patientProfile: 'Patient Profile',
		dashboard: 'Dashboard',
		totalNumberOfCalls: 'Total number of Calls',
		sessionByRole: '{value} Sessions by Role',
		totalNumberSessions: 'Total Number of Sessions',
		averageSessionDuration: 'Average Session Duration',
		totalDuration: 'Total Duration',
		durationInSeconds: 'Duration in seconds',
		sessionsByHour: 'Sessions by Hour',
		session: 'Session',
		sessions: 'Sessions',
		ambient: 'Ambient',
		monitoringSessions: 'Monitoring Sessions',
		totalSessionsDuration: 'Total Sessions Duration',
		outgoingSessionsDescription: 'Talk To Patient Sessions Initiated by the Care Team',
		incomingSessionsDescription: 'Talk To Patient Sessions Initiated by Patients',
		viewPatientSessionsDescription: 'View Patient Sessions Initiated by the Care Team',
		familyVisits: 'Family Visits',
		avgSessions: 'Average Sessions per hour',
		highestVolumeOfSessions: 'Highest Volume of Sessions',
		totalSessions: 'Total Sessions',
		sessionByType: 'Sessions by Type',
		externalIdentityProviders: 'External Identity Providers',
		externalIdentityProviderMembers: 'External Identity Provider Members',
		selectNurse: 'Select Nurse',
		noMembersAdded:
			'No {value} have been added yet. To begin adding {value}, simply click on the button above. You can add up to {value2} {value}.',
		doctors: 'Doctors',
		atLeastOneMember: 'Please select at least one member!',
		addAdditionalMember:
			'You can add additional {value} in addition to the ones already included in the program. To begin adding {value}, simply click on the button above. You can add up to {value2} {value}.',
		editRpmProgram: 'Edit RPM Program',
		levelOfConsciousness: 'Level of consciousness',
		supplementOxygen: 'Supplement Oxygen',
		phoneNotReachable: 'Phone number is not reachable',
		sipNotAvailable: 'Phone dialing is not available at the moment.',
		integrations: 'Integrations',
		eCareApiKey: 'e-care API Key',
		eCareBaseUrl: 'e-care Base Url',
		deviceWiFiTryingToConnect: 'Trying to connect {value}',
		deviceWiFiScanFailed: '{value} scan failed. Please wait a bit and try again.',
		diviceWiFiScanNotFound: '{value} not found, please try again.',
		deviceWiFiConnectionFailed: '{value} connection failed. Please try again.',
		deviceWiFiDisconnected: 'Disconnected {value}. Please try again.',
		length: 'Length',
		viewAllProviders: 'View all Providers',
		totalNumberOfProviders: 'Total Number of Providers',
		lastSessionDate: 'Last Session Date',
		noProvidersFound: 'No providers with sessions completed on the list',
		sessionStart: 'Session Start',
		sessionEnd: 'Session End',
		sessionStatus: 'Session Status',
		sessionDetails: 'Session Details',
		callStart: 'Call Start',
		callEnd: 'Call End',
		allowMicPermissions:
			'hellocare needs access to your microphone so patients can hear you. Please allow microphone access in web browser settings.',
		turnOnDictation: 'Turn dictation on',
		turnOffDictation: 'Turn dictation off',
		somethingWentWrongSpecific: '{value}. Something went wrong!',
		unitCannotBeEmpty: 'Unit cannot be empty',
		roomCannotBeEmpty: 'Room cannot be empty',
		bedCannotBeEmpty: 'Bed cannot be empty',
		total: 'Total',
		reason: 'Reason',
		subjectIdentification: 'Subject Identification',
		employeeDirection: 'Employee Direction',
		handHygieneAudits: 'Hand Hygiene Audits',
		handHygieneViewed: 'Hand Hygiene Viewed',
		exportExcel: 'Export Excel',
		exportExcelBackground:
			'The Excel export will be created in the background. Once finished, it will be sent to {value} in an attachment.',
		complianceReport: 'Hygiene Compliance Report',
		screenShareNotAllowed: 'Someone else is sharing their screen.',
		screenSharingNotAllowed: "You can't share your screen while someone else is sharing.",
		ptzCamSettingsTitle: '20x PTZ Cam Settings',
		reverseCamera: 'Reverse Camera',
		allowPermissions: 'Please allow permissions to camera and microphone in order to call patient.',
		guest: 'Guest',
		selectSurvey: 'Select survey type',
		generic: 'Generic',
		safetyRange: 'Safety Range',
		createSurvey: 'Create Survey',
		addQuestionsDescription: 'You can start adding questions by clicking on the above button.',
		typeOfSurvey: '{value} survey',
		editSurvey: 'Edit Survey',
		surveyType: 'Survey type',
		surveys: 'Surveys',
		scheduledDate: 'Scheduled Date',
		questions: 'Questions',
		rapidRequestType: 'Rapid',
		admissionRequestType: 'Admission',
		regularRequestType: 'Regular',
		failedToGetParticipantInfo: 'Failed to get participant information. Please try again.',
		failedToGetYourInfo: 'Failed to get your information. Please try again.',
		failedToGetParticipantInfoName: "Failed to retrieve {value}'s information Please try again.",
		failedToGetInitiatorInfo: 'Failed to get initiator information. Please try again.',
		ascAlphaOrder: '(A-Z)',
		descAlphaOrder: '(Z-A)',
		ascNumOrder: '(0-9)',
		descNumOrder: '(9-0)',
		queueManagement: 'Queue Management',
		rapidRequestTypeCalls: 'Rapid Calls',
		admissionRequestTypeCalls: 'Admission Calls',
		regularRequestTypeCalls: 'Regular Calls',
		manageQueue: 'Manage Queue',
		viewQueue: 'View Queue',
		doctorsQueue: `'s Queue`,
		transferPatientsToDoctor: 'Transfer Patients to Doctor',
		noAvailableDoctor: 'There is no available doctor in this hospital!',
		successfullyTransferPatient: 'Transfer successful',
		requestSuccessfullyTransferPatient: 'Your patient transfer has been sent successfully!',
		incomingPatientRequest: 'Incoming patient request!',
		changeRpmProgram: 'Change RPM',
		reasonToConnect: 'Reason to Connect',
		acuity: 'Acuity',
		clinicalConcern: 'Clinical Concern',
		nurseInfo: 'Nurse Information',
		clinicalContact: 'Clinical Contact',
		isNurseClinicalContact: 'Is Nurse Clinical Contact',
		rapidResponse: 'Rapid Response',
		edAdmitDiscussion: 'ED Admit Discussion',
		drugOrderOrClarification: 'Drug Order / Clarification',
		abnormalVital: 'Abnormal Vital',
		abnormalLab: 'Abnormal Lab',
		criticalLab: 'Critical Lab',
		patientDeclining: 'Patient Declining',
		otherEmergent: 'Other Emergent',
		reviewOutsideFacilityTransfer: 'Review Outside Facility Transfer',
		drnOrDniClarification: 'DRN / DNI Clarification',
		otherNonEmergent: 'Other Non-Emergent',
		admitIcu: 'Admit ICU',
		admitNonIcu: 'Admit Non-ICU',
		admitBackup: 'Admit Backup',
		immediate: 'Immediate',
		ext: 'Ext',
		callPhoneNumber: 'Call Phone Number',
		lifeSignals: 'LifeSignals',
		lifeSignalDescription: 'When this is enabled, Lifesignal Patch device will be available to use during monitoring',
		unableToGetInfo: "Unable to get {value}'s information.",
		unableToGetInfoGeneralMessage: 'Unable to get participant information.',
		callNumber: 'Call',
		assignWearable: 'Assign wearable',
		assignWearableTooltip: 'Assign wearable',
		wearableHistoryTooltip: 'History',
		unassignWearable: 'Are you sure you want to unassign this wearable from this patient?',
		unassignWearableTooltip: 'Unassign wearable',
		roleCustomization: 'Role Customization',
		monitoringPrecautionsDescription:
			'When this is enabled, the {value} will be able to see precautions legend during monitoring.',
		poolingFlowDescription: 'When this is enabled, the {value} will be able to change pooling flow.',
		conversationHistoryDescription: 'When this is enabled, the {value} will be able to see conversations history.',
		notificationsDescription: 'When this is enabled, the {value} will be able to see notifications.',
		savedSessionsDescription: 'When this is enabled, the {value} will be able to save view of sessions.',
		conversationFilesDescription:
			'When this is enabled, the {value} will be able to share files during the chat with other users.',
		conversationPhotosDescription:
			'When this is enabled, the {value} will be able to share photos during the chat with other users.',
		homePageDescription: 'When this is enabled, the {value} will see a list of the patients who are waiting to join on the call.',
		waitingRoomDescription:
			'When this is enabled, the {value} will see a list of the patients who are waiting to join on the call.',
		aiNotificationsDescription: 'When this is enabled, the user will be able to see Rounding AI Alert Notifications.',
		patientsDescription: 'When this is enabled, the {value} will see a list of the patients who have been assigned to him/her.',
		apppointmentsDescription: `When this is enabled, the {value} will have option to set availability and create appointments for patients.`,
		doctorRegistrationForm: 'Registration Form',
		registrationFormPhotosDescription:
			'When this is enabled, Doctors will be required to complete a registration form to proceed with their role.',
		roundingDescription: `When this is enabled, the {value} will be able to initiate roundings with patients.`,
		rpmDescription: 'When this is enabled, the {value} will be able to initiate RPM programs for patients.',
		chatDescription: 'When this is enabled, the {value} will be able to initiate chat with other users.',
		teamsDescription: 'When this is enabled, the {value} will be able to initiate chat with other users.',
		monitoringDescription: 'When this is enabled, the {value} will be able to initiate monitoring sessions.',
		visitorsDescription: 'When this is enabled, the {value} will be able to see a list of the visitors for the specific room.',
		dashboardDescription: 'When this is enabled, {value} will be able to see real-time data for the assigned Health System.',
		auditLogsDescription: 'When this is enabled, user will be able to see logs for specific health system',
		queueManagementDescription: 'When this is enabled, {value} will be able to manage the queue of the doctors.',
		organizationDescription: 'When this is enabled, {value} will be able to add new hospitals, departments, floors, and rooms. ',
		callLogsDescription: 'When this is enabled, {value} will be able to see past and ongoing calls. ',
		userManagementDescription: 'When this is enabled, {value} will be able to see members and invited members',
		configurationsDescription: 'When this is enabled, {value} will be able to configure device, network, and care events.',
		noDataToDownload: 'No data to download',
		noAssignedWearable: 'No wearable is assigned to this patient. To assign a wearable, click the "+" button on the right.',
		enableMultipleFeedAudioDescription:
			'When this is enabled, users will be able to enable audio when monitoring multiple feeds.',
		patientInformation: 'Patient Information',
		voiceOver: 'Voice Over',
		telehealthDescription:
			'An integrated TV solution, enables hospitalized individuals to enjoy movies and entertainment content, enhancing their stay without requiring physical mobility.',
		evideoDescription:
			'eVideon is a comprehensive patient experience platform designed to enhance engagement and communication in healthcare facilities. It offers interactive multimedia solutions that provide personalized education, entertainment, and communication tools for patients, improving their satisfaction and overall hospital experience.',
		pcareDescription: `With pCare's patient engagement platform, patients have access to interactive tools for education, entertainment, and communication. It empowers patients by providing personalized health information and resources, reducing anxiety, and fostering greater involvement in their own care.`,
		getwellDescription: `Getwell's patient engagement and communication platform facilitates a seamless connection between patients and their healthcare providers. It offers a range of tools for education, communication, and care coordination, enabling patients to access personalized health information, interact with their care team, and actively participate in their treatment plans.`,
		sonifiDescription: `Enhance patient experience with SONIFI Health's integrated solution, merging communication, education, and entertainment seamlessly. Elevate care delivery by enabling effortless patient engagement within hospital rooms.`,
		patientExperience: 'Patient Experience',
		rtls: 'RTLS',
		rtlsDescription: 'When this is enabled, patients will see that the nurse is in the room.',
		roomModes: 'Room Modes',
		helloCareDescription: `When this is enabled, the screen will display information such as the date, room name and patient's name.`,
		nicuDescription: 'When this is enabled, the screen will display a view for a baby room.',
		backgroundAI: 'Background AI',
		customizeRoom: 'Customize Room',
		roomTypes: 'Room Types',
		babyRoomTypeDescription: 'When this is enabled, you will be able to set Baby Room as room type on room level.',
		frontDeskRoomTypeDescription: 'When this is enabled, you will be able to set Front Desk as room type on room level.',
		doctorRoomTypeDescription: 'When this is enabled, you will be able to set Doctor as room type on room level.',
		laboratoryRoomTypeDescription: 'When this is enabled, you will be able to set Laboratory as room type on room level.',
		injectionRoomTypeDescription: 'When this is enabled, you will be able to set Injection Room as room type on room level.',
		infusionRoomTypeDescription: 'When this is enabled, you will be able to set Infusion Room as a room type on room level.',
		digitalClinicRoomTypeDescription: 'When this is enabled, you will be able to set Digital Clinic as room type on room level.',
		pharmacyRoomTypeDescription: ' When this is enabled, you will be able to set Pharmacy as room type on room level.',
		radiologyRoomTypeDescription: 'When this is enabled, you will be able to set Radiology as room type on room level.',
		kioskRoomTypeDescription: 'When this is enabled, you will be able to set Kiosk as room type on room level.',
		triageRoomTypeDescription: 'When this is enabled, you will be able to set Triage as room type on room level.',
		intakeForm: 'Intake Form',
		intakeFormDescription: 'When this is enabled, providers will be able to collect patient information.',
		doctorPoolingDescription: 'When this is enabled, the screen will display the list of the doctors assigned to that hospital.',
		scpHealthForm: 'SCP Health Form',
		baycareForm: 'Baycare Form',
		aiWidgetDescription:
			'When this is enabled, the screen will display widgets like vital signs, upcoming appointments, Call a Caregiver, AI Vital Signs, Physical Therapy.',
		widgetDescription:
			'When this is enabled, the screen will display widgets like vital signs, upcoming appointments, Call a Caregiver, Education, Today Prescriptions.',
		digitalClinicDescription: `When this is enabled, the screen will display information such as the date, room name, patient's name and patient latest measurements.`,
		telemedicineDescription:
			'When this is enabled, the screen will display the list of available doctors and the type of calls users can initiate.',
		hospitalDescription:
			'When this is enabled, the screen will display widgets like list of Family and Friends, Apps, Call a Caregiver, Education, Today Prescriptions.',
		virtualExaminationDescription:
			'When this is enabled, the screen will display the list of available doctors which the user can initiate calls.',
		simplifiedDescription: `When this is enabled, the screen will display information such as the date, room name and patient's name.`,
		eVideonDescription:
			'When this is enabled, the screen will display interactive patient care solutions that engage, educate, and entertain patients and visitors, while streamlining workflows for staff.',
		patientExperienceDescription:
			'When this is enabled, the screen will display interactive patient care solutions that engage, educate, and entertain patients and visitors, while streamlining workflows for staff.',
		mdmDescription: 'MDM patient experience platform elevates patient engagement throughout the continuum of care.',
		azureAd: 'Azure AD',
		duo: 'Duo',
		pingFederate: 'Ping Federate',
		okta: 'OKTA',
		hillRom: 'Hill-Rom',
		epic: 'Epic',
		cerner: 'Cerner',
		telehealth: 'Telehealth',
		evideon: 'eVideon',
		pcare: 'pCare',
		getwell: 'GetWell',
		sonifi: 'Sonifi',
		mdm: 'MDM',
		globo: 'Globo',
		amn: 'AMN',
		addUnitPhoneNumber: 'Add Unit Phone Number',
		unitPhoneNumber: 'Unit Phone Number',
		noIntegrationTypesAvailable: 'No integration types available',
		pleaseSetupIntegrationType: 'Please setup an integration type first. Contact your service provider for assistance.',
		errorVoiceOver: 'There was an error toggling "Stay Still" alert of {value}. Please try again!',
		enableStayStill: 'Enable "Stay Still" alert',
		disableStayStill: 'Disable "Stay Still" alert',
		stoppedAlarmPopup: 'Alarm stopped due to response from nurse {value}',
		activeAlarmTriggered: 'Active alarm triggered by',
		statAlarmStarted: 'Stat alarm has started',
		statAlarmStopped: 'Stat alarm has stopped',
		complete: 'Complete',
		hasStoppedStatAlarm: 'stopped the alarm',
		voiceOverAlarmStopped: 'Manual alert deactivated',
		voiceOverAlarmStarted: 'Manual alert activated',
		addTranslatorInCall: 'Add translator into to the call',
		whiteboard: 'Whiteboard',
		whiteboardType: 'Whiteboard Type',
		stoppedAlertPopup: 'Manual alert stopped due to response from nurse {value}',
		selectAlarm: 'Select Alarm',
		availableAlarms: 'Available Alarms',
		selectOtherAlarm: 'Select other alarm',
		alarm: 'Alarm',
		alarmCount: 'Alarm {value}',
		scheduled: 'Scheduled',
		repeat: 'Repeat',
		weekly: 'Weekly',
		monthly: 'Monthly',
		selectSchedule: 'Select schedule type',
		ivBagFluidLevel: 'IV Bag Fluid Level',
		admissions: 'Admissions',
		discharges: 'Discharges',
		admissionsAndDischarges: 'Admissions and Discharges',
		avgAdmissionsPerHour: 'Average Admissions per Hour',
		avgDischargesPerHour: 'Average Discharges per Hour',
		highestAdmissionsPerHour: 'Highest Admissions per Hour',
		highestDischargesPerHour: 'Highest Discharges per Hour',
		ivBagDescription:
			'When this is enabled, users will be able to see realtime updates of the IV bag fluid level and be alerted when the level is low.',
		monitoringHealthDataDescription: 'When this is enabled, the tab Health Data under Alerts on monitoring should be displayed.',
		ivBagCheck: 'IV bag fluid level is low. Please check the IV.',
		warningAlertAt: 'Warning alert at',
		enableIvBagWarning: 'To enable this option, you must first provide a warning value.',
		patientEngagement: 'Patient Engagement',
		nurseCallsSystems: 'Nurse Calls Systems',
		configurationTypeRequired: 'Configuration type is required',
		externalTypeDescription:
			'When this is enabled, will be able as a global authentication authority that allows customers, employees, and partners to securely access all the applications they need from any device.',
		ehrDescription:
			'When this is enabled, users will be able to access a unified patient record for better clinical decision-making and improved patient care.',
		translationTypeDescription:
			'When this is enable, users will be able to seamlessly translate content and communication into multiple languages.',
		doctorQueue: 'Doctor Queue',
		lowIvBagFluidLevel: 'Low IV Bag Fluid Level',
		bedIdentifier: 'Bed Identifier',
		enterEmailAddress: 'Enter Email Address',
		addDomain: 'Add Domain',
		enterEmailDomain: 'Enter Email Domain',
		familyMemberNotAssigned: 'You are not assigned to any patient yet!',
		dependants: 'Dependants',
		cannotBeFamilyMember: 'Patient cannot be a family member for itself',
		patientGettingOutOfChair: 'Getting out of chair detected',
		personGettingOutOfChairMessage: 'Patient is getting out of the chair',
		sessionsQueue: 'Sessions Queue',
		sessionsQueueDescription: 'When this is enabled, the Doctor will be able to see a session queue that can join or reject.',
		patientIsInactiveToastMessage: `It's due time to turn the patient. Please notify the bedside nurse`,
		forwardToNursesBtn: 'Forward to Nurses',
		patientInactiveInformation: `It's due time to turn the patient`,
		careEventsForNursesDesc:
			'Patient interventions such as Admission, Consultation, Treatment, Discharge, Stat alarm and Privacy mode performed by VCPs are registered.',
		measuringIv: 'Measuring the IV fluid level...',
		digitalBackground: 'Digital Background',
		selectPreferences: 'Select preferences',
		backgroundImage: 'Background Image',
		backgroundImagesAllowed: 'Provide image options for users to select background effects during video calls.',
		presentImage: 'Present Images',
		addImage: 'Add image',
		blurryBackgroundDefault: 'Set Blurry background as default',
		blurryBackground: 'Blurry Background',
		enabled: 'Enabled',
		notEnabled: 'Not enabled',
		viewMedications: 'View Medications',
		todaysMedication: `Today's Medication`,
		patientSkippedMedication: 'Patient has skipped medication',
		notifyPatientForMedication: 'Notify patient to take their medication',
		featureChanged: 'Feature Changed',
		variant: 'Variant',
		changedForRole: 'Changed for Role',
		skipped: 'Skipped',
		taken: 'Taken',
		displayCaregiverName: 'Display Caregiver’s Name',
		displayCaregiversNameDescription: `When this is enabled, the patient will be able to see the full name(e.g Linda Andreson) or first name and the first letter of the last name(e.g Linda A.) of the caregiver which is displayed during the call between the patient and caregiver, in cases when this is disabled, the patient will not be able to see neither name nor any letters of caregivers`,
		sessionMaintenance: 'This session was ended by maintenance.',
		exceededCharactersLimit: 'You have exceeded the maximum allowed characters. Please adjust or remove excess notes.',
		helloCareEngagement: 'Hellocare Engagement',
		homeWidget: 'Home',
		whiteboardURL: 'Whiteboard URL',
		engagementWhiteboardURLDescription: 'Enter Whiteboard URL to access hellocare digital whiteboard functionality',
		home: 'Home',
		engagementHomeDescription:
			'Patient Communication and Information Center: A central hub that facilitates patient communication and provides essential updates. It includes an interactive whiteboard for versatile use.',
		educationWidget: 'Education',
		engagementEducationDescription:
			'Healthcare Educational Resources: A repository of healthcare-related educational videos, enhancing patient knowledge and engagement.',
		liveTvWidget: 'Live TV',
		engagementLiveTVDescription:
			'Entertainment and Information Channels: A selection of diverse TV channels across genres, ensuring patients have access to entertainment and information options.',
		moviesWidget: 'Movies',
		engagementMoviesDescription: 'Movie Library: A catalog of movies covering various genres, offering on-demand entertainment.',
		musicWidget: 'Music',
		engagementMusicDescription:
			'Music Radio Stations: Access to music radio stations, categorized by genre, for patient relaxation and enjoyment.',
		moodsWidget: 'Moods',
		engagementMoodsDescription:
			' Relaxation Videos: A collection of mood-enhancing videos, including scenes like fireplaces and beaches, to promote patient comfort and well-being.',
		appsWidget: 'Apps',
		engagementAppsDescription:
			'Entertainment Applications: Integration with popular entertainment apps such as Netflix, Amazon Prime, and YouTube Music, expanding patient entertainment choices.',
		familyAndFriendsWidget: 'Family & Friends',
		engagementFamilyFriendsDescription:
			'Patient Connectivity: A feature that enables patients to stay connected with their loved ones through video calls, promoting emotional support.',
		orderMealsWidget: 'Order Meals',
		engagementOrderMealDescription:
			'Meal Ordering Convenience: A convenient option for patients to order meals based on their preferences, enhancing their dining experience.',
		whiteBoardWidget: 'Whiteboard',
		engagementWhiteboardDescription:
			'Interactive Information Hub: A multi-functional whiteboard that serves as an interactive information hub, allowing for notes, content display, and video integration.',
		careTeamWidget: 'Care Team',
		engagementCareTeamDescription:
			'Care Team Access: Patients can easily access and communicate with their dedicated care team members, streamlining care coordination.',
		healthDataWidget: 'Health Data',
		engagementHealthDataDescription:
			'Health Monitoring and Insights: Access to health data from various monitoring devices and IoTs, providing a comprehensive view of patient health.',
		sessionAborted: 'Session has been aborted. Please try again.',
		sessionDisrupted: 'Session has been disrupted. Please try again.',
		perfect: 'Perfect',
		good: 'Good',
		bad: 'Bad',
		mediocre: 'Mediocre',
		noCareEvents: 'No care events available.',
		askAdministratorForCareEvents: 'Please ask your administrator to add care events.',
		providerCamera: `Provider's camera`,
		providerMic: `Provider's microphone`,
		providerMicDesc: `By enabling this, the provider's mic will be on when starting the call.`,
		patientCamera: `Patient's camera`,
		patientMicrophone: `Patient's microphone`,
		patientMicrophoneDesc: `By enabling this, the patient's mic will be on when starting the call.`,
		disableProviderCameraDesc: `By enabling this, the provider's camera will be on when starting the call.`,
		patientMicDesc: `By enabling this, the patient's mic will be on when starting the call.`,
		patientCameraDesc: `By enabling this, the patient's camera will be on when starting the call.`,
		startAsAudioDesc: 'By enabling this, calls will start with audio only.',
		viewPatientDesc: `By enabling this, the provider can go into the patient room and monitor the patient. The provider's mic will be off by default, but they can hear and see the patient.`,
		enableLiveCaptions: 'Enable live captions',
		disableLiveCaptions: 'Disable live captions',
		liveCaptionsDescription:
			'When this is enabled, the {value} will be able to see live captions of the participants who are talking.',
		participantRemovedByAdministrator: 'You have been removed from this conference by an administrator.',
		fallPrevention: 'Fall Prevention',
		wantToTurnOnFallPrevention: 'Do you want to turn on AI Fall Prevention in monitoring?',
		continueNoAi: 'No, continue without AI',
		aiMonitoringOn: 'Turn ON AI monitoring',
		aiFallPrevention: 'AI Fall Prevention',
		helloBackground: 'Hello Background',
		setHelloBackground: 'Select an image that will appear in the patient’s view before the session starts.',
		helloWallpaper: 'Hello Wallpaper',
		setHelloWallpaper: 'Select an image that will appear behind the icons on the application screen.',
		addCareEvent: 'Add care event',
		nameOrEmail: 'Name or Email',
		careEventHistory: 'Care event history',
		talkToPatientSettings: 'Talk to Patient Settings',
		talkToPatientSettingsDesc:
			'Use the options below to specify what the provider and patient will see and hear when making a call to or from the hello device.',
		callSettingsForRoomSignDesc:
			'Use the options below to specify what the provider and patient will see and hear when making a call to or from the Room Sign.',
		viewPatientSettings: 'View Patient Settings',
		viewPatientSettingsDesc: 'Use this option to show or hide the View Patient option on the provider side.',
		videoMonitoring: 'Video Monitoring',
		ambientMonitoring: 'Ambient Monitoring',
		ambientAndVideoMonitoring: 'Ambient and Video Monitoring',
		ambientMonitoringDescription: 'When this is enabled, the provider will be able to receive alerts.',
		unoccupied: 'Unoccupied',
		ambientMonitoringNotActive: 'Ambient Monitoring Not Active',
		receiveAlerts: 'In order to receive alerts,',
		inOrderToReceiveAlerts: 'in order to receive alerts.',
		alert: 'Alert',
		manageAlert: 'Manage Alert',
		closeAlert: 'Close Alert',
		selectViewOrAddRooms: 'Select one of the Saved Views, or start by adding rooms to the Current View.',
		maxAmbientMonitoringProviders: 'This device has reached the max. of allowed Ambient Monitoring providers.',
		exportRebootDeviceLogs: 'This device has no reboot logs yet.',
		logs: 'Logs',
		removeParticipant: 'Remove participant',
		confirmParticipantRemoval: 'Confirm participant removal',
		confirmationOfRemovalParticipant: 'Are you sure that you want to remove {value} from the call?',
		youAreRemovedFromConference: `You've been removed from this conference.`,
		helloSpeakerSelected: 'Hello device internal speaker is selected',
		tvSpeakerSelected: 'TV speaker is selected',
		switchToTv: 'Switch to TV speaker',
		switchToHello: 'Switch to Hello device speaker',
		viewing: 'Viewing',
		fetchingPrecautionsFailed: 'Fetching precautions has failed.',
		selectCareEvent: 'To enable notes, select one care event',
		supportChat: 'Support Chat',
		supportChatDescription: 'When this is enabled, the users will have the support chat available within the app.',
		prefixes: 'Prefix',
		displayFullName: 'Displaying the full name',
		displayCredentialsName: 'Showing the first name, the first letter of the surname, and credentials',
		deleteConfirmation: 'Delete confirmation',
		failedToGetCaptions: 'Failed to get captions! Please try again in a moment.',
		careEventsDashboard: 'Care Events Dashboard',
		created: 'Created',
		departmentFloorRoom: 'Department > Floor > Room',
		careEventType: 'Care Event Type',
		admission: 'Admission',
		discharge: 'Discharge',
		allEvents: 'All Events',
		myCareEvents: 'My Care Events',
		selectCareTeam: 'Select Care Team',
		assignCareTeam: 'Assign Care Team',
		newCareEvent: 'New Care Event',
		searchRoomName: 'Search for room name',
		careEventsDescription: 'When this is enabled, the {value} will be able to see care events.',
		statusAlreadyExists: 'This status already exists!',
		patientCannotBeEmpty: 'Patient cannot be empty',
		nurseCannotBeEmpty: 'Nurse cannot be empty',
		addPatientWithEvent: 'Assign an existing or new patient to a room to link the care event with their record.',
		selectExistingPatientWithEvent: 'Select a patient to add a care event, and assign a care team member to manage it as a task.',
		searchPatientByMrn: 'Search patient by name or MRN',
		aIDetectionDrawings: 'AI Detection Drawings',
		removeRoomMonitoring: 'Do you want to remove room {value}?',
		sureRemoveRoomMonitoring: 'Please confirm that you would like to remove this room from the current monitoring session.',
		removeSessionMonitoring: 'Do you want to close the current session?',
		sureRemoveSessionMonitoring: 'Please confirm that you would like to close the current session.',
		interventions: 'Interventions',
		addInterventions: 'Add Interventions',
		interventionsHistory: 'Interventions History',
		newInterventions: 'New interventions',
		noInterventions: 'No interventions available',
		askAdministratorForInterventions: 'Please ask your administrator to create a list of interventions to choose from.',
		selectInterventions: 'Select an option in order to add additional notes.',
		aditionalNotes: 'Additional Notes',
		addAdditionalNotes: 'Add Additional Notes',
		eventHistory: 'Event History',
		eventCompleted: '{value} Completed',
		eventCreated: '{value} Event Created',
		statusUpdated: 'Status Updated',
		youUpdatedStatus: 'You have updated the status on an event',
		describeChange: 'Describe why you made this change',
		yourInsightsHelp: 'Your insights help us maintain accurate records and ensure the best care for all involved',
		youReassignedCareMember: 'You have reassigned a Care Team on an event',
		reassigned: 'Reassigned',
		assignedCareTeam: 'Assigned Care Team',
		patientIsAtRoom: 'Patient is at room',
		sameExerciseSameDay: 'Same Exercise, in the same day, is already created!',
		youUpdatedPriority: 'You have updated the priority on an event',
		priorityUpdated: 'Priority Updated',
		selectStatus: 'Select status',
		filters: 'Filters',
		clearAll: 'Clear All',
		dateRange: 'Date Range',
		selectWithinDateRange: 'Date range cannot be more than {value} days!',
		selectValidDateRange: 'Please select a valid date range!',
		selectValidEndTime: 'Please pick a valid end time!',
		patientPlacement: 'Patient Placement',
		skipSslVerification: 'Skip SSL verification',
		mrnNoMatch: 'The Medical Record Number (MRN) does not match our records.',
		healthAlerts: 'Health Alerts',
		aiAlerts: 'AI Alerts',
		activeAlerts: 'Active Alerts',
		showAll: 'Show all',
		showLess: 'Show less',
		allHospitals: 'All Hospitals',
		allDepartments: 'All Departments',
		allFloors: 'All Floors',
		allRooms: 'All Rooms',
		helloDevice: 'Hello Device',
		systemExecutedActions: 'System Executed Actions',
		digitalSign: 'Digital Sign',
		isIntervening: 'is intervening',
		aiSettings: 'AI Settings',
		dataCollectionAlerts: 'Allow Data Acquisition for false alerts',
		dataCollectionAlertsDescription: 'This option allows our system to get pictures in case a false alert is reported.',
		savingImage: 'Saving Image',
		imageSaved: 'Image Saved!',
		savingImageFailed: 'Saving Image Failed!',
		aiConfigurations: 'AI Configurations',
		railsDetection: 'Rails Detection',
		statAlarmActivation: 'Stat Alarm Activation on AI Alerts',
		toastMessage: 'Toast Message',
		alertOnMonitoringFeed: 'Alert on Monitoring Feed',
		soundOnAiAlert: 'Sound on AI Alert',
		patientAudio: 'Patient Audio',
		allowPatientAudio: 'Browsers require user interaction before they will play audio. Just click okay to continue.',
		allow: 'Allow',
		transferOwnership: 'Transfer Ownership',
		transferOwnershipDescription: 'Leaving the call will transfer ownership to another participant and will not end the call.',
		leaveCall: 'Leave Call',
		directCallEcm: 'Direct call from eCM',
		activeCareEvent: 'There is an active care event for the current patient on the selected room!',
		setTimeZone: 'Set Timezone',
		selectedTimeZone: 'Selected Timezone',
		searchPlaceTimeZone: 'Search Place or Timezone',
		updateCareEvents: 'There are new updates available for the care events. Click the button on the right to update',
		updateList: 'Update List',
		host: 'Host',
		path: 'Path',
		isSubscribed: 'Is subscribed',
		multipleBedRoom: 'Multiple-bed room',
		multipleBedRoomDesc: 'This will limit visitor side view. This should be added above Room Type.',
		careTeamReport: 'Care Team Report',
		patientReport: 'Patient Report',
		head: 'Head',
		rail: 'Rail',
		foot: 'Foot',
		admittedDate: 'Admitted Date',
		dischargedDate: 'Discharged Date',
		viewSessions: 'View Sessions',
		endTime: 'End Time',
		intervention: 'Intervention',
		actionTaken: 'Action Taken',
		interventedBy: 'Intervented By',
		hidePatientProfile: 'Hide patient profile',
		showPatientProfile: 'Show patient profile',
		nameNumber: 'Name/number',
		primaryPatientCondition: 'Primary Patient Condition',
		primaryAdverseEvent: 'Primary Adverse Event',
		additionalTitleBarNotes: 'Additional Title Bar Notes',
		patientNotes: 'Patient Notes',
		addNotes: 'Add notes about the patient',
		enterPatientName: 'Enter Patient Name',
		patientAge: 'Patient Age',
		enterPatientAge: 'Enter Patient Age',
		nurseNameNumber: 'RN - Name/Number',
		enterNurseNameNnumber: 'Enter RN - Name/Number',
		nurseAssistantNameNumber: 'PCT - Name/Number',
		enterNurseAssistantNameNumber: 'Enter PCT - Name/Number',
		tlNameNumber: 'TL - Name/Number',
		enterTlNameNumber: 'Enter TL - Name/Number',
		enterPrimaryAdverseEvent: 'Enter Primary Adverse Event',
		enterPrimaryPatientCondition: 'Enter Primary Patient Condition',
		primaryPatientRiskFactor: 'Primary Patient Risk Factor',
		enterPrimaryPatientRiskFactor: 'Enter Primary Patient Risk Factor',
		titleBarNotes: 'Additional Title Bar Notes',
		enterTitleBarNotes: 'Enter Additional Title Bar Notes',
		additionalPatientNotes: 'Additional Patient Notes ',
		enterAdditionalPatientNotes: 'Enter Additional Patient Notes ',
		enterPatientInformation: 'Enter Patient Information ',
		voiceAnnouncements: 'Verbal Redirection',
		patientPrivacy: 'Patient Privacy',
		patientOutOfRoom: 'Patient Out of Room',
		pleaseDoNotGetUp: 'Attention: Please do not get up!',
		pleaseDoNotTouchThat: 'Please do not touch that!',
		pleaseStopWhatYouAreDoing: 'Attention: Please stop what you are doing!',
		stopPlease: 'Stop, please!',
		someoneIsOnTheWay: 'I spoke with your nurse, someone is on the way to your room.',
		useYourCallLight: 'Please use your call light.',
		doYouNeedYourNurse: 'Do you need your nurse?',
		staffInjury: 'Staff Injury',
		isolationExposure: 'Isolation Exposure',
		medicalDeviceInterference: 'Medical Device Interference',
		elopement: 'Elopement',
		intentionalSelfHarm: 'Intentional Self-Harm',
		suicide: 'Suicide',
		delirium: 'Delirium',
		dementia: 'Dementia',
		substanceWithdrawal: 'Substance Withdrawal',
		brianInjury: 'Brian Injury',
		psychiatricDisorder: 'Psychiatric Disorder',
		stroke: 'Stroke',
		infectiousDiseaseIsolation: 'Infectious Disease Isolation',
		poorShortTermMemory: 'Poor Short-Term Memory',
		poorComprehension: 'Poor Comprehension',
		impulsivity: 'Impulsivity',
		agitation: 'Agitation',
		anxiety: 'Anxiety',
		distortedPerceptionOfReality: 'Distorted Perception Of Reality',
		angerFrustration: 'Anger/Frustration',
		none: 'None',
		patientIsOutOfTheRoom: 'Patient is Out of the Room',
		surgery: 'Surgery',
		patientisGoingDownForACTScan: 'Patient is going down for A CT Scan',
		patientIsGoingDownForAnMRI: 'Patient is going down for an MRI',
		ambulating: 'Ambulating',
		paracentesis: 'Paracentesis',
		sunshinePrivileges: 'Sunshine Privileges',
		transferring: 'Transferring',
		erCp: 'ER CP',
		dialysis: 'Dialysis',
		patientReturnedToRoom: 'Patient Returned to Room',
		roomWithoutPatientData: `This room doesn't have any patient data.`,
		fitToScreenVideoFeeds: 'Fit to Screen Video Feeds',
		drafts: 'Drafts',
		forward: 'Forward',
		interventionsAndAlerts: 'Interventions and Alerts',
		privacyModeTurnedOff: 'Privacy Mode turned off',
		privacyModeTurnedOn: 'Privacy Mode turned on',
		monitorPatient: 'Monitor the Patient',
		removeFeed: 'Remove Feed',
		turnVideo: 'Turn Video',
		turnVideoOn: 'Turn Video On',
		sessionType: 'Session Type',
		voiceAnnouncementsDescription:
			'Enabling Verbal Redirection will add the same as a feature within monitoring from where providers can use it to communicate with the patient by simply playing it as a pre-recorded sound. ',
		patientOutOfRoomDescription:
			'Enabling Patient out of Room will add the same as a feature within monitoring from where providers can document cases when the patient for certain reasons (disclosed as a dropdown) is out of room.',
		fullySilentMode: 'Silent Mode',
		cameraSettings: 'Camera Settings',
		cameraUse: 'Camera Use',
		selectOneOfCameraSettings: 'Select one of the camera settings',
		singleCameraUse: 'Single Camera Use',
		multiCameraUse: 'Multi Camera Use',
		genericDescription:
			"A Generic Survey is designed for patients to complete at their convenience or whenever they feel it's necessary. This survey will be accessible to patients on their Home Page.",
		safetyRangeDescription:
			'The Safety Ranges Survey is created for patients to fill out whenever their measurements surpass the established safety limits for a specific Vital Sign. ',
		scheduledDescription: 'Scheduled surveys are structured to serve as monthly, weekly, or daily check-ups for the patient.',
		snoozeTimerForAlert: 'Snooze timer for {value} alert',
		snoozeTimer: 'snooze timer',
		primaryPatientRisk: 'Primary Patient Risk',
		sessionByTime: 'Session By Time',
		totalHours: 'Total Hours',
		transferPatient: 'Transfer Patient',
		openLifeSignals: 'Open LifeSignals',
		isOldUserExperience: 'Is Old User Experience',
		isEnabledForHs: 'Is Enabled For Health Systems',
		isEnabledForHsDescription: ` When this is enabled, the Admins of Health Systems will be able to modify the "Is Old User Experience" Feature Flag.`,
		isOldUserExperienceDescription: 'When this is enabled, the company or health system user experience will be the old one.',
		userExperience: 'User Experience',
		mixedMonitoringView: 'Mixed monitoring view (Video and ambient)',
		mixedMonitoringViewDescription:
			'When this is enabled, providers will see "Ambient monitoring" as an additional capability/tab within the tree view and this way enable mixed monitoring view (video feed + ambient monitoring)',
		invitationNote: 'Invitation note',
		invitationNotePlaceholder: 'Add an invitation note for the guest to see.',
		invitationNoteDescription: 'All participants will see this note. Max 160 characters',
		pinLock: 'PIN Lock',
		disablePIN: 'By disabling the PIN lock, it will not be needed when accessing the settings.',
		pinMinValue: 'PIN must be at least 1000',
		changePin: 'Change PIN',
		disablePinBtn: 'Disable PIN',
		disablePinLock: 'Disable PIN Lock',
		newPinLock: 'New PIN Lock',
		searchByPatientNameMrn: 'Search by patient name or MRN',
		talkToPatientDescription: 'When this is enabled, the user will be able to talk to patient',
		viewPatientDescription: 'When this is enabled, the user will be able to view patient',
		checkFeatureAvailability: 'Please reach out to your administrator to have this feature available',
		badgeId: 'Badge Id',
		hillRomId: 'Hill Rom Id',
		userPicture: 'User Picture',
		badgeAuthorization: 'Badge Authorization',
		hl7v2: 'HL7 v2',
		hl7v2Description: 'When this is enabled, bed definition will be shown with the needed sections.',
		enablingPrivacyMode:
			'Enabling this function will blur your patient view for the selected time. Please enter the time you would like to blur your screen or select from the predefined options.',
		enterSelectTimer: 'Enter or select timer in minutes.',
		writeWholeNumber: 'Please enter a number between 1 and 60.',
		badgeDeleteMsg: 'Are you sure you want to deactivate this employee?',
		isActive: 'Is Active',
		badgeStatus: 'Badge Status',
		aspectRatio: 'Aspect Ratio',
		badgingAccess: 'Badging Access',
		centrakBadge: 'Centrak Badge',
		proxBadge: 'Prox Badge',
		overviewTab: 'When this is enabled, Overview Tab will be available.',
		converationTab: 'When this is enabled, Conversation Tab will be available.',
		filesTab: 'When this is enabled, Files Tab will be available.',
		healthTab: 'When this is enabled, Health Tab will be available.',
		physicalTherapyTab: 'When this is enabled, Physical Therapy Tab will be available.',
		visitTab: 'When this is enabled, Visits Tab will be available.',
		alertHistoryTab: 'When this is enabled, Alert History Tab will be available.',
		activate: 'Activate',
		moreParticipants: 'More participants',
		clickToViewParticipants: 'Click to view participants',
		currentlyInThisCall: 'Currently in this call',
		regeneratePin: 'Regenerate PIN',
		hide: 'Hide',
		pca: 'PCA',
		roomInfo: 'Room Info',
		pain: 'Pain',
		painLevel: 'Pain Level',
		manageablePainLevel: 'Manageable Pain Level',
		roomServiceNumber: 'Room Service #',
		isolations: 'Isolations',
		patientInfoDescription: 'When this is enabled, nurses will see "Patient Info" as a box within the room view',
		monitoringWhiteboardDescription:
			"When this is enabled, providers will see 'Whiteboard' as a box within the room view and be able to update the patient's Whiteboard from Monitoring or Ambient Monitoring.",
		listOfParticipants: 'List of Participants',
		participant: 'Participant',
		userAutoGeneratedPin: 'User AutoGenerated PIN',
		autoGeneratedPinDesc:
			'When this is enabled a PIN autogenerated from the system will be available under user management to interact with the whiteboard.',
		podcastsWidget: 'Podcasts',
		podcastsWidgetDescription: 'Podcasts Library: A catalog of podcasts covering various topics.',
		gamesWidget: 'Games',
		gamesWidgetDescription: 'Games: A list of different games apps.',
		hdmiInWidget: 'HDMI-IN',
		hdmiInWidgetDescription: 'HDMI-IN: Play content from the set-top box or other streaming devices.',
		roomControlsWidget: 'Room Controls',
		roomControlsWidgetDescription: 'Room Control: Enable the patient to use room controls.',
		settingsWidget: 'Settings',
		settingsWidgetDescription: 'Settings: Options for customizing your Whiteboard experience.',
		whiteboardPiPWidget: 'Whiteboard PiP',
		whiteboardPiPWidgetDescription:
			'Whiteboard PiP: Playing a selection of diverse TV channels across genres in a picture-in-picture format.',
		transferOwnershipMessage: 'You are now the owner of this call.',
		goingHome: 'Going Home',
		rideConfirmed: 'Is Ride Confirmed',
		healthSummary: 'Health Summary',
		diet: 'Diet',
		bradenScore: 'Braden Score',
		sensoryPerceptions: 'Sensory Perceptions',
		skinMoisture: 'Skin Moisture',
		mobility: 'Mobility',
		nutrition: 'Nutrition',
		frictionShear: 'Friction & Shear',
		within24Hours: 'Within 24 hours',
		is2or3days: '2-3 days',
		more3days: 'More than 3 days',
		rideIsConfirmed: 'Ride is confirmed',
		rideIsNotConfirmed: 'Ride is not confirmed',
		familyContact: 'Family Contact',
		pcaPhoneNumber: 'PCA Phone #',
		nursePhoneNumber: 'Nurse Phone #',
		completelyLimited:
			'Completely limited: unresponsive (doesn’t moan, flinch or grasp) to pressure. Limited ability to feel pain over most of body',
		completelyLimitedLabel: 'Completely limited',
		veryLimited: 'Very limited: Response only to painful stimuli. Cannot communicate the discomfort properly',
		veryLimitedLabel: 'Very limited',
		slightlyLimited: 'Slightly limited: response to verbal commands but feels discomfort in 1 or 2 extremities',
		slightlyLimitedLabel: 'Slightly limited',
		noImpairment: 'No impairment: response to verbal commands, has no sensory deficit',
		noImpairmentLabel: 'No impairment',
		constantlyMoistDesc: '1 - Constantly moist: skin is almost constantly kept moist',
		constantlyMoist: 'Constantly moist',
		veryMoistDesc: '2 - Very moist: skin is often moist',
		veryMoist: 'Very moist',
		occasionallyMoistDesc: '3 - Occasionally moist: skin is occasionally moist, requiring a change of linen once a day',
		occasionallyMoist: 'Occasionally moist',
		rarelyMoistDesc: '4 - Rarely moist: the skin is dry',
		rarelyMoist: 'Rarely moist',
		bedfastDesc: '1 - Bedfast: confined to bed',
		bedfast: 'Bedfast',
		chairfastDesc: '2 - Chairfast: the patient has the ability to walk severely limited but cannot bear their own weight',
		chairfast: 'Chairfast',
		walksOccasionallyDesc: '3 - Walks occasionally: can walk for very short distances',
		walksOccasionally: 'Walks occasionally',
		walksFrequentlyDesc: '4 - Walks frequently: walks outside the room at least twice a day and can walk without help',
		walksFrequently: 'Walks frequently',
		completelyImmobileDesc: '1 - Completely immobile: cannot make even slight movements',
		completelyImmobile: 'Completely immobile',
		veryLimitedDesc: '2 - Very limited: can change body position occasionally but cannot make significant changes independently',
		veryLimitedTitle: 'Very limited',
		slightlyLimitedDesc:
			'3 - Slightly limited: makes body changes independently but doesn’t have the strength to do it frequently',
		slightlyLimitedTitle: 'Slightly limited',
		noLimitationDesc: '4 - No limitation: the patient can change position without assistance',
		noLimitation: 'No limitation',
		veryPoorDesc:
			'1 - Very poor: the patient never eats a complete meal. Takes fluid poorly and cannot eat more than ⅓ of the food',
		probablyInadequateDesc: '2 - Probably inadequate: the patient eats only about ½ of the offered food',
		probablyInadequate: 'Probably inadequate',
		adequateDesc: '3 - Adequate: cannot eat the whole meal',
		adequate: 'Adequate',
		excellentDesc: '4 - Excellent: the patient usually eats a total of 4 or more servings',
		problemDesc: '1 - Problem: Lifting without sliding against sheets is impossible',
		problem: 'Problem',
		potentialProblemDesc: '2 - Potential problem: The patient requires minimum assistance to minimize friction and shear',
		potentialProblem: 'Potential problem',
		noApparentProblemDesc:
			'3 - No apparent problem: the ability to move in bed and chair independently. Ability to maintain a good position in bed and chair.',
		noApparentProblem: 'No apparent problem',
		rateCallQuality: 'Rate Call Quality',
		rateCallQualityDesc: 'This allows participants to provide feedback at the end of the call.',
		poor: 'Poor',
		veryGood: 'Very Good',
		excellent: 'Excellent',
		howWasQuality: 'How was the call quality?',
		rateCall: 'Rate Call',
		excludeCpmPatients: 'Exclude CPM patients',
		removeFromRpm: 'Remove from RPM',
		removePatient: 'Remove patient',
		areYouSureRemovePatient: 'Are you sure you want to remove the patient from the RPM program?',
		veryPoor: 'Very Poor',
		makeHost: 'Make Host',
		noPinGenerate: 'You have no existing PIN. Please generate one.',
		missedCallsNotifications: 'Missed Calls Notifications',
		missedCallFrom: 'Missed call from',
		questionProvider: 'Question for your provider',
		safetyPatientNeeds: 'Safety Alerts/Patients needs',
		newMedicationAndSideEffects: 'New Medication and side effects',
		whatMattersToMe: 'What matters to me',
		planForToday: 'Plan for today',
		painMedAvailable: 'Pain Med Available',
		lastDoseGiven: 'Last dose given',
		nextDose: 'Next dose',
		generatePin: 'Generate PIN',
		aiDetectionDrawingsDescription:
			'If this is enabled, the sitter will see drawings around people and objects detected by AI model.',
		soundOnAiAlertDescription:
			'If this is enabled, the sitter will hear an alert sound when an AI alert is triggered. The sound will be played on the device they are using to monitor the patients.',
		caseManager: 'Case Manager',
		caseManagerPhone: 'Case Manager Phone',
		showStats: 'Show statistics',
		mrn: 'MRN',
		sharedView: 'Handover Session',
		sharedBy: 'Handover by {value1} {value2}',
		logisticsDescription: 'When this is enabled, the {value} will be able to see and use the logistics tab.',
		thisMonth: 'This month',
		thisWeek: 'This week',
		sharedInChat: 'Shared in chat',
		handoverTo: 'Handover to',
		selectSitterNurse: 'Select the sitter/nurse',
		handoverSession: 'Handover Session',
		mobileApp: 'Mobile App',
		noSnooze: 'No snooze',
		triggerAlertsMoreThanOnePerson: 'Trigger alerts if more than 1 person is in the room',
		thresholdValues: 'Thresholds',
		thresholdsForRailDown: 'Thresholds for Rail Down',
		thresholdsForRailUp: 'Thresholds for Rail Up',
		aiModelCheckTimer: 'AI Model Check timer',
		triggerAlertsRailsCovered: 'Trigger alerts if the rails are covered',
		setAsDefault: 'Set as default',
		enterNumber: 'Enter number',
		writeAndClickEnter: 'Write and click Enter',
		selectOneCheckbox: 'Please select at least one option, with only one as default',
		provideThreshold: 'Please provide a value for each of the threshold values',
		pleaseSelectOption: 'Please select one option',
		fillRequiredFieldsAi: 'Please fill the required fields on AI settings tab',
		preferredLanguage: 'Preferred Language',
		updateRpmList: 'There are new updates available for RPM patients list',
		roundingWhiteboardDescription:
			"When this is enabled, the option to view and update the patient's Whiteboard during the call will be displayed.",
		sleepScheduleTitle: 'Sleep Schedule Time',
		sleepScheduleDescription:
			'Sleep Schedule automatically turns off the display based on the below settings, and during this period it can be turned on taping on the screen. The screen will go back to sleep based on the screen timeout.',
		addSleepSchedule: 'Add Sleep Schedule',
		bodyPositionToSendAlert: 'Body position to send alerts for {value}',
		side: 'Side',
		down: 'Down',
		inactiveTime: 'Inactive time notice timer',
		aiAlertsDescription: 'When this is enabled, overview data for AI alerts based on use cases will display.',
		downloadButtonAtPatientReport: 'Download Button at Patient Report',
		downloadBtnPatientReportDesc:
			'When the feature flag is enabled, the Admin/Super User can download the report for a specific patient.',
		dashboardHandHygiene: 'Hand Hygiene',
		hHDashboardDesc: 'When the feature flag is enabled, Admin/Super User will be able to see data about Hand Hygiene.',
		liveCaptions: 'Live Captions',
		liveCaptionsEmptyMessage: 'Participants need to talk in order to generate live captions.',
		scheduleNightVision: 'Schedule Night Vision',
		removeSchedule: 'Remove Schedule',
		nightVisionError: 'Please fill out start and end time to continue!',
		nightVisionInfo: 'The times displayed reflect the local time zone of room',
		assignedSessionFrom: 'You have an assigned session from',
		disregardOpenHospital: 'If you would like to disregard it and monitor other rooms, please open a hospital,',
		chooseDepartmentFloorRoom: 'choose the department, click on a floor, and select a room.',
		whiteboardTooltip: "This option will enable you to view and edit the patient's whiteboard.",
		callsNotifications: 'Calls Notifications',
		answeredCalls: 'Answered Calls',
		missedCalls: 'Missed Calls',
		callBack: 'Call Back',
		openSession: 'Open Session',
		silentModeDescription:
			'When this is enabled, we are able to turn AI on without monitoring the patient and receive alerts on a silent mode for training and reporting purposes.',
		whiteboardDisplayControl: 'Whiteboard Display Control ',
		selectPatientPosition: 'Select patient position to send alert',
		patientPositions: 'Patient Positions',
		sendPatientPositionAlert: 'For which patient position to trigger an alert',
		upInAChair: 'Up in a Chair',
		supine: 'Supine',
		prone: 'Prone',
		lateral: 'Lateral',
		screenTimeout: 'Screen Timeout',
		useRestroom: 'Do you need to use the restroom?',
		needDrink: 'Do you need a drink?',
		sorryDidNotHear: 'I am sorry, I did not hear you. Please repeat your answer.',
		needMeToRepeat: 'Do you need me to repeat what I said?',
		allowPatientSignUp: 'Allow patient sign up',
		auditLog: 'Audit Log',
		waitingForResult: 'Waiting for Result',
		galaxyWatch: 'Galaxy Watch',
		monitoringFeature: 'Monitoring Feature',
		clientThemeColor: 'Client Theme Color',
		twoFactorProviderType: 'Two Factor Provider Type',
		lengthUnit: 'Length Unit',
		patientPrivateMode: 'Patient Private Mode',
		nICUChat: 'NICU Chat',
		rpm: 'RPM',
		bloodGlucoseUnit: 'Blood Glucose Unit',
		heightUnit: 'Height Unit',
		temperatureUnit: 'Temperature Unit',
		weightUnit: 'Weight Unit',
		talkToPatientRoute: 'Talk to Patient Route',
		monitoringRoute: 'Monitoring Route',
		viewPatientRoute: 'View Patient Route',
		switchE2EE: 'Switch E2EE',
		pTZCam: 'PTZ Cam',
		helloCameraName: 'Hello Camera Name',
		externalCameraName: 'External Camera Name',
		multipartyCalls: 'Multiparty Calls',
		roundingSummary: 'Rounding Summary',
		screensharing: 'Screensharing',
		roundingNightVision: 'Rounding Night Vision',
		digitalClinicianMode: 'Digital Clinician Mode',
		liveTVWidget: 'Live TV Widget',
		roundingCareEventsForSafetyCompanions: 'Rounding Care Events For Safety Companions',
		healthSystemDefaultBackground: 'Health System Default Background',
		callBackground: 'Call Background',
		providerMicrophone: 'Provider Microphone',
		helloDefaultBackground: 'Hello Default Background',
		patientAdtDashboard: 'Patient ADT Dashboard',
		closedCaptions: 'Closed Captions',
		virtualPatientAdmission: 'Virtual Patient Admission',
		manualPatientAdmission: 'Manual Patient Admission',
		ehrPatientAdmission: 'EHR Patient Admission',
		isAudioCall: 'Is Audio Call',
		alertsCenterDashboard: 'Alerts Center Dashboard',
		aiDetectionDrawings: 'AI Detection Drawings',
		messageDoctor: 'Message Doctor',
		staffDuress: 'Staff Duress',
		allowDataCollection: 'Allow Data Collection',
		verbalRedirection: 'Verbal Redirection',
		hillRoom: 'Hill Room',
		patientsOverview: 'Patients Overview',
		patientsFiles: 'Patients Files',
		patientsHealth: 'Patients Health',
		patientsPhysicalTherapy: 'Patients Physical Therapy',
		patientsVisits: 'Patients Visits',
		patientsAlertHistory: 'Patients Alert History',
		isNewUserExperience: 'Is New User Experience',
		isExperienceHealthSystemChangeable: 'Is Experience Health System Changeable',
		providerCentricReport: 'Provider Centric Report',
		patientCentricReport: 'Patient Centric Report',
		userAutoGeneratedPIN: 'User Auto Generated PIN',
		vcpRoleDisplayName: 'VCP Role Display Name',
		patientInfoBox: 'Patient Info Box',
		whiteboardBox: 'Whiteboard Box',
		aiDetectionDrawing: 'AI Detection Drawing',
		soundOnAIAlert: 'Sound on AI Alert',
		patientAdmission: 'Patient Admission',
		whiteboardWidgetSettings: 'Whiteboard Widget Settings',
		monitoringMessage: 'Monitoring Message',
		whiteboardPiP: 'Whiteboard PiP',
		whiteboardBoxRounding: 'Whiteboard Box Rounding',
		careNotifications: 'Care Notifications',
		ir: 'IR',
		ct: 'CT',
		xray: 'XRAY',
		orAcronym: 'OR',
		swallowTest: 'Swallow Test',
		mri: 'MRI',
		inHallway: 'In Hallway',
		cathLab: 'Cath Lab',
		restroom: 'Restroom',
		restroomByRn: 'Restroom by RN',
		s529: 'S529',
		j516: 'J516',
		dc: 'D/C',
		nuclearMed: 'Nuclear Med.',
		epLab: 'EP LAB',
		liveSitterUntilAm: 'Live Sitter until AM',
		s732: 'S732',
		giLab: 'GI LAB',
		tee: 'TEE',
		pegTubePlacement: 'PEG Tube Placement',
		moveTo527: 'Move to 527',
		walkingWithNurse: 'Walking with Nurse',
		eeg: 'EEG',
		additionalDiet: 'Additional Diet',
		discontinueMonitoring: 'Discontinue Monitoring',
		behaviorsImproved: 'Behaviors Improved',
		requiresOneOnOneObservation: 'Requires One-on-One Observation',
		leftAma: 'Left AMA',
		familyPatientRefuses: 'Family/Patient Refuses',
		shiftChange: 'Shift Change',
		stopVideoMonitoring: 'Please select the reason why you want to stop monitoring this patient.',
		closingMonitoring: 'Closing Video Monitoring',
		discontinuePatientMonitoring: 'Discontinue Patient Monitoring',
		discontinuingMonitoringReason: 'Reason of discontinuing monitoring',
		transferToAnotherUnit: 'Transfer to another unit',
		selectedIncorrectRoom: 'Incorrect patient; switching to the correct patient',
		technicalIssue: 'Room disconnected due to technical issues',
		patientDischargedDiscontinue: 'Patient discharged. Please discontinue monitoring.',
		patientTransferredDiscontinue: 'Patient transferred. Please discontinue monitoring.',
		patientDischarged: 'Patient discharged',
		workflow: 'Workflow',
		aiNotifications: 'AI Notifications',
		whiteboardControls: 'Whiteboard Controls',
		whiteboardControlsDesc: 'When this is enabled, providers will be able to control the whiteboard from the web interface',
		discontinueMonitoringDescription:
			'When this is enabled, providers will have to select a reason for discontinuing monitoring.',
		roomSign: 'Room sign',
		roundingRoomSignDescription:
			"When this is enabled, the option to view and update the patient's Room Sign during and outside the call will be displayed.",
		careConsiderations: 'Care Considerations',
		selectLevelofBradenScore: 'Select the level of Braden Score',
		hearingImpaired: 'Is the patient Hearing Impaired?',
		interpreterNeeded: 'Is an Interpreter needed?',
		whatLanguageIsNeeded: 'What language is needed?',
		noWhiteboardAssigned: 'No companion device assigned into the room',
		invitedParticipants: 'Invited Participants',
		cancelAllInvites: 'Cancel all invites',
		cancelInviteDescription: 'When this is enabled, the user will be able to cancel a single invite, or all invites.',
		invalidConferenceInvitation: 'This invitation is no longer valid.',
		invalidConferenceLink: 'This link is no longer valid.',
		invalidConferenceParameters: 'Conference information is incorrect.',
		addPeople: 'Add People',
		sentInvites: 'Sent Invites',
		multipartyCalling: 'Multiparty calling',
		hospitalAtHome: 'Hospital at Home',
		patientNameDescription: 'When this is enabled, nurses will see "Patient Name" as an input within the Patient Info section.',
		patientAgeDescription: 'When this is enabled, nurses will see "Patient Age" as an input within the Patient Info section.',
		nurseNameNumberDescription:
			'When this is enabled, nurses will see "Nurse Name/Number" as an input within the Patient Info section.',
		nurseAssistantNameNumberDescription:
			'When this is enabled, nurses will see "Nurse Assistant Name/Number" as an input within the Patient Info section.',
		tlNameNumberDescription:
			'When this is enabled, nurses will see "Team Leader Name/Number" as an input within the Patient Info section.',
		primaryAdverseEventDescription:
			'When this is enabled, nurses will see "Primary Adverse Event" as an input within the Patient Info section.',
		primaryPatientConditionDescription:
			'When this is enabled, nurses will see "Primary Patient Condition" as an input within the Patient Info section.',
		primaryPatientRiskFactorDescription:
			'When this is enabled, nurses will see "Primary Patient Risk Factor" as an input within the Patient Info section.',
		preferredLanguageDescription:
			'When this is enabled, nurses will see "Preferred Language" as an input within the Patient Info section.',
		titleBarNotesDescription:
			'When this is enabled, nurses will see "Title Bar Notes" as an input within the Patient Info section.',
		additionalPatientNotesDescription:
			'When this is enabled, nurses will see "Additional Patient Notes" as an input within the Patient Info section.',
		patientInfoWidget: 'Patient Info Widget',
		patientInfoConfigsTitle:
			'The following fields will be shown within Patient Info Box when turning them on. Please turn on the fields you would like to show.',
		imbalance: 'Imbalance',
		imbalanceType: 'Imbalance Type',
		recentMetrics: 'Recent Metrics',
		orders: 'Orders',
		schedule: 'Schedule',
		painScale: 'Pain Scale',
		assignedNurse: 'Assigned Nurse',
		product: 'Product',
		sponsor: 'Sponsor',
		payer: 'Payer',
		phaseOfCare: 'Phase of Care',
		admitDate: 'Admit Date',
		appointmentWith: 'Appointment with',
		timeADay: 'time a day',
		dateOfBirthAge: 'Date of Birth (Age)',
		timeAday: 'time a day',
		hospitalAtHomeDescription:
			'Enabling this feature will allow caregivers to view their patients at home, providing a customizable interface to manage patient information, treatment plans, and communication effectively.',
		currentPosition: 'Current Position',
		configuredFor: 'Configured For',
		configuredPositions: 'Configured Positions',
		patientPositionMonitoring: 'Patient Position',
		detailedReport: 'Detailed Report',
		pressureInjuryStats: 'Pressure Injury Stats',
		standing: 'Standing',
		rightLateral: 'Right Lateral',
		leftLateral: 'Left Lateral',
		pressureInjuryHistoryDescription: 'Person detected inactive for {value1} in {value2} position.',
		pressureInjuryHistoryTime: 'From {value1} to {value2}',
		noHistoryFound: 'No history was recorded for this patient.',
		writeNewVerbalRedirection: 'Write new Verbal Redirection...',
		atLeastOne: 'At least 1 must be selected.',
		onState: 'ON',
		offState: 'OFF',
		isEnabledMessage: '{value1} turned {value2} {value3}',
		sensitivityOptionsMessage: '{value1} updated the sensitivity from {value2} to {value3} for {value4}',
		snoozeTimeMessage: '{value1} updated the snooze timer from {value2} to {value3} for {value4}',
		notificationOptionsMessage: '{value1} updated alerts from {value2} to {value3} for {value4}',
		statAlarmMessage: '{value1} updated stat alarm from {value2} to {value3} for {value4}',
		railsOptionsMessage: '{value1} updated {value2} from {value3} to {value4}',
		pressureInjuryPositionsMessage: '{value1} updated patient positions from {value2} to {value3}',
		ivBagValueMessage: '{value1} updated IV bag warning value from {value2} to {value3}',
		pressureInjuryTimerMessage: '{value1} updated pressure injury timer from {value2} to {value3}',
		atLeastOneMustBeSet: 'At least one must be set.',
		sleepScheduleTimeError: 'End Time must be at least 1 minute after Start Time.',
		allHealthSystems: 'All Health Systems',
		tomorrow: 'Tomorrow',
		never: 'Never',
		gobConfigurationMessage: '{value1} configuration: Sensitivity {value2}',
		alertConfigurationMessage: '{value1} configuration: {value2}',
		compliant: 'Compliant',
		nonCompliant: 'Non Compliant',
		totalIdentifications: 'Total Identifications',
		method: 'Method',
		evidence: 'Evidence',
		handHygiene: 'Hand Hygiene',
		rn: 'RN',
		primaryDisplayLogo: 'Primary Display Logo',
		primaryDisplayLogoDescription:
			'Upload the main logo for your hospital. This will be displayed prominently across the platform.',
		secondaryDisplayLogo: 'Secondary Display Logo',
		secondaryDisplayLogoDescription: 'Upload a secondary logo to be used in specific areas or displays.',
		compliance: 'Compliance',
		subjectIdentifications: 'Subject Identifications',
		addTranslationService: 'Add Translation Service',
		healthSystemMustBeSet: 'Health system must be set',
		evidenceReport: 'Evidence Report',
		stats: 'Stats',
	},
};

export default keys;
