import React, { useContext, useEffect, useRef, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import EmptyState from 'components/EmptyState.jsx';
import { getRecordingsPerMeasurement } from 'api/stethoscope.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { ExamType, UserRoles } from 'constants/enums.js';
import { HeartExaminationPoints, LungsExaminationPoints } from 'constants/examinations.js';
import Alert from 'components/Alert.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SelectedHeartLungRecording from 'containers/HealthMeasurements/SelectedHeartLungRecording.jsx';
import AvailableHeartLungsRecordings from 'containers/HealthMeasurements/AvailableHeartLungsRecordings.jsx';
import Button from 'components/Button.jsx';

const HeartLungsDetails = props => {
	const measurementRecentElement = useRef(null);
	const audioRef = useRef(null);
	const canvasRef = useRef(null);
	const intervalRef = useRef(null);
	const canvasParentRef = useRef(null);
	const socket = useContext(SocketContext);
	const [recordings, setRecordings] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [canvasHeight, setCanvasHeight] = useState(0);
	const [canvasWidth, setCanvasWidth] = useState(0);
	const [selectedRecording, setSelectedRecording] = useState(null);
	const [isFrontView, setIsFrontView] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isGraphShown, setIsGraphShown] = useState(false);
	const [playAudioListener, setPlayAudioListener] = useState(false);
	const [isSameRecord, setIsSameRecord] = useState(false);
	const [hasDimensions, setHasDimensions] = useState(false);
	const [initialPlaying, setInitialPlaying] = useState(false);
	const [isPlayedFromList, setIsPlayedFromList] = useState(false);
	const [error, setError] = useState(null);
	const [selectedExaminationPoint, setSelectedExaminationPoint] = useState(null);

	useEffect(() => {
		getStethoscopeMeasurements(true);
	}, []);

	useEffect(() => {
		const updateCanvasDimensions = () => {
			if (!canvasParentRef.current) {
				return;
			}
			setCanvasHeight(canvasParentRef.current.clientHeight);
			setCanvasWidth(canvasParentRef.current.clientWidth);
		};
		window.addEventListener('resize', updateCanvasDimensions);
		clearInterval(intervalRef.current);
		return () => window.removeEventListener('resize', updateCanvasDimensions);
	}, []);

	useEffect(() => {
		if (hasDimensions) {
			setPlayAudioListener(true);
		}
	}, [hasDimensions]);

	useEffect(() => {
		if (playAudioListener) {
			playPauseAudioListeners();
		}
	}, [playAudioListener]);

	useEffect(() => {
		if (!audioRef.current) {
			return;
		}
		if (isPlaying && initialPlaying) {
			intervalRef.current = setInterval(drawCanvas, 150);
		}
		if (!isPlaying && initialPlaying) {
			clearInterval(intervalRef.current);
		}
	}, [isPlaying, initialPlaying]);

	useEffect(() => {
		if (selectedRecording && !isPlaying && !isPlayedFromList) {
			drawCanvas();
		}
	}, [selectedRecording, isPlaying, isPlayedFromList]);

	useEffect(() => {
		if (!isPlayedFromList || !audioRef.current) {
			return;
		}
		if (isPlayedFromList && isPlaying) {
			audioRef.current.load();
			audioRef.current.play();
		}
		if (isPlayedFromList && !isPlaying) {
			audioRef.current.pause();
		}
		if (isPlayedFromList && !isSameRecord) {
			clearInterval(intervalRef.current);
			audioRef.current.currentTime = 0;
		}
		setCanvasWidth(canvasParentRef.current.clientWidth);
		setCanvasHeight(canvasParentRef.current.clientHeight);
		drawCanvas();
	}, [isPlayedFromList, isSameRecord, isPlaying]);

	useEffect(() => {
		const stethoscopeRecodingListener = data => {
			if (props.conferenceId === data?.conferenceId) {
				setRecordings(prevState => prevState.concat(data));
			}
		};
		stethoscopeRecodingListener();
		socket.on(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, stethoscopeRecodingListener);
		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, stethoscopeRecodingListener);
		};
	}, [socket, props.conferenceId]);

	const getStethoscopeMeasurements = async isInit => {
		setIsLoading(true);
		setError(null);
		const params = {
			patientId: props.patientId,
			conferenceId: props.selectedMeasurement.conferenceId,
			examType: props.isHeartMeasurement ? ExamType.HEART : ExamType.LUNGS,
			pageIndex,
		};
		const response = await getRecordingsPerMeasurement(params);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		const stethoscopeRecordings = response.stethoscopeRecordings.map((recording, index) => ({
			...recording,
			indexKey: props.totalMeasurements - (recordings.length + index).toString().padStart(5, '0'),
		}));
		setHasReachedEnd(stethoscopeRecordings.length < 10);
		setRecordings(prevState => (isInit ? stethoscopeRecordings : prevState.concat(stethoscopeRecordings)));
		setSelectedRecording(stethoscopeRecordings.length > 0 ? stethoscopeRecordings[0] : null);
		setIsLoading(false);
		if (isInit) {
			setHasDimensions(true);
			setPageIndex(prevState => prevState + 1);
		} else {
			setHasDimensions(false);
		}
	};

	const playPauseAudioListeners = () => {
		if (!audioRef.current) {
			return;
		}
		drawCanvas();
		audioRef.current.onplaying = () => {
			setIsPlaying(true);
		};
		audioRef.current.onpause = () => {
			setIsPlaying(false);
		};
		setInitialPlaying(true);
	};

	const drawCanvas = () => {
		if (!canvasRef.current) {
			return;
		}
		const ctx = canvasRef.current.getContext('2d');
		const canvas = canvasRef.current;
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		drawWaveForm(ctx, canvas.width);
	};

	const drawWaveForm = (ctx, width) => {
		ctx.beginPath();
		const ninthOfCanvasHeight = 0.0907;
		const rectangularSize = canvasRef.current.height * ninthOfCanvasHeight;
		for (let i = 0; i < width; i += rectangularSize) {
			ctx.moveTo(i, canvasRef.current.height);
			ctx.moveTo(width, i);
		}
		ctx.strokeStyle = '#e2ebf2';
		ctx.stroke();
		// draw waveform
		ctx.beginPath();
		ctx.moveTo(0, canvasRef.current.height / 2);
		for (let i = 10; i < width; i += 2.5) {
			const minimumRandom = canvasRef.current.height * 0.25;
			const y = getRandomIntBetween(minimumRandom, canvasRef.current.height - minimumRandom);
			ctx.lineTo(i, y);
		}
		ctx.strokeStyle = '#247ef9';
		ctx.stroke();
	};

	const getRandomIntBetween = (min, max) => {
		const minInt = Math.ceil(min);
		const maxInt = Math.floor(max);
		return Math.floor(Math.random() * (maxInt - minInt)) + minInt;
	};

	const handleScroll = event => {
		if (hasReachedEnd) {
			return;
		}
		const difference =
			measurementRecentElement.current.scrollHeight -
			Math.ceil(event.target.scrollTop) -
			measurementRecentElement.current.clientHeight;
		if (difference < 50 && difference > -10) {
			measurementRecentElement.current.scrollBottom += 100;
			getStethoscopeMeasurements();
			setPageIndex(prevState => prevState + 1);
		}
	};

	const previousRecording = () => {
		const indexFound = recordings.findIndex(recoding => recoding.indexKey === selectedRecording.indexKey);
		if (indexFound !== 0) {
			setSelectedRecord(recordings[indexFound - 1]);
		}
	};

	const nextRecording = () => {
		const indexFound = recordings.findIndex(recoding => recoding.indexKey === selectedRecording.indexKey);
		if (indexFound !== recordings.length - 1) {
			setSelectedRecord(recordings[indexFound + 1]);
		}
	};

	const setSelectedRecord = recording => {
		if (recording.indexKey === selectedRecording.indexKey) {
			return;
		}
		audioRef.current.pause();
		audioRef.current.load();
		audioRef.current.currentTime = 0;
		setSelectedRecording(recording);
		setIsPlaying(false);
		setIsFrontView(recording.examinationPoint < 15);
		setSelectedExaminationPoint(recording.examinationPoint);
	};

	const playFromList = recording => {
		setIsGraphShown(true);
		setIsPlayedFromList(true);
		setIsFrontView(recording.examinationPoint < 15);
		setSelectedExaminationPoint(recording.examinationPoint);
		if (recording.indexKey === selectedRecording?.indexKey) {
			setIsPlaying(prevState => !prevState);
			setIsSameRecord(true);
		} else {
			setSelectedRecording(recording);
			setIsPlaying(true);
			setIsSameRecord(false);
		}
	};

	const openHealthData = () => {
		const userRole = getUserRole();
		let link = '';
		if (userRole === UserRoles.PATIENT) {
			link = `health-data/${props.selectedMeasurementType.type}Stethoscope`;
		} else {
			link = `/patients/${props.patientId}/health-data/${props.selectedMeasurementType.type}Stethoscope`;
		}
		window.open(link, '_blank');
	};

	const examinationPoints = props.isHeartMeasurement ? HeartExaminationPoints : LungsExaminationPoints;

	return (
		<div className='patient-iot-history-data' onScroll={handleScroll} ref={measurementRecentElement}>
			<div className='flex'>
				<Button
					className='visit-btn stethoscope back-btn cursor-pointer'
					onClick={() => props.setMeasurement(false)}
					imgIcon={`${healthCareCdnUrl}back-arrow.svg`}
					alt='back arrow icon'
				/>
				<div className='flex flex-space-between full-width'>
					<h2 className='stethoscope-measurements-title'>
						{formattedDate(props.selectedMeasurement?.dateTime)} {translate('measurement')}
					</h2>
					{props.isFromCall && (
						<div className='flex flex-align-center header-request-btn'>
							<Button onClick={openHealthData} text={translate('seeMoreData')} />
						</div>
					)}
				</div>
			</div>

			{recordings.length > 0 && (
				<>
					<SelectedHeartLungRecording
						audioRef={audioRef}
						isLoading={isLoading}
						canvasRef={canvasRef}
						isFrontView={isFrontView}
						isGraphShown={isGraphShown}
						canvasWidth={canvasWidth}
						canvasHeight={canvasHeight}
						canvasParentRef={canvasParentRef}
						examinationPoints={examinationPoints}
						selectedRecording={selectedRecording}
						selectedExaminationPoint={selectedExaminationPoint}
						isHeartMeasurement={props.isHeartMeasurement}
						previousRecording={previousRecording}
						nextRecording={nextRecording}
						setIsFrontView={setIsFrontView}
						setSelectedExaminationPoint={setSelectedExaminationPoint}
					/>
					<AvailableHeartLungsRecordings
						isPlaying={isPlaying}
						isLoading={isLoading}
						recordings={recordings}
						hasReachedEnd={hasReachedEnd}
						selectedRecording={selectedRecording}
						examinationPoints={examinationPoints}
						playFromList={playFromList}
					/>
				</>
			)}
			{!isLoading && recordings.length === 0 && (
				<div className='empty-state-wrapper'>
					<EmptyState title={translate('noData')} image='health-data.svg' />
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default HeartLungsDetails;
