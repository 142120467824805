import { deleteRpmProgram, getRpmPrograms } from 'api/rpm.js';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { RpmDevices, patchDeviceDetails, rpmMeasurements } from 'constants/rpm.js';
import AddEditRpmProgram from 'containers/Rpm/AddEditRpmProgram.jsx';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const ManageRpmPrograms = () => {
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [selectedRpmProgram, setSelectedRpmProgram] = useState(null);
	const [rpmPrograms, setRpmPrograms] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isAddOrEditOpen, setIsAddOrEditOpen] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [currentDeviceId, setCurrentDeviceId] = useState(null);
	const [showMore, setShowMore] = useState(false);
	const [isListUpdated, setIsListUpdated] = useState(false);
	const [error, setError] = useState(null);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(null);
	const devicesRefs = useRef([]);
	const intl = useIntl();
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);

	const getHeaders = () => [
		{ id: 'rpmProgram', title: translate('program') },
		{ id: 'dateCreated', title: translate('dateCreated') },
		{ id: 'devices', title: translate('devices') },
		{ id: 'actions', title: '' },
	];

	useEffect(() => {
		getRpmProgramList(pagination.pageSize, pagination.pageIndex);
	}, [pagination.pageSize, pagination.pageIndex, isListUpdated, selectedHealthSystem]);

	const getRpmProgramList = async (pageSize, pageIndex) => {
		setIsLoading(true);
		const response = await getRpmPrograms({ pageSize, pageIndex, healthSystemId: selectedHealthSystem?.value });
		if (response.error) {
			setError(response.error.message);
		} else {
			setRpmPrograms(response.rpmPrograms);
			setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
		}
		setIsLoading(false);
		setError(null);
	};

	const handleDeleteRpmProgram = async () => {
		setIsBtnLoading(true);
		setIsListUpdated(false);
		const response = await deleteRpmProgram({ id: selectedRpmProgram.id });
		if (response.error) {
			setError(response.error.message);
		} else {
			setError(null);
			setIsListUpdated(true);
		}
		setIsBtnLoading(false);
		setIsDeleteOpen(false);
	};

	const useCloseClick = (refs, callback) => {
		const handleClick = event => {
			if (refs?.current?.length > 0 && !refs?.current?.some(ref => ref.contains(event.target))) {
				callback();
			}
		};
		useEffect(() => {
			document.addEventListener('click', handleClick);
			return () => {
				document.removeEventListener('click', handleClick);
			};
		});
	};

	useCloseClick(devicesRefs, () => {
		setShowMore(false);
		setCurrentDeviceId(null);
	});

	const addToRefs = el => {
		if (el && !devicesRefs.current.includes(el)) {
			devicesRefs.current.push(el);
		}
	};

	const transformRows = () => {
		if (rpmPrograms.length === 0) {
			return [];
		}
		return rpmPrograms.map(item => ({
			rpmProgram: item.name,
			dateCreated: formattedDate(item.createdAt),
			devices: getRpmDevices(item),
			actions: getEditButtons(item),
		}));
	};

	const getMeasurement = measurementType => rpmMeasurements.find(item => item.type === measurementType);

	const getDevicesGroupedByMeasurementType = array =>
		array.reduce((acc, item) => {
			const foundMeasurement = acc.find(measurement => measurement.measurementType === item.measurementType);
			if (foundMeasurement) {
				foundMeasurement.devices.push({ deviceId: item.deviceId });
			} else {
				acc.push({
					measurementType: item.measurementType,
					devices: [{ deviceId: item.deviceId }],
				});
			}
			return acc;
		}, []);

	const getRpmDevices = item => {
		const hasPatch = item.devices.some(device => device.deviceId === RpmDevices.LIFE_SIGNALS_PATCH);
		const iHealthDevices = item.devices.filter(
			device => device.deviceId !== RpmDevices.LIFE_SIGNALS_PATCH && Object.values(RpmDevices).includes(device.deviceId)
		);
		const devicesCount = hasPatch ? iHealthDevices.length + 1 : iHealthDevices.length;
		const filteredArray = getDevicesGroupedByMeasurementType(iHealthDevices);

		return (
			<>
				<div
					className='rpm-devices'
					onClick={() => {
						setShowMore(prevState => !prevState);
						setCurrentDeviceId(item.id);
					}}
					ref={addToRefs}>
					{devicesCount} {intl.formatMessage({ id: 'devices' })}
					<span className='material-icons'>{showMore && item.id === currentDeviceId ? 'expand_less' : 'expand_more'}</span>
				</div>
				{showMore && item.id === currentDeviceId && (
					<div className='position-relative'>
						<div className='rpm-devices-wrapper'>
							{filteredArray.map(measurement => (
								<div className='rpm-devices-details'>
									<div className='flex'>
										<img src={getMeasurement(measurement.measurementType)?.measurementImg} alt='ico' />
										<p className='rpm-measurement-type'>{translate(`${getMeasurement(measurement.measurementType)?.name}`)}</p>
									</div>
									{measurement.devices.map(element => {
										const foundDevice = rpmMeasurements
											.flatMap(rpm => rpm.devices)
											.find(rpmDevice => rpmDevice.id === element.deviceId);
										return (
											<div>
												<p className='rpm-device'>{foundDevice?.value}</p>
											</div>
										);
									})}
								</div>
							))}
							{hasPatch && (
								<div className='rpm-devices-details'>
									<div className='flex'>
										<img src={patchDeviceDetails?.measurementImg} alt='ico' />
										<p className='rpm-measurement-type'>{translate(`${patchDeviceDetails?.name}`)}</p>
									</div>
									<div>
										<p className='rpm-device'>{translate(patchDeviceDetails?.name)}</p>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</>
		);
	};

	const getEditButtons = item => (
		<div className='wrapped'>
			<Link
				to={{
					pathname: '/add-edit-rpm',
					state: {
						rpmProgramId: item.id,
						healthSystemId: item.healthSystemId,
					},
				}}>
				<span
					className='material-icons-outlined boxed-icon edit-icon'
					data-position='top'
					data-tooltip={intl.formatMessage({ id: 'edit' })}>
					edit
				</span>
			</Link>
			&nbsp;
			<i
				className='material-icons-outlined boxed-icon'
				onClick={() => {
					setIsDeleteOpen(prevState => !prevState);
					setSelectedRpmProgram(item);
				}}
				data-position='top'
				data-tooltip={intl.formatMessage({ id: 'delete' })}>
				delete
			</i>
		</div>
	);

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	const transformArray = array => array.map(item => ({ value: item.id, label: item.name }));

	return (
		<>
			<CustomTable
				isLoading={isLoading}
				headers={getHeaders()}
				rows={isLoading ? [] : transformRows()}
				className='table-break-word'>
				<div className='flex flex-space-between rpm-filter-wrapper'>
					<Select
						value={selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'allHealthSystems' })}
						classNamePrefix='react-select'
						options={transformArray(healthSystems)}
						onChange={setSelectedHealthSystem}
					/>
					<Link to='add-edit-rpm'>
						<Button
							className='button text-transform-none'
							imgIcon={`${healthCareCdnUrl}rpm/plus.svg`}
							onClick={() => {
								setIsAddOrEditOpen(prevState => !prevState);
								setSelectedRpmProgram(null);
							}}
							text={translate('createProgram')}
						/>
					</Link>
				</div>
			</CustomTable>
			{isAddOrEditOpen && <AddEditRpmProgram />}
			{isDeleteOpen && (
				<Modal
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					display={isDeleteOpen}
					position='center'
					onModalSubmit={handleDeleteRpmProgram}
					onModalClose={() => setIsDeleteOpen(false)}
					shouldSubmitOnEnter={false}
					primaryButtonLabel={intl.formatMessage({ id: 'yes' })}
					primaryButtonLoading={isBtnLoading}>
					<Form height={200} className='modal-form-wrapper'>
						<h3>{translate('deleteProgram')}</h3>
						<p>{translate('areYouSureDeleteProgram')}</p>
					</Form>
				</Modal>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			{rpmPrograms.length > 0 && !isLoading && (
				<Pagination
					totalCount={pagination.totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
				/>
			)}
		</>
	);
};

export default ManageRpmPrograms;
