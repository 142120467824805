import React, { useRef, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import classNames from 'classnames';
import { TabPanel } from 'components/Tabs.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getDoctorAssigned } from 'api/doctors.js';
import { getNursesAssigned } from 'api/rpm.js';
import Alert from 'components/Alert.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Button from 'components/Button.jsx';

const CareTeamMembers = ({
	doctorValues,
	nurseValues,
	setFieldValue,
	specialties,
	doctorErrors,
	nurseErrors,
	specialtyValues,
	isAddPatient,
	setDoctorValues,
	setNurseValues,
	programCareTeam,
	setFamilyMemberValues,
	familyMemberValues = [],
	familyMemberErrors = null,
	healthSystemId = null,
}) => {
	const [doctors, setDoctors] = useState([]);
	const [nurses, setNurses] = useState([]);
	const [error, setError] = useState(null);
	const hasReachedEnd = useRef(null);
	const intl = useIntl();
	const NO_SPECIALTY = null;
	const MAX_DR_NURSES = 5;
	const MAX_FAMILY_MEMBERS = 3;

	const transformArray = (array, isSpecialty = false) => {
		const mappedArray = array.map(item => ({
			value: item.userId || item.id,
			label: item.name || `${item.firstName} ${item.lastName}`,
		}));
		if (isSpecialty) {
			mappedArray.unshift({ value: NO_SPECIALTY, label: intl.formatMessage({ id: 'all' }) });
		}
		return mappedArray;
	};

	const getDoctors = async (specialtyId, doctorsList, value = '') => {
		const params = {
			specialtyId,
			search: value,
		};
		const response = await getDoctorAssigned(healthSystemId, params);
		const filteredArr = response.doctors.filter(
			member => !doctorsList.some(doctor => member.id === doctor?.value) && !nurseValues.some(nurse => member.id === nurse?.value)
		);
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		const newOptions = filteredArr.map(user => ({
			value: user.id,
			label: `${user.firstName} ${user.lastName}`,
			email: user.email,
			profilePicture: user.profilePicture,
			specialty: user.specialty?.name,
		}));
		hasReachedEnd.current = response.doctors.length < 10;
		setDoctors(newOptions);
		return newOptions;
	};

	const getNurses = async (nursesList, value = '') => {
		const response = await getNursesAssigned({ healthSystemId, search: value });
		const filteredArr = response.users.filter(
			member =>
				!nursesList.some(nurse => member.userId === nurse?.value) && !doctorValues.some(doctor => member.userId === doctor?.value)
		);
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		const newOptions = filteredArr.map(user => ({
			value: user.userId,
			label: `${user.firstName} ${user.lastName}`,
			email: user.email,
			profilePicture: user.profilePicture,
		}));
		hasReachedEnd.current = response.users.length < 10;
		setNurses(newOptions);
		return newOptions;
	};

	const getSpecialtyValue = (specialtiesList, index) => {
		if (specialtiesList[index]?.specialty?.id === NO_SPECIALTY) {
			return [{ value: NO_SPECIALTY, label: intl.formatMessage({ id: 'all' }) }];
		}
		return transformArray(specialties).filter(option => option.value === specialtiesList[index]?.specialty?.id);
	};

	const isDoctorDisabled = doctorValues.map(member =>
		programCareTeam.some(selectedMember => selectedMember.userId === member?.value)
	);

	const isNurseDisabled = nurseValues.map(member =>
		programCareTeam.some(selectedMember => selectedMember.userId === member?.value)
	);

	const handleDoctorChange = (event, index) => {
		setFieldValue(`doctors[${index}]`, event);
		if (event) {
			doctorValues.push(event);
			setDoctorValues(doctorValues.filter(member => !programCareTeam.some(program => program.userId === member?.value)));
		}
	};

	const handleNurseChange = (event, index) => {
		setFieldValue(`nurses[${index}]`, event);
		if (event) {
			nurseValues.push(event);
			setNurseValues(nurseValues.filter(member => !programCareTeam.some(program => program.userId === member?.value)));
		}
	};

	const handleFamilyMemberChange = (value, index) => {
		const updatedFamilyMembers = [...familyMemberValues];
		updatedFamilyMembers[index] = { value };
		setFieldValue(`familyMembers[${index}].value`, value);
		setFamilyMemberValues(updatedFamilyMembers);
	};

	const formatOptionLabel = data => (
		<div className='flex flex-align-center'>
			<ProfilePicture className='doctor-request-img' profilePicture={data.profilePicture} fullName={data.label} />
			<div className='flex column-direction care-team-info'>
				<span>{data.label}</span>
				<span className='additional-info'>{data.email}</span>
				{data.specialty && <span className='additional-info'>{data.specialty}</span>}
			</div>
		</div>
	);

	const SingleValue = ({ data }) => (
		<div className='flex flex-align-center position-absolute left-10'>
			<ProfilePicture className='doctor-request-img care-team-img' profilePicture={data.profilePicture} fullName={data.label} />
			<span>{data.label}</span>
		</div>
	);

	return (
		<TabPanel>
			<FieldArray name='doctors'>
				{arrayHelpers => (
					<div className={isAddPatient ? '' : 'top-15'}>
						<div className='care-team-wrapper'>
							<div className='flex flex-space-between'>
								<p className='semi-bold'>{translate('doctors')}</p>
								{doctorValues.length < MAX_DR_NURSES && (
									<Button
										className='add-question-btn align-self-end'
										imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
										onClick={() => arrayHelpers.push({ userId: '' })}
										text={translate('addRole', { value: intl.formatMessage({ id: 'doctor' }) })}
									/>
								)}
							</div>
							{isAddPatient && doctorValues.length > 0 && (
								<p className='--gray-color'>
									{translate('addAdditionalMember', {
										value: intl.formatMessage({ id: 'doctors' }).toLowerCase(),
										value2: 5,
									})}
								</p>
							)}
							{doctorValues.map((doctor, index) => (
								<div
									className={classNames(
										'flex flex-align-center flex-space-between bottom-15',
										isDoctorDisabled[index] ? 'disabled-doctor-wrapper' : ''
									)}
									key={doctor?.value}>
									<div className='flex column-direction care-team-select-wrapper'>
										<label className='font-14 semi-bold bottom-5'>{translate('specialty')}</label>
										<Select
											classNamePrefix='custom-select'
											onChange={event => setFieldValue(`specialties[${index}].specialty.id`, event.value)}
											value={getSpecialtyValue(specialtyValues, index)}
											options={transformArray(specialties, true)}
											placeholder={intl.formatMessage({ id: 'selectSpecialty' })}
											isDisabled={isDoctorDisabled[index]}
										/>
									</div>
									<div
										className='flex column-direction care-team-select-wrapper'
										onClick={() => getDoctors(specialtyValues[index]?.specialty?.id, doctorValues)}>
										<label className='font-14 semi-bold bottom-5'>{translate('doctor')}</label>
										<AsyncSelect
											loadOptions={value => getDoctors(specialtyValues[index]?.specialty?.id, doctorValues, value)}
											onChange={event => handleDoctorChange(event, index)}
											placeholder={intl.formatMessage({ id: 'selectDoctor' })}
											getOptionValue={option => option.value}
											classNamePrefix='custom-select'
											defaultOptions={doctors}
											isClearable
											value={doctor?.label ? doctor : null}
											isDisabled={isDoctorDisabled[index]}
											formatOptionLabel={formatOptionLabel}
											components={{ SingleValue }}
										/>
									</div>
									{!isDoctorDisabled[index] && (
										<Button
											className='cursor-pointer remove-item-btn align-self-end'
											imgIcon={`${healthCareCdnUrl}rpm/delete.svg`}
											onClick={() => {
												setDoctorValues(
													doctorValues.filter(member => !programCareTeam.some(program => program.userId !== member?.value))
												);
												arrayHelpers.remove(index);
											}}
										/>
									)}
								</div>
							))}
							{doctorValues.length === 0 && (
								<p className='--gray-color'>
									{translate('noMembersAdded', {
										value: intl.formatMessage({ id: 'doctors' }).toLowerCase(),
										value2: 5,
									})}
								</p>
							)}
						</div>
					</div>
				)}
			</FieldArray>
			<div>
				<span className='red-error'>{doctorErrors}</span>
			</div>
			<FieldArray name='nurses'>
				{arrayHelpers => (
					<div className='top-15'>
						<div className='care-team-wrapper'>
							<div className='flex flex-space-between'>
								<p className='semi-bold'>{translate('nurses')}</p>
								{nurseValues.length < MAX_DR_NURSES && (
									<Button
										className='add-question-btn align-self-end'
										imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
										onClick={() => arrayHelpers.push({ userId: '' })}
										text={translate('addRole', { value: intl.formatMessage({ id: 'nurse' }) })}
									/>
								)}
							</div>
							{isAddPatient && nurseValues.length > 0 && (
								<p className='--gray-color'>
									{translate('addAdditionalMember', {
										value: intl.formatMessage({ id: 'nurses' }).toLowerCase(),
										value2: 5,
									})}
								</p>
							)}
							{nurseValues.map((nurse, index) => (
								<div
									className='flex flex-align-center flex-space-between bottom-15'
									onClick={() => getNurses(nurseValues)}
									key={nurse?.value}>
									<div className='flex column-direction care-team-select-wrapper'>
										<label className='font-14 semi-bold bottom-5'>{translate('nurse')}</label>
										<AsyncSelect
											loadOptions={value => getNurses(nurseValues, value)}
											onChange={event => handleNurseChange(event, index)}
											placeholder={intl.formatMessage({ id: 'selectNurse' })}
											getOptionValue={option => option.value}
											classNamePrefix='custom-select'
											defaultOptions={nurses}
											isClearable
											value={nurse?.label ? nurse : null}
											isDisabled={isNurseDisabled[index]}
											formatOptionLabel={formatOptionLabel}
											components={{ SingleValue }}
										/>
									</div>
									{!isNurseDisabled[index] && (
										<Button
											className='cursor-pointer remove-item-btn align-self-end'
											imgIcon={`${healthCareCdnUrl}rpm/delete.svg`}
											onClick={() => {
												arrayHelpers.remove(index);
												setNurseValues(
													nurseValues.filter(member => !programCareTeam.some(program => program.userId !== member?.value))
												);
											}}
										/>
									)}
								</div>
							))}
							{nurseValues.length === 0 && (
								<p className='--gray-color'>
									{translate('noMembersAdded', {
										value: intl.formatMessage({ id: 'nurses' }).toLowerCase(),
										value2: 5,
									})}
								</p>
							)}
						</div>
					</div>
				)}
			</FieldArray>
			<div>
				<span className='red-error'>{nurseErrors}</span>
			</div>
			{isAddPatient && (
				<FieldArray name='familyMembers'>
					{arrayHelpers => (
						<div className='top-15'>
							<div className='care-team-wrapper'>
								<div className='flex flex-space-between'>
									<p className='semi-bold'>{translate('familyMembers')}</p>
									{familyMemberValues.length < MAX_FAMILY_MEMBERS && (
										<Button
											text={translate('addRole', { value: intl.formatMessage({ id: 'familyMember' }) })}
											className='add-question-btn align-self-end'
											imgIcon={`${healthCareCdnUrl}rpm/plus-active.svg`}
											onClick={() => arrayHelpers.push({ userId: '', value: '' })}
										/>
									)}
								</div>
								{familyMemberValues.map((familyMember, index) => (
									<>
										<div className='flex flex-align-center flex-space-between bottom-15' key={familyMember}>
											<div className='flex column-direction care-team-select-wrapper'>
												<label className='font-14 semi-bold bottom-5'>{translate('familyMember')}</label>
												<Field
													type='email'
													placeholder={intl.formatMessage({ id: 'enterEmailAddress' })}
													name={`familyMembers[${index}].value`}
													onChange={event => handleFamilyMemberChange(event.target.value, index)}
													maxLength={100}
												/>
											</div>
											<Button
												className='cursor-pointer remove-item-btn align-self-end'
												imgIcon={`${healthCareCdnUrl}rpm/delete.svg`}
												onClick={() => {
													arrayHelpers.remove(index);
													setFamilyMemberValues(prevState => prevState.filter(member => member.value !== familyMember.value));
												}}
											/>
										</div>
										{familyMemberErrors && familyMemberErrors[index] && (
											<div>
												<span className='red-error'>{familyMemberErrors[index]?.value}</span>
											</div>
										)}
									</>
								))}
								{familyMemberValues.length === 0 && (
									<p className='--gray-color'>
										{translate('noMembersAdded', {
											value: intl.formatMessage({ id: 'familyMembers' }).toLowerCase(),
											value2: 3,
										})}
									</p>
								)}
							</div>
						</div>
					)}
				</FieldArray>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</TabPanel>
	);
};

export default CareTeamMembers;
