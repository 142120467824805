import styled from 'styled-components';
import { handHygieneDashboardDetails, handHygieneDashboardIdentifications } from 'infrastructure/helpers/aiHelper.js';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomTable from 'components/CustomTable.jsx';
import translate from 'i18n-translations/translate.jsx';
import { exportHandHygieneAudit, getHandHygieneAudits } from 'api/handHygiene.js';
import { debounce } from 'lodash';
import moment from 'moment';
import Alert from 'components/Alert.jsx';
import { DeviceListLevel, TaskStatus } from 'constants/enums.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Modal from 'components/Modal.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import AiSummaryChart from 'components/DashboardCharts/PatientCentric/AI/AiSummaryChart.jsx';
import Grid from 'components/Grid.jsx';
import Button from 'components/Button.jsx';
import { EvidenceIcon } from 'calls/icons/index.js';
import EvidenceReport from 'containers/Monitoring/EvidenceReport.jsx';

const HandHygieneWrapper = styled.div`
	display: flex;
	flex-direction: column;

	> * + * {
		margin-top: var(--spacing-m);
	}
`;

const TableWrapper = styled.div`
	display: grid;

	div:nth-of-type(2) {
		margin-bottom: 0;
	}
`;

const ExportExcelButton = styled.div`
	width: 100px;
	font-size: 14px;
	background: var(--blue-2);
	color: var(--gray-0);
	margin-bottom: var(--spacing-m);
	border-radius: var(--spacing-s);
	padding: var(--spacing-m);
	justify-self: end;
	cursor: pointer;
`;

const HandHygiene = ({
	dateRange,
	selectedFloor,
	selectedRoom,
	selectedLevel,
	selectedDepartment,
	selectedHealthSystem,
	selectedHospital,
	selectedTimezone,
}) => {
	const [handHygieneAudits, setHandHygieneAudits] = useState([]);
	const [complianceSummary, setComplianceSummary] = useState(null);
	const [subjectIdentificationSummary, setSubjectIdentificationSummary] = useState(null);
	const [isExportLogsModalOpen, setIsExportLogsModalOpen] = useState(false);
	const [isTaskLoading, setIsTaskLoading] = useState(false);
	const [taskId, setTaskId] = useState(null);
	const [hasExportLogsModalErrors, setHasExportLogsModalErrors] = useState(false);
	const [error, setError] = useState(null);
	const [selectedEvidence, setSelectedEvidence] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isLoading, setIsLoading] = useState(false);
	const userProfile = getUserInfo();
	const socket = useContext(SocketContext);
	const controller = useRef(null);

	const fetchHandHygieneAudit = useCallback(
		debounce(async (params, signal) => {
			setIsLoading(true);
			const response = await getHandHygieneAudits(params, signal);
			if (!response.error) {
				setHandHygieneAudits(prevState => [...prevState, ...response.handSanytizingAudits]);
				setComplianceSummary(response.complianceSummary);
				setSubjectIdentificationSummary(response.subjectIdentificationSummary);
				setPagination(prevState => ({ ...prevState, totalCount: response.total }));
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(response.error.message);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	const displayHandHygiene = () => {
		if (!handHygieneAudits || handHygieneAudits.length === 0) {
			return [];
		}

		return handHygieneAudits.map(audit => ({
			unit: audit.unit,
			serialNumber: audit.serialNumber,
			subjectIdentification: audit.subjectIdentification,
			employeeDirection: audit.employeeDirection,
			compliant: audit.handHygieneViewed,
			method: audit.method,
			startDate: audit.eventDate ? formattedDate(audit.eventDate) : '',
			evidence: getEvidenceButton({
				helloDeviceSerialNumber: audit.serialNumber,
				id: audit?.id,
			}),
		}));
	};

	const getEvidenceButton = item => (
		<Button className='evidence-btn' svgIcon={<EvidenceIcon />} onClick={() => setSelectedEvidence(item)} />
	);

	const getLevelId = () => {
		if (selectedLevel.levelType === DeviceListLevel.HEALTH_SYSTEM) {
			return selectedHealthSystem;
		}
		if (selectedLevel.levelType === DeviceListLevel.DEPARTMENT) {
			return selectedDepartment.value;
		}
		if (selectedLevel.levelType === DeviceListLevel.FLOOR || selectedLevel.levelType === DeviceListLevel.ROOM) {
			return selectedFloor.value;
		}
		if (selectedLevel.levelType === DeviceListLevel.HOSPITAL) {
			return selectedHospital.value;
		}
		return null;
	};

	const getRoomId = () => selectedRoom?.value ?? null;

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const params = {
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			startDate: moment(dateRange.from).utc(true).format('MM/DD/YYYY'),
			endDate: moment(dateRange.to).utc(true).format('MM/DD/YYYY'),
			levelId: getLevelId(),
			roomId: getRoomId(),
		};
		fetchHandHygieneAudit(params, signal);
	}, [
		pagination.pageIndex,
		pagination.pageSize,
		selectedRoom,
		selectedFloor,
		selectedDepartment,
		selectedHospital,
		selectedHealthSystem,
		selectedTimezone,
		dateRange,
		fetchHandHygieneAudit,
	]);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	useEffect(() => {
		setHandHygieneAudits([]);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	}, [dateRange, selectedLevel, selectedFloor, selectedRoom?.value]);

	const closeExportLogsModal = () => {
		setIsExportLogsModalOpen(false);
		setHasExportLogsModalErrors(false);
	};

	const startExportTask = async () => {
		setIsTaskLoading(true);
		setError(null);
		const response = await exportHandHygieneAudit({
			levelId: getLevelId(),
			roomId: getRoomId(),
			startDate: dateRange.from,
			endDate: dateRange.to,
		});
		if (response.error) {
			setError(response.error.message);
			setIsTaskLoading(false);
			return;
		}
		setTaskId(response.taskId);
		setHasExportLogsModalErrors(!!response.error);
		setIsExportLogsModalOpen(!!response.error);
	};

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (data.taskId !== taskId) {
				return;
			}
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				setIsTaskLoading(false);
				setTaskId(null);
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setError(translate('somethingWentWrong'));
				setIsTaskLoading(false);
				setTaskId(null);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket]);

	const getTotalValue = obj => Object.values(obj || {}).reduce((prev, acc) => prev + acc, 0);

	return (
		<>
			<HandHygieneWrapper>
				<>
					<Grid columns='1fr 1fr' gridGap='15px'>
						<AiSummaryChart
							total={getTotalValue(complianceSummary)}
							chartDetails={handHygieneDashboardDetails}
							chartData={complianceSummary}
							dataSets={Object.values(complianceSummary || {}).map(value => value)}
							isLoading={!complianceSummary}
							displayInSeconds={false}
							hasPercentageValue={false}
							title='compliance'
						/>
						<AiSummaryChart
							total={getTotalValue(subjectIdentificationSummary)}
							chartDetails={handHygieneDashboardIdentifications}
							chartData={subjectIdentificationSummary}
							dataSets={Object.values(subjectIdentificationSummary || {}).map(value => value)}
							isLoading={!subjectIdentificationSummary}
							displayInSeconds={false}
							hasPercentageValue={false}
							title='subjectIdentifications'
						/>
					</Grid>

					<TableWrapper>
						<ExportExcelButton
							className={`${dateRange.to && dateRange.from ? '' : 'disabled'}`}
							onClick={() => setIsExportLogsModalOpen(true)}>
							{translate('exportExcel')}
						</ExportExcelButton>
						<CustomTable
							headers={[
								{ title: translate('unit'), id: 'unit' },
								{ title: translate('serialNumber'), id: 'serialNumber' },
								{ title: translate('subjectIdentification'), id: 'subjectIdentification' },
								{ title: translate('employeeDirection'), id: 'employeeDirection' },
								{ title: translate('compliant'), id: 'compliant' },
								{ title: translate('method'), id: 'method' },
								{ title: translate('startDate'), id: 'startDate' },
								{ title: translate('evidence'), id: 'evidence' },
							]}
							rows={displayHandHygiene()}
							isLoading={isLoading}
							stickyHeader={true}
							setPagination={setPagination}
						/>
					</TableWrapper>
					<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
				</>
			</HandHygieneWrapper>

			<Modal
				display={isExportLogsModalOpen}
				position='center'
				isLoading={isTaskLoading}
				onModalSubmit={startExportTask}
				primaryButtonLabel={translate('continue')}
				onModalClose={closeExportLogsModal}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'>
				<form>
					<h3>{translate('exportExcel')}</h3>
					{!hasExportLogsModalErrors && userProfile && (
						<p>{translate('exportExcelBackground', { value: <strong>{userProfile.email}</strong> })}</p>
					)}
					{hasExportLogsModalErrors && <p>{translate('somethingWentWrong')}</p>}
				</form>
			</Modal>
			{selectedEvidence && (
				<EvidenceReport setIsModalOpen={setSelectedEvidence} evidenceDetails={selectedEvidence} isDashboard={true} />
			)}
		</>
	);
};

export default HandHygiene;
