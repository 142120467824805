import { getTeamSettings } from 'api/adminConfigurations.js';
import { getHospitalFloors } from 'api/floors.js';
import { getOrgTeamChildren } from 'api/organization.js';
import { getDepartmentRooms, getHospitalRooms } from 'api/rooms.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import classNames from 'classnames';
import AIAlertsChart from 'components/DashboardCharts/AIAlertsChart.jsx';
import AdditionalFilters from 'components/DashboardCharts/AdditionalFilters.jsx';
import AllProviders from 'components/DashboardCharts/AllProviders.jsx';
import HandHygiene from 'components/DashboardCharts/HandHygiene.jsx';
import MonitoringKPIs from 'components/DashboardCharts/MonitoringKPIs.jsx';
import PatientCentricReport from 'components/DashboardCharts/PatientCentric/PatientCentricReport.jsx';
import ProvidersCentricReport from 'components/DashboardCharts/ProvidersCentricReport.jsx';
import RoundingKPIs from 'components/DashboardCharts/RoundingKPIs.jsx';
import VisitsByHour from 'components/DashboardCharts/VisitsByHour.jsx';
import DateRangePicker from 'components/DateRangePicker.jsx';
import EmptyState from 'components/EmptyState.jsx';
import FormInput from 'components/FormInput.jsx';
import Grid from 'components/Grid.jsx';
import SelectDashboardContent from 'components/SelectDashboardContent.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import { Alert, Button } from 'components/index.js';
import { AlertAndSettingMapping } from 'constants/ai.js';
import {
	CallWorkflowType,
	configurableAISettings,
	DashboardSettings,
	getConfigurationWithDiffTypeKey,
	SettingsCategory,
} from 'constants/configurationEnums.js';
import { DefaultTimezone, providersRoles, RoundingOrigins, RoundingTypes, tabEnums } from 'constants/dashboard.js';
import { DateRangePresets, DeviceListLevel, TreeHierarchyType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import { getConfigurationValue, getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Select from 'react-select';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const Dashboard = () => {
	const currentHealthSystem = useSelector(state => state.user.userSession.healthSystem);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const location = useLocation();
	const intl = useIntl();
	const sectionRef = useRef(null);
	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;
	const maximumRangeOfDays = 90;
	const defaultRangeOfDays = 30;
	const fromDefault = new Date();
	const toDefault = new Date();
	fromDefault.setDate(fromDefault.getDate() - defaultRangeOfDays);
	fromDefault.setHours(0, 0, 0, 0);
	toDefault.setDate(toDefault.getDate() - 1);
	toDefault.setHours(23, 59, 59, 999);
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [floors, setFloors] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [selectedHospital, setSelectedHospital] = useState(null);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [selectedFloor, setSelectedFloor] = useState(null);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const [selectedLevel, setSelectedLevel] = useState({ levelId: '', levelType: 0 });
	const [dateRange, setDateRange] = useState({ from: fromDefault, to: toDefault, preset: DateRangePresets.LAST_30_DAYS });
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showDownloadReport, setShowDownloadReport] = useState(false);
	const [selectedTimezone, setSelectedTimezone] = useState(DefaultTimezone);
	const [additionalFilters, setAdditionalFilters] = useState(null);
	const [defaultFilters, setDefaultFilters] = useState(null);
	const defaultLevelValue = 0;
	const [currentTab, setCurrentTab] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [adminConfigurations, setAdminConfigurations] = useState({});

	const tabs = [
		getConfigurationValue(companyConfigurations[DashboardSettings.OVERVIEW]) && {
			id: tabEnums.OVERVIEW,
			description: 'overview',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.PROVIDER_CENTRIC]) && {
			id: tabEnums.CARE_TEAM_REPORT,
			description: 'careTeamReport',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.PATIENT_CENTRIC]) && {
			id: tabEnums.PATIENT_REPORT,
			description: 'patientReport',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.HAND_HYGIENE]) && {
			id: tabEnums.HAND_HYGIENE,
			description: 'handHygiene',
		},
	].filter(Boolean);

	const allDashboardConfigsDisabled = !getSomeConfigurationsValues(companyConfigurations, [
		DashboardSettings.OVERVIEW,
		DashboardSettings.PATIENT_CENTRIC,
		DashboardSettings.PROVIDER_CENTRIC,
		DashboardSettings.HAND_HYGIENE,
	]);
	const showAiAlertWidget =
		getConfigurationValue(companyConfigurations[DashboardSettings.AI_ALERTS]) &&
		getSomeConfigurationsValues(adminConfigurations, Object.values(AlertAndSettingMapping));

	const getAdminConfigs = async () => {
		if (!currentHealthSystem.id || !getConfigurationValue(companyConfigurations[DashboardSettings.AI_ALERTS])) {
			return;
		}
		const response = await getTeamSettings({
			teamId: currentHealthSystem.id,
			levelId: DeviceListLevel.HEALTH_SYSTEM,
			settingsCategory: [SettingsCategory.AI_SETTINGS],
		});

		if (response.error) {
			setError(response.error.message);
		} else {
			const featureFlagConfigs = {};
			response.settings.forEach(item => {
				const config = configurableAISettings()[getConfigurationWithDiffTypeKey(item.settingTypeId, CallWorkflowType.MONITORING)];
				if (config && item.callWorkflowTypeId === CallWorkflowType.MONITORING) {
					config.value = JSON.parse(item.value);
					config.isLocked = item.isLocked;
					if (item.variant && config.options) {
						config.variant = config.options.find(opt => opt.value === item.variant);
					}
					featureFlagConfigs[item.settingTypeId] = config;
				}
			});
			setAdminConfigurations(featureFlagConfigs);
		}
	};

	const getHospitalsList = async () => {
		const hsHospitals = currentHealthSystem.id !== '0' ? await getHealthSystemHospitals(currentHealthSystem.id) : [];
		if (hsHospitals?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
		setHospitals(hsHospitals);
		setIsLoading(false);
	};

	useEffect(() => {
		if (!allDashboardConfigsDisabled) {
			onHealthSystemChange();
			getAdminConfigs();
			setSelectedLevel({ levelId: currentHealthSystem.id, levelType: DeviceListLevel.HEALTH_SYSTEM });
		}
	}, [currentHealthSystem]);

	useEffect(() => {
		const { state } = location;
		if (state) {
			setCurrentTab(state?.prevTab || 0);
			setSearchTerm(state.filters?.search || '');
			setSearchValue(state.filters?.search || '');
			setDateRange(prevState => state.filters?.dateRange || prevState);
			setSelectedTimezone(prevState => state.filters?.selectedTimezone || prevState);
			setDefaultFilters(state.filters?.additionalFilters || null);
			setSelectedRoom(state.filters?.room || null);
			getHospitalsList();
			getHospitalChildren(state.filters?.hospital);
			if (currentHealthSystem.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM) {
				return;
			}
			if (currentHealthSystem.treeHierarchyTypeId !== TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
				getDepartmentChildren(state.filters?.department);
			}
			if (currentHealthSystem.treeHierarchyTypeId !== TreeHierarchyType.HOSPITAL_DEPT_ROOM) {
				getFloorRooms(state.filters?.floor);
			}
		}
	}, [location]);

	const getHospitalChildren = async hospital => {
		if (!hospital) {
			return;
		}
		setSelectedHospital(hospital);
		setSelectedTimezone(hospital.timezone);
		let response = [];
		if (
			![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(currentHealthSystem.treeHierarchyTypeId)
		) {
			response = await getOrgTeamChildren(hospital.value);
			setDepartments(response.error ? [] : response);
		}
		if (currentHealthSystem.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			response = await getHospitalFloors(getCompanyId(), currentHealthSystem.id, hospital.value);
			if (!response.error) {
				setSelectedDepartment({ value: response.departmentId });
				setFloors(response.floors);
			}
		}
		if (currentHealthSystem.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM) {
			response = await getHospitalRooms(getCompanyId(), currentHealthSystem.id, hospital.value);
			if (!response.error) {
				setSelectedDepartment({ value: response.departmentId });
				setSelectedFloor({ value: response.floorId });
				setRooms(response.rooms);
			}
		}

		if (response?.error) {
			setError(translate('somethingWentWrong'));
			return;
		}
	};

	const getDepartmentChildren = async department => {
		if (!department) {
			return;
		}
		setSelectedDepartment(department);
		let response = [];
		if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === currentHealthSystem.treeHierarchyTypeId) {
			response = await getDepartmentRooms(currentHealthSystem.id, department.value);
			if (!response.error) {
				setSelectedFloor({ value: response.floorId });
				setRooms(response.rooms);
			}
		} else {
			response = await getOrgTeamChildren(department.value);
			setFloors(response.error ? [] : response);
		}

		if (response?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
	};

	const getFloorRooms = async floor => {
		if (!floor) {
			return;
		}
		setSelectedFloor(floor);
		const floorRooms = await getOrgTeamChildren(floor.value);
		if (floorRooms?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
		setRooms(floorRooms);
	};

	const onHealthSystemChange = () => {
		setRooms([]);
		setFloors([]);
		setDepartments([]);
		setHospitals([]);
		setSelectedFloor(null);
		setSelectedRoom(null);
		setSelectedDepartment(null);
		setSelectedHospital(null);
		setSelectedTimezone(DefaultTimezone);
		setIsLoading(true);
		getHospitalsList();
	};

	const onHospitalSelected = async hospital => {
		setRooms([]);
		setFloors([]);
		setDepartments([]);
		setSelectedFloor(null);
		setSelectedRoom(null);
		setSelectedDepartment(null);
		if (hospital.value === defaultLevelValue) {
			setSelectedHospital(null);
			setSelectedTimezone(DefaultTimezone);
			setSelectedLevel({ levelId: currentHealthSystem.id, levelType: DeviceListLevel.HEALTH_SYSTEM });
			return;
		}
		setSelectedLevel({ levelId: hospital.value, levelType: DeviceListLevel.HOSPITAL });
		setIsLoading(true);
		await getHospitalChildren(hospital);
		setSelectedRoom(null);
		setIsLoading(false);
	};

	const onDepartmentSelected = async dep => {
		setRooms([]);
		setFloors([]);
		setSelectedFloor(null);
		setSelectedRoom(null);
		if (dep.value === defaultLevelValue) {
			onHospitalSelected(selectedHospital);
			return;
		}
		setSelectedLevel({ levelId: dep.value, levelType: DeviceListLevel.DEPARTMENT });
		setIsLoading(true);
		await getDepartmentChildren(dep);
		setIsLoading(false);
	};

	const onFloorSelected = async floor => {
		if (floor.value === defaultLevelValue) {
			if (TreeHierarchyType.HOSPITAL_FLOOR_ROOM === currentHealthSystem.treeHierarchyTypeId) {
				onHospitalSelected(selectedHospital);
			} else {
				onDepartmentSelected(selectedDepartment);
			}
			setRooms([]);
			setSelectedRoom(null);
			return;
		}
		setSelectedLevel({ levelId: floor.value, levelType: DeviceListLevel.FLOOR });
		setIsLoading(true);
		await getFloorRooms(floor);
		setIsLoading(false);
		setSelectedRoom(null);
	};

	const onRoomSelected = room => {
		if (room.value === defaultLevelValue) {
			if (TreeHierarchyType.HOSPITAL_ROOM === currentHealthSystem.treeHierarchyTypeId) {
				onHospitalSelected(selectedHospital);
			} else if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === currentHealthSystem.treeHierarchyTypeId) {
				onDepartmentSelected(selectedDepartment);
			} else {
				onFloorSelected(selectedFloor);
			}
			return;
		}
		setSelectedLevel({ levelId: room.value, levelType: DeviceListLevel.ROOM });
		setSelectedRoom(room);
	};

	const transformArray = (array, sector) => {
		if (array.length === 0) {
			return [];
		}
		let newArray = array
			.map(item => ({
				value: item.id,
				label: item.name,
				regionId: item.regionId,
				...(sector === 'allHospitals' && {
					timezone: item?.timeZone ? { label: item.timeZone, zone: item.timeZone } : DefaultTimezone,
				}),
			}))
			.sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));
		newArray.unshift({ value: defaultLevelValue, label: intl.formatMessage({ id: sector }) });
		return newArray;
	};

	const handleSearchValue = useCallback(debounce(setSearchValue, 500), []);

	const handleSearchTerm = event => {
		setSearchTerm(event.target.value);
		handleSearchValue(event.target.value);
	};

	const handleSetSelectedProvider = provider => {
		setSelectedProvider(provider);
		setDefaultFilters(additionalFilters);
	};

	const onTabChange = index => {
		setSearchTerm('');
		setSearchValue('');
		setCurrentTab(index);
		sectionRef.current.scrollTo(0, 0);
	};

	return (
		<MainLayout>
			<div className='dashboard-view'>
				<main className='main-view'>
					{!selectedProvider && (
						<section ref={sectionRef}>
							<div className='full-width content-height'>
								<>
									<h3 className='title margin-bottom-xl'>{translate('dashboard')}</h3>
									<ToastMessage className='dashboard-report' onClose={() => setSuccess(false)} showToast={success} timer={10000}>
										<span>
											<Download color='#33C27F' />
										</span>
										<div>
											<span>{translate('reportSentSuccessfully')}</span>
											<p>{translate('toAccessCheckEmail')}</p>
										</div>
									</ToastMessage>
									<div className={classNames('dashboard-filters', allDashboardConfigsDisabled ? 'disabled' : '')}>
										<Select
											value={selectedHospital}
											placeholder={intl.formatMessage({ id: 'allHospitals' })}
											classNamePrefix='custom-select'
											options={transformArray(hospitals, 'allHospitals')}
											components={{ DropdownIndicator }}
											onChange={onHospitalSelected}
										/>
										{![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(
											currentHealthSystem.treeHierarchyTypeId
										) && (
											<Select
												value={selectedDepartment}
												placeholder={intl.formatMessage({ id: !selectedHospital ? 'department' : 'allDepartments' })}
												classNamePrefix='custom-select'
												options={transformArray(departments, 'allDepartments')}
												components={{ DropdownIndicator }}
												onChange={onDepartmentSelected}
												isDisabled={!selectedHospital}
											/>
										)}
										{![TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(
											currentHealthSystem.treeHierarchyTypeId
										) && (
											<Select
												value={selectedFloor}
												placeholder={intl.formatMessage({ id: !selectedDepartment ? 'floor' : 'allFloors' })}
												classNamePrefix='custom-select'
												options={transformArray(floors, 'allFloors')}
												components={{ DropdownIndicator }}
												onChange={onFloorSelected}
												isDisabled={!selectedDepartment}
											/>
										)}

										<Select
											value={selectedRoom}
											placeholder={intl.formatMessage({ id: !selectedFloor ? 'room' : 'allRooms' })}
											classNamePrefix='custom-select'
											options={transformArray(rooms, 'allRooms')}
											components={{ DropdownIndicator }}
											onChange={onRoomSelected}
											isDisabled={!selectedFloor}
										/>

										<DateRangePicker
											defaultFrom={dateRange.from}
											defaultTo={dateRange.to}
											defaultPreset={dateRange.preset}
											handleRangeChange={setDateRange}
											selectedTimezone={selectedTimezone}
											maxDays={maximumRangeOfDays}
										/>

										<div className='dashboard-filter flex-initial'>
											<AdditionalFilters defaultFilters={defaultFilters} setAdditionalFilters={setAdditionalFilters} />
										</div>
									</div>
									{!allDashboardConfigsDisabled && (
										<div className='dashboard-tabs'>
											<div className='dashboard-header'>
												<div className='flex flex-align-center position-absolute'>
													{[tabEnums.PATIENT_REPORT, tabEnums.CARE_TEAM_REPORT].includes(tabs[currentTab].id) && (
														<label>
															<FormInput
																id='searchReports'
																name='searchReports'
																placeholder={intl.formatMessage({
																	id: tabs[currentTab].id === tabEnums.PATIENT_REPORT ? 'searchByPatientNameMrn' : 'search',
																})}
																type='text'
																onChange={handleSearchTerm}
																error={null}
																value={searchTerm}
																maxLength={50}
																labelClassName='header-searchbar no-padding-left no-padding-right'
																validateOnSubmit={true}
															/>
														</label>
													)}
													<div className='dashboard-filter flex'>
														<Button
															variant='white'
															svgIcon={<Download />}
															onClick={() => setShowDownloadReport(true)}
															text={translate('download')}
														/>
													</div>
												</div>
											</div>
											<Tabs activeIndex={currentTab} onChange={onTabChange}>
												<TabList>
													{tabs.map(tab => (
														<Tab key={tab.id}>{translate(tab.description)}</Tab>
													))}
												</TabList>
												<TabPanels>
													{getConfigurationValue(companyConfigurations[DashboardSettings.OVERVIEW]) && (
														<TabPanel>
															<div className='dashboard-items'>
																<VisitsByHour
																	start={dateRange.from}
																	end={dateRange.to}
																	selectedHealthSystem={currentHealthSystem.id}
																	selectedHospital={selectedHospital}
																	selectedDepartment={selectedDepartment}
																	selectedFloor={selectedFloor}
																	selectedRoom={selectedRoom}
																	selectedTimezone={selectedTimezone}
																	xLabelScales={2}
																	additionalFilters={additionalFilters}
																/>
															</div>
															<Grid
																columns={showAiAlertWidget ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'}
																gridGap='10px'
																className='dashboard-items top-10'>
																<div className='dashboard-monitoring-wrapper'>
																	<MonitoringKPIs
																		type='video'
																		start={dateRange.from}
																		end={dateRange.to}
																		selectedHealthSystem={currentHealthSystem.id}
																		selectedHospital={selectedHospital}
																		selectedDepartment={selectedDepartment}
																		selectedFloor={selectedFloor}
																		selectedRoom={selectedRoom}
																		selectedTimezone={selectedTimezone}
																		setError={setError}
																		additionalFilters={additionalFilters}
																	/>
																	<MonitoringKPIs
																		type='ambient'
																		start={dateRange.from}
																		end={dateRange.to}
																		selectedHealthSystem={currentHealthSystem.id}
																		selectedHospital={selectedHospital}
																		selectedDepartment={selectedDepartment}
																		selectedFloor={selectedFloor}
																		selectedRoom={selectedRoom}
																		selectedTimezone={selectedTimezone}
																		setError={setError}
																		additionalFilters={additionalFilters}
																	/>
																</div>
																<div className='dashboard-monitoring-wrapper'>
																	<RoundingKPIs
																		roundingType={RoundingTypes.TALK_TO_PATIENT}
																		roundingOrigin={RoundingOrigins.OUTGOING}
																		start={dateRange.from}
																		end={dateRange.to}
																		selectedHealthSystem={currentHealthSystem.id}
																		selectedHospital={selectedHospital}
																		selectedDepartment={selectedDepartment}
																		selectedFloor={selectedFloor}
																		selectedRoom={selectedRoom}
																		selectedTimezone={selectedTimezone}
																		additionalFilters={additionalFilters}
																		setError={setError}
																		intl={intl}
																	/>
																	<RoundingKPIs
																		roundingType={RoundingTypes.TALK_TO_PATIENT}
																		roundingOrigin={RoundingOrigins.INCOMING}
																		start={dateRange.from}
																		end={dateRange.to}
																		selectedHealthSystem={currentHealthSystem.id}
																		selectedHospital={selectedHospital}
																		selectedDepartment={selectedDepartment}
																		selectedFloor={selectedFloor}
																		selectedRoom={selectedRoom}
																		selectedTimezone={selectedTimezone}
																		additionalFilters={additionalFilters}
																		setError={setError}
																		intl={intl}
																	/>
																	<RoundingKPIs
																		roundingType={RoundingTypes.VIEW_PATIENT}
																		roundingOrigin={RoundingOrigins.VIEW_PATIENT}
																		start={dateRange.from}
																		end={dateRange.to}
																		selectedHealthSystem={currentHealthSystem.id}
																		selectedHospital={selectedHospital}
																		selectedDepartment={selectedDepartment}
																		selectedFloor={selectedFloor}
																		selectedRoom={selectedRoom}
																		selectedTimezone={selectedTimezone}
																		additionalFilters={additionalFilters}
																		setError={setError}
																		intl={intl}
																	/>
																</div>
																{showAiAlertWidget && (
																	<AIAlertsChart
																		start={dateRange.from}
																		end={dateRange.to}
																		selectedLevel={selectedLevel}
																		selectedTimezone={selectedTimezone}
																		setError={setError}
																		intl={intl}
																		adminConfigurations={adminConfigurations}
																	/>
																)}
															</Grid>
														</TabPanel>
													)}
													{getConfigurationValue(companyConfigurations[DashboardSettings.PROVIDER_CENTRIC]) && (
														<TabPanel>
															<ProvidersCentricReport
																searchValue={searchValue}
																setShouldShowAllProviders={value => {
																	setSuccess(false);
																	if (!value) {
																		setSelectedProvider(null);
																	}
																}}
																start={dateRange.from}
																end={dateRange.to}
																selectedHealthSystem={currentHealthSystem.id}
																selectedHospital={selectedHospital}
																selectedDepartment={selectedDepartment}
																selectedFloor={selectedFloor}
																selectedRoom={selectedRoom}
																setSelectedProvider={handleSetSelectedProvider}
																setIsChangeLoading={setIsLoading}
																providersRoles={providersRoles}
																selectedTimezone={selectedTimezone}
																additionalFilters={additionalFilters}
															/>
														</TabPanel>
													)}
													{getConfigurationValue(companyConfigurations[DashboardSettings.PATIENT_CENTRIC]) && (
														<TabPanel>
															<PatientCentricReport
																currentTab={currentTab}
																dateRange={dateRange}
																selectedHealthSystem={currentHealthSystem.id}
																selectedHospital={selectedHospital}
																selectedDepartment={selectedDepartment}
																selectedFloor={selectedFloor}
																selectedRoom={selectedRoom}
																searchValue={searchValue}
																selectedTimezone={selectedTimezone}
																selectedLevel={selectedLevel}
																additionalFilters={additionalFilters}
															/>
														</TabPanel>
													)}

													<TabPanel>
														<HandHygiene
															dateRange={dateRange}
															selectedDepartment={selectedDepartment}
															selectedHealthSystem={currentHealthSystem.id}
															selectedHospital={selectedHospital}
															selectedFloor={selectedFloor}
															selectedRoom={selectedRoom}
															selectedLevel={selectedLevel}
															selectedTimezone={selectedTimezone}
														/>
													</TabPanel>
												</TabPanels>
											</Tabs>
										</div>
									)}
									{allDashboardConfigsDisabled && (
										<EmptyState
											className='all-tabs-disabled'
											title={translate('plsReachAdministratorToHaveThisFeatureAvailable')}
											image='no-files.svg'
										/>
									)}
								</>
							</div>
						</section>
					)}
					{selectedProvider && (
						<div className='all-providers-wrapper'>
							<AllProviders
								selectedProvider={selectedProvider}
								setSelectedProvider={setSelectedProvider}
								start={dateRange.from}
								end={dateRange.to}
								selectedHealthSystem={currentHealthSystem.id}
								selectedHospital={selectedHospital}
								selectedDepartment={selectedDepartment}
								selectedFloor={selectedFloor}
								selectedRoom={selectedRoom}
								isChangeLoading={isLoading}
								display={true}
								providersRoles={providersRoles}
								selectedTimezone={selectedTimezone}
								additionalFilters={additionalFilters}
							/>
						</div>
					)}
				</main>

				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
				<SelectDashboardContent
					showDownloadReport={showDownloadReport && !allDashboardConfigsDisabled}
					from={dateRange.from}
					to={dateRange.to}
					selectedHealthSystem={currentHealthSystem.id}
					selectedHospital={selectedHospital}
					selectedDepartment={selectedDepartment}
					selectedFloor={selectedFloor}
					selectedRoom={selectedRoom}
					setSuccess={setSuccess}
					setShowDownloadReport={setShowDownloadReport}
					selectedTimezone={selectedTimezone}
					additionalFilters={additionalFilters}
					setError={setError}
				/>
			</div>
		</MainLayout>
	);
};

export default Dashboard;
