import { updateDepartment } from 'api/departments.js';
import { updateFloor } from 'api/floors.js';
import { updateHospital } from 'api/hospitals.js';
import {
	deleteRoomLocation,
	getRoom,
	getRoomLocation,
	saveRoomConfiguration,
	updateRoom,
	updateRoomLocation,
} from 'api/rooms.js';
import { getTvDeviceTeamConfigurationProfile } from 'api/teamConfigurationProfiles.js';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import { IntegrationTypesSettings, RoomTypesSettings, TeamConfigurationIntegrationTypes } from 'constants/configurationEnums.js';
import { HealthSystemType, HospitalLogoType, SectorTypes, TeamTypes, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { addMinutes, setHours, setMinutes } from 'date-fns';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { checkIfSectorNameExists, findSectorById, getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { subtractMinutes } from 'infrastructure/helpers/dateHelper.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';

const EditSector = props => {
	const { hospitalId, departmentId, floorId, roomId, type } = props.sectorData;
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [roomType, setRoomType] = useState(null);
	const [availableHoursStart, setAvailableHoursStart] = useState(null);
	const [availableHoursEnd, setAvailableHoursEnd] = useState(null);
	const [breakTimeStart, setBreakTimeStart] = useState(null);
	const [breakTimeEnd, setBreakTimeEnd] = useState(null);
	const [error, setError] = useState(null);
	const [capacityValue, setCapacity] = useState(0);
	const [priorityValue, setPriority] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [roomTvSerialNumber, setRoomTvSerialNumber] = useState('');
	const [adtRoomLocation, setAdtRoomLocation] = useState({ unit: '', room: '', bed: '' });
	const [adtRoomLocationError, setAdtRoomLocationError] = useState({ unit: '', room: '', bed: '' });
	const [integrationTypeId, setIntegrationTypeId] = useState(null);
	const isInit = useRef(true);
	const intl = useIntl();
	const [isMultiBed, setIsMultiBed] = useState(SectorTypes.ROOM === type ? props.sectorData.isMultiBed : false);
	const [updateSectorLoading, setUpdateSectorLoading] = useState(false);
	const healthSystemId = userSession.healthSystem.id;
	const { regionId } = userSession;
	const { treeHierarchyTypeId, workflowTypeId } = userSession.healthSystem;

	const roomTypes = useMemo(
		() =>
			[
				{ label: RoomTypes.DEFAULT.message, value: RoomTypes.DEFAULT.type },
				getConfigurationValue(companyConfigurations[RoomTypesSettings.BABY_ROOM]) && {
					label: RoomTypes.BABY_ROOM.message,
					value: RoomTypes.BABY_ROOM.type,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.FRONT_DESK]) && {
					label: RoomTypes.FRONT_DESK.message,
					value: RoomTypes.FRONT_DESK.type,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.DOCTOR_ROOM]) && {
					label: RoomTypes.DOCTOR.message,
					value: RoomTypes.DOCTOR.type,
					hasSchedule: true,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.LABORATORY_ROOM]) && {
					label: RoomTypes.LABORATORY.message,
					value: RoomTypes.LABORATORY.type,
					capacity: 50,
					hasSchedule: true,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.INJECTION_ROOM]) && {
					label: RoomTypes.INJECTION_ROOM.message,
					value: RoomTypes.INJECTION_ROOM.type,
					capacity: 1,
					hasSchedule: true,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.INFUSION_ROOM]) && {
					label: RoomTypes.INFUSION_ROOM.message,
					value: RoomTypes.INFUSION_ROOM.type,
					capacity: 6,
					hasSchedule: true,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.DIGITAL_CLINIC]) && {
					label: RoomTypes.DIGITAL_CLINIC.message,
					value: RoomTypes.DIGITAL_CLINIC.type,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.PHARMACY_ROOM]) && {
					label: RoomTypes.PHARMACY.message,
					value: RoomTypes.PHARMACY.type,
					hasSchedule: true,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.RADIOLOGY_ROOM]) && {
					label: RoomTypes.RADIOLOGY.message,
					value: RoomTypes.RADIOLOGY.type,
					hasSchedule: true,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.KIOSK]) && {
					label: RoomTypes.KIOSK.message,
					value: RoomTypes.KIOSK.type,
				},
				getConfigurationValue(companyConfigurations[RoomTypesSettings.TRIAGE]) && {
					label: RoomTypes.TRIAGE.message,
					value: RoomTypes.TRIAGE.type,
				},
			].filter(Boolean),
		[companyConfigurations]
	);

	useEffect(() => {
		setBreakTimeStart(null);
		setBreakTimeEnd(null);
	}, [availableHoursEnd, availableHoursStart]);

	const resetRoomConfigurationValues = () => {
		setCapacity(null);
		setPriority(null);
		setAvailableHoursStart(null);
		setAvailableHoursEnd(null);
		setBreakTimeStart(null);
		setBreakTimeEnd(null);
	};

	const getParamsBasedOnHierarchyType = useCallback(() => {
		let profileParams = null;

		switch (treeHierarchyTypeId) {
			case TreeHierarchyType.DEFAULT_TREE:
			case TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM:
			case TreeHierarchyType.HOSPITAL_DEPT_ROOM: {
				profileParams = {
					teamType: TeamTypes.DEPARTMENT,
					teamId: departmentId,
				};
				break;
			}
			case TreeHierarchyType.HOSPITAL_FLOOR_ROOM: {
				profileParams = {
					teamType: TeamTypes.FLOOR,
					teamId: floorId,
				};
				break;
			}
			case TreeHierarchyType.HOSPITAL_ROOM: {
				profileParams = {
					teamType: TeamTypes.HOSPITAL,
					teamId: hospitalId,
				};
				break;
			}
			default: {
				profileParams = {
					teamType: TeamTypes.DEPARTMENT,
					teamId: departmentId,
				};
				break;
			}
		}
		return profileParams;
	}, [departmentId, floorId, hospitalId, treeHierarchyTypeId]);

	useEffect(() => {
		const fetchRoomData = async () => {
			setIsLoading(true);
			const data = {
				healthSystemId,
				hospitalId,
				departmentId,
				floorId,
				roomId,
			};
			const [response, roomLocation, teamResponse] = await Promise.all([
				getRoom(data),
				getRoomLocation(data),
				getTvDeviceTeamConfigurationProfile(getParamsBasedOnHierarchyType()),
			]);

			if (response.error || roomLocation.error || teamResponse.error) {
				setError(intl.formatMessage({ id: 'somethingWrong' }));
			}

			if (!response.error) {
				setRoomTvSerialNumber(response.tvSerialNumber);
				const selectedRoomType = roomTypes.find(room => room.value === response.room.type) || {
					label: RoomTypes.DEFAULT.message,
					value: RoomTypes.DEFAULT.type,
				};
				setRoomType(selectedRoomType);
				isInit.current = false;
				if (response.configuration) {
					const { breakStartTime, breakEndTime, startTime, endTime, capacity, priority } = response.configuration;
					if (startTime) {
						setAvailableHoursStart(setStringToDate(startTime));
						setAvailableHoursEnd(setStringToDate(endTime));
						setBreakTimeStart(setStringToDate(breakStartTime));
						setBreakTimeEnd(setStringToDate(breakEndTime));
					}
					setPriority(priority);
					if (capacity) {
						setCapacity(capacity);
					}
				}
			}
			if (!roomLocation.error) {
				setAdtRoomLocation(prevState => ({
					...prevState,
					unit: roomLocation.result?.unit,
					room: roomLocation.result?.room,
					bed: roomLocation.result?.bed,
				}));
				setAdtRoomLocationError(prevState => ({ ...prevState, unit: '', room: '', bed: '' }));
			}
			if (!teamResponse.error) {
				setIntegrationTypeId(teamResponse?.teamConfigurationProfile?.integrationTypeId || null);
			}
			setIsLoading(false);
		};
		if (SectorTypes.ROOM === type && isInit.current) {
			fetchRoomData();
		} else {
			setIsLoading(false);
		}
	}, [
		type,
		roomTypes,
		healthSystems,
		intl,
		getParamsBasedOnHierarchyType,
		healthSystemId,
		hospitalId,
		departmentId,
		floorId,
		roomId,
	]);

	const setStringToDate = dateInString => {
		const dateToSet = new Date();
		const hours = dateInString.substring(0, dateInString.indexOf(':'));
		const mintues = dateInString.substring(dateInString.indexOf(':') + 1, dateInString.length);
		dateToSet.setHours(hours);
		dateToSet.setMinutes(mintues);
		return dateToSet;
	};

	const handleEditSector = async values => {
		setUpdateSectorLoading(true);
		const params = {
			name: values?.sectorName,
		};

		let editSectorResponse = {};

		if (SectorTypes.HOSPITAL === type) {
			editSectorResponse = await updateHospital({
				healthSystemId,
				regionId,
				hospitalId,
				params,
			});
		}

		if (SectorTypes.DEPARTMENT === type) {
			editSectorResponse = await updateDepartment({
				healthSystemId,
				departmentId,
				hospitalId,
				params,
			});
		}

		if (SectorTypes.FLOOR === type) {
			editSectorResponse = await updateFloor({
				healthSystemId,
				departmentId,
				hospitalId,
				floorId,
				params,
			});
		}

		if (SectorTypes.ROOM === type) {
			const emptyUnit = !values.detailUnit || values.detailUnit?.trim() === '';
			const emptyRoom = !values.detailRoom || values.detailRoom?.trim() === '';
			const emptyBed = !values.detailBed || values.detailBed?.trim() === '';
			if (
				(!emptyUnit || !emptyRoom || !emptyBed) &&
				([TeamConfigurationIntegrationTypes.MDM, TeamConfigurationIntegrationTypes.PCARE].includes(integrationTypeId) ||
					getConfigurationValue(companyConfigurations[IntegrationTypesSettings.HL7_V2]))
			) {
				setAdtRoomLocationError(prevState => ({
					...prevState,
					...(emptyUnit && { unit: intl.formatMessage({ id: 'unitCannotBeEmpty' }) }),
					...(emptyRoom && { room: intl.formatMessage({ id: 'roomCannotBeEmpty' }) }),
					...(emptyBed && { bed: intl.formatMessage({ id: 'bedCannotBeEmpty' }) }),
				}));
				if (emptyUnit || emptyRoom || emptyBed) {
					return;
				}
			}
			if (
				[TeamConfigurationIntegrationTypes.MDM, TeamConfigurationIntegrationTypes.PCARE].includes(integrationTypeId) ||
				getConfigurationValue(companyConfigurations[IntegrationTypesSettings.HL7_V2])
			) {
				await editLocationDetails(values, emptyRoom && emptyUnit && emptyBed);
			}
			params.channelType = roomType?.value;
			params.tvSerialNumber = values?.tvSerialNumber;
			if (integrationTypeId === TeamConfigurationIntegrationTypes.SONIFI) {
				params.roomNumber = values.detailRoom;
			}
			if (props.sectorData.solHelloDeviceId) {
				params.deviceId = props.sectorData.solHelloDeviceId;
			}

			const dataToSend = {
				priority: +values.priority,
				isMultiBed,
			};
			if (roomType?.capacity) {
				dataToSend.capacity = +values.capacity;
			}
			if (roomType?.hasSchedule) {
				dataToSend.startTime = `${availableHoursStart.getHours()}:${
					availableHoursStart.getMinutes() !== 0 ? availableHoursStart.getMinutes() : '00'
				}`;
				dataToSend.endTime = `${availableHoursEnd.getHours()}:${
					availableHoursEnd.getMinutes() ? availableHoursEnd.getMinutes() : '00'
				}`;
				dataToSend.breakStartTime = `${breakTimeStart.getHours()}:${
					breakTimeStart.getMinutes() !== 0 ? breakTimeStart.getMinutes() : '00'
				}`;
				dataToSend.breakEndTime = `${breakTimeEnd.getHours()}:${breakTimeEnd.getMinutes() ? breakTimeEnd.getMinutes() : '00'}`;
			}
			const [configsResponse, editResponse] = await Promise.all([
				saveRoomConfiguration({
					healthSystemId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					params: dataToSend,
				}),
				updateRoom({ healthSystemId, hospitalId, departmentId, floorId, roomId, params }),
			]);

			if (configsResponse.error || editResponse.error) {
				setError(configsResponse.error.message || editResponse.error.message);
			} else {
				props.onEditSector();
				props.onModalClose();
			}
			setUpdateSectorLoading(false);
			return;
		}

		if (editSectorResponse.error) {
			setError(editSectorResponse.error.message);
		} else {
			props.onEditSector();
			props.onModalClose();
		}
		setUpdateSectorLoading(false);
	};

	const editLocationDetails = async (values, shouldDeleteLocations) => {
		const data = {
			healthSystemId,
			hospitalId,
			departmentId,
			floorId,
			roomId,
			params: { name: values.sectorName },
		};

		let response = null;

		if (!shouldDeleteLocations) {
			response = await updateRoomLocation({
				...data,
				params: {
					unit: values.detailUnit,
					room: values.detailRoom,
					bed: values.detailBed,
				},
			});
		}

		if (shouldDeleteLocations) {
			delete data.params;
			response = await deleteRoomLocation(data);
		}

		if (response.error) {
			setError(response.error.message);
		}
	};
	const getInitialData = () => ({
		sectorName: props.sectorData.name || '',
		tvSerialNumber: roomTvSerialNumber || '',
		capacity: capacityValue ? capacityValue.toString() : '',
		priority: priorityValue ? priorityValue.toString() : '',
		detailUnit: adtRoomLocation.unit,
		detailRoom: adtRoomLocation.room,
		detailBed: adtRoomLocation.bed,
	});

	const filterOption = (option, inputValue) => {
		const { label } = option;
		const roomTypeFound = intl.formatMessage({ id: label.props.id }).toLowerCase();
		return roomTypeFound.includes(inputValue.toLowerCase());
	};

	const getValidationSchema = () =>
		Yup.object().shape({
			sectorName: Yup.string()
				.required(intl.formatMessage({ id: 'sectorIsRequired' }))
				.min(
					2,
					intl.formatMessage({
						id: 'sectorNameMinLength',
					})
				)
				.max(
					100,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim()
				.test(
					'checkSectorExists',
					intl.formatMessage({ id: 'sectorExistsMessage' }, { value: intl.formatMessage({ id: props.sectorData.type }) }),
					value => {
						if (!value || value === props.sectorData.name) {
							return true;
						}
						const sector = findSectorById(treeData, props.sectorData[`${props.sectorData.type}Id`]);
						const isValid = checkIfSectorNameExists(sector, treeData, value);
						return isValid;
					}
				),
			tvSerialNumber: Yup.string()
				.min(6, `${intl.formatMessage({ id: 'minLengthIs' })} 6`)
				.notRequired()
				.trim(),
			capacity:
				roomType?.hasSchedule && roomType?.capacity
					? Yup.number()
							.required(`${intl.formatMessage({ id: 'capacity' })} ${intl.formatMessage({ id: 'isRequired' })}`)
							.min(1, `${intl.formatMessage({ id: 'minLengthIs' })} 1`)
							.max(roomType?.capacity, `${intl.formatMessage({ id: 'maxLengthIs' })} ${roomType?.capacity}`)
					: Yup.number(),
			priority:
				roomType &&
				![RoomTypes.DEFAULT.type, RoomTypes.BABY_ROOM.type, RoomTypes.KIOSK.type, RoomTypes.TRIAGE.type].includes(roomType?.value)
					? Yup.number()
							.required(`${intl.formatMessage({ id: 'priority' })} ${intl.formatMessage({ id: 'isRequired' })}`)
							.min(1, `${intl.formatMessage({ id: 'minLengthIs' })} 1`)
							.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`)
					: Yup.number(),
		});

	return (
		<Formik
			enableReinitialize
			initialValues={getInitialData()}
			validationSchema={getValidationSchema()}
			onSubmit={handleEditSector}>
			{formikProps => {
				const { values, errors, handleChange, handleSubmit } = formikProps;
				return (
					<Modal
						isLoading={isLoading}
						modalSelector='editSectorModal'
						display={true}
						position='right'
						onModalSubmit={handleSubmit}
						onModalClose={props.onModalClose}
						shouldSubmitOnEnter={false}
						primaryButtonLoading={updateSectorLoading}
						isSubmitDisabled={
							![RoomTypes.DEFAULT.type, RoomTypes.BABY_ROOM.type, RoomTypes.KIOSK.type].includes(roomType?.value) &&
							roomType?.hasSchedule &&
							[breakTimeStart, breakTimeEnd, availableHoursStart, availableHoursEnd].includes(null)
						}
						className='edit-sector-modal'>
						<Form title={props.sectorData.name} onSubmit={event => event.preventDefault()}>
							<Input
								type='text'
								validationOptions={{}}
								label={translate('name')}
								value={values.sectorName}
								onChange={handleChange}
								name='sectorName'
								placeholder={intl.formatMessage({ id: 'writeSectorName' })}
								error={errors.sectorName}
								variant='no-margin-left'
							/>
							{props.sectorData.type === SectorTypes.HOSPITAL && (
								<div className='hospital-logo'>
									<div className='primary-hospital-logo'>
										<div className='upload-logo'>
											<div>
												<p>{translate('primaryDisplayLogo')}</p>
												<p>{translate('primaryDisplayLogoDescription')}</p>
											</div>
											<div className='upload-logo-input'>
												<label htmlFor='uploadHospitalImage'>
													<span className='material-symbols-outlined'>upload</span>
													<span className='virtual-background-text'>{translate('upload')}</span>
												</label>
												<input
													id='uploadHospitalImage'
													type='file'
													accept='image/jpeg, image/jpg, image/png, image/svg+xml'
													onFocus={props.onFileInputFocus}
													onChange={evt => props.onSelectFile(evt, HospitalLogoType.PRIMARY)}
													className='hidden'
												/>
											</div>
										</div>
										{props.sectorData.primaryDisplayLogo && <img src={props.sectorData.primaryDisplayLogo} alt='primary_logo' />}
									</div>
									<div className='secondary-hospital-logo'>
										<div className='upload-logo'>
											<div>
												<p>{translate('secondaryDisplayLogo')}</p>
												<p>{translate('secondaryDisplayLogoDescription')}</p>
											</div>
											<div className='upload-logo-input'>
												<label htmlFor='uploadSecondaryHospitalImage'>
													<span className='material-symbols-outlined'>upload</span>
													<span className='virtual-background-text'>{translate('upload')}</span>
												</label>
												<input
													id='uploadSecondaryHospitalImage'
													type='file'
													accept='image/jpeg, image/jpg, image/png, image/svg+xml'
													onFocus={props.onFileInputFocus}
													onChange={evt => props.onSelectFile(evt, HospitalLogoType.SECONDARY_DISPLAY)}
													className='hidden'
												/>
											</div>
										</div>
										{props.sectorData.secondaryDisplayLogo && (
											<img src={props.sectorData.secondaryDisplayLogo} alt='secondary_logo' />
										)}
									</div>
								</div>
							)}
							<div className='input'>
								<label className='label'>{translate('location')}</label>
								<p>
									{intl.formatMessage(
										{ id: 'sectorIsLocatedAt' },
										{
											value: intl.formatMessage({
												id: props.sectorData.parentSectorType,
											}),
											value2: intl.formatMessage({
												id: props.sectorData.type,
											}),
										}
									)}
									: <span>{props.sectorData.parentSectorName}</span>
								</p>
							</div>
							{props.sectorData.type === SectorTypes.ROOM && (
								<div className='room-type'>
									{[UserRoles.SUPER_USER, UserRoles.ADMIN].includes(getUserRole()) && (
										<>
											<div className='feature-flags-category feature-flags'>
												<div className='feature-flag flex column-gap-15 no-margin-left'>
													<div>
														<div className='rounded-slider-switch' onClick={() => setIsMultiBed(prevState => !prevState)}>
															<input type='checkbox' checked={isMultiBed} onChange={() => null} />
															<span className='rounded-slider' />
														</div>
													</div>
													<div className='feature-description'>
														<p className='flex-1'>{translate('multipleBedRoom')}</p>
														<p>{translate('multipleBedRoomDesc')}</p>
													</div>
												</div>
											</div>
											<p>{translate('roomType')}</p>
											<Select
												options={
													workflowTypeId === HealthSystemType.DEFAULT
														? roomTypes
														: roomTypes.filter(room => room.value !== RoomTypes.BABY_ROOM.type)
												}
												value={roomType}
												isSearchable={true}
												onChange={type => {
													setRoomType(type);
													resetRoomConfigurationValues();
												}}
												filterOption={filterOption}
											/>
											{integrationTypeId === TeamConfigurationIntegrationTypes.EVIDEON && (
												<div className='top-30'>
													<Input
														type='text'
														validationOptions={{}}
														label={translate('tvSerialNumber')}
														value={values.tvSerialNumber}
														onChange={handleChange}
														name='tvSerialNumber'
														placeholder={intl.formatMessage({ id: 'writeTvSerialNumber' })}
														error={errors.tvSerialNumber}
														variant='no-margin-left'
													/>
												</div>
											)}
											{integrationTypeId === TeamConfigurationIntegrationTypes.SONIFI && (
												<div className='top-30'>
													<Input
														type='text'
														onChange={handleChange}
														name='detailRoom'
														placeholder={intl.formatMessage({ id: 'writeRoomNumber' })}
														value={values.detailRoom}
														label={intl.formatMessage({ id: 'roomNumber' })}
														validationOptions={{ maxLength: 63, required: true }}
														error={adtRoomLocationError.room}
													/>
												</div>
											)}
											{(getConfigurationValue(companyConfigurations[IntegrationTypesSettings.HL7_V2]) ||
												APP_CONFIG.epicAdventHSId ||
												[TeamConfigurationIntegrationTypes.MDM, TeamConfigurationIntegrationTypes.PCARE].includes(
													integrationTypeId
												)) && (
												<>
													<p className='font-bold'>{translate('bedDefinition')}</p>
													<div className='units-wrapper'>
														<Input
															type='text'
															onChange={handleChange}
															name='detailUnit'
															placeholder={intl.formatMessage({ id: 'unit' })}
															value={values.detailUnit}
															label={intl.formatMessage({ id: 'unit' })}
															validationOptions={{ maxLength: 63, required: true }}
															error={adtRoomLocationError.unit}
														/>
														<Input
															type='text'
															onChange={handleChange}
															name='detailRoom'
															placeholder={intl.formatMessage({ id: 'room' })}
															value={values.detailRoom}
															label={intl.formatMessage({ id: 'room' })}
															validationOptions={{ maxLength: 63, required: true }}
															error={adtRoomLocationError.room}
														/>
														<Input
															type='text'
															onChange={handleChange}
															name='detailBed'
															placeholder={intl.formatMessage({ id: 'bed' })}
															value={values.detailBed}
															label={intl.formatMessage({ id: 'bed' })}
															validationOptions={{ maxLength: 63, required: true }}
															error={adtRoomLocationError.bed}
														/>
													</div>
												</>
											)}
										</>
									)}
								</div>
							)}

							{roomType?.hasSchedule && (
								<>
									<div className='flex available-hours'>
										<label>
											{translate('startSchedule')}
											<DatePicker
												value={availableHoursStart}
												selected={availableHoursStart}
												placeholderText={intl.formatMessage({ id: 'from' })}
												onChange={date => setAvailableHoursStart(date)}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={30}
												timeCaption={intl.formatMessage({ id: 'time' })}
												dateFormat='h:mm aa'
												popperPlacement='top'
												minTime={setHours(setMinutes(new Date(), 0), 0)}
												maxTime={
													availableHoursEnd ? subtractMinutes(availableHoursEnd) : setHours(setMinutes(new Date(), 30), 23)
												}
												name='scheduleStart'
											/>
											{!availableHoursStart && errors.scheduleStart && (
												<small className='--red-color'>{errors.scheduleStart}</small>
											)}
										</label>
										<label>
											{translate('endSchedule')}
											<DatePicker
												value={availableHoursEnd}
												selected={availableHoursEnd}
												placeholderText={intl.formatMessage({ id: 'to' })}
												onChange={date => setAvailableHoursEnd(date)}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={30}
												timeCaption={intl.formatMessage({ id: 'time' })}
												dateFormat='h:mm aa'
												popperPlacement='top'
												minTime={
													availableHoursStart ? addMinutes(availableHoursStart, 15) : setHours(setMinutes(new Date(), 0), 0)
												}
												maxTime={setHours(setMinutes(new Date(), 30), 23)}
												name='scheduleEnd'
											/>
										</label>
									</div>

									<div className='flex available-hours'>
										<label>
											{translate('startBreak')}
											<DatePicker
												value={breakTimeStart}
												selected={breakTimeStart}
												placeholderText={intl.formatMessage({ id: 'from' })}
												onChange={date => setBreakTimeStart(date)}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={30}
												timeCaption={intl.formatMessage({ id: 'time' })}
												dateFormat='h:mm aa'
												popperPlacement='top'
												minTime={availableHoursStart ? new Date(availableHoursStart) : setHours(setMinutes(new Date(), 0), 0)}
												maxTime={availableHoursEnd ? new Date(availableHoursEnd) : setHours(setMinutes(new Date(), 45), 23)}
												name='breakStart'
											/>
										</label>
										<label>
											{translate('endBreak')}
											<DatePicker
												value={breakTimeEnd}
												selected={breakTimeEnd}
												placeholderText={intl.formatMessage({ id: 'to' })}
												onChange={date => setBreakTimeEnd(date)}
												showTimeSelect
												showTimeSelectOnly
												timeIntervals={30}
												timeCaption={intl.formatMessage({ id: 'time' })}
												dateFormat='h:mm aa'
												popperPlacement='top'
												minTime={breakTimeStart ? addMinutes(breakTimeStart, 15) : new Date(availableHoursStart)}
												maxTime={availableHoursEnd ? new Date(availableHoursEnd) : setHours(setMinutes(new Date(), 45), 23)}
												name='breakEnd'
											/>
										</label>
									</div>
								</>
							)}
							<br />
							{roomType &&
								![RoomTypes.DEFAULT.type, RoomTypes.BABY_ROOM.type, RoomTypes.KIOSK.type, RoomTypes.TRIAGE.type].includes(
									roomType?.value
								) && (
									<Input
										type='number'
										validationOptions={{}}
										label={translate('priority')}
										value={values.priority}
										onChange={handleChange}
										name='priority'
										placeholder={intl.formatMessage({ id: 'writePriority' })}
										error={errors.priority}
									/>
								)}
							{roomType?.capacity && (
								<Input
									type='number'
									validationOptions={{}}
									label={translate('capacity')}
									value={values.capacity}
									onChange={handleChange}
									name='capacity'
									placeholder={intl.formatMessage({ id: 'writeCapacity' })}
									error={errors.capacity}
								/>
							)}
							<br />
							<br />
						</Form>
						<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
					</Modal>
				);
			}}
		</Formik>
	);
};

export default EditSector;
