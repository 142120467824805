import { StyledCircularChartWrapper } from 'css/StyledComponents/index.js';
import { useIntl } from 'react-intl';
import { Chart } from 'primereact/chart.js';
import { getDurationFormat } from 'infrastructure/helpers/dateHelper.js';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Grid, Loader } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';

const ChartWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-m);
`;
/**
 * @type {import('styled-components').StyledComponent<"div", any, { $chartColor: string }, never>}
 */
const LegendMarker = styled.span`
	width: var(--spacing-xl);
	height: var(--spacing-m);
	background: ${props => props.$chartColor};
	border-radius: var(--spacing-s);
	margin-right: var(--spacing-m);
`;

const noValue = ['N/A', 0];

const AiSummaryChart = ({
	total,
	chartData,
	dataSets,
	isLoading,
	chartDetails,
	title,
	isDarkMode = false,
	displayInSeconds = true,
	hasPercentageValue = true,
}) => {
	const intl = useIntl();

	const data = useMemo(() => {
		const labels = chartDetails.map(item => (!hasPercentageValue ? intl.formatMessage({ id: item.label }) : item.label));
		const datasets = [
			{
				data: dataSets,
				backgroundColor: chartDetails.map(item => item.color),
				borderColor: 'transparent',
			},
		];

		return { labels, datasets };
	}, [chartDetails, dataSets]);

	const options = {
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		cutoutPercentage: 80,
		tooltips: {
			...(displayInSeconds && {
				callbacks: {
					label: (tooltip, data) => getDurationFormat(data.datasets[0].data[tooltip.index]),
				},
			}),
		},
		animation: {
			duration: 0,
		},
	};

	return (
		<ChartWrapper>
			<h3 className='semi-bold font-14 flex title'>{translate(title)}</h3>
			{!isLoading && (
				<StyledCircularChartWrapper $isDarkMode={isDarkMode} $isDashboard={true}>
					<div>
						<p className='flex column-direction'>
							{!noValue.includes(total) && (
								<>
									<span>{intl.formatMessage({ id: 'total' })}</span>
									<h4>{total}</h4>
								</>
							)}
						</p>
						{!noValue.includes(total) && (
							<Chart type='RoundedDoughnut' data={data} options={options} style={{ width: '100%', height: '100%' }} />
						)}
						{(!total || noValue.includes(total)) && (
							<>
								<p className='semi-bold'>{translate('noData')}</p>
							</>
						)}
					</div>
					<div className='flex column-direction flex-justify-center'>
						{chartDetails.map(chart => (
							<div key={chart.id} className='flex flex-align-center'>
								<LegendMarker $chartColor={chart.color} />
								<p className='font-13 text-align-left'>
									<div>
										{intl.formatMessage({ id: chart.label })}:{' '}
										<span className='semi-bold'>
											{displayInSeconds ? getDurationFormat(chartData?.[chart?.label]) : chartData?.[chart?.label]}
										</span>{' '}
										<span className='semi-bold'>
											(
											{hasPercentageValue
												? chartData?.[chart?.percentage]
												: Math.round(((chartData?.[chart?.label] || 0) / total) * 100) || 0}
											%)
										</span>
									</div>
									{chart?.bedAndChair && (
										<>
											<div>
												{translate('inBed')}:{' '}
												<span className='semi-bold'>
													{getDurationFormat(chart?.label === 'lying' ? chartData?.bedLying : chartData?.bedSitting)}
												</span>
											</div>
											<div>
												{translate('inChair')}:{' '}
												<span className='semi-bold'>
													{getDurationFormat(chart?.label === 'lying' ? chartData?.chairLying : chartData?.chairSitting)}
												</span>
											</div>
										</>
									)}
								</p>
							</div>
						))}
					</div>
				</StyledCircularChartWrapper>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
		</ChartWrapper>
	);
};

export default AiSummaryChart;
