import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import moment from 'moment';
import _ from 'lodash';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { checkUserEmailExists, getCountries, getUserAddress } from 'api/users.js';
import { getSpecialties, getStates } from 'api/doctorOnBoarding.js';
import { Loader, Button, Grid, Alert } from 'components/index.js';
import Genders from 'components/Genders.jsx';
import { genderItems } from 'constants/genderItems.js';
import { getDoctorProfile, getLanguages, updateDoctorProfile } from 'api/doctors.js';
import { getCompanyId, getUserId, getUserInfo, getUserProfile, loadUserInfo } from 'infrastructure/auth.js';
import ModalSelector from 'components/ModalSelector.jsx';
import Licenses from 'components/Doctors/Licenses.jsx';
import EducationHistory from 'components/Doctors/EducationHistory.jsx';
import EmploymentHistory from 'components/Doctors/EmploymentHistory.jsx';
import { Address, Education, Employment, License, Country, HealthcareErrorCode, Specialties } from 'constants/enums.js';
import { setDoctorSpecialty, stringToCamelCase, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import { uploadProfilePic } from 'api/media.js';
import ImageUploader from 'containers/ImageUploader.jsx';

const CompleteRegistration = () => {
	const [doctorProfile, setDoctorProfile] = useState(null);
	const [profileInfo, setProfileInformation] = useState(null);
	const [countries, setCountries] = useState([]);
	const [specialties, setSpecialties] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isLanguagesModalVisible, setIsLanguagesModalVisible] = useState(false);
	const [states, setStates] = useState([]);
	const [profilePicture, setProfilePicture] = useState('');
	const [profilePictureError, setProfilePictureError] = useState('');
	const [idNumberExistError, setIdNumberExistError] = useState(null);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [userAddress, setUserAddress] = useState(null);

	const history = useHistory();
	const intl = useIntl();
	const { current: userProfile } = useRef(getUserInfo());
	const companyId = getCompanyId();

	useEffect(() => {
		const getProfileInformationDetails = async () => {
			const [countriesList, doctorDetails, profileInformation, specialtiesList, languagesList, statesList, addressResponse] =
				await Promise.all([
					getCountries(),
					getDoctorProfile(getUserId()),
					getUserProfile(getUserId(), companyId),
					getSpecialties(),
					getLanguages(),
					getStates(),
					getUserAddress(getUserId()),
				]);
			const responseError =
				countriesList.error ||
				doctorDetails.error ||
				profileInformation.error ||
				specialtiesList.error ||
				languagesList.error ||
				statesList.error ||
				addressResponse.error;
			if (responseError) {
				setError(responseError.message);
				setIsLoading(false);
				return;
			}
			if (doctorDetails.doctorProfile.specialty) {
				setDoctorSpecialty(doctorDetails.doctorProfile.specialty);
				history.push('/');
			} else {
				setCountries(countriesList.countries);
				setDoctorProfile(doctorDetails.doctorProfile);
				setProfileInformation(profileInformation);
				setSpecialties(specialtiesList.specialties);
				setLanguages(languagesList.languages);
				setStates(statesList.states);
				setUserAddress(addressResponse.address);
				setIsLoading(false);
			}
		};
		getProfileInformationDetails();
	}, [history]);

	const getInitialValues = () => {
		if (profileInfo) {
			return {
				firstName: profileInfo.firstName,
				lastName: profileInfo.lastName,
				genderId: '',
				dateOfBirth: '',
				email: profileInfo.email,
				profilePicture: '',
				specialty: Specialties.OTHER,
				country1: '',
				city: userAddress?.city,
				zipCode: userAddress?.zipCode,
				address: userAddress?.address1,
				phoneNumber: profileInfo?.phoneNr,
				employmentHistory: doctorProfile.doctorExperiences.length
					? doctorProfile.doctorExperiences.map(
							({ employmentCompany, stateId, position, startDate, endDate, isCurrentPosition, city }) => ({
								company: employmentCompany,
								employmentStateId: stateId,
								position,
								startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
								endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
								currentlyWorking: isCurrentPosition || false,
								country4: countries.find(item => item.name === city)?.id,
							})
					  )
					: [{ currentlyWorking: false }],
				educationHistory: doctorProfile.userEducations.length
					? doctorProfile.userEducations.map(({ school, educationTypeId, stateId, startDate, endDate, country }) => ({
							school,
							degree: educationTypeId,
							educationStateId: stateId,
							start: startDate && moment(startDate).format('YYYY-MM-DD'),
							end: endDate && moment(endDate).format('YYYY-MM-DD'),
							country3: countries.find(item => item.name === country)?.id,
							currentlyStudying: !endDate,
					  }))
					: [{ currentlyStudying: false }],
				licenseHistory: doctorProfile.doctorStateLicences.length
					? doctorProfile.doctorStateLicences.map(({ countryId, stateId, licenceNr }) => ({
							country2: countryId,
							licenseStateId: stateId,
							licenseNo: licenceNr,
					  }))
					: [{}],
			};
		}
		return {
			firstName: '',
			lastName: '',
			dateOfBirth: '',
			email: '',
			profilePicture: '',
			specialty: '',
			country1: '',
			city: '',
			zipCode: '',
			address: '',
			licenseHistory: [],
			educationHistory: [],
			employmentHistory: [],
		};
	};

	const validateEmail = async val => {
		if (!val || val === userProfile?.email) {
			return true;
		}
		let emailExists = false;
		const schema = Yup.string().email().required();
		if (await schema.isValid(val)) {
			const response = await checkUserEmailExists(val);
			if (response.error) {
				setError(response.error.message);
			} else {
				emailExists = response;
			}
		}
		return !emailExists;
	};

	const validateForm = () => {
		return Yup.object().shape(
			{
				firstName: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteFirstName' }))
					.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
					.trim(),
				lastName: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
					.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
					.trim(),
				email: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteEmail' }))
					.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
					.email(intl.formatMessage({ id: 'invalidEmail' }))
					.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val))
					.trim(),
				idNumber: Yup.string()
					.min(
						10,
						intl.formatMessage({
							id: 'idNumberLength',
						})
					)
					.max(
						10,
						intl.formatMessage({
							id: 'idNumberLength',
						})
					),
				dateOfBirth: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteDateOfBirth' }))
					.test(
						'dateOfBirth',
						intl.formatMessage({ id: 'checkDateOfBirth' }),
						value => moment().diff(moment(value), 'years') >= 18
					),
				country1: Yup.string()
					.when(['address', 'zipCode', 'city'], {
						is: (address, zipCode, city) => (address && address.trim() !== '') || zipCode || city,
						then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
					})
					.nullable(),
				address: Yup.string()
					.when(['country1', 'zipCode', 'city'], {
						is: (country1, zipCode, city) => country1 || zipCode || city,
						then: () => Yup.string().required(intl.formatMessage({ id: 'addressRequired' })),
					})
					.nullable(),
				addressStateId: Yup.string().when(Address.COUNTRY, {
					is: country1 => country1 === Country.USA,
					then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
				}),
				employmentHistory: Yup.array().of(
					Yup.object().shape(
						{
							company: Yup.string().when([Employment.POSITION, Employment.COUNTRY, Employment.START_DATE, Employment.END_DATE], {
								is: (position, startDate, endDate) => position || startDate || endDate,
								then: () =>
									Yup.string()
										.required(intl.formatMessage({ id: 'pleaseWriteCompany' }))
										.trim(),
							}),
							position: Yup.string().when([Employment.COMPANY, Employment.COUNTRY, Employment.START_DATE, Employment.END_DATE], {
								is: (company, startDate, endDate) => company || startDate || endDate,
								then: () =>
									Yup.string()
										.required(intl.formatMessage({ id: 'pleaseWritePosition' }))
										.trim(),
							}),
							country4: Yup.string().when([Employment.COMPANY, Employment.POSITION, Employment.START_DATE, Employment.END_DATE], {
								is: (company, position, startDate) => company || position || startDate,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
							}),
							startDate: Yup.date().when([Employment.COMPANY, Employment.COUNTRY, Employment.POSITION, Employment.END_DATE], {
								is: (company, position, endDate) => company || position || endDate,
								then: () => Yup.date().required(intl.formatMessage({ id: 'pleaseWriteStartDate' })),
							}),
							endDate: Yup.date().when(
								[
									Employment.COMPANY,
									Employment.COUNTRY,
									Employment.POSITION,
									Employment.START_DATE,
									Employment.CURRENTLY_WORKING,
								],
								{
									is: (company, country, position, startDate, currentlyWorking) =>
										(company || country || position || startDate) && !currentlyWorking,
									then: () => Yup.date().required(intl.formatMessage({ id: 'pleaseWriteEndDate' })),
								}
							),
							employmentStateId: Yup.string().when(Employment.COUNTRY, {
								is: country4 => country4 === Country.USA.toString(),
								then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
							}),
						},
						[
							[Employment.COUNTRY, Employment.START_DATE],
							[Employment.COUNTRY, Employment.END_DATE],
							[Employment.COUNTRY, Employment.POSITION],
							[Employment.COUNTRY, Employment.COMPANY],
							[Employment.START_DATE, Employment.END_DATE],
							[Employment.START_DATE, Employment.POSITION],
							[Employment.START_DATE, Employment.COMPANY],
							[Employment.END_DATE, Employment.POSITION],
							[Employment.END_DATE, Employment.COMPANY],
							[Employment.COMPANY, Employment.POSITION],
						]
					)
				),
				educationHistory: Yup.array().of(
					Yup.object().shape(
						{
							school: Yup.string().when([Education.COUNTRY, Education.START, Education.END, Education.DEGREE], {
								is: (degree, country3, start, end) => country3 || start || end || degree,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseWriteSchool' })),
							}),

							degree: Yup.string().when([Education.COUNTRY, Education.START, Education.END, Education.SCHOOL], {
								is: (country3, start, end, school) => country3 || start || end || school,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectDegree' })),
							}),
							country3: Yup.string().when([Education.DEGREE, Education.START, Education.END, Education.SCHOOL], {
								is: (degree, start, end, school) => degree || start || end || school,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
							}),
							start: Yup.date().when([Education.DEGREE, Education.COUNTRY, Education.END, Education.SCHOOL], {
								is: (degree, country3, end, school) => degree || country3 || end || school,
								then: () => Yup.date().required(intl.formatMessage({ id: 'pleaseWriteStartDate' })),
							}),
							end: Yup.date().when(
								[Education.DEGREE, Education.COUNTRY, Education.START, Education.SCHOOL, Education.CURRENTLY_STUDYING],
								{
									is: (degree, country3, start, school, currentlyStudying) =>
										(degree || country3 || start || school) && !currentlyStudying,
									then: () => Yup.date().required(intl.formatMessage({ id: 'pleaseWriteEndDate' })),
								}
							),
							educationStateId: Yup.string().when(Education.COUNTRY, {
								is: country3 => country3 === Country.USA.toString(),
								then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
							}),
						},
						[
							[Education.SCHOOL, Education.COUNTRY],
							[Education.SCHOOL, Education.DEGREE],
							[Education.SCHOOL, Education.END],
							[Education.SCHOOL, Education.START],
							[Education.COUNTRY, Education.DEGREE],
							[Education.COUNTRY, Education.END],
							[Education.COUNTRY, Education.START],
							[Education.DEGREE, Education.END],
							[Education.DEGREE, Education.START],
							[Education.END, Education.START],
						]
					)
				),
				licenseHistory: Yup.array().of(
					Yup.object().shape(
						{
							country2: Yup.string().when([License.LICENSE_NO], {
								is: licenseNo => licenseNo,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
							}),
							licenseNo: Yup.string().when([License.COUNTRY], {
								is: country2 => country2,
								then: () =>
									Yup.string()
										.required(intl.formatMessage({ id: 'pleaseWriteLicense' }))
										.trim(),
							}),
							licenseStateId: Yup.string()
								.nullable()
								.when(License.COUNTRY, {
									is: country2 => country2 === Country.USA.toString(),
									then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
								}),
						},
						[[License.COUNTRY, License.LICENSE_NO]]
					)
				),
			},
			[
				[Address.COUNTRY, Address.CITY],
				[Address.COUNTRY, Address.ZIP_CODE],
				[Address.COUNTRY, Address.ADDRESS_1],
				[Address.CITY, Address.ZIP_CODE],
				[Address.CITY, Address.ADDRESS_1],
				[Address.ZIP_CODE, Address.ADDRESS_1],
			]
		);
	};

	const onSubmitHandler = async values => {
		setIsBtnLoading(true);
		const userId = getUserId();
		const {
			firstName,
			lastName,
			genderId,
			dateOfBirth,
			email,
			specialty,
			country1,
			city,
			zipCode,
			address,
			idNumber,
			userLanguage,
			addressStateId,
			phoneNumber,
		} = values;
		const params = {
			firstName,
			lastName,
			countryId: country1 ? country1 : 0,
			email,
			genderId: genderId ? parseInt(genderId, 10) : null,
			dateOfBirth,
			userAddress:
				country1 && address
					? {
							countryId: parseInt(country1, 10),
							city,
							zipCode: zipCode ? zipCode.toString() : '',
							address1: address,
							stateId: addressStateId,
					  }
					: null,
			profilePicture,
			idCard: idNumber?.toString() ?? null,
			phoneNr: phoneNumber?.toString() ?? null,
			specialtyId: specialty,
			userLanguages:
				userLanguage &&
				userLanguage.reduce((result, element) => {
					if (element) {
						result.push({ userId, languageId: element.id });
					}
					return result;
				}, []),
			userEducations: values.educationHistory.map(
				({ degree, country3, start, end, school, currentlyStudying, educationStateId }) =>
					(degree || country3 || start || end) && {
						educationTypeId: degree,
						userId,
						school,
						country: countries.find(item => item.id === country3)?.name,
						city: 'city',
						startDate: start,
						endDate: currentlyStudying ? null : end,
						isCurrentStudying: currentlyStudying,
						stateId: educationStateId,
					}
			),
			doctorExperiences: values.employmentHistory.map(
				({ company, position, country4, startDate, endDate, currentlyWorking, employmentStateId }) =>
					(company || position || country4 || startDate) && {
						doctorId: userId,
						employmentCompany: company,
						position,
						city: countries.find(item => item.id === country4)?.name,
						startDate,
						endDate: currentlyWorking ? null : endDate,
						isCurrentPosition: currentlyWorking,
						stateId: employmentStateId,
					}
			),
			doctorStateLicences: values.licenseHistory.map(
				({ country2, licenseNo, licenseStateId }) =>
					(country2 || licenseNo) && {
						countryId: country2,
						licenceNr: licenseNo,
						doctorId: userId,
						stateId: licenseStateId,
					}
			),
		};

		const newParams = {
			...params,
			doctorExperiences: params.doctorExperiences.filter(experience => experience),
			userEducations: params.userEducations.filter(education => education),
			doctorStateLicences: params.doctorStateLicences.filter(license => license),
		};
		const response = await updateDoctorProfile(newParams);
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.DOCTOR_ID_CARD_EXISTS) {
				setIdNumberExistError(translate('idNumberExists'));
			} else {
				setError(response.error.message);
			}
			setIsBtnLoading(false);
			return;
		}
		await loadUserInfo();
		setDoctorSpecialty(specialty);
		history.push('/');
	};

	const onModalClose = languagesProps => {
		languagesProps.setFieldValue('userLanguage', []);
		setIsLanguagesModalVisible(prevState => !prevState);
	};

	const transformArray = array => array.map(item => ({ value: item.id, label: item.name }));

	const translateAndTransformArray = array => array.map(item => ({ value: item.id, label: translate(_.camelCase(item.name)) }));

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<Formik
					initialValues={getInitialValues()}
					onSubmit={onSubmitHandler}
					validateOnBlur={false}
					validateOnChange={true}
					validationSchema={validateForm}>
					{formikProps => {
						const { values, handleChange, errors, handleSubmit, setFieldValue, touched } = formikProps;
						return (
							<div className='flex flex-align-center column-direction confirm-registration'>
								<div>
									<div className='flex flex-justify-center flex-align-center column-direction'>
										<img className='confirm-registration-logo' src={`${healthCareCdnUrl}hellocare-logo.svg`} alt='logo' />
										<h1 className='title'>{translate('beforeContinue')}</h1>
										<p className='description'>{translate('completeRegistration')}</p>
										<label>{translate('profilePicture')}</label>
										<div className='flex profile-info-image flex-align-center column-direction'>
											<ImageUploader
												setError={setProfilePictureError}
												existingLogo=''
												setProfilePicture={setProfilePicture}
												uploadProfilePic={uploadProfilePic}
												error={profilePictureError}
												sizeInMB={2}
												buttonText={intl.formatMessage({ id: 'uploadPicture' })}
											/>
										</div>
									</div>
									<div className='flex personal-details-wrapper'>
										<div className='flex column-direction personal-details'>
											<label>*{translate('firstName')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'firstName' })}
												maxLength={30}
												value={values.firstName}
												onChange={handleChange}
												name='firstName'
											/>
											<span className='red-error'>{errors.firstName}</span>
										</div>
										<div className='flex column-direction personal-details'>
											<label>*{translate('lastName')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'lastName' })}
												maxLength={30}
												value={values.lastName}
												onChange={handleChange}
												name='lastName'
											/>
											<span className='red-error'>{errors.lastName}</span>
										</div>
									</div>
									<div className='flex personal-details-wrapper'>
										<div className='flex column-direction personal-details'>
											<label>{translate('idNumber')}</label>
											<input
												type='number'
												placeholder={intl.formatMessage({ id: 'idNumber' })}
												value={values.idNumber}
												onChange={handleChange}
												name='idNumber'
												onKeyDown={event => validateMaxLength(event, 10)}
											/>
											<span className='red-error'>{errors.idNumber}</span>
											{idNumberExistError && <span className='red-error'>{idNumberExistError}</span>}
										</div>
										<div className='flex column-direction personal-details'>
											<label>*{translate('emailAddress')}</label>
											<input
												type='email'
												placeholder={intl.formatMessage({ id: 'emailAddress' })}
												value={values.email}
												readOnly={values.email}
												onChange={handleChange}
												maxLength={100}
												name='email'
											/>
											<span className='red-error'>{errors.email}</span>
										</div>
									</div>
									<div className='flex personal-details-wrapper'>
										<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
											<label>{translate('specialty')}</label>
											<Select
												value={translateAndTransformArray(specialties).filter(option => option.value === values.specialty)}
												placeholder={intl.formatMessage({ id: 'selectSpecialty' })}
												classNamePrefix='react-select'
												options={translateAndTransformArray(specialties)}
												onChange={event => setFieldValue('specialty', event?.value)}
												isSearchable
												isClearable
												name='specialty'
											/>
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('sex')}</label>
											<Genders
												items={genderItems}
												handleChange={handleChange}
												errors={errors}
												value={values.genderId}
												isCompleteRegistration={true}
											/>
										</div>
									</div>
									<div className='flex personal-details-wrapper position-relative'>
										<div className='flex column-direction personal-details'>
											<label>*{translate('birthDate')}</label>
											<input
												type='date'
												placeholder={intl.formatMessage({ id: 'selectDate' })}
												value={values.dateOfBirth}
												onChange={handleChange}
												name='dateOfBirth'
												max={moment().subtract(18, 'years').format('YYYY-MM-DD')}
											/>
											{touched.dateOfBirth && <span className='red-error'>{errors.dateOfBirth}</span>}
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('phoneNumber')}</label>
											<input
												type='number'
												placeholder={intl.formatMessage({ id: 'phoneNumber' })}
												value={values.phoneNumber}
												onChange={handleChange}
												name='phoneNumber'
												onKeyDown={event => validateMaxLength(event, 15)}
											/>
										</div>
									</div>
									<div className='flex personal-details-wrapper'>
										<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
											<label>{translate('selectCountry')}</label>
											<Select
												value={transformArray(countries).filter(option => option.value === values.country1)}
												placeholder={intl.formatMessage({ id: 'selectCountry' })}
												classNamePrefix='react-select'
												options={transformArray(countries)}
												onChange={event => setFieldValue('country1', event?.value)}
												isSearchable
												isClearable
												name='country1'
											/>
											<span className='red-error'>{errors.country1}</span>
											{values.country1 === Country.USA && (
												<>
													<label>{translate('selectState')}</label>
													<Select
														value={transformArray(states).filter(option => option.value === values.addressStateId)}
														placeholder={intl.formatMessage({ id: 'selectState' })}
														classNamePrefix='react-select'
														options={transformArray(states)}
														onChange={event => setFieldValue('addressStateId', event?.value)}
														isSearchable
														isClearable
														name='addressStateId'
													/>
													<span className='red-error'>{errors.addressStateId}</span>
												</>
											)}
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('city')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'city' })}
												value={values.city}
												onChange={handleChange}
												maxLength={30}
												name='city'
											/>
										</div>
									</div>
									<div className='flex personal-details-wrapper'>
										<div className='flex column-direction personal-details'>
											<label>Zip</label>
											<input
												type='number'
												placeholder='Zip'
												value={values.zipCode}
												onChange={handleChange}
												maxLength={10}
												name='zipCode'
												onKeyDown={event => validateMaxLength(event, 10)}
											/>
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('address')}</label>
											<input
												type='text'
												placeholder={`${intl.formatMessage({ id: 'address' })}`}
												value={values.address}
												onChange={handleChange}
												maxLength={50}
												name='address'
											/>
											<span className='red-error'>{errors.address}</span>
										</div>
									</div>
									<div className='flex personal-details-wrapper'>
										<div className='flex column-direction personal-details'>
											<label>{translate('languages')}</label>
											<div className='languages-list'>
												{!values.userLanguage && <span>{translate('languages')}</span>}
												{values.userLanguage &&
													values.userLanguage.map(
														language =>
															language && (
																<p key={language.id}>
																	{language.checked ? intl.formatMessage({ id: stringToCamelCase(language.name) }) : ''}
																</p>
															)
													)}
											</div>
										</div>
										<div className='flex'>
											<Button
												className='select-languages'
												text={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({ id: 'languages' })}  `}
												imgIcon={`${healthCareCdnUrl}add-new.svg`}
												icon='mic'
												onClick={() => setIsLanguagesModalVisible(prevState => !prevState)}
											/>
										</div>
									</div>
									<Licenses
										countries={countries}
										onChange={handleChange}
										formikProps={formikProps}
										transformArray={transformArray}
										states={states}
									/>
									<EducationHistory
										countries={countries}
										onChange={handleChange}
										formikProps={formikProps}
										transformArray={transformArray}
										translateAndTransformArray={translateAndTransformArray}
										states={states}
									/>
									<EmploymentHistory
										countries={countries}
										onChange={handleChange}
										formikProps={formikProps}
										transformArray={transformArray}
										states={states}
									/>
									<div className='flex right-align-content'>
										<Button
											text={translate('saveAndContinue')}
											onClick={handleSubmit}
											className='flex flex-justify-center flex-align-center complete-registration-btn'
											isLoading={isBtnLoading}
										/>
									</div>
									{isLanguagesModalVisible && (
										<ModalSelector
											onModalClose={() => onModalClose(formikProps)}
											onModalSubmit={() => setIsLanguagesModalVisible(false)}
											title={translate('languages')}
											list={languages}
											formikProps={formikProps}
											valueField='userLanguage'
											display={isLanguagesModalVisible}
											value={formikProps.values.userLanguage}
										/>
									)}
								</div>
							</div>
						);
					}}
				</Formik>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default CompleteRegistration;
