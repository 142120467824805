import React, { useState, useRef, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { enums } from '@solaborate/calls';
import { ToggleGroup, Modal, Button, Icon, Input } from 'calls/components/index.js';
import { CameraMaxZoomLevels, CameraTypes } from 'calls/enums/index.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import SocketEvents from 'constants/socket-events.js';
import { CameraType, CameraZoomLevels } from 'constants/enums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { Dropdown } from 'components/index.js';
import {
	StyledPTZ,
	StyledCameraControls,
	StyledCameraBookmarks,
	StyledAddNewCameraBookmarksButton,
} from 'css/StyledComponents/index.js';
import CustomButton from 'components/Button.jsx';

const { CameraMoveDirections, CameraEventActions } = enums;

/**
 * @param {object} props
 */
const CameraControls = ({
	feed,
	sendCameraEvent,
	conferenceInfo,
	showPTZ,
	showDeviceControlsLockedModal,
	setActiveDevice,
	isDarkMode,
	isInDoubleCall,
}) => {
	const socket = useContext(SocketContext);
	const [isRebootHuddleCamModalOpen, setIsRebootHuddleCamModalOpen] = useState(false);
	const [isSwitchDeviceDisabled, setIsSwitchDeviceDisabled] = useState(false);
	const [toggleBookmark, setToggleBookmark] = useState(false);
	const [bookmark, setBookmark] = useState('');
	const [bookmarkToDelete, setBookmarkToDelete] = useState(null);
	const [isDeleteBookmarkModalOpen, setIsDeleteBookmarkModalOpen] = useState(false);
	const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
	const [sliderRange, setSliderRange] = useState(feed.zoomLevel);
	const [activePreset, setActivePreset] = useState(null);
	const locale = getPreferredLanguageLocale();
	const bookmarksLimit = 9;
	const intl = useIntl();

	const disabledSwitchTimeout = useRef(null);
	const prevActionRef = useRef(null);

	const [bookmarks, setBookmarks] = useState([]);

	const bookmarkEvents = {
		REMOVE_BOOKMARK: 'removeBookmark',
		EDIT_BOOKMARK: 'editBookmark',
		MOVE_TO_BOOKMARK: 'moveToBookmark',
	};

	useEffect(() => {
		const onMediaControlsResponse = data => {
			if (data.participantId === feed.participantId) {
				if (data.command === enums.MediaControlsCommands.BOOKMARK_LIST) {
					if (activePreset) {
						const isActivePresetInList = data.data.some(item => item.id === activePreset?.id);
						if (!isActivePresetInList) {
							setActivePreset(null);
						}
					}
					setBookmarks(data.data);
				}

				if (data.command === enums.MediaControlsCommands.MOVE_TO_BOOKMARK) {
					const activeBookmark = bookmarks.find(x => x.id === data.data);
					setActivePreset(activeBookmark);
				}

				if (data.command === enums.MediaControlsCommands.ACTIVE_DEVICE) {
					setActiveDevice(feed.deviceId, data.data?.name);
					setActivePreset(null);
				}
			}
		};

		socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
		socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);

		return () => {
			socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
			socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
		};
	}, [socket, bookmarks, activePreset, feed.participantId, feed.deviceId]);

	const onInitialState = data => {
		if (+data.helloDeviceId === +feed.deviceId) {
			setBookmarks(data.bookmarkList);
		}
	};

	useEffect(() => {
		setSliderRange(feed.zoomLevel);
	}, [feed.zoomLevel, showDeviceControlsLockedModal]);

	useEffect(() => {
		return () => {
			if (disabledSwitchTimeout.current) {
				clearTimeout(disabledSwitchTimeout.current);
			}
		};
	}, []);

	const sendDirection = async (direction, action) => {
		if (
			(prevActionRef.current === CameraEventActions.STOP && action === CameraEventActions.STOP) ||
			(feed.cameraType === CameraType.HELLO && feed.disabledTiltDirections[direction])
		) {
			return;
		}
		sendCameraEvent(SocketEvents.HelloDevice.MOVE_CAMERA, {
			direction,
			action,
		});
		prevActionRef.current = action;
	};

	const zoomCameraEvent = async value => {
		sendCameraEvent(SocketEvents.HelloDevice.ZOOM_CAMERA, {
			level: value,
			participantId: feed.participantId,
			conferenceId: conferenceInfo.conferenceId,
			helloDeviceId: feed.deviceId,
		});
	};

	const sendBookmarkEvent = async (command, id) => {
		sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			command,
			type: 'camera',
			data: id,
			participantId: conferenceInfo.participantId,
			conferenceId: conferenceInfo.conferenceId,
			actioneeParticipantId: feed.participantId,
		});
	};

	const resetCamera = () => {
		sendCameraEvent(SocketEvents.HelloDevice.COMMAND, {
			participantId: feed.participantId,
			conferenceId: conferenceInfo.conferenceId,
			helloDeviceId: feed.deviceId,
		});
		setIsRebootHuddleCamModalOpen(false);
	};

	const addNewBookmark = text => {
		if (bookmarks.length >= bookmarksLimit) {
			return;
		}
		sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			command: 'addBookmark',
			type: 'camera',
			data: text,
			participantId: conferenceInfo.participantId,
			conferenceId: conferenceInfo.conferenceId,
			actioneeParticipantId: feed.participantId,
		});
	};

	const switchCamera = () => {
		if (isSwitchDeviceDisabled) {
			return;
		}

		setIsSwitchDeviceDisabled(true);

		// If no response is received from Hello enable switch after 3 seconds
		if (disabledSwitchTimeout.current) {
			clearTimeout(disabledSwitchTimeout.current);
		}

		disabledSwitchTimeout.current = setTimeout(() => {
			setIsSwitchDeviceDisabled(false);
		}, 3000);

		sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, {
			participantId: feed.participantId,
			conferenceId: conferenceInfo.conferenceId,
			helloDeviceId: feed.deviceId,
		});
		setActivePreset(null);
	};

	const filteredDevices = feed.mediaDevices
		? feed.mediaDevices.filter(device => [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(device.name))
		: [];

	const getCameraName = (name, type) => {
		if (CameraTypes.HUDDLE === type) {
			return name || '20x';
		}
		return name || type;
	};

	const saveHomePosition = () => {
		sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			command: enums.MediaControlsCommands.UPDATE_HOME_POSITION,
			type: 'camera',
			data: 'default',
			participantId: conferenceInfo.participantId,
			conferenceId: conferenceInfo.conferenceId,
			actioneeParticipantId: feed.participantId,
		});
	};

	const moveToHomePosition = async event => {
		event.stopPropagation();
		const response = await sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			command: enums.MediaControlsCommands.RECENTER,
			type: 'camera',
			data: 'recenter',
			participantId: conferenceInfo.participantId,
			conferenceId: conferenceInfo.conferenceId,
			actioneeParticipantId: feed.participantId,
		});
		if (!response.ok) {
			// error
		}
	};

	if (!showPTZ) {
		return <></>;
	}

	const getTopMargin = () => {
		let topMargin = 35;

		if (feed.lastCondition?.code || (!feed.lastCondition?.code && isInDoubleCall)) {
			topMargin = 70;
		}

		if (feed.lastCondition?.code && isInDoubleCall) {
			topMargin = 95;
		}

		return topMargin;
	};

	const handleDeleteBookmark = (itemId, itemName) => {
		setBookmarkToDelete({ itemId, itemName });
		setIsDeleteBookmarkModalOpen(true);
	};

	const directionIcon = {
		[CameraMoveDirections.UP]: 'keyboard_arrow_up',
		[CameraMoveDirections.RIGHT]: 'keyboard_arrow_right',
		[CameraMoveDirections.LEFT]: 'keyboard_arrow_left',
		[CameraMoveDirections.DOWN]: 'keyboard_arrow_down',
	};

	return (
		<StyledPTZ $top={getTopMargin()} $isDarkMode={isDarkMode} $isRightToLeft={locale === LOCALES.ARABIC}>
			{filteredDevices.length > 1 && (
				<header>
					<ToggleGroup>
						{filteredDevices.map(({ id, name, capabilities }) => (
							<ToggleGroup.Item
								key={id}
								onChange={switchCamera}
								disabled={isSwitchDeviceDisabled}
								name='camera'
								checked={feed.cameraType === name}>
								{getCameraName(capabilities.customName, name)}
							</ToggleGroup.Item>
						))}
					</ToggleGroup>
				</header>
			)}
			<StyledCameraControls $isDarkMode={isDarkMode}>
				<div>
					{[CameraMoveDirections.UP, CameraMoveDirections.RIGHT, CameraMoveDirections.LEFT, CameraMoveDirections.DOWN].map(
						direction => (
							<Button>
								<i
									key={direction}
									onMouseDown={() => {
										sendDirection(direction, CameraEventActions.START);
									}}
									onMouseUp={() => {
										sendDirection(direction, CameraEventActions.STOP);
									}}
									onMouseOut={() => {
										sendDirection(direction, CameraEventActions.STOP);
									}}
									style={{
										opacity:
											(feed.cameraType === CameraType.HELLO && feed.disabledTiltDirections[direction]) ||
											!feed.mediaDevices?.find(item => item.isActive)?.capabilities?.tilt
												? 0.5
												: 1,
									}}>
									<Icon name={directionIcon[direction]} />
								</i>
							</Button>
						)
					)}
					<div>
						{feed?.cameraType === CameraTypes.HUDDLE && feed.isHuddleCamConnected && (
							<CustomButton onClick={moveToHomePosition} svgIcon={<Icon name='home' />} alt='home' />
						)}
					</div>
				</div>
				<div>
					<input
						type='range'
						min={0}
						max={
							feed.cameraType === CameraType.HUDDLE && feed.isHuddleCamConnected
								? CameraMaxZoomLevels.HUDDLE_MAX
								: CameraMaxZoomLevels.HELLO_MAX
						}
						step={20}
						value={sliderRange}
						onChange={evt => {
							evt.stopPropagation();
							setSliderRange(+evt.target.value);
						}}
						onMouseUp={evt => {
							zoomCameraEvent(+evt.target.value);
						}}
						onTouchEnd={evt => {
							zoomCameraEvent(+evt.target.value);
						}}
						disabled={feed.mediaDevices?.find(item => item.isActive)?.capabilities?.zoom <= CameraZoomLevels.NO_ZOOM}
					/>
					{feed.cameraType === CameraType.HUDDLE && feed.isHuddleCamConnected && (
						<Dropdown position='right' icon='more_vert'>
							<div>
								<div>
									<CustomButton
										onClick={saveHomePosition}
										disabled={!feed.isHuddleCamConnected}
										icon='my_location'
										text={intl.formatMessage({ id: 'updateToCurrentPosition' })}
									/>
								</div>
								<div>
									<CustomButton
										onClick={evt => {
											evt.stopPropagation();
											setIsRebootHuddleCamModalOpen(true);
										}}
										disabled={!feed.isHuddleCamConnected}
										icon='refresh'
										text={intl.formatMessage({ id: 'resetCamera' })}
									/>
								</div>
							</div>
						</Dropdown>
					)}
				</div>
			</StyledCameraControls>
			{feed.cameraType === CameraType.HUDDLE && feed.isHuddleCamConnected && (
				<StyledCameraBookmarks $isDarkMode={isDarkMode} $isRightToLeft={locale === LOCALES.ARABIC}>
					<Button onClick={() => setToggleBookmark(prevState => !prevState)}>
						<div>
							<div>
								<Icon name='bookmark' />
								<div>
									<p>{intl.formatMessage({ id: 'presets' })}</p>
									<span>
										{activePreset && (
											<>
												{intl.formatMessage({ id: 'activePreset' })} {activePreset?.name}
											</>
										)}
										{!activePreset && intl.formatMessage({ id: 'noActivePreset' })}
									</span>
								</div>
							</div>
							<Icon name={toggleBookmark ? 'expand_less' : 'expand_more'} />
						</div>
					</Button>
					{toggleBookmark && (
						<div>
							{bookmarks.length === 0 && <p>{intl.formatMessage({ id: 'noPresets' })}</p>}
							{bookmarks.map(item => (
								<div>
									<CustomButton
										onClick={() => sendBookmarkEvent(bookmarkEvents.MOVE_TO_BOOKMARK, item.id)}
										text={!item.isEdit && item.name}
									/>
									<Dropdown position='right' icon='more_vert'>
										<div>
											<div>
												<CustomButton
													onClick={() => sendBookmarkEvent(bookmarkEvents.MOVE_TO_BOOKMARK, item.id)}
													disabled={!filteredDevices?.find(device => device.isActive)?.capabilities?.bookmarks?.isSupported}
													icon='my_location'
													text={intl.formatMessage({ id: 'goToPreset' })}
												/>
											</div>
											<div>
												<CustomButton
													onClick={() => sendBookmarkEvent(bookmarkEvents.EDIT_BOOKMARK, item.id)}
													icon='edit'
													text={intl.formatMessage({ id: 'updateToCurrentPosition' })}
												/>
											</div>
											<div>
												<CustomButton
													onClick={() => handleDeleteBookmark(item.id, item.name)}
													icon='delete'
													text={intl.formatMessage({ id: 'deletePreset' })}
												/>
											</div>
										</div>
									</Dropdown>
								</div>
							))}
							{/* bookmarksLimit - 1 => (because 1 is for the home position) */}
							{bookmarks.length < bookmarksLimit - 1 && (
								<StyledAddNewCameraBookmarksButton>
									<CustomButton
										onClick={() => setIsBookmarkModalOpen(true)}
										disabled={bookmarks.length >= bookmarksLimit}
										icon='add'
										text={intl.formatMessage({ id: 'addNewPreset' })}
									/>
								</StyledAddNewCameraBookmarksButton>
							)}
						</div>
					)}
				</StyledCameraBookmarks>
			)}
			{isBookmarkModalOpen && (
				<Modal
					onDismiss={() => {
						setIsBookmarkModalOpen(null);
						setBookmark(null);
					}}
					title={intl.formatMessage({ id: 'createPreset' })}>
					<Modal.Content>
						<Input>
							<Input.Label>{translate('presetName')}</Input.Label>
							<Input.Field
								value={bookmark}
								placeholder={intl.formatMessage({ id: 'typePresetDescription' })}
								onChange={e => setBookmark(e.target.value)}
							/>
							<Input.Description>{translate('presetChangeDescription')}</Input.Description>
						</Input>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								addNewBookmark(bookmark);
								setIsBookmarkModalOpen(null);
								setBookmark(null);
							}}>
							{intl.formatMessage({ id: 'createNewPreset' })}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{bookmarkToDelete && isDeleteBookmarkModalOpen && (
				<Modal onDismiss={() => setIsDeleteBookmarkModalOpen(false)} title={intl.formatMessage({ id: 'deleteConfirmation' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureToDeletePreset', {
								value: bookmarkToDelete.itemName,
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								sendBookmarkEvent(bookmarkEvents.REMOVE_BOOKMARK, bookmarkToDelete.itemId);
								setIsDeleteBookmarkModalOpen(false);
							}}>
							{translate('delete')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{isRebootHuddleCamModalOpen && (
				<Modal onDismiss={() => setIsRebootHuddleCamModalOpen(false)} title={intl.formatMessage({ id: 'rebootCamera' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureRebootHuddleCam', {
								value: filteredDevices.find(device => device?.name === CameraTypes.HUDDLE)?.capabilities?.customName || '20x',
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								resetCamera();
							}}>
							{translate('reboot')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</StyledPTZ>
	);
};

export default CameraControls;
