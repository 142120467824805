import { enums } from '@solaborate/calls';
import { getPatientsCareTeam, getUsersRpmList } from 'api/rpm.js';
import StartQueryStringKeys from 'calls/enums/StartQueryStringKeys.js';
import classNames from 'classnames';
import CustomTable from 'components/CustomTable.jsx';
import Form from 'components/Form.jsx';
import FormInput from 'components/FormInput.jsx';
import Modal from 'components/Modal.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import {
	ErrorComponentTypes,
	MediaPermissions,
	SortByNameValues,
	StreamError,
	UserPermissionDeniedErrors,
	VitalSignsEwsScoreLevels,
} from 'constants/enums.js';
import { hospitalAtHomeColumns, measurementTypes } from 'constants/hospital-at-home.js';
import { VitalSignsList } from 'constants/rpm.js';
import SocketEvents from 'constants/socket-events.js';
import HospitalAtHomeDropdownOptions from 'containers/HospitalAtHome/HospitalAtHomeDropdownOptions.jsx';
import HospitalAtHomePatientInfo from 'containers/HospitalAtHome/HospitalAtHomePatientInfo.jsx';
import AlertsModal from 'containers/VitalSignsMonitoring/AlertsModal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { formatOptionLabelHospitalAtHome, SingleValue } from 'infrastructure/helpers/careEventsHelper.js';
import { checkIfMediaDevicesPlugged, findSectorById, skipDuplicatedObjects } from 'infrastructure/helpers/commonHelpers.js';
import { defaultDateFormat, formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getVitalSignsScoreClassName } from 'infrastructure/helpers/rpmHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { debounce } from 'lodash';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isChrome, isEdgeChromium } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const HospitalAtHome = () => {
	const [patientDetailsView, setPatientDetailsView] = useState(false);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [sortByNameValue, setSortByNameValue] = useState(null);
	const [patients, setPatients] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [isAlertsModalOpen, setIsAlertsModalOpen] = useState(false);
	const [careTeam, setCareTeam] = useState([]);
	const [isCareTeamOpen, setIsCareTeamOpen] = useState(false);
	const [searchBox, setSearchBox] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const preferredUnits = useSelector(state => state.user.unitPreferences);
	const hasReachedEnd = useRef(null);
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const healthSystems = useSelector(state => state.healthSystems);
	const isAllowPermissionPrompt = useRef(false);
	const dispatch = useDispatch();
	const micStatus = useRef(null);

	const sortByName = sortType => {
		setSortByNameValue(sortType);
		const patientsList = [...patients.map(patient => ({ ...patient }))];

		if (sortByNameValue === SortByNameValues.SORTING_ZA) {
			patientsList.sort((a, b) => (a.firstName?.toUpperCase() > b.firstName?.toUpperCase() ? 1 : -1));
		} else {
			patientsList.sort((a, b) => (a.firstName?.toUpperCase() < b.firstName?.toUpperCase() ? 1 : -1));
		}
		setPatients(patientsList);
	};

	const getArrowColor = score => {
		switch (score) {
			case VitalSignsEwsScoreLevels.LOW:
				return 'blue-arrow';
			case VitalSignsEwsScoreLevels.MODERATE:
				return 'orange-arrow';
			case VitalSignsEwsScoreLevels.HIGH:
				return 'red-arrow';
			default:
				return '';
		}
	};

	useEffect(() => {
		const getRpmPatients = async () => {
			setIsLoading(true);
			const response = await getUsersRpmList({
				userId: getUserId(),
				pageIndex,
				search: searchBox,
				includeAppointment: true,
			});
			if (response.error) {
				setIsLoading(false);
				return;
			} else {
				const newArr = response.rpmListPatients.map(patient => ({
					...patient,
					painScale: patient.totalEarlyWarningScore,
					location: '1038, Bel Air, Los Angeles, California, 308729',
					language: 'English',
					admitDate: '5/2/2024',
					phaseCare: 'Acute',
					diagnosis: 'COPD, Hyperglycemia',
					product: 'Reduced LOS',
					sponsor: 'SLE',
					payer: 'Medicare FFS',
				}));

				hasReachedEnd.current = patients.length + response.rpmListPatients.length >= response.totalCount;

				if (searchBox) {
					setPatients(newArr);
					setIsLoading(false);
					return;
				}
				const concatenatedArray = patients.concat(newArr);
				const uniqueArray = skipDuplicatedObjects(concatenatedArray, 'id');

				setPatients(uniqueArray);
				setIsLoading(false);
			}
		};

		getRpmPatients();
	}, [pageIndex, searchBox]);

	const getLatestMeasurements = useCallback(
		data => {
			const foundPatient = patients.find(patient => patient?.userId === data.patientId);
			const foundVitalSign = foundPatient?.vitalSigns?.find(sign => sign.measurementType === data.measurementType);

			if (foundPatient?.vitalSigns && !foundVitalSign) {
				const vitalSignDetails = {
					device: { batteryLevel: data.batteryLevel },
					date: data.creationDate,
					...data,
				};
				foundPatient.vitalSigns.push(vitalSignDetails);
				foundPatient.hasMeasurementChanged = true;
			}

			if (foundVitalSign) {
				foundVitalSign.measurementValue = data.measurementValue;
				foundVitalSign.device.batteryLevel = data.batteryLevel;
				foundVitalSign.date = data.creationDate;
				foundPatient.hasMeasurementChanged = true;
			}

			const updatedPatients = patients.map(patient => (patient.userId === foundPatient.userId ? foundPatient : patient));
			setPatients(updatedPatients);
		},
		[patients]
	);

	useEffect(() => {
		socket.on(SocketEvents.HealthCare.MEASUREMENT_ADDED, getLatestMeasurements);

		return () => {
			socket.off(SocketEvents.HealthCare.MEASUREMENT_ADDED, getLatestMeasurements);
		};
	}, [socket, getLatestMeasurements]);

	const getCategoryPreference = useCallback(
		categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId),
		[preferredUnits]
	);

	const getUnitPreference = useCallback(
		categoryId => {
			const selectedPreference = getCategoryPreference(categoryId);
			return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
		},
		[getCategoryPreference]
	);

	const getMeasurementValue = measurement =>
		!measurement.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );

	const getVitalSignTableCell = (signs, type) => {
		const selectedVitalSigns = signs
			.filter(item => item.measurementType === type)
			.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

		let latestMeasurement = {};
		let previousMeasurement = {};
		if (selectedVitalSigns.length > 0) {
			latestMeasurement = selectedVitalSigns[0];
			if (selectedVitalSigns.length > 1) {
				previousMeasurement = selectedVitalSigns[1];
			}
		}

		const trendArrow =
			parseFloat(latestMeasurement?.measurementValue) > parseFloat(previousMeasurement?.measurementValue) ? 'rotate' : '';

		return (
			<div
				className={classNames(
					'patient-alert-body-vs hospital-at-home-cell position-relative',
					getVitalSignsScoreClassName(latestMeasurement?.earlyWarningScore, false)
				)}
				key={type}>
				<div className={getVitalSignsScoreClassName(latestMeasurement?.earlyWarningScore, true)} />
				<div className='flex flex-align-center gap-s margin-left-m'>
					{previousMeasurement.measurementValue &&
						latestMeasurement.measurementValue !== previousMeasurement.measurementValue && (
							<i
								className={classNames(
									'cursor-pointer material-icons sort-list-icon trend-arrow',
									trendArrow,
									getArrowColor(latestMeasurement?.earlyWarningScore)
								)}>
								arrow_downward
							</i>
						)}
					<div>
						<div className='flex flex-align-center'>
							<img src={VitalSignsList.find(item => item.type === type)?.icon} alt='ico' />
							{!latestMeasurement?.measurementValue && <p>N/A</p>}
							{latestMeasurement && (
								<p>
									{getMeasurementValue(latestMeasurement)}
									<span
										className={classNames({
											'unit-wrapper': !getVitalSignsScoreClassName(latestMeasurement?.earlyWarningScore, false),
										})}>
										{latestMeasurement.unitCategoryId
											? getUnitPreference(latestMeasurement.unitCategoryId)?.unit
											: latestMeasurement.measurementUnit}
									</span>
								</p>
							)}
						</div>
						<span>{latestMeasurement?.date && defaultDateFormat(latestMeasurement.date)}</span>
					</div>
				</div>
			</div>
		);
	};

	const toggleAlertsModal = patient => {
		setIsAlertsModalOpen(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const getNursesFromLocalStorage = () => {
		const savedNurses = localStorage.getItem('selectedNurses');
		return savedNurses ? JSON.parse(savedNurses) : [];
	};

	const handleCareTeamChange = (patientId, selectedNurse) => {
		const nurses = getNursesFromLocalStorage();
		const newNursesArr = nurses.filter(nurse => nurse.patientId !== patientId);
		newNursesArr.push({ patientId, selectedNurse });
		localStorage.setItem('selectedNurses', JSON.stringify(newNursesArr));
	};

	const getSelectedNurse = patientId => {
		const nurses = getNursesFromLocalStorage();
		const foundedNurse = nurses.find(item => item.patientId === patientId);
		return foundedNurse?.selectedNurse ?? '';
	};

	const handlePermissionErrors = async (callType, permissionError) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}
		if (micStatus?.current?.state === MediaPermissions.DENIED) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: StreamError.MICROPHONE_BLOCKED.type,
				})
			);
			return;
		}

		const { type, name } = permissionError;
		if (type === MediaPermissionsErrorType.UserPermissionDenied && name === UserPermissionDeniedErrors.NotAllowedError) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}
	};

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const talkToPatient = async solHelloDeviceId => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			openCallViewUrl(enums.CallTypes.VIDEO, solHelloDeviceId);
		} catch (permissionError) {
			handlePermissionErrors(enums.CallTypes.VIDEO, permissionError);
		}
		isAllowPermissionPrompt.current = false;
	};

	const getNurseSelectValue = patientId => {
		const nurse = getSelectedNurse(patientId);
		return nurse
			? {
					label: `${nurse.firstName} ${nurse.lastName}`,
					value: nurse.userId,
					profilePicture: nurse.profilePicture,
			  }
			: null;
	};

	const getNextSchedule = patient => {
		const { nextAppointment } = patient;
		const doctor =
			patient.id === nextAppointment.createdBy.id
				? nextAppointment.appointmentInvitation?.invitedUser
				: nextAppointment.createdBy;

		if (!doctor || !doctor.firstName || !doctor.lastName) {
			return 'Doctor information incomplete';
		}
		return `Dr. ${doctor?.firstName} ${doctor?.lastName}`;
	};

	const displayPatients = () =>
		patients.map(patient => {
			const vitalSigns = measurementTypes.reduce((acc, type) => {
				acc[type.toLowerCase()] = getVitalSignTableCell(patient.vitalSigns, type);
				return acc;
			}, {});

			const cellClassName = hospitalAtHomeColumns.map(type => {
				const selectedVitalSign = patient.vitalSigns.find(item => item.measurementType === type);
				return selectedVitalSign ? 'padding-2' : '';
			});

			return {
				patient: (
					<div
						className='care-event-details flex full-width cursor-pointer'
						key={patient.id}
						onClick={() => {
							getPatientCareTeam(patient.patientId);
							setSelectedPatient(patient);
							setPatientDetailsView(true);
						}}>
						<ProfilePicture
							className='doctor-request-img patient-care-event'
							fullName={`${patient.firstName} ${patient.lastName}`}
							profilePicture={patient.profilePicture}
						/>
						<div className='flex column-direction patient-details'>
							<h5>{`${patient.firstName} ${patient.lastName}`}</h5>
							<span className='mrn'>MRN: {patient.mrn || 'N/A'}</span>
							<span>
								{translate('painScale')}: {patient.painScale || 'N/A'}
							</span>
						</div>
					</div>
				),
				schedule: (
					<div className='flex column-direction schedule'>
						{patient.nextAppointment && (
							<>
								<h5 className='schedule-description'>{patient.nextAppointment?.title}</h5>
								<span className='schedule-doctor'>{getNextSchedule(patient)}</span>
								<span>{translate('at', { value: formattedDate(patient.nextAppointment?.appointmentSlot.startDateTime) })}</span>
							</>
						)}
						{!patient.nextAppointment && <span>N/A</span>}
					</div>
				),
				...vitalSigns,
				assignedNurse: (
					<label
						onClick={() => {
							getPatientCareTeam(patient.patientId);
						}}
						className='care-team-event-select'>
						<AsyncSelect
							placeholder={translate('selectNurse')}
							classNamePrefix='custom-select'
							onChange={selectedOption => handleCareTeamChange(patient.patientId, selectedOption)}
							getOptionValue={option => option.value}
							defaultOptions={careTeam}
							isClearable
							value={getNurseSelectValue(patient.patientId)}
							formatOptionLabel={formatOptionLabelHospitalAtHome}
							components={{ SingleValue }}
							styles={{
								menu: provided => ({
									...provided,
									width: '225px',
								}),
							}}
						/>
					</label>
				),
				actions: (
					<div className='flex patient-info-call'>
						<div className='patient-info-audio' onClick={() => talkToPatient(patient?.solHelloDeviceId ?? null)} />
						<HospitalAtHomeDropdownOptions
							patient={patient}
							toggleAlertsModal={toggleAlertsModal}
							getPatientCareTeam={getPatientCareTeam}
							setIsCareTeamOpen={setIsCareTeamOpen}
							setSelectedPatient={setSelectedPatient}
							setPatientDetailsView={setPatientDetailsView}
						/>
					</div>
				),
				className: patient.hasMeasurementChanged ? 'highlighted-measurement' : '',
				cellClassName,
			};
		});

	const openCallViewUrl = (callType, solHelloDeviceId) => {
		const foundSector = findSectorById(healthSystems.treeData.tree, solHelloDeviceId);
		if (!foundSector) {
			return;
		}
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: foundSector.helloDeviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: foundSector.name,
			[StartQueryStringKeys.CALL_TYPE]: callType,
			[StartQueryStringKeys.ROOM_TYPE]: foundSector.roomType,
		});
		const url = callType === enums.CallTypes.SECURITYCAM ? 'patient-feed' : 'call';
		window.open(`/${url}?${queryParams.toString()}`, '_blank');
	};

	const getPatientCareTeam = async id => {
		const response = await getPatientsCareTeam({ patientId: id, pageIndex: 0 });
		if (response.error) {
			return;
		}
		const careTeamResponse = response.patientCareTeam.concat(response.rpmCareTeam);
		setCareTeam(careTeamResponse);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const handleSearchValue = useCallback(debounce(setSearchBox, 300), []);

	const handleSearchTerm = event => {
		handleSearchValue(event.target.value);
		setSearchTerm(event.target.value);
		setPageIndex(0);
	};
	return (
		<MainLayout>
			{!patientDetailsView && (
				<div className='inner-main-view patient-alerts-wrapper full-width care-events-dashboard hospital-at-home'>
					<div className='flex flex-align-center flex-space-between full-width patients-alert-filter bottom-20'>
						<FormInput
							id='searchBox'
							labelClassName='full-width right-s'
							name='searchBox'
							text=''
							type='search'
							onChange={handleSearchTerm}
							value={searchTerm}
							placeholder={intl.formatMessage({ id: 'searchByName' })}
							className='full-width'
						/>
					</div>

					<div className='patients-alert-table-body hospital-at-home-scroll' onScroll={handleScroll}>
						<CustomTable
							headers={[
								{
									title: (
										<>
											{translate('patient')}
											<i
												className={classNames('cursor-pointer material-icons sort-list-icon', {
													rotate: sortByNameValue === SortByNameValues.SORTING_ZA,
												})}
												onClick={() =>
													sortByName(
														sortByNameValue === SortByNameValues.SORTING_ZA
															? SortByNameValues.SORTING_AZ
															: SortByNameValues.SORTING_ZA
													)
												}>
												arrow_downward
											</i>
										</>
									),
									id: 'patient',
								},
								{ title: translate('schedule'), id: 'schedule' },
								{ title: translate('bloodPressure'), id: 'bloodPressure' },
								{ title: translate('heartRate'), id: 'heartRate' },
								{ title: translate('oxygen'), id: 'oxygen' },
								{ title: translate('bloodGlucose'), id: 'bloodGlucose' },
								{ title: translate('temperature'), id: 'temperature' },
								{ title: translate('assignedNurse'), id: 'assignedNurse', columnWidth: '225px' },
								{ title: translate('actions'), id: 'actions' },
							]}
							isLoading={isLoading}
							rows={isLoading ? [] : displayPatients()}
							className='hospital-at-home-table patient-list-table'
							isEditable={false}
							stickyHeader={true}
						/>
					</div>
				</div>
			)}
			{selectedPatient && isAlertsModalOpen && (
				<AlertsModal
					display={isAlertsModalOpen}
					isLoading={false}
					position='center'
					className='vsm-alerts-modal border-radius-modal-wrapper rpm-alerts-modal'
					onModalClose={() => toggleAlertsModal(null)}
					patientId={selectedPatient.id}
					isVitalSigns={false}
				/>
			)}
			<Modal
				display={isCareTeamOpen}
				position='center'
				className='standard-modal-wrapper modal-wrapper-wo-btn wrapper-modal vsm-alerts-modal care-team-members-modal'
				onModalClose={() => setIsCareTeamOpen(false)}>
				{careTeam && (
					<Form>
						<h3>{translate('careTeam')}</h3>
						{careTeam.length === 0 && (
							<div className='flex flex-align-center flex-justify-center'>
								<p>{translate('noResultsFound')}</p>
							</div>
						)}
						<div className='care-team-members-wrapper'>
							<div className='available-doctor-items care-team-members-list'>
								{careTeam.map(item => (
									<div key={item?.userIntId} className='care-team-info'>
										<ProfilePicture
											className='doctor-request-img available-doctor-request-img'
											firstName={item.firstName}
											lastName={item.lastName}
											profilePicture={item.profilePicture}
										/>
										<p className='team-member-name'>
											{item.firstName} {item.lastName}
										</p>
										<span className='additional-info'>{item.email}</span>
										{item.specialty?.name && <span className='additional-info'>{item.specialty.name}</span>}
									</div>
								))}
							</div>
						</div>
					</Form>
				)}
			</Modal>
			{patientDetailsView && (
				<HospitalAtHomePatientInfo
					setPatientDetailsView={setPatientDetailsView}
					setSelectedPatient={setSelectedPatient}
					selectedPatient={selectedPatient}
					talkToPatient={talkToPatient}
					careTeam={careTeam}
				/>
			)}
		</MainLayout>
	);
};

export default HospitalAtHome;
