import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { Alert, Modal } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { addConfig, updateConfig } from 'api/eManager.js';
import { getCompanyId, getUserId } from 'infrastructure/auth.js';
import { ECareConfigurationTypes } from 'constants/e-care-manager-types.js';
import EcareManagerAddEditBaseUrl from 'containers/Configurations/EcareManagerAddEditBaseUrl.jsx';
import HillRoomAddEdit from 'containers/Configurations/HillRoomAddEdit.jsx';
import EcareApiKey from 'containers/Configurations/EcareApiKey.jsx';
import EcareManagerEpicCerner from 'containers/Configurations/EcareManagerEpicCerner.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

const EpicAddEdit = props => {
	const intl = useIntl();
	const [selectedConfig, setSelectedConfig] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [configParams, setConfigParams] = useState({
		nodeId: null,
		parentNodeId: null,
		nodeName: null,
		nodeLevel: null,
		configJson: '',
		configurationTypeId: null,
	});
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const shouldShowConfigType = settingTypeId =>
		getConfigurationValue(companyConfigurations[settingTypeId]) || props.itemToEdit?.configurationTypeId;

	const showApiKey = () => {
		if (props.itemToEdit) {
			return true;
		}
		if (!props.hasApiConfig) {
			return true;
		}
		return false;
	};

	const configTypes = [
		showApiKey() && {
			value: 1,
			label: intl.formatMessage({ id: 'eCareApiKey' }),
		},
		{
			value: 2,
			label: intl.formatMessage({ id: 'eCareBaseUrl' }),
		},
		shouldShowConfigType(IntegrationTypesSettings.EPIC) && {
			value: 3,
			label: 'Epic',
		},
		shouldShowConfigType(IntegrationTypesSettings.CERNER) && {
			value: 4,
			label: 'Cerner',
		},
		shouldShowConfigType(IntegrationTypesSettings.HILL_ROM) && {
			value: 8,
			label: 'Hill Room',
		},
	].filter(Boolean);

	useEffect(() => {
		const fetchConfigTypes = async () => {
			if (props.itemToEdit) {
				const foundItem = configTypes.find(item => item.value === props.itemToEdit.configurationTypeId);
				setSelectedConfig(foundItem);
			}
		};
		fetchConfigTypes();
	}, []);

	const onConfigTypeChange = event => {
		setSelectedConfig(event);
	};

	const isConfigValid = () => {
		let result;
		switch (configParams.configurationTypeId) {
			case ECareConfigurationTypes.ELERT_BASE_URL: {
				result = JSON.parse(configParams.configJson).baseUrl !== '';
				break;
			}
			default: {
				result = true;
			}
		}
		return result;
	};

	const onSubmit = async () => {
		if (!isConfigValid() || !configParams.configurationTypeId) {
			return;
		}
		setIsLoading(true);
		const paramsToSend = {
			tenantId: getCompanyId(),
			configurationTypeId: configParams.configurationTypeId,
			nodeId: configParams.nodeId,
			parentNodeId: configParams.parentNodeId,
			nodeName: configParams.nodeName,
			nodeLevel: configParams.nodeLevel,
			configJson: configParams.configJson,
			userId: getUserId(),
		};
		let response;
		if (props.itemToEdit) {
			paramsToSend.id = props.itemToEdit.id;
			response = await updateConfig(paramsToSend);
		} else {
			response = await addConfig(paramsToSend);
		}
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setIsLoading(false);
		props.setIsRefetch({ id: response.result.id });
		props.setIsAddOpen(false);
	};

	return (
		<Modal
			modalSelector='baseUrlKeyModal'
			position='right'
			onModalSubmit={() => onSubmit()}
			onModalClose={() => props.setIsAddOpen(prevState => !prevState)}
			shouldSubmitOnEnter={false}
			display={true}
			isSubmitDisabled={false}
			isLoading={isLoading}>
			<form>
				<h3>{translate('addConfiguration')}</h3>
				<div className='input'>
					<p className='label'>{translate('selectConfiguration')}</p>
					<p className='font-14'>{translate('selectConfigurationDesc')}</p>
					<Select
						isDisabled={props.itemToEdit}
						value={selectedConfig}
						placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
						classNamePrefix='react-select'
						options={configTypes}
						onChange={event => onConfigTypeChange(event)}
					/>
					{!configParams.configurationTypeId && <small>{translate('configurationTypeRequired')}</small>}
					<br />
					<br />
					{selectedConfig?.value === ECareConfigurationTypes.API_KEY && (
						<EcareApiKey itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} />
					)}
					{selectedConfig?.value === ECareConfigurationTypes.ELERT_BASE_URL && (
						<EcareManagerAddEditBaseUrl itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} />
					)}
					{selectedConfig?.value === ECareConfigurationTypes.EPIC && (
						<EcareManagerEpicCerner
							itemToEdit={props.itemToEdit}
							setConfigParams={setConfigParams}
							type={ECareConfigurationTypes.EPIC}
						/>
					)}
					{selectedConfig?.value === ECareConfigurationTypes.CERNER && (
						<EcareManagerEpicCerner
							itemToEdit={props.itemToEdit}
							setConfigParams={setConfigParams}
							type={ECareConfigurationTypes.CERNER}
						/>
					)}
					{selectedConfig?.value === ECareConfigurationTypes.REID && (
						<HillRoomAddEdit itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} />
					)}
				</div>
			</form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default EpicAddEdit;
