import classNames from 'classnames';

const Input = props => {
	return (
		<div
			className={classNames(
				'input',
				props.variant,
				props.prefixImg || props.prefixIcon || props.prefixSvg || props.symbol ? 'prefix-icon' : '',
				props.postfixIcon ? 'postfix-icon' : '',
				props.className
			)}
			style={{ marginBottom: props.bottomSpace, width: props.width }}>
			{props.label && <p className='label'>{props.label}</p>}
			{props.description && <p>{props.description}</p>}
			{props.prefixImg && <img src={props.prefixImg} alt='icon' />}
			{props.prefixIcon && (
				<i className={`material-icons${props.isOutlined ? '-outlined' : ''} prefix-icon`}>{props.prefixIcon}</i>
			)}
			{props.symbol && <i className='material-symbols-outlined prefix-icon'>{props.symbol}</i>}
			{props.prefixSvg && <i>{props.prefixSvg}</i>}
			{props.type && (
				<input
					id={props.id}
					type={props.type}
					name={props.name}
					onChange={props.onChange}
					onKeyUp={props.onKeyUp}
					onBlur={props.onBlur}
					onKeyDown={props.onKeyDown}
					placeholder={props.placeholder}
					readOnly={props.readonly}
					value={props.value}
					min={props.min}
					max={props.max}
					minLength={props.validationOptions?.minLength}
					maxLength={props.validationOptions?.maxLength}
					required={props.validationOptions?.required}
					pattern={props.validationOptions?.pattern}
					style={{ width: props.inputWidth }}
					autoComplete={props.autoComplete}
					disabled={props.disabled}
					className={props.inputClassName}
					ref={props.inputRef}
					onContextMenu={props.onContextMenu}
					onClick={props.onClick}
				/>
			)}
			{props.postfixIcon && (
				<i
					className='material-icons postfix-icon'
					data-tooltip={props.postfixTooltip}
					onClick={props.onPostfixClick}
					data-position={props.tooltipPosition || 'right'}>
					{props.postfixIcon}
				</i>
			)}
			{props.error && <small>{props.error}</small>}
		</div>
	);
};

export default Input;
