const Evidence = props => (
	<svg width={16.67} height={16.67} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<rect x='1.25' y='1.25' width='7.5' height='7.5' rx='1' stroke='#5390FF' />
		<path
			d='M3.45834 3.12533C3.45834 3.30942 3.3091 3.45866 3.12501 3.45866C2.94091 3.45866 2.79167 3.30942 2.79167 3.12533C2.79167 2.94123 2.94091 2.79199 3.12501 2.79199C3.3091 2.79199 3.45834 2.94123 3.45834 3.12533Z'
			stroke='#5390FF'
		/>
		<path
			d='M1.25 6.66667L2.17306 5.51284C2.56974 5.01699 3.30936 4.97603 3.75837 5.42504V5.42504C4.20138 5.86804 4.92914 5.83503 5.33022 5.35374L5.60006 5.02993C6.16372 4.35354 7.19514 4.33132 7.78741 4.98281L8.75 6.04167'
			stroke='#5390FF'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Evidence;
