import { mobileApi } from 'constants/global-variables.js';
import { HomeActionTypes, DeviceActionTypes, CallsActionTypes } from 'constants/action-types.js';
import { getUserInfo } from 'infrastructure/auth.js';

const mapResponse = arr => {
	return arr.map(item => {
		const returnValue = { ...item };
		returnValue.deviceName = `${item.firstName || ''} ${item.lastName || ''}`;
		return returnValue;
	});
};

export const actionCreators = {
	setTreeData:
		(tree = [{}], preSelected = {}) =>
		dispatch => {
			dispatch({
				type: HomeActionTypes.SET_TREE_DATA,
				payload: {
					tree,
					preSelected,
				},
			});
		},
	setHealthSystem:
		(healthSystem = {}) =>
		dispatch => {
			dispatch({
				type: HomeActionTypes.SET_HEALTH_SYSTEM,
				payload: healthSystem,
			});
		},

	setUser: () => dispatch => {
		const user = getUserInfo();
		dispatch({
			type: HomeActionTypes.SET_USER,
			user: user,
		});
	},
	searchDevice: (id, search) => async dispatch => {
		const response = await mobileApi.get(`Companies/GetSearchResult?idUser=${id}&searchText=${search}`);
		dispatch({
			type: DeviceActionTypes.SEARCH_DEVICE,
			adminDevices: mapResponse(response.data),
		});
	},
	toggleSwitchHealthSystemModal: () => ({
		type: HomeActionTypes.TOGGLE_HEALTH_SYTEM_MODAL,
	}),
	toggleLeftNavigation: value => ({
		type: HomeActionTypes.TOGGLE_LEFT_NAVIGATION,
		payload: value,
	}),
	toggleLeftNavigationWithValue: value => ({
		type: HomeActionTypes.TOGGLE_LEFT_NAVIGATION_VALUE,
		payload: value,
	}),
	toggleFullscreen: () => ({
		type: HomeActionTypes.TOGGLE_FULLSCREEEN,
	}),
	toggleIsRoomsView: () => ({
		type: HomeActionTypes.TOGGLE_IS_ROOMS_VIEW,
	}),
	setStreamPermissionMessage: data => ({ type: HomeActionTypes.SET_STREAM_PERMISSION_MESSAGE, payload: data }),
	createNewHospital: () => ({ type: HomeActionTypes.CREATE_NEW_HOSPITAL }),
	editHospital: name => ({
		type: HomeActionTypes.EDIT_HOSPITAL,
		payload: name,
	}),
	updateBreadcrumb: data => ({
		type: HomeActionTypes.UPDATE_BREADCRUMB,
		payload: data,
	}),
	updateMonitoringDevices: data => ({
		type: HomeActionTypes.UPDATE_MONITORING_DEVICES,
		payload: data,
	}),
	setAllHealthSystems: healthSystems => dispatch => {
		dispatch({
			type: HomeActionTypes.SET_ALL_HEALTH_SYSTEMS,
			payload: healthSystems,
		});
	},

	setIsHealthSystemFetched: isFetched => dispatch => {
		dispatch({
			type: HomeActionTypes.SET_IS_HEALTH_SYSTEM_FETCHED,
			payload: isFetched,
		});
	},

	removeDeviceFromMonitoring: room => dispatch => {
		const { hospitalId, departmentId, floorId, roomId, deviceId } = room;
		dispatch({
			type: CallsActionTypes.monitoringRemoveDevice,
			payload: {
				hospitalId,
				departmentId,
				floorId,
				roomId,
				deviceId,
			},
		});
	},
	removeDeviceFromQueue: deviceId => dispatch => {
		dispatch({
			type: CallsActionTypes.monitoringDeviceAddedSuccessfully,
			payload: {
				deviceId,
			},
		});
	},
	updateDeviceCallStatus: (deviceId, activeConferences) => dispatch => {
		dispatch({
			type: HomeActionTypes.UPDATE_DEVICE_CALL_STATUS,
			payload: {
				deviceId,
				activeConferences,
			},
		});
	},
	updateDeviceStatus: (deviceId, isOnline) => dispatch => {
		dispatch({
			type: HomeActionTypes.UPDATE_DEVICE_STATUS,
			payload: {
				deviceId,
				isOnline,
			},
		});
	},
	updateDeviceAIPrivacyStatus: (deviceId, aiPrivacyStatus) => dispatch => {
		dispatch({
			type: HomeActionTypes.UPDATE_DEVICE_PRIVACY_STATUS,
			payload: {
				deviceId,
				aiPrivacyStatus,
			},
		});
	},
	updateDevicePariedRemote: (deviceId, hasPairedRemote) => dispatch => {
		dispatch({
			type: HomeActionTypes.UPDATE_DEVICE_PAIRED_REMOTE,
			payload: {
				deviceId,
				hasPairedRemote,
			},
		});
	},

	setNewMeasurementsAlertData: data => dispatch => {
		dispatch({
			type: HomeActionTypes.NEW_MEASUREMENT_DATA,
			payload: data,
		});
	},
};
