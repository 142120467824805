import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { APP_CONFIG } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserId, getUserInfo, getUserProfile, getUserRole } from 'infrastructure/auth.js';
import { AlertTypes, UserRoles, Country, Address, Gender } from 'constants/enums.js';
import FormInput from 'components/FormInput.jsx';
import { uploadProfilePic } from 'api/media.js';
import { getCountries, getUserAddress, updateUserProfileInformation } from 'api/users.js';
import Genders from 'components/Genders.jsx';
import { genderItems } from 'constants/genderItems.js';
import { getStates } from 'api/doctorOnBoarding.js';
import { Button, Grid, Loader, Select, Form, PopUpAlert } from 'components/index.js';
import { getStorage, isAdUser } from 'infrastructure/helpers/commonHelpers.js';
import ImageUploader from './ImageUploader.jsx';
import LocationAndAddress from 'components/ProfileInformation/LocationAndAddress.jsx';

const ProfileInformation = () => {
	const [profile, setProfile] = useState(null);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isRequestProcessing, setIsRequestProcessing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isExpanded, setIsExpanded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [profilePicture, setProfilePicture] = useState('');
	const [userAddress, setUserAddress] = useState(null);
	const intl = useIntl();
	const prefixesList = [
		{ code: '', id: 99, name: 'None' },
		{ code: 'APRN', id: 0, name: 'APRN' },
		{ code: 'LPN', id: 1, name: 'LPN' },
		{ code: 'LVN', id: 2, name: 'LVN' },
		{ code: 'NP', id: 3, name: 'NP' },
		{ code: 'RN', id: 4, name: 'RN' },
		{ code: 'ACNP', id: 5, name: 'ACNP' },
		{ code: 'CNS', id: 6, name: 'CNS' },
		{ code: 'ANP', id: 7, name: 'ANP' },
		{ code: 'FNP', id: 8, name: 'FNP' },
		{ code: 'GNP', id: 9, name: 'GNP' },
		{ code: 'PNP', id: 10, name: 'PNP' },
		{ code: 'TCRN', id: 11, name: 'TCRN' },
		{ code: 'APP', id: 12, name: 'APP' },
		{ code: 'Med Tech', id: 13, name: 'Med Tech' },
	];
	const userInfo = getUserInfo();
	const companyId = getCompanyId();

	useEffect(() => {
		getProfileInformationDetails();
	}, []);

	useEffect(() => {
		let timeout;
		if (success) {
			timeout = setTimeout(() => {
				setSuccess(null);
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [success]);

	const getProfileInformationDetails = async () => {
		const [countriesList, statesList, profileInformation, addressResponse] = await Promise.all([
			getCountries(),
			getStates(),
			getUserProfile(getUserId(), companyId),
			getUserAddress(getUserId()),
		]);
		const responseError = countriesList.error || statesList.error || profileInformation.error || addressResponse.error;
		if (responseError) {
			setError(responseError.message);
		} else {
			setCountries(countriesList.countries);
			setStates(statesList.states);
			setProfile(profileInformation);
			setProfilePicture(profileInformation.profilePicture);
			setUserAddress(addressResponse.address);
		}
		setIsLoading(false);
	};

	const updateProfileInformation = async values => {
		const { firstName, lastName, genderId, dateOfBirth, country1, addressStateId, city, zipCode, address, address2, prefix } =
			values;
		const parsedGenderId = parseInt(genderId, 10);
		const userProfileValues = {
			firstName,
			lastName,
			profilePicture,
			genderId: parsedGenderId === Gender.UNAVAILABLE ? null : parsedGenderId,
			dateOfBirth: dateOfBirth ? dateOfBirth : null,
			...(prefix && {
				prefix,
			}),
			userAddress:
				country1 && address
					? {
							countryId: parseInt(country1, 10),
							stateId: parseInt(addressStateId, 10),
							city,
							zipCode: zipCode.toString(),
							address1: address,
							address2,
					  }
					: null,
		};
		setIsRequestProcessing(true);
		const response = await updateUserProfileInformation(userProfileValues);
		if (response.error) {
			setError(response.error.message);
		}
		if (response.hasSucceeded) {
			updateUserProfile(userProfileValues);
			if (getUserRole() === UserRoles.NURSE) {
				userInfo.prefix = prefix;
				getStorage().setItem('userProfile', JSON.stringify(userInfo));
			}
			setSuccess(response.hasSucceeded);
		}
		setIsRequestProcessing(false);
	};

	const updateUserProfile = values => {
		const userProfile = getUserInfo();
		userProfile.firstName = values.firstName;
		userProfile.lastName = values.lastName;
		userProfile.profilePicture = `${APP_CONFIG.profilePicBaseUrl}${values.profilePicture}`;
		localStorage.setItem('userProfile', JSON.stringify(userProfile));
	};

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	const getInitialValues = () => {
		if (profile) {
			return {
				firstName: profile.firstName,
				lastName: profile.lastName,
				genderId: profile.genderId?.toString(),
				dateOfBirth: profile.dateOfBirth ? moment(profile.dateOfBirth).format('YYYY-MM-DD') : null,
				country1: userAddress?.countryId ?? null,
				addressStateId: userAddress?.stateId ?? null,
				city: userAddress?.city ?? '',
				zipCode: userAddress?.zipCode ?? '',
				address: userAddress?.address1 ?? '',
				address2: userAddress && userAddress.address2 ? userAddress.address2 : '',
				prefix: userInfo.prefix,
			};
		}
		return null;
	};

	const getValidationSchema = () => {
		let validationSchema = {
			firstName: Yup.string()
				.required(intl.formatMessage({ id: 'firstNameCannotBeEmpty' }))
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			lastName: Yup.string()
				.required(intl.formatMessage({ id: 'lastNameCannotBeEmpty' }))
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			dateOfBirth: Yup.string()
				.test('dateOfBirth', intl.formatMessage({ id: 'greaterThanEighteen' }), value => {
					return !value || moment().diff(moment(value), 'years') >= 18;
				})
				.nullable(),
			country1: Yup.string()
				.when([Address.ADDRESS_1, Address.ADDRESS_2, Address.CITY, Address.ZIP_CODE], {
					is: (address, city, zipCode, address2) => address || city || zipCode || address2,
					then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
				})
				.nullable(),
			addressStateId: Yup.string()
				.when([Address.COUNTRY, Address.ADDRESS_1, Address.ADDRESS_2, Address.CITY, Address.ZIP_CODE], {
					is: (country1, address, address2, zipCode, city) =>
						country1 === Country.USA.toString() && (address || address2 || zipCode || city),
					then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
				})
				.nullable(),
			city: Yup.string().when([Address.COUNTRY, Address.ZIP_CODE, Address.ADDRESS_2, Address.ADDRESS_1], {
				is: (country1, zipCode, address2, address) => country1 || zipCode || address2 || address,
				then: () =>
					Yup.string()
						.required(intl.formatMessage({ id: 'pleaseWriteCity' }))
						.trim(),
			}),
			zipCode: Yup.number()
				.max(9999999999, intl.formatMessage({ id: 'zipCodeDigitsMax' }))
				.when([Address.COUNTRY, Address.CITY, Address.ADDRESS_1, Address.ADDRESS_2], {
					is: (country1, city, address, address2) => country1 || city || address || address2,
					then: () => Yup.number().required(intl.formatMessage({ id: 'pleaseWriteZipCode' })),
				}),
			address: Yup.string()
				.when([Address.COUNTRY, Address.ZIP_CODE, Address.ADDRESS_2, Address.CITY], {
					is: (country1, zipCode, address2, city) => country1 || zipCode || address2 || city,
					then: () => Yup.string().required(intl.formatMessage({ id: 'addressRequired' })),
				})
				.nullable(),
			address2: Yup.string(),
		};
		return Yup.object().shape(validationSchema, [
			[Address.COUNTRY, Address.ADDRESS_1],
			[Address.COUNTRY, Address.CITY],
			[Address.COUNTRY, Address.ZIP_CODE],
			[Address.CITY, Address.ZIP_CODE],
			[Address.CITY, Address.ADDRESS_2],
			[Address.CITY, Address.ADDRESS_1],
			[Address.CITY, Address.ZIP_CODE],
			[Address.ZIP_CODE, Address.ADDRESS_1],
		]);
	};

	const transformArray = array => array.map(item => ({ value: item.id, label: item.name }));

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='calc(100vh - 105px)' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='account-settings-panel-wrapper account-settings-inner-wrapper'>
					<h4>{translate('profileInformation')}</h4>
					<h5>{translate('profilePicture')}</h5>
					<div className='flex profile-info-image flex-align-center'>
						<ImageUploader
							setError={setError}
							existingLogo={profilePicture}
							setProfilePicture={setProfilePicture}
							uploadProfilePic={uploadProfilePic}
							error=''
							sizeInMB={2}
						/>
					</div>

					<Formik
						enableReinitialize={true}
						initialValues={getInitialValues()}
						onSubmit={updateProfileInformation}
						validationSchema={getValidationSchema()}>
						{formikProps => {
							const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, isValid } = formikProps;
							return (
								<Form className='profile-information-form'>
									<div className='flex flex-space-between flex-wrap'>
										<FormInput
											text={`*${intl.formatMessage({ id: 'firstName' })}`}
											id='firstName'
											placeholder={intl.formatMessage({ id: 'enterFirstName' })}
											type='text'
											value={values.firstName}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
											error={errors.firstName}
											touched={touched.firstName}
											readOnly={isAdUser()}
											maxLength={256}
										/>
										<FormInput
											text={`*${intl.formatMessage({ id: 'lastName' })}`}
											id='lastName'
											placeholder={intl.formatMessage({ id: 'enterLastName' })}
											type='text'
											value={values.lastName}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
											error={errors.lastName}
											touched={touched.lastName}
											readOnly={isAdUser()}
											maxLength={256}
										/>
										{getUserRole() === UserRoles.NURSE && (
											<Select
												label={translate('prefixes')}
												name='prefix'
												items={prefixesList}
												valueField='code'
												textField='name'
												value={values.prefix || ''}
												placeholder={intl.formatMessage({ id: 'select' })}
												onSelect={handleChange}
												onBlur={handleBlur}
												error={errors.prefix}
												touched={touched.prefix}
											/>
										)}
										<div className='genders-title'>
											{intl.formatMessage({ id: 'sex' })}
											<Genders
												items={genderItems}
												handleChange={event =>
													setFieldValue(
														'genderId',
														event.target.value === formikProps.values.genderId ? Gender.UNAVAILABLE : event.target.value
													)
												}
												handleBlur={handleBlur}
												errors={errors}
												touched={touched}
												value={values.genderId}
											/>
										</div>
										<FormInput
											text={translate('dateOfBirth')}
											id='dateOfBirth'
											placeholder={intl.formatMessage({ id: 'pleaseSelectDateOfBirth' })}
											type='date'
											value={values.dateOfBirth}
											onChange={handleChange}
											onBlur={handleBlur}
											max={new Date().toISOString().split('T')[0]}
											className={errors.dateOfBirth && touched.dateOfBirth ? 'text-input error' : 'text-input'}
											error={errors.dateOfBirth}
											touched={touched.dateOfBirth}
										/>
									</div>
									<div className='addreses-wrapper'>
										<div
											className='flex flex-align-center cursor-pointer position-relative'
											onClick={() => setIsExpanded(prevState => !prevState)}>
											<i className='material-icons-outlined'>{isExpanded ? 'arrow_drop_down' : 'arrow_right'}</i>
											<div className='flex column-direction'>
												<h5>{translate('locationAndAddress')}</h5>
												<span>{`${intl.formatMessage({ id: 'addOrUpdate' })} ${intl.formatMessage({
													id: 'locationAndAddress',
												})}`}</span>
											</div>
										</div>
										{isExpanded && (
											<LocationAndAddress
												countries={countries}
												states={states}
												transformArray={transformArray}
												formikProps={formikProps}
												haveSecondAddress
												onChange={handleChange}
											/>
										)}
									</div>
									<div className='full-width flex right-align-content top-30 padding-bottom-30'>
										<Button
											disabled={!isValid}
											onClick={handleSubmit}
											isLoading={isRequestProcessing}
											text={!isRequestProcessing ? translate('saveChanges') : ''}
										/>
									</div>
								</Form>
							);
						}}
					</Formik>
					<PopUpAlert
						alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
						display={error || success}
						onAlertClose={closeResponseAlert}
						contentText={success ? intl.formatMessage({ id: 'changesSaved' }) : error}
						isSilent={true}
						center={true}
					/>
				</div>
			)}
		</>
	);
};

export default ProfileInformation;
