export const ECareConfigurationTypes = {
	ELERT_BASE_URL: 2,
	API_KEY: 1,
	EPIC: 3,
	CERNER: 4,
	REID: 8,
};

export const CallStatuses = {
	ACCEPTED: 0,
	DECLINED: 0,
};
