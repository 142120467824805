export const HomeActionTypes = {
	SET_USER: 'SET_USER',
	TOGGLE_HEALTH_SYTEM_MODAL: 'TOGGLE_HEALTH_SYTEM_MODAL',
	TOGGLE_LEFT_NAVIGATION: 'TOGGLE_LEFT_NAVIGATION',
	TOGGLE_LEFT_NAVIGATION_VALUE: 'TOGGLE_LEFT_NAVIGATION_VALUE',
	TOGGLE_FULLSCREEEN: 'TOGGLE_FULLSCREEEN',
	ADD_NEW_ROOM: 'ADD_NEW_ROOM',
	UPDATE_BREADCRUMB: 'UPDATE_BREADCRUMB',
	SET_ALL_HEALTH_SYSTEMS: 'SET_ALL_HEALTH_SYSTEMS',
	SET_HEALTH_SYSTEM: 'SET_HEALTH_SYSTEM',
	SET_TREE_DATA: 'SET_TREE_DATA',
	SET_USER_PRESENCE_DATA: 'SET_USER_PRESENCE_DATA',
	CREATE_NEW_HOSPITAL: 'CREATE_NEW_HOSPITAL',
	EDIT_HOSPITAL: 'EDIT_HOSPITAL',
	SET_HOSPITAL_TREE: 'SET_HOSPITAL_TREE',
	TOGGLE_IS_ROOMS_VIEW: 'TOGGLE_IS_ROOMS_VIEW',
	UPDATE_DEVICE_CALL_STATUS: 'UPDATE_DEVICE_CALL_STATUS',
	UPDATE_DEVICE_STATUS: 'UPDATE_DEVICE_STATUS',
	UPDATE_DEVICE_PRIVACY_STATUS: 'UPDATE_DEVICE_PRIVACY_STATUS',
	UPDATE_MONITORING_DEVICES: 'UPDATE_MONITORING_DEVICES',
	SET_STREAM_PERMISSION_MESSAGE: 'SET_STREAM_PERMISSION_MESSAGE',
	SET_IS_HEALTH_SYSTEM_FETCHED: 'SET_IS_HEALTH_SYSTEM_FETCHED',
	UPDATE_DEVICE_PAIRED_REMOTE: 'UPDATE_DEVICE_PAIRED_REMOTE',
	NEW_MEASUREMENT_DATA: 'NEW_MEASUREMENT_DATA',
};

export const PatientActionTypes = {
	FETCH_MY_PATIENTS_SUCCESS: 'FETCH_MY_PATIENTS_SUCCESS',
	SET_SELECTED_PATIENT: 'SET_SELECTED_PATIENT',
	UPDATE_DEVICE_CALL_STATUS: 'UPDATE_DEVICE_CALL_STATUS',
	UPDATE_DEVICE_STATUS: 'UPDATE_DEVICE_STATUS',
	UPDATE_DEVICE_PRIVACY_STATUS: 'UPDATE_DEVICE_PRIVACY_STATUS',
	SET_HAS_RPM_PROGRAM: 'SET_HAS_RPM_PROGRAM',
	SET_SELECTED_PATIENT_DEVICE: 'SET_SELECTED_PATIENT_DEVICE',
};

export const DoctorActionTypes = {
	SET_SELECTED_DOCTOR: 'SET_SELECTED_DOCTOR',
	SET_DOCTORS: 'SET_DOCTORS',
	SET_WAITING_ROOM_COUNT: 'SET_WAITING_ROOM_COUNT',
	SET_IS_REQUEST_UPDATED: 'SET_IS_REQUEST_UPDATED',
};

export const DeviceActionTypes = {
	GET_DEVICES_LIST: 'GET_DEVICES_LIST',
	SET_SELECTED_DEVICE: 'SET_SELECTED_DEVICE',
	UPDATE_DEVICE_LIST: 'UPDATE_DEVICE_LIST',
	ON_DEVICE_UPDATE: 'ON_DEVICE_UPDATE',
	SEARCH_DEVICE: 'SEARCH_DEVICE',
	REMOVE_SELECTED_DEVICE: 'REMOVE_SELECTED_DEVICE',
	SET_DEVICE_BUSY: 'SET_DEVICES_BUSY',
	REMOVE_DEVICE_BUSY: 'REMOVE_DEVICES_BUSY',
	SET_BULK_DEVICES_BUSY: 'SET_BULK_DEVICES_BUSY',
	REMOVE_BULK_DEVICES_BUSY: 'REMOVE_BULK_DEVICES_BUSY',
	SET_ONLINE_DEVICE: 'SET_ONLINE_DEVICE',
	REMOVE_ONLINE_DEVICE: 'REMOVE_ONLINE_DEVICE',
	SET_BULK_DEVICES_ONLINE: 'SET_BULK_DEVICES_ONLINE',
	REMOVE_BULK_DEVICES_ONLINE: 'REMOVE_BULK_DEVICES_ONLINE',
	// privacy
	SET_PRIVACY_DEVICE: 'SET_PRIVACY_DEVICE',
	REMOVE_PRIVACY_DEVICE: 'REMOVE_PRIVACY_DEVICE',
	SET_BULK_DEVICES_PRIVACY: 'SET_BULK_DEVICES_PRIVACY',
	REMOVE_BULK_DEVICES_PRIVACY: 'REMOVE_BULK_DEVICES_PRIVACY',
	// pariedRemote
	SET_PAIR_REMOTE_DEVICE: 'SET_PAIR_REMOTE_DEVICE',
	REMOVE_PAIR_REMOTE_DEVICE: 'REMOVE_PAIR_REMOTE_DEVICE',
	SET_BULK_PAIR_REMOTE_DEVICE: 'SET_BULK_PAIR_REMOTE_DEVICE',
	REMOVE_BULK_PAIR_REMOTE_DEVICE: 'REMOVE_BULK_PAIR_REMOTE_DEVICE',
};

export const CallsActionTypes = {
	monitoringQueueDevice: 'MONITORING_QUEUE_DEVICE',
	monitoringRemoveDevice: 'MONITORING_REMOVE_DEVICE',
	monitoringDeviceAddedSuccessfully: 'MONITORING_DEVICE_ADDED_SUCCESSFULLY',
	incomingCallRenderedElsewhere: 'INCOMING_CALL_RENDERED_ELSEWHERE',
};

export const UserActionTypes = {
	SET_USER_CONVERSATION_NOTIFICATIONS: 'SET_USER_CONVERSATION_NOTIFICATIONS',
	SET_USER_SESSION: 'SET_USER_SESSION',
	SET_USER_PRESENCE_STATUS: 'SET_USER_PRESENCE_STATUS',
	SET_DOCTOR_DATA: 'SET_DOCTOR_DATA',
	SET_USER_CONFIGURABLE_MENU: 'SET_USER_CONFIGURABLE_MENU',
	SET_GENERAL_SETTINGS: 'SET_GENERAL_SETTINGS',
	SET_UNREAD_NOTIFICATIONS_COUNTER: 'SET_UNREAD_NOTIFICATIONS_COUNTER',
	SET_UNREAD_NOTIFICATIONS_COUNTER_INCREMENT: 'SET_UNREAD_NOTIFICATIONS_COUNTER_INCREMENT',
	SET_USER_WAITING_ROOM_COUNT: 'SET_USER_WAITING_ROOM_COUNT',
	SET_SESSIONS_QUEUE_COUNT: 'SET_SESSIONS_QUEUE_COUNT',
	SET_ADMIN_CONFIGURABLE_MENU: 'SET_ADMIN_CONFIGURABLE_MENU',
	SET_USER_DARK_MODE: 'SET_USER_DARK_MODE',
	SET_ASSIGNED_TO_DOCTORS: 'SET_ASSIGNED_TO_DOCTORS',
	SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER: 'SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER',
	SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER_INCREMENT: 'SET_UNREAD_MISSED_CALLS_NOTIFICATIONS_COUNTER_INCREMENT',
	SET_UNIT_PREFERENCES: 'SET_UNIT_PREFERENCES',
	SET_USER_ROLES: 'SET_USER_ROLES',
};

export const NotificationsCounterActionTypes = {
	FETCHING_NOTIFICATIONS_COUNTER: 'FETCHING_NOTIFICATIONS_COUNTER',
	FETCH_NOTIFICATIONS_COUNTER_SUCCESS: 'FETCH_NOTIFICATIONS_COUNTER_SUCCESS',
	FETCH_NOTIFICATIONS_COUNTER_FAILURE: 'FETCH_NOTIFICATIONS_COUNTER_FAILURE',
};
export const CompanyTypes = {
	SET_COMPANY_DETAILS: 'SET_COMPANY_DETAILS',
	SET_COMPANY_SETTINGS: 'SET_COMPANY_SETTINGS',
	SET_MOBILE_CONFIGURATIONS: 'SET_MOBILE_CONFIGURATIONS',
};

export const PatientAiSettingTypes = {
	SET_PATIENT_AI_SETTINGS: 'SET_PATIENT_AI_SETTINGS',
	SET_ENABLED_AI_TYPES: 'SET_ENABLED_AI_TYPES',
	SET_VITAL_SIGNS_AI_VISIBLE: 'SET_VITAL_SIGNS_AI_VISIBLE',
};

export const LanguageActionTypes = {
	SET_USER_LANGUAGE: 'SET_USER_LANGUAGE',
};
export const ConfigurationActionTypes = {
	SET_USER_CONFIGURABLE_MENU: 'SET_USER_CONFIGURABLE_MENU',
	SET_ADMIN_CONFIGURABLE_MENU: 'SET_ADMIN_CONFIGURABLE_MENU',
	SET_USER_SETTINGS: 'SET_USER_SETTINGS',
	SET_ROLE_ROUNDING_CONFIGURATIONS: 'SET_ROLE_ROUNDING_CONFIGURATIONS',
	SET_IS_NEW_EXPERIENCE: 'SET_IS_NEW_EXPERIENCE',
	SET_HEALTH_SYSTEM_UNBOUND_CONFIGURATIONS: 'SET_HEALTH_SYSTEM_UNBOUND_CONFIGURATIONS',
	SET_HEALTH_SYSTEM_UNBOUND_SETTINGS: 'SET_HEALTH_SYSTEM_UNBOUND_SETTINGS',
	SET_HEALTH_SYSTEM_CONFIGURATIONS: 'SET_HEALTH_SYSTEM_CONFIGURATIONS',
};

export const HealthDataTypes = {
	SET_WATCH_MEASUREMENTS_VISIBLE: 'SET_WATCH_MEASUREMENTS_VISIBLE',
	SET_IS_FIT_TO_SCREEN: 'SET_IS_FIT_TO_SCREEN',
	SET_IS_SIMPLIFIED_PATIENT_FORM: 'SET_IS_SIMPLIFIED_PATIENT_FORM',
};

export const PatientNotes = {
	SET_PATIENT_DATA_SAVED: 'SET_PATIENT_DATA_SAVED',
	SET_PATIENT_DATA_BULK: 'SET_PATIENT_DATA_BULK',
	INCREASE_DRAFTS_COUNT: 'INCREASE_DRAFTS_COUNT',
	DECREASE_DRAFTS_COUNT: 'DECREASE_DRAFTS_COUNT',
	SET_PATIENT_DATA_EDITED: 'SET_PATIENT_DATA_EDITED',
};
