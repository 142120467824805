import classNames from 'classnames';
import React from 'react';

const CheckboxInput = props => (
	<label className={classNames('checkbox-input', props.checked ? 'checkbox-input-checked' : '')} onChange={props.onChange}>
		<input type='checkbox' name='checkbox' checked={props.checked} onChange={() => null} />
		<span>{props.label}</span>
	</label>
);

export default CheckboxInput;
