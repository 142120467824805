import { DietTypes, DietTypesId, WhiteboardWidgets } from 'constants/enums.js';

export const privacyModeOptions = intl => [
	{ value: 5, label: `5 ${intl.formatMessage({ id: 'minutes' })}` },
	{ value: 10, label: `10 ${intl.formatMessage({ id: 'minutes' })}` },
	{ value: 15, label: `15 ${intl.formatMessage({ id: 'minutes' })}` },
	{ value: 30, label: `30 ${intl.formatMessage({ id: 'minutes' })}` },
];

export const initialWhiteboardData = {
	careTeam: {
		doctors: [],
		nurse: '',
		nursePhone: {
			maskedValue: '',
			unmaskedValue: '',
		},
		pca: '',
		pcaPhone: {
			maskedValue: '',
			unmaskedValue: '',
		},
		member: '',
		familyContact: {
			maskedValue: '',
			unmaskedValue: '',
		},
		caseManager: '',
		caseManagerPhone: {
			maskedValue: '',
			unmaskedValue: '',
		},
	},
	goingHome: {
		timeFrame: null,
		ride: false,
	},
	diet: [],
	painMed: {
		lastDose: '',
		nextDose: '',
	},
	activity: '',
	fallRisk: null,
	dailyWeight: null,
	question: '',
	painLevel: '',
	currentPainLevel: null,
	safetyAlerts: '',
	newMedications: '',
	whatMetters: '',
	todaysPlan: '',
	bradenScore: {
		sensory: null,
		skin: null,
		activity: null,
		mobility: null,
		nutrition: null,
		friction: null,
	},
};

export const whiteboardWidgetList = [
	{
		id: WhiteboardWidgets.CARE_TEAM,
		name: 'careTeam',
	},
	{ id: WhiteboardWidgets.GOING_HOME, name: 'goingHome' },
	{ id: WhiteboardWidgets.DIET, name: 'diet' },
	{ id: WhiteboardWidgets.PAIN_MED, name: 'painMed' },
	{ id: WhiteboardWidgets.ACTIVITY, name: 'activity' },
	{ id: WhiteboardWidgets.QUESTION, name: 'question' },
	{ id: WhiteboardWidgets.SAFETY_ALERTS, name: 'safetyAlerts' },
	{ id: WhiteboardWidgets.NEW_MEDICATIONS, name: 'newMedications' },
	{ id: WhiteboardWidgets.WHAT_MATTERS, name: 'whatMetters' },
	{ id: WhiteboardWidgets.TODAYS_PLAN, name: 'todaysPlan' },
	{ id: WhiteboardWidgets.PAIN_LEVEL, name: 'painLevel' },
	{ id: WhiteboardWidgets.BRADEN_SCORE, name: 'bradenScore' },
	{ id: WhiteboardWidgets.FALL_RISK, name: 'fallRisk' },
	{ id: WhiteboardWidgets.DAILY_WEIGHT, name: 'dailyWeight' },
];

export const dietPlans = [
	{ id: DietTypesId.NPO, name: DietTypes.NPO, label: 'NPO' },
	{ id: DietTypesId.CLEAR_LIQUID_DIET, name: DietTypes.CLEAR_LIQUID_DIET, label: 'Clear Liquid Diet' },
	{ id: DietTypesId.BARIATRIC_DIET, name: DietTypes.BARIATRIC_DIET, label: 'Bariatric Diet' },
	{ id: DietTypesId.FULL_LIQUID_DIET, name: DietTypes.FULL_LIQUID_DIET, label: 'Full Liquid Diet' },
	{ id: DietTypesId.HEART_HEALTHY, name: DietTypes.HEART_HEALTHY, label: 'Heart Healthy' },
	{ id: DietTypesId.DIABETIC_DIET, name: DietTypes.DIABETIC_DIET, label: 'Carb Controlled Diet' },
	{ id: DietTypesId.REGULAR_DIET, name: DietTypes.REGULAR_DIET, label: 'Regular Diet' },
	{ id: DietTypesId.DYSPHAGIA_DIET, name: DietTypes.DYSPHAGIA_DIET, label: 'Dysphagia Diet' },
	{
		id: DietTypesId.RENAL_DIET,
		name: DietTypes.RENAL_DIET,
		label: 'Renal Diet',
	},
	{
		id: DietTypesId.LOW_SODIUM_DIET,
		name: DietTypes.LOW_SODIUM_DIET,
		label: 'Low Sodium Diet',
	},
	{
		id: DietTypesId.FLUID_RESTRICTIONS,
		name: DietTypes.FLUID_RESTRICTIONS,
		label: 'Fluid Restrictions',
	},
	{
		id: DietTypesId.OTHER,
		name: DietTypes.OTHER,
		label: 'Other',
	},
];

export const painLevels = [
	{
		name: '0',
		value: 0,
		desc: 'very happy',
	},
	{
		name: '1-2',
		value: 2,
		desc: 'hurts a little',
	},
	{
		name: '3-4',
		value: 4,
		desc: 'hurts more',
	},
	{
		name: '5-6',
		value: 6,
		desc: 'hurts even more',
	},
	{
		name: '7-8',
		value: 8,
		desc: 'hurts a lot',
	},
	{
		name: '9-10',
		value: 10,
		desc: 'hurts really bad',
	},
];

export const painLevelOptions = [
	{
		label: '0',
		value: '0',
	},
	{
		label: '1-2',
		value: '2',
	},
	{
		label: '3-4',
		value: '4',
	},
	{
		label: '5-6',
		value: '6',
	},
	{
		label: '7-8',
		value: '8',
	},
	{
		label: '9-10',
		value: '10',
	},
];

const memorialBaseUrl = 'https://static.solaborate.com/memorial/logos/';

export const initialIsolations = [
	{
		src: `${memorialBaseUrl}contact.png`,
		name: 'Contact',
		key: 'CON',
	},
	{
		src: `${memorialBaseUrl}contact-plus.png`,
		name: 'Contact Plus',
		key: 'CONP',
	},
	{
		src: `${memorialBaseUrl}droplet.png`,
		name: 'Droplet Precautions',
		key: 'DRLPR',
	},
	{
		src: `${memorialBaseUrl}droplet-plus.png`,
		name: 'Droplet Plus',
		key: 'DRLP',
	},
	{
		src: `${memorialBaseUrl}airborne.png`,
		name: 'Airborne Precautions',
		key: 'ABP',
	},

	{
		src: `${memorialBaseUrl}chemo.png`,
		name: 'Chemo Precautions',
		key: 'CHP',
	},
	{
		src: `${memorialBaseUrl}neutropenic.png`,
		name: 'Neutropenic',
		key: 'NEU',
	},
];

export const isolationOptions = [
	{
		label: 'Contact',
		value: 'CON',
	},
	{
		label: 'Contact Plus',
		value: 'CONP',
	},
	{
		label: 'Droplet Precautions',
		value: 'DRLPR',
	},
	{
		label: 'Droplet Plus',
		value: 'DRLP',
	},
	{
		label: 'Airborne Precautions',
		value: 'ABP',
	},

	{
		label: 'Chemo Precautions',
		value: 'CHP',
	},
	{
		label: 'Neutropenic',
		value: 'NEU',
	},
];

export const goingHomeOptions = intl => [
	{ value: 1, label: intl.formatMessage({ id: 'today' }) },
	{ value: 2, label: intl.formatMessage({ id: 'within24Hours' }) },
	{ value: 3, label: intl.formatMessage({ id: 'is2or3days' }) },
	{ value: 4, label: intl.formatMessage({ id: 'more3days' }) },
];

export const rideAnswerOptions = intl => [
	{ value: true, label: intl.formatMessage({ id: 'rideIsConfirmed' }) },
	{ value: false, label: intl.formatMessage({ id: 'rideIsNotConfirmed' }) },
];

export const sensoryPerceptions = intl => [
	{
		label: intl.formatMessage({ id: 'completelyLimited' }),
		value: 1,
		key: intl.formatMessage({ id: 'completelyLimitedLabel' }),
	},
	{
		label: intl.formatMessage({ id: 'veryLimited' }),
		value: 2,
		key: intl.formatMessage({ id: 'veryLimitedLabel' }),
	},
	{
		label: intl.formatMessage({ id: 'slightlyLimited' }),
		value: 3,
		key: intl.formatMessage({ id: 'slightlyLimitedLabel' }),
	},
	{
		label: intl.formatMessage({ id: 'noImpairment' }),
		value: 4,
		key: intl.formatMessage({ id: 'noImpairmentLabel' }),
	},
];

export const skinMoisture = intl => [
	{
		label: intl.formatMessage({ id: 'constantlyMoistDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'constantlyMoist' }),
	},
	{
		label: intl.formatMessage({ id: 'veryMoistDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'veryMoist' }),
	},
	{
		label: intl.formatMessage({ id: 'occasionallyMoistDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'occasionallyMoist' }),
	},
	{
		label: intl.formatMessage({ id: 'rarelyMoistDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'rarelyMoist' }),
	},
];

export const activity = intl => [
	{
		label: intl.formatMessage({ id: 'bedfastDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'bedfast' }),
	},
	{
		label: intl.formatMessage({ id: 'chairfastDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'chairfast' }),
	},
	{
		label: intl.formatMessage({ id: 'walksOccasionallyDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'walksOccasionally' }),
	},
	{
		label: intl.formatMessage({ id: 'walksFrequentlyDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'walksFrequently' }),
	},
];

export const mobility = intl => [
	{
		label: intl.formatMessage({ id: 'completelyImmobileDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'completelyImmobile' }),
	},
	{
		label: intl.formatMessage({ id: 'veryLimitedDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'veryLimitedTitle' }),
	},
	{
		label: intl.formatMessage({ id: 'slightlyLimitedDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'slightlyLimitedTitle' }),
	},
	{
		label: intl.formatMessage({ id: 'noLimitationDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'noLimitation' }),
	},
];

export const nutrition = intl => [
	{
		label: intl.formatMessage({ id: 'veryPoorDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'veryPoor' }),
	},
	{
		label: intl.formatMessage({ id: 'probablyInadequateDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'probablyInadequate' }),
	},
	{
		label: intl.formatMessage({ id: 'adequateDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'adequate' }),
	},
	{
		label: intl.formatMessage({ id: 'excellentDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'excellent' }),
	},
];

export const frictionShear = intl => [
	{
		label: intl.formatMessage({ id: 'problemDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'problem' }),
	},
	{
		label: intl.formatMessage({ id: 'potentialProblemDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'potentialProblem' }),
	},
	{
		label: intl.formatMessage({ id: 'noApparentProblemDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'noApparentProblem' }),
	},
];

export const dailyWeightOptions = intl => [
	{ value: true, label: intl.formatMessage({ id: 'yes' }) },
	{ value: false, label: intl.formatMessage({ id: 'no' }) },
];

const otherOption = 40;

export const outOfTheRoomOptions = intl => [
	{ label: intl.formatMessage({ id: 'patientIsOutOfTheRoom' }), id: 0 },
	{ label: intl.formatMessage({ id: 'surgery' }), id: 1 },
	{ label: intl.formatMessage({ id: 'physicalTherapy' }), id: 2 },
	{ label: intl.formatMessage({ id: 'radiology' }), id: 3 },
	{ label: intl.formatMessage({ id: 'patientisGoingDownForACTScan' }), id: 4 },
	{ label: intl.formatMessage({ id: 'patientIsGoingDownForAnMRI' }), id: 5 },
	{ label: intl.formatMessage({ id: 'ultrasound' }), id: 6 },
	{ label: intl.formatMessage({ id: 'ambulating' }), id: 7 },
	{ label: intl.formatMessage({ id: 'paracentesis' }), id: 8 },
	{ label: intl.formatMessage({ id: 'sunshinePrivileges' }), id: 9 },
	{ label: intl.formatMessage({ id: 'transferring' }), id: 10 },
	{ label: intl.formatMessage({ id: 'erCp' }), id: 11 },
	{ label: intl.formatMessage({ id: 'dialysis' }), id: 12 },
	{ label: intl.formatMessage({ id: 'ir' }), id: 13 },
	{ label: intl.formatMessage({ id: 'ct' }), id: 14 },
	{ label: intl.formatMessage({ id: 'xray' }), id: 15 },
	{ label: intl.formatMessage({ id: 'orAcronym' }), id: 16 },
	{ label: intl.formatMessage({ id: 'procedure' }), id: 17 },
	{ label: intl.formatMessage({ id: 'swallowTest' }), id: 18 },
	{ label: intl.formatMessage({ id: 'mri' }), id: 19 },
	{ label: intl.formatMessage({ id: 'inHallway' }), id: 20 },
	{ label: intl.formatMessage({ id: 'cathLab' }), id: 21 },
	{ label: intl.formatMessage({ id: 'restroom' }), id: 22 },
	{ label: intl.formatMessage({ id: 'restroomByRn' }), id: 23 },
	{ label: intl.formatMessage({ id: 's529' }), id: 24 },
	{ label: intl.formatMessage({ id: 'j516' }), id: 25 },
	{ label: intl.formatMessage({ id: 'dc' }), id: 26 },
	{ label: intl.formatMessage({ id: 'nuclearMed' }), id: 27 },
	{ label: intl.formatMessage({ id: 'epLab' }), id: 28 },
	{ label: intl.formatMessage({ id: 'liveSitterUntilAm' }), id: 29 },
	{ label: intl.formatMessage({ id: 's732' }), id: 30 },
	{ label: intl.formatMessage({ id: 'giLab' }), id: 31 },
	{ label: intl.formatMessage({ id: 'tee' }), id: 32 },
	{ label: intl.formatMessage({ id: 'pegTubePlacement' }), id: 33 },
	{ label: intl.formatMessage({ id: 'discharge' }), id: 34 },
	{ label: intl.formatMessage({ id: 'test' }), id: 35 },
	{ label: intl.formatMessage({ id: 'walking' }), id: 36 },
	{ label: intl.formatMessage({ id: 'moveTo527' }), id: 37 },
	{ label: intl.formatMessage({ id: 'walkingWithNurse' }), id: 38 },
	{ label: intl.formatMessage({ id: 'eeg' }), id: 39 },
	{ label: intl.formatMessage({ id: 'other' }), id: otherOption },
];
