import React from 'react';
import {
	MeasurementTypes,
	UnitCategoryTypes,
	MeasurementUnits,
	ManualAlertTypes,
	AiAlertType,
	AiAnalyticsType,
	AnalyticsCategory,
} from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import Heart from 'icons/Monitoring/Heart.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import BedRails from 'icons/Monitoring/BedRails.jsx';
import PatientPosition from 'icons/Monitoring/PatientPosition.jsx';
import HandWashing from 'icons/Monitoring/HandWashing.jsx';
import FaceDown from 'icons/Monitoring/FaceDown.jsx';
import LayingLeft from 'icons/Monitoring/LayingLeft.js';
import LayingOnBack from 'icons/Monitoring/LayingOnBack.js';
import SitCenter from 'icons/Monitoring/SitCenter.js';
import PatientMobility from 'icons/Monitoring/PatientMobility.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import PatientGettingOutOfChairIcon from 'icons/Monitoring/PatientGettingOutOfChairIcon.js';
import PressureInjury from 'icons/Monitoring/PressureInjury.jsx';

const imageUrl = `${healthCareCdnUrl}vsm/`;

export const manualAlertType = darkMode => [
	{
		id: 1,
		type: ManualAlertTypes.FALL_DETECTED,
		icon: <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 2,
		type: ManualAlertTypes.RISK_OF_FALL,
		icon: <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 3,
		type: ManualAlertTypes.PATIENT_NEEDS_HELP,
		icon: <Heart />,
	},
	{
		id: 4,
		type: ManualAlertTypes.PATIENT_REQUESTED_HELP,
		icon: <Heart />,
	},
	{
		id: 5,
		type: ManualAlertTypes.CUSTOM,
		icon: <Heart />,
	},
];

export const measurementAlert = [
	{
		id: 0,
		type: MeasurementTypes.BLOOD_PRESSURE,
		icon: `${imageUrl}Heart-1.svg`,
		unit: 'mmHg',
	},
	{
		id: 1,
		type: MeasurementTypes.HEART_RATE,
		icon: `${imageUrl}Heart-1.svg`,
		unit: MeasurementUnits.BPM,
	},
	{
		id: 2,
		type: MeasurementTypes.OXYGEN,
		icon: `${imageUrl}Respiratory-1.svg`,
		unit: '%',
	},
	{
		id: 3,
		type: MeasurementTypes.DIABETES,
		icon: `${imageUrl}OtherData-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
	},
	{
		id: 4,
		type: MeasurementTypes.TEMPERATURE,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.TEMPERATURE,
	},
	{
		id: 5,
		type: MeasurementTypes.WEIGHT,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
	{
		id: 6,
		type: MeasurementTypes.SLEEP,
		icon: `${imageUrl}Sleep.svg`,
		unit: translate('hrs'),
	},
	{
		id: 7,
		type: MeasurementTypes.BODY_MASS_INDEX,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: 'BMI',
	},
	{
		id: 8,
		type: MeasurementTypes.PI,
		icon: `${imageUrl}Respiratory-1.svg`,
		unit: '%',
	},
	{
		id: 9,
		type: MeasurementTypes.ELECTROCARDIOGRAPHY,
		icon: `${imageUrl}Body-Measurements-1.svg`,
	},
	{
		id: 10,
		type: MeasurementTypes.BLOOD_GLUCOSE,
		icon: `${imageUrl}blood-glucose.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
	},
	{
		id: 11,
		type: MeasurementTypes.STETHOSCOPE,
		icon: `${imageUrl}stethoscope.svg`,
	},
	{
		id: 12,
		type: MeasurementTypes.DISTANCE_WALKING_RUNNING,
		icon: `${imageUrl}activity.svg`,
		unit: translate('steps'),
	},
	{
		id: 13,
		type: MeasurementTypes.FLIGHTS_CLIMBED,
		icon: `${imageUrl}activity.svg`,
		unit: translate(MeasurementTypes.FLIGHTS_CLIMBED),
	},
	{
		id: 14,
		type: MeasurementTypes.STEPS,
		icon: `${imageUrl}activity.svg`,
		unit: translate('steps'),
	},
	{
		id: 15,
		type: MeasurementTypes.LEAN_BODY_MASS,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
	{
		id: 16,
		type: MeasurementTypes.BODY_FAT,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '%',
	},
	{
		id: 17,
		type: MeasurementTypes.BODY_MASS_INDEX,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: 'BMI',
	},
	{
		id: 18,
		type: MeasurementTypes.FALLEN_TIMES,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
	},
	{
		id: 19,
		type: MeasurementTypes.RESPIRATORY_RATE,
		icon: `${imageUrl}Respiratory-1.svg`,
		unit: MeasurementUnits.BPM,
	},
	{
		id: 20,
		type: MeasurementTypes.HEIGHT,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.HEIGHT,
	},
	{
		id: 21,
		type: MeasurementTypes.STRESS_LEVEL,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
	},
	{
		id: 22,
		type: MeasurementTypes.HRV_INDEX,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
	},
	{
		id: 23,
		type: MeasurementTypes.BILIRUBIN,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
	},
	{
		id: 24,
		type: MeasurementTypes.MUSCLE_MASS,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.WEIGHT,
	},
	{
		id: 25,
		type: MeasurementTypes.BODY_WATER,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '%',
	},
	{
		id: 26,
		type: MeasurementTypes.VISCERAL_FAT_GRADE,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
	},
	{
		id: 26,
		type: MeasurementTypes.HDL_CHOLESTEROL,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
	},
	{
		id: 27,
		type: MeasurementTypes.TOTAL_CHOLESTEROL,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
	},
	{
		id: 28,
		type: MeasurementTypes.WAIST_CIRCUMFERENCE,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
		unitCategoryId: UnitCategoryTypes.HEIGHT,
	},
	{
		id: 29,
		type: MeasurementTypes.STETHO,
		icon: `${imageUrl}stethoscope.svg`,
		unit: '',
	},
	{
		id: 30,
		type: MeasurementTypes.FACIAL_SKIN_AGE,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '',
	},
	{
		id: 31,
		type: MeasurementTypes.CARDIAC_WORKLOAD,
		icon: `${imageUrl}Heart-1.svg`,
		unit: 'L/min',
	},
	{
		id: 32,
		type: MeasurementTypes.HEART_ATTACK_RISK,
		icon: `${imageUrl}Heart-1.svg`,
		unit: '%',
	},
	{
		id: 33,
		type: MeasurementTypes.STROKE_RISK,
		icon: `${imageUrl}Body-Measurements-1.svg`,
		unit: '%',
	},
	{
		id: 34,
		type: MeasurementTypes.SPIROMETER,
		icon: `${imageUrl}spirometer.svg`,
		unit: '',
	},
];

export const aiAlertType = isDarkMode => [
	{
		id: 0,
		type: AiAlertType.RAILS,
		icon: <BedRails color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 1,
		type: AiAlertType.PATIENT_SITTING,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 2,
		type: AiAlertType.PATIENT_STANDING,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 3,
		type: AiAlertType.PATIENT_FACE_DOWN,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 4,
		type: AiAlertType.PATIENT_LYING_SIDE,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 5,
		type: AiAlertType.PATIENT_LYING_FRONT,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 6,
		type: AiAlertType.PATIENT_LYING_RIGHT_SIDE,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 7,
		type: AiAlertType.FALL_DETECTION,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 8,
		type: AiAlertType.PATIENT_INACTIVE,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 9,
		type: AiAlertType.PATIENT_GETTING_OUT_OF_BED,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 10,
		type: AiAlertType.PATIENT_MOBILITY,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 11,
		type: AiAlertType.PATIENT_LYING_LEFT_SIDE,
		icon: <PatientPosition color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 12,
		type: AiAlertType.HANDS_DISINFECTED,
		icon: <HandWashing />,
	},
	{
		id: 13,
		type: AiAlertType.IV_BAG,
		icon: <IvBag color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 14,
		type: AiAlertType.LOW_IV_BAG,
		icon: <IvBag color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 15,
		type: AiAlertType.PATIENT_GETTING_OUT_OF_CHAIR,
		icon: <PatientGettingOutOfChairIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
	},
	{
		id: 16,
		type: AiAlertType.PATIENT_INACTIVE_INFORMATION,
		icon: <PressureInjury />,
	},
];

export const AiAnalyticType = isDarkMode => [
	{
		id: 1,
		type: AiAnalyticsType.LYING_FRONT,
		icon: <FaceDown color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		category: AnalyticsCategory.PATIENT_POSITION,
	},
	{
		id: 2,
		type: AiAnalyticsType.LYING_SIDE,
		icon: <LayingLeft color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		category: AnalyticsCategory.PATIENT_POSITION,
	},
	{
		id: 3,
		type: AiAnalyticsType.LYING_DOWN,
		icon: <LayingOnBack color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		category: AnalyticsCategory.PATIENT_POSITION,
	},
	{
		id: 4,
		type: AiAnalyticsType.SITTING,
		icon: <SitCenter color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		category: AnalyticsCategory.PATIENT_POSITION,
	},
	{
		id: 5,
		type: AiAnalyticsType.WALKING,
		icon: <PatientMobility color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		category: AnalyticsCategory.PATIENT_POSITION,
	},
	{
		id: 6,
		type: AiAnalyticsType.IV_BAG_FLUID_LEVEL,
		icon: <IvBag color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
		category: AnalyticsCategory.IV_BAG_FLUID_LEVEL,
	},
];

export const MainAlertType = {
	MEASUREMENT: 1,
	AI: 2,
	MANUAL: 3,
};
