import React, { useState } from 'react';
import classNames from 'classnames';
import { SectorTypes } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';

const OptionsList = ({ options, selectedOptions, onChange, onSelectLocation, selectedId }) => {
	const intl = useIntl();
	const selOptions = { ...selectedOptions };
	const toggleExpand = (selectedOptionId, isAddingSection) => {
		if (selectedOptions[selectedOptionId] && !isAddingSection) {
			delete selOptions[selectedOptionId];
		} else {
			selOptions[selectedOptionId] = {};
		}
		onChange(selOptions);
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		selOptions[optionId] = subSelections;
		onChange(selOptions);
	};

	const selectOption = option => {
		onSelectLocation(option);
	};

	return (
		<div>
			{options.map(option => (
				<div className={classNames('tree', !option.id && !option.isNewOption ? 'hidden' : '')} key={option.id}>
					<div className={classNames('tree__child', selectedId === option.id ? 'selected' : '')}>
						<div {...(option.type === SectorTypes.REGION && { onClick: () => toggleExpand(option.id) })}>
							<i
								className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
								onClick={() => {
									toggleExpand(option.id);
								}}>
								{selectedOptions[option.id] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
							</i>
							<i className='material-icons-outlined'>{option.icon}</i>
							<span
								data-tooltip={option.type === SectorTypes.ROOM ? intl.formatMessage({ id: 'unAssignableLevel' }) : undefined}
								data-position='bottom'
								style={{ cursor: 'pointer' }}
								className={classNames(
									'link',
									option.type === SectorTypes.ROOM || option.type === SectorTypes.REGION ? 'unassignable' : ''
								)}
								onClick={
									option.type !== SectorTypes.ROOM && option.type !== SectorTypes.REGION ? () => selectOption(option) : undefined
								}>
								{option.name}
								<i className={classNames('material-icons-outlined', 'room-status', option.status)}>fiber_manual_record</i>
							</span>
						</div>
					</div>
					{option.subOptions && selectedOptions[option.id] && (
						<OptionsList
							options={option.subOptions}
							selectedOptions={selectedOptions[option.id]}
							onChange={subSelections => handleSubOptionsListChange(option.id, subSelections)}
							onSelectLocation={onSelectLocation}
							selectedId={selectedId}
							intl={intl}
						/>
					)}
				</div>
			))}
		</div>
	);
};

const AssignDropdown = ({ preSelected, onSelectLocation, data, selectedId }) => {
	try {
		const [selectedOptions, setSelectedOptions] = useState(preSelected);

		return (
			<div className='assign-dropdown'>
				{data.length > 0 && (
					<OptionsList
						onSelectLocation={onSelectLocation}
						options={data}
						onChange={selectedOptionsToChange => setSelectedOptions(selectedOptionsToChange)}
						selectedOptions={selectedOptions}
						selectedId={selectedId}
					/>
				)}
				{data.length === 0 && (
					<div className='tree' style={{ wordBreak: 'normal', textAlign: 'center' }}>
						{translate('healthSystemDoesNotHaveCreatedHospitals')}
					</div>
				)}
			</div>
		);
	} catch (e) {
		return (
			<div className='tree' style={{ wordBreak: 'normal', textAlign: 'center' }}>
				{translate('healthSystemDoesNotHaveCreatedHospitals')}
			</div>
		);
	}
};

export default AssignDropdown;
