import { createCareEvent, deleteCareEvent, editCareEvent, getCareEvents } from 'api/teamConfigurationProfiles.js';
import classNames from 'classnames';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import { Alert, Button, Grid, Input, Modal, Textarea } from 'components/index.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const CareEvents = props => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const [transformedHealthSystemConfigs, setTransformedHealthSystemConfigs] = useState([]);
	const [totalConfigs, setTotalConfigs] = useState(null);
	const [alertMessage, setAlertMessage] = useState(null);
	const [isCareEventModalOpen, setIsCareEventModalOpen] = useState(false);
	const [isDeleteConfigModalOpen, setIsDeleteConfigModalOpen] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);

	const [careEventTitle, setCareEventTitle] = useState('');
	const [careEventDescription, setCareEventDescription] = useState('');
	const [careEventId, setCareEventId] = useState('');

	const healthSystemConfigHeaders = [
		{ title: 'Title', id: 'title' },
		{ title: 'Description', id: 'description' },
		{ action: '', id: 'actions' },
	];
	const companyConfigurations = useSelector(state => state.company.companySettings.companyConfigurations);

	const careEventType = {
		LegacyBasicManualAlert: 1,
		FrontLineNurses: 2,
		SafetyCompanions: 3,
		Rounding: 4,
	};

	const activeSubTab = {
		SAFETY_COMPANIONS: 0,
		FRONTLINE_NURSES: 1,
		ROUNDING: 2,
	};

	const fetchConfiguration = async (pageSize, pageIndex) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		const response = await getCareEvents({
			pageSize: pageSize ?? props.pageSize,
			pageIndex: pageIndex ?? props.pageIndex,
			teamCareEventType: getTeamCareEventType(),
			healthSystemId: props.selectedHealthSystem?.value,
		});
		if (response?.error) {
			setAlertMessage(response.error.message);
			return;
		}
		getHealthSystemConfigs(response?.teamCareEvents);
		setTotalConfigs(response?.total);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchConfiguration();
	}, [props.pageIndex, props.pageSize, props.selectedHealthSystem?.value, props.activeSubTab]);

	const getTeamCareEventType = () => {
		switch (props.activeSubTab) {
			case 0: {
				return careEventType.SafetyCompanions;
			}
			case 1: {
				return careEventType.FrontLineNurses;
			}
			case 2: {
				return careEventType.Rounding;
			}
			default: {
				return careEventType.SafetyCompanions;
			}
		}
	};

	const resetCareEventData = () => {
		setCareEventTitle('');
		setCareEventDescription('');
		setCareEventId('');
	};

	const transformArray = (array, allHealthSystems = false) => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		if (allHealthSystems && getUserRole() === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const onTabChange = async tabIndex => {
		await props.onSubTabChange(tabIndex);
	};

	const createNewCareEvent = async (name, description) => {
		const response = await createCareEvent({
			teamId: props.selectedHealthSystem?.value,
			params: {
				name,
				...(description.length > 0 && { description }),
				teamCareEventType: getTeamCareEventType(),
			},
		});
		if (response.error) {
			setAlertMessage(response.error.message);
		}
		fetchConfiguration();
	};

	const editCareEventById = async (id, params) => {
		const response = await editCareEvent({
			id,
			params,
		});
		if (response.error) {
			setAlertMessage(response.error.message);
			return;
		}
		fetchConfiguration();
	};

	const deleteCareEventById = async id => {
		const response = await deleteCareEvent({ id });
		if (response.error) {
			setAlertMessage(response.error.message);
			return;
		}
		fetchConfiguration();
	};

	const getHealthSystemConfigs = configurationsList => {
		if (!configurationsList?.length) {
			setTransformedHealthSystemConfigs([]);
			setIsLoading(false);
			return;
		}
		const transformConfigs = configurationsList.map(config => ({
			title: config.name,
			description: config.description,
			actions: (
				<div className='wrapped'>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='edit'
						data-tooltip={intl.formatMessage({ id: 'edit' })}
						data-position='top'
						onClick={() => {
							setCareEventTitle(config.name);
							setCareEventDescription(config.description ?? '');
							setCareEventId(config.id);
							setIsCareEventModalOpen(true);
						}}>
						create
					</span>
					<i
						className='material-icons-outlined cursor-pointer'
						data-cy='deleteActiveDirectory'
						style={{ color: 'red', backgroundColor: 'white' }}
						data-tooltip={intl.formatMessage({ id: 'delete' })}
						data-position='top'
						onClick={() => {
							setCareEventId(config.id);
							setIsDeleteConfigModalOpen(true);
						}}>
						delete
					</i>
				</div>
			),
		}));
		setTransformedHealthSystemConfigs(transformConfigs);
		setIsLoading(false);
	};

	const toggleConfigModal = () => {
		setIsCareEventModalOpen(prevState => !prevState);
	};

	const onCareEventSave = async () => {
		if (isModalLoading) {
			return;
		}
		setIsModalLoading(true);
		if (careEventId) {
			await editCareEventById(careEventId, {
				name: careEventTitle,
				...(careEventDescription.length > 0 && { description: careEventDescription }),
				teamCareEventType: getTeamCareEventType(),
			});
		} else {
			await createNewCareEvent(careEventTitle, careEventDescription);
		}
		setIsCareEventModalOpen(false);
		resetCareEventData();
		setIsModalLoading(false);
	};
	return (
		<div className='device-config'>
			<CustomTable
				isLoading={isLoading}
				headers={healthSystemConfigHeaders}
				rows={isLoading ? [] : transformedHealthSystemConfigs}>
				<Grid columns='1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={translate('all')}
						classNamePrefix={classNames(isLoading ? 'react-select-disabled' : '')}
						options={transformArray(props.healthSystems, true)}
						onChange={props.setSelectedHealthSystem}
					/>
					{props.selectedHealthSystem?.value !== '0' && (
						<Button text='Create care event' horizAlign='end' className='text-transform-none' onClick={toggleConfigModal} />
					)}
				</Grid>
				<div>
					<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
						<li className={props.activeSubTab === activeSubTab.SAFETY_COMPANIONS ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.SAFETY_COMPANIONS)}>
								{intl.formatMessage({ id: 'safetyCompanions' })}
							</span>
						</li>
						<li className={props.activeSubTab === activeSubTab.FRONTLINE_NURSES ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.FRONTLINE_NURSES)}>
								{intl.formatMessage({ id: 'frontlineNurses' })}
							</span>
						</li>
						{getConfigurationValue(companyConfigurations[RoundingSettings.RoundingCareEvents]) && (
							<li className={props.activeSubTab === activeSubTab.ROUNDING ? 'active' : ''}>
								<span onClick={() => onTabChange(activeSubTab.ROUNDING)}>{intl.formatMessage({ id: 'rounding' })}</span>
							</li>
						)}
					</ul>
				</div>
			</CustomTable>
			<Pagination
				totalCount={totalConfigs}
				pageSize={props.pageSize}
				pageIndex={props.pageIndex}
				onChange={(pageSize, pageIndex) => {
					props.onPaginationChange(pageSize, pageIndex);
				}}
			/>
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isDeleteConfigModalOpen}
				position='center'
				className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				onModalSubmit={async () => {
					await deleteCareEventById(careEventId);
					setIsDeleteConfigModalOpen(false);
					resetCareEventData();
				}}
				onModalClose={() => {
					setIsDeleteConfigModalOpen(false);
					resetCareEventData();
				}}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('deleteConfiguration')}</h3>
					<p>{translate('areYouSureToDeleteConfig')}</p>
				</form>
			</Modal>
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isCareEventModalOpen}
				position='right'
				className='care-events-modal border-radius-modal-wrapper'
				isSubmitDisabled={careEventTitle.length === 0}
				onModalSubmit={onCareEventSave}
				isLoading={isModalLoading}
				onModalClose={() => {
					setIsCareEventModalOpen(false);
					resetCareEventData();
				}}
				shouldSubmitOnEnter={false}
				title='Create care event'>
				<form onSubmit={event => event.preventDefault()}>
					<h3>{translate('addCareEvent')}</h3>
					<Input
						type='text'
						label='Title'
						value={careEventTitle}
						validationOptions={{ maxLength: 30 }}
						placeholder='Care Event Title'
						onChange={evt => setCareEventTitle(evt.target.value)}
					/>
					<Textarea
						maxLength={400}
						maxNumberLimit={40}
						rows={8}
						value={careEventDescription}
						onChange={evt => setCareEventDescription(evt.target.value)}
						name='message'
						placeholder='Care Event Description'
						label='Description'
					/>
				</form>
			</Modal>
			<Alert display={alertMessage} fixed={true} hideCloseButton={true} message={alertMessage} variant='dark' />
		</div>
	);
};

export default CareEvents;
