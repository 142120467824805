import styled from 'styled-components';
import Modal from 'components/Modal.jsx';
import PoolingFlow from 'containers/PoolingFlow/index.jsx';

const EditPoolingModal = props => (
	<ModalWrapper>
		<Modal display={true} position='right' onModalClose={props.toggleEditPoolingModal}>
			<PoolingFlow changePage={props.toggleEditPoolingModal} isEdit={props.isEdit} />
		</Modal>
	</ModalWrapper>
);

const ModalWrapper = styled.div`
	.modal.right.show {
		height: 100vh;
		overflow: hidden;

		.modal__wrapper {
			margin: initial;
			.modal__content {
				height: 100vh;
				padding-bottom: var(--spacing-m);
			}
		}
	}

	.modal__actions {
		display: none;
	}
`;

export default EditPoolingModal;
