import React from 'react';
import styled, { css } from 'styled-components';

import { ButtonType } from 'constants/enums.js';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @typedef {{variant?: number}} props
 * @type {import('styled-components').StyledComponent<"button", any, props, never>}
 */

const StyledButton = styled.button`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 600;
	text-transform: none;
	padding: ${LightTheme.spacing[2]}px ${LightTheme.spacing[4]}px;
	border-radius: ${LightTheme.borderRadius.buttons}px;
	background: transparent;
	color: ${LightTheme.colors.blueOne};

	${props =>
		props.$variant === ButtonType.SUBMIT &&
		css`
			background: ${LightTheme.colors.blueOne};
			color: ${LightTheme.colors.grayZero};
		`}

	${props =>
		props.$variant === ButtonType.CANCEL &&
		css`
			border: 1px solid ${LightTheme.colors.blueOne};
			margin-right: var(--spacing-m);
		`}
`;

/**
 * @param {props & React.PropsWithRef<React.ButtonHTMLAttributes<HTMLButtonElement>>} props
 */
const Button = ({ children, variant = null, ...htmlAttributes }) => {
	return (
		<StyledButton {...htmlAttributes} $variant={variant}>
			{children}
		</StyledButton>
	);
};

export default Button;
