import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { AiAlertType, PatientWarnings } from 'constants/enums.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import PressureInjury from 'icons/Monitoring/PressureInjury.jsx';
import IvBag from 'icons/Monitoring/IvBag.js';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import PatientMobility from 'icons/Monitoring/PatientMobility.jsx';
import Rails from 'icons/Monitoring/Rails.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';

export const getLanguagePrefix = () => {
	let result = '';
	const locale = getPreferredLanguageLocale();
	if (locale === LOCALES.ARABIC) {
		result = 'arabic-';
	}
	if (locale === LOCALES.GERMAN) {
		result = 'german-';
	}
	if (locale === LOCALES.SPANISH) {
		result = 'spanish-';
	}
	return result;
};

export const getHealthConcern = (type, darkMode) => {
	let text = null;
	let imgIcon = null;
	switch (type) {
		case PatientWarnings.FALL_DETECTED: {
			text = translate('pleaseCheckPatient');
			imgIcon = <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_SITTING: {
			text = translate('patientSitting');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_STANDING: {
			text = translate('patientStanding');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_LYING_FRONT: {
			text = translate('patientLyingFront');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_LYING_SIDE: {
			text = translate('patientLyingSide');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_LYING_RIGHT_SIDE: {
			text = translate('patientFaceDown');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_INACTIVE_INFORMATION:
		case AiAlertType.PATIENT_INACTIVE: {
			text = translate('patientInactive');
			imgIcon = <PressureInjury color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_GETTING_OUT_OF_BED: {
			text = translate('patientGettingOutOfBed');
			imgIcon = <OutOfBed color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.RAILS: {
			text = translate('railDownMessage');
			imgIcon = <Rails color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_MOBILITY: {
			text = translate('personIsDetectedWalking');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.IV_BAG:
		case AiAlertType.LOW_IV_BAG: {
			text = translate('ivBagCheck');
			imgIcon = <IvBag color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		case AiAlertType.PATIENT_GETTING_OUT_OF_CHAIR: {
			text = translate('personGettingOutOfChairMessage');
			imgIcon = <PatientMobility color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
		default: {
			text = translate('pleaseCheckPatient');
			imgIcon = <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			break;
		}
	}
	return { text, imgIcon };
};
