import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import ProvidersVisits from 'components/DashboardCharts/ProvidersVisits.jsx';
import Grid from 'components/Grid.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getRoleValueById } from 'infrastructure/helpers/commonHelpers.js';
import { useState } from 'react';

const AllProviders = props => {
	const [error, setError] = useState(null);
	const [currentRole, setCurrentRole] = useState(props.selectedProvider.memberRoles[0] || {});

	return (
		<>
			<div className='visits-header'>
				<div onClick={() => props.setSelectedProvider(null)}>
					<i className='material-icons-outlined'>arrow_back</i>
				</div>
				<span>
					{props.selectedProvider.firstName} {props.selectedProvider.lastName}
				</span>
				<div>
					{props.selectedProvider?.memberRoles.map(role => (
						<span
							key={role.id}
							onClick={() => setCurrentRole(role)}
							className={classNames('provider-role', currentRole?.name === role.name ? 'active' : '')}>
							<img
								src={`${healthCareCdnUrl}user-types/${
									currentRole?.name === role.name ? `${role.name.toLowerCase()}-active` : role.name.toLowerCase()
								}.svg?v3`}
								alt='icon'
							/>
							{getRoleValueById(role.id)}
							<div>
								<i className='material-icons'>done</i>
							</div>
						</span>
					))}
				</div>
			</div>
			<Grid columns='1fr' className='dashboard-items'>
				<ProvidersVisits
					key={currentRole.id}
					currentRole={currentRole}
					setCurrentRole={setCurrentRole}
					start={props.start}
					end={props.end}
					selectedHealthSystem={props.selectedHealthSystem}
					selectedHospital={props.selectedHospital}
					selectedDepartment={props.selectedDepartment}
					selectedFloor={props.selectedFloor}
					selectedRoom={props.selectedRoom}
					selectedProvider={props.selectedProvider}
					selectedTimezone={props.selectedTimezone}
					additionalFilters={props.additionalFilters}
				/>
			</Grid>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AllProviders;
