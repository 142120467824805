import React, { useEffect, useState } from 'react';
import { Button, CustomDropdown } from 'components/index.js';
import FormInput from 'components/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import {
	addNewVerbalRedirection,
	addOrUpdateVerbalRedirectionLanguages,
	deleteVerbalRedirection,
	getAllVerbalRedirectionLanguages,
	getHealthSystemVerbalRedirectionLanguages,
	getVerbalRedirections,
} from 'api/verbalRedirections.js';
import classNames from 'classnames';

const VerbalRedirectionsAdmin = ({ healthSystemId, setError }) => {
	const intl = useIntl();
	const [newVerbalRedirection, setNewVerbalRedirection] = useState('');
	const [verbalRedirections, setVerbalRedirections] = useState([]);
	const [allLanguages, setAllLanguages] = useState([]);
	const [mySelectedLanguages, setMySelectedLanguages] = useState([]);
	const [isSubmitLanguagesLoading, setIsSubmitLanguagesLoading] = useState(false);
	const [verbalRedirectionsError, setVerbalRedirectionsError] = useState('');
	const [languagesError, setLanguagesError] = useState('');

	useEffect(() => {
		const fetchAllLanguages = async () => {
			const response = await getAllVerbalRedirectionLanguages();
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = response.languages.map(item => ({
				...item,
				value: item.id,
				label: item.localeName,
			}));
			setAllLanguages(mapped);
		};
		fetchAllLanguages();
	}, [intl]);

	useEffect(() => {
		const fetchVerbalRedirections = async () => {
			const response = await getVerbalRedirections(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = response.healthSystemVerbalRedirections.map(item => ({
				...item,
				id: item.title,
			}));
			setVerbalRedirections(mapped);
		};

		if (healthSystemId) {
			fetchVerbalRedirections();
		}
	}, [intl, healthSystemId]);

	useEffect(() => {
		const fetchSelectedLanguages = async () => {
			const response = await getHealthSystemVerbalRedirectionLanguages(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}

			let mapped = [];

			if (response.languages.length === 0) {
				const defaultLang = allLanguages.find(item => item.isDefault);
				if (defaultLang) {
					mapped = [{ ...defaultLang, value: defaultLang.id, label: defaultLang.localeName }];
				}
			} else {
				mapped = response.languages.map(item => ({
					...item,
					value: item.id,
					label: item.localeName,
				}));
			}

			setMySelectedLanguages(mapped);
		};

		if (healthSystemId && allLanguages.length > 0) {
			fetchSelectedLanguages();
		}
	}, [intl, healthSystemId, allLanguages]);

	const saveVerbalRedirection = async () => {
		if (!newVerbalRedirection) {
			return;
		}

		const response = await addNewVerbalRedirection(healthSystemId, newVerbalRedirection);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}

		setVerbalRedirections(prevState => [
			...prevState,
			{
				id: newVerbalRedirection,
				title: newVerbalRedirection,
			},
		]);
		setNewVerbalRedirection('');
	};

	const deleteOneVerbalRedirection = async id => {
		setVerbalRedirectionsError('');

		if (!id) {
			return;
		}
		if (verbalRedirections.length <= 1) {
			setVerbalRedirectionsError(intl.formatMessage({ id: 'atLeastOneMustBeSet' }));
			return;
		}
		const response = await deleteVerbalRedirection(healthSystemId, id);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		setVerbalRedirections(prevState => prevState.filter(item => item.id !== id));
	};

	const submitSelectedLanguages = async () => {
		setIsSubmitLanguagesLoading(true);
		setLanguagesError('');

		const mapped = mySelectedLanguages.map(({ value, label, ...rest }) => ({
			...rest,
		}));
		if (mapped.length === 0) {
			setLanguagesError(intl.formatMessage({ id: 'atLeastOneMustBeSet' }));
			return;
		}
		const response = await addOrUpdateVerbalRedirectionLanguages(healthSystemId, mapped);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
		}
		setIsSubmitLanguagesLoading(false);
	};

	return (
		<>
			<div className='position-relative feature-flag-options flex'>
				<p>{translate('languages')}</p>
				<CustomDropdown
					defaultOptions={mySelectedLanguages}
					initialOptions={allLanguages}
					onSelect={setMySelectedLanguages}
					title={intl.formatMessage({ id: 'languages' })}
					placeholder={intl.formatMessage({ id: 'languages' })}
					isDarkModeCompatible={false}
				/>
				{languagesError && <p className='--red-color error'>{languagesError}</p>}
				<Button
					type='button'
					onClick={submitSelectedLanguages}
					className={classNames('align-self-end', { disabled: mySelectedLanguages.length === 0 })}
					text={translate('submit')}
					isLoading={isSubmitLanguagesLoading}
				/>
			</div>
			<div className='position-relative feature-flag-options flex'>
				<p>{translate('verbalRedirection')}</p>
				<ul>
					{verbalRedirections.length === 0 && <li>No added verbal redirection sounds</li>}
					{verbalRedirections.map(item => (
						<li key={item.id}>
							{item.title}
							<i className='material-icons-outlined' onClick={() => deleteOneVerbalRedirection(item.id)}>
								delete
							</i>
						</li>
					))}
				</ul>

				<FormInput
					labelClassName='feature-flag-txt-label'
					id='verbalRedirection'
					placeholder={intl.formatMessage({ id: 'writeNewVerbalRedirection' })}
					type='text'
					value={newVerbalRedirection}
					onChange={e => setNewVerbalRedirection(e.target.value)}
					className='text-input feature-flag-txt-input'
					maxLength={127}
				/>
				{verbalRedirectionsError && <p className='--red-color error'>{verbalRedirectionsError}</p>}

				<Button
					type='button'
					onClick={saveVerbalRedirection}
					className={classNames('align-self-end', { disabled: !newVerbalRedirection })}
					text={translate('addNew')}
				/>
			</div>
		</>
	);
};

export default VerbalRedirectionsAdmin;
