import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import { CompanySettings, getConfig } from 'constants/configurationEnums.js';
import { getCompanyId } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Modal.jsx';
import {
	addHealthSystemCallBackgroundPicture,
	deleteHealthSystemCallBackgroundPicture,
	getBackgroundImages,
	uploadDigitalBackgroud,
} from 'api/configurations.js';
import { getBlobSasPicture } from 'api/doctors.js';
import SpinLoader from 'icons/Monitoring/SpinLoader.jsx';
import { updateTeamSettings } from 'api/adminConfigurations.js';
import { VirtualBackgroundTypes } from 'calls/views/VirtualBackgroundProvider.jsx';
import classNames from 'classnames';
import Button from 'components/Button.jsx';

const DigitalBackground = ({ selectedHealthSystemId, wallpaperBackgroundConfigs, setWallpaperBackgroundConfigs }) => {
	const intl = useIntl();
	const [isBackgroundImgsLoading, setIsBackgroundImgsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [backGroundImages, setBackGroundImages] = useState([]);
	const [loadingBgIds, setLoadingBgIds] = useState([]);
	const [selectedPhotoId, setSelectedPhotoId] = useState(null);
	const [showUploadLimitModal, setShowUploadLimitModal] = useState(null);

	const digitalBackgroundConfigs = {
		[CompanySettings.DIGITAL_BACKGROUND]: {
			type: CompanySettings.DIGITAL_BACKGROUND,
			icon: `${healthCareCdnUrl}admin/background-image-icon.svg`,
			title: 'backgroundImage',
			description: 'backgroundImagesAllowed',
		},
	};

	const defaultBackgroundConfig = wallpaperBackgroundConfigs[CompanySettings.HS_DEFAULT_BACKGROUND];

	const updateCompanySetting = async (key, val, isLocked) => {
		const settings = { ...wallpaperBackgroundConfigs };
		const found = settings[key];
		if (found) {
			found.value = val;
			found.isLocked = isLocked;
		} else {
			settings[key] = {
				isLocked: false,
				value: val,
				description: '',
			};
		}
		const params = {
			teamSettings: [
				{
					settingTypeId: key,
					value: val,
					isLocked,
				},
			],
			teamId: selectedHealthSystemId,
			isOverride: false,
			healthSystemId: selectedHealthSystemId,
		};
		const response = await updateTeamSettings(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			setWallpaperBackgroundConfigs(settings);
		}
	};

	const mapBlob = async (pictureUrl, index) => {
		const response = await getBlobSasPicture(pictureUrl, 'team-call-background-pictures');
		const responseError = response.errorResponse || response.error;
		if (responseError) {
			setError(responseError.message);
			return { url: null, id: index, pictureUrl };
		}
		const imgToUpload = { url: response.result.uri.uri, id: index, pictureUrl };
		return imgToUpload;
	};

	const onImageAdded = async event => {
		const file = event.target.files[0];
		const fileSizeInMB = file?.size / 1024 / 1024;
		if (fileSizeInMB > 5) {
			setShowUploadLimitModal('Files must be 5 MB max');
			return;
		}
		setError(null);
		setLoadingBgIds([backGroundImages.length + 1]);
		const formData = new FormData();
		formData.append('File', file);
		const response = await uploadDigitalBackgroud(formData, 'team-call-background');
		const responseError = response.errorResponse || response.error;
		if (responseError) {
			setError(responseError.message);
			setLoadingBgIds([]);
			return;
		}
		const addBgReponse = await addHealthSystemCallBackgroundPicture(response.fileName, getCompanyId(), selectedHealthSystemId);
		if (addBgReponse.error || !addBgReponse.hasSucceeded) {
			setError(response.error ? response.error.message : intl.formatMessage({ id: 'somethingWentWrong' }));
			setLoadingBgIds([]);
			return;
		}
		const mappedBlob = await mapBlob(response.fileName, backGroundImages.length + 1);
		const imgToUpload = { url: mappedBlob.url, pictureUrl: response.fileName, id: backGroundImages.length + 1 };
		setBackGroundImages(prevState => [...prevState, imgToUpload]);
		setLoadingBgIds([]);
	};

	const handleDelete = async () => {
		const response = await deleteHealthSystemCallBackgroundPicture(
			{
				pictureUrl: selectedPhotoId,
				isActive: false,
			},
			getCompanyId(),
			selectedHealthSystemId
		);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setBackGroundImages(prevState => prevState.filter(item => item.pictureUrl !== selectedPhotoId));
		setSelectedPhotoId(null);
	};

	useEffect(() => {
		const fetchImages = async () => {
			const response = await getBackgroundImages(getCompanyId(), selectedHealthSystemId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const ids = response.map((_, index) => index + 1);
			setLoadingBgIds(ids);
			const images = await Promise.all(response.map((item, index) => mapBlob(item.pictureUrl, index)));
			const imagesWithoutUrl = images.filter(item => item.url);
			if (imagesWithoutUrl.length > 0) {
				setBackGroundImages(images);
			} else {
				setBackGroundImages([]);
			}
			setIsBackgroundImgsLoading(false);
			setLoadingBgIds([]);
		};
		fetchImages();
	}, [selectedHealthSystemId]);

	const renderConfigItem = type => {
		return (
			<div className='flex call-config-settings'>
				<div className='call-config-type'>
					<div>
						<img src={digitalBackgroundConfigs[type].icon} alt='icon' />
					</div>
					<div className='call-config-description'>
						<p>{translate(digitalBackgroundConfigs[type].title)}</p>
						<span>{translate(digitalBackgroundConfigs[type].description)}</span>
					</div>
				</div>
				<div
					className='toggle-switch call-config-toggle'
					onClick={() =>
						updateCompanySetting(type, wallpaperBackgroundConfigs[type]?.value === 'true' ? 'false' : 'true', false)
					}>
					<input
						type='checkbox'
						onChange={() => null}
						checked={wallpaperBackgroundConfigs[type]?.value === 'true' ? true : false}
					/>
					<span className='toggle-body' />
				</div>
			</div>
		);
	};

	const showImages = () => getConfig(wallpaperBackgroundConfigs[CompanySettings.DIGITAL_BACKGROUND]).value;

	const setDefaultHsBackground = value => {
		updateCompanySetting(CompanySettings.HS_DEFAULT_BACKGROUND, value, false);
	};

	return (
		<>
			<Form className='digital-background'>
				<h3>{translate('digitalBackground')}</h3>
				{renderConfigItem(CompanySettings.DIGITAL_BACKGROUND)}
				{showImages() && (
					<div className='present-images'>
						<p>{translate('presentImage')}</p>
						<div className='background-images flex'>
							{backGroundImages.length < 5 && (
								<div className='add-background-image'>
									<label htmlFor='image-upload'>
										<img src={`${healthCareCdnUrl}admin/add-image-icon.svg`} alt='icon' />
										<span className='virtual-background-text'>{translate('addImage')}</span>
									</label>
									<input
										type='file'
										accept='image/png, image/jpeg'
										id='image-upload'
										onChange={event => onImageAdded(event)}
										style={{ display: 'none' }}
									/>
								</div>
							)}
							<div
								className={classNames(
									'add-background-image ',
									defaultBackgroundConfig?.value === VirtualBackgroundTypes.NONE ? 'default-selected' : ''
								)}
								onClick={() => setDefaultHsBackground(VirtualBackgroundTypes.NONE)}>
								<span className='default-image'>
									<p>{translate('setAsDefault')}</p>
								</span>
								<label htmlFor='image-upload'>
									<i className='material-symbols-outlined' style={{ color: 'var(--blue-light-5)' }}>
										no_accounts
									</i>
									<span className='virtual-background-text'>{translate('none')}</span>
								</label>
							</div>
							<div
								className={classNames(
									'add-background-image ',
									defaultBackgroundConfig?.value === VirtualBackgroundTypes.BLUR ? 'default-selected' : ''
								)}
								onClick={() => setDefaultHsBackground(VirtualBackgroundTypes.BLUR)}>
								<span className='default-image'>
									<p>{translate('setAsDefault')}</p>
								</span>
								<label htmlFor='image-upload'>
									<i className='material-icons' style={{ color: 'var(--blue-light-5)' }}>
										blur_on
									</i>
									<span className='virtual-background-text'>{translate('filterBlur')}</span>
								</label>
							</div>
							{!isBackgroundImgsLoading &&
								backGroundImages.map(item => (
									<div
										className={classNames(
											'background-item',
											defaultBackgroundConfig?.value === item.pictureUrl ? 'default-selected' : ''
										)}
										key={item.id}
										onClick={event => {
											if (
												['i', 'button'].includes(event?.target?.tagName?.toLowerCase()) ||
												item.pictureUrl === defaultBackgroundConfig?.value
											) {
												return;
											}
											setDefaultHsBackground(item.pictureUrl);
										}}>
										<>
											{defaultBackgroundConfig?.value !== item.pictureUrl && (
												<Button
													size='small'
													svgIcon={<i className='fa fa-trash'></i>}
													className='delete-img-button'
													onClick={() => setSelectedPhotoId(item.pictureUrl)}
												/>
											)}
											<span className='default-image'>
												<p>{translate('setAsDefault')}</p>
											</span>
											<img src={item.url} alt={intl.formatMessage({ id: 'backgroundImage' })} />
										</>
									</div>
								))}
							{loadingBgIds.map(item => (
								<div className='center-circle-loader empty-box-img' key={item}>
									<SpinLoader color={'#5390FF'} />
								</div>
							))}
						</div>
					</div>
				)}
			</Form>
			<Modal
				display={selectedPhotoId}
				onModalSubmit={handleDelete}
				onModalClose={() => setSelectedPhotoId(null)}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<form>
					<h3>{translate('delete')}</h3>
					<p>
						{translate('areYouSureDeleteItem', {
							value: intl.formatMessage({ id: 'backgroundImage' }),
						})}
					</p>
				</form>
			</Modal>
			<Modal
				display={showUploadLimitModal}
				onModalSubmit={() => setShowUploadLimitModal(null)}
				onModalClose={() => setShowUploadLimitModal(null)}
				position='center'
				primaryButtonLabel={translate('ok')}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{showUploadLimitModal}</p>
				</form>
			</Modal>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};

export default DigitalBackground;
