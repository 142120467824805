import { getHealthSystemSubTree } from 'api/healthSystems.js';
import { addToHealthSystem, assignMember, deleteHealthSystemMember, getNurseHealthSystems } from 'api/users.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import AssignDropdown from 'components/AssignDropdown.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Loader from 'components/Loader.jsx';
import Modal from 'components/Modal.jsx';
import SmallModal from 'components/SmallModal.jsx';
import { CompanyRoles, UserRoles } from 'constants/enums.js';
import { Field, Form, Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { findSectorById, getSectorPath } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const ManageHealthSystems = props => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const [healthSystems, setHealthSystems] = useState([]);
	const [showHealthSystemForm, setShowHealthSystemForm] = useState(false);
	const [currentHealthSystemId, setCurrentHealthSystemId] = useState(null);
	const [currentUserSubTree, setCurrentUserSubTree] = useState([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [healthSystemId, setHealthSystemId] = useState(null);
	const [healthSystemError, setHealthSystemError] = useState('');
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [fetchingSubTree, setFetchingSubTree] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		const fetchAssignations = async () => {
			const response = await getNurseHealthSystems(props.member.memberId);
			if (response.error) {
				setErrorMessage(response.error.message);
			} else {
				setHealthSystems(response);
			}
			setIsLoading(false);
		};
		fetchAssignations();
	}, [props.member.memberId]);

	const displayHealthSystems = () => {
		return healthSystems.map(hs => {
			const hideButtons = getUserRole() === UserRoles.SUPER_USER && userSession.healthSystem.id !== hs.id;
			return {
				name: hs.name,
				assignation: hideButtons ? '' : getAssignation(hs),
				id: hs.id,
				edit: hideButtons ? '' : getRemoveBtn(hs.id),
			};
		});
	};

	const getRemoveBtn = healthSystemId => (
		<div className='wrapped'>
			<i
				className='material-icons-outlined boxed-icon'
				data-cy='removeUserFromHS'
				onClick={() => openDeleteModal(healthSystemId)}>
				delete
			</i>
		</div>
	);

	const openDeleteModal = healthSystemId => {
		if (props.member.roles.some(role => role.id === CompanyRoles.SUPER_USER) && healthSystems.length > 0) {
			setHealthSystemError(intl.formatMessage({ id: 'superUserHealthSystemValidation' }));
			return;
		}
		setIsDeleteModalOpen(true);
		setHealthSystemId(healthSystemId);
	};

	const deleteHealthSystem = async () => {
		const response = await deleteHealthSystemMember(healthSystemId, props.member.memberId);
		if (response.error) {
			setErrorMessage(response.error.message);
		}

		const filteredHealthSystems = healthSystems.filter(hs => hs.id !== healthSystemId);
		props.updateMemberHealthSystems(filteredHealthSystems);
		setHealthSystems(filteredHealthSystems);
		setIsDeleteModalOpen(false);
		setHealthSystemError('');
	};

	const getAssignation = healthSystem => {
		const sector = healthSystem.location ? findSectorById(currentUserSubTree, healthSystem.location.id) : null;
		const preselected = sector ? getSectorPath(sector) : {};
		return (
			<div style={{ position: 'relative' }}>
				<div
					className={classNames('assign-btn', { unassigned: !healthSystem.location })}
					onClick={() => getTree(healthSystem.id)}>
					{healthSystem.location && healthSystem.location.name}
					{!healthSystem.location && (
						<span>
							<i className='material-icons'>how_to_reg</i>
							{translate('assignLevel')}
						</span>
					)}
				</div>
				{currentHealthSystemId === healthSystem.id && (
					<SmallModal onModalClose={() => setCurrentHealthSystemId(null)}>
						{fetchingSubTree && (
							<div>
								<Loader />
							</div>
						)}
						{!fetchingSubTree && (
							<AssignDropdown
								onSelectLocation={level => onSelectLocation(level)}
								data={currentUserSubTree}
								preSelected={preselected}
								selectedId={healthSystem.location ? healthSystem.location.id : null}
							/>
						)}
					</SmallModal>
				)}
			</div>
		);
	};

	const onSelectLocation = async level => {
		setFetchingSubTree(true);

		const userId = props.member.userInfo.id;
		const healthSystemIndex = healthSystems.findIndex(hs => hs.id === currentHealthSystemId);
		const response = await assignMember(currentHealthSystemId, level.id, userId);
		if (response.error) {
			setErrorMessage(response.error.message);
		} else {
			setHealthSystems(prevState => {
				const items = [...prevState];
				const item = {
					...items[healthSystemIndex],
					location: {
						...items[healthSystemIndex].location,
						id: level.id,
						name: level.name,
					},
				};
				items[healthSystemIndex] = item;
				return items;
			});
			setCurrentHealthSystemId(null);
			setFetchingSubTree(false);
		}
	};

	const getTree = async healthSystemId => {
		setCurrentHealthSystemId(healthSystemId);
		setFetchingSubTree(true);
		const subtree = await getHealthSystemSubTree(healthSystemId);
		if (subtree.error) {
			setErrorMessage(subtree.error.message);
			setCurrentUserSubTree([]);
		} else {
			setCurrentUserSubTree(subtree);
		}
		setFetchingSubTree(false);
	};

	const getHealthSystems = (allHealthSystems, assignedHealthSystems) => {
		const filteredHealthSystems = allHealthSystems.filter(
			hs => !assignedHealthSystems.find(assignedHs => assignedHs.id === hs.id)
		);
		if (!filteredHealthSystems.length) return [];
		return filteredHealthSystems;
	};

	const handleAddHealthSystem = () => {
		if (isLoading) {
			return;
		}
		if (props.member.roles.some(role => role.id === CompanyRoles.SUPER_USER) && healthSystems.length > 0) {
			setHealthSystemError(intl.formatMessage({ id: 'superUserRoleValidation' }));
			return;
		}
		setShowHealthSystemForm(prevState => !prevState);
	};

	const handleSelectHealthSystem = async (healthSystemId, { resetForm }) => {
		setIsLoading(true);
		const response = await addToHealthSystem(healthSystemId, props.member.memberId);
		if (response.error) {
			setErrorMessage(response.error.message);
		} else {
			const healthSystemName = props.healthSystems.find(hs => hs.id === healthSystemId).value;
			setShowHealthSystemForm(prevState => !prevState);
			setHealthSystems(prevState => [...prevState, { id: healthSystemId, name: healthSystemName }]);
			resetForm({
				healthSystem: null,
			});
			props.updateMemberHealthSystems([...healthSystems, { id: healthSystemId, name: healthSystemName }]);
		}
		setIsLoading(false);
	};

	return (
		<>
			<Modal
				modalSelector={props.modalSelector}
				display={props.display}
				className={props.className}
				position={props.position}
				onModalClose={props.onModalClose}
				primaryButtonLabel=''
				closeButtonText={intl.formatMessage({ id: 'close' })}>
				<Formik
					initialValues={{
						healthSystem: null,
					}}>
					{formikProps => {
						return (
							<Form>
								<h3>{translate('manageHealthSystems')}</h3>
								<span className='action' onClick={handleAddHealthSystem}>
									<i
										className={classNames('material-icons-outlined', isLoading ? 'disabled' : '')}
										data-cy='assignDoctorToAnotherHS'
										data-tooltip={intl.formatMessage({
											id: showHealthSystemForm ? 'hideForm' : 'addNewHealthSystem',
										})}
										data-position='right'>
										{showHealthSystemForm ? 'indeterminate_check_box' : 'add_box'}
									</i>
								</span>
								{healthSystemError && <p className='red-error'>{healthSystemError}</p>}
								{showHealthSystemForm && getHealthSystems(props.healthSystems, healthSystems).length > 0 && (
									<div style={{ paddingTop: '15px' }}>
										<Field
											onChange={event => handleSelectHealthSystem(event.target.value, formikProps)}
											name='healthSystem'
											type='select'
											label={intl.formatMessage({ id: 'enterHealthSystem' })}
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											description={intl.formatMessage({ id: 'selectHealthSystemNurse' })}
											items={getHealthSystems(props.healthSystems, healthSystems)}
											component={Select}
											disabled={props.healthSystems.length === healthSystems.length}
											value=''
										/>
									</div>
								)}
								{showHealthSystemForm && getHealthSystems(props.healthSystems, healthSystems).length === 0 && (
									<div style={{ paddingTop: '15px', paddingBottom: '15px' }}>{translate('noMoreHealthSystems')}</div>
								)}
								<CustomTable
									isLoading={isLoading}
									headers={[
										{ title: translate('healthSystem'), id: 'name' },
										{ title: translate('assignation'), id: 'assignation' },
										{ edit: '', id: 'edit' },
									]}
									rows={isLoading ? [] : displayHealthSystems()}
								/>
								<Alert display={errorMessage} fixed hideCloseButton message={errorMessage} variant='dark' />
							</Form>
						);
					}}
				</Formik>
			</Modal>
			<Modal
				modalSelector='deleteHSFromMember'
				display={isDeleteModalOpen}
				position='center'
				onModalSubmit={deleteHealthSystem}
				primaryButtonLabel={translate('delete')}
				onModalClose={() => setIsDeleteModalOpen(false)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('deleteHealthSystemMember')}</p>
				</form>
			</Modal>
		</>
	);
};

export default ManageHealthSystems;
