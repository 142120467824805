import { getNurseCallEvents, updateNurseCallEvent } from 'api/badgeAuthorization.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Form from 'components/Form.jsx';
import Grid from 'components/Grid.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getBase64 } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const BadgeAuthorization = props => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const [isLoading, setLoading] = useState(true);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, totalCount: 0 });
	const [nurseCallEvents, setNurseCallEvents] = useState([]);
	const [selectedNurseCallEvent, setSelectedNurseCallEvent] = useState(null);
	const [error, setError] = useState(null);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		props.healthSystems.length > 0 ? { value: userSession.healthSystem.id, label: userSession.healthSystem.name } : null
	);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		const fetchNurseCallEvents = async () => {
			setLoading(true);
			const response = await getNurseCallEvents({
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				healthSystemId: selectedHealthSystem?.value,
				searchParam: searchValue,
			});
			if (response.error) {
				setError(response.error.message);
				setLoading(false);
				return;
			}
			const images = response.result.data.map(item => ({ ...item, imageUrl: item?.profilePicture?.uri?.uri ?? null }));
			setNurseCallEvents(images);
			setPagination(prevState => ({ ...prevState, totalCount: response.result.pagination.totalCount }));
			setLoading(false);
		};
		fetchNurseCallEvents();
	}, [pagination.pageIndex, pagination.pageSize, selectedHealthSystem, searchValue]);

	const getTableConfigurations = () => {
		if (isLoading) {
			return [];
		}
		return nurseCallEvents.map(item => ({
			firstName: item.firstName,
			lastName: item.lastName,
			title: item.title,
			accessRFID: item.accessRFID,
			nurseCallSystemBadgeId: item.nurseCallSystemBadgeId,
			imageUrl: !item.imageUrl ? (
				<ProfilePicture fullName={`${item.firstName} ${item.lastName}`} className='provider-intervening-img-wrapper' />
			) : (
				<img
					src={item.imageUrl}
					alt={intl.formatMessage({ id: 'backgroundImage' })}
					style={{ height: '25px', width: '25px', borderRadius: '50%' }}
				/>
			),
			isActive: translate(item.isActive ? 'active' : 'inactive'),
			hasPacsAccess: translate(item.hasPacsAccess ? 'active' : 'inactive'),
			action: (
				<div className='wrapped'>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='edit'
						data-tooltip={intl.formatMessage({ id: 'edit' })}
						data-position='top'
						onClick={() => setSelectedNurseCallEvent(item)}>
						create
					</span>
				</div>
			),
		}));
	};

	const handleSubmit = async () => {
		if (isSubmitLoading) {
			return;
		}
		setIsSubmitLoading(true);
		const response = await updateNurseCallEvent(selectedNurseCallEvent);
		if (response.error) {
			setError(response.error.message);
			setIsSubmitLoading(false);
			return;
		}
		setIsSubmitLoading(false);
		const items = _.cloneDeep(nurseCallEvents);
		const foundIndex = items.findIndex(nurse => nurse.employeeId === selectedNurseCallEvent.employeeId);
		if (foundIndex === -1) {
			return;
		}
		items.splice(foundIndex, 1, selectedNurseCallEvent);
		setNurseCallEvents(items);
		setSelectedNurseCallEvent(null);
	};

	const onImageAdded = async event => {
		setError(null);
		const file = event.target.files[0];
		const url = await getBase64(file);
		setSelectedNurseCallEvent(prevState => ({
			...prevState,
			file,
			imageUrl: url,
		}));
	};

	const handleSearchChange = value => {
		setSearchValue(value);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	};

	return (
		<div>
			<CustomTable
				isLoading={isLoading}
				headers={[
					{ title: intl.formatMessage({ id: 'name' }), id: 'firstName' },
					{ title: intl.formatMessage({ id: 'lastName' }), id: 'lastName' },
					{ title: intl.formatMessage({ id: 'title' }), id: 'title' },
					{ title: intl.formatMessage({ id: 'proxBadge' }), id: 'accessRFID' },
					{ title: intl.formatMessage({ id: 'centrakBadge' }), id: 'nurseCallSystemBadgeId' },
					{ title: intl.formatMessage({ id: 'userPicture' }), id: 'imageUrl' },
					{ title: intl.formatMessage({ id: 'status' }), id: 'isActive' },
					{ title: 'PAC Status', id: 'hasPacsAccess' },
					{ title: '', id: 'action' },
				]}
				rows={getTableConfigurations()}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
						classNamePrefix='react-select'
						options={props.healthSystems.map(item => ({ value: item.id, label: item.value }))}
						onChange={item => setSelectedHealthSystem(item)}
					/>
					<Input
						validationOptions={{}}
						type='text'
						placeholder={intl.formatMessage({ id: 'search' })}
						bottomSpace='0'
						variant='filled flex-1'
						name='searchUsers'
						maxLength={100}
						value={searchValue}
						onChange={event => handleSearchChange(event.target.value)}
					/>
				</Grid>
			</CustomTable>

			<Pagination
				totalCount={pagination.totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageSize: size, pageIndex: index }))}
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			{selectedNurseCallEvent && (
				<Modal
					position='right'
					modalSelector='editSectorModal'
					display={true}
					onModalSubmit={handleSubmit}
					onModalClose={() => setSelectedNurseCallEvent(null)}
					className={classNames('assign-doctor-modal', isLoading ? 'none-pointer-events' : '')}
					shouldSubmitOnEnter={false}
					isSubmitDisabled={false}
					primaryButtonLoading={isSubmitLoading}>
					<Form
						className={isLoading ? 'disabled' : ''}
						title={`${intl.formatMessage({ id: 'edit' })} ${selectedNurseCallEvent.firstName} ${selectedNurseCallEvent.lastName}`}
						onSubmit={event => event.preventDefault()}>
						<div className='mi-input-wrapper'>
							<label>{intl.formatMessage({ id: 'proxBadge' })}</label>
							<input
								type='text'
								onChange={event => {
									if (event.target.value.length > 10) {
										return;
									}
									setSelectedNurseCallEvent(prevState => ({ ...prevState, accessRFID: event.target.value }));
								}}
								value={selectedNurseCallEvent.accessRFID}
								placeholder={intl.formatMessage({ id: 'proxBadge' })}
							/>
						</div>
						<div className='mi-input-wrapper'>
							<label>{intl.formatMessage({ id: 'centrakBadge' })}</label>
							<input
								type='text'
								onChange={event => {
									if (event.target.value.length > 10) {
										return;
									}
									setSelectedNurseCallEvent(prevState => ({ ...prevState, nurseCallSystemBadgeId: event.target.value }));
								}}
								value={selectedNurseCallEvent.nurseCallSystemBadgeId}
								placeholder={intl.formatMessage({ id: 'centrakBadge' })}
							/>
						</div>

						<div className='badge-radio-btn flex'>
							<h5>PAC {translate('status')}:</h5>
							<div>
								<input
									type='radio'
									id='radio-active'
									name='hasPacsAccess'
									onChange={() => setSelectedNurseCallEvent(prevState => ({ ...prevState, hasPacsAccess: true }))}
									checked={selectedNurseCallEvent.hasPacsAccess}
								/>
								<label htmlFor='radio-active'> {translate('active')}</label>
							</div>
							<div>
								<input
									type='radio'
									id='radio-inactive'
									name='hasPacsAccess'
									onChange={() => setSelectedNurseCallEvent(prevState => ({ ...prevState, hasPacsAccess: false }))}
									checked={!selectedNurseCallEvent.hasPacsAccess}
								/>
								<label htmlFor='radio-inactive'>{translate('inactive')}</label>
							</div>
						</div>

						<div className='badge-authorization'>
							<h5>{intl.formatMessage({ id: 'userPicture' })}</h5>
							{!selectedNurseCallEvent.imageUrl && (
								<div className='add-background-image'>
									<label htmlFor='image-upload'>
										<img src={`${healthCareCdnUrl}admin/add-image-icon.svg`} alt='icon' />
										<span className='virtual-background-text'>{translate('addImage')}</span>
									</label>
									<input
										type='file'
										accept='image/png, image/jpeg'
										id='image-upload'
										onChange={event => onImageAdded(event)}
										style={{ display: 'none' }}
									/>
								</div>
							)}
							{selectedNurseCallEvent.imageUrl && (
								<div className='background-item'>
									<>
										<img src={selectedNurseCallEvent.imageUrl} alt={intl.formatMessage({ id: 'backgroundImage' })} />
										<Button
											size='small'
											svgIcon={<i className='fa fa-trash'></i>}
											className='delete-img-button'
											type='button'
											onClick={() =>
												setSelectedNurseCallEvent(prevState => ({
													...prevState,
													file: null,
													imageUrl: '',
													fileName: '',
												}))
											}
										/>
									</>
								</div>
							)}
						</div>
					</Form>
					<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
				</Modal>
			)}
		</div>
	);
};

export default BadgeAuthorization;
