import { deleteConfiguration, getConfigs } from 'api/eManager.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Grid from 'components/Grid.jsx';
import Modal from 'components/Modal.jsx';
import { ECareConfigurationTypes } from 'constants/e-care-manager-types.js';
import { DeviceListLevel } from 'constants/enums.js';
import EpicAddEdit from 'containers/Configurations/EpicAddEdit.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getLevelDescriptionById } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const EcareManager = () => {
	const intl = useIntl();
	const [error, setError] = useState('');
	const [apiKeyList, setApiKeyList] = useState([]);
	const [itemToEdit, setItemToEdit] = useState(null);
	const [configToDelete, setConfigToDelete] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetch, setIsRefetch] = useState(null);
	const [isAddOpen, setIsAddOpen] = useState(false);

	const apiKeyHeaders = [
		{ title: intl.formatMessage({ id: 'type' }), id: 0 },
		{ title: intl.formatMessage({ id: 'scope' }), id: 1 },
		{ title: intl.formatMessage({ id: 'nodeName' }), id: 2 },
		{ title: intl.formatMessage({ id: 'nodeId' }), id: 3 },
		{ title: intl.formatMessage({ id: 'key' }), id: 4 },
		{ title: '', id: 5 },
	];

	const getApiKeyFromConfig = (obj, property) => {
		const parsed = JSON.parse(obj);
		const result = parsed ? parsed[property] : null;
		return result;
	};

	const getHealthSystemId = item => {
		if (item.nodeLevel === DeviceListLevel.HOSPITAL) {
			return item.parentNodeId;
		}
		if (item.nodeLevel === DeviceListLevel.HEALTH_SYSTEM) {
			return item.nodeId;
		}
		return null;
	};

	const handleOnClick = (item, properties) => {
		const itemToSet = {
			healthSystemId: getHealthSystemId(item),
			hospitalId: item.nodeLevel === DeviceListLevel.HOSPITAL ? item.nodeId : null,
			id: item.id,
			userId: item.userId,
			configurationTypeId: item.configurationTypeId,
		};
		const parsed = JSON.parse(properties);
		const propertiesParsed = parsed ?? {};
		Object.keys(propertiesParsed).forEach(key => {
			if (key) {
				itemToSet[key] = getApiKeyFromConfig(item.configJson, key);
			}
		});
		setItemToEdit(itemToSet);
		setIsAddOpen(true);
	};

	const transformItem = (item, property) => ({
		type: item.configurationTypeId,
		scope: intl.formatMessage({ id: getLevelDescriptionById(item.nodeLevel) }),
		nodeName: item.nodeName || 'All',
		nodeId: item.nodeId || item.tenantId,
		key: getApiKeyFromConfig(item.configJson, property),
		delete: (
			<div className='wrapped'>
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-cy='edit'
					data-tooltip={intl.formatMessage({ id: 'edit' })}
					data-position='top'
					onClick={() => handleOnClick(item, item.configJson)}>
					create
				</span>
				<i
					className='material-icons-outlined cursor-pointer'
					data-cy='deleteActiveDirectory'
					style={{ color: 'red', backgroundColor: 'white' }}
					data-tooltip={intl.formatMessage({ id: 'delete' })}
					data-position='top'
					onClick={() => setConfigToDelete(item)}>
					delete
				</i>
			</div>
		),
	});

	const getConfigTypeDescription = type => {
		let result;
		switch (type) {
			case ECareConfigurationTypes.API_KEY: {
				result = intl.formatMessage({ id: 'eCareApiKey' });
				break;
			}
			case ECareConfigurationTypes.ELERT_BASE_URL: {
				result = intl.formatMessage({ id: 'eCareBaseUrl' });
				break;
			}
			case ECareConfigurationTypes.REID: {
				result = 'Hill Room';
				break;
			}
			case ECareConfigurationTypes.EPIC: {
				result = 'Epic';
				break;
			}
			case ECareConfigurationTypes.CERNER: {
				result = 'Cerner';
				break;
			}
			default: {
				result = '';
			}
		}
		return result;
	};

	useEffect(() => {
		const fetchConfigs = async () => {
			const configsRes = await getConfigs();
			if (configsRes.error) {
				setError(configsRes.error.message);
			} else {
				const items = configsRes.result.map(item => transformItem(item, getProperty(item.configurationTypeId)));
				setApiKeyList(items);
			}
		};
		fetchConfigs();
	}, [isRefetch]);

	const getProperty = type => {
		let result;
		switch (type) {
			case ECareConfigurationTypes.ELERT_BASE_URL: {
				result = 'baseUrl';
				break;
			}
			case ECareConfigurationTypes.API_KEY: {
				result = 'apiKey';
				break;
			}
			case ECareConfigurationTypes.REID: {
				result = 'secretKey';
				break;
			}
			case ECareConfigurationTypes.EPIC:
			case ECareConfigurationTypes.CERNER: {
				result = 'clientId';
				break;
			}
			default: {
				result = '';
			}
		}
		return result;
	};

	const onDeleteConfig = async () => {
		setIsLoading(true);
		const response = await deleteConfiguration(configToDelete.id);
		if (response.error) {
			setError(response.error.message);
		}
		setConfigToDelete(null);
		setIsRefetch({ id: configToDelete.id, isDelete: true });
		setIsLoading(false);
	};

	return (
		<>
			<div className='device-config'>
				<CustomTable
					headers={apiKeyHeaders}
					rows={apiKeyList.map(item => ({ ...item, type: getConfigTypeDescription(item.type) }))}>
					<Grid columns='1fr 1fr' vertAlign='center'>
						<h3>{translate('integrations')}</h3>
						<Button
							text={intl.formatMessage({ id: 'addConfiguration' })}
							horizAlign='end'
							onClick={() => {
								setIsAddOpen(true);
								setItemToEdit(null);
							}}
						/>
					</Grid>
				</CustomTable>
				<Modal
					modalSelector='ecareModal'
					display={configToDelete}
					position='center'
					isLoading={isLoading}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					onModalSubmit={onDeleteConfig}
					onModalClose={() => setConfigToDelete(null)}
					shouldSubmitOnEnter={false}
					primaryButtonLabel={translate('yes')}>
					<form>
						<h3>{translate('deleteConfiguration')}</h3>
						<p>{translate('areYouSureToDeleteConfig')}</p>
					</form>
				</Modal>
				{isAddOpen && (
					<EpicAddEdit
						hasApiConfig={apiKeyList.some(item => item.type === ECareConfigurationTypes.API_KEY)}
						itemToEdit={itemToEdit}
						setIsAddOpen={setIsAddOpen}
						setIsRefetch={setIsRefetch}
					/>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default EcareManager;
