import { updateHelloPin } from 'api/adminConfigurations.js';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import React, { useState } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Alert.jsx';
import classNames from 'classnames';
import PinInput from 'react-pin-input';
import Form from 'components/Form.jsx';

const PinManager = props => {
	const [isDisablePinOpen, setIsDisablePinOpen] = useState(false);
	const [isChangePinOpen, setIsChangePinOpen] = useState(false);
	const [pinError, setPinError] = useState(null);
	const [error, setError] = useState(null);
	const helloPin = props.pin;
	const DEFAULT_VALUE = 1000;
	const pinString = props.hasPin.toString();
	const isPinMangerOpen = isDisablePinOpen || isChangePinOpen;

	const getHelloPin = () => {
		const pinArray = [];

		for (let i = 0; i < pinString.length; i++) {
			pinArray.push(parseInt(pinString[i], 10));
		}
		return pinArray;
	};

	const handleUpdatePin = async isDisabledPin => {
		setPinError(null);
		const response = await updateHelloPin({
			healthSystemId: props.healthSystemId,
			regionId: props.regionId,
			hospitalId: props.hospitalId,
			dataToSend: isDisabledPin ? { disablePin: true } : { helloSettingsPin: +helloPin },
		});
		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setIsPinLockOpen(false);
		props.fetchTreeData();
	};

	const handleSubmit = async () => {
		if (isDisablePinOpen) {
			handleUpdatePin(true);
			return;
		}
		setIsChangePinOpen(true);

		if (helloPin < DEFAULT_VALUE) {
			setPinError(translate('pinMinValue'));
			return;
		}
		handleUpdatePin(false);
	};

	const getPinLockText = () => {
		if (!isChangePinOpen) {
			return isDisablePinOpen ? 'disablePIN' : 'pinLock';
		}
		return 'newPinLock';
	};

	return (
		<>
			<Modal
				display={props.isPinLockOpen}
				position='right'
				primaryButtonLabel={translate(isPinMangerOpen ? 'confirm' : 'changePin')}
				background={isPinMangerOpen ? '' : '#E0AF4C'}
				isThirdButtonShown={!isPinMangerOpen}
				thirdButtonLabel={translate('disablePinBtn')}
				thirdBtnBackground='#949494'
				hideCloseButton={!isPinMangerOpen}
				hideCloseIcon={false}
				onThirdButtonClick={() => setIsDisablePinOpen(prev => !prev)}
				onModalClose={() => {
					props.setIsPinLockOpen(false);
					setIsDisablePinOpen(prevState => !prevState);
				}}
				onModalSubmit={handleSubmit}>
				<Form title={translate(isDisablePinOpen ? 'disablePinLock' : 'pinLock')} onSubmit={event => event.preventDefault()}>
					{isChangePinOpen && (
						<div className='change-pin'>
							<div className='change-pin-img' onClick={() => setIsChangePinOpen(false)}>
								<img src={`${healthCareCdnUrl}backward-arrow-dark-gray.svg`} alt='icon' />
							</div>
							<p className='pin-lock-title'>{translate('newPinLock')}</p>
						</div>
					)}
					<div className='pin-lock-wrapper'>
						<div>
							<p className={classNames(!isDisablePinOpen ? 'pin-lock-text' : 'label')}>{translate(getPinLockText())}</p>
							{!isPinMangerOpen && props.hasPin && (
								<div className='pin-lock'>
									{getHelloPin().map((pin, index) => (
										<input key={index} type='numeric' value={pin} readOnly />
									))}
								</div>
							)}

							{isChangePinOpen && (
								<>
									<PinInput
										length={4}
										onChange={value => props.setPin(value)}
										secret={false}
										type='numeric'
										inputMode='number'
										autoSelect={true}
										regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
									/>
									<p className='red-error'>{pinError}</p>
								</>
							)}
						</div>
					</div>
				</Form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default PinManager;
