import { Fragment, useState } from 'react';
import styled from 'styled-components';
import useConferenceConfigurations from 'calls/hooks/useConferenceConfigurations.js';
import { IconButton, Dropdown, List, Tooltip } from 'calls/components/index.js';
import DropdownDirection from 'calls/enums/DropdownDirection.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { getCallsButtonColor, hasNonEmptyValues } from 'infrastructure/helpers/commonHelpers.js';
import useConference from 'calls/hooks/useConference.js';
import { AdditionalData, WindowSize } from 'calls/enums/index.js';
import { useEffect } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { conference as Conference } from '@solaborate/calls';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean }, never>}
 */
const StyledPatientInformation = styled.div`
	display: flex;
	align-items: center;
	border-left: 1px solid ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayThree)};
	padding-left: var(--spacing-m);
	height: 75%;
	p {
		color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
		font-size: 14px;
		margin: 0;
		padding: 0;
	}
	button {
		padding: ${LightTheme.spacing[1]}px;
		margin-left: ${LightTheme.spacing[1]}px;
	}
	li {
		white-space: nowrap;
		padding: ${LightTheme.spacing[1]}px ${LightTheme.spacing[2]}px;
		color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)}; !important
	}
	@media (max-width: ${WindowSize.TABLET}px) {
		p {
			color: ${LightTheme.colors.grayTwo};
		}
	}
`;

const PatientInformation = () => {
	const conferenceConfigurations = useConferenceConfigurations();
	const [isShowingAllPatientInfo, setIsShowingAllPatientInfo] = useState(false);
	const [patientInfo, setPatientInfo] = useState({ patientName: '', room: '', mrn: '', dateOfBirth: '' }); 
	const conference = useConference();

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.AdditionalDataUpdated) {
				const info = conference?.additionalData?.find(item => item.key === AdditionalData.PATIENT_INFO);
				setPatientInfo(info ? info.value : {});
			}
		});
	}, [conference]);

	const filteredObj = obj =>
		Object.entries(obj).reduce((acc, [key, value]) => {
			if (value !== '' && value !== null && value !== undefined) {
				acc[key] = value;
			}
			return acc;
		}, {});

	return (
		hasNonEmptyValues(patientInfo) && (
			<StyledPatientInformation $isDarkMode={conferenceConfigurations.isDarkMode}>
				<>
					<div>
						{Object.keys(filteredObj(patientInfo)).map((key, index) => (
							<Fragment key={key}>
								{index < 2 && (
									<p>
										{translate(key)}: {patientInfo[key]}
									</p>
								)}
							</Fragment>
						))}
					</div>

					{Object.keys(filteredObj(patientInfo)).length > 2 && (
						<div>
							<Tooltip text={!isShowingAllPatientInfo && 'Show all patient info'}>
								<Dropdown
									offset={LightTheme.spacing[3]}
									isOpen={isShowingAllPatientInfo}
									direction={DropdownDirection.TOP_RIGHT}
									onClickOutside={() => setIsShowingAllPatientInfo(false)}>
									<Dropdown.Button>
										<IconButton
											icon='expand_less'
											background={isShowingAllPatientInfo ? LightTheme.colors.blueFive : null}
											color={
												isShowingAllPatientInfo
													? LightTheme.colors.blueFour
													: getCallsButtonColor(conferenceConfigurations.isDarkMode)
											}
											borderColor={isShowingAllPatientInfo ? LightTheme.colors.blueOne05 : LightTheme.colors.grayTen02}
											onClick={evt => {
												evt.stopPropagation();
												setIsShowingAllPatientInfo(prevState => !prevState);
											}}
										/>
									</Dropdown.Button>
									<Dropdown.Items>
										<List>
											{Object.keys(filteredObj(patientInfo)).map(key => (
												<Fragment key={key}>
													<li>
														{translate(key)}: {patientInfo[key]}
													</li>
												</Fragment>
											))}
										</List>
									</Dropdown.Items>
								</Dropdown>
							</Tooltip>
						</div>
					)}
				</>
			</StyledPatientInformation>
		)
	);
};

export default PatientInformation;
