import { MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { vsmImageUrl } from 'constants/rpm.js';

export const measurements = [
	{ id: 0, measurementType: 'temperature', name: 'temperature', value: 79.3, device: 'thermometer', date: '2024-05-28T00:00:00' },
	{ id: 1, measurementType: 'oxygen', name: 'oxygen', value: 79.3, device: 'pulseOximeter', date: '2024-05-28T00:00:00' },
	{ id: 2, measurementType: 'heartRate', name: 'heartRate', value: 79.3, device: 'pulseOximeter', date: '2024-05-28T00:00:00' },
	{
		id: 3,
		measurementType: 'bloodPressure',
		name: 'bloodPressure',
		value: '121/93',
		device: 'bloodPressure',
		date: '2024-04-28T00:00:00',
	},
	{ id: 4, measurementType: 'weight', name: 'weight', value: '90', device: 'scale', date: '2024-05-26T05:00:00' },
	{ id: 5, measurementType: 'diabetes', name: 'bloodGlucose', value: '32', device: 'bloodGlucose', date: '2024-01-28T05:00:00' },
];

export const averages = {
	data: [
		{
			hour: 7,
			count: 30,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 7,
			count: 66,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 7,
			count: 10,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 8,
			count: 92,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 8,
			count: 187,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 8,
			count: 24,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 20,
			count: 100,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 8,
			count: 24,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 15,
			count: 80,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 4,
			count: 9,
			measurementType: MeasurementTypes.OXYGEN,
		},
		{
			hour: 9,
			count: 2,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 9,
			count: 185,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 9,
			count: 18,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 10,
			count: 1,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 10,
			count: 6,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 10,
			count: 2,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 11,
			count: 145,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 11,
			count: 2,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 10,
			count: 6,
			measurementType: MeasurementTypes.TEMPERATURE,
		},
		{
			hour: 10,
			count: 2,
			measurementType: MeasurementTypes.TEMPERATURE,
		},
		{
			hour: 11,
			count: 145,
			measurementType: MeasurementTypes.TEMPERATURE,
		},
		{
			hour: 11,
			count: 2,
			measurementType: MeasurementTypes.HEART_RATE,
		},
		{
			hour: 19,
			count: 10,
			measurementType: MeasurementTypes.DIABETES,
		},
		{
			hour: 11,
			count: 2,
			measurementType: MeasurementTypes.BODY_MASS_INDEX,
		},
	],
};

export const telemetry = [
	{
		id: 0,
		measurements: [
			{ type: 'heartRate', value: '65 bpm' },
			{ type: 'respiratoryRate', value: '21 brpm' },
			{ type: 'oxygen', value: '97 %' },
			{ type: 'bloodPressure', value: '117/30 mmhg' },
			{ type: 'temperature', value: '95 °F' },
			{ type: 'good', value: 'good' },
		],
	},
	{
		id: 1,
		measurements: [
			{ type: 'heartRate', value: '57 bpm' },
			{ type: 'respiratoryRate', value: '30 brpm' },
			{ type: 'oxygen', value: '99 %' },
			{ type: 'bloodPressure', value: '130/50 mmhg' },
			{ type: 'temperature', value: '80 °F' },
			{ type: 'good', value: 'good' },
		],
	},
	{
		id: 2,
		measurements: [
			{ type: 'heartRate', value: '71 bpm' },
			{ type: 'respiratoryRate', value: '27 brpm' },
			{ type: 'oxygen', value: '95 %' },
			{ type: 'bloodPressure', value: '140/90 mmhg' },
			{ type: 'temperature', value: '78 °F' },
			{ type: 'good', value: 'good' },
		],
	},
	{
		id: 3,
		measurements: [
			{ type: 'heartRate', value: '71 bpm' },
			{ type: 'respiratoryRate', value: '27 brpm' },
			{ type: 'oxygen', value: '95 %' },
			{ type: 'bloodPressure', value: '140/90 mmhg' },
			{ type: 'temperature', value: '78 °F' },
			{ type: 'good', value: 'good' },
		],
	},
	{
		id: 4,
		measurements: [
			{ type: 'heartRate', value: '68 bpm' },
			{ type: 'respiratoryRate', value: '29 brpm' },
			{ type: 'oxygen', value: '96 %' },
			{ type: 'bloodPressure', value: '120/90 mmhg' },
			{ type: 'temperature', value: '79 °F' },
			{ type: 'good', value: 'good' },
		],
	},
	{
		id: 5,
		measurements: [
			{ type: 'heartRate', value: '77 bpm' },
			{ type: 'respiratoryRate', value: '30 brpm' },
			{ type: 'oxygen', value: '95 %' },
			{ type: 'bloodPressure', value: '140/90 mmhg' },
			{ type: 'temperature', value: '78 °F' },
			{ type: 'good', value: 'good' },
		],
	},
	{
		id: 6,
		measurements: [
			{ type: 'heartRate', value: '79 bpm' },
			{ type: 'respiratoryRate', value: '32 brpm' },
			{ type: 'oxygen', value: '99 %' },
			{ type: 'bloodPressure', value: '129/80 mmhg' },
			{ type: 'temperature', value: '79 °F' },
			{ type: 'good', value: 'good' },
		],
	},
];

export const TelemetryIcons = [
	{
		id: 0,
		type: MeasurementTypes.BLOOD_PRESSURE,
		icon: `${vsmImageUrl}blood-pressure.svg`,
	},
	{
		id: 1,
		type: MeasurementTypes.HEART_RATE,
		icon: `${vsmImageUrl}heart-rate.svg`,
	},
	{
		id: 2,
		type: MeasurementTypes.OXYGEN,
		icon: `${vsmImageUrl}oxygen-saturation.svg`,
	},
	{
		id: 3,
		type: MeasurementTypes.TEMPERATURE,
		icon: `${vsmImageUrl}thermometer.svg`,
	},
	{
		id: 4,
		type: MeasurementTypes.RESPIRATORY_RATE,
		icon: `${healthCareCdnUrl}health-data/respiratory.svg`,
	},
	{
		id: 5,
		type: 'good',
		icon: `${vsmImageUrl}good.svg`,
	},
];

export const HospitalAtHomeTabs = [
	'overview',
	'schedule',
	'medication',
	'alerts',
	'recentMetrics',
	'telemetry',
	'careTeam',
	'chat',
];

export const measurementTypes = [
	MeasurementTypes.BLOOD_PRESSURE,
	MeasurementTypes.HEART_RATE,
	MeasurementTypes.OXYGEN,
	MeasurementTypes.DIABETES,
	MeasurementTypes.TEMPERATURE,
];

export const hospitalAtHomeColumns = [
	'patient',
	'schedule',
	MeasurementTypes.BLOOD_PRESSURE,
	MeasurementTypes.HEART_RATE,
	MeasurementTypes.OXYGEN,
	MeasurementTypes.DIABETES,
	MeasurementTypes.TEMPERATURE,
];

export const HospitalAtHomeTabsEnum = {
	OVERVIEW: 0,
	SCHEDULE: 1,
	MEDICATION: 2,
	ALERTS: 3,
	RECENT_METRICS: 4,
	TELEMETRY: 5,
	CARE_TEAM: 6,
	CHAT: 7,
};
