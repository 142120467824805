import React, { useState, useEffect, useRef, Fragment } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ConfigSettingType, SelectTypes, MonitoringSettings } from 'constants/configurationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import {
	updateRoomSettings,
	updateTeamSettings,
	getRoomsBySectorId,
	getTeamSettings,
	getRoomSettings,
	deleteHealthSystemRoleConfigs,
	setHealthSystemRoleConfigs,
	getHealthSystemRoleConfigs,
} from 'api/adminConfigurations.js';
import { DeviceListLevel } from 'constants/enums.js';
import { EmptyState, Modal } from 'components/index.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import {
	reorderObjects,
	getConfigurationValue,
	getUserRoleId,
	getSomeConfigurationsValues,
} from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import VerbalRedirectionsAdmin from 'components/VerbalRedirectionsAdmin.jsx';

const FeatureFlags = props => {
	const intl = useIntl();
	const disabledToggleTimeouts = useRef({});
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const cameraNames = useSelector(state => state.company.companySettings);
	const [roomsToBeOverwritten, setRoomsToBeOverwritten] = useState([]);
	const [selectedConfig, setSelectedConfig] = useState(null);
	const [adminConfigurations, setAdminConfigurations] = useState({});
	const [isLoading, setLoading] = useState(true);
	const [isEmptyState, setIsEmptyState] = useState(false);

	useEffect(() => {
		setLoading(true);
		const fetchSectorSettings = async () => {
			const response =
				props.currentSector !== DeviceListLevel.ROOM
					? await getTeamSettings({
							teamId: props.levelId,
							levelId: props.currentSector,
							settingsCategory: [props.settingCategory],
						})
					: await getRoomSettings(props.levelId, props.settingCategory);

			if (response.error) {
				props.setError(response.error.message);
			} else {
				const featureFlagConfigs = {};
				response.settings.forEach(item => {
					const config = props.defaultConfigurations[item.settingTypeId];
					if (config) {
						config.value = JSON.parse(item.value);
						config.isLocked = item.isLocked;
						if (item.variant && config.options) {
							config.variant = config.options.find(opt => opt.value === item.variant);
						}
						featureFlagConfigs[item.settingTypeId] = config;
					}
				});
				if (props.selectedRole) {
					const roleConfigsResponse = await getHealthSystemRoleConfigs(
						props.selectedHealthSystem.value,
						getUserRoleId(props.selectedRole.value)
					);
					if (roleConfigsResponse.error) {
						props.setError(roleConfigsResponse.error.message);
					} else {
						Object.keys(featureFlagConfigs).forEach(key => {
							const roleConfig = roleConfigsResponse.settings.find(config => config.settingTypeId === +key);
							featureFlagConfigs[key].value = !!roleConfig;
							if (roleConfig?.variant && featureFlagConfigs[key].options) {
								featureFlagConfigs[key].variant = featureFlagConfigs[key].options.find(item => item.value === roleConfig.variant);
							}
						});
					}
				}

				setAdminConfigurations(featureFlagConfigs);
				setIsEmptyState(Object.keys(featureFlagConfigs).length === 0);
			}
			setLoading(false);
		};

		fetchSectorSettings();

		return () => {
			Object.values(disabledToggleTimeouts.current).forEach(timeout => {
				clearTimeout(timeout);
			});
		};
	}, [props.levelId, props.settingCategory, props.selectedRole]);

	const updateConfigState = (key, settingType, variant) => {
		setAdminConfigurations(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].value = settingType === ConfigSettingType.TOGGLE ? !configsCopied[key].value : configsCopied[key].value;
			configsCopied[key].isLocked =
				settingType === ConfigSettingType.LOCK ? !configsCopied[key].isLocked : configsCopied[key].isLocked;
			if (variant) {
				configsCopied[key].variant = configsCopied[key].options.find(opt => opt.value === variant);
			}

			const shouldTurnOffDependentItems =
				configsCopied[key].otherConfigsToDisable && configsCopied[key].value && settingType === ConfigSettingType.TOGGLE;

			if (shouldTurnOffDependentItems) {
				configsCopied[key].otherConfigsToDisable.forEach(keyToDisable => {
					if (configsCopied[keyToDisable]) {
						configsCopied[keyToDisable].value = false;
						configsCopied[keyToDisable].isLocked = false;
					}
				});
			}

			return configsCopied;
		});
	};

	const updateRoomConfiguration = async (setting, settingType) => {
		const response = await updateRoomSettings(props.levelId, [setting]);
		const key = setting.settingTypeId;
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType, setting.variant);
		}
	};

	const updateTeamsConfigurations = async ({ isOverride, settingType, key, variant }) => {
		const item = adminConfigurations[key];

		const teamSettings = [
			{
				settingTypeId: key,
				value: settingType === ConfigSettingType.TOGGLE ? (!item.value).toString() : item.value.toString(),
				isLocked: settingType === ConfigSettingType.LOCK ? !item.isLocked : item.isLocked,
				...(item.variant ? { variant: variant || item.variant?.value } : {}),
			},
		];

		const shouldTurnOffDependentItems =
			settingType === ConfigSettingType.TOGGLE &&
			adminConfigurations[key]?.value &&
			adminConfigurations[key]?.otherConfigsToDisable;

		if (shouldTurnOffDependentItems) {
			adminConfigurations[key].otherConfigsToDisable.forEach(item => {
				teamSettings.push({
					settingTypeId: item,
					value: 'false',
					isLocked: false,
				});
			});
		}

		const setting = {
			teamSettings,
			teamId: props.levelId,
			isOverride,
			healthSystemId: props.healthSystemId,
		};

		const response = await updateTeamSettings(setting);
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType, setting.teamSettings[0].variant);
		}
		setSelectedConfig(null);
	};

	const toggleHealthSystemTeamItem = (settingType, key, variant = null) => {
		updateTeamsConfigurations({ isOverride: false, settingType, key, variant });
	};

	const toggleRoleConfigsItem = async (settingType, key, configVariant = null) => {
		let variant = configVariant;
		const roleId = getUserRoleId(props.selectedRole.value);
		const isAddOrUpdate =
			!getConfigurationValue(adminConfigurations[+key]) || (getConfigurationValue(adminConfigurations[+key]) && variant);
		if (adminConfigurations[+key] && adminConfigurations[+key].options && !variant) {
			variant = adminConfigurations[+key].options[0].value;
		}
		const setting = [
			{
				roleId,
				settingTypeId: +key,
				...(variant && {
					variant,
				}),
			},
		];
		const response = isAddOrUpdate
			? await setHealthSystemRoleConfigs(props.healthSystemId, setting)
			: await deleteHealthSystemRoleConfigs(props.healthSystemId, roleId, +key);
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType, variant);
		}
	};

	const toggleTeamsConfigsItem = async (settingType, key) => {
		const response = await getRoomsBySectorId(props.levelId, key, adminConfigurations[key].value.toString());
		if (response.error) {
			props.setError(response.error.message);
			return;
		}
		if (response.rooms.length > 0) {
			setRoomsToBeOverwritten(response.rooms);
			setSelectedConfig(key);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType, key, variant: null });
		}
	};

	const toggleRoomConfigsItem = (settingType, key) => {
		const item = adminConfigurations[key];
		const setting = {
			settingTypeId: key,
			value: (!item.value).toString(),
			isLocked: item.isLocked,
			...(item.variant ? { variant: item.variant?.value } : {}),
		};

		updateRoomConfiguration(setting, settingType);
	};

	const toggleItem = key => {
		const settingType = ConfigSettingType.TOGGLE;

		clearTimeout(disabledToggleTimeouts.current[key]);

		setAdminConfigurations(prevState => {
			const updatedConfig = {
				...prevState[key],
				isDisabled: true,
			};

			return {
				...prevState,
				[key]: updatedConfig,
			};
		});

		disabledToggleTimeouts.current[key] = setTimeout(() => {
			setAdminConfigurations(prevState => {
				const updatedConfig = {
					...prevState[key],
					isDisabled: false,
				};

				return {
					...prevState,
					[key]: updatedConfig,
				};
			});
		}, 1000);
		if (props.currentSector === DeviceListLevel.HEALTH_SYSTEM && !props.selectedRole) {
			toggleHealthSystemTeamItem(settingType, key);
			return;
		}

		if (props.selectedRole) {
			toggleRoleConfigsItem(settingType, key);
			return;
		}

		if (props.currentSector !== DeviceListLevel.ROOM) {
			toggleTeamsConfigsItem(settingType, key);
		} else {
			toggleRoomConfigsItem(settingType, key);
		}
	};

	const customizeConfig = (key, variant) => {
		const item = adminConfigurations[key];

		if (item.type === SelectTypes.ROLE_SETTINGS) {
			toggleRoleConfigsItem(ConfigSettingType.SELECT, key, variant);
			return;
		}

		if (props.currentSector === DeviceListLevel.ROOM) {
			const setting = { settingTypeId: key, value: item.value.toString(), isLocked: item.isLocked, variant };
			updateRoomConfiguration(setting);
		} else {
			updateTeamsConfigurations({
				isOverride: true,
				settingType: ConfigSettingType.SELECT,
				key,
				variant,
			});
		}
	};

	const toggleLock = async key => {
		const item = adminConfigurations[key];
		const setting = {
			settingTypeId: key,
			value: item.value.toString(),
			isLocked: !item.isLocked,
			...(item.variant ? { variant: item.variant?.value } : {}),
		};

		if (props.currentSector === DeviceListLevel.ROOM) {
			updateRoomConfiguration(setting, ConfigSettingType.LOCK);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType: ConfigSettingType.LOCK, key, variant: null });
		}
	};

	const transformArray = array => array.map(item => ({ value: item.value, label: intl.formatMessage({ id: item.label }) }));

	const isLockEditable = () =>
		[DeviceListLevel.ROOM, DeviceListLevel.FLOOR, DeviceListLevel.DEPARTMENT, DeviceListLevel.HOSPITAL].includes(
			props.currentSector
		);

	const groupedCategories = Object.entries(adminConfigurations).reduce((acc, [key, item]) => {
		const { category } = item;
		if (!acc[category]) {
			acc[category] = [];
		}

		const displayItem = !item.dependentFrom || adminConfigurations[item.dependentFrom]?.value;

		if (displayItem) {
			acc[category].push([key, item]);
		}

		return acc;
	}, []);

	const featureFlagsCategories = props.categoryOrder ? reorderObjects(groupedCategories, props.categoryOrder) : groupedCategories;

	const showDescriptionForChildConfigs = item =>
		item.value && item.dependentConfigsTitle && getSomeConfigurationsValues(adminConfigurations, item.otherConfigsToDisable);

	return (
		<>
			<div className='feature-flags'>
				{props.currentSector === DeviceListLevel.HEALTH_SYSTEM && (
					<div className='feature-flags-header'>
						<div>
							<h4>{translate('selectHealthSystem')}</h4>
							<Select
								value={props.selectedHealthSystem}
								classNamePrefix='react-select'
								options={allHealthSystems.map(item => ({ value: item.id, label: item.name || item.value }))}
								onChange={val => props.setSelectedHealthSystem(val)}
							/>
						</div>
						{props.selectedRole && (
							<div>
								<h4>{translate('selectRole')}</h4>
								<Select
									value={props.selectedRole}
									classNamePrefix='react-select'
									options={props.roleList}
									onChange={props.setSelectedRole}
								/>
							</div>
						)}
					</div>
				)}
				{!isLoading && (
					<>
						{Object.keys(featureFlagsCategories).map(category => (
							<div className='feature-flags-category' key={category}>
								{groupedCategories[category].length > 0 && props.categoryOrder && <h4>{translate(category)}</h4>}
								{groupedCategories[category]?.map(([key, item]) => (
									<Fragment key={key}>
										<div className={classNames('feature-flag flex', { 'left-30': item.dependentFrom })}>
											<div className='toggle-config'>
												{isLockEditable() && (
													<i
														className='material-icons cursor-pointer right-s opacity-transformation'
														onClick={() => toggleLock(+key)}>
														{item.isLocked ? 'lock' : 'lock_open'}
													</i>
												)}
												<div
													className={classNames('rounded-slider-switch', item.isDisabled ? 'disabled' : '')}
													onClick={() => toggleItem(+key)}>
													<input type='checkbox' checked={item.value} onChange={() => null} />
													<span className='rounded-slider' />
												</div>
												<p>{item.value ? translate('on') : translate('off')}</p>
											</div>
											<div className='feature-description'>
												<p className='flex-1'>{translate(item.title)}</p>
												<p>
													{translate(item.description, {
														value: translate(props.selectedRole?.value?.toLowerCase()) ?? 'user',
														huddleName: cameraNames.huddleName,
														helloName: cameraNames.helloName,
													})}
												</p>

												{item.options && item.value && !item.hasButton && (
													<div className='feature-flag-options flex'>
														<p>{translate('customize')}</p>
														<Select
															value={transformArray([item.variant])}
															classNamePrefix='react-select'
															options={transformArray(item.options)}
															onChange={event => customizeConfig(key, event.value)}
														/>
													</div>
												)}

												{+key === MonitoringSettings.VerbalRedirection &&
													item.value &&
													props.currentSector === DeviceListLevel.HEALTH_SYSTEM && (
														<VerbalRedirectionsAdmin healthSystemId={props.healthSystemId} setError={props.setError} />
													)}
											</div>
										</div>
										{showDescriptionForChildConfigs(item) && (
											<p className='feature-dependent-title'>{translate(item.dependentConfigsTitle)}</p>
										)}
									</Fragment>
								))}
							</div>
						))}
					</>
				)}
				{!isLoading && isEmptyState && <EmptyState title={translate('pleaseReachAdmin')} image='no-files.svg' />}
				{isLoading && <SkeletonLoader rows={10} padding='35px 20px' />}
			</div>
			<Modal
				position='center'
				modalSelector='confirmOverwritingAdminConfigs'
				onModalClose={() => setSelectedConfig(null)}
				className='confirm-overwrite-configs'
				display={selectedConfig}
				primaryButtonLabel={translate('applyToAll')}
				onModalSubmit={() =>
					updateTeamsConfigurations({
						isOverride: true,
						settingType: ConfigSettingType.TOGGLE,
						key: selectedConfig,
						variant: null,
					})
				}
				isThirdButtonShown
				onThirdButtonClick={() =>
					updateTeamsConfigurations({
						isOverride: false,
						settingType: ConfigSettingType.TOGGLE,
						key: selectedConfig,
						variant: null,
					})
				}
				thirdButtonLabel={translate('exclude')}>
				<div className='overwrite-configs-content'>
					<h2>
						{translate('warning')} - {translate('featureOverwrite')}
					</h2>
					<p>{translate('waringOverwriteDescription')}</p>
					<h4>{translate('roomsWithDifferentConfigs')}:</h4>
					<div className='rooms-list flex'>
						{roomsToBeOverwritten.map(item => (
							<div key={item.room.id}>
								<img className='device-owner-ico' src={`${healthCareCdnUrl}treeview/Room.svg`} alt='ico' />
								<span>{item.room.name}</span>
							</div>
						))}
					</div>
					<p>{translate('bySavingThisDescription')}</p>
				</div>
			</Modal>
		</>
	);
};

export default FeatureFlags;
