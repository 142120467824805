import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useIntl } from 'react-intl';
import _ from 'lodash'; 
import translate from 'i18n-translations/translate.jsx';
import { getUserInfo, getUserId, loadUserInfo } from 'infrastructure/auth.js';
import {
	DoctorProfileInfoTabs,
	AcademicDegrees,
	Address,
	Education,
	Employment,
	License,
	AlertTypes,
	Country,
	HealthcareErrorCode,
	Gender,
} from 'constants/enums.js';
import { getCountries, checkUserEmailExists, getUserAddress } from 'api/users.js';
import { getSpecialties, getStates } from 'api/doctorOnBoarding.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import { dataURLtoFile, isCanvasEmpty } from 'infrastructure/helpers/commonHelpers.js';
import PersonalInformation from 'components/ProfileInformation/PersonalInformation.jsx';
import LocationAndAddress from 'components/ProfileInformation/LocationAndAddress.jsx';
import EmploymentHistory from 'components/Doctors/EmploymentHistory.jsx';
import EducationHistory from 'components/Doctors/EducationHistory.jsx';
import StampAndSignature from 'containers/StampAndSignature.jsx';
import Licenses from 'components/Doctors/Licenses.jsx';
import {
	getBlobSasPicture,
	uploadStampAndSignature,
	uploadStampOrSignatureImage,
	getDoctorProfile,
	getLanguages,
	updateDoctorProfile,
} from 'api/doctors.js';
import Alert from 'components/Alert.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import Button from 'components/Button.jsx';

const DoctorProfileInfo = () => {
	const isDarkMode = useSelector(state => state.user.darkMode);
	const [specialties, setSpecialties] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [countries, setCountries] = useState([]);
	const [tabsExpanded, setTabsExpanded] = useState([
		{ id: 0, isExpanded: false },
		{ id: 1, isExpanded: false },
		{ id: 2, isExpanded: false },
		{ id: 3, isExpanded: false },
		{ id: 4, isExpanded: false },
		{ id: 5, isExpanded: false },
	]);
	const [signatureRef, setSignatureRef] = useState(null);
	const [currentSignature, setCurrentSignature] = useState('');
	const [stamp, setStamp] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [doctorProfile, setDoctorProfile] = useState(null);
	const [changesSaved, setChangesSaved] = useState(null);
	const [signatureFormProfile, setSignatureFromProfile] = useState(null);
	const [stampFromProfile, setStampFromProfile] = useState(null);
	const [states, setStates] = useState([]);
	const [userAddress, setUserAddress] = useState(null);

	const userInfo = getUserInfo();
	const intl = useIntl();

	useEffect(() => {
		const getProfileInformationDetails = async () => {
			const [countriesList, doctorDetails, specialtiesList, languagesList, statesList, addressResponse] = await Promise.all([
				getCountries(),
				getDoctorProfile(getUserId()),
				getSpecialties(),
				getLanguages(),
				getStates(),
				getUserAddress(getUserId()),
			]);
			const languagedMapped = getLanguagesMapped(languagesList.languages, doctorDetails.doctorProfile.userLanguages);
			const responseError =
				countriesList.error ||
				doctorDetails.error ||
				specialtiesList.error ||
				languagesList.error ||
				statesList.error ||
				addressResponse.error;
			if (responseError) {
				setError(responseError.message);
				setIsLoading(false);
				return;
			}
			if (doctorDetails.doctorProfile?.signaturePicture) {
				const blobSignature = await getBlobSasPicture(doctorDetails.doctorProfile?.signaturePicture, 'doctor-files');
				setSignatureFromProfile(doctorDetails.doctorProfile.signaturePicture);
				if (blobSignature.error || blobSignature.errorResponse) {
					setError(blobSignature.error || blobSignature.errorResponse.message);
				} else {
					setCurrentSignature(blobSignature.result.uri.uri);
				}
			}
			if (doctorDetails.doctorProfile?.stampPicture) {
				const blobStamp = await getBlobSasPicture(doctorDetails.doctorProfile?.stampPicture, 'doctor-files');
				setStampFromProfile(doctorDetails.doctorProfile.stampPicture);
				if (blobStamp.error || blobStamp.errorResponse) {
					setError(blobStamp.error || blobStamp.errorResponse.message);
				} else {
					setStamp(blobStamp.result.uri.uri);
				}
			}
			setCountries(countriesList.countries);
			setDoctorProfile(doctorDetails.doctorProfile);
			setSpecialties(specialtiesList.specialties);
			setLanguages(languagedMapped);
			setStates(statesList.states);
			setUserAddress(addressResponse.address);
			setIsLoading(false);
		};
		getProfileInformationDetails();
	}, []);

	useEffect(() => {
		let timeout;
		if (changesSaved) {
			timeout = setTimeout(() => {
				setChangesSaved('');
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [changesSaved]);

	const getLanguagesMapped = (languageList, doctorLanguages) =>
		languageList.map(language => (doctorLanguages.includes(language.name) ? { ...language, checked: true } : language));

	const getInitialValues = () => {
		if (doctorProfile) {
			return {
				firstName: doctorProfile.firstName,
				lastName: doctorProfile.lastName,
				idNumber: doctorProfile.idCard,
				email: doctorProfile.email,
				genderId: doctorProfile.genderId.toString(),
				profilePicture: doctorProfile.profilePicture,
				specialty: doctorProfile.specialty?.id,
				dateOfBirth: doctorProfile.dateOfBirth ? moment(doctorProfile.dateOfBirth).format('YYYY-MM-DD') : null,
				phoneNumber: doctorProfile.phoneNr,
				country1: userAddress?.countryId,
				addressStateId: userAddress?.stateId,
				city: userAddress?.city,
				zipCode: userAddress?.zipCode,
				address: userAddress?.address1,
				employmentHistory: doctorProfile.doctorExperiences.length
					? doctorProfile.doctorExperiences.map(
							({ employmentCompany, stateId, position, startDate, endDate, isCurrentPosition, city }) => ({
								company: employmentCompany,
								employmentStateId: stateId,
								position,
								startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
								endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
								currentlyWorking: isCurrentPosition || false,
								country4: countries.find(item => item.name === city)?.id,
							})
						)
					: [{ currentlyWorking: false }],
				educationHistory: doctorProfile.userEducations.length
					? doctorProfile.userEducations.map(({ school, educationTypeId, stateId, startDate, endDate, country }) => ({
							school,
							degree: educationTypeId,
							educationStateId: stateId,
							start: startDate && moment(startDate).format('YYYY-MM-DD'),
							end: endDate && moment(endDate).format('YYYY-MM-DD'),
							country3: countries.find(item => item.name === country)?.id,
							currentlyStudying: !endDate,
						}))
					: [{ currentlyStudying: false }],
				licenseHistory: doctorProfile.doctorStateLicences.length
					? doctorProfile.doctorStateLicences.map(({ countryId, stateId, licenceNr }) => ({
							country2: countryId,
							licenseStateId: stateId,
							licenseNo: licenceNr,
						}))
					: [{}],
			};
		}
		return null;
	};

	const profileInfoTabs = [
		{
			id: DoctorProfileInfoTabs.PERSONAL_INFO,
			title: intl.formatMessage({ id: 'personalInformation' }),
		},
		{
			id: DoctorProfileInfoTabs.LOCATION,
			title: intl.formatMessage({ id: 'locationAndAddress' }),
		},
		{
			id: DoctorProfileInfoTabs.SIGNATURE,
			title: intl.formatMessage({ id: 'signatureAndStamp' }),
		},
		{
			id: DoctorProfileInfoTabs.LICENSES,
			title: intl.formatMessage({ id: 'licenses' }),
		},
		{
			id: DoctorProfileInfoTabs.EMPLOYMENT,
			title: intl.formatMessage({ id: 'employment' }),
		},
		{
			id: DoctorProfileInfoTabs.EDUCATION,
			title: intl.formatMessage({ id: 'education' }),
		},
	];

	const getSelectedTab = type => {
		return tabsExpanded.find(x => x.id === type).isExpanded;
	};

	const toggleProfileInfoTabs = id => {
		const expandedTabs = [...tabsExpanded];
		const expandedTab = expandedTabs.find(tab => tab.id === id);
		expandedTab.isExpanded = !expandedTab.isExpanded;
		setTabsExpanded(expandedTabs);
	};

	const validateEmail = async val => {
		const userProfile = getUserInfo();
		if (!val || val === userProfile?.email) {
			return true;
		}
		let emailExists = false;
		const schema = Yup.string().email().required();
		if (await schema.isValid(val)) {
			const response = await checkUserEmailExists(val);
			if (response.error) {
				setError(response.error.message);
				emailExists = false;
			} else {
				emailExists = response;
			}
		}
		return !emailExists;
	};

	const validateForm = () => {
		return Yup.object().shape(
			{
				firstName: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteFirstName' }))
					.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
					.trim(),
				lastName: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
					.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
					.trim(),
				email: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteEmail' }))
					.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
					.email(intl.formatMessage({ id: 'invalidEmail' }))
					.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val))
					.trim(),
				specialty: Yup.string(),
				idNumber: Yup.number(),
				dateOfBirth: Yup.string()
					.test('dateOfBirth', intl.formatMessage({ id: 'greaterThanEighteen' }), value => {
						return !value || moment().diff(moment(value), 'years') >= 18;
					})
					.nullable(),
				country1: Yup.string().when([Address.CITY, Address.ZIP_CODE, Address.ADDRESS_1], {
					is: (city, zipCode, address) => city || zipCode || address,
					then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
				}),
				addressStateId: Yup.string().when([Address.COUNTRY, Address.ADDRESS_1, Address.CITY, Address.ZIP_CODE], {
					is: (country1, address, zipCode, city) => country1 === Country.USA.toString() && (address || zipCode || city),
					then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
				}),
				city: Yup.string().when([Address.COUNTRY, Address.ZIP_CODE, Address.ADDRESS_1], {
					is: (country1, zipCode, address) => country1 || zipCode || address,
					then: () =>
						Yup.string()
							.required(intl.formatMessage({ id: 'pleaseWriteCity' }))
							.trim(),
				}),
				zipCode: Yup.string().when([Address.COUNTRY, Address.CITY, Address.ADDRESS_1], {
					is: (country1, city, address) => country1 || city || address,
					then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseWriteZipCode' })),
				}),
				address: Yup.string().when([Address.COUNTRY, Address.CITY, Address.ZIP_CODE], {
					is: (country1, city, zipCode) => country1 || city || zipCode,
					then: () =>
						Yup.string()
							.required(intl.formatMessage({ id: 'pleaseWriteAddress' }))
							.trim(),
				}),
				employmentHistory: Yup.array().of(
					Yup.object().shape(
						{
							company: Yup.string().when([Employment.POSITION, Employment.COUNTRY, Employment.START_DATE, Employment.END_DATE], {
								is: (position, startDate, endDate) => position || startDate || endDate,
								then: () =>
									Yup.string()
										.required(intl.formatMessage({ id: 'pleaseWriteCompany' }))
										.trim(),
							}),
							position: Yup.string().when([Employment.COMPANY, Employment.COUNTRY, Employment.START_DATE, Employment.END_DATE], {
								is: (company, startDate, endDate) => company || startDate || endDate,
								then: () =>
									Yup.string()
										.required(intl.formatMessage({ id: 'pleaseWritePosition' }))
										.trim(),
							}),
							country4: Yup.string().when([Employment.COMPANY, Employment.POSITION, Employment.START_DATE, Employment.END_DATE], {
								is: (company, position, startDate) => company || position || startDate,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
							}),
							startDate: Yup.date().when([Employment.COMPANY, Employment.COUNTRY, Employment.POSITION, Employment.END_DATE], {
								is: (company, position, endDate) => company || position || endDate,
								then: () =>
									Yup.date()
										.required(intl.formatMessage({ id: 'pleaseWriteStartDate' }))
										.min(
											new Date(new Date().setFullYear(new Date().getFullYear() - 60)),
											intl.formatMessage({ id: 'pleaseWriteValidDate' })
										)
										.max(new Date(), intl.formatMessage({ id: 'startDateLaterThanToday' })),
							}),
							endDate: Yup.date().when(
								[
									Employment.COMPANY,
									Employment.COUNTRY,
									Employment.POSITION,
									Employment.START_DATE,
									Employment.CURRENTLY_WORKING,
								],
								{
									is: (company, country, position, startDate, currentlyWorking) =>
										(company || country || position || startDate) && !currentlyWorking,
									then: () =>
										Yup.date().when(Employment.START_DATE, {
											is: startDate =>
												startDate && Yup.date().min(startDate, intl.formatMessage({ id: 'endDateLaterThanStartDate' })),
											then: () =>
												Yup.date()
													.required(intl.formatMessage({ id: 'pleaseWriteEndDate' }))
													.max(new Date(), intl.formatMessage({ id: 'endDateLaterThanToday' })),
										}),
								}
							),
							employmentStateId: Yup.string().when(Employment.COUNTRY, {
								is: country4 => country4 === Country.USA.toString(),
								then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
							}),
						},
						[
							[Employment.COUNTRY, Employment.START_DATE],
							[Employment.COUNTRY, Employment.END_DATE],
							[Employment.COUNTRY, Employment.POSITION],
							[Employment.COUNTRY, Employment.COMPANY],
							[Employment.START_DATE, Employment.END_DATE],
							[Employment.START_DATE, Employment.POSITION],
							[Employment.START_DATE, Employment.COMPANY],
							[Employment.END_DATE, Employment.POSITION],
							[Employment.END_DATE, Employment.COMPANY],
							[Employment.COMPANY, Employment.POSITION],
						]
					)
				),
				educationHistory: Yup.array().of(
					Yup.object().shape(
						{
							school: Yup.string().when([Education.COUNTRY, Education.START, Education.END, Education.DEGREE], {
								is: (degree, country3, start, end) => country3 || start || end || degree,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseWriteSchool' })),
							}),
							degree: Yup.string().when([Education.COUNTRY, Education.START, Education.END], {
								is: (country3, start, end) => country3 || start || end,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectDegree' })),
							}),
							country3: Yup.string().when([Education.DEGREE, Education.START, Education.END], {
								is: (degree, start, end) => degree || start || end,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
							}),
							start: Yup.date().when([Education.DEGREE, Education.COUNTRY, Education.END], {
								is: (degree, country3, end) => degree || country3 || end,
								then: () =>
									Yup.date()
										.max(new Date(), intl.formatMessage({ id: 'pleaseWriteCorrectDate' }))
										.min(
											new Date(new Date().setFullYear(new Date().getFullYear() - 60)),
											intl.formatMessage({ id: 'pleaseWriteValidDate' })
										)
										.required(intl.formatMessage({ id: 'pleaseWriteStartDate' })),
							}),
							end: Yup.date().when([Education.DEGREE, Education.COUNTRY, Education.START, Education.CURRENTLY_STUDYING], {
								is: (degree, country3, start, currentlyStudying) => (degree || country3 || start) && !currentlyStudying,
								then: () =>
									Yup.date().when(Education.START, {
										is: start =>
											start &&
											Yup.date().min(
												new Date(new Date(start).setFullYear(new Date(start).getFullYear() + 1)),
												intl.formatMessage({ id: 'endDateCannotBeSameAsStartDate' })
											),
										then: () =>
											Yup.date()
												.required(intl.formatMessage({ id: 'pleaseWriteEndDate' }))
												.max(new Date(), intl.formatMessage({ id: 'endDateLaterThanToday' })),
									}),
							}),
							educationStateId: Yup.string().when(Education.COUNTRY, {
								is: country3 => country3 === Country.USA.toString(),
								then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
							}),
						},
						[
							[Education.COUNTRY, Education.START],
							[Education.COUNTRY, Education.END],
							[Education.COUNTRY, Education.DEGREE],
							[Education.START, Education.END],
							[Education.START, Education.DEGREE],
							[Education.END, Education.DEGREE],
						]
					)
				),
				licenseHistory: Yup.array().of(
					Yup.object().shape(
						{
							country2: Yup.string().when([License.LICENSE_NO], {
								is: licenseNo => licenseNo,
								then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
							}),
							licenseNo: Yup.string().when([License.COUNTRY], {
								is: country2 => country2,
								then: () =>
									Yup.string()
										.required(intl.formatMessage({ id: 'pleaseWriteLicense' }))
										.trim(),
							}),
							licenseStateId: Yup.string()
								.nullable()
								.when(License.COUNTRY, {
									is: country2 => country2 === Country.USA.toString(),
									then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
								}),
						},
						[[License.COUNTRY, License.LICENSE_NO]]
					)
				),
			},
			[
				[Address.COUNTRY, Address.CITY],
				[Address.COUNTRY, Address.ZIP_CODE],
				[Address.COUNTRY, Address.ADDRESS_1],
				[Address.CITY, Address.ZIP_CODE],
				[Address.CITY, Address.ADDRESS_1],
				[Address.ZIP_CODE, Address.ADDRESS_1],
			]
		);
	};

	const getSelectedLanguages = () =>
		languages.reduce((result, element) => {
			if (element.checked) {
				result.push({ userId: getUserId(), languageId: element.id });
			}
			return result;
		}, []);
	const onSubmitHandler = async values => {
		const userId = getUserId();
		const {
			firstName,
			lastName,
			genderId,
			dateOfBirth,
			email,
			profilePicture,
			specialty,
			country1,
			city,
			zipCode,
			address,
			idNumber,
			addressStateId,
			phoneNumber,
		} = values;

		const parsedGenderId = parseInt(genderId, 10);
		const params = {
			firstName,
			lastName,
			countryId: country1,
			email,
			genderId: parsedGenderId === Gender.UNAVAILABLE ? null : parsedGenderId,
			dateOfBirth: dateOfBirth ? dateOfBirth : null,
			phoneNr: phoneNumber?.toString(),
			userAddress:
				country1 || city || zipCode || address
					? {
							countryId: parseInt(country1, 10),
							city,
							zipCode: zipCode.toString(),
							address1: address,
							stateId: addressStateId,
						}
					: null,
			profilePicture: profilePicture,
			idCard: idNumber?.toString(),
			specialtyId: specialty,
			userLanguages: getSelectedLanguages(),
			userEducations: values.educationHistory.map(
				({ degree, country3, start, end, school, currentlyStudying, educationStateId }) =>
					(degree || country3 || start || end) && {
						educationTypeId: degree,
						userId,
						school,
						country: countries.find(item => item.id === country3)?.name,
						city: 'city',
						startDate: start,
						endDate: currentlyStudying ? null : end,
						isCurrentStudying: currentlyStudying,
						stateId: educationStateId,
					}
			),
			doctorExperiences: values.employmentHistory.map(
				({ company, position, country4, startDate, endDate, currentlyWorking, employmentStateId }) =>
					(company || position || country4 || startDate) && {
						doctorId: userId,
						employmentCompany: company,
						position,
						city: countries.find(item => item.id === country4)?.name,
						startDate,
						endDate: currentlyWorking ? null : endDate,
						isCurrentPosition: currentlyWorking,
						stateId: employmentStateId,
					}
			),
			doctorStateLicences: values.licenseHistory.map(
				({ country2, licenseNo, licenseStateId }) =>
					(country2 || licenseNo) && {
						countryId: country2,
						licenceNr: licenseNo,
						doctorId: userId,
						stateId: licenseStateId,
					}
			),
		};
		const newParams = {
			...params,
			doctorExperiences: params.doctorExperiences.filter(experience => experience),
			userEducations: params.userEducations.filter(education => education),
			doctorStateLicences: params.doctorStateLicences.filter(license => license),
		};
		const response = await updateDoctorProfile(newParams);
		if (response.error) {
			setError(
				response.error.response.data.code === HealthcareErrorCode.DOCTOR_ID_CARD_EXISTS
					? translate('idNumberExists')
					: response.error.message
			);
			return;
		}
		saveStampAndSignature();
		loadUserInfo();
		setChangesSaved(translate('changesSaved'));
	};

	const clearSignature = () => {
		const canvas = signatureRef.getCanvas();
		const context = canvas.getContext('2d');
		context.clearRect(0, 0, canvas.width, canvas.height);
	};

	const getSignatureAsFile = () => {
		const base64 = signatureRef.getTrimmedCanvas().toDataURL();
		const photo = dataURLtoFile(base64, 'test.png');
		const formData = new FormData();
		photo.preview = URL.createObjectURL(photo);
		formData.append('File', photo);
		return formData;
	};

	const saveStampAndSignature = async () => {
		if (!signatureRef || (signatureRef.isEmpty() && !stamp)) {
			return;
		}
		let signature = signatureFormProfile;
		let sendStamp = stampFromProfile;
		if (!isCanvasEmpty(signatureRef.getCanvas())) {
			const response = await uploadStampOrSignatureImage(getSignatureAsFile());
			if (response.error) {
				setError(response.error.message);
			} else {
				signature = response.fileName;
			}
		}
		if (stamp) {
			sendStamp = sendStamp !== '' ? stamp.split('doctor-files/')[1].split('?')[0] : '';
		}
		const stampSignature = await uploadStampAndSignature(userInfo.id, sendStamp, signature);
		if (stampSignature.error) {
			setError(stampSignature.error.message);
			return;
		}
		const drProfile = await getDoctorProfile(userInfo.id);
		if (drProfile.doctorProfile?.signaturePicture) {
			const blobSignature = await getBlobSasPicture(drProfile.doctorProfile?.signaturePicture, 'doctor-files');
			if (blobSignature.error || blobSignature.errorResponse) {
				setError(blobSignature.error || blobSignature.errorResponse.message);
			} else {
				setCurrentSignature(blobSignature.result.uri.uri);
			}
		}
	};

	const transformArray = array => array.map(item => ({ value: item.id, label: item.name }));
	const translateAndTransformArray = array => array.map(item => ({ value: item.id, label: translate(_.camelCase(item.name)) }));

	const onLanguagesChange = language => {
		setLanguages(prevState => {
			const languagesCopy = [...prevState];
			const foundIndex = languages.findIndex(lg => lg.id === language.id);
			languagesCopy[foundIndex].checked = language.checked;
			return languagesCopy;
		});
	};

	const getDescription = title => {
		switch (title) {
			case intl.formatMessage({ id: 'personalInformation' }):
				return intl.formatMessage({ id: 'personalInformationTitle' });
			case intl.formatMessage({ id: 'locationAndAddress' }):
				return intl.formatMessage({ id: 'locationAndAddressTitle' });
			case intl.formatMessage({ id: 'signatureAndStamp' }):
				return intl.formatMessage({ id: 'signatureAndStampTitle' });
			case intl.formatMessage({ id: 'licenses' }):
				return intl.formatMessage({ id: 'licensesTitle' });
			case intl.formatMessage({ id: 'employment' }):
				return intl.formatMessage({ id: 'employmentTitle' });
			case intl.formatMessage({ id: 'education' }):
				return intl.formatMessage({ id: 'educationTitle' });
			default:
				return title;
		}
	};

	const checkIfFormIsValid = errors => {
		if (Object.keys(errors).length) {
			setError(intl.formatMessage({ id: 'fillMandatoryFields' }));
			setTabsExpanded([
				{ id: 0, isExpanded: true },
				{ id: 1, isExpanded: true },
				{ id: 2, isExpanded: true },
				{ id: 3, isExpanded: true },
				{ id: 4, isExpanded: true },
				{ id: 5, isExpanded: true },
			]);
		}
	};

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='calc(100vh - 105px)' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='account-settings-inner-wrapper account-settings-tabs doctor-profile-settings'>
					<h4>{translate('profileInformation')}</h4>
					<Formik
						initialValues={getInitialValues()}
						onSubmit={onSubmitHandler}
						validateOnBlur={false}
						validateOnChange={true}
						validationSchema={validateForm}>
						{formikProps => {
							const { values, handleChange, handleSubmit, setFieldValue, errors } = formikProps;
							return (
								<>
									{profileInfoTabs.map(item => (
										<React.Fragment key={item.id}>
											<div
												className='flex flex-align-center cursor-pointer position-relative'
												onClick={() => toggleProfileInfoTabs(item.id)}>
												<i className='material-icons-outlined'>
													{tabsExpanded.find(x => x.id === item.id).isExpanded ? 'arrow_drop_down' : 'arrow_right'}
												</i>
												<div className='flex column-direction'>
													<h5>{item.title}</h5>
													<span>{`${intl.formatMessage({ id: 'addOrUpdate' })} ${getDescription(item.title)}`}</span>
												</div>
											</div>
											{getSelectedTab(DoctorProfileInfoTabs.PERSONAL_INFO) && item.id === DoctorProfileInfoTabs.PERSONAL_INFO && (
												<PersonalInformation
													formikProps={formikProps}
													specialties={specialties}
													setFieldValue={setFieldValue}
													value={specialties[values.specialty - 1]?.name}
													languages={languages}
													onLanguagesChange={onLanguagesChange}
													onChange={handleChange}
													transformArray={transformArray}
													setProfilePictureProps={value => setFieldValue('profilePicture', value)}
													isDarkMode={isDarkMode}
												/>
											)}
											{getSelectedTab(DoctorProfileInfoTabs.LOCATION) && item.id === DoctorProfileInfoTabs.LOCATION && (
												<LocationAndAddress
													formikProps={formikProps}
													countries={countries}
													onChange={handleChange}
													transformArray={transformArray}
													states={states}
													isDarkMode={isDarkMode}
												/>
											)}
											{getSelectedTab(DoctorProfileInfoTabs.SIGNATURE) && item.id === DoctorProfileInfoTabs.SIGNATURE && (
												<StampAndSignature
													setSignatureRef={setSignatureRef}
													setStamp={setStamp}
													currentSignature={currentSignature}
													stamp={stamp}
													clearSignature={clearSignature}
												/>
											)}
											{getSelectedTab(DoctorProfileInfoTabs.LICENSES) && item.id === DoctorProfileInfoTabs.LICENSES && (
												<Licenses
													countries={countries}
													onChange={handleChange}
													formikProps={formikProps}
													transformArray={transformArray}
													states={states}
													isDarkMode={isDarkMode}
												/>
											)}
											{getSelectedTab(DoctorProfileInfoTabs.EMPLOYMENT) && item.id === DoctorProfileInfoTabs.EMPLOYMENT && (
												<EmploymentHistory
													countries={countries}
													onChange={handleChange}
													formikProps={formikProps}
													transformArray={transformArray}
													states={states}
													isDarkMode={isDarkMode}
												/>
											)}
											{getSelectedTab(DoctorProfileInfoTabs.EDUCATION) && item.id === DoctorProfileInfoTabs.EDUCATION && (
												<EducationHistory
													academicDegrees={AcademicDegrees}
													countries={countries}
													onChange={handleChange}
													formikProps={formikProps}
													transformArray={transformArray}
													states={states}
													translateAndTransformArray={translateAndTransformArray}
													isDarkMode={isDarkMode}
												/>
											)}
										</React.Fragment>
									))}
									<div className='flex right-align-content padding-bottom-30'>
										<Button
											className='flex flex-justify-center flex-align-center save-changes-btn'
											onClick={() => {
												checkIfFormIsValid(errors);
												handleSubmit();
											}}
											text={translate('saveChanges')}
										/>
									</div>
								</>
							);
						}}
					</Formik>
					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
					<PopUpAlert
						alertType={AlertTypes.SUCCESS}
						display={changesSaved}
						onAlertClose={() => setChangesSaved(null)}
						contentText={changesSaved}
						isSilent={true}
						center={true}
					/>
				</div>
			)}
		</>
	);
};

export default DoctorProfileInfo;
