import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AiTab, AlertTypes, ManualAlertTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AlertHistoryModal from 'components/AlertHistoryModal.jsx';
import AiGeneralSettings from 'containers/Monitoring/AiGeneralSettings.jsx';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { AiIcon } from 'icons/Monitoring/index.js';
import SetupIcon from 'icons/Monitoring/SetupIcon.jsx';
import translate from 'i18n-translations/translate.jsx';
import PatientAiSettingsMayo from 'components/PatientAiSettingsMayo.jsx';
import Button from 'components/Button.jsx';
import { getAiSettings } from 'infrastructure/helpers/aiHelper.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { saveManualAlert } from 'api/alerts.js';
import { Alert } from 'components/index.js';
import PatientHistoryIcon from 'calls/icons/PatientHistory.jsx';
import PatientAiSettingsHistory from 'components/Monitoring/PatientAiSettingsHistory.jsx';
import { CallWorkflowType } from 'constants/configurationEnums.js';
import GearIcon from 'icons/Monitoring/GearIcon.jsx';

const AiSettings = ({
	patientId,
	feed,
	isDarkMode,
	onAiSettingClick,
	isNoteShowing,
	toggleFallPrevention,
	stopVoiceOver,
	onAlertInfoClose,
	adminAiSettingsConfigurations,
	setIvBagPercentage,
	isRounding = false,
	aiSettings,
	conferenceId,
}) => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const [expandedBox, setExpandedBox] = useState(isRounding);
	const [currentTab, setCurrentTab] = useState(AiTab.SETUP);
	const [alertDetails, setAlertDetails] = useState(null);
	const [error, setError] = useState(null);
	const [showDetectionReportedAlert, setShowDetectionReportedAlert] = useState(false);

	const reportAiDetection = async () => {
		const dataToSend = {
			deviceId: feed?.deviceId,
			manualAlertTypeId: ManualAlertTypes.REPORT_AI_DETECTION,
			patientId,
			conferenceId,
			isDraft: false,
		};
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setShowDetectionReportedAlert(true);
	};

	return (
		<>
			<div className='monitoring-timeline-box ai-settings'>
				<div className={classNames('timeline-box-header', { expanded: expandedBox })}>
					<p className='timeline-box-title flex-1'>
						<AiIcon />
						{translate('ai')}
					</p>
					{getAiSettings(aiSettings, feed?.deviceId).some(item => item.isEnabled) && (
						<Button
							text={translate('reportAiDetection')}
							onClick={reportAiDetection}
							imgIcon={`${healthCareCdnUrl}/dark-mode/report-detection.svg`}
							className='report-button'
						/>
					)}
					<div className='timeline-box-actions'>
						<Button
							border='none'
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', { expanded: expandedBox })}>
						<div className='flex flex-align-center'>
							<div className='flex alert-subtabs'>
								<Button
									className={classNames('setting-alert-tab', { active: currentTab === AiTab.SETUP })}
									onClick={() => setCurrentTab(AiTab.SETUP)}
									svgIcon={
										<SetupIcon
											color={
												isDarkMode && currentTab === AiTab.SETUP
													? getMonitoringActiveIconColor(user.darkMode)
													: getMonitoringIconColor(user.darkMode)
											}
										/>
									}
									text={translate('setup')}
									alt={intl.formatMessage({ id: 'setup' })}
								/>
								<Button
									className={classNames('setting-alert-tab', { active: currentTab === AiTab.GENERAL })}
									onClick={() => setCurrentTab(AiTab.GENERAL)}
									svgIcon={
										<GearIcon
											color={
												isDarkMode && currentTab === AiTab.GENERAL
													? getMonitoringActiveIconColor(user.darkMode)
													: getMonitoringIconColor(user.darkMode)
											}
										/>
									}
									text={translate('general')}
									alt={intl.formatMessage({
										id: 'monitoringSettings',
									})}
								/>
								<Button
									className={classNames('setting-alert-tab', { active: currentTab === AiTab.HISTORY })}
									onClick={() => setCurrentTab(AiTab.HISTORY)}
									text={translate('history')}
									svgIcon={
										<PatientHistoryIcon
											color={
												isDarkMode && currentTab === AiTab.HISTORY
													? getMonitoringActiveIconColor(user.darkMode)
													: getMonitoringIconColor(user.darkMode)
											}
										/>
									}
									alt={intl.formatMessage({ id: 'history' })}
								/>
							</div>
						</div>
						{currentTab === AiTab.SETUP && patientId && (
							<PatientAiSettingsMayo
								deviceId={feed.deviceId}
								patientId={patientId}
								onAiSettingClick={onAiSettingClick}
								areEmergencyButtonsHiden={feed.areEmergencyButtonsHiden}
								isNoteShowing={isNoteShowing}
								isDarkMode={isDarkMode}
								isFallPrevention={feed.isFallPrevention}
								toggleFallPrevention={toggleFallPrevention}
								roomId={feed.roomId}
								feedWarning={feed?.warning}
								aiToastDetails={feed?.aiToastDetails}
								stopVoiceOver={stopVoiceOver}
								onAlertInfoClose={onAlertInfoClose}
								deviceFamily={feed?.deviceFamily}
								setIvBagPercentage={setIvBagPercentage}
								isRounding={isRounding}
							/>
						)}

						{currentTab === AiTab.GENERAL && patientId && (
							<AiGeneralSettings
								patientId={patientId}
								deviceId={feed.deviceId}
								isDarkMode={isDarkMode}
								roomId={feed.roomId}
								adminAiSettingsConfigurations={adminAiSettingsConfigurations}
								onAiSettingClick={onAiSettingClick}
							/>
						)}
						{currentTab === AiTab.HISTORY && patientId && (
							<PatientAiSettingsHistory
								adminAiSettingsConfigurations={adminAiSettingsConfigurations}
								roomId={feed.roomId}
								deviceId={feed.deviceId}
								patientId={patientId}
								workflowTypeId={isRounding ? CallWorkflowType.ROUNDING : CallWorkflowType.MONITORING}
							/>
						)}

						{alertDetails && (
							<div className='monitoring-timeline-wrapper'>
								<div className='flex flex-space-between'>
									<div className='cursor-pointer --blue-color go-back semi-bold' onClick={() => setAlertDetails(null)}>
										{translate('goBack')}
									</div>
									<div className='monitoring-alert-details-title'>{translate('eventDetails')}</div>
								</div>
								<AlertHistoryModal
									alertDetails={alertDetails}
									isFromMonitoring={true}
									isManual={false}
									isMeasurementAlert={false}
								/>
							</div>
						)}
					</div>
				)}
			</div>
			<PopUpAlert
				alertType={AlertTypes.SUCCESS}
				display={showDetectionReportedAlert}
				onAlertClose={() => setShowDetectionReportedAlert(false)}
				contentText={translate('detectionReported')}
				isSilent={true}
				center={true}
				selfCloseTimeOut={2000}
			/>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default AiSettings;
