import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import MeasurementUnits from 'containers/HealthMeasurements/MeasurementUnits.jsx';
import ChangePassword from 'containers/ChangePassword.jsx';
import ChangeEmail from 'containers/ChangeEmail.jsx';
import CalendarAccountsTab from 'containers/CalendarAccountsTab.jsx';
import CompanyDetails from 'containers/CompanyDetails.jsx';
import CompanySettings from 'containers/CompanySettings.jsx';
import ProfileInformation from 'containers/ProfileInformation.jsx';
import Help from 'containers/Help.jsx';
import Pharmacy from 'containers/Pharmacy.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { AccountSettingsTab, UserRoles } from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import MyWorkflow from 'containers/MyWorkflow.jsx';
import DoctorProfileInfo from 'components/ProfileInformation/DoctorProfileInfo.jsx';
import MyUserRoles from 'containers/MyUserRoles.jsx';
import PatientProfileInfo from 'components/AccountSettings/PatientProfile/PatientProfileInfo.jsx';
import GeneralSettings from 'components/GeneralSettings.jsx';
import AppLanguage from 'components/AppLanguage.jsx';
import useScreenType from 'hooks/useScreenType.js';
import { isAdUser } from 'infrastructure/helpers/commonHelpers.js';
import { Grid, Loader } from 'components/index.js';

const AccountSettings = props => {
	const [currentTab, setCurrentTab] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const userRole = getUserRole();
	const screenType = useScreenType();

	useEffect(() => {
		setIsLoading(true);
		const setTab = () => {
			if (props.location.state?.isPatientEditProfilePageVisible) {
				setCurrentTab(1);
			}
			if (props.location.state?.isDoctorAppointmentsPageLinkVisible) {
				setCurrentTab(3);
			}
			if (props.location?.state?.isSwitchRole) {
				setCurrentTab(tabLinks.length - 1);
			}
		};
		setTab();
		setIsLoading(false);
	}, [props.location.state]);

	const tabLinks = [
		userRole !== UserRoles.PATIENT && {
			id: AccountSettingsTab.GENERAL,
			title: translate('general'),
			img: `${healthCareCdnUrl}settings/general.svg`,
			activeImg: `${healthCareCdnUrl}settings/AccountSettings.svg`,
		},
		{
			id: AccountSettingsTab.EDIT_PROFILE,
			title: translate('editProfile'),
			img: `${healthCareCdnUrl}settings/edit-profile.svg`,
			activeImg: `${healthCareCdnUrl}settings/edit-profile-active.svg`,
			description: translate('manageProfileInformation'),
		},
		!isAdUser() && {
			id: AccountSettingsTab.CHANGE_PASSWORD,
			title: translate('changePassword'),
			img: `${healthCareCdnUrl}settings/change-password.svg`,
			activeImg: `${healthCareCdnUrl}settings/change-password-active.svg`,
			description: translate('keepAccountSecure'),
		},
		!isAdUser() && {
			id: AccountSettingsTab.CHANGE_EMAIL,
			title: translate('changeEmail'),
			img: `${healthCareCdnUrl}settings/change-password.svg`,
			activeImg: `${healthCareCdnUrl}settings/change-password-active.svg`,
			description: translate('keepAccountSecure'),
		},
		userRole === UserRoles.PATIENT && {
			id: AccountSettingsTab.PHARMACY,
			title: translate('pharmacy'),
			img: `${healthCareCdnUrl}settings/pharmacy.svg?v2`,
			activeImg: `${healthCareCdnUrl}settings/pharmacy-active.svg`,
			description: translate('changePharmacyLocation'),
		},
		[UserRoles.DOCTOR, UserRoles.PATIENT].includes(userRole) && {
			id: AccountSettingsTab.INTEGRATED_APPS,
			title: translate('integratedApps'),
			img: `${healthCareCdnUrl}settings/integrated-apps.svg`,
			activeImg: `${healthCareCdnUrl}settings/integrated-apps-active.svg`,
			description: translate('manageApps'),
		},
		userRole === UserRoles.PATIENT && {
			id: AccountSettingsTab.APP_LANGUAGE,
			title: translate('appLanguage'),
			img: `${healthCareCdnUrl}settings/app-language.svg`,
			activeImg: `${healthCareCdnUrl}settings/app-language-active.svg`,
			description: translate('changeAppLanguages'),
		},
		[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(userRole) && {
			id: AccountSettingsTab.MEASUREMENT_UNITS,
			title: translate('measurementUnits'),
			img: `${healthCareCdnUrl}settings/measurement-units.svg`,
			activeImg: `${healthCareCdnUrl}settings/measurement-units-active.svg`,
			description: translate('changeMeasurementUnits'),
		},
		userRole === UserRoles.ADMIN && {
			id: AccountSettingsTab.COMPANY_DETAILS,
			title: translate('companyDetails'),
			img: `${healthCareCdnUrl}settings/company-details.svg?v2`,
			activeImg: `${healthCareCdnUrl}settings/company-details.svg`,
			description: '',
		},
		userRole === UserRoles.ADMIN && {
			id: AccountSettingsTab.COMPANY_SETTINGS,
			title: translate('companySettings'),
			img: `${healthCareCdnUrl}settings/company-settings.svg?v2`,
			activeImg: `${healthCareCdnUrl}settings/company-settings.svg`,
			description: '',
		},
		{
			id: AccountSettingsTab.HELP,
			title: translate('help'),
			img: `${healthCareCdnUrl}settings/help.svg`,
			activeImg: `${healthCareCdnUrl}settings/help-active.svg`,
			description: translate('helpDescription'),
		},
		userRole === UserRoles.DOCTOR && {
			id: AccountSettingsTab.MY_WORKFLOW,
			title: translate('myWorkflow'),
			img: `${healthCareCdnUrl}settings/workflow.svg`,
			activeImg: `${healthCareCdnUrl}settings/workflow-active.svg`,
			description: '',
		},
		userRole !== UserRoles.PATIENT && {
			id: AccountSettingsTab.MY_USER_ROLES,
			title: translate('myUserRoles'),
			img: `${healthCareCdnUrl}settings/user-roles.svg`,
			activeImg: `${healthCareCdnUrl}settings/user-roles-active.svg`,
			description: '',
		},
	].filter(Boolean);

	const accountSettingsOptions = tabLinks.map((x, i) => ({ value: x.id, label: x.title, index: i }));

	return (
		<MainLayout>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='account-settings-wrapper'>
					<h4>{translate('accountSettings')}</h4>
					{screenType.isSmall && (
						<Select
							value={accountSettingsOptions.filter(x => x.index === currentTab)}
							classNamePrefix='react-select'
							options={accountSettingsOptions}
							onChange={event => setCurrentTab(event.index)}
						/>
					)}
					{tabLinks.length > 0 && (
						<Tabs
							isVertical={true}
							activeIndex={currentTab}
							onChange={index => setCurrentTab(index)}
							className='account-settings-tab'>
							<TabList>
								{tabLinks.map((item, index) => (
									<Tab key={item.id} className='account-settings-left-menu'>
										<img src={currentTab === index ? item.activeImg : item.img} alt='icon' />
										<div className='flex column-direction'>
											<p className='no-margin no-padding-top'>{item.title}</p>
											<span className='no-margin font-13'>{item.description}</span>
										</div>
									</Tab>
								))}
							</TabList>
							<TabPanels>
								{userRole !== UserRoles.PATIENT && (
									<TabPanel>
										<GeneralSettings />
									</TabPanel>
								)}
								{![UserRoles.DOCTOR, UserRoles.PATIENT].includes(userRole) && (
									<TabPanel>
										<ProfileInformation />
									</TabPanel>
								)}
								{userRole === UserRoles.DOCTOR && (
									<TabPanel>
										<DoctorProfileInfo />
									</TabPanel>
								)}
								{userRole === UserRoles.PATIENT && (
									<TabPanel>
										<PatientProfileInfo />
									</TabPanel>
								)}
								{!isAdUser() && <ChangePassword />}
								{!isAdUser() && <ChangeEmail />}
								{userRole === UserRoles.PATIENT && (
									<TabPanel>
										<Pharmacy />
									</TabPanel>
								)}
								{[UserRoles.DOCTOR, UserRoles.PATIENT].includes(userRole) && (
									<TabPanel>
										<CalendarAccountsTab />
									</TabPanel>
								)}
								{userRole === UserRoles.PATIENT && (
									<TabPanel>
										<AppLanguage />
									</TabPanel>
								)}
								{[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.DIGITAL_CLINICIAN, UserRoles.NURSE].includes(userRole) && (
									<TabPanel>
										<MeasurementUnits />
									</TabPanel>
								)}
								{userRole === UserRoles.ADMIN && (
									<TabPanel>
										<CompanyDetails />
									</TabPanel>
								)}
								{userRole === UserRoles.ADMIN && (
									<TabPanel>
										<CompanySettings />
									</TabPanel>
								)}
								<TabPanel>
									<Help />
								</TabPanel>
								{userRole === UserRoles.DOCTOR && (
									<TabPanel>
										<MyWorkflow />
									</TabPanel>
								)}
								{userRole !== UserRoles.PATIENT && (
									<TabPanel>
										<MyUserRoles setIsLoading={setIsLoading} />
									</TabPanel>
								)}
							</TabPanels>
						</Tabs>
					)}
				</div>
			)}
		</MainLayout>
	);
};

export default injectIntl(AccountSettings);
