import { gatewayApi } from 'constants/global-variables.js';

export const getPatientAiAlerts = async ({ userId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/patients/${userId}/ai?pageIndex=${pageIndex}&pageSize=${pageSize}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAlertAnalytics = async ({ patientId, pageIndex, pageSize, category }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/alerts/patients/${patientId}/analytics?pageIndex=${pageIndex}&pageSize=${pageSize}&category=${category}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientMeasurementAlerts = async ({ userId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/alerts/patients/${userId}/measurement?pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientManualAlerts = async ({ patientId, pageIndex, pageSize, category, subCategory }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/alerts/patients/${patientId}/manual?pageIndex=${pageIndex}&pageSize=${pageSize}&category=${category}&category=${subCategory}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAiAlertActivities = async aiAlertId => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/ai/${aiAlertId}/activities`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMeasurementAlertActivities = async measurementAlertId => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/measurement/${measurementAlertId}/activities`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getManualAlertActivities = async manualAlertId => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/manual/${manualAlertId}/activities`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getLatestManualAlerts = async (patientId, deviceId, names = []) => {
	try {
		const queryParams = new URLSearchParams({});
		if (names.length > 0) {
			names.forEach(name => queryParams.append('names', name));
		}
		const { data } = await gatewayApi.get(`v1/alerts/devices/${deviceId}/patients/${patientId}/manual/latest?${queryParams.toString()}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHelloRecordingsPerPatient = async (roomId, patientId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/alerts/channels/${roomId}/patients/${patientId}/hello-video-feed-records`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getBlobUrlForRecording = async (fileName, companyId) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1/alerts/hello-video-feed-records/container/monitoring-records/file-name/${fileName}/blob-content-url`,
			companyId
		);

		return data;
	} catch (error) {
		return { error };
	}
};

export const logPlayVideoRecording = async helloVideoFeedRecordId => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/hello-video-feed-records/${helloVideoFeedRecordId}/play`);

		return data;
	} catch (error) {
		return { error };
	}
};

export const addAlertActivity = async ({ alertId, activityType, isAiAlert, comment = null, isDraft = false }) => {
	try {
		const type = isAiAlert ? 'ai' : 'measurement';
		const dataToSend = isAiAlert
			? { aiAlertActivityType: activityType, comment, isDraft }
			: { measurementAlertActivityType: activityType, comment };
		const { data } = await gatewayApi.post(`/v1/alerts/${type}/${alertId}/activities`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addManualAlertActivity = async (manualAlertId, manualAlertActivityType) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/manual/${manualAlertId}/activities`, {
			manualAlertActivityType,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardAiAlert = async ({ conversationId, alertId, hospital, department, floor, room }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/ai/${alertId}/forward`, {
			conversationId,
			hospital,
			department,
			floor,
			room,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardMeasurementAlert = async ({ conversationId, alertId, hospital, department, floor, room }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/measurement/${alertId}/forward`, {
			conversationId,
			hospital,
			department,
			floor,
			room,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardManualAlert = async params => {
	try {
		const {
			conversationId,
			hospital,
			department,
			floor,
			room,
			patientId,
			deviceId,
			manualAlertTypeId,
			comment,
			isDraft = false,
		} = params;
		const { data } = await gatewayApi.post(`/v1/alerts/manual/forward`, {
			conversationId,
			hospital,
			department,
			floor,
			room,
			patientId,
			deviceId,
			manualAlertTypeId,
			comment,
			isDraft,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardManualAlertToMSTeams = async ({
	conversationId,
	channelId,
	hospital,
	department,
	floor,
	room,
	patientId,
	deviceId,
	manualAlertTypeId,
	comment,
}) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/manual/forward-ms-teams`, {
			conversationId,
			channelId,
			hospital,
			department,
			floor,
			room,
			patientId,
			deviceId,
			manualAlertTypeId,
			comment,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardAiAlertToMSTeams = async ({ conversationId, channelId, alertId, hospital, department, floor, room }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/ai/${alertId}/forward-ms-teams`, {
			conversationId,
			channelId,
			hospital,
			department,
			floor,
			room,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardMeasurementAlertToMSTeams = async ({
	conversationId,
	channelId,
	alertId,
	hospital,
	department,
	floor,
	room,
}) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/measurement/${alertId}/forward-ms-teams`, {
			conversationId,
			channelId,
			hospital,
			department,
			floor,
			room,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardAiAlertToNoraApp = async ({ alertId, ...sendData }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/ai/${alertId}/forward-nora-app`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardMeasurementAlertToNoraApp = async ({ alertId, ...sendData }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/measurement/${alertId}/forward-nora-app`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveManualAlert = async sendData => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/manual`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};
