import moment from 'moment';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';

const PersonalInformation = props => {
	const intl = useIntl();
	const genders = [
		{ code: 1, name: intl.formatMessage({ id: 'male' }) },
		{ code: 2, name: intl.formatMessage({ id: 'female' }) },
	];
	const getValue = () => props.transformArray(genders).filter(option => option.value === props.values.genderId);
	return (
		<>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('firstName')}
						<input
							type='text'
							placeholder={intl.formatMessage({ id: 'firstName' })}
							maxLength={30}
							value={props.values.firstName}
							onChange={props.onChange}
							name='firstName'
						/>
						<span className='red-error'>{props.errors.firstName}</span>
					</label>
				</div>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('lastName')}
						<input
							type='text'
							placeholder={intl.formatMessage({ id: 'lastName' })}
							maxLength={30}
							value={props.values?.lastName}
							onChange={props.onChange}
							name='lastName'
						/>
						<span className='red-error'>{props.errors.lastName}</span>
					</label>
				</div>
			</div>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('idNumber')}
						<input
							type='number'
							placeholder={intl.formatMessage({ id: 'idNumber' })}
							maxLength={30}
							value={props.values.idNumber}
							onChange={props.onChange}
							name='idNumber'
							className='number-input-wo-arrows'
							onKeyDown={event => validateMaxLength(event, 10)}
						/>
						<span className='red-error'>{props.errors.idNumber}</span>
					</label>
				</div>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('birthDate')}
						<input
							type='date'
							placeholder={intl.formatMessage({ id: 'selectDate' })}
							value={props.values.birthDate}
							onChange={props.onChange}
							name='birthDate'
							max={moment().format('YYYY-MM-DD')}
						/>
						<span className='red-error'>{props.errors.birthDate}</span>
					</label>
				</div>
			</div>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
					<label>
						{translate('sex')}
						<Select
							value={getValue()}
							placeholder={intl.formatMessage({ id: 'sex' })}
							classNamePrefix='react-select'
							options={props.transformArray(genders)}
							onChange={event => props.formikProps?.setFieldValue('genderId', event.value)}
							isSearchable
						/>
						<span className='red-error'>{props.errors.genderId}</span>
					</label>
				</div>
			</div>
		</>
	);
};
export default PersonalInformation;
