import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Patch = props => (
	<svg width={25} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' color={LightTheme.colors.graySeven} {...props}>
		<path
			d='M10.2795 17.8765C10.3409 17.9177 10.4029 17.9588 10.4655 18C11.0862 18.4083 11.7632 18.8166 12.5001 19.2231C27.9172 10.7187 17.1253 1.43418 12.5004 7.27195C9.22597 3.13939 2.86125 6.58496 4.89165 11.9673'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M3.5 14.8571H4.88461L6.96154 17L9.03846 12L11.1154 14.8571H12.5'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export default Patch;
